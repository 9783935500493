import React, { useState } from "react";
import { styled } from "styled-components";
import { TogetherIcon } from "../../../../../assets/icons/Logo";
import { CARD_DETAILS_PAGES } from "./CardDetailsPage";
import { addUserToWaitingList } from "../../Activities/Create/api";
import LoadingSpinner from "../../../../Common/Loading/Loading";
import Button from "../../../../../kit/Button/Button";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";
import { getPhotoSrc } from "../../../Post/utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;
const TitleWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-left: 20px;
`;
const ThanksText = styled.h1`
  color: rgba(0, 63, 93, 1);
  font-family: Assistant;
  font-size: 35px;
  font-weight: 700;
  line-height: 45.78px;
  letter-spacing: 0.02em;
  text-align: center;
  direction: rtl;
  justify-self: center;
`;

const btnStyle = {
  color: "#316788",
  border: "2px solid #316788",
  width: "95%",
  height: "44px",
  borderRadius: "30px",
  margin: "29px auto",
  fontSize: "20px",
  fontFamily: "Assistant",
  fontWeight: "700",
};

const CardDetailsConfirmPage = ({
  setCurrentView,
  postData,
  refetchWaitingList,
  cardData,
}) => {
  const [hasError, setHasError] = useState(false);
  const { currentUser } = useCurrentUser();

  const onConfirm = async () => {
    try {
      await addUserToWaitingList(postData);
      refetchWaitingList();
      setCurrentView(CARD_DETAILS_PAGES.Level_Three);
    } catch (error) {
      setHasError(error?.response?.data?.error?.message);
    }
  };

  const onGoBack = async () => {
    setCurrentView(CARD_DETAILS_PAGES.Level_One);
  };

  if (!postData) {
    return <LoadingSpinner center={true} text={"Loading"} />;
  }

  return (
    <Wrapper>
      <img
        style={{ height: "250px", width: "100%", objectFit: "cover" }}
        src={getPhotoSrc(cardData?.photo)}
        alt={"Cover"}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "15px 0",
          fontFamily: "Assistant",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "23.54px",
          color: "rgba(49, 103, 136, 1)",
          width: "90%",
        }}
      >
        <span style={{ marginBottom: "auto" }}>
          {`${cardData?.startDate}${
            cardData?.endDate ? ` - ${cardData?.endDate}` : ""
          }`}
        </span>
        <span style={{ textAlign: "right" }}>
          <span>{`${cardData?.startTime} - ${cardData?.endTime}`}</span>
          <br />
          {`${cardData?.mainLocation}`}
        </span>
      </div>
      <TitleWrapper>
        <div
          style={{
            display: "flex",
            height: "50px",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TogetherIcon width={"247px"} height={"50px"} />
          </div>
        </div>
      </TitleWrapper>
      {hasError ? (
        <ThanksText>
          {hasError}
          <br />
          <br />
          <br />
          <Button text={"Go back"} action={onGoBack} styles={btnStyle} />
        </ThanksText>
      ) : (
        <ThanksText>
          הי {currentUser?.name}
          <br />
          נרשמה בקשתך להירשם
          <br />
          <Button
            text={"אישור - Confirm Registration"}
            action={onConfirm}
            styles={btnStyle}
          />
          <Button
            text={"אולי מאוחר יותר - Maybe Later"}
            action={onGoBack}
            styles={btnStyle}
          />
        </ThanksText>
      )}
    </Wrapper>
  );
};

export default CardDetailsConfirmPage;
