import React, { createContext, useState } from "react";

export const PopContext = createContext();

export function PopProvider({ children }) {
  const [popupMap, setPopupMap] = useState(false);

  const showPopupMap = (value) => setPopupMap(value);

  const hidePopupMap = () => setPopupMap(false);

  return (
    <PopContext.Provider value={{ popupMap, showPopupMap, hidePopupMap }}>
      {children}
    </PopContext.Provider>
  );
}
