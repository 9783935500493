import React, { useState, useRef, useEffect, useMemo } from "react";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "5px"};
`;

const NumberInput = styled.input`
  &::placeholder {
    color: #cbc2c4;
  }
`;

// e.g: initialValue = 123456890
const PhoneNumberInput = ({
  setValue,
  required,
  initialValue,
  placeholderValue,
  disabled,
  setHookValue,
  hookName,
  inputStyle,
  length = 10,
  showSeparator = true,
  gap,
}) => {
  const initVal =
    initialValue && initialValue !== ""
      ? initialValue?.split?.("")
      : Array(length).fill("");
  const [phoneNumber, setPhoneNumber] = useState(initVal);
  const inputRefs = useRef([]);
  const placeholderArr = useMemo(
    () => (placeholderValue ? placeholderValue?.split?.("") : []),
    [placeholderValue]
  );

  const updateValue = (newPhoneNumber) => {
    setValue?.(newPhoneNumber.join(""));
    setHookValue && setHookValue(hookName, newPhoneNumber.join(""));
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (!/\d/.test(value) || index >= length) {
      return;
    }

    const newPhoneNumber = [...phoneNumber];
    newPhoneNumber[index] = value;
    setPhoneNumber(newPhoneNumber);
    updateValue(newPhoneNumber);
    if (index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key !== "Backspace") {
      return;
    }

    if (index > 0) {
      const newPhoneNumber = [...phoneNumber];
      newPhoneNumber[index] = "";
      setPhoneNumber(newPhoneNumber);
      updateValue(newPhoneNumber);
      inputRefs.current[index - 1].focus();
      return;
    }

    if (index === 0) {
      const newPhoneNumber = [""].concat(phoneNumber.slice(1));
      setPhoneNumber(newPhoneNumber);
      updateValue(newPhoneNumber);
      inputRefs.current[index].focus();
    }
  };

  useEffect(() => {
    if (setHookValue && initialValue) {
      setHookValue(hookName, initialValue);
    }
  }, [initialValue, setHookValue, hookName]);

  return (
    <Container gap={gap}>
      {phoneNumber?.map((digit, index) => (
        <>
          {index === 3 && showSeparator && (
            <span
              key={"Separator"}
              style={{ color: "#28B5b5", marginRight: "2px" }}
            >
              -
            </span>
          )}
          <div key={index}>
            <NumberInput
              ref={(ref) => (inputRefs.current[index] = ref)}
              required={required}
              disabled={disabled}
              placeholder={placeholderArr?.[index] ?? []}
              type="text"
              pattern="\d*"
              value={digit}
              maxLength={1}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="digit-input"
              style={{
                backgroundColor: `${
                  inputStyle?.backgroundColor
                    ? inputStyle.backgroundColor
                    : "#F1F1F1"
                }`,
                border: `${inputStyle?.border ? inputStyle.border : "none"}`,
                outline: "none",
                width: `${inputStyle?.width ? inputStyle.width : "20px"}`,
                height: `${inputStyle?.height ? inputStyle.height : "20px"}`,
                textAlign: "center",
                borderRadius: `${
                  inputStyle?.borderRadius ? inputStyle.borderRadius : "50%"
                }`,
                padding: 0,
                color: `${inputStyle?.color ? inputStyle.color : "#7E7E7E"}`,
              }}
            />
          </div>
        </>
      ))}
    </Container>
  );
};

export default PhoneNumberInput;
