import React, { useRef } from "react";
import Card from "./Card";
import CardSliderContainer from "../../../CardSliderContainer/CardSliderContainer";

const CardSlider = ({
  data,
  activeSlide,
  handleClick,
  hasHiddenContent,
  zone,
  handleFocus,
  isCardsVisible = true,
}) => {
  const containerRef = useRef(null);

  const renderCard = (data, index) => {
    if (!data) return null;

    return (
      <Card
        key={data.id}
        {...data}
        isActive={activeSlide === index}
        hasHiddenContent={hasHiddenContent}
        index={index}
        handleClick={handleClick}
        zone={zone}
        ref={containerRef}
        handleFocus={handleFocus}
        activeSlide={activeSlide}
      />
    );
  };
  return (
    <CardSliderContainer ref={containerRef} numberOfCards={data?.length}>
      {isCardsVisible &&
        data?.map((data, index) => <>{renderCard(data, index)}</>)}
    </CardSliderContainer>
  );
};

export default CardSlider;
