import React from "react";

const MicrophonePlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={83}
      height={53}
      fill="none"
      viewBox="0 0 83 53"
    >
      <g filter="url(#a)">
        <rect
          width={74.359}
          height={44.872}
          x={2.641}
          y={3}
          fill="#EBF6F8"
          rx={6.41}
        />
        <rect
          width={73.718}
          height={44.231}
          x={2.961}
          y={3.321}
          stroke="#EF7300"
          strokeWidth={0.641}
          rx={6.09}
        />
      </g>
      <path
        fill="#EF7300"
        d="M55.502 36.31H54.26c-.053-.008-.106-.035-.158-.035a9.959 9.959 0 0 1-3.691-.96c-3.25-1.594-5.241-4.166-5.937-7.733-.08-.406-.114-.81-.167-1.216v-1.242c.044-.361.079-.722.14-1.083.406-2.387 1.48-4.422 3.268-6.06 2.326-2.123 5.092-3.04 8.227-2.749 2.247.212 4.237 1.084 5.928 2.59 2.07 1.84 3.25 4.149 3.533 6.897.176 1.735-.044 3.426-.74 5.03-1.586 3.62-4.334 5.778-8.245 6.447-.3.053-.608.08-.916.114Zm-.625-1.761c4.853 0 8.79-3.92 8.808-8.782.018-4.853-3.928-8.808-8.8-8.817a8.806 8.806 0 0 0-8.808 8.782c-.017 4.853 3.929 8.808 8.8 8.817Z"
      />
      <path
        fill="#EF7300"
        d="M53.142 25.76v-3.074c0-.246.052-.449.281-.572.238-.132.45-.053.652.097a734.039 734.039 0 0 1 4.22 3.074c.396.29.396.67 0 .96-1.41 1.03-2.828 2.052-4.237 3.083-.194.14-.388.211-.617.097-.229-.115-.3-.317-.3-.564v-3.1Z"
      />
      <g fill="#EF7300" clipPath="url(#b)">
        <path d="M19.446 36.97c-.007-.07-.077-.058-.116-.081-.304-.18-.45-.5-.364-.82a.722.722 0 0 1 .714-.536c1.487-.002 2.974-.004 4.46.003.172 0 .226-.037.221-.217a35.748 35.748 0 0 1 0-1.685c.005-.182-.053-.24-.228-.258a8.06 8.06 0 0 1-3.914-1.496c-1.852-1.333-2.984-3.133-3.42-5.368-.078-.401.194-.772.61-.852.357-.07.743.189.815.573.183.973.526 1.883 1.091 2.698 1.14 1.643 2.694 2.652 4.677 2.966 3.657.58 7.12-1.788 7.883-5.378.021-.103.033-.207.057-.309.092-.375.476-.623.835-.544.406.09.658.451.59.846-.588 3.385-3.354 6.178-6.712 6.773-.22.04-.443.076-.666.098-.152.015-.187.08-.184.227.01.577.01 1.154 0 1.731-.004.16.04.196.196.195 1.464-.007 2.929.006 4.393-.01.477-.006.735.28.803.544a.72.72 0 0 1-.464.865c-.008.003-.009.023-.013.035H19.446ZM13.543 19.571c.172-.306.392-.534.784-.502.352.03.652.321.656.673.006.755.005 1.51 0 2.264-.003.296-.236.578-.521.657a.757.757 0 0 1-.82-.323c-.035-.052-.03-.13-.099-.16v-2.608ZM36.61 22.176c-.177.314-.406.541-.808.498-.326-.034-.626-.322-.63-.65-.008-.769-.008-1.538 0-2.308.003-.285.242-.559.52-.633a.747.747 0 0 1 .819.325c.034.053.033.128.1.16v2.608Z" />
        <path d="M30.106 22.224c-.02.912.063 2.046-.03 3.173-.171 2.058-1.241 3.517-3.14 4.305-2.912 1.21-6.241-.645-6.805-3.746a5.662 5.662 0 0 1-.082-1.006c-.002-1.963-.004-3.925 0-5.888.006-2.376 1.452-4.293 3.719-4.942 2.718-.777 5.653 1.008 6.22 3.782.082.398.115.8.117 1.204.003.966 0 1.933 0 3.118Zm-1.462-.22V19.06a4.9 4.9 0 0 0-.045-.693c-.273-1.907-2.27-3.296-4.162-2.921a3.626 3.626 0 0 0-2.932 3.596c.029 1.977.006 3.955.007 5.932 0 .225.012.447.045.671.285 1.948 2.32 3.329 4.25 2.903 1.688-.372 2.874-1.877 2.841-3.556-.02-.995-.003-1.992-.003-2.988h-.001ZM18.236 20.893c0 .689.005 1.378-.003 2.066a.699.699 0 0 1-.62.706c-.385.04-.697-.167-.8-.533a.579.579 0 0 1-.023-.154c-.002-1.4-.004-2.8 0-4.199.002-.344.269-.643.59-.691.375-.056.694.13.82.475.04.11.036.22.036.332.002.666 0 1.332 0 1.999ZM33.365 20.872v2.02c-.001.456-.298.776-.717.78-.427.004-.728-.305-.73-.763a797.92 797.92 0 0 1 0-4.064c.002-.464.295-.768.724-.767.422.002.722.32.723.773.002.674 0 1.347 0 2.02v.001Z" />
      </g>
      <defs>
        <clipPath id="b">
          <path fill="#fff" d="M13.538 13.898h23.077v23.077H13.538z" />
        </clipPath>
        <filter
          id="a"
          width={82.052}
          height={52.563}
          x={0.077}
          y={0.436}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius={0.641}
            result="effect1_dropShadow_669_113"
          />
          <feOffset dx={1.282} dy={1.282} />
          <feGaussianBlur stdDeviation={1.603} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_669_113"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_669_113"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MicrophonePlayIcon;
