export const ApplesTitleInformation = {
  default: {
    hebrew: "השתתפו בעשייה טובה",
    english: "Join Us ",
  },
  Taglit: {
    hebrew: "הצטרפו לתגלית",
    english: "Join Taglit",
  },
};

export const ApplesSubTitleInformation = {
  default: {
    hebrew: "הדרכה למתנדבים",
    english: "Volunteers guide",
  },
};
