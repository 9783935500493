import React, { useState } from "react";
import Apple from "./Apple";
import styled from "styled-components";

import useMediaQueryConstants from "../../../../../../customHooks/useMediaQueryConstants";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const CardSliderContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const SlidingCardsWrapper = styled.div`
  display: flex;
  align-items: end;
  padding: 5px 10px;
  gap: ${(props) => (props.isPersonIconClicked ? "11px" : "26px")};
  width: ${(props) => props.width};
  margin: ${({ isMediumScreen }) => (isMediumScreen ? "auto" : "0")};
  justify-content: ${({ isMediumScreen }) =>
    isMediumScreen ? "center" : "start"};
`;

const ArrowLeft = styled.button`
  position: absolute;
  top: calc(50% + 5px);
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 24px;
  left: -20px;
`;

const ArrowRight = styled.button`
  position: absolute;
  top: calc(50% + 5px);
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 24px;
  right: -15px;
`;

const Slider = (props) => {
  const {
    data,
    handleAppleClick,
    handleClick,
    targetApple,
    activeSlide,
    isPersonIconClicked,
  } = props;
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();

  const [startIndex, setStartIndex] = useState(0);

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (startIndex < data.length - 5) {
      setStartIndex(startIndex + 1);
    }
  };

  const visibleData = isWideScreen
    ? data.slice(startIndex, startIndex + 5)
    : data;

  return (
    <CardSliderContainer id="card-slider-container">
      <SlidingCardsWrapper
        width={isWideScreen ? "100%" : `${62 * data.length}px`}
        isPersonIconClicked={isPersonIconClicked}
        isMediumScreen={isMediumScreen}
      >
        {visibleData.map((data, index) => (
          <Apple
            key={index}
            index={startIndex + index} // Adjust index based on the start index
            data={data}
            handleAppleClick={handleAppleClick}
            handleClick={handleClick}
            isActive={targetApple === startIndex + index}
            isPersonIconClicked={isPersonIconClicked}
          />
        ))}
      </SlidingCardsWrapper>
      {isWideScreen && (
        <ArrowLeft onClick={handlePrevClick}>
          <FaAngleLeft color="#316788" />
        </ArrowLeft>
      )}
      {isWideScreen && (
        <ArrowRight onClick={handleNextClick}>
          <FaAngleRight color="#316788" />
        </ArrowRight>
      )}
    </CardSliderContainer>
  );
};

export default Slider;
