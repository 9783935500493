import React, { useContext, useEffect, useMemo } from "react";
import { LanguageContext } from "../../../../store/languageContext";
import { CommunitySections } from "../Board";
import { MainOrgZone } from "../../../../customHooks/useFetchEmergencyEvents";
import {
  HelpfulTipsAndAdvice,
  unitedRescueFiveMin,
  vetDailyTip,
  vetFiveMin,
  EmergencyAndRescueTips,
  HelpfulTipsAndAdvice3,
  HelpfulTipsAndAdvice4,
  HelpfulTipsAndAdvice2,
} from "../../Mock/publicData";
import styled from "styled-components";
import Post from "../../Post/components/Post";
import { PostButtonStyles } from "../Form/AddPost";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import Form from "../Form/Container";
import VetPost from "../Form/VetPost";
import { isUserAuthorizedForAction } from "../../../Auth/login/utils";
import useCurrentUser from "../../../../customHooks/useCurrentUser";

const Wrapper = styled.div`
  margin-top: 11px;
  padding: 10px 0 0 0;
  text-align: center;
`;
const ImageHeader = styled.div`
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: contain;
`;
const ImageTitle = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  color: #007897;
  -webkit-text-stroke: 1px white;
  font-family: Assistant;
  font-size: 25px;
  font-weight: 800;
  line-height: 35px; /* 140% */
`;

const PostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const PostHighlight = styled.div`
  // border: 1.5px solid #7fb383;
  border-radius: 5px;
  margin: 15px;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(127, 179, 131, 0.5);
`;

function BasicBoard({
  posts,
  setPosts,
  showAddPost,
  setShowAddPost,
  refetchPosts,
  setShowShareList,
  zone,
  section,
  postId,
  setCustomZoneId,
  customboard,
}) {
  const { currentLanguage } = useContext(LanguageContext);
  const highlightedPost = useMemo(
    () => posts?.find((p) => p._id === postId),
    [posts, postId]
  );
  const { currentUser } = useCurrentUser();

  const getBoardData = useMemo(() => {
    const dataMapping = {
      [`${MainOrgZone.Vet}-${CommunitySections.FiveMin}`]: vetFiveMin,
      [`${MainOrgZone.Vet}-${CommunitySections.DailyTip}`]: vetDailyTip,
      [`${MainOrgZone.UnitedRescue}-${CommunitySections.FiveMin}`]:
        unitedRescueFiveMin,
      [`${MainOrgZone.BestFriends}-${CommunitySections.HelpfulTipsAndAdvice}`]:
        HelpfulTipsAndAdvice,
      [`${"BestFriends2"}-${CommunitySections.HelpfulTipsAndAdvice}`]:
        HelpfulTipsAndAdvice2,
      [`${MainOrgZone.ByBestFriends}-${CommunitySections.HelpfulTipsAndAdvice}`]:
        HelpfulTipsAndAdvice3,
      [`${"BestFriends4"}-${CommunitySections.HelpfulTipsAndAdvice}`]:
        HelpfulTipsAndAdvice4,
      [`${MainOrgZone.EmergencyAndRescue}-${CommunitySections.EmergencyAndRescueTips}`]:
        EmergencyAndRescueTips,
    };

    const key = `${zone}-${section}`;
    const dataArray = dataMapping[key] || dataMapping.default;
    return dataArray?.find((a) => postId.includes(a.id));
  }, [postId, section, zone]);

  useEffect(() => {
    const zoneId = postId?.replace(`-${getBoardData?.id}`, "");
    if (zoneId) {
      setCustomZoneId && setCustomZoneId(zoneId);
    }
  }, [getBoardData?.id, postId, setCustomZoneId, customboard]);

  return (
    <Wrapper>
      <>
        <ImageHeader>
          <Image
            postId
            src={customboard?.image ?? getBoardData?.image}
            alt=""
          />
          <ImageTitle>{customboard?.title ?? getBoardData?.title}</ImageTitle>
        </ImageHeader>
        {isUserAuthorizedForAction(currentUser, undefined, true) && (
          <Form
            title={"Add post..."}
            showForm={showAddPost}
            setShowForm={setShowAddPost}
            content={
              <VetPost
                posts={posts}
                setPosts={setPosts}
                onPostSuccess={() => setShowAddPost(false)}
                zone={zone}
                section={section}
                type={postId}
              />
            }
            headerButton={
              <button
                style={{
                  ...PostButtonStyles(
                    currentLanguage === LANGUAGES.ENGLISH.value
                  ),
                  background: "#fff",
                  margin: "auto 0",
                }}
                onClick={() => setShowAddPost((shown) => !shown)}
              >
                post
              </button>
            }
          />
        )}
        <PostsWrapper>
          {highlightedPost && (
            <PostHighlight>
              <Post
                postData={highlightedPost}
                posts={posts}
                refetchPosts={refetchPosts}
                setPosts={setPosts}
                setShowShareList={setShowShareList}
                zone={zone}
                showImage={false}
                postId={postId}
                isBasic
              />
            </PostHighlight>
          )}
          {posts &&
            posts
              ?.filter((p) => p._id !== postId)
              ?.map((post) => (
                <Post
                  postData={post}
                  posts={posts}
                  refetchPosts={refetchPosts}
                  setPosts={setPosts}
                  setShowShareList={setShowShareList}
                  zone={zone}
                  showImage={false}
                  postId={postId}
                  isBasic
                />
              ))}
        </PostsWrapper>
      </>
    </Wrapper>
  );
}

export default BasicBoard;
