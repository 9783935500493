export const ROUTES = {
  ORT: "",
  GDEROT: "gderot",
};

export const STUDENT = [
  {
    name: "אורט - תל אביב",
    route: `/activities/student/${ROUTES.ORT}`,
    value: ROUTES.ORT,
  },
  {
    name: "הגמנסיה - גדרות",
    route: `/activities/student/${ROUTES.GDEROT}`,
    value: ROUTES.GDEROT,
  },
  {
    name: "הראשונים - הוד השרון",
    route: null,
    value: "",
  },
  {
    name: "בן צבי - הרצליה",
    route: null,
    value: "",
  },
  {
    name: "רמות - רמת השרון",
    route: null,
    value: "",
  },
  {
    name: "הגבעה - כפר סבא ",
    route: null,
    value: "",
  },
];
