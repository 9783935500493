import axios from "axios";
import { CURRENT_DOMAIN } from "../../../info";

export const PostType = {
  Network: "network",
  Emergency1: "Emergency1",
  Emergency2: "Emergency2",
  Emergency3: "Emergency3",
  Emergency4: "Emergency4",
  Emergency5: "Emergency5",
  Emergency6: "Emergency6",
};

export const fetchPosts = async () =>
  await axios.get(`${CURRENT_DOMAIN}/posts`);

export const fetchPostsByZone = async (zone) =>
  await axios.get(`${CURRENT_DOMAIN}/posts/zone/${zone}`);

export const fetchPostsByZoneAndType = async (zone, type) =>
  await axios.get(`${CURRENT_DOMAIN}/posts/zone/${zone}/type/${type}`);

export const fetchPostsByZoneAndTypes = async (zone, types) => {
  return await axios.post(`${CURRENT_DOMAIN}/posts/zone/${zone}/types`, {
    types,
  });
};

export const fetchPostById = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/posts/${id}`);

export const getLikesByPostId = async (postId) =>
  await axios.get(`${CURRENT_DOMAIN}/likes/likes_by_postid/${postId}`);

export const createPost = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/posts`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editPost = async (id, data) =>
  await axios.patch(`${CURRENT_DOMAIN}/posts/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// For give & receive
export const createPostItem = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/posts_items`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editPostItem = async (id, data) =>
  await axios.patch(`${CURRENT_DOMAIN}/posts_items/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// For give & receive
export const fetchPostItemsByZone = async (zone) =>
  await axios.get(`${CURRENT_DOMAIN}/posts_items/zone/${zone}`);

export const fetchPostItemsByZoneAndType = async (zone, type) =>
  await axios.get(`${CURRENT_DOMAIN}/posts_items/zone/${zone}/type/${type}`);

export const likePost = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/likes`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const unlikePost = async (data) =>
  await axios.delete(`${CURRENT_DOMAIN}/likes`, {
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createComment = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/comments`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteCommentById = async (id) =>
  await axios.delete(`${CURRENT_DOMAIN}/comments/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getCommentsByPostId = async (postId) =>
  await axios.get(`${CURRENT_DOMAIN}/comments/comments_by_postid/${postId}`);
