import { TaskZone } from "../../../customHooks/useFetchTasks";
import TogetherInTelaviv from "../../../assets/icons/Logo/city/TogetherInTelaviv";
import TogetherInGderot from "../../../assets/icons/Logo/city/TogetherInGderot";
import TogetherOrtSchool from "../../../assets/icons/Logo/TogetherInOrtStudent";
import TogetherEmergencyIcon from "../../../assets/icons/Logo/TogetherEmergencyIcon";
import { MainOrgZone } from "../../../customHooks/useFetchEmergencyEvents";

export const ICONS = {
  [TaskZone.TelAvivCity]: TogetherInTelaviv,
  [TaskZone.GderotCity]: TogetherInGderot,
  [TaskZone.OrtStudent]: TogetherOrtSchool,
  [TaskZone.Emergency1]: TogetherEmergencyIcon,
};

export const CUSTOM_TITLE_ZONES = [
  MainOrgZone.BestFriends,
  MainOrgZone.ByBestFriends,
  MainOrgZone.Vet,
];

export const getCategoriesByZone = (zone) => {
  switch (zone) {
    case MainOrgZone.BestFriends:
      return [
        { label: "חתולים", value: "pets" },
        { label: "התנהגות", value: "behavior" },
        { label: "סיוע דחוף", value: "cats" },
        { label: "הכל - All", value: "all" },
        { label: "כלבים", value: "seal" },
      ];
    case MainOrgZone["6655d5224b6e3b35f15097c0"]:
      return [
        { label: "פורום משותף", value: "internal" },
        { label: "הגנת הדמוקרטיה", value: "emergency" },
        { label: "סיוע לפעילי מחאה", value: "surgery" },
        { label: "תמיכה בחזית", value: "all" },
        { label: "עזרה לוגיסטית", value: "radiology" },
        { label: "תמיכה בעורף", value: "economy" },
      ];
    case MainOrgZone.Vet:
      return [
        { label: "Emergency", value: "emergency" },
        { label: "Surgery", value: "surgery" },
        { label: "Internal", value: "internal" },
        { label: "הכל - All", value: "all" },
        { label: "Radiology", value: "radiology" },
        { label: "Economy", value: "economy" },
        { label: "Dermatology", value: "dermatology" },
      ];
    default:
      return [
        { label: "פורום משותף", value: "general" },
        {
          label: " חירום וביטחון",
          value: "emergency_and_security",
          color: "#EF7300",
        },
        { label: "סביבה ובריאות", value: "environment_and_health" },
        { label: "בעורף ובחזית", value: "in_the_rear_and_front" },
        { label: "תרבות וחינוך", value: "culture_and_education" },
        { label: "עסקים בעלי מקצוע", value: "professionals" },
      ];
  }
};

export const getDefaultCategoryByZone = (zone) => {
  switch (zone) {
    case MainOrgZone.BestFriends:
      return "all";
    case MainOrgZone["6655d5224b6e3b35f15097c0"]:
      return "all";
    case MainOrgZone.Vet:
      return "all";
    default:
      return "general";
  }
};
