import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import styled from "styled-components";
import { TextIcon } from "../../assets/icons";

const Title = styled.span`
  margin-right: 10px;
  color: #1b5c6d;
  direction: ${(props) => props.direction ?? "ltr"};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 8px;
  font-weight: bold;
`;

const ReactQuillWrapper = styled.div`
  background-color: #fff;
  padding: 9px 0;
  position: relative;
  border-radius: 15px;
`;

const ToolbarToggle = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${({ top }) => top};
  left: 7px;
  z-index: 10;
`;

const Size = Quill.import("attributors/style/size");
const sizes = ["10px", "12px", "14px", "16px", "18px", "20px", "24px", "30px"];
Size.whitelist = sizes;
Quill.register(Size, true);

const RichTextEditor = ({
  content,
  setContent,
  label,
  direction,
  children,
}) => {
  const [showToolbar, setShowToolbar] = useState(false);

  const modules = {
    toolbar: showToolbar
      ? [
          ["bold", "italic", "underline"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ direction: "rtl" }],
          [
            {
              size: sizes,
            },
          ],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
        ]
      : false,
  };

  const handleChange = (value) => {
    setContent(value);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(101, 201, 101, 0.15)",
        borderRadius: "17.5px",
        padding: "9px 11px",
      }}
    >
      {label && <Title direction={direction}>{label}</Title>}
      <ReactQuillWrapper>
        <ToolbarToggle
          type="button"
          onClick={() => setShowToolbar(!showToolbar)}
          top={showToolbar ? "60px" : "7px"}
        >
          <TextIcon />
        </ToolbarToggle>
        <ReactQuill
          key={showToolbar ? "toolbar-visible" : "toolbar-hidden"}
          modules={modules}
          value={content}
          onChange={handleChange}
          style={{
            color: "#1B5C6D",
            fontSize: "14px",
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: showToolbar ? "0" : "20px",
          }}
        />
        {children && children}
      </ReactQuillWrapper>
    </div>
  );
};

export default RichTextEditor;
