import React from "react";

const LogoutIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "29"}
      height={height ?? "23"}
      fill="none"
      viewBox="0 0 29 23"
    >
      <g>
        <g>
          <path
            fill={color ?? "#7FB383"}
            d="M11.935 22.5c-.124-.128-.3-.086-.448-.122a3.119 3.119 0 01-2.417-3.005c-.012-.956-.008-1.913-.003-2.87.003-.627.462-1.097 1.05-1.09.589.006 1.033.477 1.035 1.106.003.903-.002 1.805.001 2.708.002.69.43 1.123 1.11 1.123 4.492.002 8.984.002 13.476 0 .692 0 1.11-.42 1.11-1.116.002-5.176.002-10.352 0-15.529 0-.694-.419-1.115-1.111-1.116H12.262c-.683 0-1.107.431-1.109 1.124-.003.902.001 1.804-.001 2.707-.002.627-.45 1.1-1.037 1.106-.593.006-1.045-.456-1.048-1.089-.005-.956-.01-1.913.003-2.87.02-1.6 1.209-2.891 2.802-3.063.05-.005.111.008.128-.064h.065c.075.126.197.061.293.061 4.428.004 8.857.004 13.285 0 .096 0 .218.064.293-.061h.13c.025.089.102.06.16.068a3.125 3.125 0 012.713 2.996c-.003.097-.01.194-.01.292v15.218c0 .14.007.282.01.422a3.131 3.131 0 01-2.427 2.945c-.148.035-.323-.01-.446.119h-.13c-.074-.125-.195-.062-.292-.062-4.43-.003-8.858-.003-13.287 0-.096 0-.217-.063-.292.062h-.13z"
          ></path>
          <path
            fill="#A5A1A2"
            d="M28.942 3.505a.07.07 0 00.058.004v15.925a.07.07 0 00-.058.003c-.003-.14-.01-.282-.01-.422V3.797c0-.097.007-.194.01-.292z"
          ></path>
          <path
            fill="#A5A1A2"
            d="M12.066 22.5c.075-.125.195-.062.292-.062 4.43-.003 8.858-.003 13.287 0 .096 0 .217-.063.292.062h-13.87z"
          ></path>
          <path
            fill="#A5A1A2"
            d="M25.937.438c-.075.124-.196.061-.294.061-4.427.004-8.856.004-13.284 0-.096 0-.218.065-.293-.061h13.871z"
          ></path>
          <path
            fill={color ?? "#7FB383"}
            d="M4.203 10.374h13.343c.174 0 .346 0 .517.047.476.128.783.53.779 1.03-.004.49-.33.9-.811 1a2.395 2.395 0 01-.484.041c-4.33.002-8.66.002-12.991.002h-.36c.003.147.115.193.184.264.849.856 1.708 1.703 2.55 2.565.594.607.378 1.537-.402 1.78-.382.118-.74.04-1.022-.24A864.608 864.608 0 01.81 12.153a1.01 1.01 0 01.002-1.44 693.34 693.34 0 014.696-4.708 1.034 1.034 0 011.465.025c.4.41.402 1.06-.024 1.494-.836.854-1.685 1.695-2.532 2.538-.082.083-.187.141-.282.21l.067.101z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LogoutIcon;
