import React, { useEffect, useRef, forwardRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CardContainer = styled.div`
  width: 312px;
  height: 216px;
  display: flex;
  border-radius: 21.3px;
  cursor: pointer;
  position: relative;
`;

const CardImage = styled.img`
  border-radius: 21.3px;
  width: 100%;
  object-fit: cover;
`;

const Title = styled.div`
  color: ${({ color }) => color ?? "#fff"};
  bottom: 12px;
  right: 14px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.2px;
  letter-spacing: 0.223px;
  text-align: right;
  position: absolute;
`;

const Card = forwardRef((props, ref) => {
  const {
    image,
    coverImages,
    title,
    titleColor,
    cardWidth,
    link,
    index,
    hasHiddenContent,
    isActive,
    handleClick,
    isPublicZone,
    zone,
    handleFocus,
    activeSlide,
  } = props;
  const navigate = useNavigate();

  const targetLink = isPublicZone ? `/${zone}${link}` : link;
  const onClick = () => {
    handleClick && handleClick(index);
    targetLink && navigate(targetLink);
  };

  const cardRef = useRef(null);

  useEffect(() => {
    const checkCardPosition = () => {
      const card = cardRef.current;
      if (card && (activeSlide === 0 ? true : false)) {
        const screenWidth = window.innerWidth < 430 ? window.innerWidth : 430;
        const cardLeft = card.getBoundingClientRect().left;
        const cardCenter = cardLeft + card.offsetWidth / 2;
        const threshold = isActive ? 147 : 129;
        if (
          Math.abs(cardCenter - screenWidth / 2) <= threshold &&
          handleFocus
        ) {
          handleFocus(index);
        }
      }
    };

    // Add an event listener to check the card position when the window is scrolled
    ref.current.addEventListener("scroll", checkCardPosition, true);

    // Call the checkCardPosition function initially
    checkCardPosition();
  }, [ref.current]);

  return (
    <CardContainer
      cardWidth={cardWidth}
      hasHiddenContent={hasHiddenContent}
      isActive={isActive}
      onClick={() => onClick()}
      ref={cardRef}
      id="card"
    >
      <CardImage
        src={coverImages && coverImages.length > 0 ? coverImages[0] : image}
        alt={title}
        isActive={isActive}
      />
      <Title color={titleColor}>{title}</Title>
    </CardContainer>
  );
});

export default Card;
