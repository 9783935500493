import React, { forwardRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import styled from "styled-components";
import useMediaQueryConstants from "../../customHooks/useMediaQueryConstants";
const CardsAndArrowsWrapper = styled.div`
  position: relative;
  width: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen ? "100%" : isMediumScreen ? "96%" : "100%"};
  margin: auto;
`;
const CardSliderWrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "90%" : "100%")};
  margin: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

const SlidingCardsWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: ${({ isWideScreen }) => (isWideScreen ? "30px" : "14px")};
  padding: ${({ isWideScreen }) => (isWideScreen ? "5px 0 " : "5px 15px")};
  width: fit-content;
  transition: transform 0.5s ease;
  transform: ${({ displacementprop }) => `translateX(${displacementprop}px)`};
`;
const ArrowWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: ${({ isLeft }) => (isLeft ? "5px" : "auto")};
  right: ${({ isRight }) => (isRight ? "5px" : "auto")};
  transform: translate(0, -50%);
`;
const CardSliderContainer = forwardRef(({ children, numberOfCards }, ref) => {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const [count, setCount] = useState(0);
  const [displacement, setDisplacement] = useState(0);
  const swipeRight = (cardWidth, gap) => {
    if (count < numberOfCards - 3) {
      setDisplacement((displacement) => displacement - cardWidth - gap);
      setCount(count + 1);
    }
  };
  const swipeLeft = (cardWidth, gap) => {
    if (displacement < 0) {
      setDisplacement((displacement) => displacement + cardWidth + gap);
      setCount(count - 1);
    }
  };
  return (
    <CardsAndArrowsWrapper
      isWideScreen={isWideScreen}
      isMediumScreen={isMediumScreen}
    >
      {(isWideScreen || isMediumScreen) && (
        <ArrowWrapper isLeft onClick={() => swipeLeft(260, 14)}>
          <FaAngleLeft color="#316788" />
        </ArrowWrapper>
      )}
      <CardSliderWrapper
        id="card-slider-container"
        ref={ref}
        isWideScreen={isWideScreen || isMediumScreen}
      >
        <SlidingCardsWrapper
          isWideScreen={isWideScreen || isMediumScreen}
          displacementprop={displacement}
        >
          {children}
        </SlidingCardsWrapper>
      </CardSliderWrapper>
      {(isWideScreen || isMediumScreen) && (
        <ArrowWrapper isRight onClick={() => swipeRight(260, 14)}>
          <FaAngleRight color="#316788" />
        </ArrowWrapper>
      )}
    </CardsAndArrowsWrapper>
  );
});

export default CardSliderContainer;
