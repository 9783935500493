export const ChecksIcon = ({ color, single }) => (
  <svg
    width="33"
    height="18"
    viewBox="0 0 33 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9018 2L9.2923 15.9375L2 8.96875"
      stroke={color ?? "#28B5B5"}
      stroke-width="2.182"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    {!single && (
      <path
        d="M31.9018 2L17.2923 15.9375L10 8.96875"
        stroke={color ?? "#28B5B5"}
        stroke-width="2.182"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    )}
  </svg>
);
