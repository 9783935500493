import { useMutation } from "@tanstack/react-query";
import { handleSendMessage } from "../api";

function useHandleSendMessage() {
  const { mutateAsync: sendMessage, isLoading } = useMutation({
    mutationFn: handleSendMessage,
  });

  return { sendMessage, isLoading };
}

export default useHandleSendMessage;
