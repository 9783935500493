import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${(props) => props.backgroundPopUpDialog ?? "#fafffa"};
  border-radius: ${(props) => props.borderRadius ?? "30px"};
  position: ${(props) => props.position ?? "relative"};
  z-index: 1000;
  padding: ${(props) => props.paddingPopUpDialog ?? "10px"};
  overflow: hidden;
  border: ${(props) => props.border};
  ${(props) => (props.top ? `top: ${props.top};` : "")}
  ${(props) => (props.left ? `left: ${props.left};` : "")}
`;

const PopUpDialog = ({
  paddingPopUpDialog,
  children,
  background,
  backgroundPopUpDialog,
  border,
  borderRadius,
  position,
  top,
  left,
}) => {
  return (
    <Wrapper
      top={top}
      left={left}
      position={position}
      paddingPopUpDialog={paddingPopUpDialog}
      border={border}
      borderRadius={borderRadius}
      backgroundPopUpDialog={backgroundPopUpDialog}
    >
      <div style={{ background: background }}>{children}</div>
    </Wrapper>
  );
};

export default PopUpDialog;
