import React, { useState } from "react";
import styled from "styled-components";
import mockData from "../Mock/mockData";
import Persona from "../../../../kit/Persona/Persona";
import TextInput from "../../../../kit/Input/TextInput";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { APP_MAX_WIDTH } from "../../../Home/constant";

const ShareWrapper = styled.div`
  background-color: #f0f1f5;
  border-radius: 15px 15px 0 0;
  width: 100%;
  max-width: ${APP_MAX_WIDTH}px;
  height: 156px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  left: 0;
`;

const Title = styled.h2`
  font-size: 10px;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  margin-top: 10px;
`;

const DismissButton = styled.div`
  font-size: 12px;
  cursor: pointer;
  padding: 2px 6px;
`;

const ShareListWrapper = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-top: 7.5px;
  padding-right: 20px;
`;

const CardSliderContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const SlidingCardsWrapper = styled.div`
  display: flex;
  gap: 18px;
  padding: 0 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

const ButtonStyles = {
  color: "#fff",
  background: "#007897",
  borderRadius: "9.783px",
  width: "41px",
  fontSize: "8px",
  fontWeight: "700",
  outline: "none",
  cursor: "pointer",
  border: "none",
  height: "22px",
  flex: 1,
  maxWidth: "90px",
};

const SharePost = (props) => {
  const { setShowShareList } = props;

  // List of selected user ids
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const { register, handleSubmit } = useForm();

  const handleSelectionChange = (checked, user) => {
    if (checked) {
      setSelectedUsersList([...new Set([...selectedUsersList, user.userId])]);
      return;
    }
    setSelectedUsersList(
      [...selectedUsersList, user.userId].filter(
        (userId) => userId !== user.userId
      )
    );
  };

  const renderShareList = () => (
    <ShareListWrapper>
      <CardSliderContainer id="card-slider-container">
        <SlidingCardsWrapper>
          {/* TODO: Replace mockData with actual data */}
          {mockData.shareList.map((user) => (
            <Persona
              allowSelection
              imageSrc={user.userImage}
              name={user.userName}
              onSelectionChange={(checked) =>
                handleSelectionChange(checked, user)
              }
            />
          ))}
        </SlidingCardsWrapper>
      </CardSliderContainer>
    </ShareListWrapper>
  );

  const renderFooter = () => (
    <Footer>
      <TextInput
        height="22px"
        border="none"
        borderRadius="15px"
        type="text"
        margin="0 15px 0 0"
        register={register}
        placeholder="Add a message..."
        hookName={"message"}
        fontSize={"10px"}
        flex={2}
        required
      />
      <button style={ButtonStyles}>Send</button>
    </Footer>
  );

  return (
    <ShareWrapper>
      <Header>
        <Title>Share with</Title>
        <DismissButton onClick={() => setShowShareList(false)}>
          {<AiOutlineClose />}
        </DismissButton>
      </Header>
      {renderShareList()}
      {selectedUsersList?.length > 0 && renderFooter()}
    </ShareWrapper>
  );
};

export default SharePost;
