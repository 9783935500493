import React from "react";
import PopUpModal from "../../../../../kit/PopUpModal/PopUpModal";
import styled from "styled-components";
const RelativeContainer = styled.div`
  top: -15px;
  background-color: #fff;
`;
const SectionHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  background: ${(props) => props.background ?? ""};
  color: #53575f;
  font-family: Assistant;
`;
const Container = styled.div`
  direction: rtl;
  background: ${(props) => props.background ?? "rgba(127, 179, 131, 0.2)"};
`;
const Row = styled.div`
  color: var(--Title-dark, #53575f);
  text-align: center;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  padding: ${(props) => props.padding ?? "12px 0"};
  position: relative;
  background: ${(props) => props.background ?? ""};
  border-bottom: 2px solid white;
`;
const Text = styled.p`
  color: #316788;
  text-align: center;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 5px;
`;
const Answer = styled.p`
  margin: 5px;
  color: var(--Title-dark, #53575f);
  text-align: center;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
`;
const Message = styled.p`
  width: 90%;
  margin: 15px auto;
  border-radius: 14.321px;
  border: 2.19px solid #eee;
  background: #fff;
  text-align: right;
  padding: 0 10px;
`;
const VolunteerDetails = ({
  visible,
  hidePopUpfunc,
  details,
  showWaitingListPopFunc,
}) => {
  return (
    <PopUpModal
      visible={visible}
      paddingPopUpDialog={"0"}
      hidePopUpfunc={() => {
        hidePopUpfunc?.();
        showWaitingListPopFunc?.();
      }}
      useNormalDialog
      background={"rgba(127, 179, 131, 0.2)"}
    >
      <RelativeContainer>
        <Container background={"rgba(127, 179, 131, 0.2)"}>
          <SectionHeader marginTop={0}>פרטי מתנדב</SectionHeader>
          <Row>
            <Text>שם מלא - Full Name</Text>
            <Answer>{details?.full_name ?? "-"}</Answer>
          </Row>
          <Row background={"rgba(127, 179, 131, 0.40)"}>
            <Text>סלולרי - Phone Number</Text>
            <Answer>{details?.phone_number ?? "-"}</Answer>
          </Row>
          <Row background={"rgba(127, 179, 131, 0.60)"}>
            <Text>עיר מגורים - City </Text>
            <Answer>{details?.city ?? "-"}</Answer>
          </Row>
          <Row background={"rgba(127, 179, 131, 0.20)"}>
            <Text>הערה - Note</Text>
            <Message>{details?.note ?? "-"}</Message>
          </Row>
        </Container>
      </RelativeContainer>
    </PopUpModal>
  );
};

export default VolunteerDetails;
