import React from "react";
import styled from "styled-components";
import CIcon from "../../../../../assets/icons/Common/Layout/CIcon.svg";
import { Link } from "react-router-dom";
import GreenAppleIcon from "../../../../../assets/icons/Common/Apples/GreenAppleIcon";

const Wrapper = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? "row" : "column")};
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: ${({ justifyContent }) => justifyContent === "end" && "40px"};
`;

const Terms = styled.div`
  color: #1a3987;
  font-family: Assistant;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
  text-decoration-line: underline;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 20px;
  margin-bottom: ${({ isRow }) => (isRow ? "0" : "20px")};
`;
const Copyright = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: #1a3987;
  font-family: Assistant;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
`;
const PrivacyButton = styled(Link)`
  text-decoration: none;
  color: #1a3987;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const VolunteerContainer = styled.div`
  background: ${({ background }) => background ?? "#fafffa"};
  color: #53575f;
  text-decoration-line: underline;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  padding: 15px 0 20px 0;
`;

const AboutUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #53575f;
  cursor: pointer;
  gap: 3px;
`;

const CopyRightAndTerms = ({
  isRow,
  justifyContent,
  hasAdminLogin,
  showVolunteerContainer = true,
  backgroundColor,
}) => {
  return (
    <>
      {showVolunteerContainer && (
        <VolunteerContainer background={backgroundColor}>
          <AboutUsContainer>
            About Us <GreenAppleIcon width={20} height={22} />
          </AboutUsContainer>
          <Link to={"/social"} style={{ color: "#53575f" }}>
            Volunteers Information
          </Link>
        </VolunteerContainer>
      )}
      <Wrapper isRow={isRow} justifyContent={justifyContent}>
        <Terms isRow={isRow}>
          <ButtonRow>
            <PrivacyButton to={"/info/accessibility-statement"}>
              Privacy Policy
            </PrivacyButton>
            {hasAdminLogin ? (
              <PrivacyButton to={"/login"}>Admin Login</PrivacyButton>
            ) : (
              <PrivacyButton to={"/info/accessibility-statement"}>
                Terms & Conditions & Cookies
              </PrivacyButton>
            )}
          </ButtonRow>
          {hasAdminLogin && (
            <PrivacyButton to={"/info/accessibility-statement"}>
              Terms & Conditions & Cookies
            </PrivacyButton>
          )}
        </Terms>
        <Copyright>
          <div>All Rights Reserved </div>
          <img src={CIcon} alt="" width={13} height={18} />
          <div>Together - Ecosystem.com</div>
        </Copyright>
      </Wrapper>
    </>
  );
};

export default CopyRightAndTerms;
