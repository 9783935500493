import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1602_23016)">
        <path
          d="M1.66756 1.74636H2.25132V0.773438H3.22424V1.74636H9.64555V0.773438H10.6185V1.74636H11.2022C12.0069 1.74636 12.6616 2.40104 12.6616 3.20575V11.7675C12.6616 12.5722 12.0069 13.2269 11.2022 13.2269H1.66756C0.862855 13.2269 0.208174 12.5722 0.208174 11.7675V3.20575C0.208174 2.40104 0.862855 1.74636 1.66756 1.74636ZM1.1811 11.7675C1.1811 12.0357 1.39933 12.254 1.66756 12.254H11.2022C11.4705 12.254 11.6887 12.0357 11.6887 11.7675V5.34619H1.1811V11.7675ZM1.1811 4.37326H11.6887V3.20575C11.6887 2.93752 11.4705 2.71929 11.2022 2.71929H10.6185V3.69221H9.64555V2.71929H3.22424V3.69221H2.25132V2.71929H1.66756C1.39933 2.71929 1.1811 2.93752 1.1811 3.20575V4.37326Z"
          fill="#6C899B"
        />
        <path
          d="M9.84006 6.36719H10.813V7.34011H9.84006V6.36719Z"
          fill="#6C899B"
        />
        <path
          d="M7.89426 6.36719H8.86719V7.34011H7.89426V6.36719Z"
          fill="#6C899B"
        />
        <path
          d="M5.94846 6.36719H6.92139V7.34011H5.94846V6.36719Z"
          fill="#6C899B"
        />
        <path
          d="M4.00266 6.36719H4.97559V7.34011H4.00266V6.36719Z"
          fill="#6C899B"
        />
        <path
          d="M2.05637 6.36719H3.0293V7.34011H2.05637V6.36719Z"
          fill="#6C899B"
        />
        <path
          d="M9.84006 8.31348H10.813V9.2864H9.84006V8.31348Z"
          fill="#6C899B"
        />
        <path
          d="M7.89426 8.31348H8.86719V9.2864H7.89426V8.31348Z"
          fill="#6C899B"
        />
        <path
          d="M5.94846 8.31348H6.92139V9.2864H5.94846V8.31348Z"
          fill="#6C899B"
        />
        <path
          d="M4.00266 8.31348H4.97559V9.2864H4.00266V8.31348Z"
          fill="#6C899B"
        />
        <path
          d="M9.84006 10.2588H10.813V11.2317H9.84006V10.2588Z"
          fill="#6C899B"
        />
        <path
          d="M7.89426 10.2588H8.86719V11.2317H7.89426V10.2588Z"
          fill="#6C899B"
        />
        <path
          d="M5.94846 10.2588H6.92139V11.2317H5.94846V10.2588Z"
          fill="#6C899B"
        />
        <path
          d="M4.00266 10.2588H4.97559V11.2317H4.00266V10.2588Z"
          fill="#6C899B"
        />
        <path
          d="M2.05686 8.31348H3.02979V9.2864H2.05686V8.31348Z"
          fill="#6C899B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1602_23016">
          <rect
            width="12.4534"
            height="12.4534"
            fill="white"
            transform="matrix(-1 0 0 1 12.6616 0.773438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
