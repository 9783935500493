import { useMemo } from "react";
import useGetEcosystemByIds from "../../../Ecosystem/View/useGetEcosystemByIds";
import useFetchEcosystems from "../../../../customHooks/useFetchEcosystems";
import { SECTIONS_NAMES } from "../../../Ecosystem/View/constant";

const useFilteredEcosystems = () => {
  const { telAvivEcosystems } = useFetchEcosystems();

  const sectionsWithCommunities = telAvivEcosystems
    .flatMap((activity) => activity.sections || [])
    .filter((section) => section.communities);

  const neighborhoodSections = sectionsWithCommunities.filter(
    (section) => section.type === SECTIONS_NAMES.NEIGHBORHOOD
  );

  const schoolSections = sectionsWithCommunities.filter(
    (section) => section.type === SECTIONS_NAMES.SCHOOL
  );

  const organizationSections = sectionsWithCommunities.filter(
    (section) => section.type === SECTIONS_NAMES.ORGANIZATION
  );

  const neighborhoodCommunities = neighborhoodSections
    ? neighborhoodSections.flatMap((section) => section.communities)
    : [];

  const schoolCommunities = schoolSections
    ? schoolSections.flatMap((section) => section.communities)
    : [];

  const organizationCommunities = organizationSections
    ? organizationSections.flatMap((section) => section.communities)
    : [];

  const communityIds = {
    neighborhood: neighborhoodCommunities,
    school: schoolCommunities,
    organization: organizationCommunities,
  };

  const { data: neighborhoodData, isLoading: isLoadingNeighborhood } =
    useGetEcosystemByIds(communityIds.neighborhood);

  const { data: schoolData, isLoading: isLoadingSchool } = useGetEcosystemByIds(
    communityIds.school
  );

  const { data: organizationData, isLoading: isLoadingOrganization } =
    useGetEcosystemByIds(communityIds.organization);

  const mapToFilteredEcosystems = (data) =>
    data?.map((activity) => ({
      name: activity.title,
      route: `/public/activities/view/${activity._id}`,
      zoneType: activity.zoneType,
      value: activity._id,
    }));

  const filteredCityEcosystems = useMemo(
    () => mapToFilteredEcosystems(telAvivEcosystems),
    [telAvivEcosystems]
  );

  const filteredneighborhoodEcosystems = useMemo(
    () => mapToFilteredEcosystems(neighborhoodData),
    [neighborhoodData]
  );

  const filteredschoolEcosystems = useMemo(
    () => mapToFilteredEcosystems(schoolData),
    [schoolData]
  );

  const filteredorganizationEcosystems = useMemo(
    () => mapToFilteredEcosystems(organizationData),
    [organizationData]
  );

  return {
    telAvivEcosystems,
    isLoadingNeighborhood,
    isLoadingSchool,
    isLoadingOrganization,
    filteredCityEcosystems,
    filteredneighborhoodEcosystems,
    filteredschoolEcosystems,
    filteredorganizationEcosystems,
  };
};

export default useFilteredEcosystems;
