import UploadIcon from "../../assets/icons/Common/Upload/UploadIcon";
import shareSvg from "../../assets/images/Tasks/detailsPage/share.svg";
// import useShortenUrl from "../../customHooks/useShortenUrl";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: ${(props) => props.height ?? "100%"};
  object-fit: contain;
  cursor: pointer;
`;
const Text = styled.p`
  margin: 0;
  color: #003f5d;
`;
const ShareButtonEl = styled.div`
  width: 74px;
  height: 44px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: #316788;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.223px;
`;
const ShareIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DEFAULT_SHARE_WELCOME_MSG =
  "היי, מוזמנים להרשם לאחת מהפעילויות ולקחת חלק מהותי בעזרה הדדית קהילתית";

const ShareButton = ({
  title = "Together Ecosystem\nCommunity Social Network\n",
  description,
  welcomeMessage = DEFAULT_SHARE_WELCOME_MSG,
  showShareButton = false,
  customUrl,
}) => {
  // const { generatedLink, isFetching } = useShortenUrl(window.location.href);

  return (
    <div
      style={{
        width: showShareButton ? "auto" : "24px",
        height: "30px",
        marginRight: showShareButton ? "0" : "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: showShareButton ? "0" : "15px",
        color: "rgba(127, 179, 131, 1)",
      }}
      onClick={async () => {
        const shareData = {
          title: title,
          text: `${description}\n\n${welcomeMessage}\n\n`,
          url: customUrl ?? window.location.href,
        };
        // if (!navigator.canShare || !navigator.canShare(shareData)) {
        //   return;
        // }
        // try {
        navigator?.share?.(shareData);
        // } catch (error) {
        //   console.error("Failed to share, with error", error);
        // }
      }}
    >
      {!showShareButton && (
        <ShareIconWrapper>
          <UploadIcon />
          <Text> שתף</Text>
        </ShareIconWrapper>
      )}
      {showShareButton && <ShareButtonEl>share</ShareButtonEl>}
    </div>
  );
};
export default ShareButton;
