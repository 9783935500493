import React from "react";
import styled, { keyframes } from "styled-components";
import SpinnerImage from "../../../../assets/images/Mock/spinner.png";

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerImageElement = styled.img`
  width: 100px;
  height: 100px;
  margin: 5px 0;
  animation: ${spinAnimation} 1.5s linear infinite;
`;

const SpinnerSvgElement = styled.div`
  width: 100px;
  height: 100px;
  margin: 5px 0;
  animation: ${spinAnimation} 1.5s linear infinite;
  display: inline-block;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const SpinnerText = styled.p`
  color: #4caf50;
  text-align: center;
  font-size: 30.8px;
  font-weight: 600;
  margin: 0;
`;

const Spinner = ({ text, spinnerIcon = SpinnerImage }) => {
  const isSvg = typeof spinnerIcon !== "string";

  return (
    <div>
      {isSvg ? (
        <SpinnerSvgElement>{spinnerIcon}</SpinnerSvgElement>
      ) : (
        <SpinnerImageElement src={spinnerIcon} />
      )}
      {text && <SpinnerText>{text}</SpinnerText>}
    </div>
  );
};

export default Spinner;
