import React from "react";

function Down({ color = "#28B5B5" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21.938 10.125L13.5 18.563l-8.438-8.438"
        ></path>
      </g>
    </svg>
  );
}

export default Down;
