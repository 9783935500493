import { format, formatDistanceToNow } from "date-fns";

export const formatDateTime = (date) => {
  try {
    return format(new Date(date), "MMMM D, yyyy - hh:mm a");
  } catch {
    return date;
  }
};

export const formatDate = (date) => {
  try {
    return format(new Date(date), "MMMM d, yyyy");
  } catch {
    return date;
  }
};

export const formatTime = (date) => {
  try {
    return format(new Date(date), "hh:mm a");
  } catch {
    return date;
  }
};

export const timeAgo = (date) => {
  try {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  } catch {
    return date;
  }
};
