import { CURRENT_DOMAIN } from "../../../info";

export const getPhotoSrc = (srcFromBackendInput) => {
  const srcFromBackend = `${
    srcFromBackendInput?.startsWith?.(
      "http://localhost:3000/http://res.cloudinary.com/"
    )
      ? srcFromBackendInput?.replace?.("http://localhost:3000/", "")
      : srcFromBackendInput
  }`;
  if (!srcFromBackend) return "";

  return srcFromBackend?.startsWith?.("Images/") ||
    srcFromBackend?.startsWith?.("Images\\")
    ? `${CURRENT_DOMAIN}/${srcFromBackend}`
    : srcFromBackend;
};
