import React, { useContext } from "react";
import styled from "styled-components";
import Item from "./Item";
import {
  EMERGENCY_ITEMS,
  HEIGHT_HEADER,
  ITEMS,
  ITEMS2,
  STICKY_ROUTES,
} from "../constants";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../../../store/userContext";
import useCurrentRoute from "../../../../../customHooks/useCurrentRoute";
import { ROUTES as HOME_ROUTES } from "../../Main/constants";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import useRegion from "../../../../../customHooks/useRegion";

const Wrapper = styled.div`
  position: ${({ isWideScreen, isHorizontalNavbar, isSticky }) =>
    isSticky
      ? "fixed"
      : isWideScreen && isHorizontalNavbar
      ? "static"
      : "absolute"};
  bottom: ${({ isSticky }) => (isSticky ? "0" : "auto")};
  left: ${({ isSticky }) => (isSticky ? "0" : "auto")};
  right: ${({ isSticky }) => (isSticky ? "0" : "auto")};
  display: ${({ isWideScreen, isHorizontalNavbar }) =>
    isWideScreen && !isHorizontalNavbar ? "block" : "flex"};
  gap: ${({ isWideScreen, isHorizontalNavbar }) =>
    isWideScreen ? "38px" : "11px"};
  justify-content: space-around;
  align-items: center;
  width: ${({
    isWideScreen,
    isHorizontalNavbar,
    isSticky,
    isAbstractEmergency,
  }) =>
    isAbstractEmergency
      ? "calc(100% - 12px)"
      : isSticky
      ? "100%"
      : isWideScreen && !isHorizontalNavbar
      ? "10%"
      : "calc(100% - 24px)"};
  height: ${({ isWideScreen, isHorizontalNavbar, isSticky }) =>
    isSticky
      ? "62px"
      : isWideScreen && isHorizontalNavbar
      ? "auto"
      : isWideScreen
      ? "422px"
      : "62px"};
  background: ${({
    isWideScreen,
    isHorizontalNavbar,
    isSticky,
    isAbstractEmergency,
  }) =>
    isAbstractEmergency
      ? "#E5EEFF"
      : isSticky
      ? "#fafffa"
      : isWideScreen && !isHorizontalNavbar
      ? "#D7E7D8"
      : isWideScreen
      ? "transparent"
      : "#fafffa"};
  box-shadow: ${({ isWideScreen, isSticky }) =>
    isSticky
      ? "-4px -4px 20px 0px rgba(202, 202, 202, 0.2)"
      : isWideScreen
      ? "none"
      : "-4px -4px 20px 0px rgba(202, 202, 202, 0.2)"};
  border-radius: ${({ isWideScreen, isSticky, isAbstractEmergency }) =>
    isAbstractEmergency ? "0" : isSticky ? "0" : isWideScreen ? "0" : "14px"};
  border: ${({ isWideScreen, isSticky, isAbstractEmergency }) =>
    isAbstractEmergency
      ? "none"
      : isSticky
      ? "0.187px solid rgba(101, 201, 101, 0.89)"
      : isWideScreen
      ? "none"
      : "0.187px solid rgba(101, 201, 101, 0.89)"};
  top: ${({ isWideScreen, isHorizontalNavbar, isSticky }) =>
    isSticky
      ? "auto"
      : isWideScreen && !isHorizontalNavbar
      ? `${HEIGHT_HEADER}px`
      : "auto"};
  margin: ${({ isWideScreen, isSticky, isAbstractEmergency }) =>
    isAbstractEmergency
      ? "0"
      : isSticky
      ? "0"
      : isWideScreen
      ? "auto"
      : "0 5px"};
  max-width: ${({ isWideScreen, isHorizontalNavbar, isSticky }) =>
    isSticky
      ? "100%"
      : isWideScreen && !isHorizontalNavbar
      ? "9.8%"
      : !isWideScreen
      ? `none`
      : "100%"};
  padding: ${({ isWideScreen, isHorizontalNavbar, isSticky }) =>
    isSticky
      ? "6px"
      : isWideScreen && !isHorizontalNavbar
      ? "35px 10px 0"
      : "6px"};
`;

function Navbar(props) {
  const location = useLocation();
  const { isAuthenticated } = useContext(UserContext);
  const currentRoute = useCurrentRoute();
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { isAbstractEmergency } = useRegion();
  const itemsToMap = isAbstractEmergency
    ? EMERGENCY_ITEMS
    : location.pathname === HOME_ROUTES.ROOT
    ? ITEMS
    : ITEMS2;
  const pagesDontHaveHorizontalNavbar = ["card-details"];
  const isHorizontalNavbar = !(
    currentRoute === "/" ||
    pagesDontHaveHorizontalNavbar.some((p) => currentRoute.includes(p))
  );

  const isSticky = STICKY_ROUTES.some((route) => currentRoute.includes(route));

  return (
    isAuthenticated &&
    (currentRoute !== "/" || currentRoute !== "/social") && (
      <Wrapper
        isWideScreen={isWideScreen}
        isMediumScreen={isMediumScreen}
        currentRoute={currentRoute}
        isHorizontalNavbar={isHorizontalNavbar}
        isSticky={isSticky}
        isAbstractEmergency={isAbstractEmergency}
      >
        {itemsToMap.map((item, index) => (
          <Item
            key={item.label}
            color={item.color}
            image={item.image}
            Icon={
              location.pathname !== item.route ? item.Icon : item.FilledIcon
            }
            iconColor={item?.iconColor}
            label={item?.hideLabel && !isWideScreen ? "" : item.label}
            navigateLink={
              isAuthenticated
                ? item.route
                : !item.needAuthentication
                ? item.route
                : null
            }
            isAbstractEmergency={isAbstractEmergency}
          />
        ))}
      </Wrapper>
    )
  );
}

export default Navbar;
