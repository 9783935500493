import React, { useState } from "react";
import Modal from "../../../../../kit/Modal/Modal";
import HorizontalSlider from "../../../../../kit/HorizontalSlider/HorizontalSlider";
import Card from "../../../Zone/Common/Cards/Slider/BigApples/Card";
import AppleSlider from "../../../Zone/Common/Apple";
import ArrowUpAndDownIcons from "../../../../../kit/ArrowUpAndDownIcons/ArrowUpAndDownIcons";
import { Appledata } from "./data.js";
import styled from "styled-components";

import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants.js";
const Wrapper = styled.div`
  margin: 0;
  position: relative;
  background: rgba(235, 246, 248, 0.5);
  padding: 10px 0;
  box-shadow: ${({ isWideScreen }) =>
    isWideScreen ? "none" : "2px 2px 5px 1px rgba(0, 0, 0, 0.15)"};
`;

const BigApplesSlider = ({
  isPersonIconClicked,
  ApplesTitle1,
  ApplesSubTitle,
  titleAsImage,
  isCollapsible,
  collapsedByDefault = false,
}) => {
  const { isWideScreen } = useMediaQueryConstants();
  const [isCollapsed, setIsCollapsed] = useState(collapsedByDefault);
  const [showModal, setShowModal] = useState(false);
  const [targetApple, setTargetApple] = useState(0);
  const closeModal = () => {
    setShowModal(false);
  };
  const handleAppleClick = (index) => {
    setShowModal(true);
    setTargetApple(index);
  };

  return (
    <Wrapper isWideScreen={isWideScreen}>
      {(!isCollapsed || !isCollapsible) && (
        <>
          <AppleSlider
            handleAppleClick={handleAppleClick}
            targetApple={targetApple}
            isPersonIconClicked={isPersonIconClicked}
            ApplesTitle1={ApplesTitle1}
            ApplesSubTitle={ApplesSubTitle}
            titleAsImage={titleAsImage}
          />
          <Modal
            closeModal={closeModal}
            showModal={showModal}
            width={"100%"}
            height="auto"
            inTheCenter={true}
            // top={"110%"}
            top={"50%"}
            // left={"4px"}
            left={"0"}
            // positionType={"absolute"}
            positionType={"fixed"}
            padding={"0"}
          >
            <HorizontalSlider
              data={Appledata}
              cardWidth={98}
              unit="vw"
              gap={0}
              navigation={false}
              currentSlide={targetApple}
              overflowX="hidden"
              closeModal={closeModal}
              handleAppleClick={handleAppleClick}
            >
              <Card />
            </HorizontalSlider>
          </Modal>
        </>
      )}
      {isCollapsible && (
        <ArrowUpAndDownIcons
          status={!isCollapsed}
          changeStatus={() => setIsCollapsed(!isCollapsed)}
        />
      )}
    </Wrapper>
  );
};

export default BigApplesSlider;
