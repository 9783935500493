import styled from "styled-components";
import PopUpModal from "../../../../../kit/PopUpModal/PopUpModal";
import DoubleCheck from "./DoubleGreenCheck.svg";
import EditIcon from "../../../../../assets/icons/Common/WaitingList/EditIcon";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import TrueIcon from "../../../../../assets/icons/Common/WaitingList/TrueIcon";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../../../store/userContext";
import VolunteerDetails from "../VolunteerDetails/VolunteerDetails";
import { updateWaitingListUserStatus } from "../../Activities/Create/api";
import { isAdminInZone } from "../../../../Auth/login/utils";

const SectionHeader = styled.div`
  font-size: 22px;
  font-weight: 700;
  display: flex;
  padding: 10px 0;
  /* margin: ${(props) => props.marginTop ?? 17}px 0 17px 0; */
  justify-content: center;
  background: ${(props) => props.background ?? ""};
  color: #53575f;
  font-family: Assistant;
  text-align: center;
`;

const RelativeContainer = styled.div`
  top: -15px;
  background-color: #fff;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 5px;
  gap: 20px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  width: 85%;
`;

const UserRowName = styled.div`
  direction: rtl;
  width: 60%;
  font-size: 17px;
  font-family: Assistant;
  color: #696d72;
  font-weight: 700;
`;

const UserRowConfirmedIcon = styled.img`
  width: 21px;
  height: 21px;
  margin: 0 25px;
`;

const UserListWrapper = styled.div`
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : "")}
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    background: #1e9898;
    border-radius: 100px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
    border: 0.5px solid #1e9898;
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1e9898;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1e9898;
  }
`;
const Container = styled.div`
  direction: rtl;
  padding: 10px;
  background: ${(props) => props.background ?? "rgba(127, 179, 131, 0.2)"};
`;
const IconsWrapper = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;
const IconWrapper = styled.div``;
const Separator = styled.div`
  height: 2px;
  width: 95%;
  background-color: #7fb383;
`;

const DoubleCaret = () => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999999 1.00009L5.49412 7.02739L1 13.0547"
      stroke="#696D72"
      stroke-width="1.76212"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.11523 1.00009L9.60935 7.02739L5.11523 13.0547"
      stroke="#696D72"
      stroke-width="1.76212"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UserStatus = {
  Accepted: "accepted",
  Rejected: "rejected",
  Pending: "pending",
};

const WaitingList = ({
  visible,
  hidePopUpfunc,
  showPopFunc,
  waitingList,
  refetchWaitingList,
  zone,
  onUserClick,
  isStartWaitingList,
}) => {
  const { currentUser } = useContext(UserContext);

  const [users, setUsers] = useState(waitingList?.users ?? []);
  const [assignedValue, setAssignedValue] = useState(false);
  const [volunteerDetails, setVolunteerDetails] = useState(undefined);
  const isAdmin = isAdminInZone(currentUser, zone);

  useEffect(() => {
    setUsers(waitingList?.users);
    setAssignedValue(true);
  }, [waitingList, assignedValue]);

  const updateUserStatus = async (status, phone_number) => {
    try {
      await updateWaitingListUserStatus({
        status,
        waitingList_id: waitingList._id,
        phone_number,
      });
      refetchWaitingList();
    } catch (error) {
      console.error("Failed to change status", error);
    }
  };

  const getUserRow = useCallback(
    (user, approved) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UserRow
          onClick={() => {
            onUserClick?.(user?.full_name);
          }}
        >
          {isStartWaitingList && <DoubleCaret />}
          <UserRowName>{user?.full_name}</UserRowName>
          <IconsWrapper>
            {!isAdmin && approved && !isStartWaitingList && (
              <UserRowConfirmedIcon src={DoubleCheck} />
            )}
            {!isAdmin && !approved && !isStartWaitingList && (
              <IconWrapper>
                <TrueIcon />
              </IconWrapper>
            )}
            {isAdmin && approved && !isStartWaitingList && (
              <>
                <IconWrapper
                  onClick={() => {
                    hidePopUpfunc?.();
                    setVolunteerDetails(user);
                  }}
                >
                  <EditIcon />
                </IconWrapper>
                <IconWrapper
                  onClick={async () =>
                    await updateUserStatus(
                      UserStatus.Pending,
                      user.phone_number
                    )
                  }
                >
                  <CloseIcon color="red" />
                </IconWrapper>
              </>
            )}
            {isAdmin && !approved && !isStartWaitingList && (
              <>
                <IconWrapper
                  onClick={() => {
                    hidePopUpfunc?.();
                    setVolunteerDetails(user);
                  }}
                >
                  <EditIcon />
                </IconWrapper>
                <IconWrapper
                  onClick={async () =>
                    await updateUserStatus(
                      UserStatus.Rejected,
                      user.phone_number
                    )
                  }
                >
                  <CloseIcon color="red" />
                </IconWrapper>
                <IconWrapper
                  onClick={async () =>
                    await updateUserStatus(
                      UserStatus.Accepted,
                      user.phone_number
                    )
                  }
                >
                  <TrueIcon />
                </IconWrapper>
              </>
            )}
          </IconsWrapper>
        </UserRow>
        {isStartWaitingList && <Separator />}
      </div>
    ),
    [hidePopUpfunc, isAdmin, isStartWaitingList, onUserClick]
  );

  const getRegisteredCount = useMemo(
    () => users?.filter((u) => u.status === UserStatus.Accepted)?.length,
    [users]
  );
  const getInProcessCount = useMemo(
    () => users?.filter((u) => u.status === UserStatus.Pending)?.length,
    [users]
  );
  const getInProcessList = useMemo(
    () =>
      users?.map(
        (u) => u.status === UserStatus.Pending && getUserRow(u, false)
      ),
    [getUserRow, users]
  );
  const getRegisteredList = useMemo(
    () =>
      users?.map(
        (u) => u.status === UserStatus.Accepted && getUserRow(u, true)
      ),
    [getUserRow, users]
  );

  return (
    <>
      <VolunteerDetails
        visible={!!volunteerDetails}
        hidePopUpfunc={() => setVolunteerDetails(undefined)}
        showWaitingListPopFunc={showPopFunc}
        details={volunteerDetails}
      />
      <PopUpModal
        visible={visible}
        paddingPopUpDialog={"0"}
        hidePopUpfunc={() => hidePopUpfunc?.()}
        useNormalDialog
        background={"rgba(127, 179, 131, 0.2)"}
      >
        <RelativeContainer>
          <SectionHeader marginTop={0} background={"rgba(127, 179, 131, 0.2)"}>
            {isStartWaitingList
              ? "בחרו את שמכם להתחלת"
              : "Registered - נרשמו לפעילות"}
            {isStartWaitingList && (
              <>
                <br /> הפעילות / משימה
              </>
            )}
          </SectionHeader>
          <Container background={"#FAFFFA"}>
            <UserListWrapper maxHeight={isStartWaitingList ? undefined : 161}>
              {getRegisteredCount > 0
                ? getRegisteredList
                : "No users registered"}
            </UserListWrapper>
          </Container>
          {!isStartWaitingList && (
            <Container background={"rgba(127, 179, 131, 0.10)"}>
              <SectionHeader>בהרשמה - In Process</SectionHeader>
              <UserListWrapper maxHeight={200}>
                {getInProcessCount > 0
                  ? getInProcessList
                  : "No users in process"}
              </UserListWrapper>
            </Container>
          )}
        </RelativeContainer>
      </PopUpModal>
    </>
  );
};

export default WaitingList;
