import styled from "styled-components";
import useDropdown from "../../customHooks/useDropdown";
import { useRef } from "react";
import useClickOutside from "../../customHooks/useClickOutside";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import ArrowDown from "../../assets/icons/ArrowDown";

const ThreeDotsWrapper = styled.div`
  margin-left: auto;
  padding: ${(props) => props.padding ?? 8}px;
  cursor: pointer;
  position: relative;
  ${(props) => props.extraStyles ?? ""}
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 50;
  left: ${(props) => props.menuLeftOffset ?? 0};
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 2px;
  border-radius: 5px;
  z-index: 999;

  &:not(:last-child)::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ababab;
    margin-left: 8px;
  }
  ${(props) => props.optionsWrapperExtraStyles ?? ""}
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  color: #696d72;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 28px;
  justify-content: center;
  cursor: pointer;
`;

/**
 * options: [
 * {onClick: ()=>{}, text: "option 1"},
 * {onClick: ()=>{}, text: "option 2"}
 * ]
 */

export default function Dropdown({
  options,
  fontSize,
  customIcon,
  wrapperPadding,
  menuLeftOffset,
  extraStyles,
  textComponent,
  optionsWrapperExtraStyles,
}) {
  const optionsRef = useRef(null);
  const { showOptions, setShowOptions } = useDropdown(optionsRef);
  const handleOptionsClick = (e) => {
    e.stopPropagation();
    setShowOptions((prev) => !prev);
  };
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, undefined, () => setShowOptions(false));

  return (
    <ThreeDotsWrapper
      padding={wrapperPadding}
      onClick={handleOptionsClick}
      ref={wrapperRef}
      extraStyles={extraStyles}
    >
      {textComponent && textComponent}
      {customIcon ?? (
        <div
          style={{
            display: "flex",
            fontSize: fontSize ?? "30px",
            position: "relative",
            zIndex: "998",
          }}
        >
          <HiOutlineDotsHorizontal />
        </div>
      )}
      {showOptions && (
        <OptionsWrapper
          ref={optionsRef}
          menuLeftOffset={menuLeftOffset}
          optionsWrapperExtraStyles={optionsWrapperExtraStyles}
        >
          {options?.map((opt) => {
            return <OptionItem onClick={opt?.onClick}>{opt.text}</OptionItem>;
          })}
        </OptionsWrapper>
      )}
    </ThreeDotsWrapper>
  );
}

export const SelectionDropDown = ({ options, selectedOption, placeholder }) => (
  <Dropdown
    options={options}
    textComponent={
      <div>
        {selectedOption && selectedOption !== "" ? selectedOption : placeholder}
      </div>
    }
    optionsWrapperExtraStyles={`
      top: 65px;
      width: 100%;
      border: 2px solid #BCD6E5;
      box-sizing: border-box;
      border-radius: 15px;
    `}
    extraStyles={`
      height: 63px;
      box-sizing: border-box;
      font-size: 16px;
      background-color: transparent;
      padding: 10px 15px;
      color: #6C899B;
      border: 1.5px solid #BCD6E5;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      margin-left: 0;
      flex: 1;
  `}
    customIcon={
      <ArrowDown
        height={"30px"}
        width={"20px"}
        color={"#405E79"}
        strokeWidth={"4"}
      />
    }
  />
);
