import { createContext, useState } from "react";

const defaultValues = {
  selectedItem: null,
  setSelectedItem: () => {},
  expandedSectionId: null,
  setExpandedSectionId: () => {},
};

export const CardDetailsContext = createContext(defaultValues);

export const CardDetailsProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(null); // task/activity/event id
  const [expandedSectionId, setExpandedSectionId] = useState(null); // expanded ArrowUpDownIcons

  return (
    <CardDetailsContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        expandedSectionId,
        setExpandedSectionId,
      }}
    >
      {children}
    </CardDetailsContext.Provider>
  );
};
