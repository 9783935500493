import React, { useContext, useState } from "react";
import styled from "styled-components";
import Slider from "./components/Slider";
import BlueIIcon from "../../../../../assets/icons/BlueIIcon";
import GreenAppleIcon from "../../../../../assets/icons/Common/Apples/GreenAppleIcon";
import JewishArgency from "../../../../../assets/images/Activities/Common/Apples Slider/jewish argency.png";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../../../../Common/Layout/Header/constant";
import { useLocation } from "react-router-dom";
import { ApplesSubTitleInformation, ApplesTitleInformation } from "./constants";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";

const Wrapper = styled.div`
  margin: 6px 11px 0;
`;
const SliderWrapper = styled.div`
  position: relative;
`;
const ApplesTitle = styled.h2`
  width: 96%;
  display: flex;
  justify-content: ${({ isWideScreen }) =>
    isWideScreen ? "center" : "center"};

  color: #316788;
  text-align: center;
  font-weight: 600;
  margin-left: 10px;
  margin: 0;
  gap: 5px;
`;
const EnTitle = styled.h3`
  margin: 0;
  color: #003f5d;
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 17px;
  font-weight: 600;
  text-decoration-line: none;
  margin: 7px 0;
  @media (max-width: 385px) {
    font-size: 14px;
  }
`;
const HebTitle = styled.h3`
  margin: 0;
  color: #5f8362;
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 17px;
  font-weight: 600;
  text-decoration-line: none;
  margin: 7px 0;
  @media (max-width: 385px) {
    font-size: 14px;
  }
`;
const TitlesAndAppleWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;
const AppleWrapper = styled.div`
  width: 100px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8.985px;
  background: #fff;
  box-shadow: 2.25806px 2.25806px 5.64516px 1.12903px rgba(0, 0, 0, 0.3);
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  width: 55px;
  height: 55px;
  height: 60px;
  width: 45.708px;
  height: 50.189px;
  position: relative;
`;
const TextWrapper = styled.div`
  border-radius: 4.714px;
  text-align: center;
  display: flex;
  gap: 1px;
  justify-content: center;
  align-items: center;
  width: 80px;
`;
const Text = styled.div`
  color: #316788;
  text-align: center;
  font-size: ${(props) => (props.isActive ? "17.524px" : "15.4px")};
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
function Page({
  targetApple,
  handleAppleClick,
  isPersonIconClicked,
  ApplesTitle1,
  ApplesSubTitle,
  titleAsImage,
}) {
  const { isWideScreen } = useMediaQueryConstants();
  const [activeSlide, setActiveSlide] = useState(100);
  const [isTitleAppleVisable, setIsTitleAppleVisable] = useState(false);
  const { currentLanguage } = useContext(LanguageContext);
  const { pathname } = useLocation();
  const getAppleText = () => {
    if (currentLanguage === LANGUAGES.HEBREW.value) return " איך להתנדב ";
    else return "Volunteering";
  };
  const getApplesTitle = () => {
    return ApplesTitleInformation["default"][currentLanguage];
  };
  const getApplesSubTitle = () => {
    if (ApplesSubTitle) return ApplesSubTitle;
    else return ApplesSubTitleInformation["default"][currentLanguage];
  };
  const handleClick = (index) => {
    setActiveSlide(index);
  };
  const showApplesSlider = () => {
    setIsTitleAppleVisable(!isTitleAppleVisable);
  };

  const appleData = ["להתנדב", "להצטרף", "משימות", "פעילויות", "פורום"];

  return (
    <Wrapper>
      <TitlesAndAppleWrapper>
        {isTitleAppleVisable && (
          <AppleWrapper onClick={showApplesSlider}>
            <IconWrapper>
              <GreenAppleIcon />
            </IconWrapper>
            <TextWrapper>
              <Text>{getAppleText()}</Text>
            </TextWrapper>
          </AppleWrapper>
        )}
        <ApplesTitle onClick={showApplesSlider} isWideScreen={isWideScreen}>
          {/* {titleAsImage && <Image src={JewishArgency} />} */}
          <EnTitle>{"Doing what matters"}</EnTitle>
          <HebTitle>{getApplesTitle()}</HebTitle>
        </ApplesTitle>
      </TitlesAndAppleWrapper>
      {!isTitleAppleVisable && (
        <SliderWrapper>
          <Slider
            data={appleData}
            handleAppleClick={handleAppleClick}
            handleClick={handleClick}
            activeSlide={activeSlide}
            targetApple={targetApple}
            isPersonIconClicked={isPersonIconClicked}
          />
        </SliderWrapper>
      )}
    </Wrapper>
  );
}

export default Page;
