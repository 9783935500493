import { useQuery } from "@tanstack/react-query";
import { getEcosystemById } from "../domain/Ecosystem/Zone/Activities/Create/api";
import { useMemo } from "react";

function useFetchEcosystemById(id, config = {}) {
  const { isLoading, data } = useQuery({
    queryKey: ["ecosystem", id],
    queryFn: () => getEcosystemById(id),
    ...config,
  });

  const ecosystem = useMemo(() => data?.data, [data]);

  return { data, isLoading, ecosystem };
}

export default useFetchEcosystemById;
