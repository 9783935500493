import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TextArea from "../../../../kit/Input/TextArea";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import { VoiceContext } from "./Voice";
import MicrophoneIcon from "../../../../assets/icons/MicrophoneIcon";
import speakIcon from "../../../../assets/icons/speakIcon.png";
import { ArrowDown } from "../../../../assets/icons";

const Wrapper = styled.div`
  cursor: pointer;
  border-radius: 10.72px;
  background: rgba(56, 161, 188, 0.1);
  padding: ${(props) => props.padding ?? "5px 8px 10px 8px"};
  position: relative;
  box-shadow: ${(props) =>
    props.boxShadow ?? "5px 2px 5px 0px rgba(0, 0, 0, 0.25)"};
  width: ${(props) => props.width ?? "calc(100% - 16px)"};
  margin: ${(props) => props.margin ?? "0 10px"};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Title = styled.div`
  color: var(--preview-txt, #105f84);
  font-family: "NotoSans-600";
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
`;

const MicrophoneRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px 0 15px;
  color: var(--preview-txt, #105f84);
  font-family: "NotoSans-600";
  font-size: 20px;
  text-align: center;
`;

const MicrophoneContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

function OverlayInput({
  title = "Message  הודעה ",
  register,
  setValue,
  isReadyToSend,
  placeholder,
  inputHeight,
  showMicrophone = true,
  micOnClick,
  showBottomMic,
  isVoiceRecording,
  showArrow,
  onArrowClick,
  width,
  margin,
  hasVoiceRecording,
  micBottomVal,
  usePlainDesign,
  useSpeakIcon,
  focusOnLoad,
  micBackgroundColor,
  micBorder,
}) {
  const {
    transcript: voiceMessage,
    timeSpentTalking,
    listening,
    StartRecord,
    StopRecord,
  } = useContext(VoiceContext);

  const [error, setError] = useState(null);

  useEffect(() => {}, []);

  useEffect(() => {
    if (voiceMessage) {
      setValue("message", voiceMessage);
      setValue("timeSpentTalking", timeSpentTalking);
    }
  }, [setValue, timeSpentTalking, voiceMessage]);

  const onStartRecord = async () => {
    try {
      // Request access to the microphone
      await navigator.mediaDevices.getUserMedia({ audio: true });
      // Start the recording if access is granted
      StartRecord();
    } catch (err) {
      // If access is denied, show an error message
      setError("Microphone access is denied. Please allow microphone access.");
    }
  };

  const renderMicrophone = (
    bgColor,
    absolute,
    shouldUseSpeakIcon,
    onClickOverride,
    bottom = "45px",
    border
  ) => (
    <div
      style={{
        background:
          (listening && !onClickOverride) ||
          (isVoiceRecording && onClickOverride)
            ? "#FFDBDD"
            : hasVoiceRecording && onClickOverride
            ? "#67000099"
            : bgColor ?? "rgba(56, 161, 188, 0.1)",
        borderRadius: "5px",
        padding: "5px",
        cursor: "pointer",
        position: absolute ? "absolute" : "static",
        bottom: bottom,
        zIndex: "999",
        left: "16px",
        width: "40px",
        height: "43px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        boxShadow:
          "3.552420139312744px 1.4209681749343872px 3.552420139312744px 0px rgba(0, 0, 0, 0.25)",
        border: border,
      }}
      onClick={
        onClickOverride ??
        (() => {
          if (listening) {
            StopRecord?.();
            return;
          }
          onStartRecord();
        })
      }
    >
      {useSpeakIcon || shouldUseSpeakIcon ? (
        <img src={speakIcon} width={30} height={30} alt={"speak"} />
      ) : (
        <MicrophoneIcon
          color={isReadyToSend ? "#7FB383" : "#003F5D"}
          width={30}
          height={30}
        />
      )}
    </div>
  );

  return (
    <Wrapper
      width={width}
      margin={margin}
      boxShadow={usePlainDesign && "none"}
      padding={usePlainDesign && "14px 12px 22px 12px"}
    >
      <Title>
        {!usePlainDesign && title}
        {showMicrophone &&
          !showBottomMic &&
          renderMicrophone(
            micBackgroundColor,
            true,
            false,
            micOnClick,
            micBottomVal ?? "20px",
            micBorder
          )}
      </Title>
      {error && (
        <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>
      )}
      <TextArea
        focusOnLoad={focusOnLoad}
        width={"100%"}
        paddingLeft={"0"}
        paddingRight={"0"}
        margin={"0"}
        register={register}
        hookName={"message"}
        minHeight={inputHeight ?? "141px"}
        placeholder={usePlainDesign ? "" : placeholder ?? ":תוכן הודעה"}
        lan={LANGUAGES.HEBREW.key}
        additionalStyling={`
         ${
           usePlainDesign
             ? ""
             : "box-shadow: 1.52px 1.52px 3.81px 0px rgba(0, 0, 0, 0.25);"
         }
          border-radius: 8px; 
          overflow: hidden;
        `}
        inputExtraStyles={`
            font-family: "NotoSans-600";
            padding: 6px 15px;
        `}
        defaultValue={voiceMessage}
      />
      {showBottomMic && (
        <MicrophoneRow>
          <MicrophoneContainer>
            {renderMicrophone("#fff", false, false, micOnClick)}
            הקליטו
          </MicrophoneContainer>
          <MicrophoneContainer>
            {renderMicrophone("#fff", false, true)}
            תמללו
          </MicrophoneContainer>
        </MicrophoneRow>
      )}
      {showArrow && (
        <div
          style={{
            width: "100%",
            paddingTop: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onArrowClick}
        >
          <ArrowDown width={15} color={"#316788"} rotate={true} />
        </div>
      )}
    </Wrapper>
  );
}

export default OverlayInput;
