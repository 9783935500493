import { useEffect } from "react";

const useStopBodyScroll = (popUpVisible) => {
  useEffect(() => {
    const body = document.body;
    if (popUpVisible) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }

    return () => {
      body.style.overflow = "visible";
    };
  }, [popUpVisible]);
};

export default useStopBodyScroll;
