import React, { useContext, useState } from "react";
import styled from "styled-components";
import CustomRadioButton from "../../../../../Ecosystem/Zone/Common/Transportation/components/CustomRadioButton/CustomRadioButton";
import { FONTS } from "../../constant";
import { FontContext } from "../../../../../../store/fontContext";

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;
const Col = styled.div``;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Text = styled.p`
  margin: 0;
  color: #003f5d;
  text-align: left;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-top: 5px;
`;
const FontsContainer = () => {
  const { currentFont, setCurrentFont } = useContext(FontContext);
  const radioButtons = [
    {
      name: FONTS.REGULAR.value,
      checked: currentFont === FONTS.REGULAR.value,
    },
    {
      name: FONTS.BOLD.value,
      checked: currentFont === FONTS.BOLD.value,
    },
  ];
  const [radionButtons, setRadioButtons] = useState(radioButtons);
  const handleRadioChange = (e) => {
    const name = e.target.name;
    setCurrentFont(name);
    setRadioButtons(
      [...radionButtons].map((r) => {
        if (r.name === name) return { ...r, checked: true };
        else return { ...r, checked: false };
      })
    );
  };
  return (
    <Wrapper>
      <Col>
        <IconWrapper>
          <CustomRadioButton
            name={FONTS.REGULAR.value}
            checked={radionButtons[0].checked}
            onChange={handleRadioChange}
            label=""
            outCircleWidth={"20px"}
            innerCircleWidth={"14px"}
          />
        </IconWrapper>

        <Text>{FONTS.REGULAR.name}</Text>
      </Col>
      <Col>
        <IconWrapper>
          <CustomRadioButton
            name={FONTS.BOLD.value}
            checked={radionButtons[1].checked}
            onChange={handleRadioChange}
            label=""
            outCircleWidth={"20px"}
            innerCircleWidth={"14px"}
          />
        </IconWrapper>

        <Text>{FONTS.BOLD.name}</Text>
      </Col>
    </Wrapper>
  );
};

export default FontsContainer;
