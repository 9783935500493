export const ROUTES = {
  LANDING: "/",
  VOLUNTEERS_AND_SPEND_TIME: "/volunteers-and-spend-time",
  JEWISH_VAST: "/jewish-vast",
  JEWISH_EMERGENCY: "/jewish-emergency",
  GDEROT: "/gderot",
  MATNASIM: "/matnasim",
  HATZOFIM: "/hatzofim",
  SOCIAL: "/social",
  TIME_REWARD_SYSTEM: "/time-reward-system",
};
