import { useState, useContext } from "react";
import styled from "styled-components";
import SivanImage from "../../../../assets/images/Mock/User/SivanImage.png";
import TomImage from "../../../../assets/images/Mock/User/tom-cruise.png";
import { UserContext } from "../../../../store/userContext";
import { deleteCommentById } from "../../Board/api";

const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  border-radius: 12px;
  width: calc(100% - 74px);
  background-color: #fff;
  position: relative;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 5px;
`;

const UserName = styled.span`
  color: #007897;
  font-size: 10px;
  font-weight: 700;
`;

const PostText = styled.p`
  color: #696d72;
  font-size: 10px;
  font-weight: 600;
  text-align: start;
  margin-left: 50px;
  margin-top: 0;
`;

const PostActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left; /* Align items to the right */
  width: 100%; /* Make the container occupy the full width */

  color: #4caf50;
  font-size: 10px;
  font-weight: 700;
  margin-left: 50px;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;

const Post = ({ comment, refetchComments }) => {
  const [like, setLike] = useState(false);
  const { currentUser } = useContext(UserContext);

  const onDelete = async () => {
    try {
      await deleteCommentById(comment?._id);
      refetchComments?.();
    } catch (ex) {
      console.error(`Failed to delete comment with error: ${ex}`);
    }
  };

  return (
    <PostWrapper>
      <UserInfoWrapper>
        <UserImage
          src={comment?.gender === "female" ? SivanImage : TomImage}
          alt="User"
        />
        <UserInfo>
          <UserName>{comment?.user_name ?? ""}</UserName>
        </UserInfo>
      </UserInfoWrapper>
      <PostText>{comment?.body ?? ""}</PostText>
      <PostActionsWrapper>
        <IconsWrapper>Like</IconsWrapper>
        <IconsWrapper>Translate</IconsWrapper>
        <IconsWrapper onClick={onDelete}>Delete</IconsWrapper>
      </PostActionsWrapper>
    </PostWrapper>
  );
};

export default Post;
