import NoteBookIcon from "../../../../assets/icons/Common/NavBar/NoteBookIcon";
import ShareButtonIcon from "../../../../assets/icons/Common/NavBar/ShareButtonIcon";
import PersonIcon from "../../../../assets/icons/PersonIcon";
import OrangeAppleIcon from "../../../../assets/icons/Common/NavBar/OrangeAppleIcon";
import DownloadIcon from "../../../../assets/icons/Common/NavBar/DownloadIcon";
import FontIcon from "../../../../assets/icons/Common/NavBar/FontsIcon";
import SoundIcon from "../../../../assets/icons/Common/NavBar/SoundIcon";
import NavbarTogglerIcon from "../../../../assets/icons/NavbarTogglerIcon";
import EarthIcon from "../../../../assets/icons/EarthIcon";
import LanguagesContainer from "./components/LanguagesContainer/LanguagesContainer";
import SoundsContainer from "./components/SoundsContainer/SoundsContainer";
import IconsContainer from "./components/IconsContainer/IconsContainer";
import BookIcon from "../../../../assets/icons/Common/NavBar/BookIcon";
import MessageIcon from "../../../../assets/icons/Common/NavBar/MessageIcon";
import FontsContainer from "./components/FontsContainer/FontsContainer";
import LocationIcon from "../../../../assets/icons/LocationIcon";
import LocationContainer from "./components/LocationContainer/LocationContainer";
import { getCurrentShareData } from "./components/NavBar/utils";
import { SettingsIcon } from "../../../../assets/icons/Common/NavBar";

export const SHARE_DATA = {
  [`${window.location.origin}/`]: {
    title: "Together Ecosystem\nCommunity Social Network\n",
    text: "הי, מוזמנים להצטרף אלינו\n\nהשתתפות בפעילויות ומשימות Together ערכית וחשובה\n\nההשתתפות מקדמת את גלגל ההתנדבות והמוטיבציה לעזרה הדדית בקהילה ומחוצה לה.\n\n",
  },
};

export const DEFAULT_SHARE_DATA = {
  title: "Together Ecosystem\nCommunity Social Network\n",
  text: `"היי, מוזמנים להרשם לאחת מהפעילויות ולקחת חלק מהותי בעזרה הדדית קהילתית"\n\n`,
};

export const ENGLISH_SHARE_DATA = {
  title: "Together Ecosystem\nCommunity Social Network\n",
  text: "Hi there, welcome to join us\n\nParticipation in Together activities and tasks is valuable and important.\n\nYour participation promotes the wheel of volunteering and motivation for mutual help in your community and beyond.\n\n",
};

export const TOP_SIDE = [
  {
    id: Math.random(),
    text: "תפריט - Menu ",
    fontSize: "24px",
    padding: "15px 0 5px 0",
    top: "60%",
    icon: <NavbarTogglerIcon width={"30"} height={"30"} color={"#fff"} />,
  },
  {
    id: "share",
    text: "שתף - Share",
    hasDivider: true,
    icon: <ShareButtonIcon color={"#7FB383"} />,
    subTap: <IconsContainer />,
    action: (currentRoute) => {
      const shareData = getCurrentShareData(currentRoute);
      if (navigator.canShare && navigator.canShare(shareData)) {
        try {
          navigator?.share(shareData);
        } catch (error) {
          console.error("Failed to share, with error", error);
        }
      }
    },
  },
  {
    id: "download_pwa",
    text: "הורד למסך הבית",
    hasDivider: true,
    icon: <DownloadIcon color={"#7FB383"} />,
    action: (installPrompt) => installPrompt.prompt(),
  },
  {
    id: Math.random(),
    text: "הרשמה לקהילות",
    hasDivider: true,
    icon: <BookIcon color={"#7FB383"} />,
    messages: [
      {
        message: "מתנדבים וחברי קהילה",
        link: "/activities/register-for-volunteerWork",
      },
    ],
  },
  {
    id: Math.random(),
    text: "פניה לאדמין ",
    hasDivider: true,
    icon: <MessageIcon color={"#7FB383"} />,
    messages: [
      {
        message: "תורמים ונותני חסות",
        link: "/activities/registration-for-Sponsors",
      },
      {
        message: "פניות אחרות",
        link: null,
      },
    ],
  },
  {
    id: Math.random(),
    text: "תקנון - Terms",
    hasDivider: true,
    icon: <NoteBookIcon color={"#7FB383"} />,
  },
  {
    id: Math.random(),
    text: "אודות - About",
    icon: <OrangeAppleIcon color={"#7FB383"} />,
  },
];

export const BOTTOM_SIDE = [
  {
    id: Math.random(),
    text: `הגדרות - Settings`,
    fontSize: "22px",
    color: "#fff",
    icon: <SettingsIcon />,
  },
  {
    id: Math.random(),
    hasDivider: true,
    text: "גודל פונט - Font Size",
    icon: <FontIcon color={"#fff"} />,
    subTap: <FontsContainer />,
  },
  {
    id: Math.random(),
    text: "שפה - Language",
    hasDivider: true,
    icon: <EarthIcon color={"#fff"} />,
    subTap: <LanguagesContainer />,
  },
  {
    id: Math.random(),
    text: "מיקום - Location",
    hasDivider: true,
    icon: <LocationIcon width={"25"} height={"28"} color={"#fff"} />,
    subTap: <LocationContainer />,
  },
  {
    id: Math.random(),
    text: "קול - Voice",
    icon: <SoundIcon color={"#fff"} />,
    // subTap: <SoundsContainer />,
    action: () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function (stream) {
            console.log("Microphone permission granted");
            const tracks = stream.getTracks();
            tracks.forEach(function (track) {
              track.stop();
            });
          })
          .catch(function (error) {
            console.error("Error accessing microphone:", error);
          });
      } else {
        console.error("getUserMedia is not supported in this browser");
      }
    },
  },
];

export const LANGUAGES = {
  ENGLISH: {
    key: "en",
    name: "English",
    value: "english",
  },
  HEBREW: {
    key: "עב",
    name: "עברית",
    value: "hebrew",
  },
  BOTH: {
    key: "both",
    name: "EN / עב",
    value: "both",
  },
};

export const FONTS = {
  REGULAR: {
    name: "Regular",
    value: "normal",
  },
  BOLD: {
    name: "Bold",
    value: "bold",
  },
};
