import { useMediaQuery } from "react-responsive";

function useMediaQueryConstants() {
  const isWideScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isFullHD = useMediaQuery({ minWidth: 1900 });
  return { isWideScreen, isMediumScreen, isFullHD };
}

export default useMediaQueryConstants;
