import React from "react";

function LockIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={38}
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 23.75a2.969 2.969 0 1 0 0-5.938 2.969 2.969 0 0 0 0 5.938ZM19 23.75v3.563"
      />
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M30.875 13.063H7.125c-.656 0-1.188.531-1.188 1.187v16.625c0 .656.532 1.188 1.188 1.188h23.75c.656 0 1.188-.532 1.188-1.188V14.25c0-.656-.532-1.188-1.188-1.188Z"
      />
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13.656 13.063V7.718a5.344 5.344 0 0 1 10.688 0v5.343"
      />
    </svg>
  );
}

export default LockIcon;
