import { LANDING_ROUTES, ROUTES as MAIN_ROUTES } from "../Main/constants";
import { ROUTES as ECOSYSTEM_ROUTES } from "../../../Ecosystem/constant.js";
import { ROUTES as CITY_ROUTES } from "../../../Ecosystem/Zone/City/constant";
import { ROUTES as STUDENT_ROUTES } from "../../../Ecosystem/Zone/Student/constant.js";
import { ROUTES as SCHOOL_ROUTES } from "../../../Ecosystem/Zone/School/constant";
import { ROUTES as ORGANIZATION_ROUTES } from "../../../Ecosystem/Zone/Organization/constant";
import { ROUTES as VOLUNTEER_BENEFITS_ROUTES } from "../../../Ecosystem/Zone/VolunteerBenefits/constant";
import { ROUTES as EMERGENCY_ROUTES } from "../../../Emergency/constant.js";

const createRoutes = (prefix, routes) =>
  Object.values(routes).map((route) => `${prefix}/${route}`);

export const NO_GOING_BACK_ROUTES = [
  MAIN_ROUTES.ROOT,
  `/${ECOSYSTEM_ROUTES.EMERGENCY}`,
  `/${ECOSYSTEM_ROUTES.EMERGENCY}/matnas`,
  `/${ECOSYSTEM_ROUTES.EMERGENCY}/student`,
  `/${ECOSYSTEM_ROUTES.EMERGENCY}/jfk`,
  `/resetPassword`,
  `/${ECOSYSTEM_ROUTES.EMERGENCY}/private`,
  ...createRoutes(`/${ECOSYSTEM_ROUTES.EMERGENCY}`, EMERGENCY_ROUTES),
  ...createRoutes(`/${ECOSYSTEM_ROUTES.EMERGENCY}/private`, EMERGENCY_ROUTES),
];

export const NO_GOING_BACK_ENDS_WITH_ROUTES = [
  `${ECOSYSTEM_ROUTES.CITY}`,
  // `${ECOSYSTEM_ROUTES.STUDENT}`,
  `${ECOSYSTEM_ROUTES.SCHOOL}`,
  // `${ECOSYSTEM_ROUTES.ORGANIZATION}`,
  `${ECOSYSTEM_ROUTES.VOLUNTEER_BENEFITS}`,
  "login/private-ecosystem",
  ...createRoutes(`${ECOSYSTEM_ROUTES.CITY}`, CITY_ROUTES),
  ...createRoutes(`${ECOSYSTEM_ROUTES.STUDENT}`, STUDENT_ROUTES),
  ...createRoutes(`${ECOSYSTEM_ROUTES.SCHOOL}`, SCHOOL_ROUTES),
  ...createRoutes(`${ECOSYSTEM_ROUTES.ORGANIZATION}`, ORGANIZATION_ROUTES),
  ...createRoutes(
    `${ECOSYSTEM_ROUTES.VOLUNTEER_BENEFITS}`,
    VOLUNTEER_BENEFITS_ROUTES
  ),
];

export const NO_GOING_BACK_INCLUDES_ROUTES = [
  "card-details",
  "/contacts",
  "/OverlayEmergencyMap",
  "/panic-status",
  "/view",
  "login",
  `${CITY_ROUTES.IMAOT_RAMAT_AVIV}`,
  `${CITY_ROUTES.IMAOT_YAD_ELIHAO}`,
];

export const SHOULD_GO_BACK_ENDS_WITH = [];
