import React, { useRef } from "react";
import "./DatePickerInput.style.css";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import timeIcon from "../../../assets/icons/timeIcon.png";
import { styled } from "styled-components";

const Wrapper = styled.div`
  border: 1.25px solid #c9d7e0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 26px;
  position: relative;
  gap: 5px;
  width: 139px;
  background: #fff;
  ${(props) => props.extraStyles ?? ""}
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateInput = styled.input`
  border: none;
  outline: none;
  color: #6c899b;
  width: 100%;
  height: 100%;
  background: transparent;
  ${(props) => props.inputExtraStyles ?? ""}
`;

const TimeIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const PICKER_TYPES = {
  DATE: "date",
  TIME: "time",
};

const DatePickerInput = ({
  selectedDate,
  setSelectedDate,
  type = PICKER_TYPES.DATE,
  required,
  disabled,
  extraStyles,
  inputExtraStyles,
}) => {
  const pickerRef = useRef(null);

  return (
    <Wrapper
      className={"DateWrapper"}
      onClick={() => pickerRef?.current?.showPicker?.()}
      extraStyles={extraStyles}
    >
      <IconWrapper>
        {type === PICKER_TYPES.DATE ? (
          <CalendarIcon />
        ) : (
          <TimeIcon src={timeIcon} alt="time icon" />
        )}
      </IconWrapper>
      <DateInput
        type={type}
        ref={pickerRef}
        value={selectedDate}
        onChange={(e) => setSelectedDate?.(e?.target?.value)}
        required={required}
        disabled={disabled}
        inputExtraStyles={inputExtraStyles}
      />
    </Wrapper>
  );
};

export default DatePickerInput;
