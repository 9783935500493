import { TaskZone } from "../../customHooks/useFetchTasks";
import { DataType } from "../Ecosystem/Zone/Common/CardDetailsPage/CardDetailsPage";

export const filterTasksByCities = (tasks) =>
  tasks?.filter?.(
    (ev) =>
      ev.zone === TaskZone.TelAvivCity ||
      ev.zone === TaskZone.GderotCity ||
      ev.zone === TaskZone.NogaCity ||
      ev.zone === TaskZone.AlmogCity ||
      ev.zone === TaskZone.TaglitCity ||
      ev.zone === TaskZone.ScoutsCity
  );

export const isLiked = (entity, userId) =>
  !!entity?.likes?.find?.((user) => user === userId);

export const EntityType = {
  Activities: "activities",
  Events: "events",
  Tasks: "tasks",
};

export const getEntityType = (dataType) => {
  const typeMap = {
    [DataType.Activity]: EntityType.Activities,
    [DataType.Event]: EntityType.Events,
    [DataType.Task]: EntityType.Tasks,
  };
  return typeMap[dataType];
};
