import React from "react";
import styled from "styled-components";
import GrayArrowDwon from "../../assets/icons/GrayArrowDwon";
import GrayArrowUpIcon from "../../assets/icons/GrayArrowUpIcon";
const ArrowDownIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.padding ?? "20px 0 0 0"};
  cursor: pointer;
`;
const ArrowUpAndDownIcons = ({ status, changeStatus, arrowColor, padding }) => {
  return (
    <ArrowDownIconWrapper onClick={changeStatus} padding={padding}>
      {status ? (
        <GrayArrowUpIcon color={arrowColor} />
      ) : (
        <GrayArrowDwon color={arrowColor} />
      )}
    </ArrowDownIconWrapper>
  );
};

export default ArrowUpAndDownIcons;
