import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Wrapper = styled.div`
  padding: ${(props) => props.padding ?? "7px"};
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled(Link)`
  width: ${(props) => props.width ?? "163.72px"};
  height: ${(props) => props.height ?? "92px"};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent ?? "center"};
  gap: ${(props) => props.gap ?? "0"};
  align-items: center;
  padding: 10px 0px;
  text-decoration: none;
  border-radius: 16.693px;
  background: ${(props) => props.background ?? "rgba(127, 179, 131, 0.1)"};
  box-shadow: 1.669px 1.669px 4.173px 0.835px rgba(0, 0, 0, 0.3);
  /* padding-top: 10px; */
`;
const SubTitle = styled.h3`
  margin: 0;
  color: ${(props) => props.color ?? "#ef7300"};
  font-family: "NotoSans-600";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
`;
const ToLink = styled.a`
  color: ${(props) => props.color ?? "#316788"};
  font-family: "NotoSans-600";
  font-size: ${(props) => props.fontSize ?? "16px"};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: ${(props) => (props.hideUnderLine ? "none" : "underline")};
`;
const IconWrapper = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
`;
const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 80%; */
  flex: 1;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
`;
const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 20%; */
  flex: 1;
  justify-content: center;
  align-items: center;
  /* border: 1px solid yellow; */
`;
const Image = styled.img`
  padding: 0;
  margin: 0;
`;
const StyledButton = ({
  width,
  height,
  subTitle,
  subTitleColor,
  icon,
  image,
  linkTitle,
  linkTitle1,
  linkTitleColor,
  linkTitleFontSize,
  gap,
  justifyContent,
  to,
  wrapperBackground,
  wrapperMargin,
  onClick,
  wrapperHeight,
}) => {
  return (
    <Wrapper
      padding={wrapperMargin}
      height={wrapperHeight}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick?.();
        }
      }}
    >
      <ButtonWrapper
        to={to}
        gap={gap}
        justifyContent={justifyContent}
        width={width}
        height={height}
        background={wrapperBackground}
      >
        <BoxWrapper>
          {subTitle && <SubTitle color={subTitleColor}>{subTitle}</SubTitle>}
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {image && (
            <IconWrapper>
              <Image src={image} />
            </IconWrapper>
          )}
        </BoxWrapper>
        {(linkTitle || linkTitle1) && (
          <LinksWrapper>
            {linkTitle && (
              <ToLink
                color={linkTitleColor}
                fontSize={linkTitleFontSize}
                hideUnderLine={linkTitleColor}
              >
                {linkTitle}
              </ToLink>
            )}
            {linkTitle1 && (
              <ToLink
                color={linkTitleColor}
                fontSize={linkTitleFontSize}
                hideUnderLine={linkTitleColor}
              >
                {linkTitle1}
              </ToLink>
            )}
          </LinksWrapper>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

export default StyledButton;
