import { useMemo } from "react";
import useGetEcosystemByIds from "./useGetEcosystemByIds";
import { HUB_NAMES, SECTIONS_NAMES } from "./constant";
import useFetchPosts from "../../../customHooks/useFetchPosts";
import useFetchPostItems from "../../../customHooks/useFetchPostItems";
import useFetchTasks from "../../../customHooks/useFetchTasks";
import useFetchEvents from "../../../customHooks/useFetchEvents";
import useFetchActivities from "../../../customHooks/useFetchActivities";
import useGetHubs from "./useGetHubs";
import customZonesData from "./customZonesData";

const useEcosystemData = (data, PostType, zoneId) => {
  const { cardPosts } = useFetchPosts(data?._id);
  const { cardPostItems } = useFetchPostItems(data?._id);
  const { cardPosts: networkPosts } = useFetchPosts(
    data?._id,
    undefined,
    PostType.Network
  );

  const neighborhoodSection = data?.sections?.find(
    (section) => section.type === SECTIONS_NAMES.NEIGHBORHOOD
  );
  const schoolSection = data?.sections?.find(
    (section) => section.type === SECTIONS_NAMES.SCHOOL
  );
  const organizationSection = data?.sections?.find(
    (section) => section.type === SECTIONS_NAMES.ORGANIZATION
  );
  const customSections = data?.sections?.filter(
    (section) =>
      ![
        SECTIONS_NAMES.ORGANIZATION,
        SECTIONS_NAMES.NEIGHBORHOOD,
        SECTIONS_NAMES.SCHOOL,
      ].includes(section.type)
  );
  const customSectionsIds = useMemo(() => {
    const communities = [];
    customSections?.forEach?.((s) => {
      s?.communities?.forEach?.((c) => {
        communities.push(c);
      });
    });
    return communities;
  }, [customSections]);

  const { data: neighborhoodDataRaw } = useGetEcosystemByIds(
    neighborhoodSection?.communities &&
      neighborhoodSection?.communities.length !== 0 &&
      neighborhoodSection.communities
  );

  const { data: schoolDataRaw } = useGetEcosystemByIds(
    schoolSection?.communities &&
      schoolSection?.communities.length !== 0 &&
      schoolSection.communities
  );

  const { data: organizationDataRaw } = useGetEcosystemByIds(
    organizationSection?.communities &&
      organizationSection?.communities.length !== 0 &&
      organizationSection.communities
  );

  // Filter customZonesData based on zoneId and type, then append to respective data arrays
  const neighborhoodData = useMemo(() => {
    const customData = customZonesData.filter(
      (zone) => zone.type === "neighborhood" && zone.parent === zoneId
    );
    return [...customData, ...(neighborhoodDataRaw || [])];
  }, [neighborhoodDataRaw, zoneId]);

  const schoolData = useMemo(() => {
    const customData = customZonesData.filter(
      (zone) => zone.type === "school" && zone.parent === zoneId
    );
    return [...customData, ...(schoolDataRaw || [])];
  }, [schoolDataRaw, zoneId]);

  const organizationData = useMemo(() => {
    const customData = customZonesData.filter(
      (zone) => zone.type === "organization" && zone.parent === zoneId
    );
    return [...customData, ...(organizationDataRaw || [])];
  }, [organizationDataRaw, zoneId]);

  const { data: customSectionsDataRaw } = useGetEcosystemByIds(
    customSectionsIds?.length > 0 && customSectionsIds
  );

  const customSectionsData = useMemo(() => {
    const customData = customZonesData.filter(
      (zone) => zone.type === "section" && zone.parent === zoneId
    );
    return [...customData, ...(customSectionsDataRaw || [])];
  }, [customSectionsDataRaw, zoneId]);

  const sectionData = useMemo(() => {
    const sData = {
      [SECTIONS_NAMES.NEIGHBORHOOD]: neighborhoodData,
      [SECTIONS_NAMES.SCHOOL]: schoolData,
      [SECTIONS_NAMES.ORGANIZATION]: organizationData,
    };
    customSections?.forEach((section, i) => {
      if (!sData[section.type]) {
        sData[section.type] = [];
      }
      section.communities?.forEach?.((c, index) => {
        if (customSectionsData?.[index]) {
          sData[section.type] = [
            ...sData[section.type],
            customSectionsData[index],
          ];
        }
      });
    });
    if (
      zoneId === "6640beff688afe7a27141c6b" &&
      sData?.["Communities קהילות בגדרות"]
    ) {
      sData["Communities קהילות בגדרות"] = sData[
        "Communities קהילות בגדרות"
      ].sort((a, b) => {
        if (a._id === "6640c09f688afe7a27141e08") return 1;
        if (b._id === "6640c09f688afe7a27141e08") return -1;
        if (a._id === "6640bfcd688afe7a27141c9a") return 1;
        if (b._id === "6640bfcd688afe7a27141c9a") return -1;
        return 0;
      });
    }
    return sData;
  }, [
    neighborhoodData,
    schoolData,
    organizationData,
    customSections,
    zoneId,
    customSectionsData,
  ]);

  const hubsId = data?.hubs?.reduce(
    (acc, hub) => (hub.name && hub._id && (acc[hub.name] = hub._id), acc),
    {}
  );

  const subHubsId = useMemo(() => {
    return data?.hubs
      ?.filter(
        (hub) =>
          ![HUB_NAMES.ACTIVITY, HUB_NAMES.EVENT, HUB_NAMES.TASK].includes(
            hub.name
          )
      )
      .map((filteredHub) => filteredHub._id);
  }, [data?.hubs]);

  const { fetchedTasks } = useFetchTasks(data?._id);
  const { fetchedEvents } = useFetchEvents(data?._id);
  const { fetchedActivities } = useFetchActivities(data?._id);

  const { isLoading: isLoadingHubs, data: hubsData } = useGetHubs(
    subHubsId && subHubsId
  );

  return {
    cardPosts,
    cardPostItems,
    networkPosts,
    sectionData,
    hubsId,
    subHubsId,
    fetchedTasks,
    fetchedEvents,
    fetchedActivities,
    isLoadingHubs,
    hubsData,
  };
};

export default useEcosystemData;
