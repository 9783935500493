import React from "react";
import styled, { css } from "styled-components";
import { FlexRow } from "../../../../Common/Styled";
import { FaCheck } from "react-icons/fa6";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -30px;
  width: 110px;
  background-color: #e5eeff;
  border: 1px solid #316788;
  border-radius: 5px;
  z-index: 10;
  color: #316788;
  font-size: 11.985px;
  font-weight: 600;
  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `}
  z-index: 1002;
  @media (max-width: 385px) {
    left: -45px;
  }
`;

const SoundSwitch = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  flex: 1;
  height: 100%;
  border-left: ${(props) => (props.isLeft ? "1px solid #316788" : "none")};
  border-right: ${(props) => (props.isRight ? "1px solid #316788" : "none")};
  border-bottom-right-radius: ${(props) => props.isLeft && "3px"};
  border-bottom-left-radius: ${(props) => props.isRight && "3px"};
  background-color: ${(props) => (props.isClicked ? "#7FB383" : "transparent")};
  color: ${(props) => (props.isClicked ? "#fff" : "#316788")};
`;

const SoundSubTab = ({ tab, isClicked, setIsClicked }) => {
  return (
    <Wrapper customStyles={tab.styles}>
      <FlexRow
        sx={{
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #316788",
          height: "18px",
          gap: "2px",
          padding: "0 5px",
        }}
      >
        {tab.icon}
        {tab.label}
      </FlexRow>
      <FlexRow
        sx={{
          alignItems: "center",
          height: "21px",
        }}
      >
        <SoundSwitch
          isRight
          isClicked={!isClicked}
          onClick={() => setIsClicked(false)}
        >
          <FlexRow
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
            }}
          >
            OFF
            {!isClicked && <FaCheck size={15} />}
          </FlexRow>
        </SoundSwitch>
        <SoundSwitch
          isLeft
          isClicked={isClicked}
          onClick={() => setIsClicked(true)}
        >
          <FlexRow
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
            }}
          >
            ON
            {isClicked && <FaCheck size={15} />}
          </FlexRow>
        </SoundSwitch>
      </FlexRow>
    </Wrapper>
  );
};

export default SoundSubTab;
