import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Card from "./Card";
import PaginationContainer from "../../../PaginationContainer/PaginationContainer";
import { htmlToText } from "../../../RichTextEditor/htmlUtils";

const Wrapper = styled.div`
  margin: 22px 15px;
`;
const CardsWrapper = styled.div``;

function LinearCards({
  data,
  HeartColor,
  lang,
  hasStar,
  liked,
  hasDate,
  height,
  imageWidth,
  link,
  linkAddress,
  showHeart,
  to,
  titleFontSize,
  otherDetailsFontSize,
  isTasksVisable = true,
  onCardClick,
  usePagination,
  paginationPageLimit,
  paginationColor,
  canShowDetails = false,
}) {
  const navigate = useNavigate();
  const id = 0;
  const viewHandler = () => {
    navigate(`/activities/view/${id}`);
  };
  const renderCard = (activity) => {
    if (!activity) return null;

    return (
      isTasksVisable && (
        <Card
          image={activity.image}
          title={htmlToText(activity.title || activity.name)}
          actionText={activity.actionText}
          action={activity.hasAction && viewHandler}
          location={activity.location}
          date={activity.date}
          time={activity.time || activity.start_time}
          key={activity.id}
          liked={liked}
          HeartColor={HeartColor}
          showHeart={showHeart}
          lang={lang}
          hasStar={hasStar}
          hasDate={hasDate}
          height={height}
          imageWidth={imageWidth}
          titleFontSize={titleFontSize}
          otherDetailsFontSize={otherDetailsFontSize}
          linkAddress={linkAddress}
          to={to}
          onClick={() => onCardClick?.(activity)}
        />
      )
    );
  };

  return (
    <Wrapper>
      <CardsWrapper>
        {usePagination && (
          <PaginationContainer
            elements={data?.map((activity) => {
              if (!activity) return null;
              return (
                <>
                  {link ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`${link}/${activity?.id}`}
                    >
                      {renderCard(activity)}
                    </Link>
                  ) : canShowDetails ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/activities/${activity?.zone}/card-details/${activity?.task_type}/${activity?.id}`}
                    >
                      {renderCard(activity)}
                    </Link>
                  ) : (
                    renderCard(activity)
                  )}
                </>
              );
            })}
            pageLimit={paginationPageLimit ?? 5}
            color={paginationColor ?? "#003f5d"}
          />
        )}
        {!usePagination &&
          data?.map((activity) => {
            if (!activity) return null;

            return (
              <>
                {link ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`${link}/${activity?.id}`}
                  >
                    {renderCard(activity)}
                  </Link>
                ) : activity?.link ? (
                  <Link style={{ textDecoration: "none" }} to={activity?.link}>
                    {renderCard(activity)}
                  </Link>
                ) : (
                  renderCard(activity)
                )}
              </>
            );
          })}
      </CardsWrapper>
    </Wrapper>
  );
}

export default LinearCards;
