import React from "react";

function CommunitiesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18 25.313a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25ZM27.563 16.313a8.41 8.41 0 0 1 6.75 3.375M1.688 19.688a8.41 8.41 0 0 1 6.75-3.375"
      />
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9.9 30.375a9.014 9.014 0 0 1 16.2 0M8.438 16.313a4.5 4.5 0 1 1 4.416-5.344M23.146 10.969a4.5 4.5 0 1 1 4.416 5.344"
      />
    </svg>
  );
}

export default CommunitiesIcon;
