import React from "react";

const ShareIcon = ({ width = 22, height = 20, color = "#316788" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill={color}
        d="M.266 8.02c.09-.087.06-.21.085-.314a2.19 2.19 0 0 1 2.11-1.697 118.1 118.1 0 0 1 2.015-.002c.44.002.77.325.765.737-.004.414-.335.725-.777.727-.633.002-1.267-.001-1.9 0-.484.002-.789.302-.789.78v9.46c0 .485.295.78.784.78h10.9c.488 0 .783-.295.784-.781V8.25c0-.48-.303-.777-.789-.778-.633-.002-1.267 0-1.9-.001-.44-.002-.773-.316-.777-.728-.004-.416.32-.734.764-.736.672-.003 1.344-.007 2.015.002a2.177 2.177 0 0 1 2.15 1.967c.004.036-.005.078.046.09v.046c-.089.053-.043.138-.043.206-.003 3.108-.003 6.217 0 9.325 0 .068-.045.153.043.206v.091c-.063.018-.043.072-.049.112a2.194 2.194 0 0 1-2.102 1.905c-.068-.003-.137-.007-.205-.007H2.713c-.099 0-.198.005-.296.007a2.198 2.198 0 0 1-2.068-1.704c-.024-.104.008-.226-.083-.313v-.09c.088-.053.044-.138.044-.206.002-3.11.002-6.218 0-9.327 0-.068.044-.153-.044-.205v-.091Z"
      />
      <path
        fill="#A5A1A2"
        d="M13.601 19.96a.049.049 0 0 0-.002.041H2.419a.049.049 0 0 0-.002-.04c.1-.003.198-.007.297-.007h10.682c.068 0 .137.004.205.006ZM.266 8.112c.088.053.044.137.044.205.002 3.11.002 6.219 0 9.327 0 .068.044.153-.044.206V8.111ZM15.752 17.85c-.088-.053-.043-.139-.043-.207-.003-3.108-.003-6.217 0-9.325 0-.068-.045-.153.043-.206v9.737Z"
      />
      <path
        fill={color}
        d="M8.778 2.593V11.96c0 .121 0 .242-.032.362a.723.723 0 0 1-.723.546.71.71 0 0 1-.702-.569 1.685 1.685 0 0 1-.03-.34L7.29 2.84v-.252c-.103.001-.135.08-.185.129-.601.596-1.196 1.199-1.8 1.79-.426.417-1.08.265-1.25-.282a.68.68 0 0 1 .169-.718A605.283 605.283 0 0 1 7.529.211.71.71 0 0 1 8.54.213a485.595 485.595 0 0 1 3.305 3.296.726.726 0 0 1-.018 1.029.742.742 0 0 1-1.049-.017c-.599-.587-1.19-1.183-1.781-1.778-.058-.057-.1-.131-.148-.198l-.07.048Z"
      />
    </svg>
  );
};

export default ShareIcon;
