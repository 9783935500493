import React from "react";
import styled from "styled-components";
import HeaderTabs from "./components/HeaderTabs";
import LandingSideBar from "./components/LandingSideBar";

const Wrapper = styled.div`
  width: 100%;
`;

const HeaderActions = () => {
  return (
    <Wrapper>
      <HeaderTabs />
      <LandingSideBar />
    </Wrapper>
  );
};

export default HeaderActions;
