export const ROUTES = {
  ORT: "",
  GDEROT: "gderot",
};

export const SCHOOLS = [
  {
    name: "Gderot School - בית ספר יסודי גדרות",
    route: `/activities/school/${ROUTES.GDEROT}`,
    value: ROUTES.GDEROT,
  },
  {
    name: "Harishonim - Hod Hasharon",
    route: null,
    value: "",
  },
  {
    name: "Ben Tzvi - Herzliya",
    route: null,
    value: "",
  },
  {
    name: "Ramot School - Ramat Hasharon",
    route: null,
    value: "",
  },
  {
    name: "Hagivah - Kfar Saba",
    route: null,
    value: "",
  },
  {
    name: "Hagimnasya - Tel Aviv",
    route: `/activities/school/${ROUTES.ORT}`,
    value: ROUTES.ORT,
  },
];
