import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Down from "../../../Tasks/Mock/icons/Down";
import SearchForm from "../../../../kit/SearchForm/SearchForm";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";

const Wrapper = styled.div`
  max-width: 45%;
  max-width: 55%;
`;
const SideTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: ${(props) => props.color ?? "#fff"};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-family: "NotoSans-700";
  font-family: Assistant;
  font-weight: 700;
  padding: ${(props) => (props.padding ? props.padding : "0px 5px")};
  border-radius: ${(props) => props.borderRadius ?? "10px 10px 0px 0px"};
  border: ${(props) => props.border ?? "2px solid #7fb383"};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : "#7fb383"};
  background: ${(props) => props.backGround ?? "#7FB383"};
  max-width: 100%;
  flex-direction: ${(props) => (props.hasLeftArrow ? "row" : "row-reverse")};
  text-decoration: ${(props) => (props.hasUnderline ? "underline" : "none")};
`;
const InnerSideTitle = styled.div`
  width: 90%;
  width: 100%;
  margin-right: ${(props) => (props.isVisibleList ? "26px" : "0")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: ${(props) => props.padding ?? "0px 5px"};
`;
const ListWrapper = styled.div`
  position: absolute;
  top: 110%;
  width: calc(100%);
  min-width: 100px;
  left: ${(props) => (props.rtl ? "auto" : 0)};
  right: ${(props) => (props.rtl ? 0 : "auto")};
  z-index: 1;
  border-radius: 0px 0px 3px 3px;
  background: #fff;
  border-radius: 12px;
`;
const SearchFormWrapper = styled.div`
  margin: 5px 8px;
`;

const UlElement = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  box-sizing: border-box;
`;
const LiItem = styled.li`
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
  color: #7fb383;
  font-family: "NotoSans-700";
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ${(props) => (props.language === "heb" ? "rtl" : "ltr")};
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.styledLinkColor ?? "#7fb383"};
  direction: ${(props) => (props.language === "heb" ? "rtl" : "ltr")};
`;
const BottomIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 15px;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;
const BottomIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

const DropDownOptions = (props) => {
  const {
    links,
    language,
    dropDownMargin,
    title,
    color,
    border,
    padding,
    backGround,
    borderColor,
    borderRadius,
    fontSize,
    styledLinkColor,
    searchColor,
    searchBorderColor,
    placeholderColor,
    hasUnderline,
    hasLeftArrow,
  } = props;

  const [isVisibleList, setIsVisibleList] = useState(false);
  const dropdownRef = useRef(null);
  return (
    <Wrapper>
      <SideTitle
        backGround={backGround}
        color={color}
        border={border}
        borderColor={borderColor}
        borderRadius={borderRadius}
        padding={padding}
        fontSize={fontSize}
        ref={dropdownRef}
        hasLeftArrow={hasLeftArrow}
        dropDownMargin={dropDownMargin}
        hasUnderline={hasUnderline}
        isVisibleList={isVisibleList}
      >
        {!isVisibleList && (
          <MdKeyboardArrowDown
            size={"1.5em"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsVisibleList(!isVisibleList)}
          />
        )}
        <InnerSideTitle
          onClick={() => setIsVisibleList(!isVisibleList)}
          padding={padding}
          isVisibleList={isVisibleList}
        >
          {title}
        </InnerSideTitle>
        {isVisibleList && (
          <ListWrapper rtl={language === "heb"}>
            <SearchFormWrapper>
              <SearchForm
                placeholder="חיפוש"
                style={{
                  borderRadius: "20px",
                  textPadding: "12px",
                  height: "18px",
                  fontSize: "10px",
                  borderColor: `${searchBorderColor ?? "#86D389"}`,
                  inputColor: `${searchColor ?? "#86D389"}`,
                  placeholderColor: `${placeholderColor ?? "#7FB383"}`,
                }}
                iconHeight="10px"
                iconWidth="10px"
                iconColor="#7FB383"
              />
            </SearchFormWrapper>
            <UlElement>
              {links.map(
                (link, index) =>
                  link.name !== title && (
                    <LiItem key={link.name}>
                      <StyledLink
                        styledLinkColor={styledLinkColor}
                        style={{ textDecoration: "none" }}
                        to={link.route}
                      >
                        {link.name}
                      </StyledLink>
                    </LiItem>
                  )
              )}
              <BottomIconWrapper>
                <BottomIconContainer>
                  <MdKeyboardArrowUp />
                </BottomIconContainer>
              </BottomIconWrapper>
            </UlElement>
          </ListWrapper>
        )}
      </SideTitle>
    </Wrapper>
  );
};

export default DropDownOptions;
