import { useState, useEffect } from "react";
import { MarkerIcon } from "../kit/Map/Map";
import { GEO_LOCATION_POSITION_ERROR } from "../domain/Ecosystem/Public/Map/constants";

const MAX_TRIES = 10;

const useReadUserLocation = (markerIcon) => {
  const [userLocation, setUserLocation] = useState(undefined);
  const [tryCounter, setTryCounter] = useState(0);
  const [showInstructions, setShowInstructions] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getLocation = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        if (
          permissionStatus.state === "granted" ||
          permissionStatus.state === "prompt"
        ) {
          navigator?.geolocation?.getCurrentPosition(
            (position) => {
              setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                markerIcon: markerIcon ?? MarkerIcon.Orange,
              });
              setShowInstructions(false);
            },
            (error) => {
              if (tryCounter < MAX_TRIES) {
                setTimeout(() => setTryCounter((c) => c + 1), 300);
                return;
              }
              setError(
                `Error getting geolocation: ${
                  GEO_LOCATION_POSITION_ERROR[error.code]
                }`
              );
            }
          );
        } else if (permissionStatus.state === "denied") {
          setShowInstructions(true);
        }
      } catch (error) {
        console.error("Error querying geolocation permission:", error);
      }
    };
    window.addEventListener("focus", getLocation);
    document.addEventListener("focus", getLocation);
    getLocation();
    return () => {
      window.removeEventListener("focus", getLocation);
      document.removeEventListener("focus", getLocation);
    };
  }, [tryCounter]);
  return { userLocation, showInstructions, error };
};

export default useReadUserLocation;
