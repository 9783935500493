import { createContext, useState } from "react";
import { FONTS } from "../domain/Common/Layout/Header/constant";

export const FontContext = createContext();

export const FontProvider = ({ children }) => {
  const [currentFont, setCurrentFont] = useState(FONTS.REGULAR.value);
  return (
    <FontContext.Provider
      value={{
        currentFont,
        setCurrentFont,
      }}
    >
      {children}
    </FontContext.Provider>
  );
};
