import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../../assets/icons/ArrowDown";

const ButtonWithDropDownWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  box-shadow: ${(props) => props.shadow};
  box-sizing: ${(props) => props.boxSizing ?? "content-box"};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding ?? "0"};
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  position: relative;
`;
const Wrapper = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border ?? "none"};
  border: none;
  border-radius: ${(props) => props.borderRadius};
  padding: 5px;
  margin: auto;
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight ?? 600};
  font-size: ${(props) => (props.isDropdown ? "12.5px" : props.fontSize)};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "normal"};
  font-family: ${(props) => props.fontFamily};
  height: 100%;
`;
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.hasTwoTitles ? "space-between" : "center"};
`;
const Text = styled.div`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;
const HebText = styled.bdi``;

const DropdownWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 8px;
  top: 25%;
  top: 0;
  font-size: 10px;
  width: 5%;
  height: 100%;
  align-items: center;
`;
const IconWrapper = styled.div`
  width: 45px;
  height: 38px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  width: 75%;
  height: 85%;
`;
const buttonStyles = {
  color: "#000000",
  backgroundColor: "#ffffff",
  border: "none",
  shadow: "none",
  width: "98%",
  height: "100%",
  margin: "0 auto",
  padding: "0",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
};
function Button({
  text,
  hebText,
  subText,
  goToLink,
  isDropdown,
  action,
  actionProps = "",
  isDisabled,
  styles,
  image,
  icon,
  preventDefault,
  showList,
  children,
}) {
  const navigate = useNavigate();

  const handleAction = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }
    if (isDisabled) {
      return;
    }
    if (goToLink) {
      navigate(goToLink);
    }
    if (action) {
      action(actionProps);
    }
    if (isDropdown) showList();
  };

  const mergedStyles = { ...buttonStyles, ...styles };

  return (
    <ButtonWithDropDownWrapper
      isDisabled={isDisabled}
      {...mergedStyles}
      onClick={handleAction}
    >
      <Wrapper {...mergedStyles} isDropdown={isDropdown}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {image && (
          <IconWrapper>
            <Image src={image} />
          </IconWrapper>
        )}
        <TextWrapper hasTwoTitles={text && hebText}>
          {text && (
            <Text>
              {text}{" "}
              {subText && <span style={{ color: "orange" }}>({subText})</span>}
            </Text>
          )}
          {hebText && (
            <HebText>
              {subText && !text && (
                <bdi style={{ color: "orange" }}>({subText})</bdi>
              )}{" "}
              {hebText}
            </HebText>
          )}
        </TextWrapper>

        {isDropdown && (
          <DropdownWrapper>
            <ArrowDown
              width={buttonStyles.fontSize}
              height={buttonStyles.fontSize}
            />
          </DropdownWrapper>
        )}
      </Wrapper>
      {children}
    </ButtonWithDropDownWrapper>
  );
}

export default Button;
