import React, { useContext } from "react";
import Button from "../../../kit/Button/Button";
import styled from "styled-components";
import { LanguageContext } from "../../../store/languageContext";
import { LANGUAGES } from "../../Common/Layout/Header/constant";
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 25px;
`;

const buttonStyle = {
  color: "#337a37",
  backgroundColor: "#ffffff",
  shadow: "0px 4px 12px rgba(70, 57, 77, 0.1)",
  width: "100%",
  height: "48.5px",
  margin: "22px 0",
  fontSize: "22px",
  fontWeight: "600",
  fontFamily: "Assistant",
};

function ButtonGroup({ buttons, isAbstractEmergency }) {
  const { currentLanguage } = useContext(LanguageContext);
  const chooseNames = (domains) => {
    const newDomains = domains.map((domain) => {
      if (currentLanguage === LANGUAGES.HEBREW.key) {
        return {
          hebText: domain?.hebText,
          route: domain?.route,
          value: domain?.value,
          subText: domain?.subText,
          image: domain?.image,
          icon: domain?.icon,
        };
      } else {
        return domain;
      }
    });
    return newDomains;
  };
  if (currentLanguage === LANGUAGES.HEBREW.key)
    buttonStyle["fontSize"] = "22px";
  else buttonStyle["fontSize"] = "16px";
  return (
    <>
      {chooseNames(buttons).map((domain) => (
        <ButtonWrapper key={Math.random()}>
          <Button
            text={domain?.name ?? undefined}
            subText={domain?.subText ?? undefined}
            hebText={domain?.hebText ?? undefined}
            goToLink={domain?.route}
            image={domain?.image}
            icon={domain?.icon}
            styles={{
              ...buttonStyle,
              ...(isAbstractEmergency && {
                padding: "0px 25px",
                backgroundColor: "#CEDCEF",
                boxSizing: "border-box",
                margin: "10px 0",
                fontSize: "23px",
              }),
            }}
          />
        </ButtonWrapper>
      ))}
    </>
  );
}

export default ButtonGroup;
