import React from "react";

function ProfileIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        stroke="#1E9898"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M15 18.75a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
      />
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3.633 25.313a13.125 13.125 0 0 1 22.734 0"
      />
    </svg>
  );
}

export default ProfileIcon;
