import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import MuiButton from "../../../../../kit/Button/MuiButton";
import Maintinance from "../../../../../assets/icons/Maintinance.png";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import { EmergencyOverlaysContext } from "../../EmergencyOverlaysContext";
import { needHelpIconV2 } from "../../../../Auth/landingPage/images";
import {
  firefighterImage,
  medicineImage,
  mentalImage,
  logisticalImage,
  contactsImage,
} from "./v2Icons";
//import useSendWhatsappMessage from "../../hooks/useSendWhatsappMessage";

import useHandleSendMessage from "../../hooks/handleSendMessage";
import useHandleMakeCall from "../../hooks/handleMakeCall";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { PiX } from "react-icons/pi";

const Wrapper = styled.div`
  width: calc(100% - 16px);
`;

const Title = styled.h2`
  margin: 0;
  color: #337a37;
  text-align: center;
  font-family: Assistant;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 10px;
  div {
    line-height: 27px;
  }
  ${(props) =>
    props.styles &&
    css`
      ${props.styles}
    `}
`;

const OverlayCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: ${({ selected, selectedColor }) =>
    selected ? selectedColor ?? "#F3F8F1" : "f3f8f1"};
  box-shadow: 2px 2px 5px 1px rgba(70, 57, 77, 0.5);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  height: 60px;
`;

const Name = styled.h4`
  margin: 0;
  color: ${({ selected }) => (selected ? "#FFF" : "#337a37")};
  text-align: right;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  direction: rtl;
  margin-left: auto;
`;

const ButtonWrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  justify-content: space-around;
  cursor: pointer;
`;

const buttonStyles = {
  fontSize: 15,
  fontWeight: 300,
  border: "3px solid #FFF",

  fontFamily: "NotoSans-600",
  borderRadius: "10px",
  height: 36,
  margin: "20px auto 0",
  textTransform: "none",
  display: "flex",
  justifyContent: "space-around",
  color: "#fff",
};

const buttons = [
  {
    title: "כבאות והצלה",
    image: firefighterImage,
    phone: "+972509540490",
  },
  {
    title: "רפואה",
    image: medicineImage,
    phone: "+972509540490",
  },
  {
    title: "עזרה נפשית",
    image: mentalImage,
    phone: "+972509540490",
  },
  {
    title: "סיוע לוגיסטי דחוף",
    image: logisticalImage,
    phone: "+972523153355",
  },
  {
    title: "ידידים בכבישים",
    image: Maintinance,
    phone: "+972523153355",
  },
  {
    title: "אנשי קשר לחירום",
    image: contactsImage,
    phone: "+972595027348",
  },
];

const EmergencyContactV2 = ({ onClose, styles, emergencyId }) => {
  const [clickedButtons, setClickedButtons] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const { theme } = useContext(EmergencyOverlaysContext);

  //  const { seneWhatsappMessage, isLoading: isSendingMessage } =
  // useSendWhatsappMessage();

  const { sendMessage, isLoading: isSendingMessage } = useHandleSendMessage();
  const { makeCall } = useHandleMakeCall(); // Removed isCalling if not used

  const handleButtonClick = (index) => {
    setClickedButtons((prev) =>
      prev.includes(index) ? prev.filter((n) => n !== index) : [...prev, index]
    );
  };

  const generateShareContent = ({ shareData, urlRecordId }) => {
    const domain = window.location.origin;
    const fullUrl = `${domain}/emergency/OverlayEmergencyMap/${urlRecordId}`;
    return `${shareData.title}\n${shareData.text}\n${fullUrl}\n`;
  };

  const handleSendSmsClick = async () => {
    setClickedButton("send");
    const selectedContacts = clickedButtons.map((index) => buttons[index]);
    const shareData = {
      title: "🆘 Together Ecosystem - Emergency",
      text: "🚨 נשלחה אליך בקשת סיוע דחוף מחבר/ה קהילתך. לפרטים נוספים יש ללחוץ על הקישור/ המפה המצורפים.",
    };

    const message = generateShareContent({
      shareData,
      urlRecordId: emergencyId,
    });

    try {
      for (const contact of selectedContacts) {
        await sendMessage({
          to: contact.phone,
          body: message,
        });
      }
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Failed to send SMS:", error);
    }
  };

  const handleMakeCallClick = async () => {
    setClickedButton("call");
    const selectedContacts = clickedButtons.map((index) => buttons[index]);

    try {
      for (const contact of selectedContacts) {
        await makeCall({ to: contact.phone });
      }
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Failed to make call:", error);
    }
  };

  // const handleSendClick = async () => {
  //   const selectedContacts = clickedButtons.map((index) => buttons[index]);

  //   try {
  //     for (const contact of selectedContacts) {
  //       if (contact.type === "call") {
  //         await axios.post(
  //           "https://together-backend-0070.onrender.com/make-call",
  //           {
  //             to: contact.phone,
  //           }
  //         );
  //       } else {
  //         await axios.post(
  //           "https://together-backend-0070.onrender.com/send-sms",
  //           {
  //             to: contact.phone,
  //             body: "Emergency Assistance Request",
  //           }
  //         );
  //       }
  //     }
  //     setShowSuccessMessage(true);
  //     setTimeout(() => {
  //       setShowSuccessMessage(false);
  //       onClose();
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Failed to send message:", error);
  //   }
  // };

  if (showSuccessMessage) {
    return (
      <FlexColumn
        sx={{
          color: "#337A37",
          fontFamily: "Assistant",
          fontSize: 25,
          fontWeight: 700,
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: "15px 0",
        }}
      >
        <div>הודעתך למוקדי החירום </div>
        <div>נשלחה בהצלחה</div>
        <FlexRow alignItems={"center"} gap={"10px"}>
          <div>Confirmed</div>
          <IoIosCheckmarkCircle color={"#4BAE4F"} fontSize={40} />
        </FlexRow>
      </FlexColumn>
    );
  }

  return (
    <Wrapper>
      <FlexRow
        sx={{ justifyContent: "center", alignContent: "center", gap: "20px" }}
      >
        <img
          style={{ height: 40, width: 34 }}
          src={needHelpIconV2}
          alt="headerImage"
        />
        <Title styles={styles?.title}>
          <div>בקשת סיוע</div>
          <div>ממתנדבים והקהילה</div>
        </Title>
      </FlexRow>
      <FlexColumn sx={{ gap: "15px" }}>
        {buttons.map((button, index) => {
          const isClicked = clickedButtons.includes(index);
          return (
            <OverlayCard
              key={button.title}
              selected={isClicked}
              selectedColor={theme?.tertiary ?? "#83C9E4"}
              onClick={() => handleButtonClick(index)}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={button.image}
                alt={"icon"}
              />
              <Name selected={isClicked}>{button.title}</Name>
            </OverlayCard>
          );
        })}
      </FlexColumn>
      {clickedButtons.length > 0 && (
        <FlexRow
          sx={{
            justifyContent: "center",
            alignItems: "center",
            margin: "15px auto 0",
            gap: "20px",
          }}
        >
          <ButtonWrapper
            onClick={handleMakeCallClick}
            backgroundColor={
              clickedButton === "call"
                ? theme?.primary
                : theme?.tertiary ?? "#EF7300"
            }
          >
            <MuiButton
              sx={{
                ...buttonStyles,
                border: " thick double #FFFFFF",

                backgroundColor:
                  clickedButton === "call"
                    ? theme?.primary
                    : theme?.tertiary ?? "#EF7300",
                width: 130,
                "&:hover": {
                  backgroundColor: theme?.primary ?? "#EF7300",
                },
                ...styles?.button,
              }}
            >
              <div>Call</div>
              <div>שיחה</div>
            </MuiButton>
          </ButtonWrapper>
          {/* Change made here: Second ButtonWrapper for sending SMS should trigger handleSendSmsClick */}
          <ButtonWrapper
            onClick={handleSendSmsClick} // Corrected onClick to handleSendSmsClick
            backgroundColor={
              clickedButton === "send"
                ? theme?.primary
                : theme?.tertiary ?? "#EF7300"
            }
          >
            <MuiButton
              disabled={isSendingMessage}
              sx={{
                ...buttonStyles,
                border: " thick double #FFFFFF",

                backgroundColor:
                  clickedButton === "send"
                    ? theme?.tertiary
                    : theme?.primary ?? "#EF7300",
                width: 178,
                height: 45,
                borderRadius: "10px",
                padding: "6px",
                margin: "16px auto 0",
                "&:hover": {
                  backgroundColor: theme?.primary ?? "#EF7300",
                },
                ...styles?.button,
              }}
            >
              {isSendingMessage ? (
                "Sending..."
              ) : (
                <>
                  <div>Send</div>
                  <div>שלח</div>
                </>
              )}
            </MuiButton>
          </ButtonWrapper>
        </FlexRow>
      )}
    </Wrapper>
  );
};

export default EmergencyContactV2;
