import React, { useContext, useState } from "react";
import styled from "styled-components";
import StyledButton from "../../../../Emergency/components/StyledButton";
import ambulance from "./Ambulance.png";
import BellIcon from "../../../../../assets/icons/Activities/LandingPage/BellIcon";
import ArrowUpAndDownIcons from "../../../../../kit/ArrowUpAndDownIcons/ArrowUpAndDownIcons";
import helpNeededIcon from "../../../../../assets/icons/helpNeededIcon.png";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import { FlexColumn } from "../../../../Common/Styled";

import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import { EmergencyOverlaysContext } from "../../../../Emergency/Contacts/EmergencyOverlaysContext";
const Wrapper = styled.div`
  padding: 15px 0;
  background: ${({ isWideScreen }) =>
    isWideScreen ? "none" : "rgba(127, 179, 131, 0.04)"};
  box-shadow: ${({ isWideScreen }) =>
    isWideScreen ? "none" : "2px 2px 5px 1px rgba(0, 0, 0, 0.15)"};
`;
const SponsorsTitle = styled.a`
  display: flex;
  justify-content: center;
  /* padding-bottom: 15px; */
`;
const HebTitle = styled.h3`
  color: #316788;
  font-family: "NotoSans-600";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.223px;
  background-color: transparent;
  margin: 0 0 0 5px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1px;
  padding: 5px 0 0;
  position: relative;
  align-items: flex-end;
`;
const EmergencyButtons = ({
  showArrowUpOrDown = true,
  title,
  collapsedByDefault = false,
}) => {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { setShowOverlay, setOverlayProps, setReassurance } = useContext(
    EmergencyOverlaysContext
  );
  const [areButtonsVisible, setAreButtonsVisible] = useState(
    !collapsedByDefault
  );
  const { isAuthenticated } = useCurrentUser();
  const navigate = useNavigate();
  return (
    <Wrapper isWideScreen={isWideScreen || isMediumScreen}>
      <SponsorsTitle>
        <HebTitle>{title ?? "בקשת סיוע אישי במצבי חירום"}</HebTitle>
      </SponsorsTitle>
      <div>
        {areButtonsVisible && (
          <ButtonWrapper>
            <StyledButton
              width={"110px"}
              height={"88px"}
              icon={
                <img
                  src={ambulance}
                  alt={"Ambulance"}
                  style={{ width: "52px", height: "52px" }}
                />
              }
              linkTitle={
                <FlexColumn alignItems={"center"}>
                  <div>ארגוני הצלה</div>
                  <div>Organizations</div>
                </FlexColumn>
              }
              linkTitleFontSize={"14px"}
              onClick={() => {
                setShowOverlay(true);
                setReassurance(false);
                setOverlayProps({
                  isOrganization: true,
                });
              }}
              linkTitleColor={"#316788"}
            />
            <StyledButton
              width={"110px"}
              height={"100px"}
              subTitleColor={"#003F5D"}
              icon={<BellIcon width={"38"} height={"42"} color={"#E81616"} />}
              linkTitle={
                <FlexColumn alignItems={"center"}>
                  <div>לחצן מצוקה</div>
                  <div>S.O.S</div>
                </FlexColumn>
              }
              linkTitleColor={"#E81616"}
              linkTitleFontSize={"16px"}
              onClick={() => {
                setShowOverlay(true);
                setOverlayProps({
                  isSOS: true,
                  isBlue: false,
                });
              }}
            />
            <StyledButton
              width={"110px"}
              height={"88px"}
              subTitleColor={"#003F5D"}
              icon={
                <img
                  width={"85"}
                  height={"49"}
                  src={helpNeededIcon}
                  alt={"help"}
                />
              }
              linkTitle={"סיוע דחוף"}
              linkTitleColor={"#316788"}
              linkTitleFontSize={"16px"}
              onClick={() => {
                setShowOverlay(true);
                setOverlayProps({
                  isNeedHelp: true,
                });
              }}
            />
          </ButtonWrapper>
        )}
        {showArrowUpOrDown && (
          <ArrowUpAndDownIcons
            status={areButtonsVisible}
            changeStatus={() => setAreButtonsVisible(!areButtonsVisible)}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default EmergencyButtons;
