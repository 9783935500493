import React from "react";
import styled from "styled-components";
import FavoriteCard from "./Card";
import { Link, useNavigate } from "react-router-dom";
import Card from "./Card";

import useMediaQueryConstants from "../../../../../../../customHooks/useMediaQueryConstants";

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
`;
const ActivitiesWrapper = styled.div`
  display: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "flex" : "block"};
  justify-content: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "space-between" : "start"};
  gap: 10px;
`;

function Activities({
  data,
  HeartColor,
  lang,
  hasStar,
  liked,
  hasDate,
  height,
  link,
  linkAddress,
  to,
  isTasksVisable,
  InitialStateOfLinearCards,
  margin = "0px 20px",
}) {
  const navigate = useNavigate();
  const id = 0;
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const viewHandler = () => {
    navigate(`/activities/view/${id}`);
  };

  const renderCard = (activity) => {
    if (!activity) return null;
    return (
      isTasksVisable && (
        <Card
          image={activity.image}
          title={activity.name}
          actionText={activity.actionText}
          action={activity.hasAction && viewHandler}
          location={activity.location}
          date={activity.date}
          time={activity.time}
          key={activity.id}
          liked={liked}
          HeartColor={HeartColor}
          lang={lang}
          hasStar={hasStar}
          hasDate={hasDate}
          height={height}
          linkAddress={linkAddress}
          to={to}
        />
      )
    );
  };

  return (
    <Wrapper margin={margin}>
      <ActivitiesWrapper
        isWideScreen={isWideScreen}
        isMediumScreen={isMediumScreen}
      >
        {data?.map((activity) => {
          if (!activity) return null;

          return (
            <>
              {link ? (
                <Link
                  style={{ textDecoration: "none" }}
                  to={`${link}/${activity?.id}`}
                >
                  {renderCard(activity)}
                </Link>
              ) : (
                renderCard(activity)
              )}
            </>
          );
        })}
      </ActivitiesWrapper>
    </Wrapper>
  );
}

export default Activities;
