import React from "react";

const BackButton = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 54}
      height={height ?? 31}
      fill="none"
      viewBox="0 0 54 31"
    >
      <g filter="url(#a)">
        <rect
          width={52.93}
          height={30.42}
          x={0.518}
          y={0.314}
          fill="#fff"
          fillOpacity={0.5}
          rx={15.21}
        />
      </g>
      <path
        fill="#000"
        d="M20.046 20.007v-8.394h2.427c.57 0 1.069.072 1.495.217.434.136.77.358 1.01.664.238.299.357.69.357 1.176 0 .417-.11.792-.332 1.124-.213.332-.52.562-.92.69v.051c.52.094.937.311 1.252.652.324.332.485.788.485 1.367 0 .545-.132 1-.396 1.367-.264.366-.634.639-1.111.817-.477.18-1.026.269-1.648.269h-2.62Zm.945-.754h1.52c.75 0 1.333-.14 1.75-.422.418-.28.627-.715.627-1.303 0-.545-.205-.945-.614-1.2-.408-.256-.996-.384-1.763-.384h-1.52v3.309Zm0-4.037h1.29c.75 0 1.29-.128 1.623-.383.332-.265.498-.614.498-1.048 0-.52-.174-.886-.524-1.099-.349-.213-.864-.32-1.546-.32h-1.341v2.85Zm8.1 4.944c-.341 0-.648-.068-.92-.204a1.607 1.607 0 0 1-.652-.588c-.162-.264-.243-.58-.243-.945 0-.682.307-1.201.92-1.56.622-.365 1.602-.62 2.939-.766a2.74 2.74 0 0 0-.115-.792 1.124 1.124 0 0 0-.396-.626c-.188-.162-.46-.243-.818-.243-.375 0-.724.073-1.048.218a5.775 5.775 0 0 0-.83.46l-.37-.64c.178-.119.391-.242.638-.37.247-.127.52-.234.818-.32.298-.084.613-.127.945-.127.494 0 .895.107 1.201.32.316.212.541.506.677.881.145.375.218.813.218 1.316v3.833h-.767l-.076-.754h-.039c-.298.247-.622.46-.97.639-.35.179-.72.268-1.112.268Zm.242-.754c.316 0 .614-.072.895-.217a5.26 5.26 0 0 0 .907-.651v-1.815c-.733.085-1.312.205-1.738.358-.426.145-.732.324-.92.537-.187.213-.28.46-.28.74 0 .367.11.635.332.806.23.161.498.242.804.242Zm7.41.754a2.844 2.844 0 0 1-1.469-.383 2.745 2.745 0 0 1-1.035-1.099c-.255-.485-.383-1.073-.383-1.763 0-.681.136-1.265.409-1.75.28-.494.643-.87 1.086-1.125a2.863 2.863 0 0 1 1.456-.383c.409 0 .758.077 1.048.23.29.145.54.315.754.511l-.486.613a2.28 2.28 0 0 0-.588-.409 1.425 1.425 0 0 0-.69-.166c-.391 0-.74.107-1.047.32a2.141 2.141 0 0 0-.716.869c-.17.374-.255.805-.255 1.29 0 .494.08.928.243 1.303.17.366.404.652.702.856.298.205.648.307 1.048.307.298 0 .575-.06.83-.18.256-.127.482-.28.678-.459l.408.626c-.272.247-.579.443-.92.588-.34.136-.698.204-1.073.204Zm3.656-.153v-9.122h.907v6.311h.038l2.747-3.386h1.035l-2.07 2.479 2.35 3.718h-1.008l-1.879-3.067-1.213 1.431v1.636h-.907Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.217}
        d="m13.066 20.582-4.753-4.753 4.753-4.753"
      />
      <defs>
        <filter
          id="a"
          width={57.797}
          height={35.287}
          x={-1.916}
          y={-2.119}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.217} />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1116_4251"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1116_4251"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BackButton;
