import React from "react";
import { styled } from "styled-components";
import chevronLeft from "../../assets/images/Tasks/detailsPage/chevronLeft.svg";

const Button = styled.div`
  font-family: Assistant;
  font-weight: 400;
  font-size: 21px;
  border-radius: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 7.5px 10px;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999999;
  margin-top: ${({ marginTop }) => marginTop};
  cursor: pointer;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

function BackButton({ marginTop }) {
  return (
    <Button marginTop={marginTop}>
      <div
        style={{
          width: "10px",
          height: "25px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Image src={chevronLeft} />
      </div>
      <div>Back</div>
    </Button>
  );
}

export default BackButton;
