import { Dialog, styled as MuiStyled } from "@mui/material";
import React, { useState, useEffect } from "react";
import LocationIcon from "../../../../../assets/icons/LocationIcon";
import styled from "styled-components";
import { FlexRow } from "../../../../Common/Styled";
import useEditEmergency from "../hooks/useEditEmergency";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";

const Modal = MuiStyled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    background: "#FAFFFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "342px",
    minHeight: "33px",
    overflowX: "hidden",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(173, 202, 230, 0.5)",
  },
}));

const Title = styled.h3`
  font-family: Assistant;
  font-size: ${(props) => props.fontSize ?? "18px"};
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.018em;
  text-align: center;
  color: #58656d;
  direction: rtl;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;
`;

const Radio = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: ${(props) => (props.selected ? "#9EC6A1" : "#fff")};
  border: ${(props) => (props.selected ? "4px solid #fff" : "none")};
  position: absolute;
  right: 16px;
  box-sizing: border-box;
`;

const Separator = styled.div`
  height: 2px;
  width: 290px;
  margin: 0 auto;
  background: #7fb383;
`;

const options = [
  {
    title: "כן, חד פעמי",
    value: 1,
  },
  {
    title: "כן, לשעה הקרובה",
    value: 2,
  },
  {
    title: "לא כרגע",
    value: 3,
  },
];

const LocationPopup = ({
  emergencyInformation,
  userLocation,
  closePopup,
  refetchEmergency,
}) => {
  const [selected, setSelected] = useState();
  const { editEmergency } = useEditEmergency();
  const { currentUser } = useCurrentUser();

  const onRadioClick = (value) => {
    selected === value ? setSelected(undefined) : setSelected(value);
  };

  const handleClosePopup = async () => {
    if (emergencyInformation?._id) {
      const existingLocations = emergencyInformation?.locations || [];

      const locationExists = existingLocations.some(
        (loc) =>
          String(loc.latitude) === String(userLocation?.lat) &&
          String(loc.longitude) === String(userLocation?.lng)
      );

      if (!locationExists) {
        const newLocation = {
          latitude: userLocation?.lat,
          longitude: userLocation?.lng,
        };

        const payload = {
          locations: [...existingLocations, newLocation],
          sender: currentUser?._id,
        };

        await editEmergency({
          id: emergencyInformation?._id,
          payload,
        });
        refetchEmergency();
      }
    }
    closePopup(false);
  };

  return (
    <Modal onClose={handleClosePopup} open>
      <FlexRow
        sx={{
          fontSize: 22,
          fontFamily: "Assistant",
          color: "#58656D",
          fontWeight: 700,
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          height: 67,
          backgroundColor: "#7FB38333",
          width: "100%",
        }}
      >
        <LocationIcon />
        {"Location - שיתוף מקום"}
      </FlexRow>
      <Body>
        {options &&
          options?.map((s, i) => (
            <>
              <Section key={i}>
                <Title>{s.title}</Title>
                <Radio
                  selected={selected === s.value}
                  onClick={() => onRadioClick(s.value)}
                />
              </Section>
              {i < options?.length - 1 && <Separator key={`separator-${i}`} />}
            </>
          ))}
      </Body>
    </Modal>
  );
};

export default LocationPopup;
