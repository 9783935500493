import { STEPS_VALUES } from "./constants";

export const nextStep = (setSteps) => {
  setSteps((prevSteps) => {
    // Find the index of the last element with status 'pending'
    const lastPendingIndex = prevSteps
      .map((step) => step.status)
      .lastIndexOf(STEPS_VALUES.pending);

    // If no pending step found, return the steps as is
    if (lastPendingIndex === -1) return prevSteps;

    // Create a copy of steps to avoid direct state mutation
    const newSteps = [...prevSteps];

    // Update the status of the last pending step to finished
    newSteps[lastPendingIndex].status = STEPS_VALUES.finished;

    // Find the next stale step after the last pending step
    const nextStaleIndex = newSteps.findIndex(
      (step, index) =>
        index > lastPendingIndex && step.status === STEPS_VALUES.stale
    );

    // If found, update its status to pending
    if (nextStaleIndex !== -1) {
      newSteps[nextStaleIndex].status = STEPS_VALUES.pending;
    }

    return newSteps;
  });
};

/**
 * Function to go directly to a specific step (1-based index)
 * @param {Function} setSteps - The state setter function for the steps
 * @param {number} stepNumber - The step number you want to go to (1-based index)
 */
export const goToStep = (setSteps, stepNumber) => {
  setSteps((prevSteps) => {
    // Convert stepNumber (1-based) to targetIndex (0-based)
    const targetIndex = stepNumber - 1;

    // Create a copy of steps to avoid direct state mutation
    const newSteps = [...prevSteps];

    // Loop through each step and update the status based on its index
    newSteps.forEach((step, index) => {
      if (index < targetIndex) {
        // Steps before the target are finished
        step.status = STEPS_VALUES.finished;
      } else if (index === targetIndex) {
        // Target step is set to pending
        step.status = STEPS_VALUES.pending;
      } else {
        // Steps after the target are set to stale
        step.status = STEPS_VALUES.stale;
      }
    });

    return newSteps;
  });
};

// Function to reset the steps to their initial state
export const resetSteps = (setSteps, initialSteps) => {
  setSteps(initialSteps);
};
