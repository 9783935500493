import React, { useContext } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../constant";

import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";

export const Wrapper = styled.div`
  min-width: ${(props) => props.minWidth || "99px"};
  border-radius: ${(props) => props.borderRadius || "7.684px"};
  border: ${({ isWideScreen }) => (isWideScreen ? "4px" : "2px")} solid
    ${(props) => props.color || "#25beb7"};
  background-color: ${(props) =>
    props.isSelected ? props.color || "#8BDC8E" : "white"};
  color: ${(props) => (props.isSelected ? "white" : "#58656D")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Assistant"};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.isSelected ? "16px" : "14px"};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
  height: ${(props) => (props.isSelected ? "24px" : "22px")};
  padding: ${({ isWideScreen }) => (isWideScreen ? "17px 8px" : "0 8px")};
  box-sizing: border-box;
  background: ${({ isWideScreen }) => (isWideScreen ? "#F6F8F9" : "white")};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
  margin-left: 30px;
  cursor: pointer;
`;
const StyledLink = styled.div`
  text-decoration: none;
`;

function ZoneButton(props) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const {
    name,
    isSelected,
    color,
    to,
    fontSize,
    fontWeight,
    fontFamily,
    fontColor,
    borderRadius,
    marginLeft,
    currentLanguageProps,
    showName,
    onClick,
  } = props;
  const { currentLanguage } = useContext(LanguageContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const targetName = {
    // "כניסה למתנדבים"
    hebrew: showName
      ? name
      : pathname.includes("public")
      ? "כניסה לאיזור אישי"
      : "לאיזור הציבורי",
    english: pathname.includes("public") ? "Private Zone " : "Public Zone",
  };
  const getCurrentName = () => {
    if (pathname.includes("emergency")) {
      if (currentLanguage === LANGUAGES.ENGLISH.value) {
        if (pathname.includes("public")) {
          return "Private Zone";
        } else {
          return "Public Zone";
        }
      } else return name;
    } else return targetName[currentLanguageProps ?? currentLanguage];
  };
  return (
    <StyledLink onClick={() => (to ? navigate(to) : null)}>
      <Wrapper
        isWideScreen={isWideScreen || isMediumScreen}
        isSelected={isSelected}
        color={color}
        borderRadius={borderRadius}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontColor={fontColor}
        fontFamily={fontFamily}
        marginLeft={marginLeft}
        onClick={onClick}
      >
        {getCurrentName()}
      </Wrapper>
    </StyledLink>
  );
}

export default ZoneButton;
