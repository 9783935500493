import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import useGetEcosystemById from "../useGetEcosystemById";
import {
  RamatHasharonEmergencyIcon,
  TogetherIcon,
} from "../../../../assets/icons/Logo";
import Header from "../../../Common/Layout/Header";
import LoadingSpinner from "../../../Common/Loading/Loading";
import Activities from "../../Zone/Activities/Activities";
import SliderCard from "../../Zone/Common/Cards/Slider/Gderot/Card1";
import Title from "../../Zone/Common/Title/City/Page";
import { MAIN_ROUTES } from "../../../Common/Layout/Main/constants";
import { getEmergencyData, vetSponsersData } from "../../Mock/publicData";
import CopyRightAndTerms from "../../../Common/Layout/Footer/components/CopyRightAndTerms";
import { UserContext } from "../../../../store/userContext";
import { navigateToCommunitySection } from "../../Board/utils";
import { PostType } from "../../Board/api";
import { HUB_CREATE_ROUTE, HUB_NAMES, SECTION_TITLE } from "../constant";
import { setData } from "../utils";
import {
  Input,
  Section,
  AddButton,
} from "../../../Admin/AdminArea/CreatePage/CreatePage";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";
import {
  EcosystemSectionType,
  createSectionInEcosystem,
  deleteEcosystem,
  deleteSectionFromEcosystem,
  editEcosystem,
} from "../../Zone/Activities/Create/api";
import SelectionPopup from "../../../Admin/AdminArea/CreatePage/SelectionPopup";
import useEcosystemData from "../useEcosystemData ";
import { EcosystemZone } from "../../../Admin/AdminArea/CreatePage/constans";
import { LanguageContext } from "../../../../store/languageContext";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import { DataType } from "../../Zone/Common/CardDetailsPage/CardDetailsPage";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import ApplesAndSearchSection from "../../Zone/Common/ApplesAndSearchSection/ApplesAndSearchSection";
import LogosSlider from "../../Zone/Common/LogosSlider/LogosSlider";
import EmergencyButtons from "../../Zone/Common/EmergencyButtons/EmergencyButtons";
import Emergency from "../../Zone/Common/Cards/Emergency";
import EmergencyLinearCard from "../../Zone/Common/Cards/Linear/Emergency2";
import InfiniteSlider from "../../../../kit/InfiniteSlider/InfiniteSlider";
import useFetchBoardsPosts from "../../../../customHooks/useFetchBoardsPosts";
import LinearCard from "../../Zone/Common/Cards/Linear/CardWithMoreInfo/LinearCards";
import GiveAndRecieveImage from "../../../../assets/images/Activities/Common/give-receive.png";
import VolunteerBoardImage from "../../../../assets/images/Activities/CommunityBoard/card-cover.png";
import NetworkingBoardImage from "../../../../assets/images/Activities/TogetherInTheCity/telaviv/Mock/cardSlider/tel82.jpg";
import useIsUserAuthorizedForCreate from "../../../../customHooks/useIsUserAuthorizedForCreate";
import useUserHasAccessToEcosystem from "../useUserHasAccessToEcosystem";
import LoginAccessText from "../../../../kit/LoginAccessText/LoginAccessText";
import ReturnArrow from "../../../Common/Layout/ReturnArrow/ReturnArrow";
import useRegion from "../../../../customHooks/useRegion";

export const PurposeSections = [
  { title: "שכונה", value: EcosystemSectionType.Neighborhood },
  { title: "בית ספר / אוניברסיטה", value: EcosystemSectionType.School },
  { title: "ארגון", value: EcosystemSectionType.Organization },
];

export const EnglishPurposeSections = [
  { title: "My neighborhood", value: EcosystemSectionType.Neighborhood },
  { title: "Educational institutions", value: EcosystemSectionType.School },
  { title: "Organizations", value: EcosystemSectionType.Organization },
];

const Wrapper = styled.div`
  padding-bottom: ${(props) => (props.isAuthenticated ? "80px" : "0")};
  min-height: 85vh;
`;

function RamatHasharon() {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { isAuthenticated, currentUser } = useContext(UserContext);
  const { zone: zoneId } = useParams();
  const {
    data: ecosystem,
    isLoading,
    refetchEcosystem,
  } = useGetEcosystemById(zoneId);
  const navigate = useNavigate();
  const [clickedCardIndex, setClickedCardIndex] = useState(-1);
  const [createSectionPopupVisible, setCreateSectionPopupVisible] =
    useState(false);
  const [editSectionPopupVisible, setEditSectionPopupVisible] = useState(false);
  const { pathname } = useLocation();
  const isPublic = pathname.includes("public");
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const { isRamatHasharon } = useRegion();
  const [selectedEditSection, setSelectedEditSection] = useState({
    value: undefined,
    id: undefined,
  });
  const [sectionTitle, setSectionTitle] = useState();
  const { setCurrentLanguage } = useContext(LanguageContext);

  const zones = [
    {
      name: isPublic ? "כניסה למתנדבים" : "לאיזור ציבורי",
      isSelected: false,
      to: isPublic
        ? isAuthenticated
          ? `/activities/view/${zoneId}`
          : `/login/custom/ramat-hasharon`
        : `/public/activities/view/${zoneId}`,
    },
  ];

  const isUserAuthorizedForCreate = useIsUserAuthorizedForCreate(
    !isPublic,
    currentUser &&
      currentUser.admin &&
      currentUser.admin.includes(ecosystem?._id)
      ? currentUser?._id
      : ecosystem?.createdBy
  );

  const {
    cardPosts,
    cardPostItems,
    networkPosts,
    sectionData,
    fetchedTasks,
    fetchedEvents,
    fetchedActivities,
    hubsData,
    isLoadingHubs,
  } = useEcosystemData(ecosystem, PostType, zoneId);

  const userHasAccess = useUserHasAccessToEcosystem(ecosystem);

  const isSmallCommunity = useMemo(
    () => /^[0-9a-fA-F]{24}$/i.test(ecosystem?.parent),
    [ecosystem]
  );

  const hideEmegency = useMemo(
    () => ecosystem?._id === "66051bbbf000a65bb520bd6e",
    [ecosystem?._id]
  );

  const showSponsers = useMemo(
    () => ecosystem?._id === "66051bbbf000a65bb520bd6e",
    [ecosystem?._id]
  );

  const onCreateSectionPopupDismiss = async (selected) => {
    try {
      if (!selected) return;
      await createSectionInEcosystem(
        sectionTitle && sectionTitle?.trim?.() !== ""
          ? sectionTitle?.trim?.()
          : SECTION_TITLE[selected] ?? selected,
        ecosystem?._id,
        selected
      );
      refetchEcosystem?.();
    } catch (err) {
      console.error("Failed to create section", err);
    }
  };
  const onEditSectionPopupDismiss = async (selected) => {
    try {
      if (!selected) return;
      await editEcosystem(ecosystem?._id, {
        ...ecosystem,
        sections: ecosystem.sections?.map((s) => {
          if (s._id !== selectedEditSection.id) return s;
          return {
            ...s,
            type: selected,
          };
        }),
      });
      setSelectedEditSection(undefined);
      refetchEcosystem?.();
    } catch (err) {
      console.error("Failed to edit section", err);
    }
  };

  const isEnglish = useMemo(
    () =>
      ecosystem?.zoneType === EcosystemZone.Europe ||
      ecosystem?.zoneType === EcosystemZone.USA,
    [ecosystem]
  );

  const postingCards = useMemo(() => {
    return ecosystem?.postingCards?.map((card) => ({
      ...card,
      title: card.name,
      id: card._id,
    }));
  }, [ecosystem?.postingCards]);

  const { boardWithLinearPosts } = useFetchBoardsPosts(
    ecosystem?._id,
    postingCards?.map((card) => card._id),
    postingCards
  );

  const coverImages = useMemo(() => {
    const filteredImages = ecosystem?.coverImages?.filter(
      (image) => image !== null
    );
    if (filteredImages?.length === 0) {
      return ecosystem?.image ? [ecosystem?.image] : [];
    }

    return filteredImages;
  }, [ecosystem?.coverImages, ecosystem?.image]);

  const volunteerForum = useMemo(
    () => [
      {
        title: ecosystem?.volunteerForum?.[1] ?? "Volunteer Board",
        image: VolunteerBoardImage,
      },
      {
        title: ecosystem?.volunteerForum?.[0] ?? "Give & Receive",
        image: GiveAndRecieveImage,
      },
      {
        title: ecosystem?.volunteerForum?.[2] ?? "Volunteer networking",
        image: NetworkingBoardImage,
      },
    ],
    [ecosystem?.volunteerForum]
  );

  useEffect(() => {
    if (isEnglish) {
      setCurrentLanguage(LANGUAGES.ENGLISH.value);
      return;
    }
    setCurrentLanguage(LANGUAGES.HEBREW.value);
  }, [isEnglish, setCurrentLanguage]);

  useEffect(() => {
    return () => {
      setCurrentLanguage(LANGUAGES.HEBREW.value);
    };
  }, []);

  useEffect(() => {
    setLoginRoute(`/activities/view/${zoneId}`);
  }, [zoneId, setLoginRoute]);

  if (isLoading || isLoadingHubs) {
    return <LoadingSpinner center={true} text={"Loading"} />;
  }

  if (!userHasAccess && !isLoading) {
    if (!isAuthenticated) {
      navigate("/login/private-ecosystem");
    }
    return <LoginAccessText noLogin viewText={"ecosystem"} />;
  }

  return (
    <>
      <ReturnArrow
        onClickOverride={() => {
          navigate("/");
        }}
        forceShowArrow
      />
      <SelectionPopup
        title={isEnglish ? "Add a subgroup in the community" : "מטרת הקבוצה"}
        visible={createSectionPopupVisible}
        setVisible={setCreateSectionPopupVisible}
        onDismiss={onCreateSectionPopupDismiss}
        options={isEnglish ? EnglishPurposeSections : PurposeSections}
        currentSections={ecosystem?.sections}
        showExtraOption
        extraOptionPlaceholder={isEnglish ? "Add headline" : "הוסף כותרת"}
      />
      <SelectionPopup
        title={isEnglish ? "Add a subgroup in the community" : "מטרת הקבוצה"}
        visible={editSectionPopupVisible}
        setVisible={setEditSectionPopupVisible}
        onDismiss={onEditSectionPopupDismiss}
        options={PurposeSections}
        initiallySelected={selectedEditSection?.value}
      />
      <Wrapper>
        <Header
          zones={zones}
          headerButtons={
            isUserAuthorizedForCreate
              ? [
                  {
                    title: "עריכה",
                    onClick: () =>
                      navigate(`/admin/create-page/edit/${ecosystem?._id}`),
                  },
                  {
                    title: "השהיית קהילה",
                    color: "#D04343",
                    onClick: async () => {
                      try {
                        await deleteEcosystem(ecosystem?._id);
                        navigate("/");
                      } catch (er) {
                        console.error("Failed to delete ecosystem", er);
                      }
                    },
                  },
                ]
              : undefined
          }
          headerButtonsMargin={"10px 0 0 0"}
          titlePos={{ top: "40px", right: "-90px" }}
          padding={"15px 0"}
          Icon={() => TogetherIcon({ width: "240", height: "60" })}
          title={ecosystem?.title}
          titlesLang="heb"
          zoneMarginLeft={"15px"}
        />
        {coverImages?.length > 0 && <InfiniteSlider images={coverImages} />}
        <ApplesAndSearchSection zone={ecosystem?._id} />
        {/* <SearchPart
          zone={ecosystem?._id}
          tasks={[
            ...(fetchedActivities ?? []),
            ...(fetchedEvents ?? []),
            ...(fetchedTasks ?? []),
          ]}
          link={`/activities/${ecosystem._id}/card-details`}
        /> */}
        {ecosystem?.hubs?.map((hub) => {
          const zoneId = [
            HUB_NAMES.ACTIVITY,
            HUB_NAMES.EVENT,
            HUB_NAMES.TASK,
          ].includes(hub.name)
            ? ecosystem?._id
            : hub?._id;
          return (
            <Activities
              data={setData(
                hub.name,
                hub._id,
                fetchedTasks,
                fetchedEvents,
                fetchedActivities,
                hubsData
              )}
              hasActions
              TitleComponent={Title}
              titleProps={{
                hebrewTitle: hub.title,
                englishTitle: hub.title,
                AreIconsOnTheSameLine: true,
                showDivider: false,
                underline: hub.isPrimary && true,
                link:
                  hub.isPrimary &&
                  `/${MAIN_ROUTES.SEARCH}/zone/${zoneId}/type/${hub.name}`,
              }}
              SliderComponent={SliderCard}
              sliderProps={{
                cardWidth: 0,
                data: setData(
                  hub.name,
                  hub._id,
                  fetchedTasks,
                  fetchedEvents,
                  fetchedActivities,
                  hubsData
                ),
                HeartColor: "green",
                titleColor: "#9FA3A8",
                liked: false,
                showLocation: false,
                showDate: false,
                link: `/activities/${ecosystem._id}/card-details/${
                  hub?.name || DataType.Hub
                }`,
                locationFontSize: "17px",
                locationColor: "#316788",
                hebrewDescription: "בואו והצטרפו ליום כיף למשפחה",
              }}
              //TODO: view if you created the ecosystem or admin
              sideLink={
                isUserAuthorizedForCreate && {
                  title: "Create",
                  link: `/activities/${zoneId}/create/${
                    hub?.name
                      ? HUB_CREATE_ROUTE[hub.name]
                      : HUB_CREATE_ROUTE.hub
                  }`,
                }
              }
              openedCard={0}
            />
          );
        })}
        {isUserAuthorizedForCreate && (
          <Section isWideScreen={isWideScreen || isMediumScreen}>
            <Input
              width={"80%"}
              placeholder={
                isEnglish
                  ? "Add a subgroup in the community"
                  : "צור תת קבוצה בקהילה"
              }
              onChange={(e) => setSectionTitle(e.target?.value)}
            />
            <AddButton
              isWideScreen={isWideScreen || isMediumScreen}
              onClick={() => {
                setCreateSectionPopupVisible(true);
              }}
            >
              {isEnglish ? "Add" : "הוסף"}
            </AddButton>
            <Activities
              data={[]}
              background={"none"}
              boxShadow={"none"}
              SliderComponent={SliderCard}
              sliderProps={{
                data: [],
                HeartColor: "green",
                locationFontSize: "17px",
                locationColor: "#316788",
                liked: false,
                showLocation: false,
                showDate: false,
                hebrewDescription: "בואו וניתן באהבה למי שצריך",
              }}
            />
          </Section>
        )}
        {ecosystem?.sections?.map((section) => {
          return (
            <div style={{ position: "relative" }}>
              {isUserAuthorizedForCreate && (
                <AddButton
                  isWideScreen={isWideScreen || isMediumScreen}
                  onClick={() => {
                    setSelectedEditSection({
                      value: section.type,
                      id: section._id,
                    });
                    setEditSectionPopupVisible(true);
                  }}
                >
                  {"Edit"}
                </AddButton>
              )}
              <Activities
                data={sectionData[section.type]}
                hasActions
                TitleComponent={Title}
                titleProps={{
                  hebrewTitle: section.title ?? SECTION_TITLE[section.type],
                  englishTitle: section.title ?? SECTION_TITLE[section.type],
                  AreIconsOnTheSameLine: true,
                  showDivider: false,
                }}
                SliderComponent={SliderCard}
                sliderProps={{
                  cardWidth: 0,
                  data: sectionData[section.type]?.reverse(),
                  HeartColor: "green",
                  titleColor: "#9FA3A8",
                  liked: false,
                  showLocation: false,
                  showDate: false,
                  link: isPublic
                    ? `/public/activities/view`
                    : `/activities/view`,
                  locationFontSize: "17px",
                  locationColor: "#316788",
                  currentRoute: pathname,
                }}
                sideLink={
                  isUserAuthorizedForCreate && {
                    title: "create",
                    link: `/admin/create-page/${ecosystem._id}/${section._id}`,
                  }
                }
                showDelete={isUserAuthorizedForCreate}
                deleteOnClick={async () => {
                  try {
                    await deleteSectionFromEcosystem(
                      section._id,
                      ecosystem._id
                    );
                    refetchEcosystem();
                  } catch (er) {
                    console.error(
                      "Failed to delete section from ecosystem",
                      er
                    );
                  }
                }}
              />
            </div>
          );
        })}
        {postingCards?.length > 0 && ecosystem?.postingTitle && (
          <Activities
            data={boardWithLinearPosts}
            hasActions
            background={"rgba(127, 179, 131, 0.06)"}
            TitleComponent={Title}
            titleProps={{
              title: ecosystem?.postingTitle
                ? ecosystem?.postingTitle
                : "טיפים ועצות שימושיות Tips",
              AreIconsOnTheSameLine: true,
              showDivider: false,
            }}
            SliderComponent={SliderCard}
            sliderProps={{
              cardWidth: 0,
              data: boardWithLinearPosts,
              HeartColor: "green",
              titleColor: "#9FA3A8",
              liked: false,
              showLocation: false,
              link: `/activities/board/zone/${ecosystem._id}/section/custom`,
              showDate: false,
              locationFontSize: "17px",
              locationColor: "#316788",
              hebrewDescription: "בואו וניתן באהבה למי שצריך",
            }}
            LinearComponent={LinearCard}
            linearProps={{
              data: [boardWithLinearPosts?.[0], boardWithLinearPosts?.[1]],
              liked: true,
              linkAddress: "More",
            }}
            openedCard={isWideScreen || isMediumScreen ? undefined : 0}
            isLinearOpen
          />
        )}
        <Activities
          data={volunteerForum}
          hasActions
          InitialStateOfCards
          TitleComponent={Title}
          titleProps={{
            hebrewTitle: ecosystem?.boardTitle
              ? ecosystem?.boardTitle
              : isEnglish
              ? "Volunteer Forum"
              : "פורום מתנדבים",
            englishTitle: ecosystem?.boardTitle
              ? ecosystem?.boardTitle
              : "Volunteer Forum",
            showDivider: false,
            AreIconsOnTheSameLine: true,
            link: `/activities/board/zone/${ecosystem._id}`,
          }}
          SliderComponent={SliderCard}
          sideLink={
            isUserAuthorizedForCreate && {
              title: "Create",
              link: `/activities/board/zone/${ecosystem._id}`,
            }
          }
          sliderProps={{
            data: volunteerForum,
            HeartColor: "green",
            locationFontSize: "17px",
            locationColor: "#316788",
            liked: false,
            showLocation: false,
            showDate: false,
            hebrewDescription: "בואו וניתן באהבה למי שצריך",
            handleClick: (clickedIndex) => {
              navigateToCommunitySection(
                clickedIndex,
                clickedCardIndex,
                setClickedCardIndex,
                navigate,
                ecosystem._id
              );
            },
          }}
        />
        {isSmallCommunity && !hideEmegency && <EmergencyButtons />}
        {!isSmallCommunity && (
          <Activities
            data={getEmergencyData(isEnglish)}
            hasActions
            InitialStateOfCards
            SliderComponent={Emergency}
            sliderProps={{
              enTitle: "Emergency ",
              hebTitle: "התארגנות וסיוע אישי במצבי חירום ",
              data: getEmergencyData(isEnglish),
              hasShadow: true,
              zone: ecosystem._id,
              englishLogo: isEnglish,
              hideLogoText: true,
              icon: <RamatHasharonEmergencyIcon />,
            }}
            LinearComponent={EmergencyLinearCard}
            linearProps={{
              liked: false,
              linkAddress: isEnglish ? "More info" : "פרטים נוספים",
              link: `/${MAIN_ROUTES.PRIVATE_ZONE}/${ecosystem._id}/linear-card-details`,
            }}
          />
        )}
        {showSponsers ? (
          <LogosSlider
            title={"Ecosystem Sponsors"}
            data={vetSponsersData}
            backgroundColor={"#7FB38340"}
          />
        ) : (
          <LogosSlider
            title={
              ecosystem.sponsorsTitle && ecosystem.sponsorsTitle !== ""
                ? ecosystem.sponsorsTitle
                : "Sponsors"
            }
            data={ecosystem.sponsors ?? []}
            backgroundColor={"#DBF4F0CC"}
          />
        )}
        {!isAuthenticated && <CopyRightAndTerms />}
      </Wrapper>
    </>
  );
}

export default RamatHasharon;
