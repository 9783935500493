import React from "react";

function PlayIcon(props) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_609_1896)">
        <path
          d="M0.00183105 8.2672C0.0838921 8.10174 0.068367 7.91766 0.0932071 7.74245C0.594888 4.22358 3.36279 1.32661 6.86613 0.660803C11.4025 -0.201503 15.7389 2.61607 16.7844 7.10503C17.6241 10.7108 15.9536 14.5269 12.7359 16.3624C11.6354 16.9901 10.4586 17.3636 9.19488 17.4629C9.16871 17.4651 9.13855 17.458 9.12879 17.4944H7.80118C7.77678 17.4376 7.72488 17.4629 7.68585 17.4589C6.7681 17.3636 5.8774 17.1537 5.04304 16.7576C2.26938 15.4407 0.610413 13.2711 0.09232 10.2375C0.0612699 10.0566 0.0767949 9.86805 0.00183105 9.69461V8.2672ZM8.50735 1.35278C4.28985 1.34258 0.864581 4.75853 0.854379 8.98401C0.844176 13.2042 4.24638 16.6206 8.48029 16.6419C12.6969 16.6631 16.1372 13.2326 16.1417 9.0022C16.1461 4.78027 12.7359 1.36298 8.50735 1.35278Z"
          fill="#1894CD"
        />
        <path
          d="M5.94631 8.99791C5.94631 8.00297 5.94897 7.0076 5.94454 6.01266C5.9432 5.74208 6.02393 5.52118 6.27101 5.39033C6.51364 5.26214 6.73454 5.32734 6.95322 5.47372C8.43919 6.46865 9.92826 7.45871 11.416 8.45098C11.8818 8.76148 11.8813 9.23611 11.4156 9.54705C9.92782 10.5393 8.43875 11.5294 6.95278 12.5243C6.7341 12.6707 6.51275 12.7346 6.27056 12.6064C6.02305 12.4755 5.94365 12.2542 5.94454 11.9836C5.94897 10.9887 5.94631 9.99284 5.94631 8.99791ZM10.7067 8.99791C9.39553 8.12407 8.10828 7.26531 6.81571 6.40389V11.5928C8.11671 10.7252 9.4013 9.86864 10.7067 8.99791Z"
          fill="#1894CD"
        />
      </g>
      <defs>
        <clipPath id="clip0_609_1896">
          <rect
            width="16.9982"
            height="16.988"
            fill="white"
            transform="translate(0.00183105 0.507812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlayIcon;
