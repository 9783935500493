import React from "react";

function BiggerThanIcon({ color = "#4B778D", width = 8, height = 8 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M3 1.5L5.5 4L3 6.5"
        stroke={color}
        stroke-opacity="0.745098"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BiggerThanIcon;
