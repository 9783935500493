import React, { useEffect, useRef, forwardRef, useContext } from "react";
import styled from "styled-components";
import HeartIcon from "../../../../../../../../assets/icons/Activities/TogetherInTheCity/HeartIcon";
import RedHeartIcon from "../../../../../../../../assets/icons/Activities/TogetherInTheCity/RedHeartIcon";
import GreenHeartIcon2 from "../../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/GreenHeartIcon2";
import LikedGreenHeartIcon2 from "../../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/LikedGreenHeartIcon2";
import { useNavigate } from "react-router-dom";
import useFetchUser from "../../../../../../../../customHooks/useFetchUser";
import { LanguageContext } from "../../../../../../../../store/languageContext";

const CardContainer = styled.div`
  width: ${(props) =>
    props.isActive ? "296px" : props.cardWidth ? props.cardWidth : "260px"};
  height: ${(props) =>
    props?.height ? props.height : props.isActive ? "170px" : "158px"};
  display: flex;
  flex-direction: column;
  border-radius: 13.58px 13.58px 14px 14px;
  border-radius: 14px;
  border: 1px solid #ccc;
  overflow: auto;
  background: #fff;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  position: relative;
`;
const OverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28px;
  border-radius: 13.58px 13.58px 0px 0px;
  background: rgba(255, 255, 255, 0.7);
  color: #316788;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CardImage = styled.img`
  width: 100%;
  height: ${(props) =>
    props.height
      ? props.height
      : props.showLocation
      ? props.isActive
        ? "58%"
        : "58%"
      : props.isActive
      ? "79%"
      : "78%"};
  object-fit: cover;
  opacity: 0.75;
`;

const CardContent = styled.div`
  direction: ${(props) => (props.lang === "heb" ? "rtl" : "ltr")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.h2`
  color: ${(props) => (props.titleColor ? props.titleColor : "#58656D")};
  /* font-size: ${(props) =>
    props.showLocation
      ? props.titleFontSize
        ? props.titleFontSize
        : "14px"
      : "14px"}; */
  font-size: ${(props) =>
    props.isActive
      ? "16px"
      : props.titleFontSize
      ? props.titleFontSize
      : "14px"};
  font-family: ${(props) =>
    props.titleFontFamily ? props.titleFontFamily : "Assistant"};
  font-weight: 600;
  margin: 7px 10px;
  margin: ${(props) => (props.isActive ? "7.5px 10px" : "5px 10px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LocationDateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${(props) => props.isActive && "6px 10px"};
  margin: 6px 10px;
  color: ${(props) => (props.locationColor ? props.locationColor : "#9fa3a8")};
  font-weight: ${(props) =>
    props.locationFontWeight ? props.locationFontWeight : "600"};
  font-size: ${(props) => props.locationFontSize ?? "11px"};
`;
const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`;
const Details = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
  margin: 0;
  font-size: ${(props) =>
    props.locationFontSize ? props.locationFontSize : "11px"};
  font-family: ${(props) =>
    props.locationFontFamily ? props.locationFontFamily : "Assistant"};
`;
const DateDivider = styled.span`
  font-size: ${(props) => (props.isActive ? "14.81px" : "13px")};
  margin: 0 5px;
`;
const LocationIconWrapper = styled.div`
  width: ${(props) => (props.isActive ? "26.20px" : "23px")};
  height: ${(props) => (props.isActive ? "26.20px" : "23px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 3px dotted #ccc;
  border-top: 1px dashed #d9d9d9;
  margin: 0;
`;

const Card = forwardRef((props, ref) => {
  const {
    image,
    coverImages,
    title,
    location,
    user_id,
    date,
    hebrewDescription,
    lang,
    cardWidth,
    HeartColor,
    liked,
    showLocation,
    showDate = true,
    showName,
    isHeartVisable = true,
    link,
    index,
    hasHiddenContent,
    isActive,
    handleClick,
    isPublicZone,
    titleFontSize,
    titleFontFamily,
    titleFontWeight,
    titleColor,
    locationFontSize,
    locationFontFamily,
    locationFontWeight,
    locationColor,
    zone,
    handleFocus,
    activeSlide,
    cardHeight,
    imageHeight,
  } = props;
  const navigate = useNavigate();
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const targetLink = isPublicZone ? `/${zone}${link}` : link;
  const { user } = useFetchUser({ userId: user_id });
  const onClick = () => {
    handleClick && handleClick(index);
    targetLink && navigate(targetLink);
  };

  const cardRef = useRef(null);

  useEffect(() => {
    const checkCardPosition = () => {
      const card = cardRef.current;
      if (card && (activeSlide === 0 ? true : false)) {
        const screenWidth = window.innerWidth < 430 ? window.innerWidth : 430;
        const cardLeft = card.getBoundingClientRect().left;
        const cardCenter = cardLeft + card.offsetWidth / 2;
        const threshold = isActive ? 147 : 129;
        if (
          Math.abs(cardCenter - screenWidth / 2) <= threshold &&
          handleFocus
        ) {
          handleFocus(index);
        }
      }
    };

    // Add an event listener to check the card position when the window is scrolled
    ref.current.addEventListener("scroll", checkCardPosition, true);

    // Call the checkCardPosition function initially
    checkCardPosition();
  }, [ref.current]);

  return (
    <CardContainer
      cardWidth={cardWidth}
      hasHiddenContent={hasHiddenContent}
      isActive={isActive}
      height={cardHeight}
      onClick={() => onClick()}
      ref={cardRef}
      id="card"
    >
      {hebrewDescription && currentLanguage === "עב" && (
        <OverLay>{hebrewDescription}</OverLay>
      )}
      <CardImage
        src={coverImages && coverImages.length > 0 ? coverImages[0] : image}
        alt={title}
        showLocation={showLocation}
        isActive={isActive}
        isHeartVisable={isHeartVisable}
        height={imageHeight}
      />
      <CardContent lang={lang}>
        {showLocation && (
          <Title
            showLocation={showLocation}
            isActive={isActive}
            titleFontSize={titleFontSize}
            titleFontFamily={titleFontFamily}
            titleFontWeight={titleFontWeight}
            titleColor={titleColor}
          >
            {title}
          </Title>
        )}
        {showLocation && <HorizontalLine />}
        <LocationDateRow
          isActive={isActive}
          locationColor={locationColor}
          locationFontSize={locationFontSize}
        >
          <DetailsWrapper>
            <Details
              isActive={isActive}
              locationFontSize={locationFontSize}
              locationFontFamily={locationFontFamily}
              locationFontWeight={locationFontWeight}
              locationColor={locationColor}
            >
              {(showLocation ? location : showName ? user?.name : title) ??
                title}
            </Details>
            {showDate && (
              <DateDivider
                isActive={isActive}
                locationFontSize={locationFontSize}
              >
                |
              </DateDivider>
            )}
            {showDate && (
              <Details isActive={isActive} locationFontSize={locationFontSize}>
                {date}
              </Details>
            )}
          </DetailsWrapper>
          <LocationIconWrapper HeartColor={HeartColor} isActive={isActive}>
            {isHeartVisable && (
              <>
                {HeartColor === "green" ? (
                  liked ? (
                    <LikedGreenHeartIcon2
                      width={isActive && 26}
                      height={isActive && 26}
                    />
                  ) : (
                    <GreenHeartIcon2
                      width={isActive && 26}
                      height={isActive && 26}
                    />
                  )
                ) : liked ? (
                  <RedHeartIcon />
                ) : (
                  <HeartIcon />
                )}
              </>
            )}
          </LocationIconWrapper>
        </LocationDateRow>
      </CardContent>
    </CardContainer>
  );
});

export default Card;
