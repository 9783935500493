import MyBestFriend from "../View/CustomViews/MyBestFreind";
import RamatHasharon from "../View/CustomViews/RamatHasharon";
import VolunteersAndSpendTime from "../View/CustomViews/VolunteersAndSpendTime";

export const ROUTES = {
  CITY: "together",
  SCHOOL: "school",
  STUDENT: "student",
  VOLUNTEER_BENEFITS: "volunteer-benefits",
  ORGANIZATION: "organization",
  PUBLIC_ORGANIZATION: "public-organization",
  MAP: "map",
};

export const zoneComponentMap = {
  "65adf777944824b0357f4e82": MyBestFriend,
  "659e2467e6ad34f2f223b4f4": RamatHasharon,
  "6677a60e31d965b52d4e58d8": VolunteersAndSpendTime,
  "6679510731d965b52d4f4067": VolunteersAndSpendTime,
  "667c720431d965b52d51abf7": VolunteersAndSpendTime,
};
