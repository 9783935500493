import { useEffect, useContext } from "react";
import { LanguageContext } from "../store/languageContext";

function useLanguageSetter(initialLanguage, revertLanguage) {
  const { setCurrentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    setCurrentLanguage(initialLanguage);
    // If revertLanguage setCurrentLanguage to revertLanguage on component unmount
    return revertLanguage
      ? () => {
          setCurrentLanguage(revertLanguage);
        }
      : undefined;
  }, [initialLanguage, revertLanguage, setCurrentLanguage]);
}

export default useLanguageSetter;
