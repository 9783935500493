import { CommunitySections } from "./Board";

export const navigateToCommunitySection = (
  clickedIndex,
  clickedCardIndex,
  setClickedCardIndex,
  navigate,
  zone
) => {
  if (clickedIndex === 0 && clickedCardIndex === 0) {
    navigate(
      `/activities/board/zone/${zone}/section/${CommunitySections.CommunityBoard}`
    );
  }
  if (clickedIndex === 1 && clickedCardIndex === 1) {
    navigate(
      `/activities/board/zone/${zone}/section/${CommunitySections.GiveReceive}`
    );
  }
  if (clickedIndex === 2 && clickedCardIndex === 2) {
    navigate(
      `/activities/board/zone/${zone}/section/${CommunitySections.Networks}`
    );
  }
  setClickedCardIndex(clickedIndex);
};
