import { useMemo } from "react";
import useEcosystemsRoutes from "../domain/Auth/landingPage/hooks/useEcosystemsRoutes";
import useCurrentUser from "./useCurrentUser";
import { ECO_SYSTEMS } from "../domain/Ecosystem/constant";

export const useGetUserDomains = () => {
  const { currentUser, isSuperUser } = useCurrentUser();
  const { allEcosystems } = useEcosystemsRoutes();
  const userDomains = useMemo(() => {
    if (isSuperUser) {
      return allEcosystems;
    }
    const memberArr = currentUser?.member ?? [];
    const teamMemberArr = currentUser?.teamMember ?? [];
    const adminArr = currentUser?.admin ?? [];
    const concatArr = memberArr.concat(teamMemberArr).concat(adminArr);

    const ecosystems = [];

    for (const i in concatArr) {
      const entry = Object.entries(ECO_SYSTEMS).find(
        ([key, val]) => val === concatArr[i]
      );
      if (entry == null) {
        continue;
      }
      ecosystems.push({
        name: entry[0],
        route: "",
        value: concatArr[i],
      });
    }

    return ecosystems;
  }, [
    allEcosystems,
    currentUser?.admin,
    isSuperUser,
    currentUser?.member,
    currentUser?.teamMember,
  ]);

  return userDomains;
};
