import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";
import {
  HEADER_TABS,
  SUB_HEADER_TABS,
  VISITOR_HEADER_TABS,
} from "../constants";
import EmergencyContactV2 from "../../../../Emergency/Contacts/components/EmergencyContact/EmergencyContactV2";
import { Dialog, styled as MuiStyled } from "@mui/material";

const Modal = MuiStyled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    background: "#FAFFFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "359px",
    minHeight: "33px",
    overflowX: "hidden",
    border: "3px solid #0270A5",
    padding: "20px 0",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(173, 202, 230, 0.5)",
  },
}));

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  padding: 0 5px;
`;

const TabContainer = styled.div`
  position: relative;
  flex: 1;
`;

const Tab = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #316788;
  border: 1px solid #316788;
  background-color: #f3f2f2;
  border-radius: 5px;
  height: 32.15px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #316788;
    color: #fff;
  }
  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `}
  @media (max-width: 500px) {
    font-size: 12px;
  }
  @media (max-width: 430px) {
    font-size: 10px;
  }
`;

const HeaderTabs = () => {
  const { isVisitor } = useCurrentUser();
  const tabs = isVisitor ? VISITOR_HEADER_TABS : HEADER_TABS;
  const [hoveredTab, setHoveredTab] = useState(null);
  const [isClicked, setIsClicked] = useState(true);
  const [clickedTab, setClickedTab] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);

  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setClickedTab(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      {tabs.map((tab) => (
        <TabContainer key={tab.label}>
          <Tab
            customStyles={tab?.styles}
            onMouseEnter={() => setHoveredTab(tab.label)}
            onMouseLeave={() => setHoveredTab(null)}
            onClick={() =>
              setClickedTab(clickedTab === tab.label ? null : tab.label)
            }
          >
            {hoveredTab === tab.label ? tab.hoverIcon : tab.icon}
            {tab.label}
          </Tab>
          {clickedTab &&
            clickedTab === tab.label &&
            SUB_HEADER_TABS[clickedTab] && (
              <div
                style={{
                  position: "absolute",
                  top: "40px",
                  left: "-30px",
                  zIndex: 1002,
                }}
              >
                {React.cloneElement(SUB_HEADER_TABS[clickedTab].component, {
                  tab: SUB_HEADER_TABS[clickedTab],
                  isClicked,
                  setIsClicked,
                  showContactPopup: () => setShowContactPopup(true),
                })}
              </div>
            )}
        </TabContainer>
      ))}
      <Modal open={showContactPopup} onClose={() => setShowContactPopup(false)}>
        <EmergencyContactV2
          onClose={() => setShowContactPopup(false)}
          styles={{
            title: {
              fontSize: "20px !important",
              fontWeight: 600,
              color: "#316788",
            },
            button: {
              color: "#1279AA",
              borderColor: "#1279AA",
              margin: "10px auto 0",
            },
          }}
        />
      </Modal>
    </Wrapper>
  );
};

export default HeaderTabs;
