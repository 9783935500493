import React from "react";
import styled from "styled-components";
import { PlayIcon } from "../../assets/icons/Activities/CommunityBoard";
import { Link } from "react-router-dom";
import { getVideoThumbnailAndId } from "./utils";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PreviewLinkWrapper = styled(Link)`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 3px;
  color: #696d72;
  font-family: Assistant;
  font-size: 15px;
  font-weight: 600;
`;

const PlayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LinkWrapper = styled(Link)`
  color: #696d72;
  font-family: Assistant;
  font-size: 13px;
  font-weight: 600;
`;

function VideoPreview({ videoUrl }) {
  const { thumbnailUrl, videoId } = getVideoThumbnailAndId(videoUrl);

  return (
    <Wrapper>
      {videoId ? (
        <VideoWrapper>
          <Link to={videoUrl}>
            <img
              src={thumbnailUrl}
              alt="Video Preview"
              style={{ width: "100%", height: "auto" }}
            />
          </Link>
          <PreviewLinkWrapper to={videoUrl}>
            Video <PlayIcon size={13} />
          </PreviewLinkWrapper>
          <PlayIconWrapper>
            <PlayIcon />
          </PlayIconWrapper>
        </VideoWrapper>
      ) : (
        <LinkWrapper to={videoUrl}>{videoUrl}</LinkWrapper>
      )}
    </Wrapper>
  );
}

export default VideoPreview;
