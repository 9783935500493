import blue from "./blueCircle.svg";
import gray from "./grayCircle.svg";
import green from "./greenCircle.svg";
import orange from "./orangeCircle.svg";
import lightOrange from "./LightOrangeCircle.svg";
import darkRed from "./DarkRedCircle.svg";
import darkBlue from "./DarkBlueCircle.svg";
import red from "./RedCircle.svg";

export const MarkerIcon = {
  Orange: "orange",
  Blue: "blue",
  Green: "green",
  Gray: "gray",
  Red: "red",
  LightOrange: "lightOrange",
  DarkBlue: "darkBlue",
  DarkRed: "darkRed",
};

export const CircleSVGs = {
  [MarkerIcon.Blue]: blue,
  [MarkerIcon.Gray]: gray,
  [MarkerIcon.Green]: green,
  [MarkerIcon.Orange]: orange,
  [MarkerIcon.LightOrange]: lightOrange,
  [MarkerIcon.DarkBlue]: darkBlue,
  [MarkerIcon.DarkRed]: darkRed,
  [MarkerIcon.Red]: red,
};

export const placesColors = {
  hospital: {
    value: "hospital",
    color: MarkerIcon.Green,
  },
  police: {
    value: "police",
    color: MarkerIcon.Orange,
  },
  fire: {
    value: "fire",
    color: MarkerIcon.DarkRed,
  },
};
