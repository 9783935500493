import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50.5px;
  height: 26.5px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 13.5px 16px 16px 13.5px;
  cursor: pointer;
`;

function Icon(props) {
  const { name, color, backgroundColor, link, image, handleIconChange } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
    handleIconChange && handleIconChange(name);
  };

  return (
    <Wrapper
      color={color}
      backgroundColor={backgroundColor}
      onClick={handleClick}
    >
      <img src={image} alt="" />
    </Wrapper>
  );
}

export default Icon;
