const colors = {
  white: {
    main: "white",
  },
  warning: {
    90: "#fff2cc", //Alert light
    500: "#ffbf00", //base
    950: "#332600", // Alert Dark
  },
  error: {
    90: "#facdcc", //Error light
    500: "#e80400", //base
    800: "#740200", //ERROR
    950: "#2e0100", //ERROR DARK
  },
};

export default colors;
