import img11 from "../../../../assets/images/Activities/VolunteersAndSpendTime/11.png";
import img12 from "../../../../assets/images/Activities/VolunteersAndSpendTime/12.png";
import img13 from "../../../../assets/images/Activities/VolunteersAndSpendTime/13.png";
import img14 from "../../../../assets/images/Activities/VolunteersAndSpendTime/14.png";
import img15 from "../../../../assets/images/Activities/VolunteersAndSpendTime/15.png";

import img21 from "../../../../assets/images/Activities/VolunteersAndSpendTime/21.png";
import img22 from "../../../../assets/images/Activities/VolunteersAndSpendTime/22.png";
import img23 from "../../../../assets/images/Activities/VolunteersAndSpendTime/23.png";
import img24 from "../../../../assets/images/Activities/VolunteersAndSpendTime/24.png";
import img25 from "../../../../assets/images/Activities/VolunteersAndSpendTime/25.png";
import img26 from "../../../../assets/images/Activities/VolunteersAndSpendTime/26.png";

import img31 from "../../../../assets/images/Activities/VolunteersAndSpendTime/31.png";
import img32 from "../../../../assets/images/Activities/VolunteersAndSpendTime/32.png";
import img33 from "../../../../assets/images/Activities/VolunteersAndSpendTime/33.png";
import img34 from "../../../../assets/images/Activities/VolunteersAndSpendTime/34.png";
import img35 from "../../../../assets/images/Activities/VolunteersAndSpendTime/35.png";
import img36 from "../../../../assets/images/Activities/VolunteersAndSpendTime/36.png";

export const hubSliderData = [
  [
    { image: img11, title: "איכות סביבה" },
    { image: img12, title: "העשרה" },
    { image: img13, title: "תרבות" },
    { image: img14, title: "מוסיקה" },
    { image: img15, title: "טכנולוגיה" },
  ],
  [
    { image: img21, title: "ספורט" },
    { image: img22, title: "מוסיקה" },
    { image: img23, title: "רפואה", titleColor: "#003F5D" },
    { image: img24, title: "טכנולוגיה" },
    { image: img25, title: "אומנות" },
    { image: img26, title: "הורות" },
  ],
  [
    { image: img31, title: "שפה" },
    { image: img32, title: "ריקוד" },
    { image: img33, title: "פסיכולוגיה", titleColor: "#003F5D" },
    { image: img34, title: "היסטוריה" },
    { image: img35, title: "גיאוגרפיה", titleColor: "#003F5D" },
    { image: img36, title: "פילוסופיה" },
  ],
];
