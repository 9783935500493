import React from "react";
import CopyRightAndTerms from "../../../Common/Layout/Footer/components/CopyRightAndTerms";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import styled from "styled-components";
import GreenAppleIcon from "../../../../assets/icons/Common/Apples/GreenAppleIcon";
import { Link } from "react-router-dom";

const VolunteerContainer = styled.div`
  background: ${({ backgroundColor }) => backgroundColor ?? "#fafffa"};
  color: #53575f;
  text-decoration-line: underline;
  letter-spacing: 0.14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 15px 0 20px 0;
`;
const AboutUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #53575f;
  cursor: pointer;
  gap: 3px;
  color: #527f55;
`;

function LandingFooter({ hasAdminLogin, backgroundColor }) {
  const { isWideScreen } = useMediaQueryConstants();
  return (
    <div>
      <VolunteerContainer backgroundColor={backgroundColor}>
        <AboutUsContainer>
          About Us <GreenAppleIcon width={20} height={22} />
        </AboutUsContainer>
        <Link to={"/social"} style={{ color: "#527f55" }}>
          Volunteers Information
        </Link>
      </VolunteerContainer>
      <div style={{ width: "100%", height: "2px", background: "#7FB38380" }} />
      <CopyRightAndTerms
        hasAdminLogin={hasAdminLogin}
        isRow={isWideScreen}
        justifyContent={"space-evenly"}
        showVolunteerContainer={false}
      />
    </div>
  );
}

export default LandingFooter;
