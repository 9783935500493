import * as React from "react";
const GroupIcon = ({ color = "#316788", size = 25 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 25 26"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.369}
      d="M12.325 17.836a3.852 3.852 0 1 0 0-7.703 3.852 3.852 0 0 0 0 7.703ZM18.873 11.672a5.759 5.759 0 0 1 4.622 2.31M1.156 13.983a5.757 5.757 0 0 1 4.622-2.311"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.369}
      d="M6.779 21.3a6.172 6.172 0 0 1 11.093 0M5.778 11.67A3.082 3.082 0 1 1 8.8 8.012M15.85 8.011a3.082 3.082 0 1 1 3.023 3.66"
    />
  </svg>
);
export default GroupIcon;
