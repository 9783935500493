import React, { cloneElement, useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import LeftGreenArrow from "../../assets/icons/Activities/TogetherInTheCity/LeftGreenArrow";
import RightGreenArrow from "../../assets/icons/Activities/TogetherInTheCity/RightGreenArrow";
const CardSliderContainer = styled.div`
  width: 100%;
  position: relative;
  /* margin: 0 0 0 12px; */
  /* overflow-x: scroll; */
  overflow-x: ${(props) => (props.overflowX ? props.overflowX : "scroll")};
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; //Enables smooth scrolling on iOS
  /* Hide the scrollbar */
  scrollbar-width: none; //Firefox
  -ms-overflow-style: none; //Internet Explorer 10+
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const SlidingCardsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: end;
  width: ${(props) => props.width};
  transition: transform 0.5s;
  transform: translateX(${(props) => props.translateX + props.unit});
`;
const LeftIconsWrapper = styled.div`
  width: 17px;
  display: flex;
  align-items: center;
  color: red;
  position: absolute;
  right: 5px;
  top: 180px;
  top: 50%;
  transform: translate(-5px, -50%);
  z-index: 1800;
`;
const RightIconsWrapper = styled.div`
  width: 17px;
  display: flex;
  align-items: center;
  color: red;
  position: absolute;
  left: 5px;
  top: 180px;
  top: 50%;
  transform: translate(-5px, -50%);
  z-index: 1800;
`;
const HorizontalSlider = ({
  children,
  data,
  cardWidth,
  gap,
  unit,
  currentSlide = 0,
  navigation,
  overflowX,
  closeModal,
  chooseActiveSlide,
  hasContext,
  key,
  lang,
  liked,
  hasStar,
  hasOverlay,
  hasTitle,
  handleAppleClick,
}) => {
  const [targetSlide, setTargetSlide] = useState(currentSlide);
  const [activeSlide, setActiveSlide] = useState(0);
  const [translatX, setTranslateX] = useState(currentSlide * -cardWidth);
  const handleClick = (index) => {
    setActiveSlide(index);
    chooseActiveSlide(index);
  };
  const el = data.map((d, index) =>
    cloneElement(children, {
      ...d,
      closeModal,
      index,
      handleClick,
      isActive: index === activeSlide,
    })
  );

  const next = () => {
    if (targetSlide !== data.length - 1) {
      setTargetSlide((targetSlide) => targetSlide + 1);
      handleAppleClick(targetSlide + 1);
    }
  };
  const prev = () => {
    if (targetSlide > 0) {
      setTargetSlide((targetSlide) => targetSlide - 1);
      handleAppleClick(targetSlide - 1);
    }
  };
  useEffect(() => {
    setTranslateX((translatX) => targetSlide * -cardWidth - targetSlide * gap);
  }, [targetSlide]);
  useEffect(() => {
    setTargetSlide(currentSlide);
  }, [currentSlide]);
  return (
    <CardSliderContainer id="card-slider-container" overflowX={overflowX}>
      {navigation && (
        <RightIconsWrapper onClick={prev}>
          <RightGreenArrow />
        </RightIconsWrapper>
      )}
      <SlidingCardsWrapper
        width={`${cardWidth * data.length + gap * data.length}${unit}`}
        translateX={translatX}
        unit={unit}
      >
        {el}
      </SlidingCardsWrapper>
      {navigation && (
        <LeftIconsWrapper onClick={next}>
          <LeftGreenArrow />
        </LeftIconsWrapper>
      )}
    </CardSliderContainer>
  );
};

export default HorizontalSlider;
