import React from "react";
import styled from "styled-components";
import LikedGreenHeartIcon2 from "../../../../assets/icons/Activities/TogetherInTheCity/gderot/LikedGreenHeartIcon2";
import GreenHeartIcon2 from "../../../../assets/icons/Activities/TogetherInTheCity/gderot/GreenHeartIcon2";
import { Link } from "react-router-dom";
import GrayDoubleArrowIcon from "../../../../assets/icons/GrayDoubleArrowIcon";
import PlayIcon from "../../../../assets/icons/PlayIcon";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: ${(props) => props.height ?? "76px"};
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10.717px;
  background: #fff;
  color: #58656d;
  box-shadow: 4px 4px 10px 0px #e8e8e8;
  margin: 11px 0;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
`;
const ImageWrapper = styled.div`
  height: 100%;
  width: ${(props) => props.width ?? "40%"};
  flex-shrink: 0;
  padding: 0px;
  margin: 0;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  opacity: 0.75;
`;
const ContentAndImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;
const TextWrapper = styled.div`
  width: ${(props) => `calc(100% - ${props.imageWidth ?? "40%"} - 10px)`};
  margin: 0 5px;
  direction: ${(props) => (props.lang === "heb" ? "rtl" : "ltr")};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TitleWrapper = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const OhterInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;
const Text = styled.p`
  color: ${({ color }) => color ?? "#58656d"};
  font-family: Assistant;
  width: 90%;
  text-align: center;
  font-size: ${(props) => props.titleFontSize ?? "16px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 8px 0;
`;
const TextWithLink = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
`;
const LinkedText = styled.div`
  width: 70%;
  color: #1894cd;
  font-family: Assistant;
  font-size: 15px;
  font-weight: 700;
  text-decoration-line: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Date = styled.p`
  color: var(--preview-txt, #58656d);
  font-family: Assistant;
  width: 35%;
  text-align: center;
  font-size: ${(props) => props.otherDetailsFontSize ?? "14px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;
const Time = styled.p`
  color: var(--preview-txt, #58656d);
  font-family: Assistant;
  width: 25%;
  text-align: center;
  font-size: ${(props) => props.otherDetailsFontSize ?? "14px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;
const SignupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;
const LinkTo = styled(Link)`
  color: var(--preview-txt, #58656d);
  font-family: Assistant;
  font-size: ${(props) => props.otherDetailsFontSize ?? "14px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const GreenHeartIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  position: absolute;
  right: 2px;
  top: 8px;
`;

const Card = ({
  image,
  title,
  date,
  time,
  liked,
  height,
  imageWidth,
  showHeart = true,
  titleFontSize,
  otherDetailsFontSize,
  linkAddress = "Sign up",
  to = "",
  onClick,
  titlelink,
  titleColor,
}) => {
  return (
    <Wrapper onClick={onClick} height={height}>
      <ContentAndImageWrapper>
        <ImageWrapper width={imageWidth}>
          <Image src={image} />
        </ImageWrapper>
        <TextWrapper imageWidth={imageWidth}>
          <TitleWrapper>
            {titlelink ? (
              <TextWithLink titleFontSize={titleFontSize}>
                <LinkedText>{title}</LinkedText>
                <PlayIcon />
              </TextWithLink>
            ) : (
              <Text color={titleColor} titleFontSize={titleFontSize}>
                {title}
              </Text>
            )}
          </TitleWrapper>
          <OhterInfoWrapper>
            <Date otherDetailsFontSize={otherDetailsFontSize}>{date}</Date>{" "}
            <Time otherDetailsFontSize={otherDetailsFontSize}>{time}</Time>
            <SignupWrapper>
              <LinkTo otherDetailsFontSize={otherDetailsFontSize} to={to}>
                {" "}
                {linkAddress}
              </LinkTo>
              <GrayDoubleArrowIcon />
            </SignupWrapper>
          </OhterInfoWrapper>
        </TextWrapper>
        {showHeart && (
          <GreenHeartIconWrapper>
            {liked ? <LikedGreenHeartIcon2 /> : <GreenHeartIcon2 />}
          </GreenHeartIconWrapper>
        )}
      </ContentAndImageWrapper>
    </Wrapper>
  );
};

export default Card;
