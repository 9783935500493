import * as React from "react";
const RedCircle = ({ color = "red" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={29}
    fill="none"
    viewBox="0 0 28 29"
  >
    <circle cx={13.867} cy={14.242} r={13.867} fill={color} />
  </svg>
);

export default RedCircle;
