import React, { useContext, useState, useRef } from "react";
import Draggable from "react-draggable";
import styled, { keyframes } from "styled-components";
import mapImage from "./mapImage.png";
import { RedCircle } from "../../assets/icons";
import { EmergencyOverlaysContext } from "../../domain/Emergency/Contacts/EmergencyOverlaysContext";

const Wrapper = styled.div`
  position: absolute;
  border-radius: 7.449px;
  border: 2.273px solid #000;
  background: #fafffa;
  box-shadow: 0px 11.094px 11.094px 0px rgba(0, 0, 0, 0.25);
  width: 133.706px;
  height: 122.16px;
  top: 15px;
  right: 10px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Title = styled.div`
  color: #003f5d;
  font-family: Assistant;
  font-size: 8.194px;
  font-weight: 700;
  letter-spacing: 0.164px;
  text-align: center;
`;

const Image = styled.img`
  width: 106.89px;
  height: 76.066px;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  cursor: pointer;
`;

const flash = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const FlashingRedCircle = styled.div`
  animation: ${flash} 1s infinite;
`;

const MapPopup = ({ onClose = () => {}, id }) => {
  const { setShowOverlay, overlayProps, setOverlayProps, theme } = useContext(
    EmergencyOverlaysContext
  );
  const [isDragged, setIsDragged] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  const dragThreshold = 10;

  const handleStart = (e) => {
    setIsDragged(false);
    if (e.touches) {
      setStartPos({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    } else {
      setStartPos({ x: e.clientX, y: e.clientY });
    }
  };

  const handleStop = (e) => {
    let endPos;
    if (e.changedTouches) {
      endPos = {
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY,
      };
    } else {
      endPos = { x: e.clientX, y: e.clientY };
    }

    const distX = Math.abs(startPos.x - endPos.x);
    const distY = Math.abs(startPos.y - endPos.y);

    if (distX < dragThreshold && distY < dragThreshold) {
      handleClick();
    } else {
      setIsDragged(true);
    }
  };

  const handleClick = () => {
    if (!isDragged) {
      setShowOverlay(true);
      setOverlayProps({
        id,
        forceShowConfirm: true,
        isNeedHelp: Object.keys(overlayProps).length === 0 ? true : undefined,
        showSteps: true,
        ...overlayProps,
      });
      onClose?.(id);
    }
  };

  return (
    <Draggable onStart={handleStart} onStop={handleStop}>
      <Wrapper>
        <Title>
          <div>בקשתך לסיוע התקבלה,</div>
          <div>העזרה בדרך אליך!</div>
        </Title>
        <Image src={mapImage} alt="mapImage" />
        <CloseWrapper>
          <FlashingRedCircle>
            <RedCircle color={theme?.primary} />
          </FlashingRedCircle>
        </CloseWrapper>
      </Wrapper>
    </Draggable>
  );
};

export default MapPopup;
