import React from "react";

const FontsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="21"
      fill="none"
      viewBox="0 0 28 21"
    >
      <g fill={color ?? "#2A8A31"}>
        <path d="M.225 20.3c.447-1.142.898-2.282 1.34-3.426L7.779.788C8.019.165 8.262 0 8.93 0h2.583c.62 0 .873.172 1.097.749l6.345 16.408c.381.985.762 1.97 1.14 2.957.194.503.003.782-.532.782-.915.002-1.83.002-2.744 0-.357 0-.471-.08-.601-.418l-1.456-3.814c-.175-.459-.25-.512-.726-.512H6.342c-.432 0-.524.063-.678.465l-1.456 3.814c-.154.405-.243.466-.675.466-.888 0-1.776-.008-2.663.005-.29.006-.507-.075-.645-.334V20.3zm9.974-7.478h2.395c.539-.002.744-.3.55-.81-.81-2.124-1.626-4.247-2.437-6.372-.09-.235-.224-.41-.5-.412-.275-.002-.414.168-.505.406-.817 2.141-1.637 4.282-2.453 6.424-.174.457.04.763.528.765.808.002 1.614 0 2.422 0v-.001z"></path>
        <path d="M20.148 17.612c-.368-.957-.735-1.908-1.094-2.862-.027-.071-.005-.175.024-.252.544-1.44 1.095-2.878 1.64-4.318.174-.46.505-.68.986-.684a52.16 52.16 0 011.21 0c.531.006.829.213 1.02.714 1.26 3.313 2.519 6.627 3.777 9.941.173.457-.023.744-.504.748-.305.003-.61-.008-.915.003-.294.01-.463-.125-.568-.393-.327-.833-.665-1.662-.999-2.492-.147-.366-.253-.442-.643-.443-1.175-.002-2.35-.002-3.524 0-.124 0-.249.022-.411.037h.001zm2.17-1.936c.34 0 .68.002 1.02 0 .336-.003.426-.127.303-.433-.35-.88-.7-1.76-1.064-2.634-.044-.105-.166-.223-.268-.241-.068-.012-.2.132-.242.232-.364.884-.717 1.772-1.069 2.66-.112.284-.027.411.272.415.349.005.698.002 1.047.002z"></path>
      </g>
    </svg>
  );
};

export default FontsIcon;
