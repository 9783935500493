import requestHandler from "../../../../store/requestHandler";

// Create a new contact for a user
export const createContact = async (id, payload) =>
  await requestHandler("post", `/contacts/${id}`, payload);

// Get all contacts for a user
export const getContacts = async (id) =>
  await requestHandler("get", `/contacts/${id}`);

// Delete a contact by ID
export const deleteContact = async (contactId) =>
  await requestHandler("delete", `/contacts/${contactId}`);
