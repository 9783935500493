import { useMutation } from "@tanstack/react-query";
import { handleMakeCall } from "../api";

function useHandleMakeCall() {
  const { mutateAsync: makeCall, isLoading } = useMutation({
    mutationFn: handleMakeCall,
  });

  return { makeCall, isLoading };
}

export default useHandleMakeCall;
