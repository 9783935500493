import { ORGANIZATION_ROUTES } from "../../Common/Layout/Main/constants";
import VetCover from "../../../assets/images/Activities/Organization/Vet/cover/vet-cover1.png";
import PetsFriendsCover from "../../../assets/images/Activities/Organization/Pets_Friends/cover/pets-cover1.png";
import telAvivImage from "../../../assets/images/Activities/PublicOrganization/slider/tel-aviv.png";

const customZonesData = [
  {
    _id: ORGANIZATION_ROUTES.VET,
    title: "Vets in Clinics",
    image: VetCover,
    type: "organization",
    link: "/public/activities/organization/vet",
    ignoreLink: true,
    route: "organization",
    parent: "659e2467e6ad34f2f223b4f4",
    boardTitle: "Vets in Clinics",
  },
  {
    _id: ORGANIZATION_ROUTES.Pets_Friends,
    title: "Pets Firends",
    image: PetsFriendsCover,
    type: "organization",
    link: "/public/activities/organization/pets-friends",
    ignoreLink: true,
    route: "organization",
    parent: "659e2467e6ad34f2f223b4f4",
    boardTitle: "Pets Firends",
  },
  // {
  //   _id: ORGANIZATION_ROUTES.Taglit,
  //   title: "Taglit",
  //   image: telAvivImage,
  //   type: "organization",
  //   link: "/public/activities/organization/taglit",
  //   ignoreLink: true,
  //   route: "organization",
  //   parent: "659e2467e6ad34f2f223b4f4",
  //   boardTitle: "Taglit",
  // },
];

export default customZonesData;
