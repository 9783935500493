import React from "react";
import SearchIcon from "../../assets/icons/SearchIcon";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: ${(props) => (props.lang === "heb" ? "rtl" : "ltr")};
`;
const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => (props.style.height ? props.style.height : "37px")};
  border-radius: ${(props) =>
    props?.style?.borderRadius ? props?.style?.borderRadius : "7px"};
  border: ${(props) => props?.style?.borderWidth ?? "2px"} solid
    ${(props) =>
      props?.style?.borderColor ? props?.style?.borderColor : "#28b5b5"};
  font-size: ${(props) =>
    props?.style?.fontSize ? props?.style?.fontSize : "15px"};
  font-weight: 600;
  padding: 0
    ${(props) =>
      props?.style?.textPadding ? props?.style?.textPadding : "15px"}
    0;
  color: ${(props) =>
    props?.style?.inputColor ? props?.style?.inputColor : "#000"};
  &::placeholder {
    color: ${(props) =>
      props?.style?.placeholderColor ? props?.style?.placeholderColor : "#000"};
    font-weight: ${(props) =>
      props?.style?.fontWeight ? props?.style?.fontWeight : "normal"};
    letter-spacing: ${(props) =>
      props?.style?.letterSpacing ? props?.style?.letterSpacing : "normal"};
  }
`;
const SearchIconWrapper = styled.div`
  position: absolute;
  right: ${(props) => (props.lang === "heb" ? "auto" : "8px")};
  left: ${(props) => (props.lang === "heb" ? "8px" : "auto")};
  font-size: 5px;
  height: 100%;
  display: flex;
  align-items: center;
`;

function SearchForm(props) {
  const {
    placeholder,
    lang,
    isDisabled,
    handleChange,
    style,
    iconWidth,
    iconHeight,
    iconColor,
    value,
  } = props;
  return (
    <Wrapper lang={lang}>
      <StyledInput
        type="text"
        placeholder={placeholder}
        disabled={isDisabled}
        style={style}
        onChange={handleChange}
        value={value}
      />
      <SearchIconWrapper lang={lang}>
        <SearchIcon width={iconWidth} height={iconHeight} color={iconColor} />
      </SearchIconWrapper>
    </Wrapper>
  );
}

export default SearchForm;
