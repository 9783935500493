import React, { useContext, useState } from "react";
import styled from "styled-components";
import StarIcon from "../../../../../assets/icons/Activities/CommunityBoard/StarIcon";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../../../../Common/Layout/Header/constant";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  background-color: ${(props) =>
    props?.backgroundColor ??
    `rgba(101, 201, 101, ${props?.focused ? 0.3 : 0.15})`};
  border-radius: 17.5px;
  padding: 9px 11px;
  width: ${(props) => (props.width ? props.width : "calc(100% - 22px)")};
  max-width: ${(props) => props.maxWidth ?? "calc(100% - 22px)"};
  direction: ${(props) => props?.direction ?? "ltr"};
  transition: 0.2s;
  border: ${(props) => props?.border ?? "none"};
`;

const Start = styled.div`
  margin-right: 4px;
`;

const Label = styled.label`
  margin-right: 5px;
  color: ${(props) => props.color ?? "#1e9898"};
  font-size: ${(props) => props.fontSize ?? "10px"};
  font-weight: 700;
  white-space: nowrap; /* Prevent label from wrapping into multiple lines */
  direction: ${(props) => props?.direction ?? "ltr"};
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  color: #1e9898;
  font-size: ${(props) => props?.fontSize ?? "10px"};
  direction: ${(props) => props?.direction ?? "ltr"};
  &::placeholder {
    color: #1e989899;
  }
`;

function InlineInput({
  width,
  maxWidth,
  label,
  showStar,
  register,
  required,
  backgroundColor,
  fontSize,
  labelFontSize,
  labelColor,
  overrideDirection,
  onChange,
  placeholder,
  border,
}) {
  const { currentLanguage } = useContext(LanguageContext);
  const dir = currentLanguage === LANGUAGES.HEBREW.value ? "rtl" : "ltr";
  const [focused, setFocused] = useState(false);

  const inputProps = {
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
    required,
    type: "text",
    direction: overrideDirection ?? dir,
    fontSize,
    placeholder,
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      maxWidth={maxWidth}
      width={width}
      direction={overrideDirection ?? dir}
      focused={focused}
      border={border}
    >
      {showStar && (
        <Start>
          <StarIcon />
        </Start>
      )}
      <Label
        fontSize={labelFontSize}
        color={labelColor}
        direction={overrideDirection ?? dir}
      >
        {label}
      </Label>
      {register ? (
        <Input {...register} {...inputProps} />
      ) : (
        <Input onChange={(e) => onChange?.(e.target?.value)} {...inputProps} />
      )}
    </Wrapper>
  );
}

export default InlineInput;
