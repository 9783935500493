import { REGION } from "../../Common/Layout/Main/constants";
import { HUB_NAMES } from "./constant";

export const setData = (
  hubName,
  hubId,
  fetchedTasks,
  fetchedEvents,
  fetchedActivities,
  fetchedHubs,
  linearData
) => {
  if (hubName === HUB_NAMES.EVENT) {
    return fetchedEvents || [];
  } else if (hubName === HUB_NAMES.ACTIVITY) {
    return fetchedActivities || [];
  } else if (hubName === HUB_NAMES.TASK) {
    return fetchedTasks || [];
  } else if (!hubName && fetchedHubs?.Hubs) {
    const hubs = fetchedHubs?.Hubs;
    if (hubs[hubId]) {
      if (linearData) {
        Object.keys(hubs).forEach((hubKey) => {
          hubs[hubKey] = hubs[hubKey].concat(linearData);
        });
        return hubs;
      } else return hubs[hubId];
    }
    return [];
  }
};

export const isGoHomeRoute = (id, region) => {
  if (id === "6640beff688afe7a27141c6b") {
    return true;
  }
  if (
    ["6629298ca1a183141658f198", "660bb748b6a6a07774b0d327"].includes(id) &&
    region === REGION.jewish
  ) {
    return true;
  }
  if (
    id === "6640beff688afe7a27141c6b" &&
    region === REGION.GDEROT_SETTLEMENTS
  ) {
    return true;
  }
  if (
    ["6640c09f688afe7a27141e08", "6640beff688afe7a27141c6b"].includes(id) &&
    region === REGION.GderotCommunityCenter
  ) {
    return true;
  }
  if (
    ["6654a2aa347cfaf875a2b135", "6640beff688afe7a27141c6b"].includes(id) &&
    region === REGION.Fences48
  ) {
    return true;
  }
  if (id === "65adf777944824b0357f4e82" && region === REGION.BestFriends) {
    return true;
  }
  if (id === "659e2467e6ad34f2f223b4f4" && region === REGION.RamatHasharon) {
    return true;
  }
  if (
    ["660bb748b6a6a07774b0d327", "6629298ca1a183141658f198"].includes(id) &&
    region === REGION.taglit
  ) {
    return true;
  }
  if (id === "65adf912944824b0357f559c" && region === REGION.sos) {
    return true;
  }
  if (
    [
      "6629298ca1a183141658f198",
      "660bb748b6a6a07774b0d327",
      "6671c3c7d84a542869b38888",
    ].includes(id) &&
    region === REGION.jewish
  ) {
    return true;
  }
  if (id === "665ac1202c3c3162c9c7f5e7" && region === REGION.DOD) {
    return true;
  }
  if (
    ["65db1122d042431ab7f85cb0", "65a0aaea6ef957e373fb9c7a"].includes(id) &&
    region === REGION.education
  ) {
    return true;
  }
  if (
    ["666b03add84a542869afc6ce", "666addd0d84a542869afb455"].includes(id) &&
    region === REGION.student
  ) {
    return true;
  }
  if (id === "6640beff688afe7a27141c6b" && region === REGION.gderot) {
    return true;
  }
};
