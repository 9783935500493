import {
  TogetherBestFriendsIcon,
  TogetherBrotherIcon,
  TogetherEmergencyAndRescueIcon,
  TogetherEmergencyIcon,
  TogetherIcon,
  TogetherInAgricultureIcon,
  TogetherInGderot,
  TogetherInOrtStudent,
  TogetherInTelaviv,
  TogetherJewishAgencyIcon,
  TogetherKKlIcon,
  TogetherKaplanIcon,
  TogetherNogaNeighborhood,
  TogetherUnitedRescueIcon,
  TogetherVetIcon,
  VolunteeringPleasureIcon,
} from "../../../../../assets/icons/Logo";
import { ZONES } from "./constants";

export const getCurrentLogo = (zone) => {
  if (!zone) return <TogetherIcon width={240} height={60} />;
  if (zone.includes(ZONES.TEL_AVIV_CITY)) return <TogetherInTelaviv />;
  if (zone.includes(ZONES.GDEROT_CITY)) return <TogetherInGderot />;
  if (zone.includes(ZONES.NOGA_CITY)) return <TogetherNogaNeighborhood />;
  if (zone.includes(ZONES.ORT_SCHOOL)) return <TogetherInOrtStudent />;
  if (zone.includes(ZONES.EmergencyAndRescue))
    return <TogetherEmergencyAndRescueIcon />;
  if (zone.includes(ZONES.EMERGENCY)) return <TogetherEmergencyIcon />;
  if (zone.includes(ZONES.BROTHERS_IN_ARMS)) return <TogetherBrotherIcon />;
  if (zone.includes(ZONES.KAPLAN_FORCE)) return <TogetherKaplanIcon />;
  if (zone.includes(ZONES.UNITED_RESCUE)) return <TogetherUnitedRescueIcon />;
  if (zone.includes(ZONES.JEWISH_AGENCY)) return <TogetherJewishAgencyIcon />;
  if (zone.includes(ZONES.KKL)) return <TogetherKKlIcon />;
  if (zone.includes(ZONES.ImaotRamatAviv)) return <TogetherInTelaviv />;
  if (zone.includes(ZONES.BlueWhite)) return <TogetherInAgricultureIcon />;
  if (zone.includes(ZONES.Vet)) return <TogetherVetIcon />;
  if (zone.includes(ZONES.BestFriends)) return <TogetherBestFriendsIcon />;
  if (zone.includes(ZONES.VOLUNTEERING_PLEASURE))
    return <VolunteeringPleasureIcon />;
};
