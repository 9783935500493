import React from "react";

function OrangeLocationIcon(props) {
  return (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.38739 15.2041C1.08144 14.0684 0.699362 12.9457 0.498268 11.7913C0.38192 11.1243 0.504011 10.3969 0.617486 9.71263C0.953601 7.67131 1.77522 5.83412 3.07228 4.21544C3.20011 4.05587 3.32795 3.89631 3.45579 3.7353C4.33773 3.00646 5.21968 2.27906 6.10162 1.55023C7.69745 0.641696 9.38664 0.132804 11.2425 0.0235503C14.3853 -0.160456 17.1158 0.732262 19.5146 2.75633C21.1219 4.11194 22.2438 5.791 22.9217 7.75613C23.7448 10.1381 23.7419 12.5259 22.7422 14.8763C22.6704 15.0445 22.6345 15.2285 22.5813 15.4053C21.4408 17.7399 20.0188 19.8991 18.4603 21.972C18.1989 22.3199 17.9403 22.6693 17.6803 23.0171C17.5123 23.2213 17.3471 23.4283 17.1733 23.6267C15.724 25.287 14.2847 26.9575 12.8124 28.5991C12.3298 29.1368 11.7639 29.1239 11.2597 28.6049C10.5702 27.8947 9.88219 27.1803 9.25736 26.4155C7.6601 24.459 6.09731 22.4738 4.52015 20.4986C4.05045 19.7812 3.55203 19.0797 3.11968 18.3408C2.51639 17.3101 1.96195 16.2506 1.38595 15.2026L1.38739 15.2041ZM11.9951 15.3205C14.7673 15.4039 17.1618 13.1383 17.1776 10.3107C17.1963 7.17104 14.8507 5.04203 12.0195 5.04347C9.08643 5.04635 6.98213 7.37086 6.88732 9.9412C6.76954 13.1369 9.35647 15.3809 11.9951 15.3205Z"
        fill="#EF7300"
      />
      <path
        d="M11.9988 15.3239C9.36014 15.3843 6.77464 13.1403 6.89099 9.94461C6.98579 7.37571 9.08867 5.04975 12.0232 5.04688C14.8543 5.044 17.2 7.17445 17.1813 10.3141C17.1655 13.1417 14.7696 15.4073 11.9988 15.3239Z"
        fill="#FFDAB8"
      />
    </svg>
  );
}

export default OrangeLocationIcon;
