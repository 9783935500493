import React, { useContext } from "react";
import styled from "styled-components";
import ShareWrapper from "../../../../../kit/Share/ShareWrapper";
import WhatsAppIcon from "../../../../../assets/icons/Common/Layout/WhatsAppIcon.svg";
import OrangeAppleIcon from "../../../../../assets/icons/Common/NavBar/OrangeAppleIcon";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import MuiButton from "../../../../../kit/Button/MuiButton";
import { EmergencyOverlaysContext } from "../../../../Emergency/Contacts/EmergencyOverlaysContext";

const ShareContainer = styled.div`
  padding: 20px 0;
`;
const Headline = styled.h4`
  color: #105f84;
  text-align: center;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.412px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  margin-bottom: 15px;
`;

const ShareVia = ({
  shareProps,
  onClick,
  columns = false,
  hasDivider = false,
  gap,
  appleWidth = 264,
  appleHeight = 45,
}) => {
  const { theme } = useContext(EmergencyOverlaysContext);
  return (
    <ShareContainer>
      <Headline>Share via - שתף באמצעות</Headline>
      {columns ? (
        <FlexColumn alignItems={"center"} gap={gap}>
          <MuiButton
            variant="outlined"
            onClick={onClick}
            sx={{
              color: "#003F5D",
              fontSize: 20,
              fontWeight: 600,
              fontFamily: "Assistant",
              border: `2px solid ${theme?.primary}`,
              borderRadius: "33px",
              width: appleWidth,
              height: appleHeight,
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              "&:hover": {
                border: "2px solid #EF7300",
              },
            }}
          >
            <FlexRow
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ width: "95%" }}
            >
              <OrangeAppleIcon color={"#7FB383"} width={"24"} height={"27"} />
              <div>מוקדי חירום והצלה</div>
            </FlexRow>
          </MuiButton>
          {hasDivider && (
            <div
              style={{
                margin: "20px 0",
                borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.15)",
                width: "100vw",
              }}
            />
          )}
          <ShareWrapper {...shareProps}>
            <MuiButton
              variant="outlined"
              startIcon={
                <img src={WhatsAppIcon} alt="WhatsAppIcon" width={24} />
              }
              sx={{
                color: "#003F5D",
                fontSize: 20,
                fontWeight: 600,
                fontFamily: "Assistant",
                border: `2px solid ${theme?.primary}`,
                borderRadius: "10px",
                width: appleWidth,
                height: appleHeight,
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                marginTop: hasDivider && "12px",
                "&:hover": {
                  border: "2px solid #7FB383",
                },
              }}
            >
              אישי/ קהילתי
            </MuiButton>
          </ShareWrapper>
        </FlexColumn>
      ) : (
        <FlexRow justifyContent={"center"} gap={gap} sx={{ margin: "auto" }}>
          <ShareWrapper {...shareProps}>
            <MuiButton
              variant="outlined"
              startIcon={
                <img src={WhatsAppIcon} alt="WhatsAppIcon" width={24} />
              }
              sx={{
                color: "#003F5D",
                fontSize: 17,
                fontWeight: 600,
                fontFamily: "Assistant",
                border: `3px solid ${theme?.primary || "#003f5d"}`,
                borderRadius: "33px",
                width: 155,
                height: 35,
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                marginTop: hasDivider && "12px",
                "&:hover": {
                  border: `3px solid ${theme?.primary || "#003f5d"}`,
                },
              }}
            >
              וואטסאפ
            </MuiButton>
          </ShareWrapper>
          <div style={{ padding: "0 10px" }} />
          <MuiButton
            variant="outlined"
            onClick={onClick}
            startIcon={
              <OrangeAppleIcon color={"#7FB383"} width={"22"} height={"27"} />
            }
            sx={{
              color: "#003F5D",
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Assistant",
              border: `3px solid ${theme?.primary || "#003f5d"}`,
              borderRadius: "33px",
              width: 160,
              height: 35,
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              "&:hover": {
                border: `3px solid ${theme?.primary || "#003f5d"}`,
              },
            }}
          >
            קהילה ומתנדבים
          </MuiButton>
        </FlexRow>
      )}
    </ShareContainer>
  );
};

export default ShareVia;
