export const getVideoThumbnailAndId = (videoUrl) => {
  if (videoUrl) {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = videoUrl.match(regExp);
    const videoId = match && match[7].length === 11 ? match[7] : false;
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
    return { thumbnailUrl, videoId };
  } else {
    return { thumbnailUrl: null, videoId: null };
  }
};
