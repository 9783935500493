import React from "react";

const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={6}
      fill="none"
      viewBox="0 0 13 6"
    >
      <path
        stroke="#316788"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.543}
        d="M11.555 1 6.277 4.935 1 1"
      />
    </svg>
  );
};

export default ArrowDown;
