import React from "react";

function DoubleArrowIconsLeft({
  height = "14",
  width = "11",
  color = "#316788",
  rotate = false,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: rotate ? "rotate(180deg)" : "none" }}
    >
      <path
        d="M9.60937 13.0546L5.11526 7.0273L9.60938 1"
        stroke={color}
        strokeWidth="1.76212"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49414 13.0546L1.00002 7.0273L5.49414 1"
        stroke={color}
        strokeWidth="1.76212"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DoubleArrowIconsLeft;
