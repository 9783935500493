import { login } from "./api";
import { TaskZone } from "../../../customHooks/useFetchTasks";
import {
  MainEmergencyZone,
  MainOrgZone,
} from "../../../customHooks/useFetchEmergencyEvents";
import {
  AUTH_ROUTES,
  REGION,
  REGISTER_ROUTES,
} from "../../Common/Layout/Main/constants";
import {
  medicalProfileImage,
  sivanImage,
  tomImage,
} from "../../../assets/images/Mock/User";

export const generateProfileImage = (gender, name, displayName) => {
  if (name === "Medical") {
    return medicalProfileImage;
  }
  return gender === "female" ? sivanImage : tomImage;
};

export const handleLogin = async (
  data,
  setCurrentUser,
  setIsAuthenticated,
  navigate,
  route,
  setRoute,
  setError,
  setSnackbarOpen,
  propLogin
) => {
  const loginFunction = propLogin ? propLogin : login;
  const loginData = {
    username: data.loginInformation,
    password: data.loginPassword,
  };
  try {
    const response = await loginFunction(loginData);
    setRoute ?? setRoute("/");
    // Save the access token to localStorage
    localStorage.setItem("access_token", response.data.access_token);
    const profileImage = generateProfileImage(
      response.data.user.gender,
      response.data.user.name
    );
    if (response.data.user) {
      setCurrentUser({
        ...response.data.user,
        image: profileImage,
      });
      setIsAuthenticated(true);
      navigate && navigate(route, { replace: true });
      return response?.data?.user;
    } else {
      setSnackbarOpen && setSnackbarOpen(true);
      setError && setError("Login failed. Please check your credentials.");
    }
  } catch (error) {
    setSnackbarOpen && setSnackbarOpen(true);
    setError && setError("Login failed. Please check your credentials.");
    return error;
  }
};

// Used to map zone names with backend zone names
export const zonesMap = {
  [TaskZone.TelAvivCity]: "tel_aviv_city",
  [TaskZone.GderotCity]: "gderot_city",
  [TaskZone.NogaCity]: "noga_city",
  [TaskZone.ScoutsCity]: "scouts_city",
  [MainOrgZone.JewishAgency]: "jewish_agency",
  [MainOrgZone.KKL]: "kkl_organization",
  [TaskZone.AlmogCity]: "almog_organization",
  [TaskZone.TaglitCity]: "taglit_organization",
  [TaskZone.GderotSchool]: "gderot_school",
  [TaskZone.OrtSchool]: "ort_school",
  [MainEmergencyZone.Emergency]: "emergency",
  [MainEmergencyZone.BrothersInArms]: "brothers_in_arms_emergency",
  [MainEmergencyZone.KaplanForce]: "Kapla_force_emergency",
};

export const isAdmin = (user) => !!(user?.admin?.length > 0);

export const isAdminInZone = (user, zone) => {
  if (!user || !user.admin || !zone) {
    return false;
  }
  return !!user?.admin?.find?.(
    (beZone) => zonesMap[zone?.replace?.(/\d+$/, "")] === beZone
  );
};

export const isUserAuthorizedForAction = (
  currentUser,
  targetId,
  allowGuest
) => {
  if (!currentUser || !currentUser?._id) {
    return !!allowGuest;
  }
  if (currentUser?.isSuperUser) {
    return true;
  }
  return currentUser?._id === targetId;
};

export const getLoginProps = (id) => {
  switch (id) {
    case REGION.Abstract_Emergency:
      return {
        hasDescriptions: false,
        typeTitle: "Member - כניסה למנויים וצוות",
        backgroundColor: "#EFF2FB",
        logoBackgroundColor: "#E5EEFF",
        visitorBackgroundColor: "#E5EEFF",
        visitorBorderColor: "#003F5D1A",
        visitorLink: `/${AUTH_ROUTES.REGISTER}/${REGISTER_ROUTES.EMERGENCY_CREATE_PROFILE}`,
      };
    default:
      return {};
  }
};
