import React from "react";

function PlusIcon({ color = "#EF7300" }) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1538_26603)">
        <path
          d="M3.80522 0.695224C1.70694 0.695224 0 2.40216 0 4.50044C0 6.59873 1.70694 8.30566 3.80522 8.30566C5.9035 8.30566 7.61044 6.59873 7.61044 4.50044C7.61044 2.40216 5.9035 0.695224 3.80522 0.695224ZM3.80522 7.83001C1.96921 7.83001 0.475652 6.33646 0.475652 4.50044C0.475652 2.66443 1.96921 1.17088 3.80522 1.17088C5.64123 1.17088 7.13479 2.66443 7.13479 4.50044C7.13479 6.33646 5.64123 7.83001 3.80522 7.83001Z"
          fill={color}
        />
        <path
          d="M5.47023 4.26263H2.14066C2.00938 4.26263 1.90283 4.36917 1.90283 4.50046C1.90283 4.63174 2.00938 4.73828 2.14066 4.73828H5.47023C5.60151 4.73828 5.70805 4.63174 5.70805 4.50046C5.70805 4.36917 5.60151 4.26263 5.47023 4.26263Z"
          fill={color}
        />
        <path
          d="M3.80521 2.5981C3.67393 2.5981 3.56738 2.70465 3.56738 2.83593V6.16549C3.56738 6.29677 3.67393 6.40332 3.80521 6.40332C3.93649 6.40332 4.04304 6.29677 4.04304 6.16549V2.83593C4.04304 2.70465 3.93649 2.5981 3.80521 2.5981Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1538_26603">
          <rect
            width="7.61044"
            height="7.61044"
            fill="white"
            transform="matrix(1 0 0 -1 0 8.30566)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlusIcon;
