import React from "react";

const DateFormatComponent = ({ startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return "";
  }

  const startDay = start.getDate();
  const endDay = end.getDate();
  const startMonth = start.getMonth() + 1;
  const endMonth = end.getMonth() + 1;
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  let formattedDate = "";

  if (startYear !== endYear) {
    formattedDate = `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`;
  } else if (startMonth !== endMonth) {
    formattedDate = `${startDay}/${startMonth}-${endDay}/${endMonth}/${startYear}`;
  } else if (startDay !== endDay) {
    formattedDate = `${startDay}-${endDay}/${startMonth}/${startYear}`;
  } else {
    formattedDate = `${startDay}/${startMonth}/${startYear}`;
  }

  return <div>{formattedDate}</div>;
};

export default DateFormatComponent;
