import React from "react";
import styled from "styled-components";
import { TIcon } from "../../assets/icons";

const TitleWrapper = styled.div`
  width: 100%;
  height: 80.416px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 90px 0px 0px 0px;
  position: absolute;
  top: ${({ isAuthenticated, top, authTop }) =>
    isAuthenticated ? authTop ?? "319px" : top ?? "319px"};
  color: #003f5d;
  font-family: Assistant;
  font-size: 21px;
  font-weight: 700;
  @media (min-width: 768px) {
    top: 535px;
  }
  z-index: 999;
  background: ${({ background }) => background ?? "#fff"};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px 0px 0px 0px;
`;

const SubTitle = styled.div`
  color: #003f5d;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: -9px;
  top: -1px;
`;

const CurvedTitle = ({
  subTitle = "מתנדבים ומבלים בסביבתכם",
  title = (
    <div style={{ display: "flex", alignItems: "center" }}>
      Volunteering &{"\u00A0\u00A0"}
      <div style={{ position: "relative" }}>
        <IconWrapper>
          <TIcon />
        </IconWrapper>
        {"\u00A0\u00A0"}Pleasure Nearby
      </div>
    </div>
  ),
  isAuthenticated,
  top,
  authTop,
  backgroundColor,
}) => {
  return (
    <TitleWrapper
      background={backgroundColor}
      top={top}
      authTop={authTop}
      isAuthenticated={isAuthenticated}
    >
      <SubTitle>{subTitle}</SubTitle>
      <Title>{title}</Title>
    </TitleWrapper>
  );
};

export default CurvedTitle;
