import { AlarmIcon, GroupIcon, UserIcon } from "../../../../assets/icons";
import GreenAppleIcon from "../../../../assets/icons/Common/Apples/GreenAppleIcon";
import { SettingsIcon } from "../../../../assets/icons/Common/NavBar";
import BookIcon from "../../../../assets/icons/Common/NavBar/BookIcon";
import DownloadIcon from "../../../../assets/icons/Common/NavBar/DownloadIcon";
import MessageIcon from "../../../../assets/icons/Common/NavBar/MessageIcon";
import NoteBookIcon from "../../../../assets/icons/Common/NavBar/NoteBookIcon";
import ShareButtonIcon from "../../../../assets/icons/Common/NavBar/ShareButtonIcon";
import SoundIcon from "../../../../assets/icons/Common/NavBar/SoundIcon";
import LocationIcon from "../../../../assets/icons/LocationIcon";
import { getCurrentShareData } from "../../../Common/Layout/Header/components/NavBar/utils";
import ContactsSubTab from "./SubTabs/ContactsSubTab";
import SoundSubTab from "./SubTabs/SoundSubTab";
import VolunteersSubTab from "./SubTabs/VolunteersSubTab";

export const HEADER_TABS = [
  {
    label: "אנשי קשר",
    icon: <GroupIcon />,
    hoverIcon: <GroupIcon color={"#fff"} />,
    to: "#",
  },
  {
    label: "מתנדבים",
    icon: <UserIcon color={"#316788"} size={15} />,
    hoverIcon: <UserIcon color={"#fff"} size={15} />,
    to: "#",
  },
  {
    label: "צליל",
    icon: <SoundIcon color={"#316788"} size={15} />,
    hoverIcon: <SoundIcon color={"#fff"} size={15} />,
    to: "#",
  },
  {
    label: "מיקום",
    icon: <LocationIcon color={"#316788"} width={15} height={15} />,
    hoverIcon: <LocationIcon color={"#fff"} width={15} height={15} />,
    to: "#",
  },
  {
    label: "הגדרות",
    icon: <SettingsIcon color={"#316788"} size={15} />,
    hoverIcon: <SettingsIcon color={"#fff"} size={15} />,
    to: "#",
    styles: {
      fontSize: "14px !important",
      fontWeight: 700,
      direction: "rtl",
    },
  },
];

export const VISITOR_HEADER_TABS = [
  {
    label: "אנשי קשר",
    icon: <GroupIcon />,
    hoverIcon: <GroupIcon color={"#fff"} />,
    to: "#",
  },
  {
    label: "התראות",
    icon: <AlarmIcon color={"#316788"} />,
    hoverIcon: <AlarmIcon color={"#fff"} />,
    to: "#",
  },
  {
    label: "צליל",
    icon: <SoundIcon color={"#316788"} size={15} />,
    hoverIcon: <SoundIcon color={"#fff"} size={15} />,
    to: "#",
  },
  {
    label: "מיקום",
    icon: <LocationIcon color={"#316788"} width={15} height={15} />,
    hoverIcon: <LocationIcon color={"#fff"} width={15} height={15} />,
    to: "#",
  },
  {
    label: "פרופיל",
    icon: <UserIcon color={"#316788"} size={15} />,
    hoverIcon: <UserIcon color={"#fff"} size={15} />,
    to: "#",
  },
];

export const SUB_HEADER_TABS = {
  "אנשי קשר": {
    component: <ContactsSubTab />,
  },
  מתנדבים: {
    component: <VolunteersSubTab />,
  },
  צליל: {
    label: "אפשרויות צליל",
    icon: <SoundIcon color={"#316788"} size={12} />,
    component: <SoundSubTab />,
  },
  מיקום: {
    label: "שתף מיקום",
    icon: <LocationIcon color={"#316788"} width={12} height={12} />,
    styles: {
      width: "89px",
    },
    component: <SoundSubTab />,
  },
};

export const NAVBAR_TABS = [
  {
    id: "share",
    label: "שתף - Share",
    icon: <ShareButtonIcon color={"#fff"} width={25} height={25} />,
    to: "#",
    action: (currentRoute) => {
      const shareData = getCurrentShareData(currentRoute);
      if (navigator.canShare && navigator.canShare(shareData)) {
        try {
          navigator?.share(shareData);
        } catch (error) {
          console.error("Failed to share, with error", error);
        }
      }
    },
  },
  {
    id: "download_pwa",
    label: "הורד למסך הבית",
    icon: <DownloadIcon color={"#fff"} width={25} height={25} />,
    to: "#",
    action: (installPrompt) => installPrompt.prompt(),
  },
  {
    label: "הצטרפות כמנוי",
    icon: <BookIcon color={"#fff"} size={25} />,
    to: "#",
    messages: [
      {
        message: "מתנדבים וחברי קהילה",
        link: "/activities/register-for-volunteerWork",
      },
    ],
  },
  {
    label: "פניה לאדמין ",
    icon: <MessageIcon color={"#fff"} width={25} height={25} />,
    to: "#",
    messages: [
      {
        message: "תורמים ונותני חסות",
        link: "/activities/registration-for-Sponsors",
      },
      {
        message: "פניות אחרות",
        link: null,
      },
    ],
  },
  {
    label: "Terms - תקנון",
    icon: <NoteBookIcon color={"#fff"} width={25} height={25} />,
    to: "#",
  },
  {
    label: "About - אודות",
    icon: <GreenAppleIcon width={25} height={25} />,
    to: "#",
  },
];
