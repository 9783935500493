import React from "react";
import { useLocation } from "react-router-dom";

const defaultShareData = {
  title: "Together Ecosystem\nCommunity Social Network\n",
  text: `"היי, מוזמנים להרשם לאחת מהפעילויות ולקחת חלק מהותי בעזרה הדדית קהילתית"\n\n`,
};

function ShareWrapper({
  shareData = defaultShareData,
  can = true,
  children,
  urlRecordId,
  style,
}) {
  const location = useLocation();
  shareData.url = `${location.pathname}${urlRecordId ? `/${urlRecordId}` : ""}`;
  const handleShare = () => {
    if (!navigator.canShare || !navigator.canShare(shareData) || !can) {
      return;
    } else {
      try {
        navigator?.share(shareData);
      } catch (error) {
        console.error("Failed to share, with error", error);
      }
    }
  };

  return (
    <div style={style} onClick={handleShare}>
      {children}
    </div>
  );
}

export default ShareWrapper;
