import React from "react";

const CloseIcon = ({ width, height, color = "gray" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "15px"}
      height={height ?? "15px"}
      fill="none"
      viewBox="0 0 15 15"
    >
      <g>
        <g>
          <g>
            <path
              fill={color}
              d="M8.112 7.266l6.224-6.224a.605.605 0 10-.855-.855L7.257 6.41 1.032.187a.605.605 0 00-.855.855l6.224 6.224-6.224 6.225a.605.605 0 10.855.854l6.225-6.224 6.224 6.224a.605.605 0 00.855-.854L8.112 7.266z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
