import React, { useContext } from "react";
import styled from "styled-components";
import StarIcon from "../../../../../assets/icons/Activities/CommunityBoard/StarIcon";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../../../../Common/Layout/Header/constant";

const Column = styled.div`
  display: flex;
  flex-direction: ${(props) => !props.sameLine && "column"};
  font-size: 10px;
  font-weight: 700;
  background-color: rgba(101, 201, 101, 0.15);
  border-radius: 17.5px;
  padding: 9px 11px;
  width: calc(100% - 22px);
  direction: ${(props) => props.sameLine && "rtl"};
  gap: ${(props) => props.sameLine && "5px"};
`;

const CategoryTitle = styled.span`
  margin-right: 10px;
  color: ${(props) => props.color ?? "#1B5C6D"};
  direction: ${(props) => props.direction ?? "ltr"};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Separator = styled.span`
  margin: 0 4px;
  color: rgba(30, 152, 152, 0.3);
`;

const CheckboxLabel = styled.label`
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: ${({ checked }) =>
    checked ? "rgba(27, 92, 109, 1)" : "rgba(30, 152, 152, 0.3)"};
  text-decoration: ${({ checked }) => (checked ? "underline" : "none")};
  @media (max-width: 415px) {
    font-size: 12px;
  }
  @media (max-width: 380px) {
    font-size: 10px;
  }
`;

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const Category = ({ label, value, checked, register, required }) => (
  <CheckboxLabel checked={checked}>
    <HiddenRadio
      required={required}
      type="radio"
      name="category"
      checked={checked}
      value={value}
      {...register}
    />
    {label}
  </CheckboxLabel>
);

const CheckboxRow = ({
  label,
  categories,
  selectedCategory,
  showStar,
  register,
  sameLine,
}) => {
  const { currentLanguage } = useContext(LanguageContext);
  const dir = currentLanguage === LANGUAGES.HEBREW.value ? "rtl" : "ltr";

  return (
    <Column sameLine={sameLine}>
      <CategoryTitle direction={dir}>
        {showStar && <StarIcon />}
        {label}:
      </CategoryTitle>
      <div
        style={{
          display: "flex",
          margin: "10px 0",
          justifyContent: "center",
          gap: "5px",
          alignItems: "center",
          direction: dir,
        }}
      >
        {categories.map((category, index) => (
          <React.Fragment key={category.value}>
            <Category
              label={category.label}
              checked={selectedCategory === category.value}
              value={category.value}
              register={register}
            />
            {index < categories.length - 1 && <Separator>/</Separator>}
          </React.Fragment>
        ))}
      </div>
    </Column>
  );
};

export default CheckboxRow;
