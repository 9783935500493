import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LANDING_ROUTES, REGION } from "../domain/Common/Layout/Main/constants";
import { useLocation } from "react-router-dom";
import { DOMAIN } from "../domain/Common/constants";
import { isDomain } from "../domain/Common/utils";

function useRegion(props) {
  const { region, setRegion } = useContext(LanguageContext);
  const location = useLocation();
  const currentPath = location.pathname;

  const isAbstractEmergency =
    region === REGION.Abstract_Emergency || isDomain(DOMAIN.TOGETHER_ECO);
  const isJewishEmergency =
    region === REGION.JewishEmergency ||
    currentPath === LANDING_ROUTES.JEWISH_EMERGENCY;
  const isSosV2 = region === REGION.sosV2;
  const isNeedHelp =
    region === REGION.NeedHelp || isDomain(DOMAIN.STUDENT_ECOSYSTEM);
  const isSOSAndNeedHelp =
    region === REGION.SOSAndNeedHelp || isDomain(DOMAIN.SCHOOL_ECOSYSTEM);
  const isAllEmergencies =
    region === REGION.AllEmergencies || isDomain(DOMAIN.EMERGENCY_ECOSYSTEM);
  const isShareEmergency = region === REGION.ShareEmergency;

  const isAbstractEmergencyRegions =
    isAbstractEmergency ||
    isJewishEmergency ||
    isSosV2 ||
    isNeedHelp ||
    isSOSAndNeedHelp ||
    isAllEmergencies ||
    isShareEmergency;

  const isVTime = region === REGION.vTime || isDomain(DOMAIN.CITY_ECOSYSTEM);

  return {
    region,
    setRegion,
    isLocal: region === REGION.lOCAL,
    isWorldwide: region === REGION.WORLD_WIDE,
    isGderotSettlements: region === REGION.GDEROT_SETTLEMENTS,
    isDOD: region === REGION.DOD,
    isGderotCommunityCenter: region === REGION.GderotCommunityCenter,
    isFences48: region === REGION.Fences48,
    isBestFriends: region === REGION.BestFriends,
    isEmergency: region === REGION.Emeregncy,
    isTelAviv: region === REGION.TelAviv,
    isRamatHasharon: region === REGION.RamatHasharon,
    isTaglit: region === REGION.taglit,
    isSos: region === REGION.sos,
    isJewish: region === REGION.jewish,
    isIsrael: region === REGION.israel,
    isVet: region === REGION.vet,
    isEducation: region === REGION.education,
    isStudent: region === REGION.student,
    isGderot: region === REGION.gderot,
    isAbstractTelAviv: region === REGION.Abstract_TelAviv,
    isAbstractGderot: region === REGION.Abstract_Gderot,

    isVolunteersAndSpendTime: region === REGION.VolunteersAndSpendTime,
    isJewishVAST: region === REGION.JewishVAST,
    isJewishEmergency,
    isGderotV2:
      region === REGION.gderotV2 || currentPath === LANDING_ROUTES.GDEROT,
    isMatnasim:
      region === REGION.matnasim || currentPath === LANDING_ROUTES.MATNASIM,
    isHatzofim:
      region === REGION.hatzofim || currentPath === LANDING_ROUTES.HATZOFIM,
    isVTime,
    isSosV2,
    isAbstractEmergency,
    isNeedHelp,
    isSOSAndNeedHelp,
    isAllEmergencies,
    isShareEmergency,
    isAbstractEmergencyRegions,
  };
}

export default useRegion;
