export const ROUTES = {
  TEL_AVIV: "tel-aviv",
  GDEROT: "gderot",
  HOD_HASHARON: "hod-hasharon",
  HERZLIYA: "herzliya",
  RAMAT_HASHARON: "ramat-hasharon",
  KFAR_SABA: "kfar-saba",
  NOGA: "noga",
  IMAOT_RAMAT_AVIV: "imaot-ramat-aviv",
  IMAOT_YAD_ELIHAO: "imaot-yad-elihao",
};

export const CITIES = [
  {
    name: "Tel Aviv - תל אביב",
    route: `/public/activities/together/${ROUTES.TEL_AVIV}`,
    value: ROUTES.TEL_AVIV,
  },
  {
    name: "ישובי גדרות",
    route: `/public/activities/together/${ROUTES.GDEROT}`,
    value: ROUTES.GDEROT,
  },
  // {
  //   name: "הוד השרון ",
  //   route: null,
  //   value: "",
  // },
  // {
  //   name: "הרצליה",
  //   route: null,
  //   value: "",
  // },
  // {
  //   name: "רמת השרון ",
  //   route: null,
  //   value: "",
  // },
  // {
  //   name: "כפר סבא",
  //   route: null,
  //   value: "",
  // },
];

export const Noga = [
  {
    name: "noga - תגלית",
    route: `/public/activities/together/${ROUTES.NOGA}`,
    value: ROUTES.NOGA,
  },
];
