import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  fetchPostsByZone,
  fetchPostsByZoneAndType,
} from "../domain/Ecosystem/Board/api";
import { getPhotoSrc } from "../domain/Ecosystem/Post/utils";
import cardCover from "../assets/images/Activities/CommunityBoard/card-cover.png";

function useFetchPosts(zone, onSuccess, type) {
  const {
    isLoading: isLoadingPosts,
    refetch: refetchPosts,
    data: fetchedPosts,
  } = useQuery({
    queryKey: ["posts", zone, type],
    queryFn: () =>
      type ? fetchPostsByZoneAndType(zone, type) : fetchPostsByZone(zone),
    onSuccess: (data) => {
      onSuccess?.(data?.data?.posts?.slice?.().reverse?.());
    },
  });

  const posts = useMemo(
    () => fetchedPosts?.data?.posts?.slice?.().reverse?.() ?? [],
    [fetchedPosts?.data?.posts]
  );

  const cardPosts = useMemo(
    () =>
      posts
        ?.map?.((post) => ({
          name: post?.description,
          title: post?.description,
          user_id: post?.user_id,
          date: post?.createdAt?.substring(0, 10),
          image: post?.photo ? getPhotoSrc(post?.photo) : cardCover,
        }))
        ?.slice?.(0, 10) ?? [],
    [posts]
  );

  return { posts, cardPosts, isLoadingPosts, refetchPosts };
}

export default useFetchPosts;
