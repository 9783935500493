import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function MuiTextField({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  onChange,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={({ field: { onChange: fieldOnChange, onBlur, value } }) => (
        <TextField
          {...rest}
          onChange={
            onChange
              ? () => onChange(fieldOnChange, onBlur, value)
              : fieldOnChange
          }
        />
      )}
    />
  );
}

export default MuiTextField;
