import axios from "axios";
import { CURRENT_DOMAIN } from "../../info";

export const fetchUser = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/api/auth/${id}`);

export const fetchUsers = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/api/auth/users`);

export const sendResetCode = async (email) =>
  await axios.post(
    `${CURRENT_DOMAIN}/api/auth/send-reset-code`,
    {
      email,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const sendRegisterCode = async (email) =>
  await axios.post(
    `${CURRENT_DOMAIN}/api/auth/send-register-code`,
    {
      email,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const verifyResetCode = async (code, email, hashedVal) =>
  await axios.post(
    `${CURRENT_DOMAIN}/api/auth/verify-reset-code`,
    {
      code,
      email,
      hashedVal,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const verifyRegisterCode = async (code, email, hashedVal) =>
  await axios.post(
    `${CURRENT_DOMAIN}/api/auth/verify-register-code`,
    {
      code,
      email,
      hashedVal,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const resetPasswordWithToken = async (newPassword, token) =>
  await axios.post(
    `${CURRENT_DOMAIN}/api/auth/reset-forget-password`,
    {
      newPassword,
      token,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
