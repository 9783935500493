import React from "react";

function TIcon({ color = "#003F5D", width = 23, height = 19 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 23 19"
    >
      <path
        fill={color}
        d="m8.008 7.278-.383-2.452c-.016-.103-.054-.216-.032-.312.19-.89-.258-1.66-.428-2.476-.132-.626-1.311-1.006-1.805-.585-.615.525-1.19 1.074-1.483 1.937-.198.58-.65 1.079-1.051 1.551-.3.353-.952.289-1.305-.013a6.488 6.488 0 0 0-.588-.43c-.61-.407-.68-1.142-.92-1.768-.051-.137.059-.352.113-.528.045-.147.137-.28.17-.429C.456 1.017.954.55 1.703.457A.756.756 0 0 0 2.02.35C2.54.015 3.114.013 3.69.021L6.58.006c1.503.005 3.006.009 4.507.015h5.695c.431.005.88-.066 1.295.028.777.175 1.585.327 2.242.848.428.34.678.76.68 1.425.002.908-.182 1.624-.805 2.275-.652.68-1.751.78-2.321.047a2.896 2.896 0 0 1-.488-1.023c-.238-.93-.715-1.628-1.507-2.102-.663-.397-1.121-.476-1.505-.024-.276.327-.43.787-.583 1.21-.144.393-.222.811-.326 1.221-.066.265-.17.528-.178.795-.05 1.466-.086 2.933-.104 4.399-.016 1.35-.062 2.706.024 4.05.038.604.012.455.08 1.168.002-.032.002.03 0 0-.066 1.073 1.005 2.56 1.443 3.397.38.722.022 1.412-.604 1.844-1.019.705-2.07.294-3.098.37-.95.07-1.912-.048-2.87-.067-.208-.004-.471.158-.611.068-.408-.258-.852-.538-1.122-.933-.303-.449-.137-.974.122-1.47.368-.698.706-1.423.968-2.17.138-.396.076-.868.13-1.303.108-.878.232-1.754.35-2.632.01-.011.03-.024.027-.032-.004-.028-.016-.056-.024-.082l.008-.307c.028-.923.066-1.846.08-2.769.004-.327-.05-.653-.08-.98l.002.006Z"
      />
    </svg>
  );
}

export default TIcon;
