import React from "react";

function NotificationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7.903 14.625A10.11 10.11 0 0 1 18.07 4.5c5.569.042 10.027 4.669 10.027 10.252v.998c0 5.035 1.054 7.96 1.983 9.563A1.125 1.125 0 0 1 29.11 27H6.89a1.125 1.125 0 0 1-.97-1.687c.928-1.604 1.983-4.528 1.983-9.563v-1.125ZM13.5 27v1.125a4.5 4.5 0 1 0 9 0V27"
      />
    </svg>
  );
}

export default NotificationIcon;
