import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { ConfirmProvider } from "material-ui-confirm";

const ThemeComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider defaultOptions={{}}>{children}</ConfirmProvider>
    </ThemeProvider>
  );
};

export default ThemeComponent;
