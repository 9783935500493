import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddImageIcon } from "../../assets/icons";
import { CiImageOff } from "react-icons/ci";

const Wrapper = styled.div`
  width: ${(props) => (props.squareStyle ? "100px" : "296px")};
  height: ${(props) => (props.squareStyle ? "90px" : "173px")};
  border-radius: 13.585px;
  background: #e3e3e3;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
const ImageHolder = styled.div`
  width: ${(props) => (props.squareStyle ? "100%" : "296px")};
  height: ${(props) => (props.squareStyle ? "66px" : "143px")};
  background-color: #f3f3f3;
  background-size: cover;
  background-position: center;
  border-radius: ${(props) =>
    props.squareStyle ? "13.585px 13.585px 0 0" : "13.585px"};
`;
const NameInput = styled.input`
  width: ${(props) => (props.squareStyle ? "100%" : "176px")};
  height: ${(props) => (props.squareStyle ? "23px" : "23px")};
  border-radius: ${(props) => (props.squareStyle ? "0 0 10px 10px" : "10px")};
  border: 1px solid #efefef;
  background: #fff;
  margin-left: ${(props) => (props.squareStyle ? "0" : "8px")};
  margin-top: ${(props) => (props.squareStyle ? "0" : "5px")};
  box-sizing: border-box;
  padding-left: 10px;
  text-align: center;

  &::placeholder {
    color: #cccbcb;
    text-align: center;
    font-family: "NotoSans-600";
    font-size: 18px;
    letter-spacing: 0.223px;
  }
`;
const ImageButtonWrapper = styled.div`
  position: absolute;
  bottom: 37px;
  right: 10px;
  cursor: pointer;
`;
const HiddenFileInput = styled.input`
  display: none;
`;
const EditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.squareStyle ? "66px" : "143px")};
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ForumCard = ({
  name,
  setValue,
  placeholder = "כותרת הדף",
  index,
  customNameAttr,
  defaultName,
  defaultImage,
  squareStyle,
  showEditOverlay,
  canEditImage = true,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [namePrv, setNamePrv] = useState();
  const inputId = `${name}-${index}`;

  useEffect(() => {
    if (!defaultImage) return;
    if (typeof defaultImage === "string") {
      setValue(`${name}.image`, defaultImage);
      setImagePreview(defaultImage?.replace?.(/\s/g, "%20"));
      return;
    }
    setValue(`${name}.image`, defaultImage);
    setImagePreview(URL.createObjectURL(defaultImage));
  }, [defaultImage, name, setValue]);

  useEffect(() => {
    setValue(`${name}.${customNameAttr ?? "name"}`, defaultName);
    setNamePrv(defaultName);
  }, [customNameAttr, defaultName, name, setValue]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue(`${name}.image`, file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setValue(`${name}.image`, null);
    setImagePreview(null);
  };

  return (
    <Wrapper squareStyle={squareStyle}>
      <ImageHolder
        squareStyle={squareStyle}
        style={{ backgroundImage: `url(${imagePreview})` }}
        onClick={() => {
          if (!squareStyle) return;
          document.getElementById(inputId).click();
        }}
      />
      {showEditOverlay && (
        <EditOverlay
          squareStyle={squareStyle}
          onClick={() => document.getElementById(inputId).click()}
        >
          Edit
        </EditOverlay>
      )}
      <NameInput
        squareStyle={squareStyle}
        placeholder={placeholder}
        maxLength={20}
        onChange={(e) =>
          setValue(`${name}.${customNameAttr ?? "name"}`, e.target.value)
        }
        defaultValue={namePrv}
      />
      {canEditImage &&
        (imagePreview && !squareStyle ? (
          <ImageButtonWrapper onClick={removeImage}>
            <CiImageOff color="white" size={32} />
          </ImageButtonWrapper>
        ) : (
          !squareStyle && (
            <ImageButtonWrapper
              onClick={() => document.getElementById(inputId).click()}
            >
              <AddImageIcon />
            </ImageButtonWrapper>
          )
        ))}
      <HiddenFileInput
        id={inputId}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
    </Wrapper>
  );
};

export default ForumCard;
