import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const InputWrapper = styled.div`
  height: ${(props) => props.height};
  margin: ${(props) => (props.margin ? props.margin : "17px 25px")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "14px")};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : "8px"};
  width: ${(props) =>
    props.width ? props.width : "calc(100% - 68px)"}; // 68 = 25 + 25 + 18
  border: ${(props) =>
    props.errors && props.errors[props.hookName]
      ? "1px solid #ff3333"
      : props.border};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  display: flex;
  align-items: center;
  flex: ${(props) => props.flex};
  position: ${(props) => (props.position ? props.position : "relative")};
  top: ${(props) => props.top && props.top};
  bottom: ${(props) => props.bottom && props.bottom};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  ${(props) => props.additionalStyling ?? ""}
`;

const Input = styled.input`
  color: ${(props) => props.textColor ?? "var(--text, #696d72)"};
  font-size: ${(props) => props.fontSize || "20px"};
  padding-right: 2px;
  font-weight: 400;
  border: ${(props) =>
    props.errors && props.errors[props.hookName]
      ? "1px solid #ff3333"
      : "none"};
  outline: none;
  width: 100%;
  ${(props) => props.inputExtraStyles}
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  direction: ${(props) => (props.lan === "heb" ? "rtl" : "ltr")};
  text-align: ${(props) => (props.lan === "heb" ? "right" : "left")};
  &::placeholder {
    color: ${(props) =>
      props.placeholderColor || "var(--placeholder, #999999)"};
  }
`;

const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  margin-top: 2px;
`;
const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: #ff3333;
  font-size: 16px;
`;

function TextInput({
  height,
  width,
  margin,
  border,
  borderRadius,
  fontSize,
  textColor,
  type,
  flex,
  placeholder,
  register,
  hookName,
  ariaInvalid,
  required,
  backgroundColor,
  ActionButton,
  additionalStyling,
  defaultValue,
  readonly,
  inputExtraStyles,
  showPasswordByDefault,
  paddingLeft,
  paddingRight,
  position,
  top,
  bottom,
  left,
  right,
  lan,
  rules,
  errors,
  placeholderColor,
  showPasswordIconColor,
}) {
  const defaultRegister = () => {};

  const [showPassword, setShowPassword] = useState(!!showPasswordByDefault);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <InputWrapper
      height={height}
      width={width}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      flex={flex}
      additionalStyling={additionalStyling}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      position={position}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      hookName={hookName}
      errors={errors}
    >
      <Input
        defaultValue={defaultValue}
        readOnly={readonly}
        fontSize={fontSize}
        textColor={textColor}
        backgroundColor={backgroundColor}
        type={showPassword ? "text" : type}
        placeholder={placeholder}
        {...(register ? register(hookName, rules) : defaultRegister())}
        aria-invalid={ariaInvalid}
        required={required}
        inputExtraStyles={inputExtraStyles}
        lan={lan}
        errors={errors}
        placeholderColor={placeholderColor}
      />
      {type === "password" && lan !== "heb" && (
        <EyeIcon onClick={togglePasswordVisibility}>
          {showPassword ? (
            <AiOutlineEyeInvisible color={showPasswordIconColor} size={"2em"} />
          ) : (
            <AiOutlineEye color={showPasswordIconColor} size={"2em"} />
          )}
        </EyeIcon>
      )}
      {ActionButton && <ActionButton />}
      {errors && errors[hookName] && <Error>{errors[hookName].message}</Error>}
    </InputWrapper>
  );
}

export default TextInput;
