import SivanImage from "../../../../assets/images/Mock/User/SivanImage.png";
import TomImage from "../../../../assets/images/Mock/User/tom-cruise.png";
import postImage from "../../../../assets/images/Mock/User/postImage.png";
import HeartIcon from "../../../../assets/icons/Activities/CommunityBoard/HeartIcon";
import LikeIcon from "../../../../assets/icons/Activities/CommunityBoard/LikeIcon";

const mockData = {
  userImage: SivanImage,
  userName: "John Doe",
  postDate: "August 2, 2023",
  postText:
    "Description of the task in a few lines up to a certain number of characters to avoid digging.",
  postImage: postImage,
  heartIcon: HeartIcon,
  likeIcon: LikeIcon,
  commentCount: 21,
  saveCount: 3,
  shareList: [
    {
      userImage: SivanImage,
      userName: "John Doe",
      userId: "userId1",
    },
    {
      userImage: TomImage,
      userName: "Tom Cruise",
      userId: "userId2",
    },
    {
      userImage: SivanImage,
      userName: "John Doe",
      userId: "userId3",
    },
    {
      userImage: TomImage,
      userName: "Tom Cruise",
      userId: "userId4",
    },
    {
      userImage: SivanImage,
      userName: "John Doe",
      userId: "userId5",
    },
    {
      userImage: TomImage,
      userName: "Tom Cruise",
      userId: "userId6",
    },
    {
      userImage: SivanImage,
      userName: "John Doe",
      userId: "userId7",
    },
    {
      userImage: TomImage,
      userName: "Tom Cruise",
      userId: "userId8",
    },
  ],
};

export default mockData;
