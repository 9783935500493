import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Card from "./Card";
import PaginationContainer from "../../../PaginationContainer/PaginationContainer";
import { htmlToText } from "../../../RichTextEditor/htmlUtils";
import MuiButton from "../../../Button/MuiButton";
import { ArrowDown } from "../../../../assets/icons/Arrows";

const Wrapper = styled.div`
  margin: 22px 15px;
  padding-bottom: 10px;
`;
const CardsWrapper = styled.div``;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
const buttonStyle = {
  color: "#316788",
  fontFamily: "Assistant",
  fontSize: 18,
  fontWeight: 700,
  textTransform: "none",
  borderRadius: 20,
  boxShadow: "none",
  background: "#F1F3F4",
  height: 34,
  minWidth: 172,
  "&:hover": {
    background: "#F1F3F4",
  },
};

function LinearCards({
  data,
  HeartColor,
  lang,
  hasStar,
  liked,
  hasDate,
  height,
  imageWidth,
  link,
  linkAddress,
  showHeart,
  to,
  titleFontSize,
  otherDetailsFontSize,
  isTasksVisable = true,
  onCardClick,
  usePagination,
  paginationPageLimit,
  paginationColor,
  canShowDetails = false,
  cardLimit,
  hasViewMore,
}) {
  const navigate = useNavigate();
  const [visibleCards, setVisibleCards] = useState(cardLimit);
  const id = 0;

  useEffect(() => {
    const handleScroll = () => {
      setVisibleCards(cardLimit);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [cardLimit]);

  const viewHandler = () => {
    navigate(`/activities/view/${id}`);
  };

  const renderCard = (activity) => {
    if (!activity) return null;

    return (
      isTasksVisable && (
        <Card
          image={activity.image}
          title={htmlToText(activity.title || activity.name)}
          actionText={activity.actionText}
          action={activity.hasAction && viewHandler}
          location={activity.location}
          date={activity.date}
          time={activity.time || activity.start_time}
          key={activity.id}
          liked={liked}
          HeartColor={HeartColor}
          showHeart={showHeart}
          lang={lang}
          hasStar={hasStar}
          hasDate={hasDate}
          height={height}
          imageWidth={imageWidth}
          titleFontSize={titleFontSize}
          otherDetailsFontSize={otherDetailsFontSize}
          linkAddress={linkAddress}
          to={to}
          onClick={() => onCardClick?.(activity)}
        />
      )
    );
  };

  const handleViewMore = () => {
    setVisibleCards((prev) => prev + cardLimit);
  };

  const visibleData = data?.slice(0, visibleCards);

  return (
    <Wrapper>
      <CardsWrapper>
        {usePagination && (
          <PaginationContainer
            elements={visibleData?.map((activity) => {
              if (!activity) return null;
              return (
                <>
                  {link ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`${link}/${activity?.id}`}
                    >
                      {renderCard(activity)}
                    </Link>
                  ) : canShowDetails ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/activities/${activity?.zone}/card-details/${activity?.task_type}/${activity?.id}`}
                    >
                      {renderCard(activity)}
                    </Link>
                  ) : (
                    renderCard(activity)
                  )}
                </>
              );
            })}
            pageLimit={paginationPageLimit ?? 5}
            color={paginationColor ?? "#003f5d"}
          />
        )}
        {!usePagination &&
          visibleData?.map((activity) => {
            if (!activity) return null;
            return (
              <>
                {link ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`${link}/${activity?.id}`}
                  >
                    {renderCard(activity)}
                  </Link>
                ) : activity?.link ? (
                  <Link style={{ textDecoration: "none" }} to={activity?.link}>
                    {renderCard(activity)}
                  </Link>
                ) : (
                  renderCard(activity)
                )}
              </>
            );
          })}
      </CardsWrapper>
      {hasViewMore && visibleCards < data.length && (
        <ButtonWrapper>
          <MuiButton
            variant="contained"
            endIcon={<ArrowDown />}
            sx={buttonStyle}
            onClick={handleViewMore}
          >
            View More
          </MuiButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
}

export default LinearCards;
