export const ZONES = {
  TEL_AVIV_CITY: "telAvivCity",
  GDEROT_CITY: "gderotCity",
  NOGA_CITY: "NogaCity",
  ORT_SCHOOL: "ortStudent",
  EMERGENCY: "Emergency",
  BROTHERS_IN_ARMS: "BrothersInArms",
  KAPLAN_FORCE: "KaplanForce",
  UNITED_RESCUE: "UnitedRescue",
  JEWISH_AGENCY: "JewishAgency",
  KKL: "KKL",
  ImaotRamatAviv: "ImaotRamatAviv",
  BlueWhite: "BlueWhite",
  Vet: "vet",
  BestFriends: "BestFriends",
  EmergencyAndRescue: "EmergencyAndRescue",
  VOLUNTEERING_PLEASURE: "volunteering_pleasure",
  MATNASIM: "matnasim",
};

export const EMERGENCY_DESCRIPTION = `בקשת סיוע/ אות מצוקה נשלחו אליך 🚨\n\nהיי, המערכת זיהתה מקרה חירום של חבר/ה בקהילתך.\n\nאנא לחצו על הלינק בכדי לסייע לחבר/ה הקהילה.`;

export const EMERGENCY_MESSAGE =
  "לפרטים נוספים כולל מפת הגעה יש ללחוץ על הילנק:";

export const NEW_DESIGN_ZONES = ["BlueWhite1", "BlueWhite7", "BrothersInArms8"];

export const GENDER_LOCALIZATION = {
  נקבה: "female",
  זכר: "male",
};
