import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border-top: 2px solid #0d4965;
  display: flex;
`;

const Tap = styled.div`
  width: 50%;
  overflow: hidden;
  padding: 10px 33px;
  display: flex;
  justify-content: center;
  gap: 10px;
  background: ${(props) => (props.isActive ? "rgba(176, 220, 179, 0.90)" : "")};
  border-right: ${(props) => (props.hasBorder ? "2px solid #0D4965" : "none")};
  cursor: pointer;
`;

const IconWrapper = styled.div``;

const Text = styled.p`
  color: ${(props) => props.color ?? "#c13516"};
  margin: 0;
  text-align: right;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.44px;
  text-decoration-line: none;
`;

const LocationContainer = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log("Latitude:", position.coords.latitude);
          // console.log("Longitude:", position.coords.longitude);
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
          setIsClicked(true);
        }
      );
      setIsClicked(false);
    } else {
      console.error("Geolocation is not supported by this browser.");
      setIsClicked(true);
    }
  };

  return (
    <Wrapper>
      <Tap
        hasBorder
        isActive={isClicked === true}
        onClick={() => setIsClicked(true)}
      >
        <Text>off</Text>
      </Tap>
      <Tap isActive={isClicked === false} onClick={() => handleClick()}>
        <Text color="#2A8A31">on</Text>
      </Tap>
    </Wrapper>
  );
};

export default LocationContainer;
