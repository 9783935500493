import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import MuiButton from "../Button/MuiButton";
import { FlexColumn, FlexRow } from "../../domain/Common/Styled";
import { CodeInput, PhoneNumberInput } from "../Input";

const Modal = styled(Dialog)(({ backgroundColor }) => ({
  "& .MuiDialog-paper": {
    width: "100%",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    backgroundColor: backgroundColor || "#FAFFFA",
    color: "#003F5D",
    fontFamily: "Assistant",
  },
}));
const Title = styled(DialogTitle)({
  fontFamily: "Bogart-trial-700 !important",
  fontSize: 18,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
});
const Content = styled(DialogContent)(({ backgroundColor }) => ({
  width: "100%",
  backgroundColor: backgroundColor || "#E1F0E2",
  boxSizing: "border-box",
  fontWeight: 600,
  fontSize: 18,
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: 10,
  // padding: "14px 50px !important",
}));
const Actions = styled(DialogActions)(({ backgroundColor }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  backgroundColor: backgroundColor || "#C9E1CA",
  width: "100%",
  boxSizing: "border-box",
}));

const LoginModal = ({
  handleClose,
  open,
  onSubmit,
  englishTitle = "LOGIN",
  hebrewTitle = "כניסה למתנדבים",
  contentTitle = "משמתמש קיים",
  actionTitle = "Sign Up - הרשמה למערכת",
  submitHebrewText = "יצירת פרופיל חדש",
  backgroundColor = "#FAFFFA",
  contentBackgroundColor = "#E1F0E2",
  actionBackgroundColor = "#C9E1CA",
  hasPhoneNumber = false,
  inputNum = 4,
}) => {
  console.log(hasPhoneNumber);
  return (
    <Modal onClose={handleClose} open={open} backgroundColor={backgroundColor}>
      <Title>
        {englishTitle}
        <span style={{ fontFamily: "Assistant", fontWeight: 600 }}>
          {hebrewTitle}
        </span>
      </Title>
      <Content backgroundColor={contentBackgroundColor}>
        <div style={{ marginTop: "15px" }}>{contentTitle}</div>
        <div>
          <FlexRow
            justifyContent={"space-between"}
            sx={{ padding: "0 50px", marginBottom: "10px" }}
          >
            <div>Full Name</div>
            <div>שם מלא</div>
          </FlexRow>
          {/* <TextField sx={{ borderRadius: 22, height: 38 }} /> */}
          <TextField
            sx={{ borderRadius: 22 }}
            InputProps={{
              sx: {
                "&.MuiInputBase-root": {
                  height: 38,
                  borderRadius: 22,
                },
              },
            }}
          />
        </div>
        {hasPhoneNumber && (
          <FlexColumn
            alignItems={"center"}
            sx={{ marginBottom: "5px", marginTop: "5px" }}
          >
            <FlexRow
              justifyContent={"space-between"}
              sx={{
                marginBottom: "10px",
                width: "100%",
                padding: "0 10px",
                boxSizing: "border-box",
              }}
            >
              <div>Phone Number</div>
              <div>סלולרי</div>
            </FlexRow>
            <PhoneNumberInput
              setValue={() => null}
              inputStyle={{
                borderRadius: "5.23px",
                border: "1px solid #EEE",
                height: "26px",
                width: "22px",
                backgroundColor: "white",
              }}
              required
            />
          </FlexColumn>
        )}
        <FlexRow
          justifyContent={"space-between"}
          sx={{ padding: "0 30px", marginTop: "10px" }}
        >
          <div>Code</div>
          <CodeInput
            styles={{
              width: "22px",
              height: "22px",
              backgroundColor: "transparent",
              borderRadius: "7px",
              border: "2px solid #003F5D",
            }}
            navigateLink={null}
            inputNum={inputNum}
          />
          <div>קוד</div>
        </FlexRow>
      </Content>
      <Actions backgroundColor={actionBackgroundColor}>
        <MuiButton
          onClick={() => null}
          sx={{
            color: "#003F5D",
            fontSize: 18,
            fontFamily: "Assistant",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          {actionTitle}
        </MuiButton>
        <MuiButton
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "#003F5D",
            fontSize: 18,
            fontFamily: "Assistant",
            fontWeight: 600,
            width: "calc(100% - 100px)",
            boxSizing: "border-box",
            backgroundColor: "#fff",
            borderRadius: 22,
            marginBottom: "25px",
            "@media (max-width: 390px)": {
              fontSize: 16,
            },
          }}
          onClick={onSubmit}
        >
          <div>New</div>
          <div>{submitHebrewText}</div>
        </MuiButton>
      </Actions>
    </Modal>
  );
};

export default LoginModal;
