import HubImage from "./hub-image.png";
import Org1 from "./org1.png";
import Org2 from "./org2.png";
import Apple from "./apple.png";

import vpl11d from "../../../../../../assets/images/Activities/VolunteeringPleasure/mock/Details/vpl11d.png";
import vpl12d from "../../../../../../assets/images/Activities/VolunteeringPleasure/mock/Details/vpl12d.png";
import vpl13d from "../../../../../../assets/images/Activities/VolunteeringPleasure/mock/Details/vpl13d.png";

const mockHubData = {
  data: {
    _id: "657d4c4358946904810635f0",
    contact_name: "יהודה רוזנר",
    contact_number: "0555545454",
    image: HubImage,
    description:
      "הצטרפו אלינו לקטיף עגבניות בעוטף למשפחות החקלאים 👩🏻‍🌾👨🏻‍🌾\r\n\r\nהסעות יצאו מהבימה",
    published_by: "Aviad Masasa",
    needed: "-",
    quantity_needed: "11-20",
    indoor_location: "-",
    outdoor_location: "התמר 4, ניר עוז",
    category_type: "-",
    task_type: "activity",
    lattiude: "32.07282299999999",
    longitude: "34.77912000000001",
    start_date: "2024-02-27",
    end_date: "2024-02-27",
    start_time: "15:30",
    end_time: "19:00",
    reward: "נקודה",
    quantity_reward: "1",
    user_id: "64c37b822c7707274096b1fb",
    zone: "telAvivCity",
    subtitle: "קטיף עגבניות בעוטף",
    time_added: "1702710339296",
    __v: 7,
    likes: [],
  },
  status: 200,
  statusText: "",
  headers: {
    "content-length": "942",
    "content-type": "application/json; charset=utf-8",
  },
  config: {
    transitional: {
      silentJSONParsing: true,
      forcedJSONParsing: true,
      clarifyTimeoutError: false,
    },
    adapter: ["xhr", "http"],
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    maxContentLength: -1,
    maxBodyLength: -1,
    env: {},
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "get",
    url: "https://wallet.togathe33s.store/activities/657d4c4358946904810635f0",
  },
  request: {},
};

export const mockVPData = {
  vpl11: {
    ...mockHubData,
    data: {
      ...mockHubData.data,
      subtitle: "ניר הדבוראי",
      description: "הצטרפו אלינו למפגש מרתק עם ניר הדבוראי מקיבוץ נען",
      image: vpl11d,
      contact_number: "0502433367",
      contact_name: "יוני שוורץ",
      published_by: "נוסף על ידי",
      outdoor_location: "הבקעה 4, קיבוץ נען",
    },
  },
  vpl12: {
    ...mockHubData,
    data: {
      ...mockHubData.data,
      subtitle: "ניקוי פארק שלווה",
      description: "הצטרפו אלינו לניוקי פארק שלווה בחדרה",
      image: vpl12d,
      contact_number: "0502433367",
      contact_name: "יוני שוורץ",
      published_by: "נוסף על ידי",
      outdoor_location: "התמר 6, חדרה",
    },
  },
  vpl13: {
    ...mockHubData,
    data: {
      ...mockHubData.data,
      subtitle: "מיחזור בקבוקים",
      description: "בואו להנות מחוויה בלתי נשכחת במפעל למחזור בקבוקי פלסטיק",
      image: vpl13d,
      contact_number: "0502433367",
      contact_name: "יוני שוורץ",
      published_by: "נוסף על ידי ",
      outdoor_location: "השקמה 3, פארק אריאל שרון",
    },
  },
};

const organizationLogos = [Org1, Org2, Apple];

export { mockHubData, organizationLogos };
