import { REGION } from "../../../Main/constants";
import {
  DEFAULT_SHARE_DATA,
  ENGLISH_SHARE_DATA,
  SHARE_DATA,
} from "../../constant";

export const hideDownloadPWA = (id, installPrompt) => {
  if (id !== "download_pwa") return false;

  // Installed - opened in standalone mode
  if (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true
  )
    return true;

  // Not iOS - installed (beforeinstallprompt event not detected)
  if (!isIos() && !installPrompt) return true;
};

export const isIos = () =>
  /iPad|iPhone|iPod/.test(navigator?.userAgent) && !window?.MSStream;

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);

export const getCurrentShareData = (currentRoute) => {
  const isLocalRegion = process.env.REACT_APP_REGION === REGION.lOCAL;
  const currentShareData = SHARE_DATA[window.location.href];

  if (currentShareData) {
    return {
      ...(isLocalRegion ? currentShareData : ENGLISH_SHARE_DATA),
      url: currentRoute || window.location.href,
    };
  } else {
    return {
      ...(isLocalRegion ? DEFAULT_SHARE_DATA : ENGLISH_SHARE_DATA),
      url: currentRoute || window.location.href,
    };
  }
};
