import horse from "./MockIcons/horse.svg";
import sunset from "./MockIcons/sunset.svg";
import elephant from "./MockIcons/elephant.svg";
import zoology from "./MockIcons/zoology.svg";

export default [
  {
    name: "horse",
    icon: horse,
    color: "#FF5555",
    destination: "",
  },
  {
    name: "sunset",
    icon: sunset,
    color: "#71BBFF",
    destination: "",
  },
  {
    name: "zoology",
    icon: zoology,
    color: "#FF9737",
    backgroundColor: "#fff",
    destination: "",
  },
  {
    name: "elephant",
    icon: elephant,
    color: "#007897",
    destination: "",
  },
];
