import React from "react";

function LocationIconV2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={15}
      fill="none"
      viewBox="0 0 11 15"
    >
      <g fill="#EF7300" clipPath="url(#a)">
        <path d="M5.62 0c.346.066.698.112 1.037.202.907.239 1.681.714 2.334 1.383a5.098 5.098 0 0 1 1.373 2.551c.284 1.353.075 2.633-.633 3.821-1.329 2.232-2.665 4.46-4 6.689-.223.372-.601.375-.83.005A7311.099 7311.099 0 0 1 .8 8.038a5.148 5.148 0 0 1-.773-2.275C.025 5.735.01 5.709 0 5.683v-.816c.038-.239.066-.48.117-.716a5.143 5.143 0 0 1 1.374-2.566C2.145.916 2.92.441 3.827.202c.34-.09.69-.136 1.036-.202h.758Zm-.314 13.626c.02-.026.027-.033.031-.04 1.223-2.043 2.448-4.083 3.665-6.128a4.232 4.232 0 0 0 .576-2.71 4.24 4.24 0 0 0-.918-2.212C7.905 1.597 6.928 1.04 5.73.906a4.204 4.204 0 0 0-3.172.9c-.956.752-1.51 1.741-1.654 2.95-.123 1.036.127 1.989.676 2.87 1.214 1.952 2.423 3.906 3.634 5.859l.092.141Z" />
        <path d="M5.238 7.868a2.613 2.613 0 0 1-2.616-2.63 2.62 2.62 0 0 1 2.633-2.615 2.62 2.62 0 0 1 2.608 2.64 2.612 2.612 0 0 1-2.625 2.605ZM3.494 5.239a1.751 1.751 0 0 0 1.74 1.756A1.752 1.752 0 0 0 6.99 5.253a1.758 1.758 0 0 0-1.742-1.757 1.757 1.757 0 0 0-1.754 1.743Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h10.486v14.927H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LocationIconV2;
