import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${(props) => (props.isActive ? "101px" : "89px")};
  height: ${(props) => (props.isActive ? "47px" : "44px")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10.553px;
  box-sizing: border-box;
  background: ${(props) =>
    props.isActive
      ? "rgba(127, 179, 131, 0.25);"
      : "rgba(127, 179, 131, 0.05)"};
  box-shadow: 2.3px 2.3px 5.75px 1.15px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 5px;
`;
const LogoWrapper = styled.div`
  width: ${(props) => (props.isActive ? "54.617px" : "45.399px")};
  height: ${(props) => (props.isActive ? "54.617px" : "45.399px")};
  margin-top: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  background: rgba(127, 179, 131, 0.2);
  background: none;
  border: none;
  overflow: hidden;
`;
const Logo = styled.img`
  background: #f5f5f5;
  background: rgba(127, 179, 131, 0.2);
  background: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
`;

const Name = styled.div`
  color: #316788;
  text-align: center;
  font-family: Assistant;
  font-size: ${(props) => (props.isActive ? "17.564px" : "15.238px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 5px;
  white-space: normal;
`;
const Title = styled.h2`
  margin: 0;
  color: #316788;
  text-align: center;
  font-family: Assistant;
  font-size: ${(props) => (props.isActive ? "17px" : "15px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
function Card({ name, logo, key, index, isActive, activeSlide, handleFocus }) {
  return (
    <Wrapper isActive={isActive} onClick={() => handleFocus(index)} key={key}>
      {/* <LogoWrapper isActive={isActive}>
        <Logo src={logo} alt={`${name}-image`} />
      </LogoWrapper> */}
      <Name isActive={isActive}>{name}</Name>
    </Wrapper>
  );
}

export default Card;
