import React, { useState } from "react";
import styled from "styled-components";
import SoundIcon from "../../../../../../assets/icons/Common/NavBar/SoundIcon";
import MuteIcon from "../../../../../../assets/icons/Common/NavBar/MuteIcon";

const Wrapper = styled.div`
  border-top: 2px solid #fff;
  display: flex;
  /* justify-content: space-evenly; */
`;
const Tap = styled.div`
  width: 50%;
  overflow: hidden;
  padding: 10px 33px;
  display: flex;
  gap: 10px;
  background: ${(props) => (props.isActive ? "rgba(176, 220, 179, 0.90)" : "")};
  border-right: ${(props) => (props.hasBorder ? "2px solid #fff" : "none")};
`;
const IconWrapper = styled.div``;
const Text = styled.p`
  color: ${(props) => props.color ?? "#c13516"};
  margin: 0;
  text-align: right;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.44px;
  text-decoration-line: none;
`;
const SoundsContainer = () => {
  const [isMute, setIsMute] = useState(false);
  const handleClick = (soundStatus) => {
    setIsMute(soundStatus);
  };
  return (
    <Wrapper>
      <Tap
        hasBorder
        isActive={isMute === true}
        onClick={() => handleClick(true)}
      >
        <IconWrapper>
          <MuteIcon />
        </IconWrapper>
        <Text>off</Text>
      </Tap>
      <Tap isActive={isMute === false} onClick={() => handleClick(false)}>
        <IconWrapper>
          <SoundIcon />
        </IconWrapper>
        <Text color="#2A8A31">on</Text>
      </Tap>
    </Wrapper>
  );
};

export default SoundsContainer;
