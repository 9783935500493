import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const OopsPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const ErrorMessage = styled.h1`
  font-size: 24px;
  margin: 10px 0;
`;

const OopsMessage = styled.p`
  font-size: 16px;
  margin: 10px 0;
`;

const ReturnButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
`;

const PageNotFound = ({ message, error }) => {
  const navigate = useNavigate();

  const returnHome = () => {
    navigate("/");
  };

  error && console.log(error);

  return (
    <OopsPage>
      <ErrorMessage>Oops, something went wrong!</ErrorMessage>
      {message && <OopsMessage>{message}</OopsMessage>}
      <OopsMessage>
        It seems we've encountered an error. Please return to the home screen.
      </OopsMessage>
      <ReturnButton onClick={returnHome}>Return to Home</ReturnButton>
    </OopsPage>
  );
};

export default PageNotFound;
