import { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import TextareaWrapper from "./components/TextareaWrapper";
import Button from "../../../../kit/Button/Button";
import AddPhoto from "./components/AddPhoto";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../../store/userContext";
import { createPost, editPost } from "../api";
import { useNavigate } from "react-router-dom";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";
import { LanguageContext } from "../../../../store/languageContext";
import { CommunitySections } from "../Board";
import useLanguageSetter from "../../../../customHooks/useLanguageSetter";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import { PostButtonStyles } from "./AddPost";
import RichTextEditor from "../../../../kit/RichTextEditor/RichTextEditor";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 10px;
  width: 100%;
`;
const NeedPhotoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
`;

function VetPost({
  posts,
  setPosts,
  postData,
  onPostSuccess,
  zone,
  type,
  section,
  isEditMode = false,
}) {
  const { register, handleSubmit } = useForm({
    defaultValues: isEditMode ? postData : {},
  });
  const { currentUser, isAuthenticated } = useContext(UserContext);
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const navigate = useNavigate();

  const { currentLanguage } = useContext(LanguageContext);

  const onSubmit = async (data) => {
    isEditMode ? handleEditPost(data) : handleCreatePost(data);
  };

  const hasLink = useMemo(
    () => section === CommunitySections.FiveMin,
    [section]
  );

  const handleCreatePost = async (data) => {
    delete data.photo;

    const postData = {
      ...data,
      user_id: currentUser._id,
      photos: selectedFilesList,
      zone: zone,
      section: section,
      type: type ?? undefined,
      description: descriptionContent,
    };
    try {
      const response = await createPost(postData);
      setPosts([response.data.post, ...posts]);
      onPostSuccess?.();
    } catch (error) {
      console.error("Failed to create post.", error);
    }
  };

  const handleEditPost = async (data) => {
    const postData = {
      ...data,
      user_id: currentUser._id,
      zone: zone,
      section: section,
      type: type ?? undefined,
      photo: selectedFilesList,
      description: descriptionContent,
    };
    try {
      const response = await editPost(data?._id, postData);
      setPosts((posts) =>
        posts.map((post) =>
          post._id === data?._id ? response.data.post : post
        )
      );
      onPostSuccess?.();
    } catch (error) {}
  };

  const handleLogin = () => {
    setLoginRoute(`/activities/board/zone/${zone}/section/${section}/${type}`);
    navigate("/login");
  };

  useLanguageSetter(LANGUAGES.ENGLISH.value, LANGUAGES.HEBREW.value);

  if (!isAuthenticated) {
    return handleLogin();
  }

  const textProps = {
    labelColor: "#1B5C6D",
    labelFontSize: "14px",
    fontSize: "14px",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <RichTextEditor
          content={descriptionContent}
          setContent={setDescriptionContent}
          label={"Description:"}
        />
        <NeedPhotoWrapper>
          <AddPhoto
            placeholderLeft={"50px"}
            placeholderTop={"10px"}
            placeholderColor={"#1B5C6D"}
            placeholderTextDecoration={"underline"}
            register={{ ...register("photo") }}
            rightPlus={true}
            allowMultipleSelection
            selectedFilesList={selectedFilesList}
            setSelectedFilesList={setSelectedFilesList}
          />
        </NeedPhotoWrapper>
        {hasLink && (
          <TextareaWrapper
            label={"Add Link / Video :"}
            {...textProps}
            textDecoration="underline"
            register={{ ...register("link") }}
            minHeight={"35px"}
          />
        )}
        <Button
          text={"post"}
          styles={PostButtonStyles(currentLanguage === "english")}
        />
      </Wrapper>
    </div>
  );
}

export default VetPost;
