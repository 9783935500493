import requestHandler from "../../../store/requestHandler";

export const sendWhatsappMessage = async (payload) =>
  await requestHandler("post", `/whatsapp-messages/send`, payload);

export const handleSendMessage = async (payload) =>
  await requestHandler("post", "/send-sms", payload);

export const handleMakeCall = async (payload) =>
  await requestHandler("post", "/make-call", payload);
