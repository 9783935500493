import { useQuery } from "@tanstack/react-query";
import {
  getActivitiesByZone,
  getAllActivities,
} from "../domain/Ecosystem/Zone/Activities/Create/api";
import { useMemo } from "react";
import SliderCommunityImage1 from "../assets/images/Activities/TogetherInTheCity/Mock/slider-community-1.png";
import { getPhotoSrc } from "../domain/Ecosystem/Post/utils";
import { TaskZone } from "./useFetchTasks";

// Mock
import g32 from "../assets/images/Activities/TogetherInTheCity/gderot/Mock/cardSlider/g32n.jpg";
import l51 from "../assets/images/Activities/TogetherInTheCity/gderot/Mock/linearSlider/l51.png";

function useFetchActivities(zone, config = {}) {
  const { isLoading: isLoadingActivities, data: activities } = useQuery({
    queryKey: ["activities", zone],
    queryFn: () =>
      zone === TaskZone.All ? getAllActivities() : getActivitiesByZone(zone),
    enabled: zone !== null,
    ...config,
  });

  const fetchedActivities = useMemo(() => {
    return activities?.data?.activities
      ?.map?.((activity) => ({
        ...activity,
        image: activity.image
          ? getPhotoSrc(activity.image)
          : SliderCommunityImage1,
        name: activity.subtitle,
        title: activity.subtitle,
        location: activity.indoor_location,
        date: activity.start_date,
        id: activity._id,
        longitude: activity.longitude,
        latitude: activity.lattiude,
        task_type: "activity",
        linear: [
          {
            name: activity.subtitle,
            image: activity.image
              ? getPhotoSrc(activity.image)
              : SliderCommunityImage1,
            date: "09/01/2023",
            time: "07:30",
            id: activity._id,
          },
          {
            name: activity.subtitle,
            image: activity.image
              ? getPhotoSrc(activity.image)
              : SliderCommunityImage1,
            date: "09/01/2023",
            time: "07:30",
            id: activity._id,
          },
        ],
      }))
      .reverse();
  }, [activities?.data?.activities]);

  return { isLoadingActivities, fetchedActivities };
}

export default useFetchActivities;
