import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../../../store/userContext";
import BiggerThanIcon from "../../../assets/icons/Home/BiggerThanIcon";
import { Link } from "react-router-dom";
import DropDownOptions from "../../Ecosystem/Zone/component/DropDownOptions";
import { Domains } from "../constant";
import ButtonWithDropDown from "../../../kit/Button/ButtonWithDropDown";
import Icon from "../shorcutIcons/Icon";
import emergency from "../shorcutIcons/Mock/MockIcons/emergency.svg";
import GreenDoubleArrowIcon2 from "../../../assets/icons/GreenDoubleArrowIcon2";
import Elephant from "../../../assets/images/Home/Elephant/elephant.png";
const Wrapper = styled.div``;
const ImgaeWrapper = styled.div`
  width: 94%;
  height: 300px;
  margin: auto 11px;
  margin-top: 15px;
  border: 1px solid #087d0d;
  border-radius: 15px;
  background-image: url(${Elephant});
  position: relative;
`;
const ButtonAndSubTitleWrapper = styled.div`
  width: 94%;
  margin: auto;
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;
const SubTitle = styled.p`
  width: 25%;
  color: #003f5d;
  text-align: center;
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.404px;
`;
const ButtonWrapper = styled.div`
  width: 75%;
  padding: 5px 0;
  margin: 10px auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(70, 57, 77, 0.1);
`;
const YourInformationContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;
const YourName = styled.p`
  color: #fafffa;
  text-align: center;
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17.815px;
`;
const EcoSystem = styled.p`
  color: #fafffa;
  font-family: Assistant;
  font-size: 16.511px;
  font-style: normal;
  font-weight: 600;
  line-height: 4px;
  text-align: center;
`;
const ElephantCard = () => {
  const [isVisibleList, setIsVisibleList] = useState(false);
  const dropdownRef = useRef(null);

  const { currentUser } = useContext(UserContext);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsVisibleList(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <Wrapper>
      <ButtonAndSubTitleWrapper>
        <ButtonWrapper>
          <ButtonWithDropDown
            activeLink={"Together In Tel Aviv"}
            links={Domains}
            color={"#4B778D"}
            activeLinkColor={"#7FB383"}
            fontSizeActiveLink="21.472px"
            border="none"
            iconColor="#7FB383"
            textAlign="left"
            height="29px"
            backgroundColor={"#fff"}
            fontWeightActiveLink={600}
            activeLinkMargin={"0 0 10px 0;"}
            showUnderline={true}
          />
        </ButtonWrapper>
        <SubTitle>בחירת קהילה</SubTitle>
      </ButtonAndSubTitleWrapper>
      <ImgaeWrapper>
        <YourInformationContainer>
          <YourName>{currentUser.name}</YourName>
          <EcoSystem>Tel Aviv City </EcoSystem>
        </YourInformationContainer>
      </ImgaeWrapper>
    </Wrapper>
  );
};

export default ElephantCard;
