import { useContext } from "react";
import styled from "styled-components";
import Logout from "../../assets/images/Auth/Logout.png";
import { UserContext } from "../../store/userContext";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "../../assets/icons/Home/HomeIcon";
import EarthIcon from "../../assets/icons/EarthIcon";
import TogetherImage from "../../assets/images/Logo/member 2.png";

const Wrapper = styled.div`
  position: relative;
  margin: 0;
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "CooperLtBt-700";
  font-size: 20px;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #316788; /* For webkit-based browsers like Chrome and Safari */
`;
const SubTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: "CooperLtBt-700";
  font-size: 20px;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #316788; /* For webkit-based browsers like Chrome and Safari */
  margin-top: 3px;
`;

const LogoutImage = styled.img`
  position: absolute;
  width: 20px;
  cursor: pointer;
  left: 15px;
  top: 45px;
`;
const EarthWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 7px;
`;
const HomeIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border: none;
`;
const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const LinkButton = styled(Link)`
  color: #7fb383;
  text-shadow: 0px 0.9374998807907104px 0.9374998807907104px rgba(0, 0, 0, 0.12);
  font-family: Assistant;
  font-size: 13.143px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.394px;
  border-radius: 14.062px;
  border: 1.438px solid #7fb383;
  padding: 0 16px;
  text-decoration: none;
  margin-left: 18px;
  box-shadow: 0px 1.875px 4.6875px 0px rgba(66, 132, 128, 0.2);
`;
function Title({
  title,
  subTitle,
  titleImage = TogetherImage,
  showLinkButton = true,
  showLogout = true,
  showEarth = true,
  showHomeIcon = true,
}) {
  const { setCurrentUser, setIsAuthenticated } = useContext(UserContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    setCurrentUser({});
    setIsAuthenticated(false);
    localStorage.removeItem("access_token");
    navigate("/");
  };

  return (
    <Wrapper>
      <ImageWrapper>{title ? title : <Image src={titleImage} />}</ImageWrapper>
      {showHomeIcon && (
        <HomeIconWrapper>
          <HomeIcon color="#7FB383" />
        </HomeIconWrapper>
      )}
      {showLogout && <LogoutImage src={Logout} alt="" onClick={handleLogout} />}
      {showEarth && (
        <EarthWrapper>
          <EarthIcon />
        </EarthWrapper>
      )}
    </Wrapper>
  );
}

export default Title;
