import { useQuery } from "@tanstack/react-query";
import { getWaitingListById } from "../domain/Ecosystem/Zone/Activities/Create/api";
import { useMemo } from "react";

const useFetchWaitingList = (dataType, entityId) => {
  const { data: fetchedWaitingList, refetch: refetchWaitingList } = useQuery({
    queryKey: ["waitingList", dataType, entityId],
    queryFn: () => getWaitingListById(entityId, dataType),
  });

  const waitingList = useMemo(
    () => fetchedWaitingList?.data?.waiting_lists?.[0],
    [fetchedWaitingList]
  );

  return { waitingList, refetchWaitingList };
};

export default useFetchWaitingList;
