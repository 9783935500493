import React from "react";

const RightGreenArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="29"
      fill="none"
      viewBox="0 0 16 29"
    >
      <path
        fill="#7FB383"
        d="M14.05.551c.794.04 1.395.4 1.74 1.122.341.718.264 1.417-.208 2.06-.129.173-.289.324-.442.478-3.306 3.302-6.61 6.608-9.931 9.896-.305.303-.265.438.012.713 3.35 3.324 6.688 6.663 10.03 9.994.567.565.763 1.235.55 2-.208.752-.724 1.224-1.478 1.394-.74.167-1.366-.07-1.9-.608-2.115-2.123-4.237-4.237-6.356-6.357-1.736-1.734-3.471-3.468-5.204-5.204-1.03-1.033-1.037-2.143-.009-3.172C4.717 9 8.584 5.138 12.446 1.272c.447-.447.949-.744 1.605-.72h-.001z"
      ></path>
    </svg>
  );
};

export default RightGreenArrow;
