import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";

const Wrapper = styled.a`
  width: "auto";
  height: 56.196px;
  display: flex;
  flex: 1;
  flex-direction: ${({ isWideScreen }) => (isWideScreen ? "row" : "column")};
  justify-content: ${({ isWideScreen }) => (isWideScreen ? "start" : "center")};
  align-items: center;
  gap: ${({ isWideScreen }) => (isWideScreen ? "10px" : "0")};
  cursor: pointer;
  border-radius: ${(props) =>
    props.isWideScreen
      ? "none"
      : props.label === "Home" || props.image
      ? "8.23px 8.226px 8.226px 20px"
      : props.label === "Profile"
      ? "8.056px 8.056px 20px 8.056px"
      : "8px"};
  border: ${({ isWideScreen, isAbstractEmergency }) =>
    isWideScreen || isAbstractEmergency
      ? "none"
      : "0.161px solid rgba(101, 201, 101, 0.89)"};
  background: ${({ isWideScreen, isAbstractEmergency }) =>
    isAbstractEmergency
      ? "white"
      : isWideScreen
      ? "none"
      : "rgba(255, 255, 255, 0.5)"};
  box-shadow: ${({ isWideScreen }) =>
    isWideScreen ? "none" : " 0px 1.6px 1.6px 0px rgba(0, 0, 0, 0.25)"};
`;

const IconWrapper = styled.div`
  text-align: center;
  width: ${({ isWideScreen }) => (isWideScreen ? "auto" : "69.504px")};
  height: 55.916px;
  display: flex;
  justify-content: ${({ isWideScreen }) => (isWideScreen ? "start" : "center")};
  align-items: ${({ isWideScreen }) => (isWideScreen ? "center" : "center")};
`;

const Text = styled.div`
  color: ${(props) => props.textColor ?? "#007897"};
  text-align: center;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.5px;
  padding: 4px 0;
`;
const Image = styled.img`
  width: ${({ isWideScreen }) => (isWideScreen ? "31.702px" : "41.412px")};
  height: ${({ isWideScreen }) => (isWideScreen ? "35.202px" : "45.698px")};
`;
function Item({
  color,
  Icon,
  iconColor,
  image,
  label = undefined,
  navigateLink,
  isAbstractEmergency,
}) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(navigateLink);
  };

  return (
    <Wrapper
      onClick={handleNavigate}
      label={label}
      image={image}
      isWideScreen={isWideScreen}
      isAbstractEmergency={isAbstractEmergency}
    >
      <IconWrapper isWideScreen={isWideScreen}>
        {Icon && <Icon color={iconColor} />}
        {image && <Image src={image} isWideScreen={isWideScreen} />}
      </IconWrapper>
      {label && <Text textColor={color}>{label}</Text>}
    </Wrapper>
  );
}

export default Item;
