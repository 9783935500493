import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import useGetEcosystemById from "../useGetEcosystemById";
import { TogetherIcon, TogetherIconV3 } from "../../../../assets/icons/Logo";
import Header from "../../../Common/Layout/Header";
import LoadingSpinner from "../../../Common/Loading/Loading";
import Activities from "../../Zone/Activities/Activities";
import SliderCard from "../../Zone/Common/Cards/Slider/Gderot/Card1";
import Title from "../../Zone/Common/Title/City/Page";
import { MAIN_ROUTES } from "../../../Common/Layout/Main/constants";
import { getEmergencyData, vetSponsersData } from "../../Mock/publicData";
import CopyRightAndTerms from "../../../Common/Layout/Footer/components/CopyRightAndTerms";
import { UserContext } from "../../../../store/userContext";
import Emergency from "../../Zone/Common/Cards/Emergency";
import EmergencyLinearCard from "../../Zone/Common/Cards/Linear/Emergency2";
import { navigateToCommunitySection } from "../../Board/utils";
import { PostType } from "../../Board/api";
import GreenAppleIcon from "../../../../assets/icons/Common/Apples/GreenAppleIcon";
import {
  HUB_CREATE_ROUTE,
  HUB_NAMES,
  LANDING_ROUTES,
  NO_AUTH_VIEW,
  NO_APPLES_AND_SEARCH_SECTION,
  SECTION_TITLE,
  NO_EMERGENCY_SECTION,
  NO_SPONSORS_SECTION,
  NO_BOARD_SECTION,
  NO_Volunteer_BOARD,
  NO_GIVE_RECEIVE_BOARD,
  NO_NETWORK_BOARD,
  VET_SPONSORS_SECTION,
  EMERGENCY_BUTTONS_COLLAPSED,
} from "../constant";
import { isGoHomeRoute, setData } from "../utils";
import {
  Input,
  Section,
  AddButton,
} from "../../../Admin/AdminArea/CreatePage/CreatePage";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";
import {
  EcosystemSectionType,
  createSectionInEcosystem,
  deleteEcosystem,
  deleteSectionFromEcosystem,
  editEcosystem,
} from "../../Zone/Activities/Create/api";
import SelectionPopup from "../../../Admin/AdminArea/CreatePage/SelectionPopup";
import useEcosystemData from "../useEcosystemData ";
import { EcosystemZone } from "../../../Admin/AdminArea/CreatePage/constans";
import EmergencyButtons from "../../Zone/Common/EmergencyButtons/EmergencyButtons";
import { LanguageContext } from "../../../../store/languageContext";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import { DataType } from "../../Zone/Common/CardDetailsPage/CardDetailsPage";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import ApplesAndSearchSection from "../../Zone/Common/ApplesAndSearchSection/ApplesAndSearchSection";
import LogosSlider from "../../Zone/Common/LogosSlider/LogosSlider";
import InfiniteSlider from "../../../../kit/InfiniteSlider/InfiniteSlider";
import ReturnArrow from "../../../Common/Layout/ReturnArrow/ReturnArrow";
import useFetchBoardsPosts from "../../../../customHooks/useFetchBoardsPosts";
import LinearCard from "../../Zone/Common/Cards/Linear/CardWithMoreInfo/LinearCards";
import GiveAndRecieveImage from "../../../../assets/images/Activities/Common/give-receive.png";
import VolunteerBoardImage from "../../../../assets/images/Activities/CommunityBoard/card-cover.png";
import NetworkingBoardImage from "../../../../assets/images/Activities/TogetherInTheCity/telaviv/Mock/cardSlider/tel82.jpg";
import useIsUserAuthorizedForCreate from "../../../../customHooks/useIsUserAuthorizedForCreate";
import useUserHasAccessToEcosystem from "../useUserHasAccessToEcosystem";
import LoginAccessText from "../../../../kit/LoginAccessText/LoginAccessText";
import { getEnv } from "../../../Common/utils/env";
import LoginModal from "../../../../kit/Modal/LoginModal";
import { CurvedTitle } from "../../../../kit/Title";
import { LinearCardsV2, SliderCardsV2 } from "../../../../kit/Card";
import ActivitiesV2 from "../../Zone/Activities/ActivitiesV2";
import { hubSliderData } from "../Mock/VolunteersAndSpendTimeData";
import TelAvivMap from "../Mock/TelAvivSmallMap.png";
import TelAvivMap2 from "../Mock/TelAvivSmallMap2.png";
import TelAvivMap3 from "../Mock/TelAvivSmallMap3.png";
import MuiButton from "../../../../kit/Button/MuiButton";
import LogoWithText from "../../../../kit/Icon/IconWithText";

export const PurposeSections = [
  { title: "שכונה", value: EcosystemSectionType.Neighborhood },
  { title: "בית ספר / אוניברסיטה", value: EcosystemSectionType.School },
  { title: "ארגון", value: EcosystemSectionType.Organization },
];

export const EnglishPurposeSections = [
  { title: "My neighborhood", value: EcosystemSectionType.Neighborhood },
  { title: "Educational institutions", value: EcosystemSectionType.School },
  { title: "Organizations", value: EcosystemSectionType.Organization },
];

const getMapImage = (id) => {
  switch (id) {
    case "6677a60e31d965b52d4e58d8":
      return TelAvivMap2;
    case "667c720431d965b52d51abf7":
      return TelAvivMap3;
    default:
      return TelAvivMap;
  }
};

const Wrapper = styled.div`
  padding-bottom: ${(props) => (props.isAuthenticated ? "80px" : "0")};
  min-height: 85vh;
  background: #e5eeff;
`;

const SubTitle = styled.div`
  color: #003f5d;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const MapTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 27.2px;
  letter-spacing: 0.223px;
  text-align: center;
  color: rgba(0, 63, 93, 1);
  text-decoration: underline;
`;

function VolunteersAndSpendTime() {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { isAuthenticated, currentUser } = useContext(UserContext);
  const { zone: zoneId } = useParams();
  const {
    data: ecosystem,
    isLoading,
    refetchEcosystem,
  } = useGetEcosystemById(zoneId);
  const navigate = useNavigate();
  const [clickedCardIndex, setClickedCardIndex] = useState(-1);
  const [createSectionPopupVisible, setCreateSectionPopupVisible] =
    useState(false);
  const [editSectionPopupVisible, setEditSectionPopupVisible] = useState(false);
  const { pathname } = useLocation();
  const isPublic = pathname.includes("public");
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const [selectedEditSection, setSelectedEditSection] = useState({
    value: undefined,
    id: undefined,
  });
  const [sectionTitle, setSectionTitle] = useState();
  const [showEntryForVolunteers, setShowEntryForVolunteers] = useState(false);

  const { setCurrentLanguage } = useContext(LanguageContext);
  const previousRoute = useMemo(
    () =>
      isGoHomeRoute(ecosystem?._id, getEnv())
        ? "/"
        : LANDING_ROUTES.includes(ecosystem?._id)
        ? pathname.split("/").slice(0, -1).concat(ecosystem?.parent).join("/")
        : "/",
    [pathname, ecosystem?.parent, ecosystem?._id]
  );

  const userHasAccess = useUserHasAccessToEcosystem(ecosystem);
  const zones = [
    {
      name: "כניסה למתנדבים",
      isSelected: false,
      showName: true,
      onClick: () =>
        navigate(
          isAuthenticated
            ? isPublic
              ? `/activities/view/${zoneId}`
              : `/public/activities/view/${zoneId}`
            : `/login/${zoneId}`
        ),
    },
  ];

  const isUserAuthorizedForCreate = useIsUserAuthorizedForCreate(
    !isPublic,
    currentUser &&
      currentUser.admin &&
      currentUser.admin.includes(ecosystem?._id)
      ? currentUser?._id
      : ecosystem?.createdBy
  );

  const {
    sectionData,
    fetchedTasks,
    fetchedEvents,
    fetchedActivities,
    hubsData,
    isLoadingHubs,
  } = useEcosystemData(ecosystem, PostType, zoneId);

  const isSmallCommunity = useMemo(
    () =>
      /^[0-9a-fA-F]{24}$/i.test(ecosystem?.parent) ||
      ecosystem?._id === "6640beff688afe7a27141c6b",
    [ecosystem]
  );

  const emergencyButtonsCollapsedByDefault = useMemo(
    () => EMERGENCY_BUTTONS_COLLAPSED.includes(ecosystem?._id),
    [ecosystem?._id]
  );

  const hideEmergency = useMemo(
    () => NO_EMERGENCY_SECTION.includes(ecosystem?._id),
    [ecosystem?._id]
  );

  const hideSponsors = useMemo(
    () => NO_SPONSORS_SECTION.includes(ecosystem?._id),
    [ecosystem?._id]
  );

  const showVetSponsors = useMemo(
    () => VET_SPONSORS_SECTION.includes(ecosystem?._id),
    [ecosystem?._id]
  );

  const hideBoardSection = useMemo(
    () => NO_BOARD_SECTION.includes(ecosystem?._id),
    [ecosystem?._id]
  );

  const onCreateSectionPopupDismiss = async (selected) => {
    try {
      if (!selected) return;
      await createSectionInEcosystem(
        sectionTitle && sectionTitle?.trim?.() !== ""
          ? sectionTitle?.trim?.()
          : SECTION_TITLE[selected] ?? selected,
        ecosystem?._id,
        selected
      );
      refetchEcosystem?.();
    } catch (err) {
      console.error("Failed to create section", err);
    }
  };
  const onEditSectionPopupDismiss = async (selected) => {
    try {
      if (!selected) return;
      await editEcosystem(ecosystem?._id, {
        ...ecosystem,
        sections: ecosystem.sections?.map((s) => {
          if (s._id !== selectedEditSection.id) return s;
          return {
            ...s,
            type: selected,
          };
        }),
      });
      setSelectedEditSection(undefined);
      refetchEcosystem?.();
    } catch (err) {
      console.error("Failed to edit section", err);
    }
  };

  const isEnglish = useMemo(
    () =>
      ecosystem?.zoneType === EcosystemZone.Europe ||
      ecosystem?.zoneType === EcosystemZone.USA,
    [ecosystem]
  );

  const postingCards = useMemo(() => {
    return ecosystem?.postingCards?.map((card) => ({
      ...card,
      title: card.name,
      id: card._id,
    }));
  }, [ecosystem?.postingCards]);

  const { boardWithLinearPosts } = useFetchBoardsPosts(
    ecosystem?._id,
    postingCards?.map((card) => card._id),
    postingCards
  );

  const coverImages = useMemo(() => {
    const filteredImages = ecosystem?.coverImages?.filter(
      (image) => image !== null
    );
    if (filteredImages?.length === 0) {
      return ecosystem?.image ? [ecosystem?.image] : [];
    }

    return filteredImages;
  }, [ecosystem?.coverImages, ecosystem?.image]);

  const volunteerForum = useMemo(
    () => [
      !NO_Volunteer_BOARD.includes(ecosystem?._id) && {
        title: ecosystem?.volunteerForum?.[1] ?? "Volunteer Board",
        image: VolunteerBoardImage,
      },
      !NO_GIVE_RECEIVE_BOARD.includes(ecosystem?._id) && {
        title: ecosystem?.volunteerForum?.[0] ?? "Give & Receive",
        image: GiveAndRecieveImage,
      },
      !NO_NETWORK_BOARD.includes(ecosystem?._id) && {
        title: ecosystem?.volunteerForum?.[2] ?? "Volunteer networking",
        image: NetworkingBoardImage,
      },
    ],
    [ecosystem?._id, ecosystem?.volunteerForum]
  );

  useEffect(() => {
    if (isEnglish) {
      setCurrentLanguage(LANGUAGES.ENGLISH.value);
      return;
    }
    setCurrentLanguage(LANGUAGES.HEBREW.value);
  }, [isEnglish, setCurrentLanguage]);

  useEffect(() => {
    return () => {
      setCurrentLanguage(LANGUAGES.HEBREW.value);
    };
  }, []);

  useEffect(() => {
    setLoginRoute(`/activities/view/${zoneId}`);
  }, [zoneId, setLoginRoute]);

  if (isLoading || isLoadingHubs) {
    return <LoadingSpinner center={true} text={"Loading"} />;
  }

  const renderReturnArrow = () => (
    <>
      {previousRoute &&
        !previousRoute.endsWith("/activities/view/telAvivCity") &&
        !previousRoute.includes("/card-details/") &&
        ecosystem?.parent && (
          <ReturnArrow onClickOverride={() => navigate("/")} forceShowArrow />
        )}
    </>
  );

  if (!userHasAccess && !isLoading && !NO_AUTH_VIEW.includes(ecosystem?._id)) {
    if (!isAuthenticated) {
      navigate("/login/private-ecosystem");
    }
    return (
      <>
        {renderReturnArrow()}
        <LoginAccessText noLogin viewText={"ecosystem"} />
      </>
    );
  }

  return (
    <>
      <SelectionPopup
        title={isEnglish ? "Add a subgroup in the community" : "מטרת הקבוצה"}
        visible={createSectionPopupVisible}
        setVisible={setCreateSectionPopupVisible}
        onDismiss={onCreateSectionPopupDismiss}
        options={isEnglish ? EnglishPurposeSections : PurposeSections}
        currentSections={ecosystem?.sections}
        showExtraOption
        extraOptionPlaceholder={isEnglish ? "Add headline" : "הוסף כותרת"}
      />
      <SelectionPopup
        title={isEnglish ? "Add a subgroup in the community" : "מטרת הקבוצה"}
        visible={editSectionPopupVisible}
        setVisible={setEditSectionPopupVisible}
        onDismiss={onEditSectionPopupDismiss}
        options={PurposeSections}
        initiallySelected={selectedEditSection?.value}
      />
      {renderReturnArrow()}
      <Wrapper>
        <Header
          zones={zones}
          headerButtons={
            isUserAuthorizedForCreate
              ? [
                  {
                    title: "עריכה",
                    onClick: () =>
                      navigate(`/admin/create-page/edit/${ecosystem?._id}`),
                  },
                  {
                    title: "השהיית קהילה",
                    color: "#D04343",
                    onClick: async () => {
                      try {
                        await deleteEcosystem(ecosystem?._id);
                        navigate("/");
                      } catch (er) {
                        console.error("Failed to delete ecosystem", er);
                      }
                    },
                  },
                ]
              : undefined
          }
          headerButtonsMargin={"10px 0 0 0"}
          titlePos={{ top: "40px", right: "-90px" }}
          padding={"15px 0"}
          Icon={() => (
            <LogoWithText
              Icon={TogetherIconV3}
              top={"47px"}
              left={"-7px"}
              // title={ecosystem?.title}
              title={"מבלים ומתנדבים ביחד"}
              iconWidth="300px"
              iconHeight="70px"
              titleStyles={`
          font-size: 15.77px;
          color: #5A955F;
          font-weight: 400;
        `}
            />
          )}
          titlesLang="heb"
          zoneMarginLeft={"15px"}
          showLogoutIcon={
            !previousRoute ||
            previousRoute === "/public/activities/view/telAvivCity"
          }
          bottomComponent={<SubTitle>פעילויות לשגשוג חברתי / קהילתי</SubTitle>}
          zone={ecosystem?._id}
        />
        {coverImages?.length > 0 && (
          <InfiniteSlider borderRadius={"0"} images={coverImages} />
        )}
        <CurvedTitle
          backgroundColor={"#ceddf9"}
          isAuthenticated={isAuthenticated}
          subTitle={"מוזמנים להתנדב ולבלות בסביבתכם"}
        />
        {!NO_APPLES_AND_SEARCH_SECTION.includes(ecosystem?._id) && (
          <ApplesAndSearchSection
            zone={ecosystem?._id}
            isVT
            margin={"0"}
            hasApples={false}
            backgroundColor={"#ceddf9"}
          />
        )}
        <div
          style={{
            background: "#ceddf9",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MapTitle>חיפוש על פי מיקום</MapTitle>
          <img
            style={{
              width: "90%",
              height: "118px",
              maxWidth: "350px",
              margin: "10px auto",
              cursor: "pointer",
            }}
            src={getMapImage(ecosystem?._id)}
            alt="Map"
            onClick={() =>
              navigate(`/activities/map/volunteering_pleasure/${zoneId}`)
            }
          />
        </div>
        {ecosystem?.hubs?.map((hub, index) => {
          const zoneId = [
            HUB_NAMES.ACTIVITY,
            HUB_NAMES.EVENT,
            HUB_NAMES.TASK,
          ].includes(hub.name)
            ? ecosystem?._id
            : hub?._id;
          const hubData = setData(
            hub.name,
            hub._id,
            fetchedTasks,
            fetchedEvents,
            fetchedActivities,
            hubsData
          );
          return (
            <ActivitiesV2
              TitleComponent={Title}
              titleProps={{
                hebrewTitle: hub.title,
                englishTitle: hub.title,
                AreIconsOnTheSameLine: true,
                showDivider: false,
                underline: hub.isPrimary && true,
                link:
                  hub.isPrimary &&
                  `/${MAIN_ROUTES.SEARCH}/zone/${zoneId}/type/${hub.name}`,
              }}
              SliderComponent={SliderCardsV2}
              sliderProps={{
                data: hubSliderData[index],
                link: `/activities/${ecosystem._id}/card-details/${
                  hub?.name || DataType.Hub
                }`,
              }}
              //TODO: view if you created the ecosystem or admin
              sideLink={
                isUserAuthorizedForCreate && {
                  title: "Create",
                  link: `/activities/${zoneId}/create/${
                    hub?.name
                      ? HUB_CREATE_ROUTE[hub.name]
                      : HUB_CREATE_ROUTE.hub
                  }`,
                }
              }
              LinearComponent={LinearCardsV2}
              linearProps={{
                data: hubData,
                link: `/activities/${ecosystem._id}/card-details/${
                  hub?.name || DataType.Hub
                }`,
                cardLimit: 2,
                hasViewMore: true,
              }}
            />
          );
        })}
        {isUserAuthorizedForCreate && (
          <Section isWideScreen={isWideScreen || isMediumScreen}>
            <Input
              width={"80%"}
              placeholder={
                isEnglish
                  ? "Add a subgroup in the community"
                  : "צור תת קבוצה בקהילה"
              }
              onChange={(e) => setSectionTitle(e.target?.value)}
            />
            <AddButton
              isWideScreen={isWideScreen || isMediumScreen}
              onClick={() => {
                setCreateSectionPopupVisible(true);
              }}
            >
              {isEnglish ? "Add" : "הוסף"}
            </AddButton>
            <Activities
              data={[]}
              background={"none"}
              boxShadow={"none"}
              SliderComponent={SliderCard}
              sliderProps={{
                data: [],
                HeartColor: "green",
                locationFontSize: "17px",
                locationColor: "#316788",
                liked: false,
                showLocation: false,
                showDate: false,
                hebrewDescription: "בואו וניתן באהבה למי שצריך",
              }}
            />
          </Section>
        )}
        {ecosystem?.sections?.map((section) => {
          return (
            <div style={{ position: "relative" }}>
              {isUserAuthorizedForCreate && (
                <AddButton
                  isWideScreen={isWideScreen || isMediumScreen}
                  onClick={() => {
                    setSelectedEditSection({
                      value: section.type,
                      id: section._id,
                    });
                    setEditSectionPopupVisible(true);
                  }}
                >
                  {"Edit"}
                </AddButton>
              )}
              <Activities
                data={sectionData[section.type]}
                hasActions
                TitleComponent={Title}
                titleProps={{
                  hebrewTitle: section.title ?? SECTION_TITLE[section.type],
                  englishTitle: section.title ?? SECTION_TITLE[section.type],
                  AreIconsOnTheSameLine: true,
                  showDivider: false,
                }}
                SliderComponent={SliderCard}
                sliderProps={{
                  cardWidth: 0,
                  data: sectionData[section.type]?.reverse(),
                  HeartColor: "green",
                  titleColor: "#9FA3A8",
                  liked: false,
                  showLocation: false,
                  showDate: false,
                  link: isPublic
                    ? `/public/activities/view`
                    : `/activities/view`,
                  locationFontSize: "17px",
                  locationColor: "#316788",
                }}
                sideLink={
                  isUserAuthorizedForCreate && {
                    title: "create",
                    link: `/admin/create-page/${ecosystem._id}/${section._id}`,
                  }
                }
                showDelete={isUserAuthorizedForCreate}
                deleteOnClick={async () => {
                  try {
                    await deleteSectionFromEcosystem(
                      section._id,
                      ecosystem._id
                    );
                    refetchEcosystem();
                  } catch (er) {
                    console.error(
                      "Failed to delete section from ecosystem",
                      er
                    );
                  }
                }}
              />
            </div>
          );
        })}
        {postingCards?.length > 0 && ecosystem?.postingTitle && (
          <Activities
            boxShadow={"none"}
            data={boardWithLinearPosts}
            hasActions
            background={"rgba(127, 179, 131, 0.06)"}
            TitleComponent={Title}
            titleProps={{
              title: ecosystem?.postingTitle
                ? ecosystem?.postingTitle
                : "טיפים ועצות שימושיות Tips",
              AreIconsOnTheSameLine: true,
              showDivider: false,
            }}
            SliderComponent={SliderCard}
            sliderProps={{
              cardWidth: 0,
              data: boardWithLinearPosts,
              HeartColor: "green",
              titleColor: "#9FA3A8",
              hasShadow: false,
              liked: false,
              showLocation: false,
              link: `/activities/board/zone/${ecosystem._id}/section/custom`,
              showDate: false,
              locationFontSize: "17px",
              locationColor: "#316788",
              hebrewDescription: "בואו וניתן באהבה למי שצריך",
            }}
            LinearComponent={LinearCard}
            linearProps={{
              data: [boardWithLinearPosts?.[0], boardWithLinearPosts?.[1]],
              liked: true,
              linkAddress: "More",
            }}
            openedCard={isWideScreen || isMediumScreen ? undefined : 0}
            isLinearOpen
          />
        )}
        {!hideBoardSection && (
          <Activities
            data={volunteerForum}
            boxShadow={"none"}
            hasActions
            InitialStateOfCards
            TitleComponent={Title}
            titleProps={{
              hebrewTitle: ecosystem?.boardTitle
                ? ecosystem?.boardTitle
                : isEnglish
                ? "Volunteer Forum"
                : "פורום מתנדבים",
              englishTitle: ecosystem?.boardTitle
                ? ecosystem?.boardTitle
                : "Volunteer Forum",
              showDivider: false,
              AreIconsOnTheSameLine: true,
              link: `/activities/board/zone/${ecosystem._id}`,
            }}
            SliderComponent={SliderCard}
            sideLink={
              isUserAuthorizedForCreate && {
                title: "Create",
                link: `/activities/board/zone/${ecosystem._id}`,
              }
            }
            sliderProps={{
              data: volunteerForum,
              HeartColor: "green",
              hasShadow: false,
              locationFontSize: "17px",
              locationColor: "#316788",
              liked: false,
              showLocation: false,
              showDate: false,
              hebrewDescription: "בואו וניתן באהבה למי שצריך",
              handleClick: (clickedIndex) => {
                navigateToCommunitySection(
                  clickedIndex,
                  clickedCardIndex,
                  setClickedCardIndex,
                  navigate,
                  ecosystem._id
                );
              },
            }}
          />
        )}
        {isSmallCommunity && !hideEmergency && (
          <EmergencyButtons
            collapsedByDefault={emergencyButtonsCollapsedByDefault}
          />
        )}
        {!isSmallCommunity && (
          <Activities
            data={getEmergencyData(isEnglish)}
            hasActions
            InitialStateOfCards
            SliderComponent={Emergency}
            boxShadow={"none"}
            sliderProps={{
              enTitle: "Emergency ",
              hebTitle: "התארגנות וסיוע אישי במצבי חירום ",
              data: getEmergencyData(isEnglish),
              hasShadow: false,
              zone: ecosystem._id,
              englishLogo: isEnglish,
              hideLogoText: true,
              useLogoTitle: true,
              logoTitle: ecosystem?.title,
            }}
            LinearComponent={EmergencyLinearCard}
            linearProps={{
              liked: false,
              linkAddress: isEnglish ? "More info" : "פרטים נוספים",
              link: `/${MAIN_ROUTES.PRIVATE_ZONE}/${ecosystem._id}/linear-card-details`,
            }}
          />
        )}
        {showVetSponsors ? (
          <LogosSlider
            title={"Ecosystem Sponsors"}
            data={vetSponsersData}
            backgroundColor={"#7FB38340"}
          />
        ) : (
          !hideSponsors && (
            <LogosSlider
              title={
                ecosystem.sponsorsTitle && ecosystem.sponsorsTitle !== ""
                  ? ecosystem.sponsorsTitle
                  : "Sponsors"
              }
              data={ecosystem.sponsors ?? []}
              backgroundColor={"#DBF4F0CC"}
            />
          )
        )}
        <div style={{ width: "100%", display: "flex" }}>
          <MuiButton
            variant="outlined"
            sx={{
              borderRadius: "12.5px",
              width: "90%",
              maxWidth: "360px",
              height: "44px",
              margin: "0 auto 15px auto",
              background: "#F1F3F4",
              border: "none",
              color: "rgb(49, 103, 136)",
              fontSize: "22px",
            }}
            onClick={() => navigate(`/time-reward-system/telAvivCity`)}
            startIcon={<GreenAppleIcon width={30} height={30} />}
          >
            מימוש הטבות למתנדבים
          </MuiButton>
        </div>
        {!isAuthenticated && <CopyRightAndTerms backgroundColor={"#E5EEFF"} />}
        {showEntryForVolunteers && (
          <LoginModal
            handleClose={() => setShowEntryForVolunteers(false)}
            open={showEntryForVolunteers}
            onSubmit={() =>
              navigate(`/activities/create-profile/${ecosystem?._id}`)
            }
          />
        )}
      </Wrapper>
    </>
  );
}

export default VolunteersAndSpendTime;
