import { EmergencyIcon } from "../../../../assets/icons";
import beach from "../../Zone/Common/BigApplesSlider/imgs/beach.png";
import tv1 from "../../Zone/Common/BigApplesSlider/imgs/tv1.png";
import ju3 from "../../Zone/Common/BigApplesSlider/imgs/ju3.png";
import { placesColors } from "../../../../kit/Map/CircleConstants";

export const GEO_LOCATION_POSITION_ERROR = {
  1: `The acquisition of the geolocation information failed because the page didn't have the necessary permissions, for example because it is blocked by a Permissions Policy`,
  2: `The acquisition of the geolocation failed because at least one internal source of position returned an internal error`,
  3: `The time allowed to acquire the geolocation was reached before the information was obtained.`,
};

export const EMERGENCY_TABS = [
  {
    title: "מצוקה",
    backgroundColor: "#FF8933",
    color: "#ffffff",
    icon: <EmergencyIcon />,
    tabName: "emergency",
  },
  {
    title: "צוות",
    iconColor: "#FFB400",
    tabName: "staff",
  },
  {
    title: "מתנדבים",
    iconColor: "#828E8C",
    tabName: "volunteers",
  },
  {
    title: "אחר",
    iconColor: "#C490E9",
    tabName: "other",
  },
];

export const VT_TABS = [
  [
    {
      label: "בריאות",
      value: 2,
      color: "#4ABFEE",
    },
    {
      label: "בעלי חיים",
      value: 1,
      color: "#003F5D",
    },
    {
      label: "All כל הקטגוריות",
      value: "all",
    },
  ],
  [
    {
      label: "תרבות",
      value: 5,
      color: "#982514",
    },
    {
      label: "חקלאות",
      value: 4,
      color: "#559255E3",
    },
    {
      label: "סביבה",
      value: 3,
      color: "#27C6A9",
    },
    {
      label: "טיולים",
      value: 3,
      color: "#784378",
    },
  ],
];

export const VT_SPENDTIME_TABS = [
  [
    {
      label: "חינוך והעשרה",
      value: "3",
      color: "#784378",
    },
    {
      label: "ספורט",
      value: "2",
      color: "#559255E3",
    },
    {
      label: "תרבות ופנאי",
      value: "1",
      color: "#D61C72",
    },
    {
      label: "All כל הקטגוריות",
      value: "all",
    },
  ],
];

export const DEFAULT_VT_TABS = [
  [
    {
      label: "ספורט",
      value: "task",
      color: "rgba(152, 37, 20, 1)",
    },
    {
      label: "בעלי חיים",
      value: 1,
      color: "rgba(85, 146, 85, 0.89)",
    },
    {
      label: "All כל הקטגוריות",
      value: "all",
    },
  ],
  [
    {
      label: "חקלאות",
      value: 6,
      color: "rgba(120, 67, 120, 1)",
    },
    {
      label: "תרבות",
      value: "event",
      color: "rgba(0, 63, 93, 1)",
    },
    {
      label: "סביבה",
      value: "activity",
      color: "rgba(247, 151, 107, 1)",
    },
    {
      label: "חינוך",
      value: 3,
      color: "rgba(85, 146, 85, 0.89)",
    },
  ],
];

export const VT_TEL_AVIV_TABS = [
  [
    {
      label: "חינוך והעשרה",
      value: "3",
      color: "#784378",
    },
    {
      label: "ספורט",
      value: "2",
      color: "#559255E3",
    },
    {
      label: "תרבות ופנאי",
      value: "1",
      color: "#D61C72",
    },
    {
      label: "All כל הקטגוריות",
      value: "all",
    },
  ],
  [
    {
      label: "חקלאות",
      value: "4",
      color: "#FFDB4F",
    },
    {
      label: "תרבות",
      value: "5",
      color: "#F7976B",
    },
    {
      label: "סביבה",
      value: "6",
      color: "#003F5D",
    },
    {
      label: "חינוך",
      value: "7",
      color: "#4ABFEE",
    },
  ],
];

export const VT_GDEROT_TABS = [
  [
    {
      label: "חינוך והעשרה",
      value: "3",
      color: "#784378",
    },
    {
      label: "ספורט",
      value: "2",
      color: "#559255E3",
    },
    {
      label: "תרבות ופנאי",
      value: "1",
      color: "#D61C72",
    },
    {
      label: "All כל הקטגוריות",
      value: "all",
    },
  ],
];

export const ABSTRACT_EMERGENCY_TABS = [
  [
    {
      label: "רפואה דחופה",
      value: "1",
      color: "#E81616",
    },
    {
      label: "All כל הקטגוריות",
      value: "all",
    },
  ],
  [
    {
      label: "צבא וביטחון",
      value: "2",
      color: "#F7976B",
    },
    {
      label: "כיבוי אש",
      value: "3",
      color: "#982514",
    },
    {
      label: "עזרה נפשית",
      value: "4",
      color: "#559255E3",
    },
  ],
];

export const ABSTRACT_EMERGENCY_POPUP_TABS = [
  [
    {
      label: "משטרה וביטחון",
      value: placesColors.police.value,
      color: "#F7976B",
      backgroundColor: "#3BB6D54D",
      selectedBackgroundColor: "#3BB6D5CC",
    },
    {
      label: "כיבוי והצלה",
      value: placesColors.fire.value,
      color: "#982514",
      backgroundColor: "#3BB6D54D",
      selectedBackgroundColor: "#3BB6D5CC",
    },
    {
      label: "רפואה",
      value: placesColors.hospital.value,
      color: "#559255",
      backgroundColor: "#3BB6D54D",
      selectedBackgroundColor: "#3BB6D5CC",
    },
  ],
];

export const VT_SEARCH_LINKS = [
  {
    name: "All - כל הקטגוריות",
  },
  {
    name: "Travels - טיולים",
  },
  {
    name: "Sport - ספורט",
  },
  {
    name: "Environment - סביבה",
  },
  {
    name: "Pets - בעלי חיים",
  },
  {
    name: "Culture - תרבות",
  },
  {
    name: "Medicine - רפואה",
  },
  {
    name: "Education - חינוך",
  },
];

export const modalData = {
  enTitle: "To Volunteer",
  hebTitle: "בואו להתנדב",
  cards: [
    {
      title: "להירשם להתנדבות",
      linkAddress: "מפת התנדבויות",
      image: tv1,
      showHeart: false,
      titleColor: "#46884B",
    },
    {
      title: "הדרכה למתנדבים",
      linkAddress: "מפת התנדבויות",
      titlelink: "#",
      to: "#",
      image: beach,
      showHeart: false,
    },
    {
      title: "סיום המשימה וקבלת תגמול ",
      linkAddress: "להתנדב",
      titlelink: "#",
      to: "#",
      image: ju3,
      showHeart: false,
    },
    {
      title: "מימוש תגמולי מתנדבים",
      linkAddress: "מפת אטרקציות לבילוי",
      titlelink: "#",
      to: "#",
      image: tv1,
      showHeart: false,
    },
  ],
};

export const ZoneMapCity = {
  "6677a60e31d965b52d4e58d8": "Gderot",
  "6679510731d965b52d4f4067": "Tel Aviv",
  "667c720431d965b52d51abf7": "Haifa",
  "6640beff688afe7a27141c6b": "Gderot",
};

export const VSTZones = [
  "6677a60e31d965b52d4e58d8",
  "6679510731d965b52d4f4067",
  "667c720431d965b52d51abf7",
];

export const EMERGENCY_STATUS = {
  STARTED: "started",
  CONFIRMED: "confirmed",
};
