import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import GreenDoubleArrowIcon2 from "../../../assets/icons/GreenDoubleArrowIcon2";

const LinkWrapper = styled.div`
  font-weight: 600;
  font-family: "NotoSans-600";
  width: 100%;
  color: #316788;
  display: flex;
  flex-direction: ${(props) =>
    props.AreIconsOnTheSameLine ? "row" : "column"};
  gap: 10px;
  box-sizing: border-box;
  cursor: pointer;
`;
const TitlesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
`;
const IconAndCreateWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 25px;
`;
const Title = styled.h3`
  position: relative;
  color: #316788;
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "21px")};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  @media (max-width: 390px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: 1px;
  @media (max-width: 390px) {
    top: 0px;
  }
`;
const DArrowIconWrapper = styled.div`
  margin-top: 8px;
`;
const CreateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Create = styled(Link)`
  color: #316788;
  font-size: 13.974px;
  font-weight: 600;
  text-decoration-line: underline;
  margin-left: 15px;
`;
function CardTitle(props) {
  const {
    title,
    length,
    link,
    sideLink,
    icon,
    fontSize,
    underline = false,
  } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <LinkWrapper onClick={handleClick} to={link ? link : null}>
      <TitlesWrapper>
        <Title underline={underline || link} fontSize={fontSize}>
          <IconWrapper>{icon}</IconWrapper>
          <div>{title}</div>
        </Title>
        {link && (
          <DArrowIconWrapper>
            <GreenDoubleArrowIcon2 color={"#316788"} />
          </DArrowIconWrapper>
        )}
      </TitlesWrapper>
      {sideLink && (
        <IconAndCreateWrapper length={length}>
          <CreateWrapper>
            <Create to={sideLink.link}>{sideLink.title}</Create>
          </CreateWrapper>
        </IconAndCreateWrapper>
      )}
    </LinkWrapper>
  );
}

export default CardTitle;
