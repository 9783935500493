let CURRENT_DOMAIN;

if (process.env.NODE_ENV === "development") {
  // CURRENT_DOMAIN = "https://wallet.togathe33s.store";
  // CURRENT_DOMAIN = "http://localhost:4000";
  CURRENT_DOMAIN = "https://together-backend-aavq.onrender.com";
} else {
  // CURRENT_DOMAIN = "https://wallet.togathe33s.store";
  // CURRENT_DOMAIN = "https://wallet.togathe33s.store"; // Set the production URL
  CURRENT_DOMAIN = "https://together-backend-aavq.onrender.com";
}

export { CURRENT_DOMAIN };
