export const STEPS_VALUES = {
  finished: "finished",
  pending: "pending",
  stale: "stale",
};

export const STEPS_COLORS = {
  [STEPS_VALUES.finished]: "#337A37",
  [STEPS_VALUES.pending]: "#EF7300",
  [STEPS_VALUES.stale]: "#c4c4c4",
  
};
