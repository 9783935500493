import React from "react";

const NoteBookIcon = ({ color, width = 32, height = 33 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 32 33"
    >
      <g fill={color ?? "#EF7300"}>
        <path d="M25.012 18.381c1.24.224 2.338.708 3.234 1.561 1.904 1.813 2.561 4.03 1.791 6.538-.761 2.479-2.52 3.964-5.091 4.382-.792.129-1.615.083-2.424.084-6.105.005-12.208.004-18.312.002-1.265 0-2.188-.7-2.435-1.848a3.142 3.142 0 01-.048-.651c-.003-7.873-.003-15.747-.001-23.62 0-1.533.958-2.492 2.49-2.492h18.281c1.566 0 2.516.96 2.516 2.535V18.383v-.002zm-2.007-.007c.004-.06.01-.101.01-.142V4.788c0-.358-.1-.455-.47-.455H4.174c-.373 0-.453.083-.453.468v23.675c0 .396.079.474.478.474h14.876c.083 0 .167-.015.28-.026-3.362-3.82-1.322-9.654 3.649-10.551zm-3.31 6.237a4.304 4.304 0 004.332 4.336c2.387.001 4.303-1.927 4.306-4.331.004-2.546-2.188-4.373-4.426-4.312-2.29.062-4.209 1.965-4.211 4.307h-.001z"></path>
        <path d="M13.343 14.976c-1.965 0-3.93.003-5.895-.002-.747-.001-1.225-.61-1.025-1.283.125-.422.502-.698.994-.71.436-.01.873-.002 1.31-.002h10.478c.694 0 1.142.397 1.142 1.002 0 .555-.41.98-.985.99-.77.012-1.539.004-2.308.004h-3.711z"></path>
        <path d="M13.378 9.654c-1.975 0-3.951.002-5.926 0-.73-.002-1.21-.59-1.037-1.256.117-.446.497-.736.996-.739 1.08-.005 2.162-.002 3.243-.002h8.609c.614 0 1.052.383 1.081.931.033.619-.41 1.064-1.071 1.065-1.965.003-3.93.001-5.895.001z"></path>
        <path d="M11.72 18.315h4.302c.443 0 .753.22.925.617.164.379.11.766-.194 1.032-.202.177-.507.33-.768.33-2.857.024-5.715.02-8.571.012-.602-.002-1.051-.465-1.038-1.019.013-.566.45-.97 1.073-.972 1.424-.004 2.847-.002 4.271-.002v.002z"></path>
        <path d="M11.036 23.633h3.52c.682 0 1.147.414 1.142 1.006-.006.586-.462.99-1.125.99-2.357.002-4.715.002-7.072 0-.66 0-1.118-.406-1.124-.99-.006-.597.455-1.005 1.139-1.005h3.52z"></path>
        <path d="M23.33 24.584c.69-.695 1.324-1.338 1.963-1.978.282-.283.626-.363 1.004-.255.37.106.599.367.684.74a.95.95 0 01-.271.915c-.878.886-1.756 1.772-2.646 2.646-.433.426-1.009.41-1.447-.017-.432-.42-.858-.847-1.279-1.279-.416-.427-.427-1.022-.037-1.424.399-.41 1.009-.408 1.446.018.192.188.365.396.583.634z"></path>
      </g>
    </svg>
  );
};

export default NoteBookIcon;
