import { useQuery } from "@tanstack/react-query";
import {
  getAllEvents,
  getEventsByZone,
} from "../domain/Ecosystem/Zone/Activities/Create/api";
import { useMemo } from "react";
import SliderCommunityImage1 from "../assets/images/Activities/TogetherInTheCity/Mock/slider-community-1.png";
import { getPhotoSrc } from "../domain/Ecosystem/Post/utils";
import { TaskZone } from "./useFetchTasks";

// Mock
import g32 from "../assets/images/Activities/TogetherInTheCity/gderot/Mock/cardSlider/g32n.jpg";
import l51 from "../assets/images/Activities/TogetherInTheCity/gderot/Mock/linearSlider/l51.png";

function useFetchEvents(zone, config = {}) {
  const { isLoading: isLoadingEvents, data: events } = useQuery({
    queryKey: ["events", zone],
    queryFn: () =>
      zone === TaskZone.All ? getAllEvents() : getEventsByZone(zone),
    enabled: zone !== undefined,
    ...config,
  });

  const fetchedEvents = useMemo(() => {
    return events?.data?.events
      ?.map?.((event) => ({
        ...event,
        image: event.image ? getPhotoSrc(event.image) : SliderCommunityImage1,
        name: event.subtitle,
        title: event.subtitle,
        location: event.indoor_location,
        longitude: event.longitude,
        latitude: event.lattiude,
        date: event.start_date,
        id: event._id,
        task_type: "event",
        // Mock
        linear: [
          {
            name: event.subtitle,
            image: event.image
              ? getPhotoSrc(event.image)
              : SliderCommunityImage1,
            date: "09/01/2023",
            time: "07:30",
            id: event._id,
          },
          {
            name: event.subtitle,
            image: event.image
              ? getPhotoSrc(event.image)
              : SliderCommunityImage1,
            date: "09/01/2023",
            time: "07:30",
            id: event._id,
          },
        ],
      }))
      .reverse();
  }, [events?.data?.events]);

  return { isLoadingEvents, fetchedEvents };
}

export default useFetchEvents;
