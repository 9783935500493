import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { TOP_SIDE, BOTTOM_SIDE } from "../../constant";
import { UserContext } from "../../../../../../store/userContext";
import { Link, useLocation } from "react-router-dom";
import PWAPrompt from "react-ios-pwa-prompt";
import { hideDownloadPWA, isIos, isSafari } from "./utils";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 30px 0 0 30px;
`;
const TopSide = styled.div`
  border-radius: 30px 0px 0px 0px;
  background: #003f5d;
`;
const BottomSide = styled.div`
  border-radius: 0px 0px 0px 30px;
  background: #7fb383;
  overflow: hidden;
`;
const Text = styled.p`
  direction: rtl;
  margin: 0;
  color: ${(props) => props.color ?? "#fff"};
  text-align: center;
  font-family: Assistant;
  font-size: ${(props) => props.fontSize ?? "20px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-right: 10px;
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: ${(props) => props.top ?? "50%"};
  transform: translate(0, -50%);
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MainTap = styled.div`
  position: relative;
  padding: ${(props) => props.padding ?? "14px 0"};
`;
const SubTap = styled.div``;
const Divider = styled.hr`
  width: 90%;
  margin: 0 auto;
  height: 2px;
  border: none;
  background: ${(props) => props.color ?? "#fff"};
`;
const Message = styled(Link)`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: end;
  margin: 5px 0 10px;
  text-decoration: none;
  color: #fff;
  text-align: right;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
`;
const NavBar = () => {
  const [activeTap, setActiveTap] = useState(undefined);
  const [iosPWAVisible, setIosPWAVisible] = useState(false);
  const [iosPWAKeyOffset, setIosPWAKeyOffset] = useState(0);
  const { installPrompt } = useContext(UserContext);
  const location = useLocation();
  const currentLocation = useMemo(
    () => `${window.location.origin}${location.pathname}`,
    [location?.pathname]
  );

  const handleItemClick = (item) => {
    if (item.action) {
      if (item.id === "download_pwa") {
        if (isIos() && isSafari()) {
          setIosPWAVisible(true);
          return;
        }
        installPrompt && item.action(installPrompt);
      } else if (item.id === "share") {
        item.action(currentLocation);
      } else {
        item.action();
      }
    }

    // Add any other logic you want to perform on item click
    if (activeTap === item.id) setActiveTap(undefined);
    else setActiveTap(item.id);
  };

  return (
    <Wrapper>
      {iosPWAVisible && (
        <PWAPrompt
          debug
          key={iosPWAKeyOffset}
          onClose={() => {
            setIosPWAVisible(false);
            setIosPWAKeyOffset((o) => o + 1);
          }}
          delay={200}
          permanentlyHideOnDismiss={false}
        />
      )}
      <TopSide>
        {TOP_SIDE.map((t) => (
          <>
            {!hideDownloadPWA(t.id, installPrompt) && (
              <MainTap onClick={() => handleItemClick(t)} padding={t.padding}>
                <Text fontSize={t.fontSize}>{t.text} </Text>
                <IconWrapper top={t.top}>{t.icon}</IconWrapper>
              </MainTap>
            )}
            {(t.subTap || t.messages?.length > 0) &&
              activeTap === t.id &&
              t.messages &&
              t.messages.map((m) => (
                <SubTap>
                  <Message to={m.link}>{m.message}</Message>
                </SubTap>
              ))}
            {t.subTap && activeTap === t.id && <SubTap>{t.subTap}</SubTap>}
            {!hideDownloadPWA(t.id, installPrompt) && t.hasDivider && (
              <Divider />
            )}
          </>
        ))}
      </TopSide>
      <BottomSide>
        {BOTTOM_SIDE.map((t) => (
          <>
            <MainTap
              onClick={() => handleItemClick(t)}
              hasDivider={t?.hasDivider}
              border={"2px solid #000"}
              padding={t.padding}
            >
              {t.hebText && (
                <Text
                  color={"#fff"}
                  fontSize={t.fontSize}
                  marginRight={t.marginRight}
                >
                  {t.hebText}
                </Text>
              )}
              <Text fontSize={t.fontSize} color={t.color ?? "#003F5D"}>
                {t.text}{" "}
              </Text>
              <IconWrapper top={t.top}>{t.icon}</IconWrapper>
            </MainTap>
            {t.subTap && activeTap === t.id && <SubTap>{t.subTap}</SubTap>}
            {t.hasDivider && <Divider color="#003F5D" />}
          </>
        ))}
      </BottomSide>
    </Wrapper>
  );
};

export default NavBar;
