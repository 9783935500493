import React from "react";

function SearchIcon(props) {
  const { width = 21, height = 21, color = "#696D72" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.51562 16.4062C13.3212 16.4062 16.4062 13.3212 16.4062 9.51562C16.4062 5.71004 13.3212 2.625 9.51562 2.625C5.71004 2.625 2.625 5.71004 2.625 9.51562C2.625 13.3212 5.71004 16.4062 9.51562 16.4062Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3882 14.3887L18.3749 18.3754"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SearchIcon;
