import { useRef } from "react";
import styled from "styled-components";
import PopUpDialog from "./PopUpDialog";
import { APP_MAX_WIDTH } from "../../domain/Home/constant";
import useStopBodyScroll from "../../customHooks/useStopBodyScroll";
import useCustomAppWidth from "../../customHooks/useCustomAppWidth";

const Backdrop = styled.div`
  background-color: rgba(173, 202, 230, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: ${(props) => props.zIndex};
  padding-top: 39px;
`;

const RefDiv = styled.div`
  width: 90%;
  max-width: ${APP_MAX_WIDTH - 40}px;
  height: fit-content;
`;

const PopUpModal = ({
  visible,
  paddingPopUpDialog,
  hidePopUpfunc,
  useNormalDialog,
  children,
  background,
  backgroundPopUpDialog,
  border,
  borderRadius,
  top,
  zIndex = 999,
}) => {
  const childrenContainerRef = useRef(null);
  useCustomAppWidth();
  useStopBodyScroll(visible);

  if (!visible) {
    return null;
  }

  return (
    <Backdrop
      onClick={(evt) => {
        if (
          evt.target === childrenContainerRef.current ||
          childrenContainerRef.current.contains(evt.target)
        ) {
          return;
        }
        hidePopUpfunc?.();
      }}
      zIndex={zIndex}
    >
      <RefDiv ref={childrenContainerRef}>
        {useNormalDialog ? (
          <PopUpDialog
            background={background}
            backgroundPopUpDialog={backgroundPopUpDialog}
            border={border}
            paddingPopUpDialog={paddingPopUpDialog}
            borderRadius={borderRadius}
            top={top}
          >
            {children}
          </PopUpDialog>
        ) : (
          children
        )}
      </RefDiv>
    </Backdrop>
  );
};

export default PopUpModal;
