import { useQuery } from "@tanstack/react-query";
import { getContacts } from "../api";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";

function useContacts() {
  const { currentUser } = useCurrentUser();

  const { isError, isLoading, data } = useQuery({
    queryKey: ["contacts", currentUser?._id],
    queryFn: () => getContacts(currentUser?._id),
    retry: false,
  });

  return {
    data,
    isLoading,
    isError,
  };
}

export default useContacts;
