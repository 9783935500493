import { useQuery } from "@tanstack/react-query";
import { getEcosystems } from "../domain/Ecosystem/Zone/Activities/Create/api";
import { useMemo } from "react";

function useFetchEcosystems() {
  const { isLoading: isLoadingEcosystems, data: ecosystems } = useQuery({
    queryKey: ["ecosystems"],
    queryFn: () => getEcosystems(),
  });

  const fetchedEcosystems = useMemo(() => {
    return ecosystems?.data?.data
      ?.map?.((ecosystem) => ({
        ...ecosystem,
        location: "Tel Aviv",
        date: "09/01/2024",
        id: ecosystem.createdAt,
        link: `/activities/view/${ecosystem._id}`,
      }))
      .reverse();
  }, [ecosystems?.data]);

  const telAvivEcosystems = useMemo(
    () => fetchedEcosystems?.filter?.((es) => es.parent === "telAvivCity"),
    [fetchedEcosystems]
  );

  return {
    isLoadingEcosystems,
    fetchedEcosystems: fetchedEcosystems ?? [],
    telAvivEcosystems: telAvivEcosystems ?? [],
  };
}

export default useFetchEcosystems;
