import { useQuery } from "@tanstack/react-query";
import { fetchPostsByZoneAndTypes } from "../domain/Ecosystem/Board/api";
import { useMemo } from "react";
import { format, parseISO } from "date-fns";
import { getVideoThumbnailAndId } from "../kit/VideoPreview/utils";

function useFetchBoardsPosts(zone, types, boardData) {
  const {
    isLoading: isLoadingPosts,
    refetch: refetchPosts,
    data: fetchedPosts,
  } = useQuery({
    queryKey: [
      "boards_posts",
      zone,
      boardData ? boardData?.map((card) => card.id) : types,
    ],
    queryFn: () => fetchPostsByZoneAndTypes(zone, types),
  });

  const posts = useMemo(
    () => fetchedPosts?.data?.posts?.slice?.().reverse?.() ?? [],
    [fetchedPosts?.data?.posts]
  );

  const linearPosts = useMemo(
    () =>
      posts?.map?.((post) => {
        const { thumbnailUrl } = getVideoThumbnailAndId(post?.link);
        return {
          id: post?._id,
          title: post?.description,
          date: format(parseISO(post?.createdAt), "MM/dd/yyyy"),
          time: format(parseISO(post?.createdAt), "hh:mm"),
          image:
            post?.photos[0] && post?.photos[0] !== null
              ? post?.photos[0]
              : post?.photo
              ? post?.photo
              : thumbnailUrl,
          type: post?.type,
          //TODO: Remove 'five-min'
          link: `/activities/board/zone/${post?.zone}/section/${
            post?.section ?? "five-min"
          }/${post?.type}`,
        };
      }) ?? [],
    [posts]
  );

  const boardWithLinearPosts = useMemo(() => {
    return boardData?.map((category) => {
      const filteredPosts = linearPosts
        .filter((post) => post?.type === category?.id)
        .slice(0, 2);
      return {
        ...category,
        linear: filteredPosts,
      };
    });
  }, [boardData, linearPosts]);

  return {
    posts,
    linearPosts,
    boardWithLinearPosts,
    isLoadingPosts,
    refetchPosts,
  };
}

export default useFetchBoardsPosts;
