import { createContext, useState } from "react";
import { MarkerIcon } from "../../../kit/Map/CircleConstants";
import { STEPS_VALUES } from "../../../kit/Steps";

export const EMERGENCY_TYPES = {
  sos: "sos",
  organization: "organization",
  need_help: "need_help",
};

export const EMERGENCY_THEMES = {
  [EMERGENCY_TYPES.sos]: {
    primary: "#E81616",
    secondary: "#FFEBEB",
    tertiary: "#DF6E6E",
    mainly: "#DF6E6E",
    mapMarker: MarkerIcon.Red,
  },
  [EMERGENCY_TYPES.organization]: {
    primary: "#EF7300",
    secondary: "#FFDCBC",
    tertiary: "#F9A150",
    mainly: "#DF6E6E",
    mapMarker: MarkerIcon.Orange,
  },
  [EMERGENCY_TYPES.need_help]: {
    primary: "#0270A5",
    secondary: "#D5F1FF",
    tertiary: "#4BA7CA",
    headline: "מתנדבים וקהילה",
    mainly: "#DF6E6E",
    mapMarker: MarkerIcon.Blue,
  },
};

export const initialSteps = [
  { status: STEPS_VALUES.pending, texts: ["ממתין", "לסיוע"] },
  { status: STEPS_VALUES.stale, texts: ["סיוע", "בדרך"] },
  { status: STEPS_VALUES.stale, texts: ["טיפול", "במקרה"] },
  { status: STEPS_VALUES.stale, texts: ["סגירת", "דיווח"] },
];

export const EmergencyOverlaysContext = createContext({
  showOverlay: false,
  setShowOverlay: () => {},
  overlayProps: {},
  setOverlayProps: ({
    forceShowConfirm,
    isSOS,
    isNeedHelp,
    isOrganization,
    isReassurance,
    id,
    isBlue,
    showSteps,
  }) => {},
  userInformation: undefined,
  setUserInformation: () => {},
  showEmergencyMap: false,
  setShowEmergencyMap: () => {},
  isEditOverlay: false,
  setIsEditOverlay: () => {},
  emergencyContactPopUpData: undefined,
  setEmergencyContactPopUpData: () => {},
  canShowMapPopup: false,
  setCanShowPopupMap: () => {},
  Reassurance: false,
  setReassurance: () => {},
  hasReassuranceColumnShare: false,
  setHasReassuranceColumnShare: () => {},
  forceHideMap: false,
  setForceHideMap: () => {},
  theme: null,
  setTheme: () => {},
  steps: initialSteps,
  setSteps: () => {},
  sourcePage: false,
  setSourcePage: () => {},
});

export const EmergencyOverlaysProvider = ({ children }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [userInformation, setUserInformation] = useState(false);
  const [showEmergencyMap, setShowEmergencyMap] = useState(false);
  const [isEditOverlay, setIsEditOverlay] = useState(false);
  const [emergencyContactPopUpData, setEmergencyContactPopUpData] =
    useState(undefined);
  const [canShowMapPopup, setCanShowPopupMap] = useState(false);
  const [hasReassuranceColumnShare, setHasReassuranceColumnShare] =
    useState(false);
  const [forceHideMap, setForceHideMap] = useState(false);
  const [overlayProps, setOverlayProps] = useState({
    forceShowConfirm: undefined,
    isSOS: undefined,
    isNeedHelp: undefined,
    isOrganization: undefined,
    isReassurance: undefined,
    id: undefined,
    isBlue: undefined,
    showSteps: false,
  });
  const [Reassurance, setReassurance] = useState(!!overlayProps?.isReassurance);
  const [theme, setTheme] = useState(null);
  const [steps, setSteps] = useState(initialSteps);
  const [sourcePage, setSourcePage] = useState(null);

  return (
    <EmergencyOverlaysContext.Provider
      value={{
        showOverlay,
        setShowOverlay,
        overlayProps,
        setOverlayProps,
        userInformation,
        setUserInformation,
        showEmergencyMap,
        setShowEmergencyMap,
        isEditOverlay,
        setIsEditOverlay,
        emergencyContactPopUpData,
        setEmergencyContactPopUpData,
        canShowMapPopup,
        setCanShowPopupMap,
        Reassurance,
        setReassurance,
        hasReassuranceColumnShare,
        setHasReassuranceColumnShare,
        forceHideMap,
        setForceHideMap,
        theme,
        setTheme,
        steps,
        setSteps,
        sourcePage,
        setSourcePage,
      }}
    >
      {children}
    </EmergencyOverlaysContext.Provider>
  );
};
