import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PiCopyright } from "react-icons/pi";
import { FlexRow } from "../../../Common/Styled";

const Wrapper = styled.div`
  color: #316788;
  font-size: 16px;
  font-weight: 400;
  font-family: Assistant;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  color: #316788;
`;

const AbstractLandingFooter = () => {
  return (
    <Wrapper>
      <StyledLink to={"/info/emergency-privacy"}>
        Privacy Policy / Terms & Conditions / Cookies
      </StyledLink>
      <FlexRow alignItems={"center"} gap={"5px"}>
        All Rights Reserved <PiCopyright size={18} /> בית חולים לחיות שדמה
      </FlexRow>
    </Wrapper>
  );
};

export default AbstractLandingFooter;
