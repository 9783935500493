import styled from "styled-components";
import { TogetherIcon } from "../../../../../assets/icons/Logo";
import CoverPhoto from "../../../../Emergency/Contacts/components/CoverPhoto";
import { getPhotoSrc } from "../../../Post/utils";
import {
  ButtonEl,
  CARD_DETAILS_PAGES,
  E1BtnProps,
  renderShareBtn,
} from "./CardDetailsPage";
import { getCurrentLogo } from "./CardDetailsUtils";
import { Rating } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AddPhoto from "../../../Board/Form/components/AddPhoto";
import CardMock from "./CardMock.png";
import useCustomAppWidth from "../../../../../customHooks/useCustomAppWidth";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import { useLocation, useNavigate } from "react-router-dom";
import CardDetailsTimeReward from "./CardDetailsTimeReward";
import VolunteerPleasureMapIcon from "./VolunteerPleasureMapIcon.svg";
import { PreviousRouteContext } from "../../../../../store/perviousRouteContext";
import { EMERGENCY_DESCRIPTION, EMERGENCY_MESSAGE } from "./constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;
const ThankYouText = styled.h3`
  font-family: Assistant;
  font-size: 25px;
  font-weight: ${({ fontWeight }) => fontWeight ?? "700"};
  line-height: 38.78px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #003f5d;
  margin: 0;
  padding: 0;
`;
const TitleText1 = styled.h3`
  font-family: Assistant;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.39px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #28b5b5;
  margin: 0;
  padding: 0;
`;
const TitleText2 = styled.h4`
  font-family: Assistant;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #1e9898;
  margin: 0;
  padding: 0;
`;
const NormalText = styled.p`
  font-family: Assistant;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.93px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${(props) => props.color ?? "#696d72"};
  margin: ${(props) => props.margin ?? "0"};
  padding: 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
const TitleInnerWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`;
const EmojisRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
`;
const EmojiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: ${(props) => (props.selected ? "1px solid #1e9898" : "none")};
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? "#1e989855" : "transparent"};
  padding: 2.5px;
`;
const Emoji = styled.div`
  font-size: 40px;
`;
const FeedBackInputSection = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  background: #ecefef;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 12px;
  margin: 30px 0 10px 0;
`;
const FeedbackTextArea = styled.textarea`
  border: none;
  outline: none;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.93px;
  letter-spacing: 0.02em;
  height: 85px;
  background: white;
  width: 100%;
`;
const CameraRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;
const CardData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #316788;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  margin-top: 5px;
`;
const VolunteerPleasureMap = styled.img``;

const Emojis = [
  {
    emoji: "🥰",
    text: "מושלם",
  },
  {
    emoji: "😁",
    text: "טוב מאוד",
  },
  {
    emoji: "😐",
    text: "טוב",
  },
  {
    emoji: "☹️",
    text: "טעון שיפור",
  },
];

const BUTTON_COLOR = "#4A844E";

const CardDetailsFeedback = ({
  ecosystem,
  zone,
  cardData,
  setCurrentView,
  currentView,
  displayName,
  isShareView,
}) => {
  useCustomAppWidth();
  const navigate = useNavigate();
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { previousRoute, updatePreviousRoute } =
    useContext(PreviousRouteContext);
  const [starRating, setStarRating] = useState();
  const [selectedEmoji, setSelectedEmoji] = useState(-1);
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [showThankYou, setShowThankYou] = useState(true);
  const [showReward, setShowReward] = useState(
    previousRoute === "/activities/map/volunteering_pleasure"
  );
  const location = useLocation();

  const renderEmoji = ({ emoji, text }, index) => (
    <EmojiWrapper
      onClick={() => setSelectedEmoji(selectedEmoji === index ? -1 : index)}
      selected={selectedEmoji === index}
    >
      <Emoji>{emoji}</Emoji>
      <NormalText color={"#1E9898"}>{text}</NormalText>
    </EmojiWrapper>
  );

  const renderCover = (timeReward) => (
    <CoverPhoto
      coverWidth={"100vw"}
      multipleImages={timeReward}
      image={getPhotoSrc(cardData?.photo)}
      logo={
        timeReward ? (
          <VolunteerPleasureMap
            src={VolunteerPleasureMapIcon}
            alt={"Map"}
            onClick={() => navigate("/activities/map/volunteering_pleasure")}
          />
        ) : (
          getCurrentLogo(zone) || TogetherIcon({ width: "240", height: "60" })
        )
      }
      logoTitle={timeReward ? "" : ecosystem?.title}
      onClick={() => {
        if (showReward) {
          setShowReward(false);
          return;
        }
        if (!showThankYou) {
          setShowThankYou(true);
          return;
        }
        setCurrentView?.(CARD_DETAILS_PAGES.Level_One);
      }}
      logoRightButton={
        timeReward
          ? undefined
          : renderShareBtn(
              location,
              cardData.description,
              window.location?.href?.includes?.("/feedback/")
                ? window.location.href
                : window.location.href + `/feedback/${displayName}`
            )
      }
    />
  );

  useEffect(() => {
    if (isShareView && previousRoute) {
      updatePreviousRoute(null);
    }
  }, []);

  if (showReward) {
    return (
      <CardDetailsTimeReward
        zone={zone}
        backFunc={() => setShowReward(false)}
        CoverPhoto={renderCover(true)}
      />
    );
  }

  if (showThankYou) {
    return (
      <Wrapper>
        {renderCover()}
        <ContentWrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px 0",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ThankYouText>{displayName} תודה</ThankYouText>
              <ThankYouText>על השתתפותך</ThankYouText>
            </div>
            <CheckSvg />
          </div>
          <div style={{ padding: "10px 0" }} />
          <ThankYouText>Thanks! See you Soon</ThankYouText>
          <div style={{ padding: isShareView ? "20px 0" : "10px 0" }} />
          {isShareView && (
            <>
              <ThankYouText fontWeight="400">
                {" "}
                Time Rewards תגמולי מתנדבים
              </ThankYouText>
              <div style={{ padding: "10px 0" }} />
            </>
          )}
          <>
            <img src={CardMock} alt={"Card"} style={{ maxHeight: "130px" }} />
          </>
          {isShareView && (
            <>
              <div style={{ padding: "10px 0" }} />
              <ThankYouText fontWeight="400">
                תגמולים ייחודיים המאפשרים לבלות{" "}
              </ThankYouText>
              <ThankYouText fontWeight="400">ולהנות במגוון</ThankYouText>
              <div style={{ padding: "10px 0" }} />
              <ThankYouText fontWeight="400">
                אטרקציות והטבות נוספות
              </ThankYouText>
            </>
          )}
          <div style={{ padding: "20px 0" }} />
          {!isShareView && (
            <>
              <ButtonEl
                {...E1BtnProps}
                onClick={() => setShowReward(true)}
                type="button"
                borderColor={BUTTON_COLOR}
                color={BUTTON_COLOR}
                width={"270px"}
              >
                Reward Info - תגמולים
              </ButtonEl>
              <div style={{ height: "20px" }} />
              <ButtonEl
                {...E1BtnProps}
                onClick={() => setShowThankYou(false)}
                borderColor={BUTTON_COLOR}
                color={BUTTON_COLOR}
                width={"270px"}
              >
                Feedback - שלח משוב
              </ButtonEl>
              <div style={{ height: "20px" }} />
              <ButtonEl
                {...E1BtnProps}
                borderColor={BUTTON_COLOR}
                color={BUTTON_COLOR}
                onClick={() => {
                  const description =
                    location?.pathname?.includes?.("emergency") &&
                    !["BrothersInArms", "KaplanForce"].some((keyword) =>
                      location?.pathname?.includes?.(keyword)
                    )
                      ? EMERGENCY_DESCRIPTION
                      : cardData.description;
                  const welcomeMsg = location?.pathname?.includes?.("emergency")
                    ? EMERGENCY_MESSAGE
                    : undefined;
                  const url = window.location?.href?.includes?.("/feedback/")
                    ? window.location.href
                    : window.location.href + `/feedback/${displayName}`;
                  const shareData = {
                    title: "Together Ecosystem\nCommunity Social Network\n",
                    text: `${description}\n\n${welcomeMsg}\n\n`,
                    url,
                  };
                  navigator?.share?.(shareData);
                  setCurrentView(CARD_DETAILS_PAGES.Level_One);
                }}
                width={"270px"}
              >
                Confirm & Exit - יציאה
              </ButtonEl>
            </>
          )}
          {isShareView && (
            <ButtonEl
              {...E1BtnProps}
              onClick={() => setShowReward(true)}
              type="button"
              borderColor={BUTTON_COLOR}
              color={BUTTON_COLOR}
              width={"270px"}
            >
              Reward Info - תגמולים
            </ButtonEl>
          )}
          <div style={{ height: "20px" }} />
        </ContentWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {renderCover()}
      <ContentWrapper>
        <TitleWrapper>
          <TitleInnerWrapper>
            <TitleText1>משימה הושלמה!</TitleText1>
            <TitleText2>תודה רבה על השתתפותך </TitleText2>
          </TitleInnerWrapper>
          <div style={{ padding: "0 15px" }} />
          <CheckSvg />
        </TitleWrapper>
        <NormalText>תאר את חוות דעתך על המשימה/ הפעילות</NormalText>
        <EmojisRow>{Emojis.map(renderEmoji)}</EmojisRow>
        <NormalText>
          דרגו את מידת הביטחון והנוחות בעת ביצוע המשימה/ הפעילות
        </NormalText>
        <Rating
          name="star-rating"
          style={{ margin: "10px 0 20px 0", fontSize: "40px" }}
          value={starRating}
          onChange={(_, newValue) => {
            setStarRating(newValue);
          }}
        />
        <NormalText>רשמו לנו המלצות לשיפור להמשך הדרך</NormalText>
        <FeedBackInputSection>
          <FeedbackTextArea />
          <CameraRow>
            <div>
              <AddPhoto
                showImageIcon
                height={"40px"}
                allowMultipleSelection
                selectedFilesList={selectedFilesList}
                setSelectedFilesList={setSelectedFilesList}
              />
            </div>
            <NormalText>צרפו תמונה מהפעילות</NormalText>
          </CameraRow>
        </FeedBackInputSection>
        <ButtonsRow>
          <ButtonEl
            {...E1BtnProps}
            borderColor="#28B5B5"
            backGroundColor="#28B5B5"
            color="#fff"
            boxShadow="0px 4px 10px 0px #42848033"
            width={"302px"}
            onClick={() => setCurrentView(CARD_DETAILS_PAGES.Level_One)}
          >
            שלח - Send
          </ButtonEl>
        </ButtonsRow>
      </ContentWrapper>
    </Wrapper>
  );
};

const CheckSvg = () => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dii_1822_9225)">
      <path
        d="M14.3477 45.6523C12.2633 43.568 13.6453 39.1953 12.5805 36.6352C11.5156 34.075 7.4375 31.832 7.4375 29C7.4375 26.168 11.4703 24.0156 12.5805 21.3648C13.6906 18.7141 12.2633 14.432 14.3477 12.3477C16.432 10.2633 20.8047 11.6453 23.3648 10.5805C25.925 9.51563 28.168 5.4375 31 5.4375C33.832 5.4375 35.9844 9.47031 38.6352 10.5805C41.2859 11.6906 45.568 10.2633 47.6523 12.3477C49.7367 14.432 48.3547 18.8047 49.4195 21.3648C50.4844 23.925 54.5625 26.168 54.5625 29C54.5625 31.832 50.5297 33.9844 49.4195 36.6352C48.3094 39.2859 49.7367 43.568 47.6523 45.6523C45.568 47.7367 41.1953 46.3547 38.6352 47.4195C36.075 48.4844 33.832 52.5625 31 52.5625C28.168 52.5625 26.0156 48.5297 23.3648 47.4195C20.7141 46.3094 16.432 47.7367 14.3477 45.6523Z"
        fill="url(#paint0_linear_1822_9225)"
      />
    </g>
    <path
      d="M40.9688 23.5625L27.6695 36.25L21.0312 29.9063"
      stroke="#FCFCFC"
      stroke-width="2.26562"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <filter
        id="filter0_dii_1822_9225"
        x="0.640625"
        y="0.453125"
        width="60.7188"
        height="60.7188"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.8125" />
        <feGaussianBlur stdDeviation="3.39844" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.307034 0 0 0 0 0.679439 0 0 0 0 0.63475 0 0 0 0.42 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1822_9225"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1822_9225"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.453125" dy="-0.453125" />
        <feGaussianBlur stdDeviation="0.90625" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1822_9225"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.90625" />
        <feGaussianBlur stdDeviation="0.90625" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.333248 0 0 0 0 0.546106 0 0 0 0 0.49502 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_1822_9225"
          result="effect3_innerShadow_1822_9225"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1822_9225"
        x1="52.4348"
        y1="49.8043"
        x2="9.56522"
        y2="0.630435"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#28B5B5" />
        <stop offset="1" stop-color="#7FB383" />
      </linearGradient>
    </defs>
  </svg>
);

export default CardDetailsFeedback;
