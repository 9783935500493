import {
  CITY_ROUTES,
  ORGANIZATION_ROUTES,
} from "../../Common/Layout/Main/constants";

export const ROUTES = {
  CITY: "city",
  STUDENT: "student",
  SCHOOL: "school",
  ORGANIZATION: "organization",
  VOLUNTEER_BENEFITS: "volunteer",
  VOLUNTEER_ORGANIZATION: "volunteer-organization",
  PUBLIC_ORGANIZATION: "public-organization",
  PRIVATE_ORGANIZATION: "private-organization",
  PRIVATE_ECOSYSTEM: "private-ecosystem",
  EMERGENCY: "emergency",
  LOGINORBACK: "login-or-back",
  CUSTOM: "custom/:id",
};

export const CUSTOM_DDL = {
  [CITY_ROUTES.RAMAT_HASHARON]: [
    {
      name: "רמת-השרון",
      route: "/activities/view/659e2467e6ad34f2f223b4f4",
      value: CITY_ROUTES.RAMAT_HASHARON,
    },
    {
      name: "Vets in Clinics",
      route: "/public/activities/organization/vet",
      value: ORGANIZATION_ROUTES.VET,
    },
    {
      name: "Pets Firends",
      route: "/public/activities/organization/pets-friends",
      value: ORGANIZATION_ROUTES.Pets_Friends,
    },
  ],
};

export const NEW_LOGIN_ZONES = [
  "6677a60e31d965b52d4e58d8",
  "6679510731d965b52d4f4067",
  "667c720431d965b52d51abf7",
  "abstract_emergency",
];
