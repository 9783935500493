import React from "react";
import styled from "styled-components";
import Header from "../../../../../Common/Layout/Header";
import {
  TogetherIcon,
  TogetherInTelaviv,
} from "../../../../../../assets/icons/Logo";
import { Link } from "react-router-dom";
const Wrapper = styled.div`
  position: relative;
`;
const MessagesWrapper = styled.div`
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Message = styled.p`
  color: var(--Primary-blue, #28b5b5);
  text-align: center;
  font-family: Assistant;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  margin: 80px 0;
`;
const Button = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 50%;
  margin: auto;
  height: 45px;
  padding: 0px 21.412px;
  justify-content: center;
  align-items: center;
  gap: 3.569px;
  flex-shrink: 0;
  border-radius: 33.903px;
  border: 1px solid #65a884;
  color: #65a884;

  text-align: center;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.412px;
`;
const RegistrationSuccess = ({ hideDoneBtn }) => {
  return (
    <div>
      <Header Icon={TogetherInTelaviv} showLogoutIcon={false} />
      <MessagesWrapper>
        <Message>🎉 הרשמתך בטיפול </Message>
        <Message>! נתראה בקרוב </Message>
        {!hideDoneBtn && (
          <Button to={-1}>{`Done \u00A0 \u00A0 \u00A0  סיום`}</Button>
        )}
      </MessagesWrapper>
    </div>
  );
};

export default RegistrationSuccess;
