import { useEffect, useState } from "react";

const useHideBack = (extraCondition) => {
  const [originalDisplay, setOriginalDisplay] = useState(null);
  useEffect(() => {
    const backBtn = document.querySelector("#Go_Back_Arrow");
    if (!originalDisplay) {
      setOriginalDisplay(backBtn?.style?.display);
    }
    if (backBtn?.style) {
      if (extraCondition == null) {
        backBtn.style.display = "none";
        return;
      }
      if (!!extraCondition) {
        backBtn.style.display = "none";
      }
      if (!extraCondition) {
        backBtn.style.display = "block";
      }
    }
  }, [extraCondition, originalDisplay]);

  useEffect(() => {
    const backBtn = document.querySelector("#Go_Back_Arrow");
    if (backBtn?.style) {
      return () => (backBtn.style.display = originalDisplay);
    }
  }, []);
};

export default useHideBack;
