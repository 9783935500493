import React from "react";

const GreenHeartIcon2 = ({ width = 21, height = 18, color = "#7FB383" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        fill={color}
        d="M14.555 0c-1.856 0-3.48.798-4.492 2.146C9.05.798 7.425 0 5.57 0A5.577 5.577 0 000 5.57c0 6.29 9.325 11.38 9.722 11.59a.719.719 0 00.681 0c.397-.21 9.722-5.3 9.722-11.59A5.577 5.577 0 0014.555 0zm-4.492 15.705c-1.641-.956-8.626-5.311-8.626-10.135A4.138 4.138 0 015.57 1.437c1.748 0 3.215.931 3.828 2.426a.719.719 0 001.33 0c.612-1.497 2.08-2.425 3.827-2.425a4.138 4.138 0 014.133 4.132c0 4.817-6.987 9.178-8.625 10.135z"
      ></path>
    </svg>
  );
};

export default GreenHeartIcon2;
