export const QUERY_KEY = {
  PROFILE: "profileUser",
};

export const ROUTES = {
  PROFILE: "/",
  SETTINGS: "settings",
  PRIVACY: "privacy",
  CHANGE_PASSWORD: "change-password",
};
