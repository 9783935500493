import {
  MainEmergencyZone,
  MainOrgZone,
} from "../../../../../customHooks/useFetchEmergencyEvents";
import { TaskZone } from "../../../../../customHooks/useFetchTasks";
import { DataType } from "./CardDetailsPage";

export const getCardDetailsURLForOrg = (zone) =>
  `/activities/organization/${zone}/card-details/${DataType.Event}`;

export const ZoneRouteMap = {
  [TaskZone.GderotCity]: "/public/activities/together/gderot",
  [TaskZone.GderotSchool]: "/public/activities/school/gderot",
  [TaskZone.GderotStudent]: "/public/activities/student/gderot",
  [TaskZone.TelAvivCity]: "/public/activities/together/tel-aviv",
  [TaskZone.OrtSchool]: "/public/activities/school/",
  [TaskZone.OrtStudent]: "/public/activities/student/",
  [TaskZone.NogaCity]: "/activities/together/noga",
  [TaskZone.AlmogCity]: "",
  [TaskZone.TaglitCity]: "/public/activities/organization/taglit",
  [TaskZone.ImaotRamatAviv]:
    "/public/activities/together/tel-aviv/imaot-ramat-aviv",
  [TaskZone.ImaotYadElihao]:
    "/public/activities/together/tel-aviv/imaot-yad-elihao",

  [MainOrgZone.KKL]: "/public/activities/organization/kkl",
  [MainOrgZone.JewishAgency]: "/public/activities/organization/jewish-agency",
  [MainOrgZone.UnitedRescue]: "/public/activities/organization/united-rescue",
  [MainOrgZone.BlueWhite]: "/public/activities/organization/blue-white",
  [MainOrgZone.Vet]: "/public/activities/organization/vet",
  [MainOrgZone.BestFriends]: "/public/activities/organization/pets-friends",
  [MainOrgZone.EmergencyAndRescue]:
    "/public/activities/organization/emergency-and-rescue",
  [MainEmergencyZone.Emergency]: "/emergency",
  [MainEmergencyZone.BrothersInArms]: "/emergency/brothers-in-arms",
  [MainEmergencyZone.KaplanForce]: "/emergency/kaplan-force",
  volunteering_pleasure: "/activities/volunteering_pleasure",
};

export const getZoneUrl = (zone) => {
  if (!zone) {
    return "";
  }
  let mapZone = zone;
  if (compareZones(mapZone, MainOrgZone.KKL)) {
    mapZone = MainOrgZone.KKL;
  }
  if (compareZones(mapZone, MainOrgZone.JewishAgency)) {
    mapZone = MainOrgZone.JewishAgency;
  }
  if (compareZones(mapZone, MainOrgZone.UnitedRescue)) {
    mapZone = MainOrgZone.UnitedRescue;
  }
  if (compareZones(mapZone, MainOrgZone.BlueWhite)) {
    mapZone = MainOrgZone.BlueWhite;
  }
  if (compareZones(mapZone, MainOrgZone.Vet)) {
    mapZone = MainOrgZone.Vet;
  }
  if (compareZones(mapZone, MainOrgZone.BestFriends)) {
    mapZone = MainOrgZone.BestFriends;
  }
  if (compareZones(mapZone, MainEmergencyZone.Emergency)) {
    mapZone = MainEmergencyZone.Emergency;
  }
  if (compareZones(mapZone, MainEmergencyZone.BrothersInArms)) {
    mapZone = MainEmergencyZone.BrothersInArms;
  }
  if (compareZones(mapZone, MainEmergencyZone.KaplanForce)) {
    mapZone = MainEmergencyZone.KaplanForce;
  }
  if (compareZones(mapZone, TaskZone.ImaotRamatAviv)) {
    mapZone = TaskZone.ImaotRamatAviv;
  }
  if (compareZones(mapZone, TaskZone.ImaotYadElihao)) {
    mapZone = TaskZone.ImaotYadElihao;
  }
  if (compareZones(zone, MainOrgZone.EmergencyAndRescue)) {
    mapZone = MainOrgZone.EmergencyAndRescue;
  }
  if (compareZones(zone, "volunteering_pleasure")) {
    mapZone = "volunteering_pleasure";
  }
  return ZoneRouteMap[mapZone] ?? `/public/activities/view/${zone}`;
};

const compareZones = (zone1, zone2) =>
  zone1.toLowerCase?.().startsWith?.(zone2.toLowerCase());
