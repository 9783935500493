import * as React from "react";
const AlarmIcon = ({ color = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      fill="transparent"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.9}
      d="M3.857 7.35A4.553 4.553 0 0 1 8.435 2.79c2.507.02 4.514 2.102 4.514 4.616v.45c0 2.266.475 3.583.893 4.305a.507.507 0 0 1-.437.76H3.401a.506.506 0 0 1-.437-.76c.418-.722.893-2.039.893-4.306V7.35Z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.9}
      d="M6.377 12.922v.506a2.026 2.026 0 0 0 4.052 0v-.506"
    />
  </svg>
);
export default AlarmIcon;
