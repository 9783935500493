import { useEffect } from "react";
import { APP_MAX_WIDTH } from "../domain/Home/constant";

const useCustomAppWidth = (customWidth) => {
  useEffect(() => {
    if (document?.getElementById?.("main-app-wrapper")?.style) {
      document.getElementById("main-app-wrapper").style.maxWidth =
        customWidth ?? "100%";
    }
    return () => {
      if (document?.getElementById?.("main-app-wrapper")?.style) {
        document.getElementById(
          "main-app-wrapper"
        ).style.maxWidth = `${APP_MAX_WIDTH}px`;
      }
    };
  }, []);
};

export default useCustomAppWidth;
