import React from "react";

const EmojiIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_431_1375)">
        <path
          d="M7.42647 14.0001H6.6042C6.56887 13.9924 6.53393 13.9799 6.4986 13.9776C5.40107 13.9003 4.37498 13.5867 3.43779 13.014C1.61931 11.9029 0.502759 10.2987 0.109481 8.20228C0.0601758 7.93983 0.0357173 7.67273 0 7.40757C0 7.13386 0 6.86055 0 6.58685C0.0112587 6.48824 0.0225174 6.39001 0.0337761 6.2914C0.217409 4.66976 0.858767 3.2566 2.01414 2.09928C3.58687 0.52423 5.5024 -0.16488 7.7192 0.0335059C9.11334 0.158128 10.3607 0.683793 11.4423 1.57362C12.7825 2.67581 13.6153 4.08237 13.922 5.79214C13.9693 6.05536 13.9953 6.32207 14.0311 6.58723V7.40796C14.0198 7.50657 14.0085 7.60479 13.9973 7.7034C13.8296 9.21012 13.2527 10.5394 12.2351 11.6665C11.155 12.863 9.81604 13.6099 8.22351 13.8914C7.95951 13.938 7.69202 13.9644 7.42608 14.0005L7.42647 14.0001ZM7.01728 0.771921C3.58376 0.767651 0.767921 3.57961 0.778792 7.00109C0.79005 10.4373 3.57095 13.2124 7.01223 13.2206C10.4391 13.2291 13.2282 10.4494 13.2488 7.00575C13.269 3.58815 10.4578 0.775804 7.01689 0.771533L7.01728 0.771921Z"
          fill="#38ABC8"
        />
        <path
          d="M2.83337 8.89905H11.1873C10.709 10.1162 9.29043 11.4497 7.385 11.593C5.18567 11.7584 3.4891 10.4135 2.83337 8.89905Z"
          fill="#38ABC8"
        />
        <path
          d="M4.56524 6.87084C4.05005 6.87356 3.61912 6.44612 3.62067 5.93404C3.62222 5.42468 4.04268 5.00345 4.55398 4.99918C5.07188 4.99491 5.49932 5.41264 5.50514 5.92977C5.51097 6.44068 5.0843 6.86812 4.56485 6.87084H4.56524Z"
          fill="#38ABC8"
        />
        <path
          d="M9.47016 6.87086C8.95575 6.87358 8.52598 6.4473 8.52637 5.93445C8.52637 5.42548 8.94566 5.00463 9.45735 4.9992C9.97408 4.99376 10.4004 5.41033 10.4081 5.92862C10.4159 6.43682 9.98612 6.86853 9.47016 6.87086Z"
          fill="#38ABC8"
        />
      </g>
      <defs>
        <clipPath id="clip0_431_1375">
          <rect width="14.0311" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmojiIcon;
