import React from "react";

const LikedGreenHeartIcon2 = ({
  width = 23,
  height = 23,
  color = "#7FB383",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 23 23"
    >
      <g>
        <path
          fill={color}
          d="M11.293 19c.494.005.9-.11 1.274-.335.57-.342 1.112-.724 1.65-1.113 1.412-1.017 2.758-2.107 3.951-3.381.881-.94 1.669-1.941 2.223-3.112.595-1.255.778-2.558.442-3.917-.214-.864-.594-1.638-1.19-2.311a4.79 4.79 0 00-1.593-1.204 5.116 5.116 0 00-2.614-.472 5.224 5.224 0 00-1.836.488c-.852.408-1.543 1.022-2.127 1.755-.112.14-.156.084-.22 0a5.809 5.809 0 00-2.239-1.802 5.069 5.069 0 00-3.162-.37c-1.164.23-2.132.833-2.892 1.758-1.023 1.243-1.347 2.692-1.187 4.25.095.917.479 1.756.951 2.548.588.983 1.314 1.856 2.112 2.674.64.655 1.318 1.269 2.027 1.844a34.802 34.802 0 003.196 2.306c.394.251.815.404 1.233.393h.001z"
        ></path>
      </g>
    </svg>
  );
};

export default LikedGreenHeartIcon2;
