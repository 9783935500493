import { useQuery } from "@tanstack/react-query";
import {
  getAllTasks,
  getTasksByZone,
} from "../domain/Ecosystem/Zone/Activities/Create/api";
import { useMemo } from "react";
import SliderCommunityImage1 from "../assets/images/Activities/TogetherInTheCity/Mock/slider-community-1.png";
import { getPhotoSrc } from "../domain/Ecosystem/Post/utils";

// Mock
import g32 from "../assets/images/Activities/TogetherInTheCity/gderot/Mock/cardSlider/g32n.jpg";
import l51 from "../assets/images/Activities/TogetherInTheCity/gderot/Mock/linearSlider/l51.png";

export const TaskZone = {
  GderotCity: "gderotCity",
  ScoutsCity: "ScoutsCity",
  GderotSchool: "gderotSchool",
  GderotStudent: "gderotStudent",
  TelAvivCity: "telAvivCity",
  OrtSchool: "ortSchool",
  OrtStudent: "ortStudent",
  NogaCity: "NogaCity",
  AlmogCity: "AlmogCity",
  TaglitCity: "TaglitCity",
  ImaotRamatAviv: "ImaotRamatAviv",
  ImaotYadElihao: "ImaotYadElihao",
  Vet: "vet",
  BestFriends: "BestFriends",
  EmergencyAndRescue: "EmergencyAndRescue",
  All: "All",

  // For testing only
  EmergencyTest: "EmergencyTest",
};

export const ImaotRamatAvivEvents = {
  ImaotRamatAviv1: "ImaotRamatAviv1",
  ImaotRamatAviv2: "ImaotRamatAviv2",
};

export const ImaotYadElihaoEvents = {
  ImaotYadElihao1: "ImaotYadElihao1",
  ImaotYadElihao2: "ImaotYadElihao2",
};

export const EmergencyZone = {
  Emergency1: "Emergency1",
  Emergency2: "Emergency2",
  Emergency3: "Emergency3",
  Emergency4: "Emergency4",
  Emergency5: "Emergency5",
  Emergency5C: "Emergency5C",
  Emergency6: "Emergency6",
  Emergency7: "Emergency7",
  BrothersInArms1: "BrothersInArms1",
  BrothersInArms2: "BrothersInArms2",
  BrothersInArms3: "BrothersInArms3",
  BrothersInArms4: "BrothersInArms4",
  BrothersInArms5: "BrothersInArms5",
  BrothersInArms5C: "BrothersInArms5C",
  BrothersInArms6: "BrothersInArms6",
  BrothersInArms7: "BrothersInArms7",
  BrothersInArms8: "BrothersInArms8",
  KaplanForce1: "KaplanForce1",
  KaplanForce2: "KaplanForce2",
  KaplanForce3: "KaplanForce3",
  KaplanForce4: "KaplanForce4",
  KaplanForce5: "KaplanForce5",
  KaplanForce5C: "KaplanForce5C",
  KaplanForce6: "KaplanForce6",
  KaplanForce7: "KaplanForce7",
};

export const OrgZone = {
  UnitedRescue1: "UnitedRescue1",
  UnitedRescue2: "UnitedRescue2",
  UnitedRescue3: "UnitedRescue3",
  UnitedRescue4: "UnitedRescue4",
  UnitedRescue5: "UnitedRescue5",
  UnitedRescue5C: "UnitedRescue5C",
  UnitedRescue6: "UnitedRescue6",
  UnitedRescue7: "UnitedRescue7",
  UnitedRescue8: "UnitedRescue8",
  UnitedRescue9: "UnitedRescue9",
  UnitedRescue10: "UnitedRescue10",
  JewishAgency1: "JewishAgency1",
  JewishAgency2: "JewishAgency2",
  JewishAgency3: "JewishAgency3",
  JewishAgency4: "JewishAgency4",
  JewishAgency5: "JewishAgency5",
  JewishAgency5C: "JewishAgency5C",
  JewishAgency6: "JewishAgency6",
  JewishAgency7: "JewishAgency7",
  JewishAgency8: "JewishAgency8",
  JewishAgency9: "JewishAgency9",
  JewishAgency10: "JewishAgency10",
  JewishAgency11: "JewishAgency11",
  JewishAgency12: "JewishAgency12",
  KKL1: "KKL1",
  KKL2: "KKL2",
  KKL3: "KKL3",
  KKL4: "KKL4",
  KKL5: "KKL5",
  KKL5C: "KKL5C",
  KKL6: "KKL6",
  KKL7: "KKL7",
  KKL8: "KKL8",
  KKL9: "KKL9",
  KKL10: "KKL10",
  BlueWhite1: "BlueWhite1",
  BlueWhite2: "BlueWhite2",
  BlueWhite3: "BlueWhite3",
  BlueWhite4: "BlueWhite4",
  BlueWhite5: "BlueWhite5",
  BlueWhite6: "BlueWhite6",
  BlueWhite7: "BlueWhite7",
  Vet1: "Vet1",
  Vet2: "Vet2",
  Vet3: "Vet3",
  PetsFiends1: "BetsFriends1",
  ByBestFriends1: "ByBestFriends1",
};
export const TelAvivZone = {
  ImaotRamatAviv: "ImaotRamatAviv",
  ImaotYadElihao: "ImaotYadElihao",
};

function useFetchTasks(zone) {
  const { isLoading: isLoadingTasks, data: tasks } = useQuery({
    queryKey: ["tasks", zone],
    queryFn: () =>
      zone === TaskZone.All ? getAllTasks() : getTasksByZone(zone),
    enabled: zone !== undefined,
  });

  const fetchedTasks = useMemo(() => {
    return tasks?.data?.tasks
      ?.map?.((task) => ({
        ...task,
        image: task.image ? getPhotoSrc(task.image) : SliderCommunityImage1,
        name: task.subtitle,
        title: task.subtitle,
        location: task.indoor_location,
        date: task.start_date,
        id: task._id,
        longitude: task.longitude,
        latitude: task.lattiude,
        task_type: "task",
        // Mock
        linear: [
          {
            name: task.subtitle,
            image: task.image ? getPhotoSrc(task.image) : SliderCommunityImage1,
            date: "09/01/2023",
            time: "07:30",
            id: task._id,
          },
          {
            name: task.subtitle,
            image: task.image ? getPhotoSrc(task.image) : SliderCommunityImage1,
            date: "09/01/2023",
            time: "07:30",
            id: task._id,
          },
        ],
      }))
      .reverse();
  }, [tasks?.data?.tasks]);

  return { isLoadingTasks, fetchedTasks };
}

export default useFetchTasks;
