import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PlusIcon from "../../../../../assets/icons/Activities/CommunityBoard/PlusIcon";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../../../../Common/Layout/Header/constant";
import CameraIcon from "./cameraIcon.png";
import FormPreviewImages from "./FormPreviewImages";

const Wrapper = styled.div`
  position: relative;
  border-radius: ${(props) => props.borderRadius ?? "17.5px"};
  border: ${(props) => props.border ?? "none"};
  background: ${(props) => props.color ?? "rgba(101, 201, 101, 0.15)"};
  width: 100%;
  height: ${(props) => props.height ?? "auto"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection ?? "column"};
  gap: ${(props) => props.gap ?? "0"};
  cursor: pointer;
`;

const Info = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.4px;
`;

const Text = styled.div`
  color: ${(props) => props.color ?? "#1b5c6d"};
  text-decoration: ${(props) => props.textDecoration ?? "none"};
  font-size: 14px;
  font-weight: 700;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

function AddPhoto({
  color,
  height,
  border,
  register,
  placeholderLeft,
  placeholderTop,
  placeholderColor,
  placeholderTextDecoration,
  rightPlus,
  allowMultipleSelection,
  selectedFilesList,
  setSelectedFilesList,
  borderRadius,
  showImageIcon,
  hidePreview,
}) {
  const [selectedImages, setSelectedImages] = useState([]);
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    setSelectedImages(
      selectedFilesList?.map?.((f) =>
        typeof f === "string" ? f : URL.createObjectURL(f)
      )
    );
  }, [selectedFilesList]);

  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const imageUrls = selectedFiles.map((file) => URL.createObjectURL(file));

    if (allowMultipleSelection) {
      setSelectedFilesList([...(selectedFilesList ?? []), ...selectedFiles]);
      setSelectedImages([...selectedImages, ...imageUrls]);
    } else {
      if (selectedFiles?.length > 0) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(selectedFiles[0]);
        setSelectedImages([imageUrls[0]]);
      }
    }
  };

  return (
    <Wrapper
      borderRadius={showImageIcon ? "0" : borderRadius}
      color={showImageIcon ? "transparent" : color}
      height={height}
      border={border}
      flexDirection={showImageIcon ? "row-reverse" : "column"}
      gap={showImageIcon ? "10px" : "0"}
    >
      <Info left={placeholderLeft} top={placeholderTop}>
        {!rightPlus && !showImageIcon && <PlusIcon />}
        {showImageIcon ? (
          <img style={{ height: height }} src={CameraIcon} alt={"Select"} />
        ) : (
          <Text
            color={placeholderColor}
            textDecoration={placeholderTextDecoration}
          >
            {currentLanguage === LANGUAGES.HEBREW.value
              ? "הוספת תמונה"
              : "Add photo"}
          </Text>
        )}
        {rightPlus && !showImageIcon && <PlusIcon color={"#1B5C6D"} />}
      </Info>
      <StyledInput
        type="file"
        accept="image/*"
        {...(register ? register : {})}
        onInput={handleImageChange}
        multiple={allowMultipleSelection}
      />
      {!hidePreview && selectedImages?.length > 0 && (
        <FormPreviewImages
          height={height}
          allowMultipleSelection={allowMultipleSelection}
          selectedFilesList={selectedFilesList}
          setSelectedFilesList={setSelectedFilesList}
          showImageIcon={showImageIcon}
          hidePreview={hidePreview}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
        />
      )}
    </Wrapper>
  );
}

export default AddPhoto;
