import React from "react";

const GrayDoubleArrowIcon = ({ width, height, color = "#58656D" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "9"}
      height={height ?? "11"}
      fill="none"
      viewBox="0 0 10 12"
    >
      <path
        fill={color}
        d="M.908 11.2l3.148-5.6L.908 0h1.543l3.148 5.6-3.148 5.6H.908zm3.746 0l3.148-5.6L4.654 0h1.543l3.148 5.6-3.148 5.6H4.654z"
      ></path>
    </svg>
  );
};

export default GrayDoubleArrowIcon;
