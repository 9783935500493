import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled(Link)`
  width: 94%;
  margin: 20px auto;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
  text-decoration: none;
`;
const ImageWrapper = styled.div`
  width: 55px;
  height: 25px;
  border-radius: 5px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;
const Text = styled.div`
  margin: 0;
  width: ${(props) => props.width ?? "70%"};
  display: flex;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #58656d;
`;
const Letter = styled.p`
  margin: 0;
  color: ${(props) => props.color};
`;
const Item = ({ image, text, date, searchItem, link }) => {
  const HighlightedText = text.split("");
  const startIndex = text.indexOf(searchItem);
  const endIndex = searchItem.length + startIndex;
  return (
    <Wrapper to={link}>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
      <Text width={"50%"}>
        {HighlightedText.map((l, index) => (
          <Letter
            color={
              index >= startIndex && index < endIndex
                ? "#7FB383"
                : "var(--preview-txt, #58656d)"
            }
          >
            {l === " " ? "\u00A0" : l}
          </Letter>
        ))}
      </Text>
      <Text width={"30%"}>{date}</Text>
    </Wrapper>
  );
};

export default Item;
