import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: 226px;
    object-fit: cover;
    border-radius: ${(props) => props.borderRadius ?? "20px 20px 0px 0px"};
    @media (min-width: 768px) {
      height: 449px;
    }
  }
`;
const OverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  transition: transform 0.5s ease;
  left: 50%;
  top: 20px;
  transform: translate(-50%, -50%);
  border-radius: ${(props) => props.borderRadius ?? "20px 20px 0px 0px"};
  background: rgba(255, 255, 255, 0.7);
  color: #316788;
  font-family: "Assistant";
  font-size: 15px;
  font-size: 18px;
  font-weight: 800;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function CoverImage({ image, text, borderRadius }) {
  return (
    <Wrapper borderRadius={borderRadius}>
      <img src={image} alt={image} />
      {text && (
        <OverLay borderRadius={borderRadius}>
          <p>{text}</p>
        </OverLay>
      )}
    </Wrapper>
  );
}

export default CoverImage;
