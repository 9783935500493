import React, { useEffect, useMemo, useState, useTransition } from "react";
import BoardContent from "./BoardContent";
import LoadingSpinner from "../../Common/Loading/Loading";
import useFetchPosts from "../../../customHooks/useFetchPosts";
import { useParams } from "react-router-dom";
import { TaskZone } from "../../../customHooks/useFetchTasks";
import useFetchPostItems from "../../../customHooks/useFetchPostItems";
import { PostType } from "./api";
import { FormProvider, useForm } from "react-hook-form";
import useCustomAppWidth from "../../../customHooks/useCustomAppWidth";
import { getDefaultCategoryByZone } from "./constant";

export const CommunitySections = {
  CommunityBoard: "CommunityBoard",
  GiveReceive: "GiveReceive",
  Networks: "Networks",
  FiveMin: "five-min",
  DailyTip: "daily-tip",
  HelpfulTipsAndAdvice: "helpful-tips-and-advice",
  EmergencyAndRescueTips: "emergency-and-rescue-tips",
};

function CommunityBoardPageWrapper(props) {
  const [posts, setPosts] = useState([]);
  const [fitleredPosts, setFilteredPosts] = useState([]);
  const [networkPosts, setNetworkPosts] = useState([]);
  const [isPending, startTransition] = useTransition();

  const { zone, section, postId } = useParams();
  useCustomAppWidth();

  const methods = useForm({
    defaultValues: {
      category: getDefaultCategoryByZone(zone),
    },
  });

  const { watch } = methods;

  const category = watch("category");

  const isCustom = useMemo(() => section === "custom", [section]);

  const shoudSendPostId = useMemo(() => {
    const sectionsToSendId = [
      CommunitySections.FiveMin,
      CommunitySections.DailyTip,
      CommunitySections.AnimalAssociations,
      CommunitySections.HelpfulTipsAndAdvice,
      isCustom,
    ];

    return sectionsToSendId.includes(section) || isCustom;
  }, [section, isCustom]);

  const { isLoadingPosts, refetchPosts } = useFetchPosts(
    zone ?? TaskZone.OrtStudent, // TODO: Remove fallback value
    (posts) => {
      setPosts(posts ?? []);
    },
    shoudSendPostId && postId
  );

  const {
    isLoadingPosts: isLoadingNetworkPosts,
    refetchPosts: refetchNetworkPosts,
  } = useFetchPosts(
    zone ?? TaskZone.OrtStudent, // TODO: Remove fallback value
    (posts) => {
      setNetworkPosts(posts ?? []);
    },
    PostType.Network
  );

  const { isLoadingPostItems, cardPostItems, refetchPostItems } =
    useFetchPostItems(
      zone ?? TaskZone.OrtStudent // TODO: Remove fallback value
    );

  useEffect(() => {
    startTransition(() => {
      if (category === "all") {
        setFilteredPosts(posts ?? []);
      } else {
        const filtered = posts.filter?.((post) => post.category === category);
        setFilteredPosts(filtered ?? []);
      }
    });
  }, [category, posts]);

  if (isLoadingPosts || isLoadingPostItems || isLoadingNetworkPosts) {
    return <LoadingSpinner />;
  }
  return (
    <FormProvider {...methods}>
      <BoardContent
        posts={fitleredPosts}
        setPosts={setPosts}
        postItems={cardPostItems}
        refetchPostItems={refetchPostItems}
        refetchPosts={refetchPosts}
        networkPosts={networkPosts}
        setNetworkPosts={setNetworkPosts}
        refetchNetworkPosts={refetchNetworkPosts}
        zone={zone}
        postId={postId}
        section={section}
        isCustom={isCustom}
        isPending={isPending}
      />
    </FormProvider>
  );
}

export default CommunityBoardPageWrapper;
