import React from "react";

function SettingsIcon({ color = "#1E9898", size = 39 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 39 39"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19.5 26.813a7.312 7.312 0 1 0 0-14.625 7.312 7.312 0 0 0 0 14.624Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M27.985 9.917c.386.356.752.722 1.097 1.097l4.159.595a15.719 15.719 0 0 1 1.554 3.747l-2.53 3.367s.047 1.036 0 1.554l2.53 3.367a15.57 15.57 0 0 1-1.554 3.747l-4.16.594s-.715.747-1.096 1.097l-.594 4.16a15.717 15.717 0 0 1-3.748 1.553l-3.367-2.529a8.823 8.823 0 0 1-1.553 0l-3.367 2.53a15.57 15.57 0 0 1-3.748-1.555l-.594-4.159a27.884 27.884 0 0 1-1.097-1.097l-4.159-.594a15.724 15.724 0 0 1-1.554-3.747l2.529-3.367s-.046-1.036 0-1.554l-2.529-3.367a15.569 15.569 0 0 1 1.554-3.748l4.159-.594c.356-.375.721-.741 1.097-1.097l.594-4.159a15.722 15.722 0 0 1 3.748-1.553l3.367 2.529a8.82 8.82 0 0 1 1.553 0l3.367-2.53a15.57 15.57 0 0 1 3.748 1.554l.594 4.16Z"
      />
    </svg>
  );
}

export default SettingsIcon;
