import { useContext, useEffect } from "react";
import { ZoneRouteContext } from "../../store/zoneRouteContext";
import { useNavigate } from "react-router-dom";

const LoginAccessText = ({ afterLoginRoute, viewText, noLogin }) => {
  const navigate = useNavigate();
  const { setLoginRoute } = useContext(ZoneRouteContext);

  useEffect(() => {
    if (!noLogin) {
      setLoginRoute(afterLoginRoute);
      navigate("/login", { replace: true });
    }
  }, [noLogin, setLoginRoute, afterLoginRoute, navigate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: noLogin ? "#D04343" : "#555",
        height: "100vh",
      }}
    >
      {/* {noLogin ? ( */}
      <>You don't have access to view this {viewText ?? "board"}.</>
      {/* ) : (
        <>
          Please&nbsp;
          <span
            style={{ color: "#DD4343", cursor: "pointer" }}
            onClick={() => {
              setLoginRoute(afterLoginRoute);
              navigate("/login");
            }}
          >
            login
          </span>
          &nbsp;to view this {viewText ?? "board"}.
        </>
      )} */}
    </div>
  );
};

export default LoginAccessText;
