import { useState } from "react";
import styled from "styled-components";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowsRow = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageNumber = styled.div`
  color: ${({ color }) => color ?? "#000"};
`;

const PaginationContainer = ({ elements, pageLimit = 5, color }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const isBackEnabled = () => currentPage !== 0;
  const isForwardEnabled = () =>
    currentPage < Math.floor(elements?.length / pageLimit);

  return (
    <Wrapper>
      {elements
        ?.slice?.(currentPage * pageLimit, currentPage * pageLimit + pageLimit)
        ?.map?.((elm, index) => (
          <div key={index}>{elm}</div>
        ))}
      <ArrowsRow>
        <HiChevronLeft
          style={{ cursor: "pointer" }}
          size={"2em"}
          color={isBackEnabled() ? color : "lightgray"}
          onClick={() => {
            if (!isBackEnabled()) return;
            setCurrentPage((p) => p - 1);
          }}
        />
        <PageNumber color={color}>{currentPage + 1}</PageNumber>
        <HiChevronRight
          style={{ cursor: "pointer" }}
          size={"2em"}
          color={isForwardEnabled() ? color : "lightgray"}
          onClick={() => {
            if (!isForwardEnabled()) return;
            setCurrentPage((p) => p + 1);
          }}
        />
      </ArrowsRow>
    </Wrapper>
  );
};

export default PaginationContainer;
