import React, { useState } from "react";
import styled from "styled-components";
import BlueCheck from "../../assets/icons/BlueCheck";

const DEFAULT_IMG_RADIUS = 25;

const PersonaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${(props) => props.width}px;
  box-sizing: border-box;
  cursor: pointer;
`;

const PersonaImageWrapper = styled.div`
  position: relative;
`;

const PersonaImage = styled.img`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  border-radius: ${(props) =>
    props?.width ? props.width / 2 : DEFAULT_IMG_RADIUS}px;
  object-fit: cover;
  flex: 2;
`;

const PersonaName = styled.h3`
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  flex: 1;
  margin-top: 4px;
  max-height: 30px;
  text-overflow: ellipsis;
  max-width: ${(props) => props.width}px;
  overflow: hidden;
`;

const CheckWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Persona = (props) => {
  const {
    imageSrc,
    imgRadius = DEFAULT_IMG_RADIUS,
    name,
    allowSelection,
    onSelectionChange, // (selected: boolean) => void
  } = props;

  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    if (!allowSelection) return;
    setChecked(!checked);
    onSelectionChange && onSelectionChange(!checked);
  };

  return (
    <PersonaWrapper width={imgRadius * 2} onClick={handleClick}>
      <PersonaImageWrapper src={imageSrc} width={imgRadius * 2}>
        <PersonaImage src={imageSrc} width={imgRadius * 2} />
        {checked && (
          <CheckWrapper>
            <BlueCheck />
          </CheckWrapper>
        )}
      </PersonaImageWrapper>
      <PersonaName width={imgRadius * 2}>{name}</PersonaName>
    </PersonaWrapper>
  );
};

export default Persona;
