import React from "react";
import styled from "styled-components";
import YellowStarIcon from "../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/YellowStarIcon";
import LikedGreenHeartIcon2 from "../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/LikedGreenHeartIcon2";
import GreenHeartIcon2 from "../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/GreenHeartIcon2";
import { Link } from "react-router-dom";
import GrayDoubleArrowIcon from "../../../../../../../assets/icons/GrayDoubleArrowIcon";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10.717px;
  background: #fff;
  color: #58656d;
  box-shadow: 4px 4px 10px 0px #e8e8e8;
  margin: 11px 0;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
`;
const ImageWrapper = styled.div`
  height: 100%;
  width: 40%;
  flex-shrink: 0;
  padding: 0px;
  margin: 0;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
`;
const ContentAndImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;
const TextWrapper = styled.div`
  width: calc(60% - 10px);
  margin: 0 5px;
  direction: ${(props) => (props.lang === "heb" ? "rtl" : "ltr")};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TitleWrapper = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const OhterInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;
`;
const Text = styled.p`
  color: var(--preview-txt, #58656d);
  font-family: Assistant;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 9px 0;
`;
const Date = styled.p`
  color: var(--preview-txt, #58656d);
  font-family: Assistant;
  width: 35%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;
const Time = styled.p`
  color: var(--preview-txt, #58656d);
  font-family: Assistant;
  width: 25%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;
const SignupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;
const LinkTo = styled(Link)`
  color: var(--preview-txt, #69a16d);
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GreenHeartIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  margin: 0 10px;
`;
const Card = ({ image, title, liked, linkAddress = "Sign up", to = "" }) => {
  return (
    <Wrapper>
      <ContentAndImageWrapper>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
        <TextWrapper>
          <TitleWrapper>
            <Text>{title}</Text>
          </TitleWrapper>
          <OhterInfoWrapper>
            <GreenHeartIconWrapper>
              {liked ? <LikedGreenHeartIcon2 /> : <GreenHeartIcon2 />}
            </GreenHeartIconWrapper>
            <SignupWrapper>
              <LinkTo to={to}> {linkAddress}</LinkTo>
              <GrayDoubleArrowIcon color={"#69a16d"} />
            </SignupWrapper>
          </OhterInfoWrapper>
        </TextWrapper>
      </ContentAndImageWrapper>
    </Wrapper>
  );
};

export default Card;
