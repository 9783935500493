import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import SearchInput from "../../component/SearchInput";
import SearchIcon from "../../../../../assets/icons/SearchIcon";
import GoogleMapIcon from "../../../../../assets/icons/Activities/TogetherInTheCity/GoogleMapIcon";
import { TMapsIcon } from "../../../../../assets/icons/Activities";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../../../../Common/Layout/Header/constant";
import { ROUTES } from "../../../../Common/Layout/Main/constants";
import { ROUTES as ROUTES2 } from "../../../Public/constant";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import { TaskZone } from "../../../../../customHooks/useFetchTasks";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
  margin: auto;
  padding: ${({ padding }) => padding ?? "24px 12px)"};
  box-sizing: border-box;
  background: ${({ isWideScreen, backgroundColor }) =>
    isWideScreen ? "none" : backgroundColor ?? "rgba(127, 179, 131, 0.04)"};
  box-shadow: ${({ isWideScreen, boxShadow }) =>
    isWideScreen
      ? "none"
      : boxShadow
      ? boxShadow
      : "2px 2px 5px 1px rgba(0, 0, 0, 0.15)"};
`;
const SearchPartEl = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin: auto;
  padding: 13px 0;
`;
const Title = styled.div`
  color: #316788;
  text-align: center;
  font-family: Assistant;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.66px;
`;
const SearchWrapper = styled.div`
  flex-grow: 2;
  width: ${(props) => props.width ?? "auto"};
`;
const MapsWrapper = styled.div`
  border-radius: ${(props) => props.borderRadius ?? "20px"};
  border: ${(props) => (props.border ? props.border : "2px solid #c9d7e0")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
  background: ${(props) => props.background ?? "#fff"};
  max-height: 311px;
  width: ${(props) => props.width ?? "auto"};
  position: relative;
`;
const MapLeft = styled.div`
  position: absolute;
  top: 10px;
  left: 7px;
`;
const MapRight = styled.div`
  position: absolute;
  top: 8px;
  right: 7px;
  display: flex;
  align-items: center;
  gap: 3px;
`;
const MapText = styled.div`
  font-size: ${(props) => props.fontSize ?? "19px"};
  font-weight: 600;
  font-family: Assistant;
  color: #316788;
`;

const SearchPart = ({
  title,
  zone = TaskZone.TelAvivCity,
  tasks = [],
  link,
  mapIcon,
  languageProp,
  boxShadow,
  hasText = false,
  mapRoute,
  searchRoute,
  backgroundColor,
  padding,
  isVT,
  hideMapBtn,
}) => {
  const { isFullHD, isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const [searchItem, setSearchItem] = useState("");
  const { currentLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const language = useMemo(
    () => languageProp ?? currentLanguage,
    [currentLanguage, languageProp]
  );
  const filteredTasks = useMemo(
    () =>
      tasks.length > 0
        ? tasks.filter(
            (t) =>
              t.title &&
              t.title.toLowerCase().includes(searchItem?.toLowerCase())
          )
        : [],
    [tasks, searchItem]
  );
  const handleChange = (e) => {
    setSearchItem(e.target.value);
  };

  return (
    <Wrapper
      boxShadow={boxShadow}
      backgroundColor={backgroundColor}
      padding={padding}
    >
      {title && <Title>{title}</Title>}
      <SearchPartEl isWideScreen={isWideScreen || isMediumScreen}>
        <SearchWrapper
          width={
            searchItem
              ? "100%"
              : isFullHD
              ? "374.45px"
              : isWideScreen
              ? "318px"
              : "178px"
          }
          onClick={() =>
            navigate(searchRoute ?? `/${ROUTES.SEARCH}/zone/${zone}/type/all`)
          }
        >
          <SearchInput
            placeholder={
              isVT
                ? "Search"
                : language === LANGUAGES.HEBREW.value
                ? "חיפוש"
                : "Search"
            }
            placeHolderColor={isVT ? "#316788" : "#7FB383"}
            placeHolderPadding={"0 14px"}
            height={"41px"}
            borderRadius={isVT ? "10px" : "20px"}
            iconWidth={"20.435px"}
            items={searchItem === "" ? [] : filteredTasks}
            name="searchItem"
            lang={
              isVT ? "en" : language === LANGUAGES.HEBREW.value ? "heb" : "en"
            }
            searchItem={searchItem}
            onChange={handleChange}
            link={link}
            icon={<SearchIcon color="#316788" />}
          ></SearchInput>
        </SearchWrapper>
        {!searchItem && !hideMapBtn && (
          <MapsWrapper
            width={isFullHD ? "343.25px" : isWideScreen ? "291px" : "140px"}
            onClick={() =>
              navigate(
                mapRoute ?? isVT
                  ? `/${ROUTES.PRIVATE_ZONE}/${ROUTES2.MAP}/volunteering_pleasure/${zone}`
                  : `/${ROUTES.PUBLIC_ZONE}/${ROUTES2.MAP}/${zone}`
              )
            }
          >
            <MapLeft>
              <TMapsIcon />
            </MapLeft>
            <MapRight>
              <GoogleMapIcon width="27" height="27" />
              <MapText>
                {mapIcon
                  ? ""
                  : language === LANGUAGES.HEBREW.value
                  ? ``
                  : hasText
                  ? "מפות"
                  : ""}
              </MapText>
            </MapRight>
          </MapsWrapper>
        )}
      </SearchPartEl>
    </Wrapper>
  );
};

export default SearchPart;
