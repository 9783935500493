export const ROUTES = {
  Taglit: "taglit",
  UnitedRescue: "united-rescue",
  JewishAgency: "jewish-agency",
  KKL: "kkl",
  BlueWhite: "blue-white",
  Brothers_In_Arms: "brothers-in-arms",
  Kaplan_Force: "kaplan-force",
  Noga: "noga",
  VET: "vet",
  Pets_Friends: "pets-friends",
  EmergencyAndRescue: "emergency-and-rescue",
};

export const PRIVATE_ORGANIZATION = [
  {
    name: "Taglit - תגלית",
    route: `/activities/organization/${ROUTES.Taglit}`,
    value: ROUTES.Taglit,
  },
  {
    name: "איחוד והצלה",
    route: `/activities/organization/${ROUTES.UnitedRescue}`,
    value: ROUTES.UnitedRescue,
  },
  {
    name: "הסוכנות היהודית",
    route: `/activities/organization/${ROUTES.JewishAgency}`,
    value: ROUTES.JewishAgency,
  },
  {
    name: "Vets in clinics",
    route: `/activities/organization/private/${ROUTES.VET}`,
    value: ROUTES.VET,
  },
  {
    name: "Pets Friends",
    route: `/activities/organization/private/${ROUTES.Pets_Friends}`,
    value: ROUTES.Pets_Friends,
  },
  {
    name: "Emergency & Rescue",
    oute: `/activities/organization/private/${ROUTES.EmergencyAndRescue}`,
    value: ROUTES.EmergencyAndRescue,
  },
  {
    name: "קק״ל",
    route: `/activities/organization/private/${ROUTES.KKL}`,
    value: ROUTES.KKL,
  },
  {
    name: "חקלאות כחול לבן",
    route: `/activities/organization/private/${ROUTES.BlueWhite}`,
    value: ROUTES.BlueWhite,
  },
  {
    name: "אחים לנשק",
    route: `/activities/organization/${ROUTES.Brothers_In_Arms}`,
    value: ROUTES.Brothers_In_Arms,
  },
  {
    name: "כוח קפלן",
    route: `/activities/organization/${ROUTES.Kaplan_Force}`,
    value: ROUTES.Kaplan_Force,
  },
];
