import { ZONES } from "../../Ecosystem/Zone/Common/CardDetailsPage/constants";
import {
  TogetherInGderot,
  TogetherInOrtStudent,
  TogetherInTelaviv,
  TogetherNogaNeighborhood,
  TogetherEmergencyIcon,
  TogetherBrotherIcon,
  TogetherKaplanIcon,
  TogetherUnitedRescueIcon,
  TogetherJewishAgencyIcon,
  TogetherKKlIcon,
  TogetherVetIcon,
  VolunteeringPleasureIcon,
} from "../../../assets/icons/Logo";
import TogetherBestFriendsIcon from "../../../assets/icons/Logo/orgnaization/TogetherBestFriendsIcon";

export const getLogoByZone = (zone) => {
  if (zone.includes(ZONES.TEL_AVIV_CITY)) return TogetherInTelaviv;
  if (zone.includes(ZONES.GDEROT_CITY)) return TogetherInGderot;
  if (zone.includes(ZONES.NOGA_CITY)) return TogetherNogaNeighborhood;
  if (zone.includes(ZONES.ORT_SCHOOL)) return TogetherInOrtStudent;
  if (zone.includes(ZONES.EMERGENCY)) return TogetherEmergencyIcon;
  if (zone.includes(ZONES.BROTHERS_IN_ARMS)) return TogetherBrotherIcon;
  if (zone.includes(ZONES.KAPLAN_FORCE)) return TogetherKaplanIcon;
  if (zone.includes(ZONES.UNITED_RESCUE)) return TogetherUnitedRescueIcon;
  if (zone.includes(ZONES.JEWISH_AGENCY)) return TogetherJewishAgencyIcon;
  if (zone.includes(ZONES.KKL)) return TogetherKKlIcon;
  if (zone.includes(ZONES.ImaotRamatAviv)) return TogetherInTelaviv;
  if (zone.includes(ZONES.Vet)) return TogetherVetIcon;
  if (zone.includes(ZONES.BestFriends)) return TogetherBestFriendsIcon;
  if (zone.includes(ZONES.VOLUNTEERING_PLEASURE))
    return VolunteeringPleasureIcon;
};
