import React from "react";
import styled from "styled-components";
import { ShareIcons } from "./constant";

const Wrapper = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 37;
  flex-wrap: wrap;
`;
const IconsContainer = () => {
  return (
    <Wrapper>
      {ShareIcons.map((i) => (
        <>
          <img width={34} height={34} src={i} style={{ margin: "13px 18px" }} />
        </>
      ))}
    </Wrapper>
  );
};

export default IconsContainer;
