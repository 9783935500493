import styled from "styled-components";
import CardSliderContainer from "../../../../../kit/CardSliderContainer/CardSliderContainer";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import { useMemo, useState } from "react";
import ArrowUpAndDownIcons from "../../../../../kit/ArrowUpAndDownIcons/ArrowUpAndDownIcons";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  background-color: ${(props) => props.backgroundColor ?? "#dbf4f0"};
  margin: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "40px" : "15px 0"};
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);
`;

const TitleWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
  color: #007897;
  font-family: Assistant;
  font-weight: 700;
  font-size: ${(props) => props.fontSize ?? "20px"};
  letter-spacing: 0.223px;
  text-decoration-line: ${(props) => props.titleHasUnderline && "underline"};
`;

function LogosSlider({
  title,
  data,
  fontSize,
  backgroundColor,
  titleHasUnderline = true,
  logosPadding,
  showArrowUpOrDown = true,
  collapsedByDefault = false,
}) {
  const [areSponsorsVisible, setAreSponsorsVisible] = useState(
    !collapsedByDefault
  );
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const handleArrowOnClick = () => {
    setAreSponsorsVisible(!areSponsorsVisible);
  };

  const getLink = (link) => {
    if (!link) {
      return "";
    }
    if (!link?.startsWith("http://") && !link?.startsWith("https://")) {
      return `http://${link}`;
    }
    return link;
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      isWideScreen={isWideScreen}
      isMediumScreen={isMediumScreen}
    >
      <TitleWrapper fontSize={fontSize} titleHasUnderline={titleHasUnderline}>
        {title}
      </TitleWrapper>
      {data && areSponsorsVisible && (
        <CardSliderContainer
          id="card-slider-container"
          isWideScreen={isWideScreen}
        >
          {data.map((element) => (
            <Link
              to={getLink(element.link)}
              target={"_blank"}
              rel={"noopener noreferrer"}
              style={{
                width: "77px",
                height: "71px",
                background: "#fff",
                borderRadius: "9px",
                padding: "5px",
                boxShadow: "2.31px 2.31px 5.77px 1.15px rgba(0, 0, 0, 0.2)",
              }}
            >
              <img
                src={element.image}
                alt={element.image}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Link>
          ))}
        </CardSliderContainer>
      )}
      {showArrowUpOrDown && (
        <ArrowUpAndDownIcons
          status={areSponsorsVisible}
          changeStatus={handleArrowOnClick}
          padding={"0 0 10px 0"}
        />
      )}
    </Wrapper>
  );
}

export default LogosSlider;
