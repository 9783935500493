import React from "react";

function Star(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14557 7.8L0.985566 6.94L2.14557 4.92L0.0255664 3.96L0.465566 2.6L2.74557 3.08L2.98557 0.76H4.44557L4.68557 3.1L6.94557 2.6L7.40557 3.96L5.26557 4.92L6.44557 6.94L5.28557 7.8L3.72557 6.02L2.14557 7.8Z"
        fill="#1B5C6D"
      />
    </svg>
  );
}

export default Star;
