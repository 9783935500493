import React, { useContext, useState } from "react";
import styled from "styled-components";
import CustomRadioButton from "../../../../../Ecosystem/Zone/Common/Transportation/components/CustomRadioButton/CustomRadioButton";
import { LANGUAGES } from "../../constant";
import { LanguageContext } from "../../../../../../store/languageContext";

const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;
const Col = styled.div``;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Text = styled.p`
  margin: 0;
  color: #003f5d;
  text-align: left;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-top: 5px;
`;

const LanguagesContainer = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const radioButtons = [
    {
      name: LANGUAGES.BOTH.value,
      checked: currentLanguage === LANGUAGES.BOTH.value,
    },
    {
      name: LANGUAGES.ENGLISH.value,
      checked: currentLanguage === LANGUAGES.ENGLISH.value,
    },
    {
      name: LANGUAGES.HEBREW.value,
      checked: currentLanguage === LANGUAGES.HEBREW.value,
    },
  ];
  const [radionButtons, setRadioButtons] = useState(radioButtons);
  const handleRadioChange = (e) => {
    const name = e.target.name;
    setCurrentLanguage(name);
    setRadioButtons(
      [...radionButtons].map((r) => {
        if (r.name === name) return { ...r, checked: true };
        else return { ...r, checked: false };
      })
    );
  };
  return (
    <Wrapper>
      <Col>
        <IconWrapper>
          <CustomRadioButton
            name={LANGUAGES.BOTH.value}
            checked={radionButtons[0].checked}
            onChange={handleRadioChange}
            label=""
            outCircleWidth={"20px"}
            innerCircleWidth={"14px"}
          />
        </IconWrapper>

        <Text>EN/HEB</Text>
      </Col>
      <Col>
        <IconWrapper>
          <CustomRadioButton
            name={LANGUAGES.ENGLISH.value}
            checked={radionButtons[1].checked}
            onChange={handleRadioChange}
            label=""
            outCircleWidth={"20px"}
            innerCircleWidth={"14px"}
          />
        </IconWrapper>

        <Text>EN</Text>
      </Col>
      <Col>
        <IconWrapper>
          <CustomRadioButton
            name={LANGUAGES.HEBREW.value}
            checked={radionButtons[2].checked}
            onChange={handleRadioChange}
            label=""
            outCircleWidth={"20px"}
            innerCircleWidth={"14px"}
          />
        </IconWrapper>

        <Text>HEB</Text>
      </Col>
    </Wrapper>
  );
};

export default LanguagesContainer;
