import React from "react";

const PersonIcon = ({ width = 17, height = 21, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 17 21"
    >
      <g fill={color ?? "#fff"}>
        <path d="M5.426 20.682c-.1-.034-.203-.063-.301-.102-.486-.194-.746-.646-.653-1.167.371-2.105.765-4.206 1.117-6.314.173-1.037.305-2.084.398-3.132.066-.759-.088-.923-.8-1.15-1.075-.342-2.155-.671-3.224-1.033a3.395 3.395 0 01-.924-.47c-.646-.477-.507-1.638.215-1.99.218-.106.438-.095.663-.025 1.6.494 3.228.859 4.885 1.094 1.31.185 2.615.128 3.917-.083a35.579 35.579 0 004.345-1.008c.465-.136.834.005 1.082.431.31.532.423 1.296-.435 1.745-.39.204-.811.349-1.225.5-.9.33-1.81.635-2.708.97-.788.294-.878.415-.8 1.306.166 1.93.557 3.823.904 5.723.207 1.13.417 2.259.619 3.39.109.61-.198 1.093-.784 1.262-.053.016-.105.035-.158.053h-.492c-.586-.16-.842-.604-.956-1.167-.062-.308-.111-.62-.18-.925-.314-1.389-.625-2.777-.954-4.162a4.736 4.736 0 00-.331-.878c-.11-.238-.22-.23-.35.004a1.45 1.45 0 00-.111.244c-.103.31-.226.616-.296.934a412.972 412.972 0 00-.985 4.628c-.125.606-.328 1.136-.985 1.322h-.493z"></path>
        <path d="M8.49.926c1.168-.001 2.12.968 2.121 2.158.001 1.19-.95 2.162-2.117 2.163-1.166 0-2.12-.97-2.12-2.16C6.371 1.9 7.323.927 8.49.926z"></path>
      </g>
    </svg>
  );
};

export default PersonIcon;
