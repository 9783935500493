export const ROUTES = {
  IRS: "",
};

export const VolunteerBenefits = [
  {
    name: "Volunteer Ecosystem - ISR",
    route: `/activities/volunteer-benefits/${ROUTES.IRS}`,
    value: ROUTES.IRS,
  },
];
