import React from "react";

const TrueIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="12"
      fill="none"
      viewBox="0 0 17 12"
    >
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.715"
        d="M15.54 1l-9.433 9.433-4.716-4.717"
      ></path>
    </svg>
  );
};

export default TrueIcon;
