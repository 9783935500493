import React, { useState } from "react";
import styled from "styled-components";
import Card from "./components/Card";
import { Link, useNavigate } from "react-router-dom";
import emBtn from "../../../../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/embtn.png";
import { ROUTES as MAIN_ROUTES } from "../../../../../Common/Layout/Main/constants";
import useCurrentUser from "../../../../../../customHooks/useCurrentUser";
import { ROUTES as EMERGENCY_ROUTES } from "../../../../../Emergency/constant";
import emergencyButton1 from "../../../../../../assets/images/Emergency/mock/btn6.png";
import emergencyButton2 from "../../../../../../assets/images/Emergency/mock/btn7.png";
import helpIcon from "../../../../../../assets/icons/helpIcon.png";
import StyledButton from "../../../../../Emergency/components/StyledButton";
import TogetherEmergencyButtonIcon from "../../../../../../assets/icons/Logo/Emergency/TogetherEmergencyButtonIcon";
import { TogetherEmergencyIcon } from "../../../../../../assets/icons/Logo";
import BellIcon from "../../../../../../assets/icons/Activities/LandingPage/BellIcon";
import OrangeAppleIcon from "../../../../../../assets/icons/Common/NavBar/OrangeAppleIcon";
import ArrowUpAndDownIcons from "../../../../../../kit/ArrowUpAndDownIcons/ArrowUpAndDownIcons";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import LogoWithTitle from "../../../../../../kit/LogoWithTitle/LogoWithTitle";
import useMediaQueryConstants from "../../../../../../customHooks/useMediaQueryConstants";
const CardSliderContainer = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "90%" : "100%")};
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  margin: auto;
  padding: 5px 0 8px 0;
  display: flex;
  justify-content: ${({ centered }) => centered && "center"};
`;
const SlidingCardsWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  align-items: center;
  gap: 20px;
  padding: ${({ isWideScreen }) => (isWideScreen ? "5px 0 " : "5px 15px")};
  width: fit-content;
  transition: transform 0.5s ease;
  transform: ${({ displacementprop }) => `translateX(${displacementprop}px)`};
`;
const SponsorsWrapper = styled.div``;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const CardsAndArrowsWrapper = styled.div`
  position: relative;
  width: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen ? "100%" : isMediumScreen ? "96%" : "100%"};
  margin: auto;
`;
const ShadowBox = styled.div`
  width: 100%;
  height: 5px;
  box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.13);
  margin: 10px 0;
`;
const ArrowWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: ${({ isLeft }) => (isLeft ? "5px" : "auto")};
  right: ${({ isRight }) => (isRight ? "5px" : "auto")};
  transform: translate(0, -50%);
`;
const Page = (props) => {
  const {
    isCardsVisible,
    data,
    activeSlide,
    handleFocus,
    englishLogo,
    hideLogoText,
    useLogoTitle,
    logoTitle,
    icon,
    centered,
    handleUnFocus,
  } = props;
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const [count, setCount] = useState(0);
  const [displacement, setDisplacement] = useState(0);
  const swipeRight = (cardWidth, gap) => {
    if (count < data.length - 1 - 3) {
      setDisplacement((displacement) => displacement - cardWidth - gap);
      setCount(count + 1);
    }
  };
  const swipeLeft = (cardWidth, gap) => {
    if (displacement < 0) {
      setDisplacement((displacement) => displacement + cardWidth + gap);
      setCount(count - 1);
    }
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <SponsorsWrapper
      isWideScreen={isWideScreen || isMediumScreen}
      onClick={handleUnFocus}
    >
      {!useLogoTitle && (
        <LogoWrapper>{icon ? icon : <TogetherEmergencyIcon />}</LogoWrapper>
      )}
      {useLogoTitle && (
        <LogoWithTitle title={logoTitle} iconHeight={"50"} iconWidth={"220"} />
      )}

      {isCardsVisible && data && (
        <CardsAndArrowsWrapper
          isWideScreen={isWideScreen}
          isMediumScreen={isMediumScreen}
        >
          {(isWideScreen || isMediumScreen) && (
            <ArrowWrapper isLeft onClick={() => swipeLeft(90, 20)}>
              <FaAngleLeft color="#316788" />
            </ArrowWrapper>
          )}
          <CardSliderContainer
            id="card-slider-container"
            isWideScreen={isWideScreen || isMediumScreen}
            centered={centered}
          >
            <SlidingCardsWrapper
              width={`${123 * data.length}px`}
              isWideScreen={isWideScreen || isMediumScreen}
              displacementprop={displacement}
            >
              {data.map((company, index) => (
                <div onClick={stopPropagation}>
                  <Card
                    key={company.id}
                    name={company.name}
                    logo={company.logo}
                    index={index}
                    isActive={activeSlide === index}
                    activeSlide={activeSlide}
                    handleFocus={handleFocus}
                  />
                </div>
              ))}
            </SlidingCardsWrapper>
          </CardSliderContainer>
          {(isWideScreen || isMediumScreen) && (
            <ArrowWrapper isRight onClick={() => swipeRight(88, 20)}>
              <FaAngleRight color="#316788" />
            </ArrowWrapper>
          )}
        </CardsAndArrowsWrapper>
      )}
    </SponsorsWrapper>
  );
};
export default Page;
