import React, { useState } from "react";
import styled from "styled-components";
import Header from "../Common/Layout/Header/Header";
import ButtonGroup from "./component/ButtonGroup";
import ShortcutIcons from "./shorcutIcons/ShortcutIcons";
import {
  TABS,
  ICONS,
  CARD_COMPONENTS,
  Cards,
  ABSTRACT_EMERGENCY_CARD_COMPONENTS,
  volunteerAbstractEmergencyDomains,
  AdminTeamMemberAbstractEmergencyDomains,
} from "./constant.jsx";
import useCurrentUser from "../../customHooks/useCurrentUser";
import { USER_ROLES } from "../Auth/constant";
import ElephantCard from "./Cards/ElephantCard";
import GreenDoubleArrowIcon2 from "../../assets/icons/GreenDoubleArrowIcon2";
import { Link } from "react-router-dom";

import useMediaQueryConstants from "../../customHooks/useMediaQueryConstants";
import { EmergencyLogo } from "../Auth/CreateAccountPage/emergencyLogo";
import useHideBack from "../../customHooks/useHideBack.js";

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "#fafffa"};
  min-height: calc(100vh - 100px);
`;
const Container = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "80%" : "100%")};
  margin: 20px auto;
  display: ${({ isWideScreen }) => (isWideScreen ? " flex" : "block")};
  justify-content: space-evenly;
`;
const CardWrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "45%" : "100%")};
`;
const ButtonsWrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "55%" : "100%")};
`;
const IconsAndMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const IconsWrapper = styled.div`
  width: ${(props) => (props.currentCard === Cards.Elephant ? "80%" : "100%")};
  margin-top: 15px;
`;
const MoreWrapper = styled.div`
  display: flex;
  align-items: end;
  width: 15%;
`;
const More = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #4b778d;
`;
const ShortcutIconsAndMoreWrapper = styled.div`
  display: flex;
`;
function Home({ isAbstractEmergency }) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { userRole } = useCurrentUser();
  const [currentCard, setCurrentCard] = useState(Cards.User);
  const handleIconChange = (activeIcon) => {
    setCurrentCard(activeIcon);
  };
  const chooseCurrentCard = () => {
    if (currentCard === Cards.User)
      return isAbstractEmergency
        ? ABSTRACT_EMERGENCY_CARD_COMPONENTS[userRole]
        : CARD_COMPONENTS[userRole];
    else if (currentCard === Cards.Elephant) return <ElephantCard />;
    else
      return isAbstractEmergency
        ? ABSTRACT_EMERGENCY_CARD_COMPONENTS[userRole]
        : CARD_COMPONENTS[userRole];
  };
  useHideBack(isAbstractEmergency);

  return (
    <Wrapper backgroundColor={isAbstractEmergency && "#E5EEFF"}>
      <Header
        titlesLang="heb"
        Icon={isAbstractEmergency ? EmergencyLogo : ICONS[userRole]}
        personIconColor="#7FB383"
        hasPersonIcon={false}
        hasEarth={false}
        showNavbar={false}
        hasLogoutIcon={true}
        handlePersonIconClicked={handleIconChange}
      />
      <Container isWideScreen={isWideScreen || isMediumScreen}>
        <IconsAndMoreWrapper>
          {!(isWideScreen || isMediumScreen) &&
            userRole === USER_ROLES.MEMBER && (
              <IconsWrapper currentCard={currentCard}>
                <ShortcutIcons handleIconChange={handleIconChange} />
              </IconsWrapper>
            )}
          {!(isWideScreen || isMediumScreen) &&
            currentCard === Cards.Elephant && (
              <MoreWrapper>
                <More to={"/profile"}>Member</More>
                <GreenDoubleArrowIcon2 height={"12"} color={"#6e8d9a"} />
              </MoreWrapper>
            )}
        </IconsAndMoreWrapper>
        <CardWrapper isWideScreen={isWideScreen || isMediumScreen}>
          {chooseCurrentCard()}
        </CardWrapper>
        <ButtonsWrapper isWideScreen={isWideScreen || isMediumScreen}>
          <ShortcutIconsAndMoreWrapper>
            {(isWideScreen || isMediumScreen) &&
              userRole === USER_ROLES.MEMBER && (
                <IconsWrapper currentCard={currentCard}>
                  <ShortcutIcons handleIconChange={handleIconChange} />
                </IconsWrapper>
              )}
            {(isWideScreen || isMediumScreen) &&
              currentCard === Cards.Elephant && (
                <MoreWrapper>
                  <More to={"/profile"}>Member</More>
                  <GreenDoubleArrowIcon2 height={"12"} color={"#6e8d9a"} />
                </MoreWrapper>
              )}
          </ShortcutIconsAndMoreWrapper>
          <div style={{ height: "25px" }} />
          <ButtonGroup
            buttons={
              isAbstractEmergency
                ? [USER_ROLES.TEAM_MEMBER, USER_ROLES.ADMIN].includes(userRole)
                  ? AdminTeamMemberAbstractEmergencyDomains
                  : volunteerAbstractEmergencyDomains
                : TABS[userRole]
            }
            isAbstractEmergency={isAbstractEmergency}
          />
        </ButtonsWrapper>
      </Container>
    </Wrapper>
  );
}

export default Home;
