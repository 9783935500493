import bordImage from "../../../assets/images/Activities/CommunityBoard/card-cover.png";
import whiteComunityBord from "../../../assets/images/Activities/CommunityBoard/comunityBordWhite.png";
import bordImage2 from "../../../assets/images/Activities/Common/give-receive.png";
import WisdomForm from "../../../assets/images/Activities/Common/wisdom-form.png";
import whiteGiveReceive from "../../../assets/images/Activities/Common/give-receive-white.png";
import give_Receive1 from "../../../assets/images/Activities/OrtSchool/giveReceive1.jpg";
import give_Receive2 from "../../../assets/images/Activities/OrtSchool/giveReceive2.jpg";
import greenGive_Receive from "../../../assets/images/Activities/Common/give-receive.png";
import Networking2 from "../../../assets/images/Activities/TogetherInTheCity/telaviv/Mock/cardSlider/tel82.jpg";
import em1 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em1.png";
import em2 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em2.png";
import em3 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em3.png";
import em4 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em4.png";
import em5 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em5.png";
import em6 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em6.png";
import em7 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em7.png";
import em8 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em8.png";
import em9 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em9.png";
import em10 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/em10.png";
// import lem3 from "../../../assets/images/Activities/TogetherInTheCity/Mock/Emergency/lem3.png";
import c1 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c1.png";
import c2 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c2.png";
import c3 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c3.png";
import c4 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c4.png";
import c5 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c5.png";
import c6 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c6.png";
import c7 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c7.png";
import c8 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c8.png";
import c9 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c9.png";
import c10 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c10.png";
import c11 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c11.png";
import c12 from "../../../assets/images/Activities/TogetherInTheCity/Mock/companies/c12.png";

import vs1 from "../../../assets/images/Activities/Organization/Vet/Sponsers/vs1.png";
import vs2 from "../../../assets/images/Activities/Organization/Vet/Sponsers/vs2.png";
import vs3 from "../../../assets/images/Activities/Organization/Vet/Sponsers/vs3.png";
import vs4 from "../../../assets/images/Activities/Organization/Vet/Sponsers/vs4.png";
import rm1 from "../../../assets/images/Activities/Organization/Vet/ReadMore/rm1.png";
import rm2 from "../../../assets/images/Activities/Organization/Vet/ReadMore/rm2.png";
import rm3 from "../../../assets/images/Activities/Organization/Vet/ReadMore/rm3.png";
import rm4 from "../../../assets/images/Activities/Organization/Vet/ReadMore/rm4.png";
import vsp1 from "../../../assets/images/Activities/Organization/Vet/ServiceProvider/vsp1.png";
import vsp2 from "../../../assets/images/Activities/Organization/Vet/ServiceProvider/vsp2.png";
import vsp3 from "../../../assets/images/Activities/Organization/Vet/ServiceProvider/vsp3.png";
import vsp4 from "../../../assets/images/Activities/Organization/Vet/ServiceProvider/vsp4.png";
import fm1 from "../../../assets/images/Activities/Organization/Vet/FiveMin/fm1.png";
import fm2 from "../../../assets/images/Activities/Organization/Vet/FiveMin/fm2.png";
import fm3 from "../../../assets/images/Activities/Organization/Vet/FiveMin/fm3.png";
import fm4 from "../../../assets/images/Activities/Organization/Vet/FiveMin/fm4.png";
import fm5 from "../../../assets/images/Activities/Organization/Vet/FiveMin/fm5.png";
import fm6 from "../../../assets/images/Activities/Organization/Vet/FiveMin/fm6.png";
import dt1 from "../../../assets/images/Activities/Organization/Vet/DailyTip/dt1.png";
import dt2 from "../../../assets/images/Activities/Organization/Vet/DailyTip/dt2.png";
import dt3 from "../../../assets/images/Activities/Organization/Vet/DailyTip/dt3.png";
import dt4 from "../../../assets/images/Activities/Organization/Vet/DailyTip/dt4.png";
import dt5 from "../../../assets/images/Activities/Organization/Vet/DailyTip/dt5.png";
import dt6 from "../../../assets/images/Activities/Organization/Vet/DailyTip/dt6.png";

import urfm1 from "../../../assets/images/Activities/Organization/UnitedRecue/FiveMin/urfm1.png";
import urfm2 from "../../../assets/images/Activities/Organization/UnitedRecue/FiveMin/urfm2.png";
import urfm3 from "../../../assets/images/Activities/Organization/UnitedRecue/FiveMin/urfm3.png";
import urfm4 from "../../../assets/images/Activities/Organization/UnitedRecue/FiveMin/urfm4.png";
import urfm5 from "../../../assets/images/Activities/Organization/UnitedRecue/FiveMin/urfm5.png";
import ru1 from "../../../assets/images/Activities/Organization/UnitedRecue/RecueUnion/ru1.png";
import ru2 from "../../../assets/images/Activities/Organization/UnitedRecue/RecueUnion/ru2.png";
import ru3 from "../../../assets/images/Activities/Organization/UnitedRecue/RecueUnion/ru3.png";
import ru4 from "../../../assets/images/Activities/Organization/UnitedRecue/RecueUnion/ru4.png";

import ha1 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha1.png";
import ha2 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha2.png";
import ha3 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha3.png";
import ha4 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha4.png";
import ha5 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha5.png";
import ha6 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha6.png";
import ha7 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha7.png";
import ha8 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha8.png";
import ha9 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha9.png";
import ha10 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha10.png";
import ha11 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha11.png";
import ha12 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha12.png";
import ha13 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha13.png";
import ha14 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha14.png";
import ha24 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha24.png";
import ha25 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha25.png";
import ha26 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha26.png";
import ha27 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha27.png";
import ha28 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha28.png";
import ha29 from "../../../assets/images/Activities/Organization/Pets_Friends/Helpful_Tips_And_Advice/ha29.png";

import eart1 from "../../../assets/images/Activities/Organization/EmergencyAndRescue/Tips/eart1.png";
import eart2 from "../../../assets/images/Activities/Organization/EmergencyAndRescue/Tips/eart2.png";

import mbfsp1 from "../../../assets/images/Activities/Organization/MyBestFriends/ServiceProvider/mbfsp1.png";
import mbfsp2 from "../../../assets/images/Activities/Organization/MyBestFriends/ServiceProvider/mbfsp2.png";
import mbfsp3 from "../../../assets/images/Activities/Organization/MyBestFriends/ServiceProvider/mbfsp3.png";
import mbfsp4 from "../../../assets/images/Activities/Organization/MyBestFriends/ServiceProvider/mbfsp4.png";

export const publicForumData = {
  slider: [
    {
      image: bordImage,
      title: "Volunteer Board",
      // title: "Someone have any dog's collar?",
      location: "Ofira Cohen",
      date: "09/01/2023",
      id: Math.random(),
      linear: [
        {
          image: give_Receive1,
          title: "English textbook for delivery",
          location: "Ofira Cohen/",
          date: "09/01/2023",
          id: Math.random(),
        },
        {
          image: give_Receive2,
          title: "Who wants pencils?",
          location: "Ofira Cohen",
          date: "09/01/2023",
          id: Math.random(),
        },
      ],
    },
    {
      image: bordImage2,
      // title: "Does anyone have a mat?",
      title: "Give & Receive",
      location: "Ofira Cohen/",
      date: "09/01/2023",
      id: Math.random(),
      linear: [
        {
          image: give_Receive1,
          name: "English textbook for delivery",
          location: "Ofira Cohen/",
          date: "09/01/2023",
          time: "16:30",
          id: Math.random(),
        },
        {
          image: give_Receive2,
          name: "Who wants pencils?",
          location: "Ofira Cohen",
          date: "09/01/2023",
          time: "7:30",
          id: Math.random(),
        },
      ],
    },
    {
      image: Networking2,
      // title: "Looking for a job in the tech field",
      title: "Volunteer networking",
      location: "Yuval Kovach",
      date: "09/01/2023",
      id: Math.random(),
    },
  ],
  linear: [],
};

export const vetForumData = [
  {
    image: bordImage,
    title: "פורום מתנדבים",
    id: Math.random(),
  },
  {
    image: bordImage2,
    title: "Give & Receive - לוח תן וקבל",
    id: Math.random(),
  },
  {
    image: Networking2,
    title: "שאלות מקצועיות",
    id: Math.random(),
  },
  {
    image: WisdomForm,
    title: "חכמת הקהילה",
    id: Math.random(),
  },
];

export const telAvivForumData = [
  {
    image: bordImage,
    title: "פורום מתנדבים",
    id: Math.random(),
  },
  {
    image: bordImage2,
    title: "Give & Receive - לוח תן וקבל",
    id: Math.random(),
  },
  {
    image: Networking2,
    title: "TLV Networking",
    id: Math.random(),
  },
];

export const unitedRescueForumData = [
  {
    image: bordImage,
    title: "פורום מתנדבים",
    id: Math.random(),
  },
  {
    image: bordImage2,
    title: "לוח נותנים באהבה",
    id: Math.random(),
  },
  {
    image: Networking2,
    title: "Volunteer networking",
    id: Math.random(),
  },
];

//// companies
export const companiesData = [
  {
    name: "בנק לאומי",
    logo: c1,
  },
  {
    name: "Intel",
    logo: c2,
    background: "white",
  },
  {
    name: "Google",
    logo: c3,
  },
  {
    name: "Meta",
    logo: c4,
  },
  {
    name: "הסכונות היהודית",
    logo: c5,
  },
  {
    name: "הסכונות היהודית",
    logo: c6,
  },
  {
    name: "הסכונות היהודית",
    logo: c7,
  },
  {
    name: "הסכונות היהודית",
    logo: c8,
    background: "white",
  },
  {
    name: "הסכונות היהודית",
    logo: c9,
    background: "white",
  },
  {
    name: "הסכונות היהודית",
    logo: c10,
  },
  {
    name: "הסכונות היהודית",
    logo: c11,
  },
  {
    name: "הסכונות היהודית",
    logo: c12,
    background: "white",
  },
];
export const companiesData2 = [
  {
    name: "הסכונות היהודית",
    logo: c7,
  },
  {
    name: "הסכונות היהודית",
    logo: c8,
    background: "white",
  },
  {
    name: "הסכונות היהודית",
    logo: c9,
    background: "white",
  },
  {
    name: "הסכונות היהודית",
    logo: c10,
  },
  {
    name: "הסכונות היהודית",
    logo: c11,
  },
  {
    name: "הסכונות היהודית",
    logo: c12,
    background: "white",
  },
];

export const vetSponsersData = [
  { link: "https://www.hillspet.co.il", image: vs1 },
  { link: "https://www.royalcanin.com/il", image: vs2 },
  { link: "https://www.purina.co.il ", image: vs3 },
  { link: "https://www.zoetis.com", image: vs4 },
];

export const vetReadMoreData = [
  { link: "https://www.vetmarket.co.il", image: rm1 },
  { link: "https://almedimarket.com/#/", image: rm2 },
  { link: "https://www.zoetis.com", image: rm3 },
  { link: "https://www.purina.co.il", image: rm4 },
];

export const vetServiceProviderData = [
  { link: "https://www.zooclub.co.il", image: vsp1 },
  { link: "https://almedimarket.com/#/", image: vsp2 },
  { link: "https://doghotels.co.il/פנסיון-כלבים/בית-הדקל/", image: vsp3 },
  { link: "https://www.vet-hospital.co.il/mobile/", image: vsp4 },
];

export const rescueUnionData = [
  { link: "https://www.terminalx.com", image: ru1 },
  { link: "https://www.homecenter.co.il", image: ru2 },
  { link: "https://www.bug.co.il", image: ru3 },
  { link: "https://www.betili-shop.com", image: ru4 },
];

export const myBestFriendsServiceProviderData = [
  { link: "", image: mbfsp1 },
  { link: "", image: mbfsp2 },
  { link: "", image: mbfsp3 },
  { link: "", image: mbfsp4 },
];

export const emergencyData = [
  {
    name: "עזרה בעורף",
    id: Math.random(),
    logo: em4,
    linear: [
      {
        id: Math.random(),
        name: "עזרה בעורף",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "עזרה בעורף",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "ציוד רפואי",
    id: Math.random(),
    logo: em2,
    linear: [
      {
        id: Math.random(),
        name: "ציוד רפואי - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "ציוד רפואי",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "עזרה בחזית",
    id: Math.random(),
    logo: em3,
    linear: [
      {
        id: Math.random(),
        name: "עזרה בחזית - לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "עזרה בחזית - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: " כיבוי והצלה",
    id: Math.random(),
    logo: em1,
    linear: [
      {
        id: Math.random(),
        name: " כיבוי והצלה - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: " כיבוי והצלה - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "בתי חולים",
    id: Math.random(),
    logo: em5,
    linear: [
      {
        id: Math.random(),
        name: "בתי חולים - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "בתי חולים - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "אחים לנשק",
    id: Math.random(),
    logo: em6,
    linear: [
      {
        id: Math.random(),
        name: "אחים לנשק - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "אחים לנשק - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "מתנדבי קפלן",
    id: Math.random(),
    logo: em7,
    linear: [
      {
        id: Math.random(),
        name: "מתנדבי קפלן - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "מתנדבי קפלן - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "עזרה נפשית",
    id: Math.random(),
    logo: em8,
    linear: [
      {
        id: Math.random(),
        name: "עזרה נפשית - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "עזרה נפשית - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
  {
    name: "אירוח משפחות ",
    id: Math.random(),
    logo: em9,
    linear: [
      {
        id: Math.random(),
        name: "אירוח משפחות  - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "אירוח משפחות  - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },

  {
    name: "מתנדבי ת״א",
    id: Math.random(),
    logo: em10,
    linear: [
      {
        id: Math.random(),
        name: "מתנדבי ת״א - לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: whiteGiveReceive,
      },
      {
        id: Math.random(),
        name: "מתנדבי ת״א  - פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: whiteComunityBord,
      },
    ],
  },
];

export const getEmergencyData = (isEnglish) => [
  {
    ...emergencyData[0],
    name: isEnglish ? "Medical \n equipment" : "עזרה בחזית",
    linear: [
      {
        id: Math.random(),
        name: isEnglish ? "Medical equipment" : "עזרה בחזית",
        description: isEnglish ? "Give & Recieve Board" : "לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: isEnglish ? "Medical equipment" : "עזרה בחזית",
        description: isEnglish ? "Community Forum" : "פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    ...emergencyData[1],
    name: isEnglish ? "Extraction \n and rescue" : "ציוד רפואי",
    linear: [
      {
        id: Math.random(),
        name: isEnglish ? "Extraction \n and rescue" : "ציוד רפואי",
        description: isEnglish ? "Give & Recieve Board" : "לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: isEnglish ? "Extraction \n and rescue" : "ציוד רפואי",
        description: isEnglish ? "Community Forum" : "פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    ...emergencyData[2],
    name: isEnglish ? "Pets" : "בעלי חיים",
    linear: [
      {
        id: Math.random(),
        name: isEnglish ? "Pets" : "בעלי חיים",
        description: isEnglish ? "Give & Recieve Board" : "לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: isEnglish ? "Pets" : "בעלי חיים",
        description: isEnglish ? "Community Forum" : "פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },

  {
    ...emergencyData[3],
    name: isEnglish ? "Parents \n & Kids" : "הורים וילדים",
    linear: [
      {
        id: Math.random(),
        name: isEnglish ? "Parents \n & Kids" : "הורים וילדים",
        description: isEnglish ? "Give & Recieve Board" : "לוח נותנים באהבה",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: isEnglish ? "Parents \n & Kids" : "הורים וילדים",
        description: isEnglish ? "Community Forum" : "פורום מתנדבים",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
];

export const getLinearEmergencyData = (cardTitle) => {
  let data = emergencyData[0].linear;
  cardTitle &&
    data.forEach((item) => {
      item.name = cardTitle;
    });
  return data;
};

export const brotherInArmsData = [
  {
    name: "עזרה בעורף",
    id: Math.random(),
    logo: em4,
    linear: [
      {
        id: Math.random(),
        name: "עזרה בעורף",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "עזרה בעורף",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "ציוד רפואי",
    id: Math.random(),
    logo: em2,
    linear: [
      {
        id: Math.random(),
        name: "ציוד רפואי",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "ציוד רפואי",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "עזרה בחזית",
    id: Math.random(),
    logo: em3,
    linear: [
      {
        id: Math.random(),
        name: "עזרה בחזית",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "עזרה בחזית",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: " כיבוי והצלה",
    id: Math.random(),
    logo: em1,
    linear: [
      {
        id: Math.random(),
        name: " כיבוי והצלה",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: " כיבוי והצלה",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "בתי חולים",
    id: Math.random(),
    logo: em5,
    linear: [
      {
        id: Math.random(),
        name: "בתי חולים",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "בתי חולים",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "אחים לנשק",
    id: Math.random(),
    logo: em6,
    linear: [
      {
        id: Math.random(),
        name: "אחים לנשק",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "אחים לנשק",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "מתנדבי קפלן",
    id: Math.random(),
    logo: em7,
    linear: [
      {
        id: Math.random(),
        name: "מתנדבי קפלן",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "מתנדבי קפלן",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "עזרה נפשית",
    id: Math.random(),
    logo: em8,
    linear: [
      {
        id: Math.random(),
        name: "עזרה נפשית",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "עזרה נפשית",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
  {
    name: "אירוח משפחות ",
    id: Math.random(),
    logo: em9,
    linear: [
      {
        id: Math.random(),
        name: "אירוח משפחות ",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "אירוח משפחות ",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },

  {
    name: "מתנדבי תל אביב",
    id: Math.random(),
    logo: em10,
    linear: [
      {
        id: Math.random(),
        name: "מתנדבי תל אביב",
        description: "לוח נותנים באהבה ",
        date: "09/02/2024",
        time: "07:30",
        image: greenGive_Receive,
      },
      {
        id: Math.random(),
        name: "מתנדבי תל אביב",
        description: "פורום מתנדבים ",
        date: "09/02/2024",
        time: "07:30",
        image: bordImage,
      },
    ],
  },
];

export const vetFiveMin = [
  {
    id: "fm1",
    title: "Surgery",
    image: fm1,
  },
  {
    id: "fm2",
    title: "Dermatology",
    image: fm2,
  },
  {
    id: "fm3",
    title: "Internal Medicine",
    image: fm3,
  },
  {
    id: "fm4",
    title: "Emergency",
    image: fm4,
  },
  {
    id: "fm5",
    title: "Behavior",
    image: fm5,
  },
  {
    id: "fm6",
    title: "Vet clinic management",
    image: fm6,
  },
];

export const unitedRescueFiveMin = [
  {
    id: "urfm1",
    title: "עזרה ראשונה תינוקות",
    image: urfm1,
  },
  {
    id: "urfm2",
    title: "חירום מבוגרים",
    image: urfm2,
  },
  {
    id: "urfm3",
    title: "מערכת  הנשימה",
    image: urfm3,
  },
  {
    id: "urfm4",
    title: "קרדיולוגיה",
    image: urfm4,
  },
  {
    id: "urfm5",
    title: "פסיכולוגיה  במצבי  חירום",
    image: urfm5,
  },
];

export const vetDailyTip = [
  {
    id: "dt1",
    title: "Surgery",
    image: dt1,
  },
  {
    id: "dt2",
    title: "Dermatology",
    image: dt2,
  },
  {
    id: "dt3",
    title: "Internal Medicine ",
    image: dt3,
  },
  {
    id: "dt4",
    title: "Emergency",
    image: dt4,
  },
  {
    id: "dt5",
    title: "Behavior",
    image: dt5,
  },
  {
    id: "dt6",
    title: "Vet clinic management",
    image: dt6,
  },
];

export const HelpfulTipsAndAdvice = [
  {
    id: "ha1",
    title: "גידול  כלבים",
    image: ha1,
  },
  {
    id: "ha2",
    title: "גידול  חתולים",
    image: ha2,
  },
  {
    id: "ha3",
    title: "התנהגות  בעלי  חיים",
    image: ha3,
  },
  {
    id: "ha4",
    title: "רפואת  כלבים",
    image: ha4,
  },
  {
    id: "ha5",
    title: "רפואת  חתולים",
    image: ha5,
  },
  {
    id: "ha6",
    title: "העשרה לחתולים",
    image: ha6,
  },
  {
    id: "ha7",
    title: "תזונה  חתולים",
    image: ha7,
  },
  {
    id: "ha8",
    title: "תזונת  כלבים",
    image: ha8,
  },
  {
    id: "ha9",
    title: "ילדים  ובעלי  חיים",
    image: ha9,
  },
];

export const HelpfulTipsAndAdvice2 = [
  {
    id: "ha10",
    title: "כלבים  בוגרים",
    image: ha10,
  },
  {
    id: "ha11",
    title: "גורי  כלבים",
    image: ha11,
  },
  {
    id: "ha12",
    title: "כלבים  לאומנה",
    image: ha12,
  },
  {
    id: "ha13",
    title: "גורי  חתולים",
    image: ha13,
  },
  {
    id: "ha14",
    title: "חתולים  בוגרים",
    image: ha14,
  },
];

export const HelpfulTipsAndAdvice3 = [
  {
    id: "ha15",
    title: "19-20.4",
    image: ha1,
  },
  {
    id: "ha16",
    title: "26-27.4",
    image: ha2,
  },
  {
    id: "ha17",
    title: "3-4.5",
    image: ha3,
  },
  {
    id: "ha18",
    title: "10-11.5",
    image: ha4,
  },
  {
    id: "ha19",
    title: "17-18.5",
    image: ha5,
  },
  {
    id: "ha20",
    title: "24-25.5",
    image: ha6,
  },
  {
    id: "ha21",
    title: "31-1.5",
    image: ha7,
  },
  {
    id: "ha22",
    title: "7-8.5",
    image: ha8,
  },
  {
    id: "ha23",
    title: "14-15.5",
    image: ha9,
  },
];

export const HelpfulTipsAndAdvice4 = [
  {
    id: "ha24",
    title: "עזרה בכלביה בתל אביב",
    image: ha24,
  },
  {
    id: "ha25",
    title: "עזרה בכלביה בהרצליה",
    image: ha25,
  },
  {
    id: "ha26",
    title: "עזרה בכלביה ברחובות",
    image: ha26,
  },
  {
    id: "ha27",
    title: "עזרה ביום אימוץ בת״א",
    image: ha27,
  },
  {
    id: "ha28",
    title: "עזרה ביום אימוץ בהרצליה",
    image: ha28,
  },
  {
    id: "ha29",
    title: "הסעות ולוגיסטיקה",
    image: ha29,
  },
];

export const EmergencyAndRescueTips = [
  {
    id: "eart1",
    title: "חילוץ בשטח פתוח",
    image: eart1,
  },
  {
    id: "eart2",
    title: "עזרה ראשונה",
    image: eart2,
  },
];
