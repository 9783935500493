export const ROUTES = {
  CITY: "together",
  STUDENT: "student",
  SCHOOL: "school",
  ORGANIZATION: "organization",
  VOLUNTEER_BENEFITS: "volunteer-benefits",
  VOLUNTEER_ORGANIZATION: "volunteer-organization",
  PUBLIC_ORGANIZATION: "public-organization",
  MAP: "map",
  BOARD: "board",
  EMERGENCY: "emergency",
  Register_For_VolunteerWork: "register-for-volunteerWork",
  Registration_For_Sponsors: "registration-for-Sponsors",
  VOLUNTEERING_PLEASURE: "volunteering_pleasure",
};
// http://localhost:3000/activities/register-for-volunteerWork
export const ECO_SYSTEMS = {
  "Tel Aviv City": "tel_aviv_city",
  "Ramat Hasharon": "ramat_hasharon",
  "Vets In Clinics - וטרינרים": "vets_in_clinics",
  "Pets Friends": "pets_friends",
  "Best Friends": "best_friends",
  "Gderot City": "gderot_city",
  "Noga City": "noga_city",
  "Scouts City": "scouts_city",
  "Jewish Agency": "jewish_agency",
  "Kkl Organization": "kkl_organization",
  "Almog Organization": "almog_organization",
  "Taglit Organization": "taglit_organization",
  "United Rescue Organization": "united_rescue_organization",
  "ISR Organization": "isr_organization",
  "Gderot School": "gderot_school",
  "Ort School": "ort_school",
  "Gderot Student": "gderot_student",
  "ISR Volunteer Benefits": "isr_volunteer_benefits",
  Emergency: "emergency",
  "Brothers In Arms Emergency": "brothers_in_arms_emergency",
  "Kapla Force Emergency": "Kapla_force_emergency",
};
