import React from "react";

const CameraIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={14}
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        fill="#003F5D"
        d="M17.185 12.019c-.111.27-.184.568-.342.81-.363.555-.906.801-1.57.799-1.146-.006-2.29-.002-3.436-.002-3.002 0-6.003-.001-9.004.001-.734 0-1.3-.296-1.692-.915-.187-.295-.252-.625-.252-.971.002-1.56.001-3.119.001-4.677 0-.987-.003-1.973.001-2.96.004-.872.6-1.62 1.44-1.803a2.37 2.37 0 0 1 .49-.046c.753-.005 1.506-.002 2.259-.002h.156c.062-.222.092-.442.183-.632.267-.556.735-.818 1.343-.82C8.28.797 9.796.793 11.312.803c.73.006 1.243.41 1.426 1.084.03.111.037.229.059.366h2.473c.984.002 1.689.58 1.89 1.547.003.014.016.026.024.04v8.179h.001Zm-8.147.788c2.058 0 4.115 0 6.173-.002.136 0 .278-.006.408-.043.495-.139.76-.52.76-1.096.006-2.503.005-5.006-.002-7.51 0-.169-.034-.353-.106-.505-.187-.4-.529-.574-.964-.574h-2.832c-.352 0-.509-.16-.51-.516v-.239c0-.504-.21-.714-.715-.714H6.843c-.058 0-.117 0-.175.004a.559.559 0 0 0-.531.518c-.014.147-.01.296-.011.445-.005.343-.165.502-.51.502-.949 0-1.898-.002-2.847.001-.623.002-1.053.418-1.054 1.03-.005 2.556-.004 5.112.002 7.668 0 .154.036.32.102.459.197.414.555.573.999.573h6.22v-.001Z"
      />
      <path
        fill="#003F5D"
        d="M12.85 7.955c-.025 2.111-1.73 3.81-3.818 3.795A3.795 3.795 0 0 1 5.23 7.927a3.779 3.779 0 0 1 3.828-3.792c2.145.016 3.773 1.717 3.79 3.82h.001Zm-.842.02A2.961 2.961 0 0 0 9.04 4.984a2.964 2.964 0 0 0-2.973 2.97 2.97 2.97 0 0 0 2.97 2.978 2.966 2.966 0 0 0 2.972-2.956ZM14.551 5.627a.756.756 0 0 1-.757-.758.757.757 0 0 1 1.515.002.753.753 0 0 1-.758.757Z"
      />
    </svg>
  );
};

export default CameraIcon;
