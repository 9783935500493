import React from "react";
import Icon from "./Icon";
import styled from "styled-components";
import shortcutsData from "./Mock/mockData";
import HomeIcon from "../../../assets/icons/Home/HomeIcon";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

function ShortcutIcons({ handleIconChange }) {
  return (
    <Wrapper>
      {shortcutsData.map((shortcut) => (
        <Icon
          name={shortcut.name}
          color={shortcut.color}
          backgroundColor={shortcut.backgroundColor}
          link={shortcut.destination}
          image={shortcut.icon}
          handleIconChange={handleIconChange}
        />
      ))}
    </Wrapper>
  );
}

export default ShortcutIcons;
