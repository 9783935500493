import React from "react";

function AddImageIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={57} height={57} fill="none">
      <g filter="url(#a)">
        <rect width={48} height={48} x={3} y={3} fill="#F0F4FC" rx={6.667} />
      </g>
      <path
        fill="#316788"
        d="M16.477 31.087c.813-.98 1.578-1.909 2.35-2.831 1.326-1.585 3.571-1.584 4.9 0 .336.4.665.805 1.018 1.233l.285-.321c.945-1.08 1.888-2.162 2.835-3.24 1.381-1.574 3.588-1.573 4.972.004 1.42 1.618 2.834 3.24 4.25 4.86.093.107.188.212.344.389v-.363c0-2.083-.002-4.164.003-6.246 0-.158.011-.323.06-.471.12-.362.52-.607.873-.558a.854.854 0 0 1 .752.803c.012.265.004.532.004.797 0 2.755.002 5.51 0 8.266-.002 2.646-1.77 4.812-4.365 5.335-.345.07-.703.094-1.056.095-4.509.006-9.017.013-13.526 0-2.652-.008-4.791-1.77-5.314-4.354a5.41 5.41 0 0 1-.097-1.055c-.006-4.501-.007-9.002-.002-13.503.003-2.431 1.47-4.495 3.723-5.191a5.91 5.91 0 0 1 1.668-.259c2.994-.024 5.988-.012 8.982-.01.46 0 .783.223.893.605.108.373-.033.78-.38.957-.181.092-.406.132-.61.133-2.941.007-5.883.002-8.823.007-1.718.002-3.138 1.04-3.6 2.646a3.993 3.993 0 0 0-.147 1.072c-.011 3.642-.007 7.283-.007 10.924 0 .069.007.137.015.275Zm10.435 6.054c2.25 0 4.5.005 6.75-.001 1.916-.006 3.446-1.367 3.673-3.264a.442.442 0 0 0-.096-.304c-1.93-2.207-3.861-4.413-5.808-6.606a1.657 1.657 0 0 0-.696-.457c-.625-.202-1.165-.011-1.597.484-1.228 1.409-2.458 2.817-3.688 4.225-.517.59-1.035.589-1.53-.006-.499-.6-.997-1.2-1.493-1.8-.68-.823-1.715-.82-2.392.006-1.09 1.328-2.172 2.663-3.28 3.978-.232.276-.282.524-.203.861.405 1.71 1.855 2.876 3.637 2.882 2.241.008 4.483.002 6.723.002Z"
      />
      <path
        fill="#316788"
        d="M34.03 17.869c0-.812 0-1.581.002-2.351 0-.115 0-.231.02-.344.08-.455.45-.736.913-.702a.82.82 0 0 1 .761.81c.012.753.005 1.506.006 2.259v.314c.117.005.21.013.304.013.727.001 1.452-.002 2.179.001.551.003.917.347.917.848-.002.497-.374.847-.921.851-.717.006-1.434.002-2.152.002h-.327v.304c0 .727.003 1.453-.002 2.18-.003.54-.367.924-.854.916-.475-.007-.839-.39-.843-.91-.007-.815-.002-1.63-.002-2.477-.105-.005-.198-.013-.29-.013-.735-.001-1.47.008-2.205-.003-.62-.009-1.022-.52-.868-1.084.101-.37.414-.609.831-.613.735-.006 1.47-.002 2.204-.002h.327ZM18.166 20.997a3.105 3.105 0 0 1 3.086-3.128c1.725-.008 3.13 1.365 3.143 3.074.015 1.733-1.37 3.147-3.09 3.157a3.11 3.11 0 0 1-3.139-3.103Zm1.703-.032a1.406 1.406 0 0 0 1.398 1.434 1.421 1.421 0 0 0 1.428-1.385 1.41 1.41 0 0 0-1.39-1.442 1.396 1.396 0 0 0-1.436 1.393Z"
      />
      <defs>
        <filter
          id="a"
          width={56}
          height={56}
          x={0.333}
          y={0.333}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius={0.667}
            result="effect1_dropShadow_189_2256"
          />
          <feOffset dx={1.333} dy={1.333} />
          <feGaussianBlur stdDeviation={1.667} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_189_2256"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_189_2256"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default AddImageIcon;
