import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import NewAppleIcon from "../../../../../../assets/images/Activities/Common/NewAppleIcon";
import GreenDoubleArrowIcon2 from "../../../../../../assets/icons/GreenDoubleArrowIcon2";
import { useContext } from "react";
import { LanguageContext } from "../../../../../../store/languageContext";
import { LANGUAGES } from "../../../../../Common/Layout/Header/constant";
import GrayDoubleArrowIcon from "../../../../../../assets/icons/GrayDoubleArrowIcon";

import useMediaQueryConstants from "../../../../../../customHooks/useMediaQueryConstants";

const LinkWrapper = styled.div`
  font-weight: 600;
  padding: 20px 0;
  font-family: "NotoSans-600";
  width: 100%;
  color: #316788;
  display: flex;
  flex-direction: ${(props) =>
    props.AreIconsOnTheSameLine ? "row" : "column"};
  gap: ${(props) => (props.AreIconsOnTheSameLine ? "10px" : "0")};
  box-sizing: border-box;
  cursor: pointer;
`;
const TitlesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
`;
const IconAndCreateWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.AreIconsOnTheSameLine ? "space-between" : "flex-end"};
  align-items: center;
  padding: 0 5px;
  height: 25px;
  min-width: ${(props) => (props.AreIconsOnTheSameLine ? "43%" : "auto")};
  display: ${(props) =>
    !props.length && props.AreIconsOnTheSameLine ? "none" : "auto"};
`;
const TitleEN = styled.h3`
  color: #316788;
  text-align: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "22px")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.223px;
  margin: 0;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  @media (max-width: 390px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "22px")};
  }
`;
const PreviousTitle = styled.h3`
  color: #316788;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.223px;
  margin: 0;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  @media (max-width: 390px) {
    font-size: 22px;
  }
`;
const TitleHEB = styled.h3`
  color: #316788;
  text-align: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "22px")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.223px;
  margin: 0 0 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  @media (max-width: 390px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "22px")};
  }
`;
const Divider = styled.h3`
  margin: 0 5px;
`;
const DArrowIconWrapper = styled.div`
  height: 100%;
  width: 13px;
  display: flex;
  align-items: center;
`;
const GreenAppleIconWrapper = styled.div`
  height: 100%;
  width: 16px;
  display: flex;
  align-items: center;
`;
const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 3px;
`;
const CreateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Create = styled(Link)`
  color: #316788;
  font-size: 13.974px;
  font-weight: 600;
  text-decoration-line: underline;
  margin-left: 15px;
`;
const Image = styled.img`
  margin-right: 5px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 3px;
  height: 100%;
`;
function Page(props) {
  const {
    title,
    length,
    link,
    previousTitle,
    hebrewTitle,
    englishTitle,
    sideLink,
    AreIconsOnTheSameLine,
    islogedIn,
    showDivider = true,
    icon,
    showIcon,
    fontSize,
    underline = false,
  } = props;
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { currentLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();

  const getCurrentTitle = () => {
    if (currentLanguage === LANGUAGES.HEBREW.value) return hebrewTitle;
    if (currentLanguage === LANGUAGES.ENGLISH.value) return englishTitle;
    else return hebrewTitle;
  };
  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <LinkWrapper
      onClick={handleClick}
      to={link ? link : null}
      AreIconsOnTheSameLine={AreIconsOnTheSameLine}
      islogedIn={islogedIn}
    >
      <TitlesWrapper
        hasTwoTitles={hebrewTitle && title}
        underline={underline || link}
        isWideScreen={isWideScreen || isMediumScreen}
      >
        {icon && icon}
        {showIcon && <Image src={showIcon} />}
        <TitleEN fontSize={fontSize}>{title}</TitleEN>
        {showDivider && title && <Divider>-</Divider>}
        {hebrewTitle && (
          <>
            <PreviousTitle underline={underline || link}>
              {previousTitle}{" "}
            </PreviousTitle>
            <TitleHEB fontSize={fontSize} underline={underline || link}>
              {getCurrentTitle()}
            </TitleHEB>
          </>
        )}
        {link && (
          <IconWrapper>
            <GrayDoubleArrowIcon width={"12"} height={"14"} color="#316788" />
          </IconWrapper>
        )}
      </TitlesWrapper>
      <IconAndCreateWrapper
        AreIconsOnTheSameLine={AreIconsOnTheSameLine}
        length={length}
      >
        {length && (
          <IconsWrapper>
            <GreenAppleIconWrapper>
              <NewAppleIcon text={length} />
            </GreenAppleIconWrapper>
            <DArrowIconWrapper>
              <GreenDoubleArrowIcon2 />
            </DArrowIconWrapper>
          </IconsWrapper>
        )}
        {sideLink && (
          <CreateWrapper>
            <Create to={sideLink.link}>{sideLink.title}</Create>
          </CreateWrapper>
        )}
      </IconAndCreateWrapper>
    </LinkWrapper>
  );
}

export default Page;
