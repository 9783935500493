import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(false);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isAuthenticated,
        setIsAuthenticated,
        installPrompt,
        setInstallPrompt,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
