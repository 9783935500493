import React from "react";

const NavbarTogglerIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "26"}
      height={height ?? "20"}
      fill="none"
      viewBox="0 0 26 20"
    >
      <g stroke={color ?? "#000"} strokeWidth="2">
        <path d="M0 1.789L26 1.789"></path>
        <path d="M0 10L26 10"></path>
        <path d="M0 18.211L26 18.211"></path>
      </g>
    </svg>
  );
};

export default NavbarTogglerIcon;
