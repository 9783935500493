import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ReturnArrow from "../../../Common/Layout/ReturnArrow/ReturnArrow";
import { useMediaQuery } from "react-responsive";
import InfiniteSlider from "../../../../kit/InfiniteSlider/InfiniteSlider";
import coverImage1 from "../../../../domain/Ecosystem/Zone/Common/CardDetailsPage/Mock/feedbackCover1.png";
import coverImage2 from "../../../../domain/Ecosystem/Zone/Common/CardDetailsPage/Mock/feedbackCover2.png";
import coverImage3 from "../../../../domain/Ecosystem/Zone/Common/CardDetailsPage/Mock/feedbackCover3.png";

const Cover = styled.div`
  width: ${(props) => props.width ?? "auto"};
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 330px;
  height: 300px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const LogoRightButtonWrapper = styled.div`
  position: absolute;
  right: 19px;
  bottom: 12.5px;
`;
const LogoWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${(props) => props.background ?? "#fafffa"};
  height: 70.416px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px 0px 0px 0px;
  border-top: 23px solid #7fb383;
`;
const BorderText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 50px;
  width: calc(100% - 25px);
  top: 210px;
  margin-left: 25px;
  color: #fff;
  font-family: "Bogart-trial-700";
  font-size: 19px;
  line-height: 12.326px; /* 64.876% */
  letter-spacing: 0.19px;
`;
const IconTitle = styled.div`
  position: absolute;
  top: 265px;
  right: ${(props) => (props.xs ? "40px" : props.sm ? "50px" : "30px")};
  color: #7fb383;
  font-family: "InknutAntiqua-700";
  font-size: 16px;
  font-weight: 700;
  width: 300px;
  max-width: ${(props) => (props.xs ? "125px" : props.sm ? "175px" : "200px")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const CoverPhoto = ({
  image,
  multipleImages,
  logo,
  logoTitle,
  onClick,
  logoRightButton,
  coverWidth,
  hideReturnArrow,
  bgColor,
}) => {
  const navigate = useNavigate();
  const xsScreen = useMediaQuery({ maxWidth: 400 });
  const smScreen = useMediaQuery({ minWidth: 420, maxWidth: 768 });
  return (
    <Cover width={coverWidth}>
      {!hideReturnArrow && (
        <ReturnArrow
          forceShowArrow
          onClickOverride={() => (onClick ? onClick() : navigate(-1))}
        />
      )}
      <ImageWrapper>
        {!multipleImages && <Image src={image} />}
        {multipleImages && (
          <InfiniteSlider
            noHeightStyles
            images={[coverImage1, coverImage2, coverImage3]}
          />
        )}
        <LogoWrapper background={bgColor}>{logo}</LogoWrapper>
        <BorderText>
          <div>PILOT</div>
          <div>PILOT</div>
          <div>PILOT</div>
        </BorderText>
        {logoTitle && (
          <IconTitle xs={xsScreen} sm={smScreen}>
            {logoTitle}
          </IconTitle>
        )}
        {logoRightButton && (
          <LogoRightButtonWrapper>{logoRightButton}</LogoRightButtonWrapper>
        )}
      </ImageWrapper>
    </Cover>
  );
};
export default CoverPhoto;
