import React from "react";

function EmergencyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.143}
        d="M7.464 13.813a9.55 9.55 0 0 1 9.603-9.563c5.259.04 9.47 4.41 9.47 9.682v.943c0 4.755.995 7.517 1.872 9.031a1.063 1.063 0 0 1-.917 1.594H6.508a1.062 1.062 0 0 1-.916-1.594c.876-1.514 1.872-4.276 1.872-9.03v-1.063ZM12.75 25.5v1.063a4.25 4.25 0 0 0 8.5 0V25.5M24.197 1.478a13.945 13.945 0 0 1 5.047 5.591M4.436 7.07a13.945 13.945 0 0 1 5.046-5.592"
      />
    </svg>
  );
}

export default EmergencyIcon;
