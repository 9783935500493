import React from "react";

const SoundIcon = ({ color, size = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 30 29"
    >
      <g fill={color ?? "#2A8A31"} clipPath="url(#clip0_7209_31682)">
        <path d="M0 19.075c.162.65.467 1.214 1.108 1.485.33.14.712.211 1.072.218 1.386.024 2.773.004 4.16.018.176.001.386.07.523.178 3.116 2.475 6.224 4.96 9.332 7.444.315.253.642.443 1.07.314.42-.128.691-.455.723-.89.007-.097.006-.195.006-.292 0-8.497 0-16.993.004-25.49 0-.375-.047-.719-.348-.98-.402-.35-.907-.325-1.41.075-1.198.954-2.393 1.911-3.59 2.866-1.935 1.547-3.869 3.094-5.81 4.632a.83.83 0 01-.474.16c-1.405.012-2.811.002-4.217.008-1.111.004-1.785.517-2.1 1.58-.014.045-.033.09-.049.134V19.075zm15.986 6.603c-.088-.065-.137-.097-.181-.133-2.546-2.033-5.094-4.064-7.633-6.106-.099-.08-.163-.26-.163-.394-.01-2.827-.006-5.653-.01-8.479 0-.192.053-.319.209-.442 2.505-1.992 5.003-3.99 7.504-5.985.08-.064.165-.12.274-.2v21.739zM5.976 10.832v7.941H2.02v-7.941h3.956z"></path>
        <path d="M30 13.927c-.077-.547-.135-1.099-.234-1.643-.52-2.842-1.82-5.278-3.86-7.323-.416-.418-1.015-.42-1.418-.033a.98.98 0 00-.01 1.415c1.042 1.072 1.897 2.272 2.499 3.642a11.75 11.75 0 01.992 5.606 11.775 11.775 0 01-1.293 4.661 12.743 12.743 0 01-2.072 2.886 1.604 1.604 0 00-.393.69c-.1.427.138.827.512 1.021.393.204.864.155 1.179-.205.62-.711 1.262-1.414 1.791-2.191 1.341-1.97 2.079-4.166 2.259-6.546.005-.076.032-.15.049-.226v-1.755l-.001.001z"></path>
        <path d="M25.994 14.546c-.03-2.452-.98-4.8-2.892-6.752-.41-.42-1.01-.44-1.42-.06-.405.376-.438.981-.035 1.412.67.718 1.237 1.498 1.656 2.39 1.29 2.75.74 6.342-1.33 8.568-.126.136-.254.27-.37.414a.99.99 0 00.087 1.37c.401.367 1.014.365 1.394-.054.437-.483.878-.973 1.242-1.51 1.12-1.659 1.657-3.507 1.667-5.778z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7209_31682">
          <path
            fill="#fff"
            d="M0 0H30V27.938H0z"
            transform="matrix(1 0 0 -1 0 28.773)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SoundIcon;
