import React from "react";

const YellowStarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        stroke="#FFC700"
        strokeLinejoin="round"
        strokeWidth="1.508"
        d="M8.886 1l2.34 5.071 5.546.658-4.1 3.792 1.088 5.478-4.874-2.728L4.012 16l1.089-5.478L1 6.729l5.547-.658L8.886 1z"
      ></path>
    </svg>
  );
};

export default YellowStarIcon;
