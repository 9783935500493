import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TaskDeletedWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const TaskDeletedText = styled.p`
  color: #ef7300;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.44px;
  display: flex;
  justify-content: space-between;
`;
const ButtonsGroup = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin} 0;
`;
const ButtonEl = styled.button`
  width: ${(props) => props.width};
  text-align: center;
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius};
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  font-family: Assistant;
  font-size: ${(props) => props.fontSize};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.51px;
  background-color: ${(props) =>
    props.backGroundColor ? props.backGroundColor : "white"};
  cursor: pointer;
`;

const TaskDeletedPage = ({ dataType = "task" }) => {
  const navigate = useNavigate();
  return (
    <TaskDeletedWrapper>
      <TaskDeletedText>
        {`${dataType.substring(0, 1)?.toUpperCase()}${dataType.substring(
          1
        )} deleted successfully!`}{" "}
      </TaskDeletedText>
      <ButtonsGroup justifyContent="center" gap="19px" margin="40px">
        <ButtonEl
          width="245px"
          padding="10px"
          borderRadius="30px"
          borderColor="#28B5B5"
          color="white"
          fontSize="17px"
          backGroundColor="#28B5B5"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to private zone
        </ButtonEl>
      </ButtonsGroup>
    </TaskDeletedWrapper>
  );
};

export default TaskDeletedPage;
