import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  NO_GOING_BACK_ROUTES,
  NO_GOING_BACK_ENDS_WITH_ROUTES,
  NO_GOING_BACK_INCLUDES_ROUTES,
  SHOULD_GO_BACK_ENDS_WITH,
} from "./constant";
import BackButton from "../../../../kit/Button/BackButton";
import useRegion from "../../../../customHooks/useRegion";

const ArrowButton = styled.div`
  position: fixed;
  top: 12.5px;
  left: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: ${({ zIndex }) => zIndex ?? "903"};
  display: ${({ canGoBack }) => (canGoBack ? "block" : "none")};
  margin-top: ${({ marginTop }) => marginTop ?? "0"};
`;

const ReturnArrow = ({
  onClickOverride,
  forceShowArrow,
  icon,
  marginTop,
  zIndex,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAbstractEmergency } = useRegion();

  const canGoBack = useMemo(() => {
    if (forceShowArrow) return true;
    const path = location.pathname;

    const isIncluded = (routes) => routes.some((route) => path.includes(route));
    const endsWith = (routes) => routes.some((route) => path.endsWith(route));

    const ecosystemRegex = /\/activities\/together\/([a-zA-Z0-9\-_]+)$/;

    return (
      (!NO_GOING_BACK_ROUTES.includes(path) &&
        !endsWith(NO_GOING_BACK_ENDS_WITH_ROUTES) &&
        !isIncluded(NO_GOING_BACK_INCLUDES_ROUTES) &&
        !ecosystemRegex.test(path)) ||
      endsWith(SHOULD_GO_BACK_ENDS_WITH)
    );
  }, [forceShowArrow, location.pathname]);
  const canBackToCustomLink = (cases) => {
    const linkToArray = location.pathname.split("/");
    return cases.some((l) => l === linkToArray[linkToArray.length - 1]);
  };
  const adminCases = ["requests", "messages", "invite", "community"];
  const handleBack = () => {
    // Custom case
    if (canBackToCustomLink(adminCases)) {
      navigate(isAbstractEmergency ? "/profile" : "/");
    } else if (canGoBack) {
      navigate(-1);
    }
  };

  return (
    <ArrowButton
      canGoBack={canGoBack}
      onClick={onClickOverride ?? handleBack}
      marginTop={marginTop}
      id={"Go_Back_Arrow"}
      zIndex={zIndex}
    >
      {icon ? icon : <BackButton />}
    </ArrowButton>
  );
};

export default ReturnArrow;
