import React, { useContext } from "react";
import styled from "styled-components";
import { TaskZone } from "../../../../../customHooks/useFetchTasks";
import BigApplesSlider from "../BigApplesSlider/BigApplesSlider";
import SearchPart from "../SearchPart/SearchPart";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import Navbar from "../../../../Common/Layout/Navbar/components/Navbar";
import { UserContext } from "../../../../../store/userContext";
import {
  COLLAPSIBLE_APPLE_SLIDER,
  NO_APPLES_AND_SEARCH_SECTION,
} from "../../../View/constant";

const ApplesAndSearchWrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "100%" : "100%")};
  margin: ${({ margin }) => margin ?? "auto"};
  box-sizing: border-box;
  padding: ${({ isWideScreen }) => (isWideScreen ? "0 2%" : "0")};
  display: ${({ isWideScreen }) => (isWideScreen ? "flex" : "block")};
  gap: ${({ isWideScreen }) => isWideScreen && "20px"};
  flex-direction: ${({ isWideScreen }) =>
    isWideScreen ? "row-reverse" : "row"};
  justify-content: ${({ isWideScreen }) =>
    isWideScreen ? "space-between" : "start"};
  background: ${({ isWideScreen, background }) =>
    background ?? (isWideScreen ? "rgba(127, 179, 131, 0.1)" : "none")};
  direction: ${({ isWideScreen }) => isWideScreen && "rtl"};
  @media (min-width: 1024px) {
    align-items: center;
  }
`;
const BigApplesSliderWrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "50%" : "100%")};
  display: ${({ isWideScreen }) => (isWideScreen ? "flex" : "block")};
  flex-direction: column;
`;
const SearchPartWrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "50%" : "90%")};
  margin: 0 auto;
`;
const TitlesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
`;
const Title = styled.p`
  /* margin: 20px 0 10px 0; */
  margin: 0;
  font-family: "NotoSans-600";
  font-weight: 600;
  font-size: 20px;
  line-height: 27.57px;
  color: ${({ color }) => color ?? "black"};
`;
const ApplesAndSearchSection = ({
  zone,
  hideSearch,
  hasApples = true,
  isVT,
  margin,
  backgroundColor,
}) => {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { isAuthenticated } = useContext(UserContext);

  if (NO_APPLES_AND_SEARCH_SECTION.includes(zone)) {
    return null;
  }

  return (
    <ApplesAndSearchWrapper
      background={backgroundColor}
      isWideScreen={isWideScreen}
      margin={margin}
    >
      {!hideSearch && (
        <SearchPartWrapper isWideScreen={isWideScreen}>
          <SearchPart
            zone={zone ?? TaskZone.TelAvivCity}
            isVT={isVT}
            hideMapBtn={isVT}
            boxShadow={isVT && "none"}
          />
        </SearchPartWrapper>
      )}
      {isAuthenticated && (isWideScreen || isMediumScreen) && (
        <div
          style={{
            width: "40%",
            minWidth: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <TitlesWrapper>
            <Title color={"#003F5D"}>Volunteer Ecosystem</Title>
            <Title color={"#7FB383"}>איזור אישי</Title>
          </TitlesWrapper>
          <Navbar />
        </div>
      )}
      {!isAuthenticated && hasApples && (
        <BigApplesSliderWrapper isWideScreen={isWideScreen}>
          <BigApplesSlider
            isCollapsible={Object.keys(COLLAPSIBLE_APPLE_SLIDER).includes(zone)}
            collapsedByDefault={COLLAPSIBLE_APPLE_SLIDER[zone] ?? false}
          />
        </BigApplesSliderWrapper>
      )}
    </ApplesAndSearchWrapper>
  );
};

export default ApplesAndSearchSection;
