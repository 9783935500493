import React from "react";
import styled from "styled-components";
import appleImage from "../../../../../../assets/icons/Common/Layout/greenApple.png";
import GreenAppleIcon from "../../../../../../assets/icons/Common/Apples/GreenAppleIcon";

const AppleWrapper = styled.div`
  width: ${(props) =>
    props.isActive
      ? props.isPersonIconClicked
        ? "83px"
        : "73px"
      : props.isPersonIconClicked
      ? "82px"
      : "68px"};
  height: ${(props) =>
    props.isActive
      ? props.isPersonIconClicked
        ? "68px"
        : "82px"
      : props.isPersonIconClicked
      ? "62px"
      : "72px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  border-radius: 7.958px;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isActive ? "45.2px" : "40px")};
  height: ${(props) => (props.isActive ? "50px" : "44px")};
  position: relative;
`;
const AppleImage = styled.img`
  width: 100%;
  height: 100%;
`;
const TextWrapper = styled.div`
  border-radius: 4.714px;
  text-align: center;
  display: flex;
  gap: 1px;
  justify-content: center;
  align-items: center;
  width: 80px;
`;
const Text = styled.div`
  color: ${(props) => props.color ?? "#316788"};
  text-align: center;
  font-size: ${(props) => (props.isActive ? "17.524px" : "15.4px")};
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

function Apple(props) {
  const {
    data,
    index,
    handleAppleClick = "",
    isActive,
    handleClick,
    isPersonIconClicked,
  } = props;
  const onClick = () => {
    handleAppleClick && handleAppleClick(index);
    handleClick && handleClick(index);
  };
  return (
    <AppleWrapper
      onClick={() => onClick()}
      isActive={isActive}
      isPersonIconClicked={isPersonIconClicked}
    >
      <IconWrapper isActive={isActive}>
        <GreenAppleIcon
          width={isActive ? "45" : "38"}
          height={isActive ? "50" : "43"}
          color={data === "מצוקה" ? "#EF7300" : undefined}
        />
      </IconWrapper>
      <TextWrapper>
        <Text
          color={data === "מצוקה" ? "#EF7300" : undefined}
          isActive={isActive}
        >
          {data}
        </Text>
      </TextWrapper>
    </AppleWrapper>
  );
}

export default Apple;
