export const ROUTES = {
  EMERGENCY: "",
  BROTHERS_IN_ARMS: "brothers-in-arms",
  KAPLAN_FORCE: "kaplan-force",
};

export const PUBLIC_EMERGENCY = [
  {
    name: "תל אביב והסביבה",
    route: `/emergency/${ROUTES.EMERGENCY}`,
  },
  {
    name: "אחים לנשק",
    route: `/emergency/${ROUTES.BROTHERS_IN_ARMS}`,
  },
  {
    name: "כוח קפלן",
    route: `/emergency/${ROUTES.KAPLAN_FORCE}`,
  },
  {
    name: "גדרות והדרום",
    route: null,
  },
  {
    name: "הרצליה",
    route: null,
  },
  {
    name: "רמת השרון",
    route: null,
  },
  {
    name: "ירושלים והסביבה",
    route: null,
  },
  {
    name: "חיפה והצפון",
    route: null,
  },
];

export const PRIVATE_EMERGENCY = [
  {
    name: "תל אביב והסביבה",
    route: `/emergency/private/${ROUTES.EMERGENCY}`,
  },
  {
    name: "אחים לנשק",
    route: `/emergency/private/${ROUTES.BROTHERS_IN_ARMS}`,
  },
  {
    name: "כוח קפלן",
    route: `/emergency/private/${ROUTES.KAPLAN_FORCE}`,
  },
  {
    name: "גדרות והדרום",
    route: null,
  },
  {
    name: "הרצליה",
    route: null,
  },
  {
    name: "רמת השרון",
    route: null,
  },
  {
    name: "ירושלים והסביבה",
    route: null,
  },
  {
    name: "חיפה והצפון",
    route: null,
  },
];

export const BrothersInArms = {
  headLines: [
    {
      english: "Volunteers on the home front",
      hebrew: "מתנדבים בעורף",
      both: "",
    },
    {
      english: "Volunteers at the front",
      hebrew: "עזרה בחזית ",
      both: "",
    },
    {
      english: "Medical assistance",
      hebrew: "עזרה רפואית ",
      both: "",
    },
    {
      english: "Firefighting & Rescue",
      hebrew: "כיבוי והצלה      ",
      both: "",
    },
    {
      english: "Guarding in the settlements",
      hebrew: "שמירה בישובים      ",
      both: "",
    },
    {
      english: "Activities for parents & children",
      hebrew: "פעילויות להורים וילדים      ",
      both: "",
    },
    //
    {
      english: "Hosting families",
      hebrew: "אירוח משפחות      ",
      both: "",
    },
    {
      english: "Animal assistance",
      hebrew: "סיוע לבעלי חיים      ",
      both: "",
    },
  ],
};
