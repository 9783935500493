import React from "react";
import styled from "styled-components";
import { STEPS_COLORS, STEPS_VALUES } from "./constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 15px 0;
  align-items: center;
  width: 100%;
  position: relative;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StepCircle = styled.div`
  width: ${(props) =>
    props.status === STEPS_VALUES.finished ||
    props.status === STEPS_VALUES.pending
      ? "25px"
      : "20px"};
  height: ${(props) =>
    props.status === STEPS_VALUES.finished ||
    props.status === STEPS_VALUES.pending
      ? "25px"
      : "20px"};
  border-radius: 50%;
  background-color: ${(props) =>
    props.status === STEPS_VALUES.finished ||
    props.status === STEPS_VALUES.pending
      ? props.finishedColor
      : STEPS_COLORS[props.status] || "#c4c4c4"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: width 0.3s, height 0.3s;
  z-index: 1;
`;

const StepLine = styled.div`
  height: 5px;
  background-color: ${(props) => props.color || "#58656D"};
  position: absolute;
  width: 95%;
  margin-bottom: 30px;
  margin-right: 2.5%;
`;


const StepText = styled.div`
  margin-top: 5px;
  font-size: 10px;
  font-weight: 500;
  font-family: Assistant;
  color: #003f5d;
  text-align: center;
`;

const Steps = ({ steps, color = STEPS_COLORS[STEPS_VALUES.finished] }) => {
  return (
    <Wrapper>
      {steps.map((step, index) => (
        <StepItem key={index}>
          <StepCircle status={step.status} finishedColor={color}></StepCircle>
          <StepText>
            {step.texts.map((text, idx) => (
              <div key={idx}>{text}</div>
            ))}
          </StepText>
        </StepItem>
      ))}
      <StepLine />
    </Wrapper>
  );
};

export default Steps;
