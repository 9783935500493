import React from "react";

const LeftGreenArrow = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "16"}
      height={height ?? "29"}
      fill="none"
      viewBox="0 0 16 29"
    >
      <path
        fill={color ?? "#7FB383"}
        d="M1.95 28.26c-.794-.04-1.395-.399-1.74-1.12-.341-.72-.264-1.418.208-2.06.129-.175.289-.325.442-.48 3.306-3.3 6.61-6.608 9.931-9.896.305-.302.265-.437-.012-.712C7.429 10.668 4.09 7.329.749 3.997c-.567-.564-.763-1.234-.55-2C.406 1.247.922.774 1.676.603c.74-.166 1.366.071 1.9.608C5.692 3.335 7.814 5.45 9.933 7.57c1.736 1.734 3.471 3.468 5.204 5.204 1.03 1.033 1.037 2.143.009 3.172-3.863 3.866-7.73 7.728-11.592 11.595-.447.447-.949.744-1.605.72h.001z"
      ></path>
    </svg>
  );
};

export default LeftGreenArrow;
