export const shouldBlockMapRequest = () =>
  process.env.NODE_ENV === "development";

// userLocation = {lng: "", lat: ""}
export const fetchLocationName = async (userLocation) => {
  if (shouldBlockMapRequest()) {
    console.warn("Request blocked on dev env to save quota.");
    return;
  }
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLocation.lat},${userLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&language=he`
    );
    const data = await response.json();

    if (data.status === "OK" && data.results.length > 0) {
      const locationName = data.results?.[0]?.formatted_address;
      return locationName;
    }
  } catch (error) {
    console.error("Error fetching location name:", error);
    return "";
  }
};

export const getCityCoordinates = async (cityName) => {
  if (shouldBlockMapRequest()) {
    console.warn("Request blocked on dev env to save quota.");
    return;
  }
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        cityName
      )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    const result = await response.json();

    if (result.results.length > 0) {
      const location = result.results[0].geometry.location;
      return { lat: location.lat, lng: location.lng };
    } else {
      console.error("City not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching city coordinates", error);
    return null;
  }
};
