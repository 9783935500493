import React from "react";
import { styled } from "styled-components";

const BackDrop = styled.div`
  z-index: 1700;
  position: fixed;
  width: 100%;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.6;
`;
const Overlay = styled.div`
  z-index: 1800;
  position: ${(props) => props.positionType};

  height: ${(props) => (props.height ? props.height : "80vh")};
  width: ${(props) => (props.width ? props.width : "100%")};
  top: ${(props) => (props.default ? "50%" : props.top)};
  left: ${(props) => (props.default ? "50%" : props.left)};
  transform: ${(props) => (props.default ? "translate(-50%, -50%)" : "none")};
  background-color: ${(props) => props.backgroundColor ?? "none"};
  color: ${(props) => (props.color ? props.color : "black")};
  padding: ${(props) => props.padding ?? "15px 0"};
  position: fixed;
  top: 50%;
  left: 0%;
  transform: translate(0, -50%);
`;
const Modal = ({
  closeModal,
  showModal,
  children,
  width,
  height,
  backGround,
  color,
  positionType,
  inTheCenter = true,
  left,
  top,
  padding,
}) => {
  if (!showModal) return null;
  return (
    <div>
      <BackDrop onClick={closeModal} />
      <Overlay
        width={width}
        height={height}
        backGround={backGround}
        color={color}
        inTheCenter={inTheCenter}
        left={left}
        top={top}
        positionType={positionType}
        padding={padding}
      >
        {children}
      </Overlay>
    </div>
  );
};

export default Modal;
