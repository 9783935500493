import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaThumbsUp, FaHeart, FaSadTear, FaSmile } from "react-icons/fa"; // Import icons from react-icons
import { likePost, unlikePost } from "../../domain/Ecosystem/Board/api";

const ReactionWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ReactionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; // Increased hit area
  height: 30px; // Increased hit area
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const ReactionOptions = styled.div`
  display: flex;
  position: absolute;
  bottom: 35px;
  left: -10px;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px;
  z-index: 10;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: visibility 0.2s, opacity 0.2s;
`;

const ReactionOption = styled.div`
  margin: 0 5px;
  padding: 10px; // Increased hit area
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const reactions = {
  like: { icon: <FaThumbsUp color="#007BFF" />, color: "#007BFF" },
  love: { icon: <FaHeart color="#FF0000" />, color: "#FF0000" },
  sad: { icon: <FaSadTear color="#FFC107" />, color: "#FFC107" },
  happy: { icon: <FaSmile color="#28A745" />, color: "#28A745" },
};

const ReactionButton = ({
  postId,
  userId,
  hasReacted,
  currentReaction,
  refetchLikes,
  canReact,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(currentReaction);
  const [isHolding, setIsHolding] = useState(false);
  const [holdTimeout, setHoldTimeout] = useState(null);
  const reactionRef = useRef(null);

  const handleReactionClick = async (reaction) => {
    setSelectedReaction(reaction);
    setShowOptions(false);
    try {
      await likePost({ user_id: userId, post_id: postId, reaction });
      refetchLikes();
    } catch (ex) {
      console.error("Failed to react to post:", ex);
    }
  };

  const handleButtonClick = async () => {
    if (selectedReaction) {
      try {
        await unlikePost({ user_id: userId, post_id: postId });
        setSelectedReaction(null);
        refetchLikes();
      } catch (ex) {
        console.error("Failed to remove reaction from post:", ex);
      }
    } else {
      handleReactionClick("like");
    }
  };

  const handleMouseDown = () => {
    if (!canReact) return;
    setIsHolding(true);
    const timeout = setTimeout(() => {
      setShowOptions(true);
    }, 250);
    setHoldTimeout(timeout);
  };

  const handleMouseUp = () => {
    if (!canReact) return;
    setIsHolding(false);
    clearTimeout(holdTimeout);
    if (!showOptions) {
      handleButtonClick();
    }
  };

  const handleTouchStart = () => {
    if (!canReact) return;
    setIsHolding(true);
    const timeout = setTimeout(() => {
      setShowOptions(true);
    }, 250);
    setHoldTimeout(timeout);
  };

  const handleTouchEnd = () => {
    if (!canReact) return;
    setIsHolding(false);
    clearTimeout(holdTimeout);
    if (!showOptions) {
      handleButtonClick();
    }
  };

  const handleMouseLeave = () => {
    setIsHolding(false);
    clearTimeout(holdTimeout);
  };

  const handleClickOutside = (event) => {
    if (reactionRef.current && !reactionRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <ReactionWrapper ref={reactionRef} onMouseLeave={handleMouseLeave}>
      <ReactionIconWrapper
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        disabled={!canReact}
      >
        {selectedReaction ? (
          reactions[selectedReaction]?.icon
        ) : hasReacted ? (
          reactions.like.icon
        ) : (
          <FaThumbsUp color="#ABABAB" />
        )}
      </ReactionIconWrapper>
      <ReactionOptions visible={showOptions}>
        {Object.keys(reactions).map((reaction) => (
          <ReactionOption
            key={reaction}
            onClick={() => handleReactionClick(reaction)}
          >
            {reactions[reaction].icon}
          </ReactionOption>
        ))}
      </ReactionOptions>
    </ReactionWrapper>
  );
};

export default ReactionButton;
