import React from "react";

const MessageIcon = ({ color = "#7FB383", width = 29, height = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 29 20"
    >
      <g clipPath="url(#clip0_7539_64336)">
        <path
          fill={color}
          d="M0 2.178c.135-.479.308-.936.665-1.305C1.162.36 1.76.087 2.477.086c7.745 0 15.49 0 23.234.002a2.455 2.455 0 012.482 2.477c.002 4.956.002 9.911 0 14.867a2.456 2.456 0 01-2.482 2.478c-7.735.003-15.47.002-23.206 0-1.21 0-2.175-.78-2.453-1.966-.01-.044-.034-.084-.051-.125V2.177H0zm2.894-.434c.081.09.125.145.175.195l10.32 10.355c.425.427.99.43 1.415.003 3.448-3.457 6.894-6.915 10.34-10.374.046-.047.082-.104.14-.179H2.895zm.008 16.5h22.38l-7.066-6.994c-.71.705-1.458 1.448-2.204 2.194-1.109 1.109-2.72 1.11-3.826.002l-2.04-2.047c-.07-.069-.143-.134-.165-.156-2.36 2.336-4.706 4.655-7.079 7.002zM1.668 2.986v13.97l7.063-6.982-7.063-6.988zm24.86.044l-7.058 6.976 7.059 6.983V3.03z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7539_64336">
          <path
            fill="#fff"
            d="M0 0H28.195V19.826H0z"
            transform="translate(0 .086)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessageIcon;
