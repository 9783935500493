import axios from "axios";
import { CURRENT_DOMAIN } from "../../../../../info";
import requestHandler from "../../../../../store/requestHandler";

export const getAllByZones = async (payload) =>
  await requestHandler("post", `/ecosystems/find-all-by-zones`, payload);

export const getAllTasks = async () =>
  await axios.get(`${CURRENT_DOMAIN}/tasks`);

export const getAllEvents = async () =>
  await axios.get(`${CURRENT_DOMAIN}/events`);

export const getAllActivities = async () =>
  await axios.get(`${CURRENT_DOMAIN}/activities`);

export const getTasksByZone = async (zone) =>
  await axios.get(`${CURRENT_DOMAIN}/tasks/zone/${zone}`);

export const getEventsByZone = async (zone) =>
  await axios.get(`${CURRENT_DOMAIN}/events/zone/${zone}`);

export const getActivitiesByZone = async (zone) =>
  await axios.get(`${CURRENT_DOMAIN}/activities/zone/${zone}`);

export const getTask = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/tasks/${id}`);

export const getEvent = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/events/${id}`);

export const getActivity = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/activities/${id}`);

export const getHub = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/hubs/${id}`);

export const createTask = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/tasks`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createEvent = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/events`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createActivity = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/activities`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createHub = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/hubs`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editTask = async (id, data) =>
  await axios.patch(`${CURRENT_DOMAIN}/tasks/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editEvent = async (id, data) =>
  await axios.patch(`${CURRENT_DOMAIN}/events/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editActivity = async (id, data) =>
  await axios.patch(`${CURRENT_DOMAIN}/activities/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const editHub = async (id, data) =>
  await axios.patch(`${CURRENT_DOMAIN}/hubs/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteTask = async (id) =>
  await axios.delete(`${CURRENT_DOMAIN}/tasks/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteEvent = async (id) =>
  await axios.delete(`${CURRENT_DOMAIN}/events/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteActivity = async (id) =>
  await axios.delete(`${CURRENT_DOMAIN}/activities/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteHub = async (id) =>
  await axios.delete(`${CURRENT_DOMAIN}/hubs/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// dataType: task, event, activity
export const getWaitingListById = async (id, dataType) => {
  switch (dataType) {
    case "task":
      return await axios.get(`${CURRENT_DOMAIN}/waiting_lists/by_task/${id}`);
    case "activity":
      return await axios.get(
        `${CURRENT_DOMAIN}/waiting_lists/by_activity/${id}`
      );
    case "event":
      return await axios.get(`${CURRENT_DOMAIN}/waiting_lists/by_event/${id}`);
    default:
      return;
  }
};

// status: accepted, rejected, pending
export const updateWaitingListUserStatus = async ({
  status,
  phone_number,
  waitingList_id,
}) =>
  await axios.post(
    `${CURRENT_DOMAIN}/waiting_lists/updateUserStatus`,
    { status, phone_number, waitingList_id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const addUserToWaitingList = async (data) =>
  await axios.post(
    `${CURRENT_DOMAIN}/waiting_lists/addUserToWaitingList`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const startTaskForUser = async (payload) =>
  await axios.post(
    `${CURRENT_DOMAIN}/waiting_lists/startTaskForUser`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const endTaskForUser = async (payload) =>
  await axios.post(`${CURRENT_DOMAIN}/waiting_lists/endTaskForUser`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getUserTaskStatus = async (waitingList_id, user_id) =>
  await axios.get(
    `${CURRENT_DOMAIN}/waiting_lists/getUserTaskStatus/${user_id}/${waitingList_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const createEmergency = async (data) =>
  await axios.post(`${CURRENT_DOMAIN}/emergencies`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getEmergencies = async () =>
  await axios.get(`${CURRENT_DOMAIN}/emergencies`);

export const getEmergencyById = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/emergencies/${id}`);

export const createEcosystem = async (body) =>
  await axios.post(`${CURRENT_DOMAIN}/ecosystems`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const EcosystemSectionType = {
  Neighborhood: "neighborhood",
  School: "school",
  Organization: "organization",
};
export const createSectionInEcosystem = async (
  title,
  ecosystemId,
  sectionType
) =>
  await axios.post(
    `${CURRENT_DOMAIN}/ecosystems/createSection`,
    {
      title,
      id: ecosystemId,
      type: sectionType,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const addCommunityToSection = async (
  ecosystemBody,
  sectionId,
  ecosystemId
) =>
  await axios.post(
    `${CURRENT_DOMAIN}/ecosystems/addCommunityToSection`,
    {
      ...ecosystemBody,
      sectionId,
      ecosystemId,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const getEcosystems = async () =>
  await axios.get(`${CURRENT_DOMAIN}/ecosystems`);

export const getEcosystemById = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/ecosystems/${id}`);

export const getEcosystemByParent = async (id) =>
  await axios.get(`${CURRENT_DOMAIN}/ecosystems/parent/${id}`);

export const deleteEcosystem = async (id) =>
  await axios.delete(`${CURRENT_DOMAIN}/ecosystems/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteSectionFromEcosystem = async (sectionId, ecosystemId) =>
  await axios.post(
    `${CURRENT_DOMAIN}/ecosystems/deleteSectionFromEcosystem`,
    {
      sectionId,
      ecosystemId,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const editEcosystem = async (id, data) =>
  await axios.put(`${CURRENT_DOMAIN}/ecosystems/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const likeEntity = async (entityType, body) =>
  await axios.post(`${CURRENT_DOMAIN}/${entityType}/like`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const unlikeEntity = async (entityType, body) =>
  await axios.post(`${CURRENT_DOMAIN}/${entityType}/unlike`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
