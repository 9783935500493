import React from "react";

function GoogleMapIcon({ width = 30, height = 30 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1101_99295)">
        <path
          d="M15.0282 5.18838C15.3202 5.69842 15.5367 6.24056 15.6819 6.80815C15.7833 7.20506 15.8529 7.60981 15.9297 8.01299C15.9625 8.18718 15.9766 8.3645 15.9993 8.54065V9.47853C15.9892 9.49497 15.9727 9.51063 15.97 9.52825C15.9164 9.89385 15.8784 10.2626 15.8095 10.6254C15.6377 11.5289 15.2893 12.3654 14.8129 13.1494C14.3741 13.8724 13.8367 14.5148 13.1873 15.0565C12.8992 15.2969 12.5837 15.5043 12.2807 15.7267C12.0333 15.3838 11.7859 15.0405 11.5389 14.6976C11.5315 14.678 11.524 14.6584 11.5166 14.6389C11.7667 14.3637 12.04 14.1057 12.2631 13.8098C12.8526 13.0281 13.298 12.1685 13.5666 11.2224C13.7529 10.5671 13.8805 9.90246 13.8727 9.21823C13.8687 8.87024 13.8687 8.52147 13.8378 8.17544C13.8104 7.86894 13.7427 7.56558 13.6926 7.26104C13.7635 7.16631 13.8339 7.07119 13.9052 6.97647C14.3299 6.41201 14.717 5.82407 15.0282 5.18799V5.18838Z"
          fill="#7A797F"
        />
        <path
          d="M10.649 16.5409C10.1432 16.6607 9.63829 16.7844 9.13138 16.8983C8.99163 16.9296 8.84524 16.9312 8.70236 16.9492C8.66439 16.9539 8.62838 16.9703 8.59119 16.9809H7.43489C7.34995 16.9703 7.26461 16.9613 7.18006 16.9484C7.11978 16.9394 7.06028 16.9253 7 16.9136C7.0184 16.9054 7.03562 16.8932 7.0548 16.8889C7.45211 16.7993 7.85568 16.7304 8.24634 16.6173C8.91217 16.4247 9.53025 16.1186 10.1237 15.762C10.2485 15.9338 10.3758 16.1037 10.4967 16.2783C10.5543 16.3613 10.5985 16.4528 10.649 16.5405V16.5409Z"
          fill="#2C80C3"
        />
        <path
          d="M0.346961 11.3229C0.251058 10.85 0.137933 10.3799 0.0655171 9.90351C0.0138474 9.56845 0.0024957 9.22555 0.000147077 8.88578C-0.00455017 8.18589 0.103487 7.49813 0.292551 6.82525C0.55912 5.87563 0.989701 5.00507 1.58077 4.21397C2.01605 3.63191 2.521 3.11951 3.09368 2.67132C3.83193 2.09317 4.64064 1.64654 5.54016 1.38232C5.98483 1.25158 6.44399 1.17172 6.89688 1.06995C6.9509 1.05781 7.00648 1.05312 7.06129 1.04529C7.13918 1.06721 7.21629 1.095 7.29536 1.10988C8.18354 1.27741 9.02473 1.57647 9.817 2.01214C9.78217 2.05559 9.74772 2.09943 9.71288 2.14288C9.35981 2.58364 9.13551 3.08389 9.04391 3.63973C9.01338 3.82606 8.98011 4.02413 9.01143 4.20575C9.06584 4.52008 9.17035 4.82579 9.25373 5.13503C9.15665 5.2047 9.06075 5.27594 8.96289 5.34405C6.40954 7.12274 3.8562 8.90144 1.30168 10.6786C0.986569 10.8978 0.665199 11.1084 0.346961 11.3229ZM5.89872 4.89938C5.72688 4.89821 5.55503 4.89507 5.38319 4.89703C5.19609 4.89899 5.00859 4.90408 4.82148 4.91073C4.69035 4.91543 4.62185 5.01407 4.60619 5.11741C4.59562 5.18826 4.63985 5.29512 4.69622 5.34092C4.76864 5.39964 4.87589 5.43487 4.97101 5.44191C5.17495 5.4564 5.38045 5.44622 5.59066 5.44622C5.44347 5.99501 5.04225 6.22909 4.6195 6.28507C3.94427 6.37432 3.28587 5.73745 3.40409 5.00115C3.49686 4.423 3.9431 4.048 4.52555 4.03822C4.80856 4.03352 5.05008 4.15722 5.27555 4.31849C5.41764 4.41987 5.53233 4.3956 5.64702 4.25194C5.72492 4.15487 5.70026 4.01825 5.58165 3.91413C5.16634 3.55049 4.67391 3.46829 4.15173 3.55792C3.78339 3.62095 3.48942 3.82567 3.24673 4.11611C2.92379 4.50246 2.80832 4.94909 2.88543 5.4286C2.96607 5.92847 3.24712 6.32382 3.68631 6.58413C4.29147 6.94307 4.89116 6.88475 5.44661 6.51328C5.84939 6.24397 6.06703 5.84353 6.15315 5.36989C6.20208 5.10058 6.14062 4.96866 5.94021 4.90877C5.92651 4.90564 5.91242 4.90251 5.89872 4.89938Z"
          fill="#3EA459"
        />
        <path
          d="M10.1242 15.7624C9.53074 16.119 8.91266 16.4251 8.24683 16.6177C7.85617 16.7308 7.45299 16.7997 7.05529 16.8894C7.03611 16.8937 7.01889 16.9058 7.00049 16.914C6.78207 16.8835 6.56012 16.8671 6.34522 16.8201C5.5784 16.6518 4.84759 16.3825 4.15826 16.0047C2.90371 15.3174 1.91141 14.3536 1.15555 13.1421C1.1395 13.1163 1.12971 13.0862 1.11719 13.0576C1.37514 12.8744 1.63467 12.6932 1.89145 12.508C2.90332 11.7784 3.91401 11.0464 4.92626 10.3175C5.22062 10.1054 5.52007 9.90026 5.81717 9.69202C5.88137 9.78557 5.94439 9.8803 6.01015 9.97268C6.87719 11.1877 7.74461 12.4023 8.61204 13.617C9.11543 14.3215 9.6192 15.0261 10.1226 15.7303C10.1226 15.7409 10.123 15.7511 10.1234 15.7616L10.1242 15.7624Z"
          fill="#4898D3"
        />
        <path
          d="M9.25513 5.13438C9.17176 4.82514 9.06764 4.51943 9.01283 4.2051C8.98113 4.02348 9.0144 3.82541 9.04532 3.63909C9.13692 3.08324 9.36082 2.58299 9.71429 2.14223C9.74913 2.09878 9.78397 2.05533 9.8188 2.01149C10.0591 1.81303 10.2995 1.61496 10.5398 1.4165C10.9747 1.16912 11.435 1.03055 11.9408 1.0008C12.7973 0.950693 13.5414 1.19378 14.1951 1.74492C14.6331 2.11405 14.9388 2.57124 15.1236 3.10634C15.3479 3.75495 15.3471 4.40513 15.0746 5.04513C15.0551 5.09093 15.0453 5.14103 15.0308 5.18918C14.72 5.82487 14.3325 6.41281 13.9078 6.97726C13.8365 7.07199 13.7661 7.16711 13.6952 7.26184C13.6494 7.31742 13.6044 7.37379 13.557 7.42781C13.1621 7.87992 12.7698 8.33477 12.3686 8.78179C12.2371 8.92819 12.0829 8.92466 11.9455 8.78336C11.7576 8.58999 11.5701 8.39544 11.3998 8.1872C10.9645 7.65445 10.5387 7.11387 10.1089 6.57604C9.98086 6.38071 9.84503 6.18969 9.72721 5.98849C9.5628 5.70783 9.41171 5.41934 9.25474 5.13398L9.25513 5.13438ZM12.1459 5.16608C12.9014 5.18879 13.5539 4.57188 13.5582 3.80192C13.5633 2.94702 12.9241 2.36731 12.1526 2.3677C11.3532 2.36848 10.7798 3.00143 10.7539 3.70132C10.7218 4.57149 11.4268 5.18252 12.1459 5.16608Z"
          fill="#CB2F27"
        />
        <path
          d="M9.25188 5.13367C9.40885 5.41902 9.55994 5.70751 9.72435 5.98817C9.84217 6.18937 9.978 6.38 10.106 6.57572C9.4112 7.07989 8.71522 7.58328 8.0216 8.08902C7.75072 8.28669 7.48376 8.49024 7.21523 8.69144C7.19175 8.70123 7.16513 8.70671 7.14478 8.72119C6.88956 8.90282 6.63434 9.08444 6.38108 9.2692C6.19123 9.40777 6.00452 9.55065 5.81624 9.69196C5.51914 9.9002 5.21969 10.1053 4.92533 10.3175C3.91268 11.0463 2.90238 11.7783 1.89051 12.508C1.63373 12.6931 1.3746 12.8743 1.11625 13.0575C0.909571 12.6324 0.697803 12.2097 0.498561 11.7811C0.430843 11.6354 0.395222 11.4753 0.344727 11.3219C0.662965 11.1074 0.984335 10.8968 1.29944 10.6776C3.85396 8.90047 6.40731 7.12178 8.96065 5.34309C9.05851 5.27498 9.15481 5.20373 9.25149 5.13406L9.25188 5.13367Z"
          fill="#FFCF05"
        />
        <path
          d="M10.5392 1.41546C10.2989 1.61392 10.0586 1.81199 9.81822 2.01045C9.02595 1.57478 8.18514 1.27572 7.29658 1.10818C7.21751 1.09331 7.1404 1.06552 7.0625 1.0436C7.50248 1.03029 7.94323 0.993883 8.38243 1.00876C9.11989 1.03381 9.8421 1.16572 10.5396 1.41507L10.5392 1.41546Z"
          fill="#378C4E"
        />
        <path
          d="M7.21777 8.69226C7.4863 8.49145 7.75287 8.28751 8.02413 8.08983C8.71776 7.5841 9.41374 7.08071 10.1085 6.57654C10.5383 7.11398 10.9638 7.65456 11.3995 8.18769C11.5698 8.39594 11.7577 8.59048 11.9452 8.78385C12.0822 8.92516 12.2368 8.92868 12.3683 8.78229C12.7695 8.33527 13.1614 7.88042 13.5567 7.42831C13.6041 7.37429 13.6487 7.31753 13.6949 7.26234C13.745 7.56687 13.8127 7.87024 13.8401 8.17673C13.871 8.52276 13.871 8.87193 13.8749 9.21952C13.8828 9.90375 13.7548 10.5684 13.5688 11.2237C13.2999 12.1694 12.8549 13.0294 12.2654 13.8111C12.0422 14.107 11.769 14.365 11.5189 14.6401C11.4923 14.6084 11.4633 14.5787 11.4394 14.5454C10.2001 12.818 8.96202 11.0902 7.72194 9.36357C7.55911 9.13654 7.38648 8.91616 7.21856 8.69304L7.21777 8.69226Z"
          fill="#8A8790"
        />
        <path
          d="M7.21931 8.69202C7.38724 8.91553 7.55986 9.13552 7.7227 9.36255C8.96277 11.0892 10.2009 12.8174 11.4402 14.5444C11.4641 14.5777 11.493 14.6078 11.5196 14.6391C11.5271 14.6587 11.5345 14.6783 11.542 14.6978C11.5024 14.719 11.4578 14.7346 11.4237 14.7628C11.2175 14.9347 11.0209 15.1194 10.8064 15.2799C10.5888 15.4427 10.3535 15.5817 10.1257 15.7312C9.62234 15.0266 9.11856 14.3225 8.61518 13.6179C7.74775 12.4032 6.88032 11.1886 6.01329 9.97358C5.94714 9.8812 5.88451 9.78648 5.82031 9.69292C6.00859 9.552 6.19531 9.40874 6.38516 9.27017C6.63842 9.08541 6.89363 8.90378 7.14885 8.72216C7.16921 8.70767 7.19543 8.70219 7.21931 8.69241V8.69202Z"
          fill="#FEFEFE"
        />
        <path
          d="M10.1221 15.7309C10.3495 15.5814 10.5847 15.442 10.8028 15.2796C11.0177 15.1191 11.2138 14.9343 11.4201 14.7625C11.4541 14.7343 11.4988 14.719 11.5383 14.6975C11.7857 15.0404 12.0331 15.3837 12.2801 15.7266C11.8072 15.9673 11.3344 16.2085 10.8611 16.448C10.7922 16.4829 10.7194 16.5103 10.6482 16.5408C10.5977 16.4531 10.553 16.3611 10.4959 16.2785C10.3749 16.1039 10.2477 15.9341 10.1229 15.7622C10.1229 15.7517 10.1225 15.7415 10.1221 15.7309Z"
          fill="#E0E0E2"
        />
        <path
          d="M5.93929 4.90774C6.13971 4.96802 6.20117 5.09955 6.15224 5.36886C6.06651 5.8425 5.84848 6.24294 5.44569 6.51225C4.88985 6.88411 4.29056 6.94204 3.6854 6.5831C3.24621 6.32279 2.96515 5.92744 2.88452 5.42757C2.80701 4.94806 2.92288 4.50143 3.24581 4.11508C3.48851 3.82464 3.78247 3.62031 4.15082 3.55689C4.67299 3.46725 5.16542 3.54946 5.58074 3.9131C5.69934 4.01683 5.724 4.15383 5.64611 4.25091C5.53142 4.39418 5.41673 4.41884 5.27463 4.31746C5.04877 4.15618 4.80765 4.03249 4.52464 4.03719C3.94218 4.04697 3.49594 4.42197 3.40317 5.00012C3.28496 5.73642 3.94375 6.37328 4.61858 6.28404C5.04134 6.22806 5.44295 5.99398 5.58974 5.44519C5.37954 5.44519 5.17403 5.45536 4.9701 5.44088C4.87498 5.43423 4.76772 5.39861 4.69531 5.33989C4.63894 5.29409 4.59471 5.18723 4.60528 5.11638C4.62093 5.01265 4.68944 4.9144 4.82057 4.9097C5.00767 4.90305 5.19478 4.89796 5.38228 4.896C5.55412 4.89404 5.72596 4.89757 5.8978 4.89835C5.9115 4.90148 5.92559 4.90461 5.93929 4.90774Z"
          fill="#FDFEFD"
        />
        <path
          d="M12.1459 5.16643C11.4268 5.18287 10.7223 4.57183 10.754 3.70167C10.7798 3.00217 11.3529 2.36883 12.1526 2.36804C12.9241 2.36726 13.5633 2.94737 13.5582 3.80227C13.5539 4.57223 12.901 5.18913 12.1459 5.16643Z"
          fill="#89211B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1101_99295">
          <rect
            width="16"
            height="15.9871"
            fill="white"
            transform="translate(0 0.994141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GoogleMapIcon;
