import React from "react";

function RightArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={11}
      fill="none"
      viewBox="0 0 11 11"
    >
      <path
        stroke="#1E9898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.815}
        d="M4.125 8.938 7.563 5.5 4.124 2.062"
      />
    </svg>
  );
}

export default RightArrow;
