import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import SearchForm from "../SearchForm/SearchForm";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const ButtonWithDropDownWrapper = styled.div`
  width: ${({ fullWidth, width }) => (fullWidth ? "90vw" : width)};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  box-shadow: ${(props) => props.shadow};
  border-radius: ${(props) => props.borderRadius ?? "15px"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  left: ${(props) => props.fullWidth && 0};
  flex-direction: ${(props) => (props.isReversed ? "row-reverse" : "row")};
  direction: ${(props) => (props.isReversed ? "rtl;" : "ltr")};
  position: ${({ showShadow, fullWidth }) =>
    showShadow ? "relative" : fullWidth ? "absolute" : "static"};
  position: ${(props) => props.position};
  z-index: ${({ showShadow, isVisibleList }) =>
    showShadow ? "22" : isVisibleList ? "23" : "0"};
`;

const ListWrapper = styled.div`
  width: ${(props) => (props.dropDownWidth ? props.dropDownWidth : "86%")};
  margin: auto;
  flex-shrink: 0;
  color: ${(props) => (props.color ? props.color : "red")};
`;
const SearchFormWrapper = styled.div``;
const UlElement = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 2px 0;
  box-sizing: border-box;
  max-height: ${(props) => props.maxHight ?? "auto"};
  overflow: auto;
`;
const LiItem = styled.li`
  width: 100%;
  padding: 10px 0 5px;
  text-align: ${(props) =>
    props.LiItemTextAlign ? props.LiItemTextAlign : "left"};
  cursor: pointer;
  color: inherit;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
`;
const StyledLink = styled(Link)`
  color: ${(props) => (props.color ? props.color : "#316788")};
  padding: 0 10px;
  font-weight: ${(props) => (props.hasRoute ? "bold" : "normal")};
`;
const ActiveLink = styled.p`
  color: ${(props) => (props.color ? props.color : "#316788")};
  display: flex;
  height: 100%;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "right")};
  ${(props) => (props.margin ? `margin: ${props.margin}` : "")};
  font-weight: ${(props) => props.fontWeightActiveLink ?? "normal"};
  text-decoration: ${(props) => (props.showUnderline ? "underline" : "none")};
  flex-direction: ${(props) => props.flexDirection ?? "row"};
`;
const TextAndIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Text = styled.p`
  width: ${(props) => props.width ?? "90%"};
  padding: 2.5px 15px 0 15px;
  margin: 0;
  font-family: "Assistant";
  font-size: ${(props) => props.fontSizeActiveLink ?? "20px"};
  position: relative;
  display: ${(props) =>
    props.isReversed || props.flexType
      ? "flex"
      : "block"}; /* Change display to flex */
  justify-content: ${(props) =>
    props.isReversed ? "flex-start" : props.flexType ?? "none"};
  @media (max-width: 400px) {
    font-size: 21px;
  }
  @media (max-width: 384px) {
    font-size: 19px;
  }
  @media (max-width: 362px) {
    font-size: 16px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  color: ${(props) => (props.iconColor ? props.iconColor : "#316788")};
  font-size: ${(props) =>
    props.fontSizeActiveLink ? props.fontSizeActiveLink : "20px"};
`;
const PropsIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
`;
const ArrowUpIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${(props) => (props.iconColor ? props.iconColor : "#316788")};
  font-size: ${(props) =>
    props.fontSizeActiveLink ? props.fontSizeActiveLink : "20px"};
`;
const SpicalActiveLink = styled.span`
  color: #ef7300;
  text-align: center;
  font-family: Assistant;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.63px;
  @media (max-width: 362px) {
    font-size: 18px;
  }
`;
const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
`;
const ButtonWithDropDown = forwardRef(
  (
    {
      activeLink,
      TheRestofTheActiveLink = "",
      Icon,
      links,
      color,
      border,
      backgroundColor,
      fontSizeActiveLink,
      fontWeightActiveLink,
      iconColor,
      textAlign,
      LiItemTextAlign,
      setState = () => null,
      height,
      activeLinkMargin,
      showUnderline = false,
      borderRadius,
      searchFormheight,
      searchFormFontSize,
      iconHeight,
      iconWidth,
      searchFormColor,
      showArrow = true,
      isRoutes = true,
      isReversed = false,
      setRoute,
      flexType,
      language,
      activeLinkColor,
      boxShadow,
      width,
      activeLinkFlexDirection,
      activeLinkTextWidth,
      padding,
      onClickCallBack,
      maxListHeight = "300px",
      showShadow,
      fullWidth,
    },
    ref
  ) => {
    const dropDownContentRef = useRef(null);
    const [isVisibleList, setIsVisibleList] = useState(false);
    const [isShadowVisible, setIsShadowVisible] = useState(false);
    const [value, setValue] = useState(null);

    const handleOpenDDL = () => {
      setIsVisibleList(!isVisibleList);
      onClickCallBack?.();
      showShadow && setIsShadowVisible(!isVisibleList);
    };

    const handleClickOutside = (event) => {
      const { target } = event;
      if (
        dropDownContentRef.current &&
        !dropDownContentRef.current.contains(target)
      ) {
        setIsVisibleList(false);
        showShadow && setIsShadowVisible(false);
      }
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          hideDropdown: () => setIsVisibleList(false),
        };
      },
      []
    );
    useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [dropDownContentRef]);
    return (
      <>
        {isShadowVisible && <Backdrop />}
        <ButtonWithDropDownWrapper
          ref={dropDownContentRef}
          onClick={handleOpenDDL}
          color={color}
          border={border}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          height={!isVisibleList ? height : undefined}
          isReversed={isReversed}
          shadow={boxShadow}
          width={width}
          padding={padding}
          showShadow={showShadow}
          fullWidth={fullWidth && isVisibleList}
          isVisibleList={isVisibleList}
        >
          <ActiveLink
            color={activeLinkColor ?? color}
            textAlign={textAlign}
            margin={activeLinkMargin}
            fontWeightActiveLink={fontWeightActiveLink}
            showUnderline={showUnderline}
            flexDirection={activeLinkFlexDirection}
          >
            <TextAndIconWrapper>
              <Text
                fontSizeActiveLink={fontSizeActiveLink}
                isReversed={isReversed}
                flexType={flexType}
                width={activeLinkTextWidth}
              >
                <SpicalActiveLink> {TheRestofTheActiveLink}</SpicalActiveLink>
                {Icon && (
                  <PropsIconWrapper>
                    <Icon />
                  </PropsIconWrapper>
                )}
                {value ? value : `${activeLink} `}
              </Text>
            </TextAndIconWrapper>
            {!isVisibleList && showArrow && (
              <IconWrapper
                iconColor={iconColor}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDDL();
                }}
              >
                <FaAngleDown />
              </IconWrapper>
            )}
          </ActiveLink>

          {isVisibleList && (
            <ListWrapper color={color}>
              <SearchFormWrapper onClick={(e) => e.stopPropagation()}>
                <SearchForm
                  placeholder={language === "heb" ? "לחפש" : "search"}
                  style={{
                    borderRadius: "15px",
                    textPadding: "12px",
                    height: searchFormheight ?? "20px",
                    fontSize: searchFormFontSize ?? "10px",
                    borderColor: "#C9D7E0",
                    placeholderColor: searchFormColor ?? "black",
                    inputColor: searchFormColor ?? "black",
                  }}
                  iconHeight={iconHeight ?? "10px"}
                  iconWidth={iconWidth ?? "10px"}
                  lang={language}
                />
              </SearchFormWrapper>
              <UlElement maxHight={maxListHeight}>
                {links.map((link, index) => (
                  <LiItem key={link.name} LiItemTextAlign={LiItemTextAlign}>
                    <StyledLink
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        if (setState) {
                          setState(link.value);
                          setIsVisibleList(false);
                          setValue(link.name);
                        }
                        if (setRoute) {
                          setRoute(link.route);
                        }
                      }}
                      // to={!setState ? link.route : null}
                      // This is Temporarily to work on the home page
                      to={isRoutes && link.route}
                      color={color}
                      hasRoute={link.route}
                    >
                      {link.name}
                    </StyledLink>
                  </LiItem>
                ))}
              </UlElement>
            </ListWrapper>
          )}
          {isVisibleList && (
            <ArrowUpIconWrapper iconColor={iconColor}>
              <FaAngleUp />
            </ArrowUpIconWrapper>
          )}
        </ButtonWithDropDownWrapper>
      </>
    );
  }
);

export default ButtonWithDropDown;
