import { useContext, useState } from "react";
import styled from "styled-components";
import TextInput from "../../../../../kit/Input/TextInput";
import Button from "../../../../../kit/Button/Button";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../../../store/userContext";
import { TogetherEcostystemIcon } from "../../../../../assets/icons/Logo";
import Header from "../../../../Common/Layout/Header";
import LoginFooter from "../../../../Auth/login/components/LoginFooter";
import cityCoverImage from "../../../../../assets/images/Auth/login/city-cover.png";
import CoverPhoto from "../../../../Emergency/Contacts/components/CoverPhoto";
import useCustomAppWidth from "../../../../../customHooks/useCustomAppWidth";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import { handleLogin } from "../../../../Auth/login/utils";
import useFetchWaitingList from "../../../../../customHooks/useFetchWaitingList";
import CardDetailsConfirmPage from "./CardDetailsConfirmPage";

const Wrapper = styled.div`
  display: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "flex" : "block"};
  direction: ${({ isWideScreen }) => (isWideScreen ? "rtl" : "ltr")};
  flex-direction: ${({ isMediumScreen }) => isMediumScreen && "column"};
  justify-content: space-between;
  gap: 25px;
  text-align: center;
`;
const FormWrapper = styled.div`
  width: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen ? "40%" : isMediumScreen ? "60%" : "100%"};
  margin: auto;
  box-sizing: border-box;
  direction: ltr;
`;
const Form = styled.form`
  width: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "90%" : "100%"};
  margin: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "20px auto 0" : "0"};
`;
const Decription = styled.div`
  margin: 10px 0;
  color: var(--dark-blue-2, #405e79);
  text-align: center;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.44px;
`;
const SubDescription = styled.h4`
  margin: 0;
  color: #4b778d;
  text-align: center;
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: 0.17px;
`;
const SubmitWrapper = styled.div`
  display: flex;
  direction: ltr;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding-bottom: 25px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Checkbox = styled.input`
  appearance: none;
  width: 21.188px;
  height: 22px;
  border: 1.5px solid #1e9898;
  border-radius: 5px;
  outline: none;
  position: relative;
  margin-right: 5px;

  &:checked {
    background-color: #1e9898;
  }

  &:checked::before {
    content: "";
    position: absolute;
    left: 4px;
    width: 8px;
    height: 13px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: white;
    transform: rotate(45deg);
  }
`;

/// this is for WideScreen and MediumScreen
const DescriptionAndImageWrapper = styled.div`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: ${({ isWideScreen }) => (isWideScreen ? "flex" : "block")};
  flex-direction: column;
  width: ${({ isWideScreen }) => (isWideScreen ? "70%" : "100%")};
`;
const DescriptionsWrapper = styled.div`
  height: ${({ isWideScreen }) => (isWideScreen ? "50%" : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ltr;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: ${({ isMediumScreen }) => (isMediumScreen ? "400px" : "50%")};
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const LoginStyles = {
  color: "#28B5B5",
  shadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.12)",
  border: "2px solid #28B5B5",
  borderRadius: "30px",
  width: "221px",
  height: "50px",
  margin: "none",
  fontSize: "20px",
  fontFamily: "Assistant",
};

function Page({ setCurrentView, dataType, entityId, logo, authorId }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState();
  const [postData, setPostData] = useState();
  const { currentUser, setCurrentUser, isAuthenticated, setIsAuthenticated } =
    useContext(UserContext);
  const { register, handleSubmit } = useForm();
  const { waitingList, refetchWaitingList } = useFetchWaitingList(
    dataType,
    entityId
  );
  useCustomAppWidth();
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();

  const onLogin = async (data) => {
    try {
      const loginResponse = await handleLogin(
        data,
        setCurrentUser,
        setIsAuthenticated,
        setErrorMessage
      );
      setError("");
      onRegister(loginResponse);
    } catch (error) {}
  };

  const onRegister = async (userData) => {
    const postData = {
      waitingList_id: waitingList?._id,
      user_id: userData?._id,
      phone_number: userData?.phone,
      full_name: userData?.displayName,
      city: userData?.address,
      authorId: authorId,
    };
    setPostData(postData);
  };
  if (isAuthenticated) {
    !postData && onRegister(currentUser);
    return (
      <CardDetailsConfirmPage
        setCurrentView={setCurrentView}
        postData={postData}
        refetchWaitingList={refetchWaitingList}
      />
    );
  }

  return (
    <Wrapper isWideScreen={isWideScreen} isMediumScreen={isMediumScreen}>
      {!isWideScreen && !isMediumScreen && (
        <CoverPhoto
          image={cityCoverImage}
          logo={logo ? logo : <TogetherEcostystemIcon />}
        />
      )}
      {isMediumScreen && (
        <Header
          Icon={logo ? () => logo : TogetherEcostystemIcon}
          showNavbar={false}
          showLogoutIcon={false}
        />
      )}
      <DescriptionAndImageWrapper isWideScreen={isWideScreen}>
        <DescriptionsWrapper isWideScreen={isWideScreen}>
          <div>
            <Decription>! הצטרפו ותהיו חלק מקהילת המתנדבים</Decription>
            <SubDescription>
              בואו להיות חלק מקבוצת התנדבות עירוניות
            </SubDescription>
            <SubDescription>
              . ותוכלו ליהנות ממגוון פעילויות ואירועים ייחודיים
            </SubDescription>
          </div>
        </DescriptionsWrapper>
        {isWideScreen && (
          <ImageWrapper>
            <Image src={cityCoverImage} />
          </ImageWrapper>
        )}
      </DescriptionAndImageWrapper>
      <FormWrapper isWideScreen={isWideScreen} isMediumScreen={isMediumScreen}>
        {isWideScreen && (
          <Header
            Icon={logo ? () => logo : TogetherEcostystemIcon}
            showNavbar={false}
            showLogoutIcon={false}
          />
        )}

        <Form
          isWideScreen={isWideScreen}
          isMediumScreen={isMediumScreen}
          onSubmit={handleSubmit(onLogin)}
        >
          <TextInput
            height="63px"
            border="2px solid #92ddd0"
            borderRadius="15px"
            type="text"
            placeholder="שם מלא / שם משתמש / סלולרי"
            register={register}
            hookName={"loginInformation"}
            lan={"heb"}
            required
          />
          <TextInput
            height="63px"
            border="2px solid #92ddd0"
            borderRadius="15px"
            type="password"
            placeholder="סיסמא"
            register={register}
            hookName={"loginPassword"}
            lan={"heb"}
            required
            showPasswordByDefault
          />
          <SubmitWrapper>
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                id="remember me"
                {...register("rememberMe")}
              />
              <label
                for="remember me"
                style={{
                  fontSize: "14px",
                  color: "#1E9898",
                  fontFamily: "Assistant",
                }}
              >
                זכור אותי
              </label>
            </CheckboxWrapper>
            <Button
              text={"Log In   \u00a0  \u00a0  \u00a0   כניסה "}
              styles={LoginStyles}
            />
          </SubmitWrapper>
        </Form>
        {error && error !== "" && (
          <span style={{ marginTop: "15px", color: "darkred", width: "90%" }}>
            {error}
          </span>
        )}
        <LoginFooter />
      </FormWrapper>
      {isMediumScreen && (
        <ImageWrapper isMediumScreen={isMediumScreen}>
          <Image src={cityCoverImage} />
        </ImageWrapper>
      )}
    </Wrapper>
  );
}

export default Page;
