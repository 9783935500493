import styled from "styled-components";
import PopUpModal from "../../../kit/PopUpModal/PopUpModal";
import heart from "../../../assets/images/Tasks/detailsPage/heart.svg";
import { getPhotoSrc } from "../Post/utils";
import WhatsAppIcon from "../../../assets/icons/Common/Layout/WhatsAppIcon.svg";
import SmallGreenAppleIcon from "../../../assets/icons/Common/Apples/SmallGreenAppleIcon";
import { formatTime } from "../../Common/utils/date";
import Dropdown from "../../../kit/Dropdown/Dropdown";
import { deletePostItem } from "../Post/api";
import ShareButton from "../../../kit/Share/ShareButton";
import { useContext } from "react";
import { UserContext } from "../../../store/userContext";
import Map from "../../../kit/Map/Map";

const RelativeContainer = styled.div`
  top: -5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 0 5px;
`;

const Title = styled.div`
  font-family: Assistant;
  font-size: 30px;
  font-weight: 600;
  color: #316788;
  max-width: 220px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const Options = styled.div`
  transform: Rotate(90deg);
  font-family: Assistant;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  line-height: 5px;
  height: 30px;
  color: #316788;
  position: relative;
  top: 8px;
`;

const ImagePreview = styled.img`
  max-width: 310px;
  width: 90%;
  height: 170px;
  object-fit: cover;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 21px;
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 310px;
  margin: 0 auto;
  text-align: right;
  font-family: Assistant;
  font-size: 15px;
  color: #316788;
  direction: rtl;
`;

const DataRowTitle = styled.div`
  font-weight: 700;
`;

const DataRowValue = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Separator = styled.div`
  border: 1px solid #7fb383;
  opacity: 0.3;
  max-width: 310px;
  width: 90%;
  margin: 8px auto;
`;

const GiveReceivePopUp = ({ visible, hidePopUpfunc, data, onDelete }) => {
  const { isAuthenticated } = useContext(UserContext);

  if (!data) {
    return null;
  }

  return (
    <PopUpModal
      visible={visible}
      hidePopUpfunc={() => hidePopUpfunc?.()}
      useNormalDialog
    >
      <RelativeContainer>
        <HeaderSection>
          <div style={{ width: "30px", height: "30px" }}>
            <Image src={heart} />
          </div>
          <Title>{data.title}</Title>
          <div style={{ marginLeft: "5px", marginTop: "-15px" }}>
            <Dropdown
              customIcon={<Options>...</Options>}
              menuLeftOffset={"-75px"}
              wrapperPadding={0}
              options={[
                {
                  onClick: async () => {
                    if (!isAuthenticated) return;
                    try {
                      await deletePostItem(data?.id);
                      onDelete?.(data?.type);
                      hidePopUpfunc?.();
                    } catch (ex) {
                      console.error(`Failed to delete post item. Error=${ex}`);
                    }
                  },
                  text: "Delete",
                },
              ]}
            />
          </div>
        </HeaderSection>
        <ImagePreview src={getPhotoSrc(data.image)} />
        <DataRow>
          <DataRowTitle>תיאור - Description</DataRowTitle>
          <DataRowValue>
            {data.description}
            <ShareButton description={data.description} />
          </DataRowValue>
        </DataRow>
        <Separator />
        <DataRow>
          <DataRowTitle>{data?.location ?? "שבזי 4, תל אביב"}</DataRowTitle>
          <DataRowValue>
            <div>
              <DataRowTitle>{`${formatTime(data.createdAt)?.substring?.(
                0,
                5
              )} ${data.date?.replaceAll?.("-", "/")} `}</DataRowTitle>
            </div>
            <div style={{ width: "100%", marginTop: "5px" }}>
              <Map
                disableClick
                height={"150px"}
                expandedHeight={"225px"}
                centerCity={data?.location}
                showMarkerForCenterCity
                expandOnFocus
              />
            </div>
          </DataRowValue>
        </DataRow>
        <Separator />
        <DataRow>
          <DataRowTitle>איש קשר - Contacts</DataRowTitle>
          <DataRowValue>
            <div>
              {data.contact_name}
              &nbsp; &nbsp; &nbsp;
              {data.telephone}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img src={WhatsAppIcon} width={22} height={22} alt="whatsapp" />
              <SmallGreenAppleIcon />
            </div>
          </DataRowValue>
        </DataRow>
      </RelativeContainer>
    </PopUpModal>
  );
};

export default GiveReceivePopUp;
