import { useContext } from "react";
import { UserContext } from "../store/userContext";
import { USER_ROLES } from "../domain/Auth/constant";

const getUserRole = (currentUser) => {
  if (currentUser[USER_ROLES.ADMIN]?.length > 0 || currentUser.isSuperUser) {
    return USER_ROLES.ADMIN;
  } else if (currentUser[USER_ROLES.TEAM_MEMBER]?.length > 0) {
    return USER_ROLES.TEAM_MEMBER;
  } else {
    return USER_ROLES.MEMBER;
  }
};

function useCurrentUser(props) {
  const { isAuthenticated, setIsAuthenticated, currentUser, setCurrentUser } =
    useContext(UserContext);

  const accessToken = localStorage.getItem("access_token");

  return {
    isAuthenticated,
    setIsAuthenticated,
    currentUser,
    setCurrentUser,
    username: currentUser?.name,
    firstName: currentUser?.name?.split(" ")[0] || "",
    profileImage: currentUser?.image,
    userRole: currentUser ? getUserRole(currentUser) : null,
    isAdmin: currentUser?.role === USER_ROLES.ADMIN || USER_ROLES.TEAM_MEMBER,
    accessToken,
    isSuperUser: currentUser?.isSuperUser,
    isVisitor: currentUser?.id === "visitor",
  };
}

export default useCurrentUser;
