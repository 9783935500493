export const htmlToText = (htmlString) => {
  try {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || htmlString;
  } catch {
    return htmlString;
  }
};

export const isRTLText = (text) =>
  /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/.test(text);
