export const ROUTES = {
  ORT: "",
  GDEROT: "gderot",
};

export const STUDENT = [
  // {
  //   name: "אורט - תל אביב",
  //   route: `/public/activities/student/${ROUTES.ORT}`,
  // },
  // {
  //   name: "הגמנסיה - גדרות",
  //   // route: `/public/activities/student/${ROUTES.GDEROT}`,
  //   route: null,
  // },
  // {
  //   name: "הראשונים - הוד השרון",
  //   route: null,
  // },
  // {
  //   name: "בן צבי - הרצליה",
  //   route: null,
  // },
  // {
  //   name: "רמות - רמת השרון",
  //   route: null,
  // },
  // {
  //   name: "הגבעה - כפר סבא ",
  //   route: null,
  // },
];
