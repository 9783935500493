import React from "react";

function MicrophoneIcon({ width, height, color }) {
  return (
    <svg
      width={width ?? "36"}
      height={height ?? "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5725_17223)">
        <path
          d="M9.21455 35.9911C9.20471 35.8815 9.09502 35.899 9.03455 35.8632C8.55994 35.5812 8.33354 35.0834 8.46572 34.5856C8.59861 34.085 9.03947 33.7482 9.58088 33.7475C11.8998 33.7454 14.2194 33.7418 16.5383 33.7524C16.8062 33.7538 16.8899 33.6954 16.8828 33.4149C16.8624 32.5388 16.8617 31.6613 16.8828 30.7852C16.8899 30.5011 16.7999 30.4125 16.5263 30.3837C14.2855 30.1461 12.2436 29.3621 10.4204 28.05C7.53127 25.9702 5.76572 23.1626 5.0865 19.6751C4.96486 19.0493 5.38815 18.4714 6.03783 18.3455C6.59471 18.2379 7.19588 18.6408 7.30908 19.2399C7.59455 20.7586 8.12893 22.1775 9.01135 23.4495C10.7888 26.0124 13.2125 27.586 16.3063 28.0761C22.0121 28.9796 27.4128 25.2861 28.6039 19.6857C28.6377 19.5261 28.6553 19.3629 28.6939 19.2047C28.8374 18.619 29.435 18.2316 29.9954 18.3554C30.6289 18.4953 31.0227 19.0599 30.9158 19.6751C29.9989 24.9556 25.6831 29.3122 20.4456 30.241C20.101 30.3022 19.7544 30.3598 19.4064 30.3943C19.1694 30.4182 19.1146 30.518 19.1188 30.7486C19.1342 31.6486 19.1349 32.5486 19.1188 33.4486C19.1138 33.6968 19.1813 33.7538 19.426 33.7524C21.7098 33.7425 23.9942 33.7629 26.278 33.7369C27.0233 33.7285 27.4248 34.1743 27.531 34.5863C27.6533 35.0616 27.4719 35.6783 26.8074 35.9349C26.7948 35.9398 26.7934 35.9715 26.7863 35.9904L9.21455 35.9911Z"
          fill={color ?? "#58656D"}
        />
        <path
          d="M0.00708008 8.84207C0.274971 8.36395 0.618096 8.00887 1.23052 8.0595C1.77966 8.1052 2.24794 8.55942 2.25286 9.10926C2.26341 10.2863 2.2613 11.4633 2.25286 12.6404C2.24935 13.1023 1.88583 13.5418 1.44075 13.6648C0.964033 13.7956 0.435986 13.5847 0.161064 13.1621C0.107627 13.0798 0.116064 12.9589 0.00708008 12.9118V8.84348V8.84207Z"
          fill={color ?? "#58656D"}
        />
        <path
          d="M35.9929 12.9104C35.7159 13.4005 35.3587 13.7542 34.7322 13.6874C34.2232 13.6332 33.7549 13.1846 33.7492 12.6742C33.7359 11.4739 33.7373 10.273 33.7492 9.07277C33.7535 8.62839 34.1268 8.20089 34.5607 8.08487C35.0465 7.9555 35.5675 8.16433 35.8375 8.59183C35.8903 8.6748 35.8896 8.79152 35.9936 8.84144V12.9097L35.9929 12.9104Z"
          fill={color ?? "#58656D"}
        />
        <path
          d="M25.8448 12.9824C25.8146 14.4041 25.9439 16.1731 25.7977 17.9317C25.5312 21.1414 23.862 23.4181 20.9011 24.6479C16.3575 26.5344 11.1636 23.641 10.2846 18.8035C10.1904 18.2853 10.1574 17.7601 10.1567 17.2342C10.1539 14.1721 10.1496 11.1106 10.1567 8.04854C10.1651 4.34237 12.4207 1.35198 15.9575 0.340179C20.1987 -0.873415 24.7768 1.91166 25.662 6.23869C25.7893 6.86026 25.8406 7.48604 25.8427 8.11744C25.8476 9.62494 25.8441 11.1324 25.8441 12.9824H25.8448ZM23.5639 12.6378C23.5639 11.1071 23.5639 9.57643 23.5639 8.04573C23.5639 7.68432 23.5456 7.32502 23.4943 6.96432C23.0689 3.9894 19.954 1.82377 17.0016 2.40737C14.2882 2.94385 12.3877 5.33096 12.4271 8.0176C12.4721 11.1015 12.4369 14.1868 12.4383 17.2721C12.4383 17.6223 12.4566 17.9696 12.5079 18.3184C12.9537 21.3573 16.1269 23.511 19.1384 22.8472C21.7716 22.2664 23.6222 19.9187 23.5709 17.3003C23.54 15.7471 23.5653 14.1924 23.5653 12.6378H23.5639Z"
          fill={color ?? "#58656D"}
        />
        <path
          d="M7.32797 10.9072C7.32797 11.9816 7.33571 13.0559 7.32446 14.1296C7.31813 14.7181 6.90188 15.1744 6.35696 15.2314C5.75649 15.294 5.26993 14.9705 5.10961 14.3996C5.08782 14.3223 5.07235 14.24 5.07235 14.1598C5.07024 11.9766 5.06672 9.79273 5.07375 7.60952C5.07516 7.07234 5.49211 6.60617 5.99414 6.53093C6.57844 6.44374 7.07485 6.73413 7.27313 7.27202C7.33571 7.44218 7.32797 7.61656 7.32797 7.79023C7.33079 8.82945 7.32938 9.86867 7.32938 10.9079H7.32797V10.9072Z"
          fill={color ?? "#58656D"}
        />
        <path
          d="M30.9297 10.8665C30.9297 11.917 30.9318 12.9681 30.9297 14.0186C30.9283 14.7288 30.4657 15.228 29.8111 15.2336C29.1459 15.2399 28.6755 14.759 28.6734 14.0446C28.6678 11.931 28.6685 9.81813 28.6734 7.70454C28.6748 6.98032 29.1332 6.50571 29.8019 6.50782C30.4607 6.51063 30.9283 7.00633 30.9297 7.71368C30.9325 8.76415 30.9304 9.81532 30.9304 10.8658L30.9297 10.8665Z"
          fill={color ?? "#58656D"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5725_17223">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicrophoneIcon;
