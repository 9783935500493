import React, { useState } from "react";
import styled, { css } from "styled-components";
import { GroupIcon } from "../../../../../assets/icons";
import { Box, Divider, TextField, styled as MuiStyled } from "@mui/material";
import { CiCircleMinus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneNumberInput from "../../../../../kit/Input/PhoneNumberInput";
import MuiButton from "../../../../../kit/Button/MuiButton";
import useContacts from "../hooks/useContacts.js";
import useCreateContact from "../hooks/useCreateContact.js";
import useDeleteContact from "../hooks/useDeleteContact.js";

const StyledDividers = MuiStyled(Divider)({
  borderColor: "#316788",
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 30px;
  width: 150px;
  background-color: #e5eeff;
  border: 1px solid #316788;
  border-radius: 5px;
  z-index: 10;
  color: #316788;
  font-size: 12px;
  font-weight: 600;
  font-family: Assistant;
  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `}
  z-index: 1002;
  @media (max-width: 385px) {
    left: -45px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Contact = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  height: 37px;
  cursor: pointer;
`;

const CreateToggleButton = styled.div`
  height: 26px;
  background-color: #316788;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const CreationForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 7px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  number: Yup.string().required("Number is required"),
});

const ContactsSubTab = ({ tab, showContactPopup }) => {
  const [showCreateContact, setShowCreateContact] = useState(false);

  const { data: contacts = [], isLoading: isLoadingContacts } = useContacts();
  const { createContact, isLoading: isCreatingContact } = useCreateContact();
  const { deleteContact } = useDeleteContact();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (formData) => {
    try {
      const newContact = await createContact(formData);
      reset();
      setShowCreateContact(false);
    } catch (error) {
      console.error("Failed to create contact:", error);
    }
  };

  const handleDelete = async (contactId) => {
    try {
      await deleteContact(contactId);
    } catch (error) {
      console.error("Failed to delete contact:", error);
    }
  };

  return (
    <Wrapper customStyles={tab.styles}>
      <Header>
        <GroupIcon size={20} />
        {"אנשי קשר בחירום "}
      </Header>
      <StyledDividers />
      {!isLoadingContacts &&
        contacts.map((contact, index) => (
          <React.Fragment key={contact._id}>
            <Contact onClick={showContactPopup}>
              <div>{contact.name}</div>
              <div>{contact.number}</div>
              <Box
                sx={{ position: "absolute", top: "4px", left: "5.5px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(contact._id);
                }}
              >
                <CiCircleMinus style={{ cursor: "pointer" }} />
              </Box>
            </Contact>
            <StyledDividers />
          </React.Fragment>
        ))}
      <CreateToggleButton
        onClick={() => setShowCreateContact(!showCreateContact)}
      >
        <FaPlus />
        <span>הוספת איש קשר</span>
      </CreateToggleButton>
      {showCreateContact && (
        <CreationForm onSubmit={handleSubmit(onSubmit)}>
          <div>שם מלא</div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
                margin="normal"
                sx={{
                  width: 131,
                  height: 18,
                  backgroundColor: "transparent",
                  margin: 0,
                  "& .MuiOutlinedInput-root": {
                    background: "transparent",
                    border: "2px solid #316788",
                    color: "#316788",
                    borderRadius: "5px",
                    fontSize: 12,
                    "&.Mui-focused fieldset": {
                      border: 0,
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    "&.MuiInputBase-root": {
                      height: 18,
                      backgroundColor: "transparent",
                    },
                  },
                }}
              />
            )}
          />
          <div>מספר טלפון</div>
          <PhoneNumberInput
            setHookValue={setValue}
            hookName="number"
            gap="0px"
            inputStyle={{
              borderRadius: "5.23px",
              border: "1px solid #316788",
              height: "16px",
              width: "11.56px",
              backgroundColor: "transparent",
            }}
          />
          <MuiButton
            type="submit"
            sx={{
              fontSize: 8.437,
              fontWeight: 600,
              fontFamily: "NotoSans-600",
              border: "1px solid #1279AA",
              borderRadius: "46.873px",
              width: 78,
              height: 15,
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              "&:hover": {
                border: "1px solid #1279AA",
              },
            }}
            disabled={isCreatingContact}
          >
            <div>Save</div>
            <div>שמירה</div>
          </MuiButton>
        </CreationForm>
      )}
    </Wrapper>
  );
};

export default ContactsSubTab;
