import MailIcon from "../../../../../../assets/icons/Common/Layout/MailIcon.svg";
import WhatsAppIcon from "../../../../../../assets/icons/Common/Layout/WhatsAppIcon.svg";
import TwitterIcon from "../../../../../../assets/icons/Common/Layout/TwitterIcon.svg";
import FacebookIcon from "../../../../../../assets/icons/Common/Layout/FacebookIcon.svg";
import greenAppleIcon from "../../../../../../assets/icons/Common/Layout/greenApple.png";
import BluetoothIcon from "../../../../../../assets/icons/Common/Layout/Bluetooth.png";

export const ShareIcons = [
  BluetoothIcon,
  WhatsAppIcon,
  FacebookIcon,
  greenAppleIcon,
  TwitterIcon,
  MailIcon,
];
