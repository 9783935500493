import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../../../store/languageContext";
import { LANGUAGES } from "../../../../Common/Layout/Header/constant";

const Column = styled.div`
  display: flex;
  flex-direction: ${(props) => (!props.sameLine ? "column" : "row")};
  font-size: 10px;
  font-weight: 700;
  border-radius: 17.5px;
  padding: 6px 18px;
  width: 100%;
  box-sizing: border-box;
  direction: ${(props) => (props.sameLine ? "rtl" : "ltr")};
  gap: ${(props) => (props.sameLine ? "5px" : "10px")};
`;

const CategoryTitle = styled.span`
  margin-right: 10px;
  color: ${(props) => props.color ?? "#1B5C6D"};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckboxLabel = styled.label`
  position: relative;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.27px;
  color: ${({ color }) => color ?? "#58656d"};
  background-color: ${({ checked }) => (checked ? "#BCD9BF" : "#e1f0e2")};
  height: ${({ boxHeight }) => boxHeight ?? "31px"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 13.885px;
  box-shadow: 0px 3.455px 3.455px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 423px) {
    font-size: 12px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const Category = ({
  label,
  value,
  checked,
  register,
  required,
  boxHeight,
  color,
}) => (
  <CheckboxLabel checked={checked} boxHeight={boxHeight} color={color}>
    <HiddenRadio
      required={required}
      type="radio"
      name="category"
      checked={checked}
      value={value}
      {...register}
    />
    {label}
  </CheckboxLabel>
);

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: ${({ gap }) => gap ?? "13px"};
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;
`;

const CheckboxRowV2 = ({
  leftLabel,
  rightLabel,
  categories,
  selectedCategory,
  showStar,
  register,
  sameLine,
  boxHeight,
  gap,
}) => {
  const { currentLanguage } = useContext(LanguageContext);
  const dir = currentLanguage === LANGUAGES.HEBREW.value ? "rtl" : "ltr";

  return (
    <Column sameLine={sameLine}>
      {(leftLabel || rightLabel) && (
        <CategoryTitle>
          <div>{leftLabel}</div>
          <div>{rightLabel}</div>
        </CategoryTitle>
      )}
      <GridContainer style={{ direction: dir }} gap={gap}>
        {categories.map((category) => (
          <Category
            key={category.value}
            label={category.label}
            checked={selectedCategory === category.value}
            value={category.value}
            register={register}
            boxHeight={boxHeight}
            color={category?.color}
          />
        ))}
      </GridContainer>
    </Column>
  );
};

export default CheckboxRowV2;
