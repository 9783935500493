import React from "react";
import styled from "styled-components";
import WazeButton from "../MapsExternalButtons/WazeButton";
import GoogleMapsButton from "../MapsExternalButtons/GoogleMapsButton";
import Map, { MarkerIcon } from "./Map";
import { FlexRow } from "../../domain/Common/Styled";
import GrayDoubleArrowIcon from "../../assets/icons/GrayDoubleArrowIcon";
import { usePlaceDetails } from "../../customHooks/usePlaceDetails";
import LoadingSpinner from "../../domain/Common/Loading/Loading";
import BackButton from "../Button/BackButton";
import { Box } from "@mui/material";

const Wrapper = styled.div`
  min-height: calc(100vh - 30px);
  padding-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  background: #ffffff;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-family: "Assistant";
  text-align: right;
  font-size: 25px;
  color: #316788;
  font-weight: 600;
`;

const SubText = styled.p`
  font-family: "Assistant";
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: right;
  color: #316788;
  margin-bottom: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #9fa3a8;
  padding-bottom: 10px;
`;

const InfoItem = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #316788;
  width: ${(props) => props.width};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3px;
  margin-bottom: 20px;
`;

const MapContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const MoreInfo = styled.a`
  color: #316788;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const LocationDetailsPage = ({ location, onClose }) => {
  const {
    data: placeDetails,
    isLoading,
    isError,
  } = usePlaceDetails(location?.place_id);

  if (isLoading) {
    return <LoadingSpinner text={"Creating"} center />;
  }

  if (isError || !placeDetails) {
    return <div>Error loading place details.</div>;
  }

  return (
    <Wrapper>
      <ContentContainer>
        <Box onClick={onClose}>
          <BackButton />
        </Box>
        <Title>{placeDetails.name}</Title>
        <SubText>
          {"מוקד סיוע לטיפול ראשוני למקרי חירום והפנייה לבתי חולים לפי הצורך"}
        </SubText>
        <InfoContainer>
          <InfoItem>
            Tel <br />
            {placeDetails.formatted_phone_number || "03-6354647"}
          </InfoItem>
          <InfoItem>
            שעות פעילות <br />
            {placeDetails.opening_hours
              ? placeDetails.opening_hours.weekday_text.map((day, index) => (
                  <div key={index}>{day}</div>
                ))
              : "00:00 - 00:00"}
          </InfoItem>
        </InfoContainer>
        <ButtonContainer>
          <FlexRow gap={"10px"}>
            <WazeButton latitude={location?.lat} longitude={location?.lng} />
            <GoogleMapsButton
              latitude={location?.lat}
              longitude={location?.lng}
            />
          </FlexRow>
          <InfoItem width={"180px"}>
            {placeDetails.formatted_address || location.vicinity}
          </InfoItem>
        </ButtonContainer>
        <MoreInfo
          href={`https://www.google.com/maps/place/?q=place_id:${location.place_id}`}
        >
          More Info{" "}
          <GrayDoubleArrowIcon color={"#316788"} width={14} height={14} />
        </MoreInfo>
        <MapContainer>
          <Map
            initialSelectedLocation={{
              lat: location.lat,
              lng: location.lng,
              markerIcon: MarkerIcon.Orange,
            }}
            height={"280px"}
            borderRadius={"10px"}
          />
        </MapContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default LocationDetailsPage;
