import React from "react";
import Emergency from "../Cards/Emergency";
import EmergencyLinearCard from "../Cards/Linear/Emergency";
import EmergencyLinearCard2 from "..//Cards/Linear/Emergency2";
import { MAIN_ROUTES } from "../../../../Common/Layout/Main/constants";
import { TaskZone } from "../../../../../customHooks/useFetchTasks";

import styled from "styled-components";
import Activities from "../../Activities/Activities";
import { emergencyData } from "../../../../Ecosystem/Mock/publicData";
import EmergencyButtons from "../EmergencyButtons/EmergencyButtons";

import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
const Wrapper = styled.div`
  width: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen ? "80%" : isMediumScreen ? "90%" : "100%"};
  margin: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "20px auto" : "auto"};
  display: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen ? "flex" : "block"};
  flex-direction: row-reverse;
  justify-content: center;
  gap: 10px;
`;
const ButtonsWrapper = styled.div`
  width: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen ? "35%" : "100%"};
  background: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "rgba(127, 179, 131, 0.04)" : "none"};
  box-shadow: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen
      ? "1.229px 1.229px 3.072px 0.614px rgba(0, 0, 0, 0.15)"
      : "none"};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SliderWrapper = styled.div`
  width: ${({ isWideScreen, showButtons }) =>
    isWideScreen && showButtons ? "64%" : "100%"};
  background: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "rgba(127, 179, 131, 0.04)" : "none"};
  box-shadow: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen
      ? "1.229px 1.229px 3.072px 0.614px rgba(0, 0, 0, 0.15)"
      : "none"};
`;
const EmergencySection = ({
  zone,
  data,
  showButtons = true,
  showActivites = true,
  hebrewLogo = true,
  linearCardType = "1",
  icon,
  emergencyButtonsNum,
  centered,
  title,
  collapsedByDefault,
}) => {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const buttonsData = emergencyButtonsNum
    ? emergencyData.slice(0, emergencyButtonsNum)
    : emergencyData;
  return (
    <Wrapper isWideScreen={isWideScreen} isMediumScreen={isMediumScreen}>
      {showButtons && (
        <ButtonsWrapper
          isWideScreen={isWideScreen}
          isMediumScreen={isMediumScreen}
        >
          <EmergencyButtons title={title} collapsedByDefault />
        </ButtonsWrapper>
      )}
      {showActivites && (
        <SliderWrapper
          isWideScreen={isWideScreen}
          isMediumScreen={isMediumScreen}
          showButtons={showButtons}
        >
          <Activities
            data={data ?? buttonsData}
            hasActions
            InitialStateOfCards
            width={"100%"}
            boxShadow={"none"}
            SliderComponent={Emergency}
            sliderProps={{
              enTitle: "Emergency ",
              hebTitle: "התארגנות וסיוע אישי במצבי חירום ",
              data: data ?? buttonsData,
              hasShadow: true,
              zone: zone ?? TaskZone.TelAvivCity,
              icon: icon,
              centered: centered,
            }}
            LinearComponent={
              linearCardType == "2" ? EmergencyLinearCard2 : EmergencyLinearCard
            }
            linearProps={{
              liked: false,
              linkAddress: "פרטים נוספים",
              link: `/${MAIN_ROUTES.PRIVATE_ZONE}/${
                zone ?? TaskZone.TelAvivCity
              }/linear-card-details`,
            }}
            hasUnfocus
            showArrowUpOrDown
          />
        </SliderWrapper>
      )}
    </Wrapper>
  );
};

export default EmergencySection;
