import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TogetherIcon from "../../../../assets/icons/Logo/TogetherIcon";
import SearchPart from "../../../Ecosystem/Zone/Common/SearchPart/SearchPart";
import Activities from "../../../Ecosystem/Zone/Activities/Activities";
import {
  emergencyData,
  publicForumData,
} from "../../../Ecosystem/Mock/publicData";
import SliderCard from "../../../Ecosystem/Zone/Common/Cards/Slider/Gderot/Card1";
import Emergency from "../../../Ecosystem/Zone/Common/Cards/Emergency";
import { useForm } from "react-hook-form";
import {
  addCommunityToSection,
  createEcosystem,
  editEcosystem,
} from "../../../Ecosystem/Zone/Activities/Create/api";
import useCurrentUser from "../../../../customHooks/useCurrentUser";
import { useNavigate, useParams } from "react-router-dom";
import useGetEcosystemById from "../../../Ecosystem/View/useGetEcosystemById";
import SelectionPopup from "./SelectionPopup";
import { EcosystemZone, VOLUNTEER_BOARD_IMAGES } from "./constans";
import { SECTION_TITLE } from "../../../Ecosystem/View/constant";
import { IoClose } from "react-icons/io5";
import ForumCard from "../../../../kit/Card/ForumCard";
import CardSliderContainer from "../../../../kit/CardSliderContainer/CardSliderContainer";
import { MdClose } from "react-icons/md";
import LoadingSpinner from "../../../Common/Loading/Loading";
import PopUpModal from "../../../../kit/PopUpModal/PopUpModal";
import InlineInput from "../../../Ecosystem/Board/Form/components/InlineInput";
import Button from "../../../../kit/Button/Button";
import { PostButtonStyles } from "../../../Ecosystem/Board/Form/AddPost";
import AddPhoto from "../../../Ecosystem/Board/Form/components/AddPhoto";

import GiveAndRecieveImage from "../../../../assets/images/Activities/Common/give-receive.png";
import VolunteerBoardImage from "../../../../assets/images/Activities/CommunityBoard/card-cover.png";
import NetworkingBoardImage from "../../../../assets/images/Activities/TogetherInTheCity/telaviv/Mock/cardSlider/tel82.jpg";

const Wrapper = styled.form``;
const Header = styled.div`
  overflow: hidden;
`;
const IconWrapper = styled.div`
  width: 100%;
  height: 100px;
  padding: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;
export const Input = styled.input`
  display: block;
  position: ${(props) => props.position ?? "static"};
  top: ${(props) => props.top ?? "auto"};
  left: ${(props) => props.left ?? "auto"};
  width: ${(props) => props.width ?? "100%"};
  color: ${(props) => props.color ?? "#cccbcb"};
  text-align: center;
  font-family: ${(props) => props.fontFamily ?? "NotoSans-600"};
  font-size: ${(props) => props.fontSize ?? "19px"};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "600"};
  line-height: normal;
  margin: ${(props) => props.margin ?? "auto"};
  letter-spacing: 0.223px;
  border-radius: 10px;
  border: 1px solid #efefef;
  background: #fff;
  padding: ${(props) => props.padding ?? "5px"};
  &::placeholder {
    font-size: 18px;
    font-weight: 600;
    color: #cccbcb;
    font-family: "NotoSans-600";
  }
`;
const UserCard = styled.div`
  width: 100%;
  height: 209px;
  border-radius: 20px 20px 0px 0px;
  background: url(${(props) => (props.image ? props.image : "#f1f1f1")});
  position: relative;
  background-size: cover;
`;
const CoverPhotoDescription = styled.div`
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  color: #316788;
  text-align: center;
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
const AddImageIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 13px;
  right: 13px;
  padding: 5px;
  border-radius: 6.667px;
  background: #f0f4fc;
  box-shadow: 1.333px 1.333px 3.333px 0.667px rgba(0, 0, 0, 0.25);
`;
export const Section = styled.div`
  position: relative;
  width: ${({ isWideScreen }) => (isWideScreen ? "78%" : "auto")};
  margin: ${({ isWideScreen }) => (isWideScreen ? "auto" : "0")};
  min-height: 70px;
  padding: 15px;
  background: rgba(127, 179, 131, 0.04);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);
`;
export const AddButton = styled.div`
  margin: 0;
  cursor: pointer;
  color: #316788;
  text-align: right;
  font-family: "NotoSans-600";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 7.5px;
  background: none;
  position: absolute;
  bottom: 10px;
  right: ${({ isWideScreen }) => (isWideScreen ? "11%" : "10px")};
  /* right: 10%; */
  z-index: 2;
`;
const ConfirmButtonWrapper = styled.div`
  width: 30%;
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #ef7300;

  text-align: center;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const ConfirmButton = styled.button`
  background: none;
  border: none;
  color: #ef7300;

  text-align: center;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ThankYouContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Assistant;
  font-size: 45px;
  font-weight: 600;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
  color: #28b5b5;
  flex-direction: column;
  height: 90vh;
`;

const ThankYouBtn = styled.div`
  cursor: pointer;
  width: 70%;
  box-shadow: 0px 4px 10px 0px #42848033;
  border-radius: 30px;
  font-family: Assistant;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: center;
  background: #28b5b5;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 100px auto 0 auto;
  max-width: 245px;
`;

const DeleteButton = styled.div`
  cursor: pointer;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 15px;
`;
const ForumCardWrapper = styled.div`
  position: relative;
  margin-top: 15px;
`;
const MdDeleteForeverWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const SectionTitle = {
  Activity: "Activity",
  Event: "Event",
  Task: "Task",
  Extra: "Extra",
};

const EcosystemZoneOptions = [
  {
    title: "Israel",
    value: EcosystemZone.Israel,
  },
  {
    title: "USA",
    value: EcosystemZone.USA,
  },
  {
    title: "Europe",
    value: EcosystemZone.Europe,
  },
];

const emptyPostingCard = { name: "", image: "" };
const MAX_POSTING_CARDS = 5;

const CreatePage = () => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      postingCards: [{}],
      sponsors: [{}],
      volunteerForum: [
        {
          name: "Give & Receive - לוח תן וקבל עירוני",
          image: GiveAndRecieveImage,
        },
        {
          name: "Volunteer Board",
          image: VolunteerBoardImage,
        },
        {
          name: "Volunteer networking",
          image: NetworkingBoardImage,
        },
      ],
    },
  });
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { ecosystemId, sectionId, editId } = useParams();
  const [isCreatingEcosystem, setIsCreatingEcosystem] = useState(false);
  const [isEditingEcosystem, setIsEditingEcosystem] = useState(false);
  const [selectedCoverFilesList, setSelectedCoverFilesList] = useState([]);
  const [editDataSet, setEditDataSet] = useState(false);
  const [extraHubs, setExtraHubs] = useState([{ title: "" }]);
  const [showThankYou, setShowThankYou] = useState(false);
  const [createdId, setCreatedId] = useState();
  const { data: editData } = useGetEcosystemById(editId);
  const [selectZoneVisible, setSelectZoneVisible] = useState(true);
  const [selectedZone, setSelectedZone] = useState();
  const [sponsorsCreatePopupVisible, setSponsorsCreatePopupVisible] =
    useState(false);
  const [sponsorsPopUpData, setSponsorsPopUpData] = useState(undefined);
  const [editSections, setEditSections] = useState();
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false);
  const isEnglish = useMemo(
    () =>
      selectedZone === EcosystemZone.USA ||
      selectedZone === EcosystemZone.Europe,
    [selectedZone]
  );

  const setPostingCards = useCallback(
    (value) => {
      setValue("postingCards", value);
    },
    [setValue]
  );
  const setVolunteerForum = useCallback(
    (value) => {
      const volunteerForum = [
        {
          name: value[0],
          image: GiveAndRecieveImage,
        },
        {
          name: value[1],
          image: VolunteerBoardImage,
        },
        {
          name: value[2],
          image: NetworkingBoardImage,
        },
      ];
      setValue("volunteerForum", volunteerForum);
    },
    [setValue]
  );
  const postingCards = watch("postingCards");
  const volunteerForum = watch("volunteerForum");

  const setSponsors = useCallback(
    (value) => {
      setValue("sponsors", value);
    },
    [setValue]
  );
  const sponsors = watch("sponsors");

  const editCoverImages = useMemo(() => {
    return editData?.coverImages?.filter((image) => image !== null);
  }, [editData?.coverImages]);

  useEffect(() => {
    if (!editData || editDataSet) return;
    setValue("title", editData.title);
    setValue("postingTitle", editData.postingTitle);
    setValue("boardTitle", editData.boardTitle);
    setValue("sponsorsTitle", editData.sponsorsTitle);
    setPostingCards(editData.postingCards);
    setVolunteerForum(editData.volunteerForum);
    setSponsors(editData.sponsors ?? []);
    setValue(
      SectionTitle.Activity,
      editData?.hubs?.find?.((h) => h.name === "activity")?.title
    );
    setValue(
      SectionTitle.Event,
      editData?.hubs?.find?.((h) => h.name === "event")?.title
    );
    setValue(
      SectionTitle.Task,
      editData?.hubs?.find?.((h) => h.name === "task")?.title
    );
    setExtraHubs([
      ...editData?.hubs?.filter?.(
        (h) => h.name !== "activity" && h.name !== "event" && h.name !== "task"
      ),
      { title: "" },
    ]);
    setSelectedCoverFilesList(
      editData?.image && (!editCoverImages || editCoverImages?.length === 0)
        ? [editData.image]
        : editCoverImages
    );
    setEditSections(editData.sections);
    setEditDataSet(true);
  }, [
    editData,
    editDataSet,
    setPostingCards,
    setSponsors,
    setValue,
    editCoverImages,
    setVolunteerForum,
  ]);

  const getFilteredSections = () =>
    extraHubs?.filter((h) => h.title && h?.title?.trim?.() !== "");

  const deleteForumCard = (cardIndex) => {
    setPostingCards(postingCards?.filter((_, index) => cardIndex !== index));
  };

  const deleteSponsor = (cardIndex) => {
    setSponsors(sponsors?.filter((_, index) => cardIndex !== index));
  };

  const onSubmit = async (payload) => {
    const addedHubs = getFilteredSections();
    const postBody = {
      title: payload.title,
      ...(payload.image?.[0] && { image: payload.image?.[0] }),
      postingTitle: payload.postingTitle,
      boardTitle: payload.boardTitle,
      postingCards: payload.postingCards,
      volunteerForum: payload.volunteerForum.map((item) => item.name),
      sponsorsTitle: payload.sponsorsTitle,
      sponsors: payload.sponsors,
      coverImages: selectedCoverFilesList,
      hubs: [
        {
          name: "activity",
          title: payload[SectionTitle.Activity],
          isPrimary: true,
        },
        { name: "event", title: payload[SectionTitle.Event], isPrimary: true },
        { name: "task", title: payload[SectionTitle.Task], isPrimary: true },
        ...addedHubs,
      ],
      zoneType: selectedZone ?? EcosystemZoneOptions[0],
      userId: editData?.createdBy ?? currentUser?._id,
      parent: !sectionId && ecosystemId,
      ...(editData && editSections && { sections: editSections }),
    };
    try {
      if (editId && editData) {
        setIsEditingEcosystem(true);
        setConfirmBtnDisabled(true);
        await editEcosystem(editId, postBody);
        setCreatedId(editId);
        setShowThankYou(true);
        setConfirmBtnDisabled(false);
        return;
      }
      if (sectionId && ecosystemId) {
        await addCommunityToSection(postBody, sectionId, ecosystemId);
        setCreatedId(ecosystemId);
        setShowThankYou(true);
        return;
      }
      setIsCreatingEcosystem(true);
      const createdEcosystem = await createEcosystem(postBody);
      setCreatedId(createdEcosystem.data?.data?._id);
      setShowThankYou(true);
    } catch (ex) {
      setConfirmBtnDisabled(false);
      console.error("Failed to create ecosystem.", ex);
    } finally {
      setIsCreatingEcosystem(false);
      setIsEditingEcosystem(false);
    }
  };

  const sections = [
    {
      placeholder: "ערוך כותרת לפעילויות",
      buttonText: "הוסף פעילות",
      hookName: SectionTitle.Activity,
    },
    {
      placeholder: "ערוך כותרת לאירועים",
      buttonText: "הוסף אירוע",
      hookName: SectionTitle.Event,
    },
    {
      placeholder: "ערוך כותרת למשימות",
      buttonText: "הוסף למשימות",
      hookName: SectionTitle.Task,
    },
  ];

  const previewCoverImage = useMemo(() => {
    if (!selectedCoverFilesList || selectedCoverFilesList.length === 0) {
      return undefined;
    }
    if (
      typeof selectedCoverFilesList[selectedCoverFilesList.length - 1] ===
      "string"
    ) {
      return selectedCoverFilesList[selectedCoverFilesList.length - 1];
    }
    return URL.createObjectURL(
      selectedCoverFilesList[selectedCoverFilesList.length - 1]
    );
  }, [selectedCoverFilesList]);

  const renderSponsorsPopup = () => (
    <PopUpModal
      visible={sponsorsCreatePopupVisible}
      hidePopUpfunc={() => {
        setSponsorsCreatePopupVisible(false);
        setSponsorsPopUpData(undefined);
      }}
      useNormalDialog
      paddingPopUpDialog={"20px"}
      top={"15vh"}
    >
      <InlineInput
        width={"100%"}
        label={"Link URL:"}
        labelColor={"#1B5C6D"}
        labelFontSize={"14px"}
        fontSize={"14px"}
        overrideDirection={"ltr"}
        required
        onChange={(val) =>
          setSponsorsPopUpData({ ...sponsorsPopUpData, link: val })
        }
      />
      <div style={{ display: "flex", marginTop: "15px", gap: "10px" }}>
        <Button
          text={"פירסום"}
          styles={{ ...PostButtonStyles(), margin: "0", width: "50%" }}
          action={() => {
            setSponsors([...sponsors, sponsorsPopUpData]);
            setSponsorsPopUpData(undefined);
            setSponsorsCreatePopupVisible(false);
          }}
        />
        <div style={{ width: "50%", height: "33px" }}>
          <AddPhoto
            height={"33px"}
            border={"0.69px solid rgba(27, 92, 109, 1)"}
            color={"#fff"}
            borderRadius={"13px"}
            setSelectedFilesList={(files) =>
              setSponsorsPopUpData({
                ...sponsorsPopUpData,
                image: files?.[0],
              })
            }
          />
        </div>
      </div>
    </PopUpModal>
  );

  if (isCreatingEcosystem) {
    return <LoadingSpinner text={"Creating"} center />;
  }
  if (isEditingEcosystem) {
    return <LoadingSpinner text={"Editing"} center />;
  }

  if (showThankYou) {
    return (
      <ThankYouContainer>
        <div>תודה לך</div>
        <br />
        <br />
        <div style={{ width: "80%" }}>על יצירת קהילה חדשה 🎉</div>
        <ThankYouBtn
          onClick={() => {
            navigate(`/activities/view/${createdId}`, { replace: true });
          }}
        >
          חזור לדף הבית
        </ThankYouBtn>
      </ThankYouContainer>
    );
  }

  return (
    <>
      {renderSponsorsPopup()}
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <SelectionPopup
          title={"בחירת איזור - Zone"}
          visible={selectZoneVisible}
          setVisible={setSelectZoneVisible}
          onDismiss={(selected) => {
            setSelectedZone(selected);
          }}
          options={EcosystemZoneOptions}
          initiallySelected={editData && editData.zoneType}
        />
        <Header>
          <IconWrapper>
            <TogetherIcon width={"240"} height={"60"} />
            <Input
              placeholder="שם הקהילה"
              {...register("title")}
              width={"40%"}
              position={"absolute"}
              top={"50%"}
              left={"48%"}
              color={"#7FB383"}
              fontFamily={"InknutAntiqua-700"}
              fontWeight={"700"}
              padding={"0"}
            />
            <ConfirmButtonWrapper>
              <ConfirmButton>בטל</ConfirmButton>/
              <ConfirmButton disabled={confirmBtnDisabled}>אשר</ConfirmButton>
            </ConfirmButtonWrapper>
          </IconWrapper>
        </Header>
        <UserCard image={previewCoverImage}>
          <CoverPhotoDescription>
            בואו והצטרפו עם משפחתכם לסיורים והדרכות בעיר
          </CoverPhotoDescription>
          <AddImageIconWrapper>
            <AddPhoto
              allowMultipleSelection
              selectedFilesList={selectedCoverFilesList}
              setSelectedFilesList={setSelectedCoverFilesList}
            />
          </AddImageIconWrapper>
        </UserCard>
        <SearchPart />
        {sections.map((s) => (
          <Section>
            <Input
              width={"80%"}
              color="#316788"
              fontSize={"22px"}
              placeholder={s.placeholder}
              {...register(s.hookName)}
            />
            <AddButton>{s.buttonText}</AddButton>
          </Section>
        ))}
        {extraHubs?.map?.((e, index) => (
          <Section>
            {index !== extraHubs.length - 1 && (
              <DeleteButton
                onClick={() => {
                  let modifiedHubs = [...extraHubs];
                  modifiedHubs[index] = undefined;
                  modifiedHubs = modifiedHubs.filter((h) => h != null);
                  setExtraHubs(modifiedHubs);
                }}
              >
                <IoClose />
              </DeleteButton>
            )}
            <Input
              width={"80%"}
              color="#316788"
              fontSize={"22px"}
              placeholder={"הוסף כותרת"}
              value={e.title}
              onChange={(e) => {
                const modifiedHubs = [...extraHubs];
                modifiedHubs[index] = { title: e?.target?.value ?? "" };
                setExtraHubs(modifiedHubs);
              }}
              onBlur={() => {
                setExtraHubs([...getFilteredSections(), { title: "" }]);
              }}
            />
            <AddButton>{"הוסף"}</AddButton>
          </Section>
        ))}
        {editData &&
          editData?.sections.map?.((s, index) => (
            <Section>
              <Input
                width={"80%"}
                color="#316788"
                fontSize={"22px"}
                placeholder={"הוסף כותרת"}
                defaultValue={s?.title ?? SECTION_TITLE[s?.type]}
                onChange={(e) => {
                  const modifiedSections = [...editSections];
                  modifiedSections[index] = {
                    ...modifiedSections[index],
                    title: e?.target?.value ?? "",
                  };
                  setEditSections(modifiedSections);
                }}
              />
              <AddButton>{"הוסף"}</AddButton>
            </Section>
          ))}
        <Section>
          <Input
            width={"80%"}
            placeholder={"צור דף פרסום"}
            {...register("postingTitle")}
          />
          <AddButton
            onClick={() =>
              postingCards.length < MAX_POSTING_CARDS &&
              setPostingCards([...postingCards, emptyPostingCard])
            }
          >
            {"הוסף"}
          </AddButton>
          <CardSliderContainer>
            {postingCards?.map?.((card, index) => (
              <ForumCardWrapper key={index}>
                <ForumCard
                  key={index}
                  index={`postingCards-${index}`}
                  name={`postingCards[${index}]`}
                  setValue={setValue}
                  placeholder="כותרת הדף"
                  defaultName={card.name}
                  defaultImage={card.image}
                />
                <MdDeleteForeverWrapper onClick={() => deleteForumCard(index)}>
                  <MdClose color="red" size={24} />
                </MdDeleteForeverWrapper>
              </ForumCardWrapper>
            ))}
          </CardSliderContainer>
        </Section>
        <Section>
          <Input
            width={"80%"}
            placeholder={isEnglish ? "Volunteer Forum" : "פורום מתנדבים"}
            {...register("boardTitle")}
          />
          <AddButton>{"הוסף"}</AddButton>
          <CardSliderContainer>
            {volunteerForum?.map?.((card, index) => (
              <ForumCardWrapper key={index}>
                <ForumCard
                  key={index}
                  index={`volunteerForum-${index}`}
                  name={`volunteerForum[${index}]`}
                  setValue={setValue}
                  placeholder="כותרת הדף"
                  defaultName={card.name}
                  defaultImage={card.image ?? VOLUNTEER_BOARD_IMAGES[index]}
                  canEditImage={false}
                />
              </ForumCardWrapper>
            ))}
          </CardSliderContainer>
        </Section>
        <Activities
          data={emergencyData}
          hasActions
          SliderComponent={Emergency}
          sliderProps={{
            enTitle: "Emergency ",
            hebTitle: "התארגנות וסיוע במצבי חירום",
            data: emergencyData,
            hasShadow: true,
            englishLogo: isEnglish,
          }}
        />
        <Section>
          <Input
            width={"80%"}
            placeholder={"שותפים לעשייה"}
            {...register("sponsorsTitle")}
          />
          <AddButton onClick={() => setSponsorsCreatePopupVisible(true)}>
            {"הוסף"}
          </AddButton>
          <CardSliderContainer>
            {sponsors?.map?.((card, index) => (
              <ForumCardWrapper key={index}>
                <ForumCard
                  key={`sponsors-${index}`}
                  showEditOverlay
                  index={index}
                  name={`sponsors[${index}]`}
                  setValue={setValue}
                  placeholder="link"
                  defaultName={card.link}
                  defaultImage={card.image}
                  customNameAttr={"link"}
                  squareStyle
                />
                <MdDeleteForeverWrapper onClick={() => deleteSponsor(index)}>
                  <MdClose color="red" size={24} />
                </MdDeleteForeverWrapper>
              </ForumCardWrapper>
            ))}
          </CardSliderContainer>
        </Section>
        <div style={{ height: "75px" }} />
      </Wrapper>
    </>
  );
};

export default CreatePage;
