import React from "react";

export default function Messages({ color = "#007897" }) {
  return (
    <svg
      width="29"
      height="21"
      viewBox="0 0 29 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.0001 2.15007C29.0001 7.50741 29.0001 12.8653 29.0001 18.2227C28.9825 18.2658 28.9581 18.3072 28.9473 18.352C28.6471 19.596 27.671 20.3724 26.3969 20.3724C18.4483 20.373 10.4997 20.3735 2.55163 20.3718C1.1266 20.3713 0.00178866 19.247 0.00122114 17.8231C-0.00104891 12.7302 0.00065363 7.6368 8.61153e-05 2.54392C8.61153e-05 1.80559 0.282708 1.19267 0.809362 0.681909C1.18846 0.314727 1.66007 0.138797 2.1521 -0.000244141C10.3839 -0.000244141 18.6157 -0.000244141 26.8475 -0.000244141C27.4303 0.14731 27.953 0.394746 28.3628 0.854433C28.697 1.23013 28.8667 1.6796 29.0001 2.15007ZM3.03345 1.71252C3.01472 1.73749 2.99543 1.76189 2.9767 1.78686C3.05332 1.84248 3.13958 1.88844 3.20598 1.95428C6.70471 5.45925 10.2017 8.96592 13.6993 12.472C14.2345 13.0083 14.764 13.0112 15.2952 12.4794C18.7996 8.96706 22.3034 5.45357 25.8073 1.94009C25.8702 1.87653 25.9281 1.80729 26.0144 1.71195C18.3257 1.71252 10.6796 1.71252 3.03345 1.71252ZM10.2846 11.4897C7.85279 13.8885 5.44028 16.2681 3.01529 18.6602C10.6784 18.6602 18.3257 18.6602 25.9985 18.6602C23.5627 16.2517 21.148 13.8636 18.7326 11.4755C18.0153 12.1894 17.2576 12.9391 16.5051 13.6933C15.315 14.8862 13.6817 14.8834 12.4894 13.6865C11.7374 12.9317 10.9798 12.182 10.2846 11.4897ZM1.72363 3.0161C1.72363 7.77584 1.72363 12.6088 1.72363 17.3492C4.11627 14.9867 6.54296 12.5906 8.97874 10.1855C6.54353 7.77925 4.11797 5.38207 1.72363 3.0161ZM27.293 3.00248C24.8833 5.3815 22.4572 7.77698 20.0203 10.1832C22.4612 12.594 24.8902 14.9935 27.293 17.3663C27.293 12.6003 27.293 7.77414 27.293 3.00248Z"
        fill={color}
      />
    </svg>
  );
}
