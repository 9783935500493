import React from "react";

const GrayArrowUpIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="10"
      fill="none"
      viewBox="0 0 20 10"
    >
      <g>
        <path
          stroke={color ?? "#9FA3A8"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.141"
          d="M2 8.545l7.324-5.461 7.324 5.46"
        ></path>
      </g>
    </svg>
  );
};

export default GrayArrowUpIcon;
