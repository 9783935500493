import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContact } from "../api";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";

function useCreateContact() {
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (payload) => createContact(currentUser?._id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries("contacts");
    },
  });

  return { createContact: mutateAsync, isLoading };
}

export default useCreateContact;
