import React, { createContext, useState } from "react";

export const PreviousRouteContext = createContext();

export function PreviousRouteProvider({ children }) {
  const [previousRoute, setPreviousRoute] = useState("");

  const updatePreviousRoute = (newRoute) => {
    setPreviousRoute(newRoute);
  };

  return (
    <PreviousRouteContext.Provider
      value={{ previousRoute, updatePreviousRoute }}
    >
      {children}
    </PreviousRouteContext.Provider>
  );
}
