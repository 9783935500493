import React from "react";
import styled from "styled-components";
import LikedGreenHeartIcon2 from "../../../../assets/icons/Activities/TogetherInTheCity/gderot/LikedGreenHeartIcon2";
import GreenHeartIcon2 from "../../../../assets/icons/Activities/TogetherInTheCity/gderot/GreenHeartIcon2";
import PlayIcon from "../../../../assets/icons/PlayIcon";
import { ShareIcon } from "../../../../assets/icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: ${(props) => props.height ?? "79px"};
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  background: #fff;
  color: #316788;
  margin: 11px 0;
  background-color: #f1f3f4;
`;
const ImageWrapper = styled.div`
  height: 100%;
  width: ${(props) => props.width ?? "113px"};
  flex-shrink: 0;
  padding: 0px;
  margin: 0;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
`;
const ContentAndImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;
const TextWrapper = styled.div`
  width: ${(props) => `100%`};
  margin: 0 5px;
  direction: ${(props) => (props.lang === "heb" ? "rtl" : "ltr")};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TitleWrapper = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const OhterInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;
const Text = styled.p`
  color: ${({ color }) => color ?? "#316788"};
  font-family: Assistant;
  width: 100%;
  text-align: center;
  font-size: ${(props) => props.titleFontSize ?? "16px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 8px 0;
`;
const TextWithLink = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
`;
const LinkedText = styled.div`
  width: 70%;
  color: #1894cd;
  font-family: Assistant;
  font-size: 15px;
  font-weight: 700;
  text-decoration-line: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Date = styled.p`
  color: #316788;
  font-family: Assistant;
  width: 45%;
  text-align: center;
  font-size: ${(props) => props.otherDetailsFontSize ?? "14px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;
const Share = styled.div``;
const GreenHeartIconWrapper = styled.div`
  width: 21px;
  height: 18px;
`;

const Card = ({
  image,
  title,
  date,
  time,
  liked,
  height,
  imageWidth,
  showHeart = true,
  titleFontSize,
  otherDetailsFontSize,
  onClick,
  titlelink,
  titleColor,
}) => {
  return (
    <Wrapper onClick={onClick} height={height}>
      <ContentAndImageWrapper>
        <ImageWrapper width={imageWidth}>
          <Image src={image} />
        </ImageWrapper>
        <TextWrapper imageWidth={imageWidth}>
          <TitleWrapper>
            {titlelink ? (
              <TextWithLink titleFontSize={titleFontSize}>
                <LinkedText>{title}</LinkedText>
                <PlayIcon />
              </TextWithLink>
            ) : (
              <Text color={titleColor} titleFontSize={titleFontSize}>
                {title}
              </Text>
            )}
          </TitleWrapper>
          <OhterInfoWrapper>
            <Date otherDetailsFontSize={otherDetailsFontSize}>{date}</Date>{" "}
            <IconWrapper>
              <Share>
                <ShareIcon />
              </Share>
              {showHeart && (
                <GreenHeartIconWrapper>
                  {liked ? (
                    <LikedGreenHeartIcon2 color={"#316788"} />
                  ) : (
                    <GreenHeartIcon2 color={"#316788"} />
                  )}
                </GreenHeartIconWrapper>
              )}
            </IconWrapper>
          </OhterInfoWrapper>
        </TextWrapper>
      </ContentAndImageWrapper>
    </Wrapper>
  );
};

export default Card;
