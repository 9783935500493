import { useQuery } from "@tanstack/react-query";
import { fetchByIds } from "./api";

const useGetEcosystemByIds = (ids) => {
  const { data, isLoading } = useQuery({
    queryKey: ["ecosystems", ids],
    queryFn: () => fetchByIds(ids),
  });

  return { data, isLoading };
};

export default useGetEcosystemByIds;
