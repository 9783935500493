import React, { useState } from "react";
import styled from "styled-components";
import { isRTLText } from "../RichTextEditor/htmlUtils";

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.div`
  color: #696d72;
  font-size: 10px;
  font-weight: 600;
  text-align: start;
  direction: ${(props) => props.direction};
  ${(props) => props.style}
`;

const HtmlContent = styled.div`
  color: #696d72;
  font-size: 10px;
  font-weight: 600;
  text-align: start;
  direction: ${(props) => props.direction};
  ${(props) => props.style}
`;

const MoreButton = styled.div`
  width: 44px;
  color: #696d72;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 5px;
  margin-left: auto;
  ${(props) => props.style}
`;

const ExpandableText = ({
  text,
  maxLength = 150,
  textStyle = {},
  buttonStyle = {},
  displayAsHtml = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const truncatedText =
    text.length <= maxLength || isExpanded
      ? text
      : `${text.substring(0, maxLength).trim()}... `;

  return (
    <TextContainer style={textStyle}>
      {!displayAsHtml ? (
        <Text direction={isRTLText(text) ? "rtl" : "ltr"} style={textStyle}>
          {truncatedText}
        </Text>
      ) : (
        <HtmlContent
          style={textStyle}
          dangerouslySetInnerHTML={{
            __html: isExpanded
              ? text
              : `${text.substring(0, maxLength).trim()}... `,
          }}
          direction={isRTLText(text) ? "rtl" : "ltr"}
        />
      )}
      {text.length > maxLength && (
        <MoreButton onClick={toggleIsExpanded} style={buttonStyle}>
          {isExpanded ? "Less..." : "More..."}
        </MoreButton>
      )}
    </TextContainer>
  );
};

export default ExpandableText;
