import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TextareaWrapper from "./components/TextareaWrapper";
import CheckboxRow from "./components/CheckboxRow";
import InlineInput from "./components/InlineInput";
import Button from "../../../../kit/Button/Button";
import AddPhoto from "./components/AddPhoto";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../../store/userContext";
import { createPost, editPost } from "../api";
import { useNavigate } from "react-router-dom";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";
import { LanguageContext } from "../../../../store/languageContext";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import RichTextEditor from "../../../../kit/RichTextEditor/RichTextEditor";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 10px;
  width: 100%;
`;
const NeedPhotoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
`;

export const PostButtonStyles = (english) => ({
  color: "#EF7300",
  shadow: "0px 1.3px 3.26px 0px rgba(66, 132, 128, 0.20)",
  border: "1px solid #EF7300",
  borderRadius: "9.783px",
  width: "148px",
  fontSize: "16px",
  fontWeight: "700",
  height: "33px",
  cursor: "pointer",
  direction: english ? "ltr" : "rtl",
  margin: !english ? "0 auto 0 14px" : "0 14px 0 auto",
  marginTop: "0px",
});

function PostItems({
  posts,
  setPosts,
  postData,
  onPostSuccess,
  zone,
  type,
  section,
  isEditMode = false,
}) {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: isEditMode ? postData : {},
  });
  const { currentUser, isAuthenticated } = useContext(UserContext);
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const navigate = useNavigate();

  const { currentLanguage } = useContext(LanguageContext);
  const category = watch("category");
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);

  const onSubmit = async (data) => {
    isEditMode ? handleEditPost(data) : handleCreatePost(data);
  };

  useEffect(() => {
    if (isEditMode && !descriptionUpdated && postData?.description) {
      setDescriptionContent(postData?.description);
      setDescriptionUpdated(true);
    }
  }, [postData, descriptionUpdated, isEditMode]);

  const handleCreatePost = async (data) => {
    delete data.photo;

    const postData = {
      ...data,
      user_id: currentUser._id,
      photos: selectedFilesList,
      zone: zone,
      type: type ?? undefined,
      description: descriptionContent,
    };
    try {
      const response = await createPost(postData);
      setPosts([response.data.post, ...posts]);
      onPostSuccess?.();
    } catch (error) {
      console.error("Failed to create post.", error);
    }
  };

  const handleEditPost = async (data) => {
    const postData = {
      ...data,
      user_id: currentUser._id,
      zone: zone,
      type: type ?? undefined,
      photos: selectedFilesList,
      description: descriptionContent,
    };
    try {
      const response = await editPost(data?._id, postData);
      setPosts((posts) =>
        posts.map((post) =>
          post._id === data?._id ? response.data.post : post
        )
      );
      onPostSuccess?.();
    } catch (error) {}
  };

  const getCategories = (english) => [
    { label: english ? "General" : "כללי", value: "general" },
    { label: english ? "Sport" : "ספורט", value: "sport" },
    { label: english ? "Education" : "לימודים", value: "education" },
    { label: english ? "Pets" : "בע״ח", value: "pets" },
    { label: english ? "Electronic" : "אלקטרוניקה", value: "electronic" },
  ];

  const handleLogin = () => {
    setLoginRoute(
      `/activities/board/zone/${zone}${section ? `/section/${section}` : ""}`
    );
    navigate("/login");
  };

  if (!isAuthenticated) {
    return handleLogin();
  }

  const textProps = {
    labelColor: "#1B5C6D",
    labelFontSize: "14px",
    fontSize: "14px",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <RichTextEditor
          content={descriptionContent}
          setContent={setDescriptionContent}
          label={
            currentLanguage === LANGUAGES.HEBREW.value
              ? "תיאור:"
              : "Description:"
          }
          direction={currentLanguage === LANGUAGES.HEBREW.value ? "rtl" : "ltr"}
        />
        <CheckboxRow
          label={
            currentLanguage === LANGUAGES.HEBREW.value ? "קטגוריה" : "Category"
          }
          categories={getCategories(
            currentLanguage === LANGUAGES.ENGLISH.value
          )}
          selectedCategory={category}
          register={{ ...register("category") }}
          required
        />
        <NeedPhotoWrapper>
          <TextareaWrapper
            label={
              currentLanguage === LANGUAGES.HEBREW.value
                ? "הבקשה:"
                : "What is needed:"
            }
            register={{ ...register("needed") }}
            {...textProps}
            required
          />
          <AddPhoto
            register={{ ...register("photos") }}
            allowMultipleSelection
            selectedFilesList={selectedFilesList}
            setSelectedFilesList={setSelectedFilesList}
          />
        </NeedPhotoWrapper>
        <NeedPhotoWrapper>
          <Button
            text={
              currentLanguage === LANGUAGES.HEBREW.value ? "פירסום" : "post"
            }
            styles={PostButtonStyles(
              currentLanguage === LANGUAGES.ENGLISH.value
            )}
          />
          <InlineInput
            width={"50%"}
            label={
              currentLanguage === LANGUAGES.HEBREW.value ? "קוד" : "Your Code:"
            }
            {...textProps}
            showStar={true}
            register={{ ...register("code") }}
            required
          />
        </NeedPhotoWrapper>
      </Wrapper>
    </div>
  );
}

export default PostItems;
