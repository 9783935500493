import styled from "styled-components";
import { useContext, useEffect, useState, useId } from "react";
import { Link } from "react-router-dom";
import ArrowUpAndDownIcons from "../../../../kit/ArrowUpAndDownIcons/ArrowUpAndDownIcons";
import { IoClose } from "react-icons/io5";
import { CardDetailsContext } from "../../../../store/cardDetailsContext";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import MuiButton from "../../../../kit/Button/MuiButton";
import { ArrowDown } from "../../../../assets/icons/Arrows";

const Wrapper = styled.div`
  position: relative;
  background: ${(props) =>
    props.isWideScreen || props.isMediumScreen
      ? "rgba(127, 179, 131, 0.04)"
      : props.background ?? ""};
  box-shadow: ${(props) =>
    props.boxShadow ??
    (props.isWideScreen || props.isMediumScreen
      ? "1.383px 1.383px 3.457px 0.691px rgba(0, 0, 0, 0.15)"
      : "2px 2px 5px 1px rgba(0, 0, 0, 0.15)")};
  padding: ${(props) => (props.hasTitle ? "0 0 20px 0" : "20px 0")};
  width: ${({ width, isWideScreen, isMediumScreen }) =>
    width ? width : isWideScreen || isMediumScreen ? "94%" : "100%"};
  margin: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "20px auto" : "auto"};
`;
const PostWrapper = styled.div`
  width: calc(100% - 40px);
  text-align: center;
  margin: 0 20px;
  margin-top: 10px;
`;
const Post = styled.img`
  width: 100%;
`;
const CreateWrapper = styled.div`
  position: absolute;
  top: ${(props) =>
    props.hasTitle ? (props.hasopenedCard ? "65px" : "55px") : "6px"};
  right: 0;
  display: flex;
  justify-content: flex-end;
`;
const Create = styled(Link)`
  color: #316788;
  font-size: 13.974px;
  font-weight: 600;
  text-decoration-line: underline;
  margin-left: 15px;
`;
export const DeleteButton = styled.div`
  cursor: pointer;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 15px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const buttonStyle = {
  color: "#316788",
  fontFamily: "Assistant",
  fontSize: 18,
  fontWeight: 700,
  textTransform: "none",
  borderRadius: 20,
  boxShadow: "none",
  background: "#F1F3F4",
  height: 34,
  minWidth: 172,
  "&:hover": {
    background: "#F1F3F4",
  },
};

function Page({
  width,
  TitleComponent,
  titleProps,
  SliderComponent,
  sliderProps,
  LinearComponent,
  linearProps,
  post, //Mock only
  recentPost,
  isStudent,
  data,
  hasActions = false,
  sideLink,
  openedCard,
  InitialStateOfCards,
  InitialStateOfLinearCards,
  background,
  boxShadow,
  showArrowUpOrDown = false,
  showDelete,
  deleteOnClick,
  isLinearOpen = false,
  hasUnfocus = false,
  hasViewMore,
}) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();

  const id = useId();

  const { expandedSectionId, setExpandedSectionId } =
    useContext(CardDetailsContext);

  const [arrowUpDownId, setArrowUpDownId] = useState();

  const [activeSlide, setActiveSlide] = useState(
    openedCard === 0 ? 0 : openedCard || null
  );
  const [isTasksVisible, setIsTasksVisible] = useState(
    InitialStateOfLinearCards ?? InitialStateOfCards ?? false
  );
  const [isCardsVisible, setIsCardsVisible] = useState(
    InitialStateOfCards ?? true
  );

  const handleFocus = (index) => {
    setActiveSlide(index);
    setIsTasksVisible(true);
  };

  const handleUnFocus = (index) => {
    if (hasUnfocus && isTasksVisible) {
      console.log("close");
      setActiveSlide(null);
      setIsTasksVisible(false);
    }
  };

  const handleArrowOnClick = () => {
    setExpandedSectionId(isTasksVisible ? null : arrowUpDownId);
    setActiveSlide(null);
  };

  useEffect(() => {
    if (isLinearOpen) {
      setIsTasksVisible(true);
    } else {
      setIsTasksVisible(arrowUpDownId === expandedSectionId);
    }
  }, [arrowUpDownId, expandedSectionId, isLinearOpen]);

  useEffect(() => {
    if (arrowUpDownId) return;
    setArrowUpDownId(id);
  }, [arrowUpDownId, id]);

  return (
    <Wrapper
      onClick={handleUnFocus}
      width={width}
      isStudent={isStudent}
      hasTitle={TitleComponent}
      background={background}
      boxShadow={boxShadow}
      isWideScreen={isWideScreen}
      isMediumScreen={isMediumScreen}
    >
      {TitleComponent && <TitleComponent {...titleProps} />}
      {SliderComponent && !hasActions && (
        <SliderComponent
          {...sliderProps}
          isCardsVisible={showArrowUpOrDown ? isTasksVisible : isCardsVisible}
        />
      )}
      {SliderComponent && hasActions && (
        <SliderComponent
          {...sliderProps}
          activeSlide={activeSlide}
          isCardsVisible={showArrowUpOrDown ? isTasksVisible : isCardsVisible}
          handleClick={(index) => {
            sliderProps?.handleClick?.(index);
            handleFocus(index);
          }}
          handleFocus={handleFocus}
          handleUnFocus={handleUnFocus}
        />
      )}
      {LinearComponent && !hasActions && (
        <LinearComponent {...linearProps} isTasksVisable={isTasksVisible} />
      )}
      {LinearComponent && hasActions && (
        <LinearComponent
          {...linearProps}
          isTasksVisable={isTasksVisible}
          data={hasActions && data ? data[activeSlide]?.linear || [] : []}
        />
      )}
      {post && !recentPost && (
        <PostWrapper>
          <Post src={post} alt="" />
        </PostWrapper>
      )}
      {recentPost && <PostWrapper>{recentPost}</PostWrapper>}
      {showArrowUpOrDown && (
        <ArrowUpAndDownIcons
          status={isTasksVisible}
          changeStatus={handleArrowOnClick}
        />
      )}
      {sideLink && (
        <CreateWrapper
          hasTitle={TitleComponent}
          hasopenedCard={openedCard >= 0}
        >
          <Create to={sideLink?.link}>{sideLink?.title}</Create>
        </CreateWrapper>
      )}
      {showDelete && (
        <DeleteButton onClick={deleteOnClick}>
          <IoClose />
        </DeleteButton>
      )}
      {hasViewMore && (
        <ButtonWrapper>
          <MuiButton
            variant="contained"
            endIcon={<ArrowDown />}
            sx={buttonStyle}
          >
            View More
          </MuiButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
}

Page.defaultProps = {
  post: "",
};

export default Page;
