import { useContext, useMemo } from "react";
import { isUserAuthorizedForAction } from "../domain/Auth/login/utils";
import { UserContext } from "../store/userContext";

const useIsUserAuthorizedForCreate = (isPrivate, targetUserId) => {
  const { currentUser } = useContext(UserContext);
  const isUserAuthorizedForCreate = useMemo(
    () => isPrivate && isUserAuthorizedForAction(currentUser, targetUserId),
    [currentUser, isPrivate, targetUserId]
  );
  return isUserAuthorizedForCreate;
};

export default useIsUserAuthorizedForCreate;
