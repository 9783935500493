import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "./api";

function useGetCurrentUser() {
  const { isError, isLoading, data } = useQuery({
    queryKey: ["currentUser"],
    queryFn: () => getCurrentUser(),
    retry: false,
  });

  return {
    data,
    isLoading,
    isError,
  };
}

export default useGetCurrentUser;
