import React, { useContext, useEffect, useState } from "react";
import OverlayInput from "../components/OverlayInput";
import SendButton from "../components/SendButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { VoiceContext } from "../components/Voice";
import useReadUserLocation from "../../../../customHooks/useReadUserLocation";
import { useAudioRecorder } from "react-audio-voice-recorder";
import Map from "../../../../kit/Map/Map";
import { fetchLocationName } from "../../../../kit/Map/MapUtils";
import { HiArrowLeft } from "react-icons/hi";
import useStopBodyScroll from "../../../../customHooks/useStopBodyScroll";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import BellIcon from "../../../../assets/icons/Activities/LandingPage/BellIcon";
import OverlayCard from "../components/OverlayCard";
import MuiButton from "../../../../kit/Button/MuiButton";

import ShareWrapper from "../../../../kit/Share/ShareWrapper";
import WhatsAppIcon from "../../../../assets/icons/Common/Layout/WhatsAppIcon.svg";
import { DoubleBorder, FlexRow } from "../../../Common/Styled";
import OrangeAppleIcon from "../../../../assets/icons/Common/NavBar/OrangeAppleIcon";
import EmergencyContactPopUp from "../EmergencyOverlaysContext.js";

import MicrophoneIcon from "../../../../assets/icons/MicrophoneIcon";
import ShareVia from "../../../Ecosystem/Public/Map/components/ShareVia";
import { EmergencyOverlaysContext } from "../EmergencyOverlaysContext";
import { EMERGENCY_LANDING_ROUTE } from "../../../Auth/landingPage/LandingVariants/AbstractEmergencyLanding";

const Modal = styled(({ border, ...props }) => <Dialog {...props} />)`
  & .MuiDialog-paper {
    border-radius: 20px;
    background: #fafffa;
    display: flex;
    flex-direction: column;

    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    min-width: 359px;
    overflow-x: hidden;
    border: ${(props) => props.border};
  }
`;

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Grayed-out background color with some transparency */
  z-index: 99;
`;

const Title = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0 0 0;
  color: ${(props) => props.color ?? "var(--preview-txt, #105F84)"};
  font-family: "NotoSans-600";
  font-size: ${(props) => props.fontSize ?? "24px"};
  margin-bottom: 30px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  gap: 12px;
`;
const Cancel = styled.div`
  text-align: center;
  cursor: pointer;
  color: #105f84;
  font-family: "NotoSans-600";
  font-size: 18px;
  margin: 0 0px;
  background-color: #fff;
  box-shadow: 5px 2px 5px 0px rgba(0, 0, 0, 0.25);
  width: 75px;
  height: 50px;
  border-radius: 10.717px;
  border: ${(props) => props.border};
  justify-content: center;
  align-items: center;
`;
const InformationContainer = styled.div`
  margin: auto;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
`;
const Information = styled.p`
  margin: 0;
  color: var(--preview-txt, #58656d);
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const Text = styled.p`
  margin: 0;
  color: ${(props) => props.color ?? "#ffffff"};
  font-family: "NotoSans-600";
  font-size: 19.09px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;
const shareButtonStyle = {
  color: "#105F84",
  fontSize: 20,
  fontWeight: 600,
  fontFamily: "Assistant",
  borderRadius: "10.717px",
  width: 228,
  height: 51,
  display: "flex",
  justifyContent: "space-between",
};

const ShareButtonContainer = styled.div`
  display: flex;
  margin: 10px;

  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

function ReassuranceOverlay({
  overlay,
  setOverlay,
  isOrganization,

  isReadyToSend,
  action,
  isEmergencyOverlay,
  onShare,
  hasColumnShare,
}) {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm();
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [searchUserLocation, setSearchUserLocation] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
  } = useAudioRecorder();

  const { listening, StopRecord } = useContext(VoiceContext);
  const {
    setShowOverlay,
    setReassurance,
    setUserInformation,
    theme,
    setEmergencyContactPopUpData,
  } = useContext(EmergencyOverlaysContext);
  const handleCloseClick = () => {
    setReassurance(false);
    if (showMap) {
      setShowMap(false);
      return;
    }
    setOverlay(null);
    action("");
    if (isRecording) {
      stopRecording();
    }
    if (listening) {
      StopRecord?.();
    }
  };

  const { userLocation } = useReadUserLocation();
  const [locationBgColor, setLocationBgColor] = useState();
  const handleFormSubmit = (data) => {
    if (listening) {
      StopRecord?.();
    }
    action({
      ...data,
      recording: {
        blob:
          recordingDuration && recordingDuration != "0"
            ? recordingBlob
            : undefined,
        duration: recordingDuration,
      },
      selectedLocation: { loc: searchUserLocation, name: locationName },
      phone: phoneNumber,
    });
  };

  const endRecord = () => {
    setRecordingDuration(recordingTime);
    stopRecording();
  };

  useEffect(() => {
    if (recordingTime >= 30) {
      endRecord();
    }
  }, [recordingTime]);

  useStopBodyScroll(overlay);
  useEffect(() => {
    if (!userLocation) {
      return;
    }
    setSearchUserLocation(userLocation);
    fetchLocationName(userLocation).then((name) => {
      setLocationName(name);
    });
  }, [userLocation]);

  const micOnClick = () => {
    if (isRecording) {
      endRecord();
      return;
    }
    startRecording();
  };

  const renderMessageInput = () => (
    <OverlayInput
      register={register}
      setValue={setValue}
      isReadyToSend={isReadyToSend}
      inputHeight={"160px"}
      title={"Message\u00A0הודעה"}
      placeholder={":תוכן הודעה"}
      showBottomMic={false}
      showMicrophone={true}
      useSpeakIcon
      width={"100%"}
      margin={"auto"}
    />
  );

  const renderContent = () => (
    <>
      <Title fontSize={overlay?.titleFontSize} color={overlay?.titleColor}>
        <BellIcon width={"37"} height={"40"} color={"#7FB383"} />
        <div style={{ marginLeft: "15px" }}>{"שלח הרגעה"}</div>
      </Title>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <OverlayCard
          showShakeAnimation
          Icon={MicrophoneIcon}
          iconWrapperBgColor={"#ECF6F9"}
          IconColor={"#7FB383"}
          iconText={"Record"}
          iconSize={"36px"}
          counter={
            isRecording
              ? recordingTime
              : recordingDuration && recordingDuration != 0
              ? recordingDuration
              : undefined
          }
          showDelete={!!(recordingDuration && recordingDuration != 0)}
          onDeleteClick={() => {
            setRecordingDuration(0);
            stopRecording();
          }}
          title={isReadyToSend ? "הקליטו הודעת הרגעה" : "הקלט הודעתך"}
          titleFontSize={"20px"}
          isVoiceComponent
          isReadyToSend={isReadyToSend}
          wrapperBgColor={
            recordingTime || (recordingDuration && recordingDuration != 0)
              ? "#EBDBDD"
              : "#ECF6F9"
          }
          onClick={micOnClick}
        />
      </div>
      {renderMessageInput({ isOrganization: false, isEmergencyOverlay: true })}
      <ButtonWrapper>
        <Cancel
          onClick={handleCloseClick}
          border={`1px solid ${theme?.primary}`}
        >
          <div>חזור</div>
          <div>Back</div>
        </Cancel>
        <DoubleBorder borderColor={theme?.primary ?? "rgba(127, 179, 131, 1)"}>
          <SendButton
            color={"#fff"}
            backgroundColor={theme?.primary ?? "rgba(127, 179, 131, 1)"}
            border={"0"}
            padding={"10px 5px"}
            borderRadius={"5px"}
            width={"100%"}
            marginTop={0}
            isReadyToSend={isReadyToSend}
            onSubmit={() => {
              setShowOverlay(false);
              setReassurance(false);
              setUserInformation(false);
              navigate(EMERGENCY_LANDING_ROUTE);
            }}
            text={
              <>
                <Text>אישור</Text>
                {" - "}
                <Text>Confirm</Text>
              </>
            }
          />
        </DoubleBorder>
      </ButtonWrapper>
      <div
        style={{
          textAlign: "center",
          fontSize: "5px",
          margin: "15px",
        }}
      >
        <Text color={"#58656d"}>Share via - שתף באמצעות</Text>
      </div>
      <ShareButtonContainer>
        <ShareWrapper
          shareProps={{
            shareData: {
              title: "Together Ecosystem\nCommunity Volunteer Network\n",
              text: isEmergencyOverlay
                ? "נשלחה אליך בקשת סיוע דחוף מחבר/ה קהילתך\nלפרטים נוספים\nיש ללחוץ על הקישור/ המפה המצורפים"
                : "בקשת סיוע/ אות מצוקה נשלחו אליך 🚨\n\nמקרה חירום של חבר/ה בקהילתך. \n\nלפרטים נוספים כולל מפת הגעה יש ללחוץ על הלינק/ המפה  המצורפים:",
            },
            can: isReadyToSend,
          }}
        >
          <MuiButton
            variant="outlined"
            sx={{
              fontSize: 20,
              fontWeight: 600,
              fontFamily: "Assistant",
              borderRadius: "7.717px",
              width: 90,
              height: 51,
              display: "flex",
              justifyContent: "space-between",
              border: `1.5px solid ${theme?.primary}`,
              "&:hover": {
                border: `3px solid ${theme?.primary}`,
              },
            }}
          >
            <img src={WhatsAppIcon} alt="WhatsAppIcon" width={24} />

            <h2
              style={{
                fontSize: "15px",
                color: "#105F84",
                margin: "0px 6px 0px 5px",
              }}
            >
              פרטי
            </h2>
          </MuiButton>
        </ShareWrapper>

        <MuiButton
          sx={{
            ...shareButtonStyle,
            border: `3px solid ${theme?.primary}`,
            "&:hover": {
              border: `3px solid ${theme?.primary}`,
            },
          }}
          onClick={() => {
            setEmergencyContactPopUpData(true);
            setReassurance(false);
            setOverlay(null);
          }}
        >
          <FlexRow
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ width: "95%" }}
          >
            <OrangeAppleIcon color={"#7FB383"} width={"24"} height={"27"} />
            <div>{theme?.headline ? theme.headline : "מוקדי חירום והצלה"}</div>
          </FlexRow>
        </MuiButton>
      </ShareButtonContainer>
    </>
  );

  return (
    <>
      <BackgroundOverlay aria-hidden onClick={handleCloseClick} />
      <Modal
        onClose={handleCloseClick}
        open
        border={`3px solid ${theme?.primary}`}
      >
        {showMap ? (
          <>
            <HiArrowLeft
              size={"1.5em"}
              style={{
                color: "#4B778D",
                position: "absolute",
                top: 15,
                left: 15,
              }}
              onClick={() => setShowMap(false)}
            />
            <div style={{ width: "100%", marginTop: "35px" }}>
              <Map
                disableClick={false}
                height={"60vh"}
                initialSelectedLocation={userLocation ?? searchUserLocation}
                showSearch={true}
                selectedLocationName={locationName}
                onLocationSelect={(loc) => {
                  setSearchUserLocation(loc);
                  fetchLocationName(loc).then((name) => {
                    setLocationName(name);
                  });
                }}
              />
            </div>
            <InformationContainer>
              <Information>{locationName}</Information>
            </InformationContainer>
          </>
        ) : (
          renderContent()
        )}
      </Modal>
    </>
  );
}

export default ReassuranceOverlay;
