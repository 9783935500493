import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import { TogetherIcon } from "../../../../../assets/icons/Logo";
import { CARD_DETAILS_PAGES } from "./CardDetailsPage";
import ReturnArrow from "../../../../Common/Layout/ReturnArrow/ReturnArrow";
import InlineInput from "../../../Board/Form/components/InlineInput";
import { useForm } from "react-hook-form";
import TextareaWrapper from "../../../Board/Form/components/TextareaWrapper";
import PhoneNumberInput from "../../../../../kit/Input/PhoneNumberInput";
import {
  addUserToWaitingList,
  updateWaitingListUserStatus,
} from "../../Activities/Create/api";
import { UserContext } from "../../../../../store/userContext";
import useFetchWaitingList from "../../../../../customHooks/useFetchWaitingList";
import useCustomAppWidth from "../../../../../customHooks/useCustomAppWidth";
import RegistrationSuccess from "../../Activities/RegistrationForSponsors/components/RegistrationSuccess";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(127, 179, 131, 0.2);
  min-height: 50px;
  height: calc(100vh - 17px);
`;
const TitleWrapper = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 10px;
  margin-left: 20px;
`;
const ButtonEl = styled.button`
  width: ${(props) => props.width};
  text-align: center;
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius};
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  font-size: ${(props) => props.fontSize};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.51px;
  font-family: Assistant;
  background-color: ${(props) =>
    props.backGroundColor ? props.backGroundColor : "white"};
  cursor: pointer;
  margin: 25px auto 25px auto;
  height: 45px;
`;

const Section = styled.div`
  background-color: ${(props) => props.backgroundColor ?? "transparent"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: ${(props) => (!!props?.noBorder ? "none" : "3px solid white")};
  padding: 25px 10px;
  width: 100%;
  box-sizing: border-box;
`;
const SectionTitle = styled.h3`
  margin: 0;
  font-family: Assistant;
  font-size: 22px;
  font-weight: 700;
  color: rgba(49, 103, 136, 1);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;
const OptionalSpan = styled.span`
  margin: 0 4px 0 0px;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
`;

const BottomTitle = styled.h3`
  margin: -13px auto 20px auto;
  font-family: Assistant;
  font-size: 22px;
  font-weight: 600;
  color: rgba(83, 87, 95, 1);
`;

const TopTitle = styled.h3`
  margin: 21px auto 0px auto;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 700;
  color: rgba(83, 87, 95, 1);
`;

const RegisterPage = ({
  setCurrentView,
  dataType,
  entityId,
  zone,
  authorId,
  registerVisitorFunc,
}) => {
  const { register, handleSubmit } = useForm();
  useCustomAppWidth();
  const [phoneNumberKey, setPhoneNumberKey] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const { currentUser, isAuthenticated } = useContext(UserContext);
  const { waitingList, refetchWaitingList } = useFetchWaitingList(
    dataType,
    entityId
  );
  const [error, setError] = useState();

  const onSubmit = async (data) => {
    try {
      setError("");
      const postData = {
        waitingList_id: waitingList?._id,
        user_id: currentUser?._id,
        phone_number: phoneNumber,
        authorId: authorId,
        ...data,
      };
      await addUserToWaitingList(postData);
      if (!isAuthenticated) {
        await updateWaitingListUserStatus({
          status: "accepted",
          waitingList_id: waitingList._id,
          phone_number: phoneNumber,
        });
        setShowSuccessPage(true);
        refetchWaitingList();
        registerVisitorFunc?.(data.full_name ?? "");
        setTimeout(() => {
          setShowSuccessPage(false);
          setCurrentView(CARD_DETAILS_PAGES.Level_Three);
        }, 2000);
        return;
      }
      refetchWaitingList();
      setCurrentView(CARD_DETAILS_PAGES.Level_Three);
    } catch (error) {
      setError(error?.response?.data?.error?.message);
      console.error("Failed to add user to waiting list", error);
    }
  };

  if (showSuccessPage) {
    return <RegistrationSuccess hideDoneBtn />;
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <TitleWrapper>
        <ReturnArrow
          onClickOverride={() => setCurrentView(CARD_DETAILS_PAGES.Level_One)}
          forceShowArrow
        />
        <div style={{ display: "flex", height: "50px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TogetherIcon width={"275px"} height={"60px"} />
          </div>
        </div>
      </TitleWrapper>
      <TopTitle>Registration - הרשמה להתנדבות</TopTitle>
      <Section noBorder>
        <SectionTitle>Full Name - שם מלא</SectionTitle>
        <InlineInput
          width={"90%"}
          register={{ ...register("full_name") }}
          required
          backgroundColor="#fff"
          maxWidth={"320px"}
          fontSize={"14px"}
        />
      </Section>
      <Section backgroundColor={"rgba(127, 179, 131, 0.4)"}>
        <SectionTitle>Phone Number - סלולרי</SectionTitle>
        <PhoneNumberInput
          // Force rerender
          key={phoneNumberKey}
          initialValue={
            phoneNumber && phoneNumber !== "" ? phoneNumber : undefined
          }
          setValue={setPhoneNumber}
          inputStyle={{
            borderRadius: "5.23px",
            border: "1px solid rgba(238, 238, 238, 1)",
            height: "30px",
            width: "26px",
          }}
          required
        />
      </Section>
      <Section backgroundColor={"rgba(127, 179, 131, 0.6)"}>
        <BottomTitle>אפשרות פרטים נוספים - לאדמין בלבד</BottomTitle>
        <SectionTitle>
          <OptionalSpan>(Optional)</OptionalSpan>City - עיר מגורים
        </SectionTitle>
        <InlineInput
          width={"90%"}
          register={{ ...register("city") }}
          backgroundColor="#fff"
          maxWidth={"320px"}
          fontSize={"14px"}
        />
        <div style={{ margin: "6.5px 0" }} />
        <SectionTitle>
          <OptionalSpan>(Optional)</OptionalSpan>הערה / בקשות - Note
        </SectionTitle>
        <TextareaWrapper
          placeholder="Add text"
          register={{ ...register("note") }}
          background="#fff"
          maxWidth={"320px"}
          fontSize={"14px"}
        />
        {error && error !== "" && (
          <span style={{ marginTop: "15px", color: "darkred", width: "90%" }}>
            {error}
          </span>
        )}
        <ButtonEl
          width="172px"
          borderRadius="30px"
          borderColor="rgba(49, 103, 136, 1)"
          color="rgba(49, 103, 136, 1)"
          backGroundColor="transparent"
          fontSize="17px"
          padding="5px 0"
        >
          Send &nbsp; - &nbsp;&nbsp;&nbsp; שליחה
        </ButtonEl>
      </Section>
    </Wrapper>
  );
};

export default RegisterPage;
