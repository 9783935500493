import beach from "./imgs/beach.png";
import tv1 from "./imgs/tv1.png";
import ju1 from "./imgs/ju1.png";
import ju3 from "./imgs/ju3.png";
import t1 from "./imgs/t1.png";
import a1 from "./imgs/a1.png";
import f1 from "./imgs/f1.png";
import f2 from "./imgs/f2.png";
import f3 from "./imgs/f3.png";

export const Appledata = [
  {
    enTitle: "To Volunteer",
    hebTitle: "בואו להתנדב",
    questions: [
      {
        title: "להתנדבויות באיזור שלי",
        linkAddress: "מפת התנדבויות",
        image: tv1,
      },
      {
        title: "סרטון אנימציה למתנדבים",
        linkAddress: "פרטים נוספים",
        titlelink: "#",
        to: "#",
        image: beach,
      },
    ],
  },
  {
    enTitle: "Join Us",
    hebTitle: "בואו להצטרף",
    questions: [
      {
        title: "קהילות וארגונים באיזור שלי",
        linkAddress: "פרטים נוספים",
        image: ju1,
      },
      {
        title: "הדרכה ואנימציה למצטרפים",
        titlelink: "#",
        linkAddress: "פרטים נוספים",
        to: "#",
        image: beach,
      },
      {
        title: "Personal zone - איזור האישי",
        linkAddress: "פרטים נוספים",
        image: ju3,
      },
    ],
  },
  {
    enTitle: "Tasks",
    hebTitle: "משימות",
    questions: [
      {
        title: "אירגוני מתנדבים",
        linkAddress: "פרטים נוספים",
        image: t1,
      },
      {
        title: "הדרכה ואנימציה למתנדבים",
        linkAddress: "פרטים נוספים",
        titlelink: "#",
        to: "#",
        image: beach,
      },
    ],
  },
  {
    enTitle: "Activities",
    hebTitle: "פעילויות",
    questions: [
      {
        title: "פעילויות באיזור שלי",
        linkAddress: "מפת פעילויות",
        image: a1,
      },
      {
        title: "הצטרפות לפעילויות",
        linkAddress: "פרטים נוספים",
        titlelink: "#",
        to: "#",
        image: beach,
      },
    ],
  },
  {
    enTitle: "Forum",
    hebTitle: "פורום מתנדבים",
    questions: [
      {
        title: "לוח מתנדבים",
        linkAddress: "פרטים נוספים",
        image: f1,
      },
      {
        title: "לוח מוסרים באהבה",
        linkAddress: "פרטים נוספים",
        titlelink: "#",
        to: "#",
        image: f2,
      },
      {
        title: "נטוורקינג למתנדבים",
        linkAddress: "פרטים נוספים",
        image: f3,
      },
    ],
  },
];
