import React from "react";
import { styled } from "styled-components";
import { organizationLogos } from "../Mock/data";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;
const Title = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #316788;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
`;
const Logos = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 18px;
`;
const Logo = styled.img`
  height: 38px;
  width: auto;
`;

function DetailsOrganizations(props) {
  return (
    <Wrapper>
      <Title>Organizations - ארגוני התנדבות</Title>
      <Logos>
        {organizationLogos.map((org, i) => (
          <Logo src={org} alt={`org-logo-${i}`} />
        ))}
      </Logos>
    </Wrapper>
  );
}

export default DetailsOrganizations;
