import React from "react";
import styled, { css } from "styled-components";
import { TogetherIcon } from "../../assets/icons/Logo";

const Wrapper = styled.div`
  height: 72px;
  display: flex;
  justify-content: center;
`;

const LogoContainer = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
`;

const IconTitle = styled.div`
  position: absolute;
  top: ${(props) => props.top ?? "40px"};
  right: ${(props) => props.left ?? "-50px"};
  color: #7fb383;
  text-align: left;
  font-family: Assistant;
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  max-width: 215px;
  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `}
`;

const LogoWithText = ({
  Icon = TogetherIcon,
  top,
  left,
  title,
  iconWidth,
  iconHeight,
  titleStyles,
}) => (
  <Wrapper>
    <LogoContainer>
      <Icon width={iconWidth} height={iconHeight} />
      <IconTitle top={top} left={left} customStyles={titleStyles}>
        {title}
      </IconTitle>
    </LogoContainer>
  </Wrapper>
);

export default LogoWithText;
