import { useQuery } from "@tanstack/react-query";
import { fetchUser } from "../domain/Auth/api";
import { useMemo } from "react";

function useFetchUser(props) {
  const { userId, queryKey = "user" } = props;

  const { isLoading: isLoadingUser, data: fetchedUser } = useQuery({
    queryKey: [queryKey, userId],
    queryFn: () => fetchUser(userId),
    staleTime: 1000,
  });

  const user = useMemo(
    () => fetchedUser?.data?.users,
    [fetchedUser?.data.users]
  );

  return {
    user: user,
    isLoadingUser: isLoadingUser,
  };
}

export default useFetchUser;
