import React from "react";
import styled, { css } from "styled-components";
import { Divider, styled as MuiStyled } from "@mui/material";

const StyledDividers = MuiStyled(Divider)({
  borderColor: "#316788",
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 30px;
  width: 150px;
  background-color: #e5eeff;
  border: 1px solid #316788;
  border-radius: 5px;
  z-index: 10;
  color: #316788;
  font-size: 12px;
  font-weight: 600;
  font-family: Assistant;
  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `}
  z-index: 1002;
  @media (max-width: 385px) {
    left: -45px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 22px;
`;

const Contact = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  height: 37px;
  cursor: pointer;
`;

const volunteers = [
  {
    _id: 1,
    name: "כבאות והצלה",
    number: "052-3153355",
  },
  {
    _id: 2,
    name: "רפואה",
    number: "052-3153355",
  },
  {
    _id: 3,
    name: "עזרה נפשית",
    number: "052-3153355",
  },
  {
    _id: 4,
    name: "סיוע לוגיסטי דחוף",
    number: "052-3153355",
  },
  {
    _id: 5,
    name: "ידידים בכבישים",
    number: "052-3153355",
  },
];

const VolunteersSubTab = ({ tab, showContactPopup }) => {
  return (
    <Wrapper customStyles={tab.styles}>
      <Header>{"ארגוני מתנדבים בחירום"}</Header>
      <StyledDividers />
      {volunteers.map((contact, index) => (
        <React.Fragment key={contact._id}>
          <Contact onClick={showContactPopup}>
            <div>{contact.name}</div>
            <div>{contact.number}</div>
          </Contact>
          {index !== volunteers.length - 1 && <StyledDividers />}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default VolunteersSubTab;
