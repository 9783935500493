import { useMemo } from "react";
import useFilteredEcosystems from "./useFilteredEcosystems ";
import { EcosystemZone } from "../../../Admin/AdminArea/CreatePage/constans";
import { STUDENT } from "../../../Ecosystem/Public/Student/constant";
import { PUBLIC_ORGANIZATION } from "../../../Ecosystem/Public/Organization/constant";
import { CITIES } from "../../../Ecosystem/Public/City/constant";

const useEcosystemsRoutes = () => {
  const {
    filteredCityEcosystems,
    filteredorganizationEcosystems,
    filteredschoolEcosystems,
  } = useFilteredEcosystems();

  const IsraelCities = useMemo(() => {
    const second = filteredCityEcosystems?.find(
      (city) => city.value === "659e2467e6ad34f2f223b4f4"
    );

    const otherCities =
      filteredCityEcosystems?.filter(
        (city) =>
          city.zoneType === EcosystemZone.Israel &&
          city.value !== "659e2467e6ad34f2f223b4f4"
      ) || [];

    return [CITIES[0], ...(second ? [second] : []), ...otherCities];
  }, [filteredCityEcosystems]);

  const USACities = useMemo(() => {
    const filteredCities =
      filteredCityEcosystems?.filter((s) => s.zoneType === EcosystemZone.USA) ??
      [];

    const headerCityIndex = filteredCities.findIndex(
      (city) => city.name === "Manhattan"
    );
    if (headerCityIndex !== -1) {
      const manhattanCity = filteredCities.splice(headerCityIndex, 1)[0];
      filteredCities.unshift(manhattanCity);
    }

    return filteredCities;
  }, [filteredCityEcosystems]);

  const EuropeCities = useMemo(
    () =>
      filteredCityEcosystems?.filter(
        (s) => s.zoneType === EcosystemZone.Europe
      ) ?? [],
    [filteredCityEcosystems]
  );

  const students = useMemo(
    () => [...STUDENT, ...(filteredschoolEcosystems || [])],
    [filteredschoolEcosystems]
  );

  const organization = useMemo(
    () => [...PUBLIC_ORGANIZATION, ...(filteredorganizationEcosystems || [])],
    [filteredorganizationEcosystems]
  );

  const allEcosystems = useMemo(
    () => [
      ...(IsraelCities || []),
      ...(USACities || []),
      ...(EuropeCities || []),
      ...(students || []),
      ...(organization || []),
    ],
    [IsraelCities, USACities, EuropeCities, students, organization]
  );

  return {
    IsraelCities,
    USACities,
    EuropeCities,
    students,
    organization,
    allEcosystems,
  };
};

export default useEcosystemsRoutes;
