import { useEffect, useState } from "react";
import styled from "styled-components";
import PopUpModal from "../../../../kit/PopUpModal/PopUpModal";
import { Input } from "./CreatePage";

const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 67px;
  background: #7fb38333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Title = styled.h3`
  font-family: Assistant;
  font-size: ${(props) => props.fontSize ?? "18px"};
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.018em;
  text-align: center;
  color: #58656d;
  direction: rtl;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;
`;

const Radio = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: ${(props) => (props.selected ? "#9EC6A1" : "#fff")};
  border: ${(props) => (props.selected ? "4px solid #fff" : "none")};
  position: absolute;
  right: 16px;
  box-sizing: border-box;
`;

const Separator = styled.div`
  height: 2px;
  width: 290px;
  margin: 0 auto;
  background: #7fb383;
`;

// options: [{title: string, value: string}]
const SelectionPopup = ({
  title,
  icon,
  visible,
  setVisible,
  onDismiss,
  options,
  currentSections,
  initiallySelected,
  showExtraOption,
  extraOptionPlaceholder,
  bodyComponentRenderFunc,
}) => {
  const [selected, setSelected] = useState();
  const onRadioClick = (value) => {
    console.log(value);
    selected === value ? setSelected(undefined) : setSelected(value);
  };

  useEffect(() => {
    if (!initiallySelected) return;
    setSelected(initiallySelected);
  }, [initiallySelected]);

  return (
    <PopUpModal
      visible={visible}
      hidePopUpfunc={async () => {
        await onDismiss(selected);
        setVisible?.(false);
      }}
      useNormalDialog
      paddingPopUpDialog={0}
    >
      <Container>
        <Header>
          {icon}
          <Title fontSize={"22px"}>{title}</Title>
        </Header>
        <Body>
          {bodyComponentRenderFunc?.()}
          {options &&
            options?.map(
              (s, i) =>
                !currentSections?.some(
                  (section) => section.type === s.value
                ) && (
                  <>
                    <Section key={i}>
                      <Title>{s.title}</Title>
                      <Radio
                        selected={selected === s.value}
                        onClick={() => onRadioClick(s.value)}
                      />
                    </Section>
                    {i < options?.length - 1 && (
                      <Separator key={`separator-${i}`} />
                    )}
                  </>
                )
            )}
          {showExtraOption && (
            <>
              {options.length > 0 && (
                <Separator key={`separator-${options.length}`} />
              )}
              <Section key={options.length}>
                <Input
                  width={"80%"}
                  placeholder={extraOptionPlaceholder}
                  onChange={(e) => setSelected(e.target?.value)}
                />
              </Section>
            </>
          )}
        </Body>
      </Container>
    </PopUpModal>
  );
};
export default SelectionPopup;
