import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { UserProvider } from "./store/userContext";
import { ZoneRouteProvider } from "./store/zoneRouteContext";
import Main from "./domain/Common/Layout/Main/Main";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LanguageProvider } from "./store/languageContext";
import { APP_MAX_WIDTH } from "./domain/Home/constant";
import { CardDetailsProvider } from "./store/cardDetailsContext";
import { FontProvider } from "./store/fontContext";
import { PreviousRouteProvider } from "./store/perviousRouteContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PopProvider } from "./store/popupContext";
import { EmergencyOverlaysProvider } from "./domain/Emergency/Contacts/EmergencyOverlaysContext";

const Wrapper = styled.div`
  max-width: ${APP_MAX_WIDTH}px;
  background-color: #fafffa;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  // Clear cache on version change
  useEffect(() => {
    // Extract name and version from package.json
    const packageName = process.env.REACT_APP_NAME || "together";
    const packageVersion = process.env.REACT_APP_VERSION || "1.0.0";
    const lastVersion = localStorage.getItem(`${packageName}-version`);

    if (lastVersion !== packageVersion) {
      localStorage.setItem(`${packageName}-version`, packageVersion);
      window.location.reload(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <UserProvider>
        <FontProvider>
          <LanguageProvider>
            <CardDetailsProvider>
              <ZoneRouteProvider>
                <PreviousRouteProvider>
                  <EmergencyOverlaysProvider>
                    <PopProvider>
                      <QueryClientProvider client={queryClient}>
                        <Wrapper id="main-app-wrapper">
                          <Main />
                        </Wrapper>
                        {/* For debugging react query  */}
                        <ReactQueryDevtools initialIsOpen={false} />
                      </QueryClientProvider>
                    </PopProvider>
                  </EmergencyOverlaysProvider>
                </PreviousRouteProvider>
              </ZoneRouteProvider>
            </CardDetailsProvider>
          </LanguageProvider>
        </FontProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
