import React from "react";

const BellIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "28"} //31
      height={height ?? "31"} // 34
      fill="none"
      viewBox="0 0 31 34"
    >
      <g fill={color ?? "#EF7300"} clipPath="url(#clip0_5724_16002)">
        <path d="M14.584 33.476c-.2-.138-.447-.12-.668-.19-1.877-.592-3.09-1.83-3.632-3.714-.066-.23-.153-.278-.371-.276-2.005.008-4.01.006-6.014.004-1.068 0-1.947-.58-2.327-1.527-.39-.972-.175-2.001.647-2.726 1.264-1.114 2.228-2.426 2.802-4.013.341-.944.538-1.92.543-2.927.008-1.337.003-2.675.006-4.012.01-4.355 2.684-8.036 6.824-9.397a8.19 8.19 0 011.649-.367c.232-.026.253-.115.252-.303-.007-.946-.013-1.892 0-2.838.01-.835.753-1.36 1.463-1.04.44.197.647.561.648 1.04.001.936.005 1.87-.003 2.805-.001.206.009.304.276.34 4.243.57 7.617 3.874 8.307 8.102.08.495.126.99.124 1.492-.004 1.196.007 2.393-.002 3.589-.024 2.967 1.04 5.452 3.268 7.438 1.096.979 1.236 2.4.363 3.458-.475.575-1.095.884-1.848.886-2.037.004-4.074.004-6.112-.003-.212 0-.308.038-.376.266-.643 2.168-2.067 3.477-4.32 3.855-.024.004-.041.04-.062.06h-1.438v-.002zm.768-6.263l11.178-.001c.245 0 .533.065.64-.248.104-.299-.17-.415-.337-.569-2.427-2.227-3.718-4.979-3.81-8.271-.036-1.326-.008-2.653-.006-3.98a8.037 8.037 0 00-.148-1.62c-1.024-5.045-6.52-7.69-11.082-5.285-2.504 1.32-3.885 3.455-4.067 6.286-.088 1.375-.037 2.76-.035 4.141.004 2.841-.87 5.369-2.692 7.56-.398.479-.84.913-1.308 1.322-.148.128-.224.27-.147.457.073.175.232.213.406.208.076-.002.152 0 .229 0H15.35zM18.26 29.3h-5.7c-.041 0-.082.008-.123.012.262 1.109 1.564 2.047 2.865 2.073 1.303.026 2.639-.9 2.958-2.086z"></path>
        <path d="M30.731 14.167c-.09.018-.063.101-.085.155-.214.531-.674.794-1.218.694-.479-.087-.844-.52-.843-1.054 0-.97-.089-1.932-.279-2.88a14.72 14.72 0 00-3.219-6.693c-.266-.316-.555-.613-.836-.916-.467-.506-.478-1.139-.027-1.564.433-.41 1.078-.375 1.541.098 2.446 2.495 4.011 5.449 4.644 8.888.138.748.239 1.5.26 2.261 0 .06-.026.135.062.163v.848z"></path>
        <path d="M.005 12.535c.153-.36.127-.753.186-1.129.569-3.651 2.169-6.78 4.755-9.415.457-.466 1.11-.486 1.54-.064.436.429.429 1.06-.039 1.537C4.504 5.446 3.18 7.77 2.524 10.47a15.864 15.864 0 00-.425 3.416c-.013.594-.31 1.017-.792 1.126-.544.122-.966-.108-1.252-.682-.008-.015-.033-.022-.05-.033V12.535z"></path>
      </g>
      <defs>
        <clipPath id="clip0_5724_16002">
          <path fill="#fff" d="M0 0H30.738V33.485H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BellIcon;
