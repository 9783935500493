import requestHandler from "../../../store/requestHandler";
import { CURRENT_DOMAIN } from "../../../info";

export const fetchById = async (id) =>
  id
    ? await requestHandler("get", `${CURRENT_DOMAIN}/ecosystems/${id}`)
    : undefined;

export const fetchByIds = async (ids) =>
  await requestHandler("get", `/ecosystems/findByIds?ids=${ids.join(",")}`);

export const getHubs = async (zones) =>
  await requestHandler("get", `/hubs/findByZones?zones=${zones.join(",")}`);
