import { useEffect, useState } from "react";

export default function useDropdown(optionsRef) {
  const [showOptions, setShowOptions] = useState(false);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        showOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(e.target)
      ) {
        setShowOptions(false);
      }
    };

    // Add or remove the event listener conditionally based on the showOptions state
    if (showOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions]);
  return { showOptions, setShowOptions };
}
