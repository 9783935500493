import React from "react";
import styled, { css, keyframes } from "styled-components";
import DeleteIconThin from "../../../../assets/icons/DeleteIconThin";

const flicker = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: ${(props) => (props.isIcon ? "flex" : "block")};
  box-shadow: 5px 2px 5px 0px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10.72px;
  background: ${(props) => props.background ?? "#DAF3DC80"};
  overflow: hidden;
  height: ${({ height }) => height};
  width: 100%;
`;

const TitleWrapper = styled.div`
  width: 65%;
  text-align: center;
`;

const Title = styled.div`
  /* margin-top: 5px; */
  color: ${(props) => props.color ?? "#105F84"};
  font-family: ${(props) => props.fontFamily ?? "NotoSans-600"};
  font-size: ${(props) => props.fontSize ?? "16px"};
  letter-spacing: 0.223px;
  font-weight: ${(props) => props.fontWeight ?? "normal"};
`;
const SubTitle = styled.div`
  /* margin-top: 10px; */
  color: var(--preview-txt, #58656d);
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: ${({ height }) => height ?? "100%"};
  width: 78px;
  justify-content: center;
  background-color: ${({ background }) => background ?? "transparent"};
  padding: 3px;
  border-radius: 10.72px;
  border: ${({ border }) => border ?? "none"};
  ${({ shouldFlicker }) =>
    shouldFlicker
      ? css`
          animation: ${flicker} 1s infinite;
        `
      : ""}
`;
const IconText = styled.div`
  color: ${(props) => props.color ?? "var(--preview-txt, #58656d)"};
  font-family: "NotoSans-600";
  font-size: 9.82px;
  text-align: center;
  width: 100%;
`;

export const OverlayCardDesignStyles = {
  Panic: "Panic",
  Help: "Help",
};

function OverlayCard({
  title,
  subTitle,
  titleFontSize,
  titleFontWeight,
  Icon,
  IconColor,
  iconText,
  wrapperBgColor,
  onClick,
  showShakeAnimation,
  showDelete,
  counter,
  onDeleteClick,
  iconWrapperBgColor,
  iconWrapperBorder,
  iconWrapperHeight,
  height,
  iconSize = "23.56px",
  cardStyle = OverlayCardDesignStyles.Panic,
}) {
  const renderText = (text, subtext) => (
    <TitleWrapper>
      <Title
        fontWeight={titleFontWeight ?? "normal"}
        fontFamily={
          cardStyle === OverlayCardDesignStyles.Help && "NotoSans-500"
        }
        color={IconColor ? "#105F84" : undefined}
        fontSize={titleFontSize}
      >
        {text}
      </Title>
      <SubTitle>{subtext}</SubTitle>
    </TitleWrapper>
  );

  return (
    <Wrapper
      isIcon={Icon}
      background={wrapperBgColor}
      onClick={onClick}
      showShakeAnimation={showShakeAnimation}
      height={height}
    >
      {cardStyle === OverlayCardDesignStyles.Help && (
        <>
          {renderText(title)}
          <Icon
            color={IconColor ?? "#EF7300"}
            width={iconSize}
            height={iconSize}
          />
          {renderText(subTitle)}
        </>
      )}
      {Icon && cardStyle === OverlayCardDesignStyles.Panic && (
        <IconWrapper
          background={iconWrapperBgColor}
          border={iconWrapperBorder}
          height={iconWrapperHeight}
          shouldFlicker={!showDelete && counter != null}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              color={IconColor ?? "#EF7300"}
              width={iconSize}
              height={iconSize}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconText color={IconColor}>{iconText}</IconText>
          </div>
        </IconWrapper>
      )}
      {cardStyle === OverlayCardDesignStyles.Panic &&
        renderText(title, subTitle)}
      {(showDelete || counter != null) && (
        <IconWrapper
          onClick={(e) => {
            if (!showDelete) {
              return;
            }
            e.stopPropagation();
            onDeleteClick?.();
          }}
          style={{
            padding: "0 10px",
            width: "50px",
            display: "flex",
            alignSelf: !showDelete && counter != null ? "end" : "center",
          }}
        >
          {!showDelete && (
            <div
              style={{
                fontFamily: "NotoSans-600",
                fontSize: "13px",
                color: "rgba(16, 95, 132, 1)",
              }}
            >
              {counter} sec
            </div>
          )}
          {showDelete && <DeleteIconThin />}
        </IconWrapper>
      )}
    </Wrapper>
  );
}

export default OverlayCard;
