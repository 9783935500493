import { useMutation } from "@tanstack/react-query";
import { editEmergencyById } from "../api";

function useEditEmergency() {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async ({ id, payload }) => editEmergencyById(id, payload),
  });
  return { editEmergency: mutateAsync, isLoading };
}

export default useEditEmergency;
