import React from "react";
// import LoadingWrapper from "./LoadingWrapper";
import Spinner from "./components/Spinner";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.center ? "100vh" : "auto")};
`;

// Define the spinning animation keyframes
function LoadingSpinner(props) {
  const { text, center } = props;
  return (
    <LoadingWrapper center={center}>
      <Spinner text={text} />
    </LoadingWrapper>
  );
}

export default LoadingSpinner;
