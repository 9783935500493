import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { TogetherIcon } from "../../../../../assets/icons/Logo";
import MuiButton from "../../../../../kit/Button/MuiButton";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import r1 from "./Mock/r1.png";
import r2 from "./Mock/r2.png";
import r3 from "./Mock/r3.png";
import r4 from "./Mock/r4.png";
import r5 from "./Mock/r5.png";
import r6 from "./Mock/r6.png";
import r7 from "./Mock/r7.png";
import ReturnArrow from "../../../../Common/Layout/ReturnArrow/ReturnArrow";
import { useMediaQuery } from "react-responsive";
import LandingFooter from "../../../../Auth/landingPage/component/LandingFooter";
import { useParams } from "react-router-dom";

const CaretDown = () => (
  <svg
    width="13"
    height="6"
    viewBox="0 0 13 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5546 1L6.27729 4.93489L1 1"
      stroke="#316788"
      stroke-width="1.54285"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Wrapper = styled.div`
  padding: 15px 0;
  background-color: ${({ isBlue }) => isBlue && "#E5EEFF"};
`;

const DomainTitle = styled.div`
  color: #003f5d;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.989px; /* 63.298% */
  letter-spacing: 0.3px;
  text-align: center;
  margin: 10px 0;
`;

const Description = styled.div`
  color: #003f5d;
  text-align: center;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 88% */
  letter-spacing: 0.25px;
  margin-top: 10px;
  max-width: 85%;
  display: flex;
  margin: 0 auto;
`;

const Title = styled.div`
  color: #003f5d;
  text-align: center;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.989px; /* 63.298% */
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

const CodeWrapper = styled.div`
  color: #003f5d;
  text-align: right;
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.989px; /* 75.957% */
  letter-spacing: 0.25px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const LogoWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${({ isBlue }) => (isBlue ? "#E5EEFF" : "#fafffa")};
  height: 70.416px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 75px;
`;
const IconTitle = styled.div`
  position: absolute;
  top: 50px;
  color: #7fb383;
  position: absolute;
  transform: translateX(60px);
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.54px;
  letter-spacing: 0.03em;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const OptionsTitle = styled.div`
  color: #003f5d;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.989px; /* 75.957% */
  letter-spacing: 0.25px;
  text-transform: uppercase;
  margin-top: 30px;
`;
const Image = styled.img`
  width: 303px;
  height: 175px;
`;
const ViewMoreBtn = styled.div`
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.54px;
  letter-spacing: 0.22326388955116272px;
  text-align: center;
  gap: 10px;
  color: rgba(49, 103, 136, 1);
  background: rgba(241, 243, 244, 1);
  width: 172px;
  height: 34px;
  margin: 10px 0 25px 0;
`;

const buttonStyles = {
  border: "2px solid #003F5D",
  background: "#fff",
  borderRadius: "20px",
  color: "#003F5D",
  fontFamily: "Assistant",
  fontSize: 14,
  fontWeight: 600,
  padding: "6.5px",
  height: "29.62px",
  "&:hover": {
    border: "2px solid #B0BFDE",
    backgroundColor: "#B0BFDE",
  },
  "@media (max-width: 375px)": {
    fontSize: 14,
  },
  "@media (max-width: 363px)": {
    fontSize: 12,
  },
};

const CardOptions = {
  Opt1: "ספורט והעשרה",
  Opt2: "בעלי חיים",
  Opt3: "תרבות ופנאי",
  All: "All - הכל",
};

const CardDetailsTimeReward = ({ backFunc, zone, CoverPhoto }) => {
  const { id } = useParams();
  const { control, watch } = useForm({
    defaultValues: {
      selectedCategory: "",
    },
  });
  const xsScreen = useMediaQuery({ maxWidth: 400 });
  const smScreen = useMediaQuery({ minWidth: 400, maxWidth: 768 });
  const lScreen = useMediaQuery({ minWidth: 768 });
  const isBlue = id === "telAvivCity";

  const selectedCategory = watch("selectedCategory");
  const mockCards = useMemo(() => {
    const cardsList = [r1, r2, r3, r4, r5, r6, r7];
    switch (selectedCategory) {
      case CardOptions.All:
        return cardsList;
      case CardOptions.Opt3:
        return cardsList.slice(0, 5);
      case CardOptions.Opt2:
        return [cardsList[6]];
      case CardOptions.Opt1:
        return [cardsList[5]];
      default:
        return cardsList;
    }
  }, [selectedCategory]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Wrapper isBlue={isBlue}>
      <ImageWrapper>
        <LogoWrapper isBlue={isBlue}>
          {<TogetherIcon width={216} height={70} />}
          <IconTitle xs={xsScreen} sm={smScreen} l={lScreen}>
            {"Ecosystem Pilot"}
          </IconTitle>
        </LogoWrapper>
      </ImageWrapper>
      {backFunc && <ReturnArrow forceShowArrow onClickOverride={backFunc} />}
      <DomainTitle>Time Rewards System - מערכת תגמול</DomainTitle>
      <Description>
        המערכת מעניקה למשתתפים בפעילויות עזרה הדדית תגמולים ייחודיים המאפשרים
        לבלות ולהנות במגוון אטרקציות והטבות נוספות
      </Description>
      {/* <div
        style={{
          backgroundColor: isBlue ? "#D6E4FF" : "rgba(127, 179, 131, 0.1)",
          margin: "15px 0",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          padding: "18px 5px",
        }}
      >
        <Title>לבדיקת אפשרויות מימוש תגמולים</Title>
        <CodeWrapper>
          <div>Insert Code</div>
          <CodeInput
            styles={{
              width: "20px",
              height: "21px",
              backgroundColor: "#FAFFFA",
              borderRadius: "7px",
              border: "2.114px solid #58656D",
            }}
            navigateLink={"#"}
          />
          <div>קוד קהילה</div>
        </CodeWrapper>
      </div>
      <Title>CATEGORIES - לצפייה בחר קטגוריה</Title> */}
      <FlexRow justifyContent={"space-around"} sx={{ marginTop: "30px" }}>
        {Object.values(CardOptions).map((category) => (
          <Controller
            key={category}
            name="selectedCategory"
            control={control}
            render={({ field }) => (
              <MuiButton
                variant="outlined"
                sx={{
                  ...buttonStyles,
                  backgroundColor:
                    field.value === category ||
                    (field.value === "" && category === CardOptions.All)
                      ? "#B0BFDE"
                      : "#fff",
                  margin: "0 2.5px",
                }}
                onClick={() => field.onChange(category)}
              >
                {category}
              </MuiButton>
            )}
          />
        ))}
      </FlexRow>
      {/* <OptionsTitle>לצפייה באפשרויות המימוש יש לגלול מטה</OptionsTitle> */}
      <FlexColumn
        justifyContent={"center"}
        alignItems={"center"}
        gap={"25px"}
        sx={{ marginTop: "30px" }}
      >
        {mockCards.map((card) => (
          <Image src={card} alt="card" />
        ))}
        <ViewMoreBtn>
          <span>View More</span>
          <CaretDown />
        </ViewMoreBtn>
      </FlexColumn>
      <div
        style={{
          height: "2px",
          width: "100%",
          background: "rgba(127, 179, 131, 0.5)",
        }}
      />
      <LandingFooter backgroundColor={isBlue && "#E5EEFF"} />
    </Wrapper>
  );
};

export default CardDetailsTimeReward;
