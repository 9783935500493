import React, { useRef } from "react";
import styled from "styled-components";
import BottomForm from "./components/BottomForm";
import CheckboxRowV2 from "./components/CheckboxRowV2";
import { useFormContext } from "react-hook-form";
import LoadingSpinner from "../../../Common/Loading/Loading";

const Wrapper = styled.div`
  position: relative;
`;
const FormWrapper = styled.div`
  border-radius: 17.5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease;
  padding: 13px 13px 20px 13px;
  margin: 0 16px;
`;
const Title = styled.div`
  color: #1b5c6d;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
`;
const ContentWrapper = styled.div`
  background-color: #7fb38333;
  padding: 20px 0;
  border-radius: ${({ hasBorder }) => hasBorder && "15px 15px 0px 0px"};
  margin-bottom: 30px;
`;
const GroupInfo = styled.div`
  color: #58656d;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.36px;
  height: 49px;
  border-radius: 15px 15px 0px 0px;
  background: #cde4cf;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function BoardFormV2(props) {
  const {
    showForm,
    setShowForm,
    title = "...הוסף פוסט לפורום הקהילתי",
    content,
    categories,
    isLoading,
    children,
  } = props;

  const { register, watch } = useFormContext();

  const category = watch("category");

  const formRef = useRef(null);

  const handleShowForm = () => {
    !showForm && setShowForm(true);
    scrollToRef(formRef);
  };

  const handleHideForm = () => {
    showForm && setShowForm(false);
  };

  const scrollToRef = (ref, offset = -35) => {
    setTimeout(() => {
      if (ref.current) {
        window.scrollTo({
          top: ref.current.offsetTop + offset,
          behavior: "smooth",
        });
      }
    }, 10);
  };

  return (
    <Wrapper>
      {showForm && content ? (
        <FormWrapper ref={formRef}>
          <Title onClick={handleHideForm}>
            <div>Add Post</div>
            <div>{title}</div>
          </Title>
          {content}
        </FormWrapper>
      ) : (
        <>
          <CheckboxRowV2
            categories={categories}
            selectedCategory={category}
            register={{ ...register("category") }}
            required
          />
          <GroupInfo>
            Group Info -{" "}
            {categories.find((cat) => cat.value === category)?.label}
          </GroupInfo>
        </>
      )}
      <ContentWrapper hasBorder={showForm && content}>
        {isLoading ? <LoadingSpinner /> : children}
      </ContentWrapper>
      <BottomForm onClick={handleShowForm} />
    </Wrapper>
  );
}

export default BoardFormV2;
