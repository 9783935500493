import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  fetchPostItemsByZone,
  fetchPostItemsByZoneAndType,
} from "../domain/Ecosystem/Board/api";
import { getPhotoSrc } from "../domain/Ecosystem/Post/utils";
import cardCover from "../assets/images/Activities/CommunityBoard/card-cover.png";

function useFetchPostItems(zone, onSuccess, type) {
  const {
    isLoading: isLoadingPostItems,
    refetch: refetchPostItems,
    data: fetchedPosts,
  } = useQuery({
    queryKey: ["postItems", zone, type],
    queryFn: () =>
      type
        ? fetchPostItemsByZoneAndType(zone, type)
        : fetchPostItemsByZone(zone),
    onSuccess: (data) => {
      onSuccess?.(data?.data?.posts?.slice?.().reverse?.());
    },
  });

  const postItems = useMemo(
    () => fetchedPosts?.data?.posts?.slice?.().reverse?.() ?? [],
    [fetchedPosts?.data?.posts]
  );

  const cardPostItems = useMemo(
    () =>
      postItems?.map?.((post) => ({
        ...post,
        id: post?._id,
        title: post?.item,
        name: post?.item,
        contact_name: post?.name,
        user_id: post?.user_id,
        location: post?.location,
        date: post?.createdAt?.substring(0, 10),
        image: post?.photo ? getPhotoSrc(post?.photo) : cardCover,
      })) ?? [],
    [postItems]
  );

  return { postItems, cardPostItems, isLoadingPostItems, refetchPostItems };
}

export default useFetchPostItems;
