export const ROUTES = {
  LOGIN: "login",
  REGISTER: "register",
  RESET_PASSWORD: "resetPassword",
  CREATE_ACCOUNT: "createAccount",
  EMERGENCY_CREATE_PROFILE: "emergency-create-profile",
};

export const USER_ROLES = {
  MEMBER: "member",
  TEAM_MEMBER: "teamMember",
  ADMIN: "admin",
};
