import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../../kit/Button/Button";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../../store/userContext";
import { createPost, editPost } from "../api";
import { useNavigate } from "react-router-dom";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";
import { LanguageContext } from "../../../../store/languageContext";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import RichTextEditor from "../../../../kit/RichTextEditor/RichTextEditor";
import CheckboxRowV2 from "./components/CheckboxRowV2";
import {
  CameraIcon,
  EmojiIcon,
  LocationIconV2,
  ThreeDotsIcon,
} from "../../../../assets/icons";
import { getCategoriesByZone } from "../constant";
import FormPreviewImages from "./components/FormPreviewImages";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 10px;
  width: 100%;
`;
const NeedPhotoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
`;
const AddPhotoWrapper = styled.div`
  display: flex;
  height: 41px;
  border-radius: 10.81px;
  border: 0.6px solid #cecaca;
  background: #fff;
  margin: 0 6px;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  color: #1b5c6d;
  font-size: 12px;
  font-weight: 700;
`;
const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const IconWrapper = styled.div`
  border-radius: 4.074px;
  background: #fff;
  box-shadow: 0px 1.63px 1.63px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26.074px;
  height: 22px;
  cursor: pointer;
  position: relative;
`;
const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const PostButtonStyles = (english) => ({
  color: "#5F9362",
  shadow: "0px 1.304px 3.261px 0px rgba(66, 132, 128, 0.20)",
  border: "2px solid #5F9362",
  borderRadius: "9.783px",
  width: "128px",
  fontSize: "16px",
  fontWeight: "700",
  height: "37px",
  cursor: "pointer",
  direction: english ? "ltr" : "rtl",
  margin: !english ? "0 auto 0 6px" : "0 6px 0 auto",
  marginTop: "0px",
});

function CommunityBoardPost({
  posts,
  setPosts,
  postData,
  onPostSuccess,
  zone,
  type,
  section,
  isEditMode = false,
  categories,
}) {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: isEditMode ? postData : {},
  });
  const { currentUser, isAuthenticated } = useContext(UserContext);
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const navigate = useNavigate();

  const { currentLanguage } = useContext(LanguageContext);
  const category = watch("category");
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const onSubmit = async (data) => {
    isEditMode ? handleEditPost(data) : handleCreatePost(data);
  };

  useEffect(() => {
    if (isEditMode && !descriptionUpdated && postData?.description) {
      setDescriptionContent(postData?.description);
      setDescriptionUpdated(true);
    }
  }, [postData, descriptionUpdated, isEditMode]);

  const handleCreatePost = async (data) => {
    delete data.photo;

    const postData = {
      ...data,
      user_id: currentUser._id,
      photos: selectedFilesList,
      zone: zone,
      type: type ?? undefined,
      description: descriptionContent,
    };
    try {
      const response = await createPost(postData);
      setPosts([response.data.post, ...posts]);
      onPostSuccess?.();
    } catch (error) {
      console.error("Failed to create post.", error);
    }
  };

  const handleEditPost = async (data) => {
    const postData = {
      ...data,
      user_id: currentUser._id,
      zone: zone,
      type: type ?? undefined,
      photos: selectedFilesList,
      description: descriptionContent,
    };
    try {
      const response = await editPost(data?._id, postData);
      setPosts((posts) =>
        posts.map((post) =>
          post._id === data?._id ? response.data.post : post
        )
      );
      onPostSuccess?.();
    } catch (error) {}
  };

  const handleLogin = () => {
    setLoginRoute(
      `/activities/board/zone/${zone}${
        section ? `/section/${section}?show=true` : "?show=true"
      }`
    );
    navigate("/login");
  };

  useEffect(() => {
    setSelectedImages(
      selectedFilesList?.map?.((f) =>
        typeof f === "string" ? f : URL.createObjectURL(f)
      )
    );
  }, [selectedFilesList]);

  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const imageUrls = selectedFiles.map((file) => URL.createObjectURL(file));

    setSelectedFilesList([...(selectedFilesList ?? []), ...selectedFiles]);
    setSelectedImages([...selectedImages, ...imageUrls]);
  };

  if (!isAuthenticated) {
    return handleLogin();
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <RichTextEditor
          content={descriptionContent}
          setContent={setDescriptionContent}
          label={
            currentLanguage === LANGUAGES.HEBREW.value
              ? "תיאור:"
              : "Description:"
          }
          direction={currentLanguage === LANGUAGES.HEBREW.value ? "rtl" : "ltr"}
          hasAddPhoto
        >
          {selectedImages?.length > 0 && (
            <FormPreviewImages
              allowMultipleSelection
              selectedFilesList={selectedFilesList}
              setSelectedFilesList={setSelectedFilesList}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              removeIconWidth={"10px"}
              removeIconRight={"0"}
            />
          )}
          <AddPhotoWrapper>
            <div>Add to your Post</div>
            <ActionsWrapper>
              <IconWrapper>
                <StyledInput
                  type="file"
                  accept="image/*"
                  {...(register ? register : {})}
                  onInput={handleImageChange}
                  multiple
                />
                <CameraIcon />
              </IconWrapper>
              <IconWrapper>
                <LocationIconV2 />
              </IconWrapper>
              <IconWrapper>
                <EmojiIcon />
              </IconWrapper>
              <ThreeDotsIcon />
            </ActionsWrapper>
          </AddPhotoWrapper>
        </RichTextEditor>
        <CheckboxRowV2
          leftLabel={"Choose Category"}
          rightLabel={":בחירת קטגוריה"}
          categories={getCategoriesByZone(zone)}
          selectedCategory={category}
          register={{ ...register("category") }}
          boxHeight={"37px"}
          gap={"10px"}
          required
        />
        <NeedPhotoWrapper>
          <Button
            text={"Post - פרסום"}
            styles={PostButtonStyles(
              currentLanguage === LANGUAGES.ENGLISH.value
            )}
          />
        </NeedPhotoWrapper>
      </Wrapper>
    </div>
  );
}

export default CommunityBoardPost;
