import React, { useState } from "react";
import styled from "styled-components";
import TextInput from "../../../../kit/Input/TextInput";
import PhoneNumberInput from "../../../../kit/Input/PhoneNumberInput";
import { ArrowDown } from "../../../../assets/icons";

const Wrapper = styled.div`
  cursor: pointer;
  border-radius: 10.72px;
  background: rgba(56, 161, 188, 0.1);
  box-shadow: 5px 2px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 5px 0 15px 0;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: center;
  width: ${(props) => props.width ?? "calc(100% - 16px)"};
  margin: ${(props) => props.margin ?? "0 10px"};
  box-sizing: border-box;
`;

const Title = styled.div`
  color: var(--preview-txt, #105f84);
  font-family: "NotoSans-600";
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 7px;
  width: 92%;
`;

const InputTitle = styled.h4`
  margin: 0;
  direction: rtl;
  color: #58656d80;
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : "")}
  ${({ marginLeftAuto }) => (marginLeftAuto ? `margin-left: auto;` : "")}
`;

const InputTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

const SepButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 5px 2px 5px 0px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10.72px;
  background: ${(props) => props.background ?? "#38A1BC1A"};
  overflow: hidden;
  height: ${({ height }) => height};
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  color: #105f84;
`;

const SepButtonTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SepButtonText = styled.p`
  font-family: "NotoSans-600";
  font-size: 19px;
  font-weight: 600;
  line-height: 25.84px;
  text-align: right;
  margin: 0;
`;

const inputStyle = {
  backgroundColor: "#fafffa",
  border: "1.57px solid rgba(211, 211, 211, 1)",
  borderRadius: "5.232px",
  width: "22px",
  height: "27px",
  boxSizing: "border-box",
};

const OverlayInputs = ({
  title = "Message  הודעה ",
  hasSubTitle = true,
  register,
  phoneNumber,
  setPhoneNumber,
  fullName,
  showArrow,
  onArrowClick,
  width,
  margin,
  useSeparateStyle,
}) => {
  const renderNameInput = () => (
    <TextInput
      hookName="fullName"
      register={register}
      fontSize="16px"
      height={"43px"}
      borderRadius={"8px"}
      margin={"0"}
      lan="heb"
      backgroundColor={
        fullName !== "" && fullName != null
          ? "rgba(218, 243, 220, 1)"
          : undefined
      }
      additionalStyling={`box-shadow: 1.52px 1.52px 3.81px 0px rgba(0, 0, 0, 0.25); width: 100%; box-sizing: border-box;`}
    />
  );

  const renderTelInput = () => (
    <PhoneNumberInput
      initialValue={phoneNumber}
      inputStyle={{
        ...inputStyle,
        ...(phoneNumber &&
          phoneNumber.length === 10 && {
            backgroundColor: "rgba(218, 243, 220, 1)",
          }),
      }}
      setValue={setPhoneNumber}
    />
  );

  const renderSepButton = (
    leftText,
    rightText,
    expanded,
    setExpanded,
    textComponentRenderFunc
  ) => (
    <SepButton>
      <SepButtonTextContainer onClick={() => setExpanded?.((ex) => !ex)}>
        <SepButtonText>{leftText}</SepButtonText>
        <SepButtonText>{rightText}</SepButtonText>
      </SepButtonTextContainer>
      {expanded && textComponentRenderFunc && textComponentRenderFunc()}
    </SepButton>
  );

  const [nameBtnExpanded, setNameBtnExpanded] = useState(false);
  const [telBtnExpanded, setTelBtnExpanded] = useState(false);

  if (useSeparateStyle) {
    return (
      <SepWrapper>
        {renderSepButton(
          "Name",
          "שם מלא",
          nameBtnExpanded,
          setNameBtnExpanded,
          renderNameInput
        )}
        {renderSepButton(
          "Tel",
          "טלפון",
          telBtnExpanded,
          setTelBtnExpanded,
          renderTelInput
        )}
      </SepWrapper>
    );
  }

  return (
    <Wrapper width={width} margin={margin}>
      <Title>
        {title}
        {hasSubTitle && (
          <span style={{ color: "rgba(127, 179, 131, 1)" }}>
            {" (אופציונלי)"}
          </span>
        )}
      </Title>
      <InputContainer>
        <InputTitleWrapper>
          <InputTitle marginLeftAuto>שם מלא:</InputTitle>
        </InputTitleWrapper>
        {renderNameInput()}
      </InputContainer>
      <div style={{ marginTop: "13px" }} />
      <InputContainer>
        <InputTitleWrapper>
          <InputTitle marginLeftAuto>טלפון:</InputTitle>
        </InputTitleWrapper>
        {renderTelInput()}
      </InputContainer>
      {showArrow && (
        <div
          style={{
            width: "100%",
            paddingTop: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onArrowClick}
        >
          <ArrowDown width={15} color={"#316788"} rotate={true} />
        </div>
      )}
    </Wrapper>
  );
};

export default OverlayInputs;
