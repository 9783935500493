export const getShareDeleteOptions = (onEdit, onDelete, onShare) => {
  return [
    {
      text: "Edit",
      onClick: onEdit,
    },
    {
      text: "Delete",
      onClick: onDelete,
    },
    {
      text: "Share",
      onClick: onShare,
    },
  ];
};

export const getReportOptions = (onReport, onShare) => {
  return [
    {
      text: "Report",
      onClick: onReport,
    },
    {
      text: "Share",
      onClick: onShare,
    },
  ];
};
