import React, { useId } from "react";
import styled from "styled-components";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  background: ${(props) =>
    props.isWideScreen || props.isMediumScreen
      ? "rgba(127, 179, 131, 0.04)"
      : props.background ?? ""};
  box-shadow: ${(props) =>
    props.boxShadow ??
    (props.isWideScreen || props.isMediumScreen
      ? "1.383px 1.383px 3.457px 0.691px rgba(0, 0, 0, 0.15)"
      : "2px 2px 5px 1px rgba(0, 0, 0, 0.15)")};
  padding: "0 0 20px 0";
  width: ${({ width, isWideScreen, isMediumScreen }) =>
    width ? width : isWideScreen || isMediumScreen ? "94%" : "100%"};
  margin: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "20px auto" : "auto"};
`;
const CreateWrapper = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;
const Create = styled(Link)`
  color: #316788;
  font-size: 13.974px;
  font-weight: 600;
  text-decoration-line: underline;
  margin-left: 15px;
`;

const ActivitiesV2 = ({
  TitleComponent,
  titleProps,
  SliderComponent,
  sliderProps,
  LinearComponent,
  linearProps,
  sideLink,
  boxShadow,
}) => {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();

  return (
    <Wrapper
      boxShadow={boxShadow}
      isWideScreen={isWideScreen}
      isMediumScreen={isMediumScreen}
    >
      {TitleComponent && <TitleComponent {...titleProps} />}
      {SliderComponent && <SliderComponent {...sliderProps} />}
      {LinearComponent && <LinearComponent {...linearProps} />}
      {sideLink && (
        <CreateWrapper hasTitle={TitleComponent}>
          <Create to={sideLink?.link}>{sideLink?.title}</Create>
        </CreateWrapper>
      )}
    </Wrapper>
  );
};

export default ActivitiesV2;
