import React, { useContext, useEffect } from "react";
import SOSOverlay from "./Overlays/SOSOverlay.jsx";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getEmergencyById } from "../../Ecosystem/Zone/Activities/Create/api.js";
import Voice from "./components/Voice.jsx";
import EmergencyContactPopUp from "./components/EmergencyContactPopUp.jsx";
import OrganizationOverlay from "./Overlays/OrganizationOverlay.jsx";
import NeedHelpOverlay from "./Overlays/NeedHelpOverlay.jsx";
import ReassuranceOverlay from "./Overlays/ReassuranceOverlay.jsx";
import { EmergencyOverlaysContext } from "./EmergencyOverlaysContext.js";

function EmergencyOverlaysHandler({
  isSOS,
  isNeedHelp,
  isOrganization,
  id,
  isBlue,
}) {
  const {
    setShowOverlay,
    showOverlay,
    showEmergencyMap,
    setShowEmergencyMap,
    userInformation,
    setUserInformation,
    isEditOverlay,
    emergencyContactPopUpData,
    setEmergencyContactPopUpData,
    hasReassuranceColumnShare,
    Reassurance,
    setReassurance,
    forceHideMap,
  } = useContext(EmergencyOverlaysContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInformation) {
      if (!userInformation.hasOwnProperty("fullName")) {
        navigate("/public/activities/together/tel-aviv");
      } else {
        setShowEmergencyMap(true);
      }
    }
  }, [navigate, setShowEmergencyMap, userInformation]);

  useEffect(() => {
    return () => (document.body.style.overflow = "visible");
  }, []);

  useEffect(() => {
    if (!showOverlay) {
      document.body.style.overflow = "visible";
    }
  }, [showOverlay]);

  const { data: emergencyInformation } = useQuery({
    queryKey: ["emergency", id],
    queryFn: () => getEmergencyById(id),
  });

  useEffect(() => {
    if (showEmergencyMap || (id && emergencyInformation && !forceHideMap)) {
      navigate("/emergency/OverlayEmergencyMap");
    }
  }, [
    emergencyInformation,
    forceHideMap,
    id,
    navigate,
    setShowOverlay,
    showEmergencyMap,
  ]);

  const renderOverlay = () => {
    switch (true) {
      case Reassurance:
        return (
          <ReassuranceOverlay
            overlay={showOverlay}
            setOverlay={setShowOverlay}
            isReadyToSend={!isEditOverlay && userInformation !== ""}
            action={setUserInformation}
            isSOS={isSOS}
            userInformation={userInformation}
            onShare={() => {
              setEmergencyContactPopUpData(true);
              setShowOverlay(false);
              document.body.style.overflow = "visible";
            }}
            showEmergencyMap={() => setShowEmergencyMap(true)}
            hideReassurance={() => setReassurance(false)}
            hasColumnShare={hasReassuranceColumnShare}
          />
        );
      case isOrganization:
        return (
          <OrganizationOverlay
            overlay={showOverlay}
            setOverlay={setShowOverlay}
            isReadyToSend={!isEditOverlay && userInformation !== ""}
            action={setUserInformation}
          />
        );
      case isNeedHelp:
        return (
          <NeedHelpOverlay
            overlay={showOverlay}
            setOverlay={setShowOverlay}
            isReadyToSend={!isEditOverlay && userInformation !== ""}
            action={setUserInformation}
          />
        );
      case isSOS:
        return (
          <SOSOverlay
            overlay={showOverlay}
            setOverlay={setShowOverlay}
            isReadyToSend={!isEditOverlay && userInformation !== ""}
            action={setUserInformation}
            isSOS={isSOS}
            userInformation={userInformation}
            isNeedHelp={isNeedHelp}
            isOrganization={isOrganization}
            isBlue={isBlue}
          />
        );
      default:
        return (
          <NeedHelpOverlay
            overlay={showOverlay}
            setOverlay={setShowOverlay}
            isReadyToSend={!isEditOverlay && userInformation !== ""}
            action={setUserInformation}
          />
        );
    }
  };

  return (
    <>
      {showOverlay && <Voice>{renderOverlay()}</Voice>}
      {emergencyContactPopUpData && (
        <EmergencyContactPopUp
          visible={!!emergencyContactPopUpData}
          hidePopUpfunc={() => setEmergencyContactPopUpData(undefined)}
          data={emergencyContactPopUpData}
          onReturn={() => {
            setEmergencyContactPopUpData(undefined);
            setReassurance(true);
            setShowOverlay(true);
          }}
        />
      )}
    </>
  );
}

export default EmergencyOverlaysHandler;
