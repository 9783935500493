import { useQuery } from "@tanstack/react-query";
import { getHubs } from "./api";

const useGetHubs = (findActivitiesByZones) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hubs", findActivitiesByZones],
    queryFn: () => getHubs(findActivitiesByZones),
  });
  return { data: { Hubs: reverseData(data?.Hubs) }, isLoading };
};

function reverseData(data) {
  const obj = {};
  for (const key in data) {
    obj[key] = [];
    for (let i = data[key].length - 1; i >= 0; i--) {
      obj[key].push(data[key][i]);
    }
  }
  return obj;
}

export default useGetHubs;
