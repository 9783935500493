import LogoWithText from "../../../../kit/Icon/IconWithText";
import React, { useState, useEffect } from "react";
import { Box, Dialog, styled as MuiStyled } from "@mui/material";
import VTTabs from "./components/VTTabs";
import {
  ABSTRACT_EMERGENCY_POPUP_TABS,
  ABSTRACT_EMERGENCY_TABS,
} from "./constants";
import Map, { MarkerIcon } from "../../../../kit/Map/Map";
import LocationDetails from "./components/LocationDetails";
import { TogetherIconV2 } from "../../../../assets/icons/Logo";
import { BackButton } from "../../../../assets/icons";
import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../../Common/Styled";
import { CodeInput } from "../../../../kit/Input";
import SelectionPopup from "../../../Admin/AdminArea/CreatePage/SelectionPopup";
import MuiButton from "../../../../kit/Button/MuiButton";

const Modal = MuiStyled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    background: "#FAFFFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "100%",
    minHeight: "100%",
    overflowX: "hidden",
    padding: "40px 10px 20px 10px",
    position: "relative",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(173, 202, 230, 0.5)",
  },
}));

const buttonStyle = {
  color: "#316788",
  fontSize: 17,
  fontWeight: 600,
  fontFamily: "Assistant",
  borderRadius: "10px",
  border: "2px solid #316788",

  width: 190,
  height: 30,
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "space",
  "&:hover": {
    backgroundColor: "#BCD9BF",
  },
};

const Text = styled.div`
  font-size: 21.14px;
  font-weight: 600;
  font-family: Assistant;
  color: #4b778d;
  text-align: center;
`;
const locationOptions = [
  {
    title: "לשעה הקרובה - 1h",
    value: 1,
  },
  {
    title: "שעתיים - 2h",
    value: 2,
  },
  {
    title: "4 שעות - 4h",
    value: 3,
  },
  {
    title: "8 שעות - 8h",
    value: 4,
  },
  {
    title: "לא כרגע - Maybe Later",
    value: 5,
  },
];

const PopupZoneMap = ({
  isOpen,
  onClose,
  selectedLocation,
  setSelectedLocation,
  onLocationClick,
  iconWidth,
  iconHeight,
  showUserInformation,
  isOpenedFromSecondButton,
}) => {
  const [activeTabs, setActiveTabs] = useState([
    ABSTRACT_EMERGENCY_POPUP_TABS[0][2].value,
  ]);
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [openLocationSelection, setOpenLocationSelection] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(showUserInformation);

  useEffect(() => {
    // Use the Geolocation API to get the user's current position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMapCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching location:", error);
          setMapCenter({ lat: 32.0853, lng: 34.7818 });
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      setMapCenter({ lat: 32.0853, lng: 34.7818 });
    }
  }, []);

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    setShowLocationDetails(true);
  };

  const renderLocationDetails = () => {};

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{ position: "absolute", left: "10px", cursor: "pointer" }}
        onClick={onClose}
      >
        <BackButton width={75} height={45} />
      </Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginBottom: "20px",
        }}
      ></Box>
      <LogoWithText
        left={"-90px"}
        top={"40px"}
        width={"150px"}
        iconHeight="60"
        iconWidth="200"
        Icon={TogetherIconV2}
        title={"In Emergency"}
        titleStyles={`
              font-size: 15px;
              color: #EF7300;
              font-weight: 500;
            `}
      />
      <FlexColumn gap={"20px"}>
        {showUserInfo ? (
          <FlexColumn gap={"20px"} sx={{ width: "calc(100vw - 60px)" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: "20px",
                height: "62px",
                width: "100%",
              }}
            >
              <Text>Michal Gold מיכל גולד</Text>
              <Text>Paramedic פראמדיקית</Text>
            </Box>
            <FlexRow
              sx={{
                justifyContent: "space-between",
                borderRadius: "20px",
                border: "2px solid #337A37",
                padding: "0 15px",
                height: "30.43px",
              }}
            >
              <Text>Search</Text>
              <Text>חיפוש</Text>
            </FlexRow>
          </FlexColumn>
        ) : (
          <FlexRow gap={"20px"}>
            <CodeInput
              navigateLink={null}
              setValue={(value) => {
                if (value.length === 6) {
                  setOpenLocationSelection(true);
                }
              }}
              inputNum={6}
              styles={{
                width: "23.42px",
                height: "27.02px",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                border: "1.8px solid #316788",
                textAlign: "center",
              }}
            />
            <Text>הכנס קוד</Text>
          </FlexRow>
        )}
      </FlexColumn>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          gap: "50px",
          marginTop: "30px",
        }}
      >
        <VTTabs
          tabsGroup={
            showUserInfo
              ? ABSTRACT_EMERGENCY_TABS
              : ABSTRACT_EMERGENCY_POPUP_TABS
          }
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
          width={"100%"}
          allowMultiSelect={false}
        />
        {isOpenedFromSecondButton && (
          <FlexRow
            sx={{
              justifyContent: "space-between",
              width: "87%",
              margin: "20px auto 15px auto",
              boxSizing: "border-box",
            }}
          >
            <MuiButton
              sx={{
                ...buttonStyle,
                backgroundColor: "#BCD9BF",
              }}
            >
              מפת מוקדי סיוע והצלה
            </MuiButton>
            <MuiButton
              sx={{
                ...buttonStyle,
                width: 141,
              }}
            >
              קריאות מצוקה
            </MuiButton>
          </FlexRow>
        )}
        {mapCenter && (
          <Map
            placesType={activeTabs}
            initialSelectedLocation={mapCenter}
            onMarkerClick={handleMarkerClick}
            disableClick
            borderRadius={"20px"}
            defaultColor={MarkerIcon.Blue}
            zoom={10}
          />
        )}

        {showLocationDetails && selectedLocation && (
          <LocationDetails
            location={selectedLocation}
            reset={() => {
              setShowLocationDetails(false);
              setSelectedLocation(null);
            }}
            onClick={onLocationClick}
          />
        )}
        <SelectionPopup
          title={"בחירת איזור - Zone"}
          visible={openLocationSelection}
          setVisible={setOpenLocationSelection}
          onDismiss={(selected) => {
            setShowUserInfo(true);
          }}
          options={locationOptions}
        />
      </Box>
    </Modal>
  );
};

export default PopupZoneMap;
