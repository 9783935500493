import React from "react";

function UploadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      height={43}
      fill="none"
      {...props}
    >
      <g filter="url(#a)">
        <circle cx={21.177} cy={18.545} r={13.607} fill="#28B5B5" />
      </g>
      <g fill="#fff" clipPath="url(#b)">
        <path d="M21.177 24.59c-1.124 0-2.249.008-3.373-.003-1.027-.01-1.81-.463-2.335-1.348a2.456 2.456 0 0 1-.337-1.253c-.005-.772-.003-1.545 0-2.317 0-.273.159-.488.396-.555.232-.065.488.018.606.226a.763.763 0 0 1 .094.351c.008.739.004 1.477.004 2.214 0 .916.668 1.582 1.585 1.583h6.721c.918 0 1.584-.666 1.585-1.583v-2.252c.001-.25.15-.455.375-.53a.52.52 0 0 1 .598.18.63.63 0 0 1 .122.329c.005.857.025 1.717-.017 2.573-.056 1.142-1.007 2.156-2.138 2.337-.186.03-.376.045-.564.046-1.107.003-2.214.001-3.322.001Z" />
        <path d="M21.726 14.42v6.809c0 .32-.15.527-.424.596a.545.545 0 0 1-.668-.486c-.005-.06-.003-.12-.003-.18v-6.778c-.057.053-.09.083-.12.114l-2.204 2.203c-.198.197-.434.244-.657.136a.55.55 0 0 1-.188-.84c.03-.037.064-.07.098-.103 1.056-1.057 2.113-2.113 3.17-3.168.298-.3.598-.301.894-.005l3.197 3.195c.166.165.24.353.172.584a.545.545 0 0 1-.872.266c-.04-.033-.076-.07-.112-.107-.72-.72-1.44-1.438-2.157-2.159-.032-.032-.048-.081-.07-.123l-.056.045Z" />
      </g>
      <defs>
        <clipPath id="b">
          <path fill="#fff" d="M15.129 12.497h12.098v12.095H15.129z" />
        </clipPath>
        <filter
          id="a"
          width={42.334}
          height={42.333}
          x={0.011}
          y={0.402}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3.024} />
          <feGaussianBlur stdDeviation={3.78} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.260486 0 0 0 0 0.516667 0 0 0 0 0.501296 0 0 0 0.2 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_161_375"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_161_375"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default UploadIcon;
