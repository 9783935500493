import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { LANGUAGES } from "../../domain/Common/Layout/Header/constant";

const InputWrapper = styled.div`
  height: ${(props) => props.height};
  margin: ${(props) => (props.margin ? props.margin : "17px 25px")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "14px")};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : "8px"};
  width: ${(props) =>
    props.width ? props.width : "calc(100% - 68px)"}; // 68 = 25 + 25 + 18
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius ?? ""};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  display: flex;
  align-items: center;
  flex: ${(props) => props.flex};
  position: relative;
  ${(props) => props.additionalStyling ?? ""}
`;

const Input = styled.textarea`
  color: var(--text, #696d72);
  font-size: ${(props) => props.fontSize || "20px"};
  padding-right: 2px;
  font-weight: 400;
  border: none;
  outline: none;
  width: 100%;
  min-height: ${(props) => props.minHeight || "auto"};
  resize: vertical; /* Allows textarea to resize vertically */
  text-align: ${(props) =>
    props.lan === LANGUAGES.HEBREW.key
      ? "right"
      : "left"}; /* Conditionally set text-align */
  ${(props) => props.inputExtraStyles}
`;

const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  margin-top: 2px;
`;

function TextInput({
  height,
  width,
  margin,
  border,
  borderRadius,
  fontSize,
  type,
  flex,
  placeholder,
  register,
  hookName,
  ariaInvalid,
  required,
  backgroundColor,
  ActionButton,
  additionalStyling,
  defaultValue,
  readonly,
  inputExtraStyles,
  showPasswordByDefault,
  paddingLeft,
  paddingRight,
  minHeight, // New prop for minimum height
  lan,
  focusOnLoad,
  // autocomplete,
}) {
  const defaultRegister = () => {};
  const inputRef = useRef(null);

  const [showPassword, setShowPassword] = useState(!!showPasswordByDefault);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (focusOnLoad && inputRef.current) {
      inputRef.current?.focus?.();
    }
  }, [focusOnLoad]);

  return (
    <InputWrapper
      height={height}
      width={width}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      flex={flex}
      additionalStyling={additionalStyling}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
    >
      <Input
        defaultValue={defaultValue}
        readOnly={readonly}
        fontSize={fontSize}
        backgroundColor={backgroundColor}
        type={showPassword ? "text" : type}
        placeholder={placeholder}
        {...(register ? register(hookName) : defaultRegister())}
        aria-invalid={ariaInvalid}
        required={required}
        inputExtraStyles={inputExtraStyles}
        minHeight={minHeight}
        lan={lan}
        //  autocomplete={autocomplete}
        // ref={inputRef}
      />
      {type === "password" && (
        <EyeIcon onClick={togglePasswordVisibility}>
          {showPassword ? (
            <AiOutlineEyeInvisible size={"2em"} />
          ) : (
            <AiOutlineEye size={"2em"} />
          )}
        </EyeIcon>
      )}
      {ActionButton && <ActionButton />}
    </InputWrapper>
  );
}

export default TextInput;
