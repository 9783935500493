import React from "react";

export default function Notifications({ color = "#007897" }) {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.80596 10.5932C3.80435 9.44467 4.02997 8.30722 4.46983 7.24632C4.90969 6.18541 5.5551 5.222 6.36888 4.4116C7.18267 3.60119 8.14874 2.95979 9.21147 2.52435C10.2742 2.0889 11.4126 1.86801 12.561 1.8744C17.3563 1.91073 21.195 5.89472 21.195 10.7021V11.5619C21.195 15.8971 22.1032 18.4158 22.9024 19.7963C22.9873 19.9433 23.0321 20.11 23.0322 20.2798C23.0324 20.4496 22.9879 20.6164 22.9033 20.7636C22.8187 20.9107 22.6969 21.0331 22.5501 21.1183C22.4033 21.2036 22.2367 21.2488 22.0669 21.2494H2.93409C2.76433 21.2488 2.5977 21.2036 2.45091 21.1183C2.30412 21.0331 2.18231 20.9107 2.09768 20.7636C2.01306 20.6164 1.9686 20.4496 1.96875 20.2798C1.9689 20.11 2.01366 19.9433 2.09854 19.7963C2.89776 18.4158 3.80596 15.8971 3.80596 11.5619V10.5932Z"
        stroke={color}
        strokeWidth="2.21429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22V22.9687C9 23.9965 9.40826 24.9821 10.135 25.7088C10.8617 26.4355 11.8473 26.8437 12.875 26.8437C13.9027 26.8437 14.8883 26.4355 15.615 25.7088C16.3417 24.9821 16.75 23.9965 16.75 22.9687V22"
        stroke={color}
        strokeWidth="2.21429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
