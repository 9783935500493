import React from "react";

const ShareButtonIcon = ({ color, width = 24, height = 31 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 31"
    >
      <g>
        <g>
          <path
            fill={color ?? "#EF7300"}
            d="M0 12.4c.139-.134.094-.324.132-.485A3.386 3.386 0 013.394 9.29c1.039-.013 2.078-.008 3.117-.003.68.003 1.191.502 1.184 1.139-.007.64-.518 1.122-1.202 1.124-.98.004-1.96-.001-2.94.002-.748.002-1.22.466-1.22 1.205v14.631c0 .75.457 1.206 1.213 1.206 5.62.002 11.24.002 16.86 0 .754 0 1.21-.456 1.211-1.207.002-4.877.002-9.755 0-14.632 0-.741-.468-1.201-1.22-1.203-.979-.003-1.959.001-2.94-.002-.68-.002-1.193-.487-1.2-1.126-.006-.643.496-1.134 1.182-1.137 1.039-.005 2.078-.011 3.117.003 1.737.023 3.139 1.313 3.325 3.042.005.056-.009.12.07.14v.07c-.137.082-.067.213-.067.319-.004 4.807-.004 9.615 0 14.422 0 .105-.069.237.067.32v.14c-.096.027-.065.11-.075.173a3.393 3.393 0 01-3.251 2.946c-.106-.004-.212-.01-.318-.01H3.785c-.153 0-.306.006-.459.01A3.4 3.4 0 01.13 28.227c-.038-.16.012-.35-.129-.484v-.14c.135-.082.067-.213.067-.318.004-4.81.004-9.618 0-14.426 0-.105.07-.236-.067-.317V12.4z"
          ></path>
          <path
            fill="#A5A1A2"
            d="M20.625 30.87a.075.075 0 00-.004.063H3.331a.076.076 0 00-.004-.063c.153-.004.306-.01.459-.01h16.522c.105 0 .211.006.317.01z"
          ></path>
          <path
            fill="#A5A1A2"
            d="M0 12.547c.137.08.068.212.068.317.004 4.808.004 9.617 0 14.425 0 .105.068.236-.067.318v-15.06z"
          ></path>
          <path
            fill="#A5A1A2"
            d="M23.952 27.607c-.136-.082-.067-.213-.067-.32-.004-4.806-.004-9.614 0-14.422 0-.105-.07-.237.067-.318v15.06z"
          ></path>
          <path
            fill={color ?? "#EF7300"}
            d="M13.166 4.01V18.497c0 .189 0 .375-.05.561-.14.516-.576.85-1.119.845a1.098 1.098 0 01-1.086-.88 2.608 2.608 0 01-.045-.526c-.002-4.701-.002-9.402-.002-14.104v-.39c-.159.002-.21.124-.286.2-.93.92-1.85 1.853-2.785 2.768-.659.645-1.67.41-1.932-.436-.13-.416-.045-.804.26-1.11 1.7-1.705 3.4-3.407 5.112-5.098a1.097 1.097 0 011.565.002 753.68 753.68 0 015.11 5.099c.444.446.411 1.163-.026 1.59-.445.435-1.152.437-1.623-.026-.927-.908-1.84-1.83-2.756-2.749-.09-.09-.153-.203-.228-.306l-.11.073z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ShareButtonIcon;
