export const SECTIONS_NAMES = {
  NEIGHBORHOOD: "neighborhood",
  SCHOOL: "school",
  ORGANIZATION: "organization",
};

export const SECTION_TITLE = {
  [SECTIONS_NAMES.NEIGHBORHOOD]: "השכונה שלי ושכונות נוספות",
  [SECTIONS_NAMES.SCHOOL]: "מוסדות לימוד",
  [SECTIONS_NAMES.ORGANIZATION]: "ארגונים",
};

export const HUB_CREATE_ROUTE = {
  event: "new-event",
  activity: "new-activity",
  task: "new-task",
  hub: "new-hub",
};

export const HUB_NAMES = {
  EVENT: "event",
  ACTIVITY: "activity",
  TASK: "task",
  hub: "hub",
};

export const NO_AUTH_VIEW = [
  "6654a2aa347cfaf875a2b135",
  "6640e283688afe7a27142d8b",
  "6640c09f688afe7a27141e08",
  "6677a60e31d965b52d4e58d8",
  "6679510731d965b52d4f4067",
  "667c720431d965b52d51abf7",
];

export const NO_EMERGENCY_SECTION = [
  "6677a60e31d965b52d4e58d8",
  "6679510731d965b52d4f4067",
  "6640c09f688afe7a27141e08",
  "667c720431d965b52d51abf7",
];

export const EMERGENCY_BUTTONS_COLLAPSED = ["6640beff688afe7a27141c6b"];

export const VET_SPONSORS_SECTION = ["66051bbbf000a65bb520bd6e"];

export const NO_SPONSORS_SECTION = [
  "6679510731d965b52d4f4067",
  "6677a60e31d965b52d4e58d8",
  "6640beff688afe7a27141c6b",
  "6640c09f688afe7a27141e08",
  "667c720431d965b52d51abf7",
];

export const NO_BOARD_SECTION = [
  "6679510731d965b52d4f4067",
  "6677a60e31d965b52d4e58d8",
  "667c720431d965b52d51abf7",
];

export const NO_GIVE_RECEIVE_BOARD = [];

export const NO_NETWORK_BOARD = ["65adf777944824b0357f4e82"];

export const NO_Volunteer_BOARD = [];

export const NO_APPLES_AND_SEARCH_SECTION = [
  "6655d5224b6e3b35f15097c0",
  "665ac1202c3c3162c9c7f5e7",
];

export const LANDING_ROUTES = [
  "665ac1202c3c3162c9c7f5e7",
  "6640beff688afe7a27141c6b",
  "6640c09f688afe7a27141e08",
  "6654a2aa347cfaf875a2b135",
  "65adf777944824b0357f4e82",
];

export const COLLAPSIBLE_APPLE_SLIDER = {
  "6640c09f688afe7a27141e08": true, // id: collapsedByDefault?
  "65adf777944824b0357f4e82": true,
};

export const ENTRY_FOR_VOLUNTEERS_ZONES = [
  "6679510731d965b52d4f4067",
  "6677a60e31d965b52d4e58d8",
  "667c720431d965b52d51abf7",
];
