import React, { createContext, useContext, useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styled from "styled-components";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import { LanguageContext } from "../../../../store/languageContext";
const Text = styled.p`
  color: ${(props) => props.color ?? "#000"};
  font-family: Assistant;
  font-size: 16px;
`;
export const VoiceContext = createContext();
const Voice = ({ children }) => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const [cuurentLang, setCurrentLang] = useState(LANGUAGES.HEBREW.key);
  const [error, setError] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const commands = [];
  const { transcript, listening, resetTranscript } = useSpeechRecognition({
    commands,
  });
  const timeSpentTalking = Math.floor(Math.abs((endTime - startTime) / 1000));
  const getCurrentLanguage = () => {
    if (currentLanguage === LANGUAGES.HEBREW.key) return "he-IL";
    else if (currentLanguage === LANGUAGES.ENGLISH.key) return "en-GB";
    else return "he-IL";
  };
  const StartRecord = async () => {
    SpeechRecognition.startListening({ language: getCurrentLanguage() });
  };
  const StopRecord = async () => {
    SpeechRecognition.stopListening({ language: getCurrentLanguage() });
  };

  //language:
  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return null;
    }

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      setError(
        "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
      );
    }
  }, []);
  useEffect(() => {
    if (listening) {
      setStartTime(new Date());
    } else {
      setEndTime(new Date());
    }
  }, [listening]);

  useEffect(() => {
    return () => {
      resetTranscript();
    };
  }, []);
  return (
    <VoiceContext.Provider
      value={{
        listening,
        timeSpentTalking,
        transcript,
        StartRecord,
        StopRecord,
      }}
    >
      {error && <Text color={"red"}>{error}</Text>}
      {children}
    </VoiceContext.Provider>
  );
};

export default Voice;
