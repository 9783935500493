import React, { useState } from "react";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import MuiButton from "../../../../../kit/Button/MuiButton";
import { Box, TextField } from "@mui/material";
import styled from "styled-components";
import { CodeInput } from "../../../../../kit/Input";
import LMap from "../../mock/share-l-map.png";
import MMap from "../../mock/share-m-map.png";
import { SlArrowLeft } from "react-icons/sl";

const Text = styled.div`
  color: #4b778d;
  font-size: 20px;
  font-weight: 600;
  font-family: Assistant;
`;

const Image = styled.img`
  width: 90%;
  cursor: pointer;
  margin-bottom: 20px;
`;

const buttonStyle = {
  color: "#316788",
  fontSize: 17,
  fontWeight: 600,
  fontFamily: "Assistant",
  borderRadius: "10px",
  border: "2px solid #316788",
  margin: "21px auto 21px auto",

  width: 208,
  height: 30,
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "space",
  "&:hover": {
    backgroundColor: "#BCD9BF",
  },
};

const textFieldStyles = {
  sx: {
    width: "157.22px",
    borderRadius: "8.165px",
    margin: "10px 0",
    "& .MuiOutlinedInput-root": {
      background: "#FFFFFF",
      borderRadius: "8.165px",
      border: "1.4px solid#316788",
      "&.Mui-focused fieldset": {
        border: 0,
      },
    },
  },
  InputProps: {
    sx: {
      "&.MuiInputBase-root": {
        height: 30.79,
        backgroundColor: "#FFFFFF",
        padding: 0,
      },
      "& .MuiInputBase-input::placeholder": {
        color: "gray",
      },
    },
  },
  inputProps: { sx: { direction: "rtl" } },
};

const HelpFlow = ({
  showMap,
  showFooter,
  isAuthenticated,
  hideFooter,
  setIsOpenedFromSecondButton,
  isOpenedFromSecondButton,
  showBack,
  setShowBack,
}) => {
  const [showCode, setShowCode] = useState(false);
  const [username, setUsername] = useState("");
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const handleFirstButtonClick = () => {
    setActiveButton("first");
    setShowCode(true);
    setShowBack(true);
    setIsOpenedFromSecondButton(true);
    hideFooter();
    setIsFirstButtonActive(true);
  };

  const handleSecondButtonClick = () => {
    setActiveButton("second");
    showMap();
    setIsOpenedFromSecondButton(true);
    setShowCode(false);
    setShowBack(false);
    showFooter();
    setIsFirstButtonActive(false);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    if (value.length === 6) {
      showMap();
      setShowCode(false);
      showFooter();
    }
  };

  const renderCode = () => {
    return (
      <>
        <FlexColumn sx={{ marginBottom: "20px" }}>
          <FlexRow sx={{ gap: "20px", alignItems: "center" }}>
            <Text>Name </Text>

            <TextField
              value={username}
              onChange={handleUsernameChange}
              sx={textFieldStyles.sx}
              InputProps={{
                ...textFieldStyles.InputProps,
              }}
              inputProps={textFieldStyles.inputProps}
            />
            <Text>שם משתמש</Text>
          </FlexRow>
          <FlexRow
            sx={{ gap: "20px", alignItems: "center", marginBottom: "25px" }}
          >
            <Text>Code </Text>

            <CodeInput
              navigateLink={null}
              setValue={(value) => {
                if (value.length === 6) {
                  showMap();
                  setShowCode(false);
                  showFooter();
                }
              }}
              inputNum={6}
              styles={{
                width: "23.21px",
                height: "26.79px",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                border: "1.4px solid #316788",
                textAlign: "center",
              }}
            />
            <Text>שם מלא</Text>
          </FlexRow>
        </FlexColumn>

        <FlexRow
          sx={{
            justifyContent: "space-between",
            width: "82%",
            margin: "-30px auto 5px auto",
            boxSizing: "border-box",
          }}
        >
          <MuiButton
            sx={{
              ...buttonStyle,
              backgroundColor: activeButton === "first" ? "#7FB383" : "#FFFFFF", // Highlight if active

              width: isAuthenticated ? 190 : 241,
              height: isAuthenticated ? 30 : 35,
              fontSize: isAuthenticated ? 17 : 20,
            }}
            onClick={handleSecondButtonClick}
          >
            מפת מוקדי סיוע והצלה
          </MuiButton>
          {isAuthenticated && (
            <MuiButton
              sx={{
                ...buttonStyle,
                backgroundColor:
                  activeButton === "second" ? "#7FB383" : "#FFFFFF", // Highlight if active

                width: 141,
                "&:hover": {
                  backgroundColor: "#CAC7C7",
                },
              }}
              onClick={handleSecondButtonClick}
            >
              קריאות מצוקה
            </MuiButton>
          )}
        </FlexRow>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <FlexRow
        sx={{
          justifyContent: isAuthenticated ? "space-between" : "center",
          width: "85%",
          margin: "-26px auto -5px auto",
          boxSizing: "border-box",
        }}
      >
        <MuiButton
          sx={{
            ...buttonStyle,
            width: isAuthenticated ? 190 : 241,
            height: isAuthenticated ? 30 : 35,
            fontSize: isAuthenticated ? 17 : 20,
          }}
          onClick={handleFirstButtonClick}
        >
          מפת מוקדי סיוע והצלה
        </MuiButton>
        {isAuthenticated && (
          <MuiButton
            sx={{
              ...buttonStyle,
              width: 141,
              "&:hover": {
                backgroundColor: "#CAC7C7",
              },
            }}
            onClick={handleSecondButtonClick}
          >
            קריאות מצוקה
          </MuiButton>
        )}
      </FlexRow>
    );
  };

  return (
    <>
      {showBack && (
        <Box
          onClick={() => {
            setShowCode(false);
            setShowBack(false);
            showFooter();
          }}
          sx={{
            width: 60,
            height: 26,
            border: "1px solid #003F5D",
            color: "#003F5D",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            fontSize: "15px",
            fontWeight: 600,
            fontFamily: "Assistant",
            position: "absolute",
            top: 43,
            left: 32,
            borderRadius: "8.81px",
            cursor: "pointer",
          }}
        >
          <SlArrowLeft size={13} />
          <div>Back</div>
        </Box>
      )}
      {showCode ? renderCode() : renderButtons()}
      <Image
        onClick={() => {
          showMap();
        }}
        src={showCode ? MMap : LMap}
        alt="mapImage"
      />
    </>
  );
};

export default HelpFlow;
