import React, { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import styled from "styled-components";
import PopUpDialog from "../PopUpModal/PopUpDialog";
import useMediaQueryConstants from "../../customHooks/useMediaQueryConstants";

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const ImageWrapper = styled.div`
  flex: 1;
  max-width: ${(props) => props.maxWidth};
  position: relative;
  cursor: pointer;
`;

const PostImage = styled.img`
  border-radius: 5px;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 5px;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalImage = styled.img`
  max-width: 80%;
  border-radius: 10px;
  object-fit: cover;
`;

const ModalPrvRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-width: 80%;
  flex-wrap: wrap;
  margin: 15px auto 0 auto;
`;

const VerticalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  max-width: 40%;
`;

const ModalPrvPhoto = styled.img`
  max-width: 75px;
  max-height: 75px;
  border-radius: 5px;
  object-fit: cover;
  border: ${(props) => (props.selected ? "1px solid lightblue" : "none")};
  cursor: pointer;
`;

function PostImages({ photos }) {
  const { isWideScreen } = useMediaQueryConstants();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  if (!photos || photos.length < 1) {
    return null;
  }

  const openModal = (index) => {
    setCurrentImage(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % photos.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + photos.length) % photos.length);
  };

  const renderModal = () => {
    const chevronStyle = {
      background: "white",
      cursor: "pointer",
      minWidth: "30px",
      minHeight: "30px",
      borderRadius: "50%",
      marginLeft: "5px",
      padding: 0,
    };
    return (
      <>
        <ModalBackdrop onClick={closeModal} />
        <PopUpDialog
          position={"fixed"}
          paddingPopUpDialog={"0"}
          background={"transparent"}
          backgroundPopUpDialog={"transparent"}
          borderRadius={0}
          top={"100px"}
          left={isWideScreen ? "8.5vw" : "0"}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <HiChevronLeft
              style={chevronStyle}
              size={"2em"}
              color={"gray"}
              onClick={prevImage}
            />
            <ModalImage src={photos[currentImage]} />
            <HiChevronRight
              onClick={nextImage}
              style={chevronStyle}
              size={"2em"}
              color={"gray"}
            />
          </div>
          <ModalPrvRow>
            {photos?.map?.((photo, index) => (
              <ModalPrvPhoto
                src={photo}
                selected={currentImage === index}
                onClick={() => setCurrentImage(index)}
              />
            ))}
          </ModalPrvRow>
        </PopUpDialog>
      </>
    );
  };

  const renderImage = (index, maxWidth, showOverlay) => (
    <ImageWrapper
      key={index}
      maxWidth={maxWidth ?? "100%"}
      onClick={() => openModal(index)}
    >
      <PostImage src={photos[index]} />
      {showOverlay && <Overlay>+{photos.length - 3}</Overlay>}
    </ImageWrapper>
  );

  const renderImages = () => {
    if (photos.length === 1) {
      return renderImage(0, "100%");
    }
    if (photos.length === 2) {
      return (
        <>
          {renderImage(0, "50%")}
          {renderImage(1, "50%")}
        </>
      );
    }
    if (photos.length === 3) {
      return (
        <>
          {renderImage(0, "50%", false)}
          <VerticalImageContainer>
            {renderImage(1)}
            {renderImage(2)}
          </VerticalImageContainer>
        </>
      );
    }

    if (photos.length === 4) {
      return (
        <>
          <VerticalImageContainer>
            {renderImage(0)}
            {renderImage(1)}
          </VerticalImageContainer>
          <VerticalImageContainer>
            {renderImage(2)}
            {renderImage(3)}
          </VerticalImageContainer>
        </>
      );
    }
    return (
      <>
        <VerticalImageContainer>
          {renderImage(0)}
          {renderImage(1)}
        </VerticalImageContainer>
        <VerticalImageContainer>
          {renderImage(2)}
          {renderImage(3, undefined, true)}
        </VerticalImageContainer>
      </>
    );
  };

  return (
    <>
      <ImagesContainer>{renderImages()}</ImagesContainer>
      {modalOpen && renderModal()}
    </>
  );
}

export default PostImages;
