import React, { useContext } from "react";
import styled from "styled-components";
import TextareaWrapper from "./components/TextareaWrapper";
import CheckboxRow from "./components/CheckboxRow";
import InlineInput from "./components/InlineInput";
import Button from "../../../../kit/Button/Button";
import AddPhoto from "./components/AddPhoto";
import { useForm } from "react-hook-form";
import { UserContext } from "../../../../store/userContext";
import { createPostItem, editPostItem } from "../api";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../../store/languageContext";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";
import { PostButtonStyles } from "./AddPost";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: ${({ isHebrew }) => (isHebrew ? "30px" : "10px")};
  position: relative;
`;
const TwoInputWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;
const AddPhotoWrapper = styled.div`
  width: 50%;
  margin-right: 2px;
`;

function PostItems({
  onPostSuccess,
  zone,
  refetchPostItems,
  type,
  section,
  isEditMode = false,
  postData,
}) {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: isEditMode ? postData : {},
  });
  const { currentUser, isAuthenticated } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const navigate = useNavigate();

  const Status = watch("status");
  const Category = watch("category");
  const Remark = watch("remark");

  const onSubmit = async (data) => {
    isEditMode ? handleEditPost(data) : handleCreatePost(data);
  };

  const handleCreatePost = async (data) => {
    const postData = {
      ...data,
      user_id: currentUser._id,
      photo: data?.photo?.[0],
      zone: zone,
      type: type ?? undefined,
    };
    try {
      await createPostItem(postData);
      refetchPostItems();
      onPostSuccess?.();
    } catch (error) {}
  };

  const handleEditPost = async (data) => {
    const postData = {
      ...data,
      user_id: currentUser._id,
      zone: zone,
      type: type ?? undefined,
      photo: data?.photo?.[0],
    };
    try {
      await editPostItem(data._id, postData);
      refetchPostItems();
      onPostSuccess?.();
    } catch (error) {}
  };

  const getStatus = (english) => [
    {
      label: english ? "requiring repair" : "צריך תיקון",
      value: "requiringRepair",
    },
    { label: english ? "working" : "עובד", value: "working" },
    { label: english ? "like new" : "כמו חדש", value: "likeNew" },
    { label: english ? "new" : "חדש", value: "new" },
  ];

  const getCategories = (english) => [
    { label: english ? "General" : "כללי", value: "general" },
    { label: english ? "Sport" : "ספורט", value: "sport" },
    { label: english ? "Education" : "לימודים", value: "education" },
    { label: english ? "Pets" : "בע״ח", value: "pets" },
    { label: english ? "Electronic" : "אלקטרוניקה", value: "electronic" },
  ];

  const getRemark = (english) => [
    { label: english ? "Free" : "חינם", value: "free" },
    { label: english ? "Cost" : "מחיר סמלי", value: "cost" },
  ];

  const textProps = {
    labelColor: "#1B5C6D",
    labelFontSize: "14px",
    fontSize: "14px",
  };

  const handleLogin = () => {
    setLoginRoute(
      `/activities/board/zone/${zone}${section ? `/section/${section}` : ""}`
    );
    navigate("/login");
  };

  if (!isAuthenticated) {
    return handleLogin();
  }

  return (
    <Wrapper
      isHebrew={currentLanguage === LANGUAGES.HEBREW.value}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TwoInputWrapper>
        <InlineInput
          label={
            currentLanguage === LANGUAGES.HEBREW.value ? "קוד:" : "Your Code:"
          }
          register={{ ...register("code") }}
          {...textProps}
        />
        <InlineInput
          width={"55%"}
          label={currentLanguage === LANGUAGES.HEBREW.value ? "מוצר:" : "Item:"}
          register={{ ...register("item") }}
          {...textProps}
        />
      </TwoInputWrapper>
      <TextareaWrapper
        label={
          currentLanguage === LANGUAGES.HEBREW.value ? "תיאור:" : "Description:"
        }
        minHeight={"68px"}
        register={{ ...register("description") }}
        {...textProps}
      />
      <CheckboxRow
        label={currentLanguage === LANGUAGES.HEBREW.value ? "ססטוס" : "Status"}
        categories={getStatus(currentLanguage === LANGUAGES.ENGLISH.value)}
        selectedCategory={Status}
        register={{ ...register("status") }}
        {...textProps}
      />
      <CheckboxRow
        label={
          currentLanguage === LANGUAGES.HEBREW.value ? "asdasd" : "Category"
        }
        categories={getCategories(currentLanguage === LANGUAGES.ENGLISH.value)}
        selectedCategory={Category}
        register={{ ...register("category") }}
        {...textProps}
      />
      <InlineInput
        {...textProps}
        label={currentLanguage === LANGUAGES.HEBREW.value ? "שם מלא:" : "Name:"}
        register={{ ...register("name") }}
      />
      <InlineInput
        {...textProps}
        label={
          currentLanguage === LANGUAGES.HEBREW.value ? "מספר סלולרי:" : "TEL:"
        }
        register={{ ...register("telephone") }}
      />
      <InlineInput
        {...textProps}
        label={
          currentLanguage === LANGUAGES.HEBREW.value ? "מיקום:" : "Location:"
        }
        register={{ ...register("location") }}
      />
      <CheckboxRow
        label={currentLanguage === LANGUAGES.HEBREW.value ? "הערה" : "Note"}
        categories={getRemark(currentLanguage === LANGUAGES.ENGLISH.value)}
        selectedCategory={Remark}
        register={{ ...register("remark") }}
        sameLine
        {...textProps}
      />
      <TwoInputWrapper>
        <Button
          text={currentLanguage === LANGUAGES.HEBREW.value ? "פירסום" : "post"}
          styles={{
            ...PostButtonStyles(currentLanguage === LANGUAGES.ENGLISH.value),
            width: "50%",
            height: "auto",
          }}
        />
        <AddPhotoWrapper>
          <AddPhoto
            color={"rgba(255, 255, 255, 0.00)"}
            border={"0.692px solid #1E9898"}
            register={{ ...register("photo") }}
          />
        </AddPhotoWrapper>
      </TwoInputWrapper>
    </Wrapper>
  );
}

export default PostItems;
