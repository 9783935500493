import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: ${({ height }) => height ?? "51px"};
  cursor: pointer;
  box-shadow: 5px 2px 5px 0px #00000040;
  border-radius: 10.72px;
  background-color: #ffffff;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "#daf3dc80"};
  border-radius: 10.72px;
`;

const Text = styled.div`
  flex: 3;
  background-color: #ffffff;
  border-top-right-radius: 10.72px;
  border-bottom-right-radius: 10.72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  color: #105f84;
  font-family: "NotoSans-600";
`;

const OverlayCard = ({
  hasExpand = false,
  icon,
  iconText,
  text,
  onClick,
  iconBackgroundColor,
}) => {
  return (
    <Wrapper onClick={onClick}>
      <IconWrapper backgroundColor={iconBackgroundColor}>{icon}</IconWrapper>
      <Text>{text}</Text>
    </Wrapper>
  );
};

export default OverlayCard;
