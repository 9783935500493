import { createContext, useState } from "react";
import { Domains } from "../domain/Home/constant";

export const ZoneRouteContext = createContext();

export const ZoneRouteProvider = ({ children }) => {
  const [cityRoute, setCityRoute] = useState("tel-aviv");
  const [studentRoute, setStudentRoute] = useState("");
  const [loginRoute, setLoginRoute] = useState("/");
  const [homeZone, setHomeZone] = useState(Domains[0]);

  return (
    <ZoneRouteContext.Provider
      value={{
        cityRoute,
        setCityRoute,
        studentRoute,
        setStudentRoute,
        loginRoute,
        setLoginRoute,
        homeZone,
        setHomeZone,
      }}
    >
      {children}
    </ZoneRouteContext.Provider>
  );
};
