import GiveAndRecieveImage from "../../../../assets/images/Activities/Common/give-receive.png";
import VolunteerBoardImage from "../../../../assets/images/Activities/CommunityBoard/card-cover.png";
import NetworkingBoardImage from "../../../../assets/images/Activities/TogetherInTheCity/telaviv/Mock/cardSlider/tel82.jpg";

export const EcosystemZone = {
  Israel: "israel",
  USA: "usa",
  Europe: "europe",
};

export const VOLUNTEER_BOARD_IMAGES = [
  GiveAndRecieveImage,
  VolunteerBoardImage,
  NetworkingBoardImage,
];
