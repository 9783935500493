import React from "react";
import styled from "styled-components";
import RedHeartIcon from "../../../../../../../assets/icons/Activities/TogetherInTheCity/RedHeartIcon";
import LikedGreenHeartIcon2 from "../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/LikedGreenHeartIcon2";
import GreenHeartIcon2 from "../../../../../../../assets/icons/Activities/TogetherInTheCity/gderot/GreenHeartIcon2";
import useFetchUser from "../../../../../../../customHooks/useFetchUser";
import Dropdown from "../../../../../../../kit/Dropdown/Dropdown";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ height }) => height ?? "46px"};
  padding: 0px 13px 0 0;
  margin: 8px 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10.717px;
  background: #fff;
  box-shadow: 4px 4px 10px 0px #e8e8e8;
`;
const Text = styled.div`
  color: var(--preview-txt, #58656d);
  font-size: ${({ fontSize }) => fontSize ?? "12px"};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "0"};
`;
const ContentAndImageWrapper = styled.div`
  display: flex;
  gap: 30px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.rowToRender !== RenderRows.Empty ? "column" : "row"};
  align-items: ${(props) =>
    props.rowToRender !== RenderRows.Empty ? "" : "center"};
  justify-content: ${(props) =>
    props.rowToRender !== RenderRows.Empty ? "center" : ""};
`;
const LocationDateRow = styled.div`
  color: #9fa3a8;
  font-family: Assistant;
  font-size: ${({ fontSize }) => fontSize ?? "9px"};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const DateDivider = styled.span`
  margin: 0 5px;
`;
const View = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap ?? "0"};
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
`;

const RenderRows = {
  Location: 0,
  Name: 1,
  Empty: 2,
};

function Activity({
  image,
  text,
  action,
  location,
  date,
  liked,
  showLocation = true,
  showName,
  userId,
  heartColor,
  showOptions,
  optionsList,
  isBigDesign,
}) {
  const { user } = useFetchUser({ userId });

  const rowToRender = showLocation
    ? RenderRows.Location
    : showName
    ? RenderRows.Name
    : RenderRows.Empty;

  const renderOptions = () => {
    if (
      !showOptions ||
      !optionsList ||
      (optionsList && optionsList?.length < 1)
    )
      return null;
    return (
      <div
        style={{
          marginLeft: isBigDesign ? "0" : "5px",
          marginTop: isBigDesign ? "0" : "-15px",
        }}
      >
        <Dropdown
          wrapperPadding={isBigDesign ? "2" : undefined}
          options={optionsList}
          menuLeftOffset={"-75px"}
          fontSize={isBigDesign ? "22px" : undefined}
        />
      </div>
    );
  };

  return (
    <Wrapper height={isBigDesign ? "89px" : "46px"} onClick={action}>
      <ContentAndImageWrapper>
        <img
          src={image}
          style={{
            width: isBigDesign ? "111px" : "93px",
            height: isBigDesign ? "89px" : "46px",
            borderRadius: "10.717px",
            objectFit: "cover",
          }}
          alt=""
        />
        <ContentWrapper rowToRender={rowToRender}>
          <Text
            fontSize={isBigDesign ? "14px" : "12px"}
            marginBottom={isBigDesign ? "12px" : "0"}
          >
            {text}
          </Text>
          {rowToRender !== RenderRows.Empty && (
            <LocationDateRow fontSize={isBigDesign ? "12px" : "9px"}>
              <span>
                {(rowToRender === RenderRows.Location
                  ? location
                  : user?.name) ?? ""}
              </span>
              <DateDivider>|</DateDivider>
              <span>{date}</span>
            </LocationDateRow>
          )}
        </ContentWrapper>
      </ContentAndImageWrapper>

      {!liked && (
        <View
          flexDirection={isBigDesign ? "column-reverse" : "row"}
          gap={isBigDesign ? "22.5px" : "0"}
        >
          {heartColor === "green" ? (
            <GreenHeartIcon2
              width={isBigDesign ? 18 : undefined}
              height={isBigDesign ? 18 : undefined}
            />
          ) : (
            <GreenHeartIcon2
              width={isBigDesign ? 18 : undefined}
              height={isBigDesign ? 18 : undefined}
              color={"#000"}
            />
          )}
          {renderOptions()}
        </View>
      )}
      {liked && (
        <View
          flexDirection={isBigDesign ? "column-reverse" : "row"}
          gap={isBigDesign ? "22.5px" : "0"}
        >
          {heartColor === "green" ? (
            <LikedGreenHeartIcon2
              width={isBigDesign ? 18 : undefined}
              height={isBigDesign ? 18 : undefined}
            />
          ) : (
            <RedHeartIcon
              width={isBigDesign ? 18 : undefined}
              height={isBigDesign ? 18 : undefined}
              color={"#000"}
            />
          )}
          {renderOptions()}
        </View>
      )}
    </Wrapper>
  );
}

export default Activity;
