import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";

const Text = styled.div`
  color: ${(props) => props.color ?? "#1b5c6d"};
  text-decoration: ${(props) => props.textDecoration ?? "none"};
  font-size: 14px;
  font-weight: 700;
`;

const PreviewImage = styled.img`
  width: ${(props) => props.width ?? "150px"};
  height: ${(props) => props.height ?? "100px"};
  border-radius: ${(props) => props.borderRadius ?? "0"};
  object-fit: cover;
`;

const ImageCountOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  width: 300px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalImage = styled.img`
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin: 15px 5px;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: -10px;
  right: ${({ right }) => right ?? "-10px"};
  background: ${(props) => props.color ?? "rgba(0, 0, 0, 0.4)"};
  border: none;
  cursor: pointer;
  padding: 2.5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width ?? "15%"};
  height: 15%;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
`;

const FormPreviewImages = ({
  height,
  allowMultipleSelection,
  selectedFilesList,
  setSelectedFilesList,
  showImageIcon,
  hidePreview,
  selectedImages,
  setSelectedImages,
  removeIconWidth,
  removeIconRight,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleRemoveImage = (index) => {
    const newSelectedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(newSelectedImages);
    if (!allowMultipleSelection) return;
    setSelectedFilesList(selectedFilesList.filter((_, i) => i !== index));
  };

  const handleOverlayClick = () => {
    setShowModal(true);
  };

  return (
    !hidePreview &&
    selectedImages?.length > 0 && (
      <div
        style={{
          position: "relative",
          marginBottom: showImageIcon ? "0" : "15px",
          borderRadius: "5px",
        }}
      >
        <PreviewImage
          src={selectedImages[selectedImages.length - 1]}
          alt="Selected"
          width={showImageIcon ? "100%" : undefined}
          height={showImageIcon ? height : undefined}
        />
        {(!allowMultipleSelection || selectedImages.length === 1) && (
          <RemoveButton
            width={removeIconWidth}
            right={removeIconRight}
            color={showImageIcon ? "#1e9898" : undefined}
            onClick={() => handleRemoveImage(selectedImages.length - 1)}
          >
            <IoClose color="white" style={{ fontSize: "30px" }} />
          </RemoveButton>
        )}
        {allowMultipleSelection && (
          <>
            {selectedImages.length > 1 && (
              <ImageCountOverlay onClick={handleOverlayClick}>
                {selectedImages.length}
                {" Images"}
              </ImageCountOverlay>
            )}
            {showModal && (
              <>
                <ModalBackground onClick={() => setShowModal(false)} />
                <Modal>
                  {selectedImages.length > 0 ? (
                    selectedImages.map((image, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <ModalImage src={image} alt="Selected" />
                        <RemoveButton
                          color={showImageIcon ? "#1e9898" : undefined}
                          onClick={() => handleRemoveImage(index)}
                        >
                          <IoClose color="white" style={{ fontSize: "30px" }} />
                        </RemoveButton>
                      </div>
                    ))
                  ) : (
                    <Text>No image selected</Text>
                  )}
                </Modal>
              </>
            )}
          </>
        )}
      </div>
    )
  );
};

export default FormPreviewImages;
