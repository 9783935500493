import React, { useContext } from "react";
import styled from "styled-components";
import { getPhotoSrc } from "../../../Post/utils";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import DateFormatComponent from "./DateFormatComponent";
import { useNavigate } from "react-router-dom";
import { EmergencyOverlaysContext } from "../../../../Emergency/Contacts/EmergencyOverlaysContext";

const EventInformationContainer = styled.div`
  width: 213px;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: white;
  bottom: 31px;
  right: 26px;
  border-radius: 12.845px;
  background: #fff;
  box-shadow: 0px 2.23395px 15.63762px 0px rgba(0, 0, 0, 0.15);
`;
const EventTitle = styled.h3`
  margin: 0;
  color: var(--titles, #58656d);
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  letter-spacing: -0.27px;
  display: flex;
  justify-content: space-between;
`;
const EventImage = styled.img``;
const EventDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #58656d;
  font-family: Assistant;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
`;
const VisitBtn = styled.button`
  width: 98px;
  height: 30px;
  padding: 8px 12.5px;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  text-align: center;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.404px;
  border-radius: 21.222px;
  background: var(--donor-dark-green, #1c6662);
  border: none;
  display: block;
  margin: auto;
  cursor: pointer;
  text-decoration: none;
`;
const CloseIconWrapper = styled.div`
  cursor: pointer;
`;

const LocationDetails = ({
  location,
  reset,
  zone,
  buttonText = "Visit",
  onClick,
}) => {
  const navigate = useNavigate();
  const { setShowOverlay, setOverlayProps } = useContext(
    EmergencyOverlaysContext
  );

  return (
    <EventInformationContainer>
      <EventTitle>
        {location.title ?? location.message}
        <CloseIconWrapper onClick={reset}>
          <CloseIcon />
        </CloseIconWrapper>
      </EventTitle>
      {location.image && <EventImage src={getPhotoSrc(location.image)} />}
      <EventDateWrapper>
        <DateFormatComponent
          startDate={location.start_date}
          endDate={location.end_date}
        />
        {location.start_time && location.end_time && (
          <div>{`${location.start_time}-${location.end_time}`}</div>
        )}
      </EventDateWrapper>
      <VisitBtn
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          if (location.message) {
            setShowOverlay(true);
            setOverlayProps(location._id);
            return;
          }
          const navUrl = location.dataType
            ? `/activities/${zone}/card-details/${location?.dataType}/${location._id}/navBack`
            : location?.url ?? "";
          navigate(navUrl);
        }}
      >
        {buttonText}
      </VisitBtn>
    </EventInformationContainer>
  );
};

export default LocationDetails;
