import React from "react";

const TextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={26}
      fill="none"
      viewBox="0 0 30 26"
    >
      <g filter="url(#a)">
        <rect width={26.074} height={22} x={2} fill="#fff" rx={4.074} />
      </g>
      <g clipPath="url(#b)">
        <path
          fill="#FEFEFE"
          d="M19.212 15.262c.036.33.188.539.449.67.587.295 1.36-.054 1.353-.855-.008-.966 0-1.931-.003-2.897 0-.235-.013-.47-.05-.704-.165-1.027-.709-1.738-1.737-1.994-1.091-.273-2.172-.17-3.227.204a.706.706 0 0 0-.48.566c-.099.573.388 1.024.951.873.62-.167 1.243-.266 1.882-.137.587.118.978.69.85 1.236l-.184-.055a4.703 4.703 0 0 0-2.252-.122c-1.03.188-1.605.754-1.76 1.706-.15.926.308 1.783 1.171 2.128.674.269 1.367.272 2.055.039.373-.126.685-.352.982-.659Zm3.522-3.677V7.15c0-.175-.06-.41.03-.511.101-.117.341-.035.521-.037.431-.006.426-.002.433-.425.002-.133-.026-.179-.17-.177-.642.009-1.284.004-1.927.004-.483 0-.48 0-.456.487.004.083.025.113.11.11.224-.005.449.01.672-.005.16-.01.203.038.203.201-.005 3.08-.004 6.161-.005 9.241 0 .17.057.396-.029.495-.099.113-.33.034-.505.037-.449.005-.443.002-.447.446 0 .125.035.155.158.154.647-.006 1.296-.003 1.943-.003.466 0 .463 0 .453-.473-.002-.105-.037-.127-.133-.124-.224.006-.448-.008-.671.005-.146.008-.186-.035-.185-.183.006-1.602.004-3.204.004-4.806Z"
        />
        <path
          fill="#1F262C"
          d="M5.62 14.979c.107-.233.216-.464.32-.698.908-2.051 1.817-4.102 2.723-6.154.172-.389.406-.706.84-.815.69-.172 1.233.091 1.526.747.522 1.17 1.038 2.34 1.556 3.511.464 1.047.929 2.094 1.39 3.142.184.417.131.782-.142 1.06a.904.904 0 0 1-1.424-.184c-.249-.434-.413-.908-.607-1.368-.056-.133-.125-.177-.271-.176-1.137.006-2.274.005-3.41 0-.125 0-.187.035-.234.153-.172.424-.347.848-.538 1.265-.251.548-.867.739-1.34.432-.196-.127-.287-.33-.387-.526v-.389H5.62Zm3.043-2.623c.03.012.04.02.05.02.73.002 1.46.002 2.19.005.12 0 .1-.051.067-.13-.138-.324-.272-.65-.408-.974l-.724-1.719-1.174 2.797ZM19.212 15.262c-.297.307-.61.532-.983.659-.688.233-1.38.23-2.054-.04-.863-.344-1.322-1.201-1.171-2.128.155-.951.73-1.517 1.76-1.705a4.711 4.711 0 0 1 2.251.122l.185.054c.128-.546-.263-1.117-.85-1.236-.64-.13-1.262-.029-1.882.138-.564.151-1.05-.3-.952-.874a.707.707 0 0 1 .48-.565c1.054-.375 2.136-.477 3.228-.204 1.028.256 1.571.967 1.736 1.993.037.234.049.47.05.704.004.966-.004 1.932.004 2.898.006.8-.767 1.15-1.354.855-.26-.131-.413-.34-.45-.67l.002-.001Zm-1.422-.454c.129-.014.259-.021.385-.044.74-.135 1.103-.584 1.08-1.327-.002-.06.003-.114-.07-.141-.612-.23-1.233-.315-1.861-.085-.381.14-.56.45-.536.86.02.33.247.583.617.681.125.034.253.055.384.057h.001Z"
        />
        <path
          fill="#343F49"
          d="M22.734 11.585c0 1.602.002 3.204-.003 4.806 0 .149.038.192.184.183.223-.012.448 0 .672-.005.095-.003.13.02.132.124.01.474.014.474-.452.474-.648 0-1.296-.004-1.944.002-.122.002-.158-.028-.157-.154.003-.444-.002-.44.447-.446.174-.002.405.077.505-.037.085-.099.029-.324.029-.494 0-3.081 0-6.162.004-9.242 0-.163-.042-.21-.203-.2-.223.014-.448 0-.671.005-.086.002-.107-.027-.11-.11-.025-.488-.027-.488.456-.488.642 0 1.284.006 1.926-.004.145-.002.173.044.17.177-.007.423-.001.42-.433.425-.18.002-.42-.08-.522.037-.089.102-.029.336-.029.512v4.435h-.001Z"
        />
        <path
          fill="#FEFEFE"
          d="m8.663 12.356 1.175-2.797.724 1.719c.136.324.27.65.407.973.034.08.053.131-.067.13-.73-.003-1.459-.003-2.189-.004-.01 0-.021-.008-.05-.02v-.001ZM17.79 14.808a1.584 1.584 0 0 1-.384-.056c-.37-.099-.597-.352-.617-.683-.026-.41.154-.719.535-.859.629-.23 1.25-.145 1.86.085.075.028.07.081.071.14.024.744-.34 1.193-1.079 1.329-.127.023-.257.03-.385.043v.001Z"
        />
      </g>
      <defs>
        <clipPath id="b">
          <path fill="#fff" d="M5.62 6h18.101v11.17h-18.1z" />
        </clipPath>
        <filter
          id="a"
          width={29.333}
          height={25.259}
          x={0.37}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1.63} />
          <feGaussianBlur stdDeviation={0.815} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_431_499"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_431_499"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default TextIcon;
