import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import CoverImage from "./CoverImage";

const ImageContainer = styled.div`
  position: relative;
  img {
    width: 100%;
    height: 226px;
    object-fit: cover;
    border-radius: ${(props) => props.borderRadius ?? "20px 20px 0px 0px"};
    @media (min-width: 768px) {
      ${(props) => (props.noHeightStyles ? "" : "height: 449px;")}
    }
  }
`;

const OverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  left: 50%;
  top: 20px;
  transform: translate(-50%, -50%);
  border-radius: ${(props) => props.borderRadius ?? "20px 20px 0px 0px"};
  background: rgba(255, 255, 255, 0.7);
  color: #316788;
  font-family: "Assistant";
  font-size: 15px;
  font-size: 18px;
  font-weight: 800;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyArrow = () => null;

const InfiniteSlider = ({ images, noHeightStyles, borderRadius }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <EmptyArrow />,
    prevArrow: <EmptyArrow />,
  };

  if (images.length === 1) {
    return (
      <CoverImage
        image={images[0]?.src ?? images[0]}
        text={images[0]?.text}
        borderRadius={borderRadius}
      />
    );
  }

  if (images.length > 1) {
    return (
      <Slider {...settings}>
        {images.map((img, index) => (
          <ImageContainer
            borderRadius={borderRadius}
            key={index}
            noHeightStyles={noHeightStyles}
          >
            <img src={img?.src ?? img} alt={`Slide ${index}`} />
            {(img?.hebDescriptions || img?.enDescriptions) && (
              <OverLay borderRadius={borderRadius}>
                <p>
                  {img?.hebDescriptions
                    ? img?.hebDescriptions
                    : img?.enDescriptions}
                </p>
              </OverLay>
            )}
          </ImageContainer>
        ))}
      </Slider>
    );
  }
};

export default InfiniteSlider;
