import { useQuery } from "@tanstack/react-query";
import { fetchById } from "./api";

const useGetEcosystemById = (id) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["ecosystem", id],
    queryFn: () => fetchById(id),
  });

  return { data, isLoading, refetchEcosystem: refetch };
};

export default useGetEcosystemById;
