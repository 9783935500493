import React from "react";
import { styled } from "styled-components";
import Maps from "../../assets/images/Location/Maps.svg";
import MapsSmall from "../../assets/images/Location/googleMapSmall.png";

const Image = styled.img`
  width: 100%;
  height: ${(props) => props.height ?? "100%"};
  object-fit: contain;
  cursor: pointer;
`;

const GoogleMapsButton = ({
  latitude,
  longitude,
  width,
  height,
  isSmallIcon,
}) => {
  return (
    <a
      style={{
        width: width ?? "65px",
        height: height ?? "auto",
        backgroundColor: "#EFEFEF",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
      }}
      href={`https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`}
    >
      <Image src={isSmallIcon ? MapsSmall : Maps} height={height ?? "59px"} />
    </a>
  );
};

export default GoogleMapsButton;
