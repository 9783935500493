import React from "react";

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <g fill="#EF7300">
        <path d="M.389 1.947c.02-.088.041-.178.063-.266A2.227 2.227 0 012.596 0h9.833c1.201 0 2.199.995 2.201 2.193.003 1.493.002 2.986.001 4.48 0 .341-.23.585-.55.587-.329.002-.561-.242-.561-.59l-.001-4.396c0-.55-.32-.983-.825-1.118a1.365 1.365 0 00-.343-.043c-3.227-.002-6.453-.003-9.68-.001-.691 0-1.17.48-1.17 1.174a7578.39 7578.39 0 000 7.136c0 .7.48 1.176 1.181 1.177.876.001 1.753.004 2.629-.002a.645.645 0 01.55.267c.49.628.985 1.253 1.478 1.879.145.184.189.183.333 0 .494-.632.99-1.263 1.482-1.895a.606.606 0 01.514-.25c.88.004 1.762.002 2.642.001.72 0 1.17-.43 1.21-1.15a.55.55 0 01.562-.519.55.55 0 01.548.533c.032 1.085-.804 2.07-1.885 2.221-.114.017-.23.026-.346.026-.779.002-1.557.004-2.336-.002a.295.295 0 00-.266.128c-.425.55-.852 1.1-1.285 1.643-.556.698-1.472.691-2.023-.009-.438-.557-.879-1.112-1.315-1.671-.052-.067-.105-.088-.186-.088-.784.002-1.567.001-2.35 0A2.219 2.219 0 01.413 9.827C.41 9.806.397 9.786.388 9.765V1.947z"></path>
        <path d="M8.066 6.977V8.45c0 .348-.234.59-.563.588-.32-.002-.548-.246-.548-.588V5.503c0-.34.232-.581.555-.581.323 0 .555.242.556.581v1.474z"></path>
        <path d="M8.066 3.552a.55.55 0 01-.549.56.55.55 0 01-.563-.56A.549.549 0 017.51 3a.55.55 0 01.556.552z"></path>
      </g>
    </svg>
  );
};

export default EditIcon;
