import React from "react";
import { styled } from "styled-components";
import Waze from "../../assets/images/Location/WazeLogoNoBg.svg";

const Image = styled.img`
  width: 100%;
  height: ${(props) => props.height ?? "100%"};
  object-fit: contain;
  cursor: pointer;
`;

const WazeButton = ({
  latitude,
  longitude,
  width,
  height,
  imageHeight,
  fontSize,
}) => {
  return (
    <a
      style={{
        backgroundColor: "rgba(49, 198, 247, 1)",
        borderRadius: "3.65px",
        textDecoration: "none",
        fontStyle: "normal",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: width ?? "64px",
        height: height ?? "59px",
      }}
      href={`https://www.waze.com/ul?ll=${latitude}%2C${longitude}&navigate=yes&zoom=17`}
    >
      <Image src={Waze} height={imageHeight ?? "37px"} />
      <span
        style={{
          fontWeight: "700",
          fontSize: fontSize ?? "12px",
          fontFamily: "Assistant",
          color: "#fff",
        }}
      >
        Waze
      </span>
    </a>
  );
};

export default WazeButton;
