import React from "react";
import styled from "styled-components";

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RadioInput = styled.input.attrs({ type: "radio" })`
  display: none;
`;

const RadioStyled = styled.div`
  width: ${(props) => props.outCircleWidth ?? "25px"};
  height: ${(props) => props.outCircleWidth ?? "25px"};
  border-radius: 50%;
  border: none;
  background: #fff;
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;
  cursor: pointer;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &:after {
    content: ${(props) => (props.checked ? '""' : "none")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: ${(props) => props.innerCircleWidth ?? "13px"};
    height: ${(props) => props.innerCircleWidth ?? "13px"};
    background-color: ${(props) => props.color ?? "#58656D"};
    border-radius: 50%;
  }
`;

const CustomRadioButton = ({
  checked,
  onChange,
  label,
  name,
  outCircleWidth,
  innerCircleWidth,
}) => {
  return (
    <RadioContainer>
      <RadioInput checked={checked} onChange={onChange} name={name} />
      <RadioStyled
        checked={checked}
        outCircleWidth={outCircleWidth}
        innerCircleWidth={innerCircleWidth}
      />
      {label && <span>{label}</span>}
    </RadioContainer>
  );
};

export default CustomRadioButton;
