import { useContext, useMemo } from "react";
import { UserContext } from "../../../store/userContext";
import { isUserAuthorizedForAction } from "../../Auth/login/utils";

const useUserHasAccessToEcosystem = (ecosystem) => {
  const { isAuthenticated, currentUser } = useContext(UserContext);

  const userHasAccess = useMemo(() => {
    // No Authentication needed (Public ecosystem)
    if (
      !ecosystem?.parent ||
      ecosystem?.parent === "telAvivCity" ||
      ecosystem?.parent === "659e2467e6ad34f2f223b4f4"
    ) {
      return true;
    }

    // Authentication needed (Private ecosystem)
    if (!isAuthenticated) {
      return false;
    }

    // User has access to ecosystem
    if (
      isUserAuthorizedForAction(currentUser, ecosystem?.createdBy, false) ||
      currentUser?.teamMember?.includes?.(ecosystem?._id)
    ) {
      return true;
    }

    // User does not have access to the ecosystem
    return false;
  }, [
    currentUser,
    ecosystem?._id,
    ecosystem?.createdBy,
    ecosystem?.parent,
    isAuthenticated,
  ]);

  return userHasAccess;
};

export default useUserHasAccessToEcosystem;
