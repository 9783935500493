export const ROUTES = {
  Taglit: "taglit",
  UnitedRescue: "united-rescue",
  JewishAgency: "jewish-agency",
  KKL: "kkl",
  BlueWhite: "blue-white",
  Brothers_In_Arms: "brothers-in-arms",
  KaplanForce: "kaplan-force",
  Noga: "noga",
  VET: "vet",
  Pets_Friends: "pets-friends",
  EmergencyAndRescue: "emergency-and-rescue",
};

export const PUBLIC_ORGANIZATION = [
  // {
  //   name: "Taglit - תגלית",
  //   route: `/public/activities/organization/${ROUTES.Taglit}`,
  //   value: ROUTES.Taglit,
  // },
  {
    name: "איחוד והצלה",
    route: `/public/activities/organization/${ROUTES.UnitedRescue}`,
    value: ROUTES.UnitedRescue,
  },
  {
    name: "Vets In Clinics - וטרינרים",
    route: `/public/activities/organization/${ROUTES.VET}`,
    value: ROUTES.VET,
  },
  // {
  //   name: "הסוכנות היהודית",
  //   route: `/public/activities/organization/${ROUTES.JewishAgency}`,
  //   value: ROUTES.JewishAgency,
  // },
  {
    name: "קק״ל",
    route: `/public/activities/organization/${ROUTES.KKL}`,
    value: ROUTES.KKL,
  },
  {
    name: "Pets Friends",
    route: `/public/activities/organization/${ROUTES.Pets_Friends}`,
    value: ROUTES.Pets_Friends,
  },
  {
    name: "Emergency & Rescue",
    route: `/public/activities/organization/${ROUTES.EmergencyAndRescue}`,
    value: ROUTES.EmergencyAndRescue,
  },
  {
    name: "חקלאות כחול לבן",
    route: `/public/activities/organization/${ROUTES.BlueWhite}`,
    value: ROUTES.BlueWhite,
  },
  {
    name: "אחים לנשק",
    route: `/public/activities/organization/${ROUTES.Brothers_In_Arms}`,
    value: ROUTES.Brothers_In_Arms,
  },
  {
    name: "כוח קפלן",
    route: `/public/activities/organization/${ROUTES.KaplanForce}`,
    value: ROUTES.KaplanForce,
  },
];
