import React from "react";

const UserIcon = ({ color = "#003F5D", size = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 19"
    >
      <path
        stroke={color}
        strokeMiterlimit={10}
        strokeWidth={1.267}
        d="M10 11.875a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.267}
        d="M2.8 16.032a8.313 8.313 0 0 1 14.4 0"
      />
    </svg>
  );
};

export default UserIcon;
