import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteContact } from "../api";

function useDeleteContact() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (contactId) => deleteContact(contactId),
    onSuccess: () => {
      queryClient.invalidateQueries("contacts");
    },
  });

  return { deleteContact: mutateAsync, isLoading };
}

export default useDeleteContact;
