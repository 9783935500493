export const OrangeMapIcon = () => (
  <svg
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.21473 12.5825C0.961536 11.6427 0.645338 10.7135 0.478917 9.75822C0.38263 9.2062 0.48367 8.60423 0.577579 8.03794C0.85574 6.34859 1.53569 4.82818 2.6091 3.4886C2.7149 3.35654 2.8207 3.22449 2.92649 3.09125C3.65637 2.48808 4.38624 1.8861 5.11611 1.28293C6.43678 0.531052 7.83472 0.109905 9.37055 0.0194896C11.9715 -0.132789 14.2312 0.606002 16.2164 2.28107C17.5466 3.40294 18.475 4.79249 19.036 6.41878C19.7172 8.39008 19.7148 10.3661 18.8874 12.3113C18.828 12.4505 18.7983 12.6027 18.7543 12.7491C17.8105 14.6811 16.6336 16.468 15.3439 18.1835C15.1275 18.4714 14.9135 18.7605 14.6984 19.0484C14.5593 19.2174 14.4226 19.3887 14.2788 19.5529C13.0794 20.9269 11.8883 22.3093 10.6698 23.668C10.2704 24.1129 9.80205 24.1022 9.38481 23.6727C8.81422 23.085 8.24483 22.4937 7.72773 21.8608C6.40588 20.2417 5.11255 18.5987 3.80733 16.9641C3.41862 16.3705 3.00614 15.7899 2.64833 15.1784C2.14907 14.3254 1.69022 13.4486 1.21355 12.5813L1.21473 12.5825ZM9.99343 12.6789C12.2877 12.7479 14.2693 10.8729 14.2823 8.53285C14.2978 5.93458 12.3566 4.17267 10.0136 4.17385C7.58628 4.17623 5.8448 6.09995 5.76635 8.2271C5.66887 10.8718 7.80975 12.7288 9.99343 12.6789Z"
      fill="#EF7300"
    />
    <path
      d="M9.99634 12.6769C7.81266 12.7269 5.67296 10.8698 5.76925 8.22512C5.8477 6.09916 7.58799 4.17426 10.0165 4.17188C12.3595 4.1695 14.3007 5.9326 14.2852 8.53087C14.2722 10.871 12.2894 12.7459 9.99634 12.6769Z"
      fill="#FFDAB8"
    />
  </svg>
);
