import styled from "styled-components";
import { TogetherIcon } from "../../assets/icons/Logo";

const LogoIconWrapper = styled.div`
  height: 72px;
  display: flex;
  justify-content: center;
`;
const LogoWrapper = styled.div`
  width: fit-content;
  position: relative;
`;
const IconTitle = styled.div`
  position: absolute;
  top: ${(props) => props.top ?? "32px"};
  left: ${(props) => props.left ?? "100px"};
  color: #7fb383;
  text-align: left;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  max-width: 180px;
`;

const LogoWithTitle = ({ iconWidth, iconHeight, titlePos, title }) => {
  return (
    <LogoIconWrapper>
      <LogoWrapper>
        <TogetherIcon width={iconWidth ?? "240"} height={iconHeight ?? "60"} />
        <IconTitle top={titlePos?.top} left={titlePos?.left}>
          {title}
        </IconTitle>
      </LogoWrapper>
    </LogoIconWrapper>
  );
};

export default LogoWithTitle;
