import React from "react";
import styled from "styled-components";
import CloseIcon from "../../../../../../../assets/icons/CloseIcon";
import { LinearCard, LinearCards } from "../../../../../../../kit/Card";

const CardWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;
const CardContainer = styled.div`
  width: 94%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  border-radius: 40px;
  background: #fff;
  box-shadow: 11.91792px 11.91792px 29.79479px 5.95896px rgba(0, 0, 0, 0.2);
  border: none;
  box-sizing: border-box;
  padding: 0 0 33px 0;
`;
const AppleAndTitlesWrapper = styled.div`
  width: 94%;
  margin: 13px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitlesContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HebTitle = styled.h2`
  padding: 0;
  margin: 0;
  color: ${(props) => props.color ?? "#316788"};
  text-align: center;
  font-family: Assistant;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CloseIconWrapper = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
`;

const Card = (props) => {
  const {
    enTitle,
    hebTitle,
    color,
    questions,
    showCloseIcon = false,
    closeModal,
  } = props;
  return (
    <CardWrapper>
      <CardContainer>
        {showCloseIcon && (
          <CloseIconWrapper onClick={closeModal}>
            <CloseIcon
              width={"18.855px"}
              height={"20.427px"}
              color={"#316788"}
            />
          </CloseIconWrapper>
        )}
        <AppleAndTitlesWrapper>
          <TitlesContainer>
            <HebTitle color={color}>{enTitle}</HebTitle>
            <HebTitle color={color}>{hebTitle}</HebTitle>
          </TitlesContainer>
        </AppleAndTitlesWrapper>
        <CardsWrapper>
          {questions.map((q) => (
            <LinearCard
              title={q?.title}
              titleColor={"#46884B"}
              linkAddress={q?.linkAddress}
              titlelink={q?.titlelink}
              image={q?.image}
              to={q?.to}
              showHeart={false}
            />
          ))}
        </CardsWrapper>
      </CardContainer>
    </CardWrapper>
  );
};

export default Card;
