import styled from "styled-components";
import Item from "../Common/SearchPart/components/Item";
import { useEffect, useRef } from "react";

const Wrapper = styled.div`
  border-radius: ${(props) => props.borderRadius ?? "20px"};
  border: ${(props) => (props.border ? props.border : "2px solid #c9d7e0")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
  background: ${(props) => props.background ?? "#fff"};
  max-height: 311px;
  overflow: auto;
  width: ${(props) => props.width ?? "auto"};
`;
const Input = styled.input`
  border-radius: ${(props) => props.borderRadius ?? "20px"};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
  background: ${(props) => props.background ?? "#fff"};
  height: ${(props) => (props.innerHeight ? props.innerHeight : "30px")};
  width: 100%;
  margin: 1px;
  border: none;
  border-bottom: ${(props) => props.borderBottom};
  color: ${(props) =>
    props.textColor ?? props.placeHolderColor ?? "rgba(103, 103, 103, 0.5)"};

  direction: ${(props) => (props.lang === "heb" ? "rtl" : "ltr")};
  cursor: pointer;
  padding: ${(props) =>
    props.placeHolderPadding ? props.placeHolderPadding : "auto"};
  font-size: ${(props) => props.fontSize ?? "19px"};
  &::placeholder {
    color: ${(props) =>
      props.placeHolderColor
        ? props.placeHolderColor
        : "rgba(103, 103, 103, 0.5)"};
    font-size: ${(props) => props.fontSize ?? "19px"};
    font-weight: 600;
    font-family: Assistant;
    padding-left: ${(props) =>
      props.placeholderPaddingLeft ? props.placeholderPaddingLeft : "auto"};
    padding-right: ${(props) =>
      props.placeholderPaddingRight ? props.placeholderPaddingRight : "auto"};
  }
`;
const PrevIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: ${(props) => (props.lang === "heb" ? "auto" : "9px")};
  right: ${(props) => (props.lang === "heb" ? "9px" : "auto")};
  height: 100%;
`;
const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: ${(props) => (props.lang === "heb" ? "auto" : "12px")};
  left: ${(props) => (props.lang === "heb" ? "12px" : "auto")};
  height: 100%;
  width: ${(props) => (props.iconWidth ? props.iconWidth : "14px")};
`;
const InputAndIconWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: ${(props) => props.borderRadius ?? "20px"};
`;
const ImageWrapper = styled.div`
  width: 60px;
  height: 30px;
  margin: 0 20px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
function SearchInput(props) {
  const {
    autoFocus,
    items,
    width,
    height,
    type,
    placeholder,
    icon,
    iconWidth,
    onClick,
    name,
    onChange,
    searchItem,
    link,
    lang,
    placeHolderColor,
    placeHolderPadding,
    border,
    boxShadow,
    background,
    borderRadius,
    fontSize,
    textColor,
    innerHeight = height,
    prevIcon,
    placeholderPaddingLeft,
    placeholderPaddingRight,
    borderBottom,
    image,
    required,
    defaultValue,
  } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Wrapper
      onClick={onClick}
      height={height}
      border={border}
      boxShadow={boxShadow}
      background={background}
      borderRadius={borderRadius}
      width={width}
    >
      <InputAndIconWrapper borderRadius={borderRadius}>
        {prevIcon && (
          <PrevIconWrapper lang={lang} iconWidth={iconWidth}>
            {prevIcon}
          </PrevIconWrapper>
        )}

        <Input
          ref={inputRef}
          name={name}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          placeHolderColor={placeHolderColor}
          lang={lang}
          innerHeight={innerHeight}
          placeHolderPadding={placeHolderPadding}
          placeholderPaddingLeft={placeholderPaddingLeft}
          placeholderPaddingRight={placeholderPaddingRight}
          fontSize={fontSize}
          textColor={textColor}
          borderBottom={borderBottom}
          borderRadius={borderRadius}
          required={required}
          value={defaultValue}
        />
        {!image && (
          <IconWrapper lang={lang} iconWidth={iconWidth}>
            {icon}
          </IconWrapper>
        )}
        {image && (
          <ImageWrapper>
            <Image src={image} />
          </ImageWrapper>
        )}
      </InputAndIconWrapper>
      {items?.map((i) => (
        <Item
          image={i?.image}
          text={i?.title}
          date={i?.date}
          searchItem={searchItem}
          link={`${link}/${i?.task_type}/${i?._id}`}
        />
      ))}
    </Wrapper>
  );
}

SearchInput.defaultProps = {
  type: "text",
};

export default SearchInput;
