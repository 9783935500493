import { useContext, useState } from "react";

import { Switch } from "@mui/material";

import { FaCog } from "react-icons/fa";

import styled from "styled-components";
import { ZoneRouteContext } from "../../../store/zoneRouteContext";
import BiggerThanIcon from "../../../assets/icons/Home/BiggerThanIcon";
import { Link, useNavigate } from "react-router-dom";
import { Domains } from "../constant";
import ButtonWithDropDown from "../../../kit/Button/ButtonWithDropDown";
import emergencyIcon from "../shorcutIcons/Mock/MockIcons/emergency.svg";
import { getRouteByValue } from "../utils";

import useMediaQueryConstants from "../../../customHooks/useMediaQueryConstants";
import useCurrentUser from "../../../customHooks/useCurrentUser";
import { useGetUserDomains } from "../../../customHooks/useGetUserDomains";
import MuiButton from "../../../kit/Button/MuiButton";
import { Box } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { MAIN_ROUTES } from "../../Common/Layout/Main/constants";
import { SettingsIcon } from "../../../assets/icons/Profile";

const Wrapper = styled.div`
  margin: auto 11px;
  margin-top: 15px;
  border: 2px solid #4b778d;
  border-radius: 21.9px;
  position: relative;
  min-height: ${({ isWideScreen }) => (isWideScreen ? "92%" : "auto")};
`;
const Row = styled.div`
  margin-top: 10px;
  display: flex;
`;
const LeftSide = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "100%" : "45%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: start;
  margin-left: 10px;
`;
const StyledImage = styled.img`
  width: 95px;
  height: 95px;
  text-align: center;
  border-radius: 21.9016px;
`;

const MoreWrapper = styled.div`
  margin-top: 5px;
  margin-right: 70px;
`;
const More = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #4b778d;
  font-family: "Assistant";
`;

const RightSide = styled.div`
  width: 67%;
  margin-top: -8px;
`;

const InfoWrapper = styled.div`
  margin-left: ${({ isWideScreen }) => (isWideScreen ? "0px" : "10px")};
  margin-top: 25px;
`;

const Name = styled.div`
  color: #4b778d;
  font-family: "Assistant";
  font-size: 19px;
  font-weight: 700;
  line-height: 16.426px; /* 86.454% */
`;

const City = styled.div`
  color: #7fb383;
  font-family: "Assistant";
  font-size: 20px;
  font-weight: 600;
  line-height: 23.89px; /* 119.452% */
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ThirdLine = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 23.89px;
  color: #4b778d;
`;

const LinksTitle = styled.h3`
  margin: 10px auto;
  color: #4b778d;
  text-align: center;
  font-family: "Assistant";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.404px;
`;
const ButtonWrapper = styled.div`
  width: 90%;
  padding: 5px 0 15px;
  margin: auto;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50.5px;
  height: 26.5px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 13.5px 16px 16px 13.5px;
  cursor: pointer;
`;
const IconImage = styled.img``;
const IconAndLInkWrapper = styled.div`
  direction: rtl;
  display: flex;
  justify-content: space-evenly;
  margin-top: ${({ isWideScreen }) => (isWideScreen ? "15px" : "0")};
`;
const LinkButton = styled(Link)`
  color: #7fb383;
  text-shadow: 0px 0.9374998807907104px 0.9374998807907104px rgba(0, 0, 0, 0.12);
  font-family: Assistant;
  font-size: 13.143px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.394px;
  border-radius: 14.062px;
  border: 1.438px solid #7fb383;
  padding: 5px 16px;
  text-decoration: none;
  box-shadow: 0px 1.875px 4.6875px 0px rgba(66, 132, 128, 0.2);
`;
const IconAndLinkWrapper = styled.div`
  display: ${({ isWideScreen }) => (isWideScreen ? "block " : "flex")};
  justify-content: space-between;
  gap: 15px;
  position: absolute;
  top: 11px;
  right: 15px;
  direction: rtl;
`;

const ToggleContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px;
`;

// Toggle button container
const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 105px;
  height: 26.54px;
  border: 1.5px solid
    ${({ isActive, activeColor, inactiveColor }) =>
      isActive ? activeColor : inactiveColor};
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  transition: border-color 0.3s ease;
`;

const ToggleLabel = styled.span`
  font-family: "Assistant";
  font-size: 17px;
  font-weight: 600;
  color: #4b778d;
  transition: color 0.3s ease;
`;

const ToggleCircle = styled.div`
  width: 33.71px;
  height: 17.97px;
  background-color: ${({ isActive, color }) =>
    isActive ? color : "transparent"};
  border-radius: 10.93px 12.94px 12.94px 10.93px;
  right: 5px;
  border: 1px solid ${({ color }) => color};
  transition: background-color 0.3s ease;
`;
const SettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1.5px solid #4b778d;
  cursor: pointer;
  gap: 5px;
  font-family: "Assistant";
  font-size: 17px;
  font-weight: 600;
  color: #4b778d;
  height: 26.54px;
  width: 105px;
`;
function UserCard({ isAbstractEmergency, hideDropdown }) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const navigate = useNavigate();
  const { homeZone, setHomeZone } = useContext(ZoneRouteContext);
  // State for toggling sound and status

  const [soundEnabled, setSoundEnabled] = useState(true);

  const [statusEnabled, setStatusEnabled] = useState(true);

  const { currentUser } = useCurrentUser();
  const userDomains = useGetUserDomains();

  return (
    <Wrapper isWideScreen={isWideScreen || isMediumScreen}>
      <ToggleContainer>
        <ToggleWrapper
          isActive={soundEnabled}
          activeColor="#003f5d"
          inactiveColor="#4b778d"
          onClick={() => setSoundEnabled(!soundEnabled)}
        >
          <ToggleLabel>Sound</ToggleLabel>
          <ToggleCircle isActive={soundEnabled} color="#003f5d" />
        </ToggleWrapper>

        {/* Status Toggle */}
        <ToggleWrapper
          isActive={statusEnabled}
          activeColor="#7fb383"
          inactiveColor="#4b778d"
          onClick={() => setStatusEnabled(!statusEnabled)}
        >
          <ToggleLabel>Status</ToggleLabel>
          <ToggleCircle isActive={statusEnabled} color="#7fb383" />
        </ToggleWrapper>
        <SettingsButton onClick={() => navigate("/Profile")}>
          <span>Settings</span>
          <SettingsIcon color={"#4B778D"} size={25} />
        </SettingsButton>
      </ToggleContainer>
      <Row>
        <LeftSide isWideScreen={isWideScreen || isMediumScreen}>
          <StyledImage src={currentUser.image} alt="" />
          {(isWideScreen || isMediumScreen) && (
            <InfoWrapper isWideScreen={isWideScreen || isMediumScreen}>
              <Name>{currentUser.displayName}</Name>
              <City>Together In Tel Aviv</City>
            </InfoWrapper>
          )}
          <MoreWrapper>
            {/* <More to={"/profile"}>More</More> */}
            <BiggerThanIcon />
          </MoreWrapper>
        </LeftSide>
        {!(isWideScreen || isMediumScreen) && (
          <RightSide>
            <InfoWrapper isWideScreen={isWideScreen || isMediumScreen}>
              <Name>{currentUser.displayName}</Name>
              <City>
                {isAbstractEmergency
                  ? "Together In Emergency"
                  : "Together In Tel Aviv"}
              </City>
              {isAbstractEmergency && (
                <ThirdLine>Team Member - Paramedic</ThirdLine>
              )}
            </InfoWrapper>
          </RightSide>
        )}
      </Row>

      {!hideDropdown && (
        <>
          {isAbstractEmergency ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 0 15px",
              }}
            >
              <MuiButton
                variant="outlined"
                endIcon={<FaAngleDown />}
                sx={{
                  fontFamily: "Assistant",
                  borderRadius: "12px",
                  width: "100%",
                  height: 28,
                  textTransform: "none",
                  backgroundColor: "#CEDCEF",
                  color: "#337A37",
                  fontSize: 18,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  boxSizing: "border-box",
                  margin: "0 30px",
                  "&:hover": {
                    backgroundColor: "#CEDCEF",
                    textDecoration: "underline",
                  },
                }}
              >
                {"United Hatzalah  -  איחוד והצלה"}
              </MuiButton>
            </Box>
          ) : (
            <ButtonWrapper>
              <ButtonWithDropDown
                activeLink={homeZone?.name}
                links={userDomains ?? Domains}
                color={"#4B778D"}
                activeLinkColor={"#7FB383"}
                fontSizeActiveLink="21.472px"
                border="none"
                iconColor="#7FB383"
                textAlign="left"
                height="34px"
                borderRadius={"30px"}
                searchFormheight={"30px"}
                searchFormFontSize={"15px"}
                iconHeight={"20px"}
                iconWidth={"20px"}
                searchFormColor={"gray"}
                backgroundColor={"#fff"}
                fontWeightActiveLink={600}
                activeLinkMargin={"0 0 10px 0;"}
                showUnderline={true}
                isRoutes={false}
                setState={(value) =>
                  setHomeZone(getRouteByValue(value, userDomains))
                }
              />
            </ButtonWrapper>
          )}
        </>
      )}
      <IconAndLinkWrapper isWideScreen={isWideScreen || isMediumScreen}>
        <IconAndLInkWrapper
          isWideScreen={isWideScreen || isMediumScreen}
        ></IconAndLInkWrapper>
      </IconAndLinkWrapper>
    </Wrapper>
  );
}

export default UserCard;
