import { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import Header from "../../Common/Layout/Header";
import Form from "./Form/Container";
import AddPost, { PostButtonStyles } from "./Form/AddPost";
import PostItems from "./Form/PostItems";
import Post from "../Post/components/Post";
import WideCommunityBoard from "../../../assets/images/Activities/CommunityBoard/community-board-wide.png";
import C0CommunityBoard from "../../../assets/images/Activities/CommunityBoard/c0CommunityBoard.png";
import TLVNetworkImage from "../../../assets/images/Activities/CommunityBoard/TLVNetwork.PNG";
import TLVNetworkHebrewImage from "../../../assets/images/Activities/CommunityBoard/TLVNetwork-hebrew.png";
import TLVNetworkBlankImage from "../../../assets/images/Activities/CommunityBoard/TLVNetwork-blank.png";
import GiveReceiveImage from "../../../assets/images/Activities/CommunityBoard/TLV-GiveReceive.png";
import { deletePostItem } from "../Post/api";
import Card from "../Zone/Common/Cards/Linear/Common/Card";
import { PostType } from "./api";
import GiveReceivePopUp from "./GiveReceivePopUp";
import { CommunitySections } from "./Board";
import { UserContext } from "../../../store/userContext";
import { LanguageContext } from "../../../store/languageContext";
import { LANGUAGES } from "../../Common/Layout/Header/constant";
import { getLogoByZone } from "../../Common/utils/logo";
import useGetEcosystemById from "../View/useGetEcosystemById";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TogetherIcon } from "../../../assets/icons/Logo";
import SharePost from "../Post/components/SharePost";
import ReturnArrow from "../../Common/Layout/ReturnArrow/ReturnArrow";
import useHideBack from "../../../customHooks/useHideBack";
import { getZoneUrl } from "../Zone/Common/CardDetailsPage/urlUtils";
import BasicBoard from "./Boards/BasicBoard";
import { CUSTOM_TITLE_ZONES, getCategoriesByZone } from "./constant";
import { isUserAuthorizedForAction } from "../../Auth/login/utils";
import { ZoneRouteContext } from "../../../store/zoneRouteContext";
import LoginAccessText from "../../../kit/LoginAccessText/LoginAccessText";
import CommunityBoardFormContent from "./Form/CommunityBoardFormContent";
import BoardFormV2 from "./Form/BoardFormV2";
import { ThreeDotsIcon } from "../../../assets/icons";
import { MainOrgZone } from "../../../customHooks/useFetchEmergencyEvents";

const Wrapper = styled.div`
  padding: 10px 0 0 0;
  text-align: center;
  min-height: calc(100vh - 90px);
`;

const ShareMask = styled.img`
  position: absolute;
  background: #00000066;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px 9px 0 0;
  z-index: 9;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: fit-content;
`;
const Image = styled.img`
  margin-top: 20px;
  width: 100%;
  max-height: 300px;
  object-fit: contain;
`;
const ImageV2 = styled.img`
  margin-top: 20px;
  width: 100%;
  max-height: 142px;
  object-fit: contain;
`;
const ImageTitle = styled.div`
  position: absolute;
  top: 80px;
  right: 50px;
  color: #007897;
  -webkit-text-stroke: 1px white;
  font-family: Assistant;
  font-size: 30px;
  font-weight: 800;
  line-height: 35px;
`;

const PostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  gap: ${({ gap }) => gap};
`;
const PostHighlight = styled.div`
  border-radius: 5px;
  margin: 15px;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(127, 179, 131, 0.5);
`;
const PostItemEditWrapper = styled.div`
  margin: 15px 18px;
  padding: 9px 15px;
  position: relative;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 17.5px;
`;
const CloseEdit = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`;
const PostItemsButton = styled.button`
  min-width: 152px;
  height: 32px;
  top: 216px;
  left: 220px;
  gap: 0px;
  border-radius: 17.5px;
  background: white;
  margin-left: auto;
  border: 2px solid #2882a7;
  opacity: 0px;
  font-family: Assistant;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  color: #2882a7;
  cursor: pointer;
`;

function CommunityBoardPage({
  posts,
  setPosts,
  postItems,
  refetchPostItems,
  refetchPosts,
  networkPosts,
  setNetworkPosts,
  refetchNetworkPosts,
  zone,
  type,
  section,
  postId,
  isCustom = false,
  isPending,
}) {
  const navigate = useNavigate();
  const { isAuthenticated, currentUser } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  let [searchParams] = useSearchParams();
  const [showAddPost, setShowAddPost] = useState(searchParams.get("show"));
  const [showAddItem, setShowAddItem] = useState(false);
  const [showAddNetworkPost, setShowAddNetworkPost] = useState(false);
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const isEmergency = useMemo(() => zone?.startsWith("Emergency"), [zone]);
  const [giveAndReceivePopUpData, setGiveAndReceivePopUpData] =
    useState(undefined);
  const [showShareList, setShowShareList] = useState(false);
  const [editPostItem, setEditPostItem] = useState(null);
  const highlightedPost = useMemo(
    () =>
      section === CommunitySections.Networks
        ? networkPosts?.find((p) => p._id === postId)
        : posts?.find((p) => p._id === postId),
    [section, networkPosts, posts, postId]
  );
  useHideBack();

  const userHasAccess = useMemo(() => {
    if (
      zone === "vet" &&
      !(
        section === CommunitySections.FiveMin ||
        section === CommunitySections.DailyTip
      )
    ) {
      return isUserAuthorizedForAction(currentUser);
    }
    return true;
  }, [zone, section, currentUser]);
  const data = useGetEcosystemById(zone).data;

  const customboard = useMemo(() => {
    return data?.postingCards
      ?.map((card) => ({
        ...card,
        title: card.name,
        id: card._id,
      }))
      .find((card) => card.id === postId);
  }, [data?.postingCards, postId]);

  const renderContent = () => (
    <>
      {showShareList && (
        <ShareMask
          aria-disabled={true}
          onClick={() => setShowShareList(false)}
        />
      )}
      {showShareList && <SharePost setShowShareList={setShowShareList} />}
      <GiveReceivePopUp
        visible={!!giveAndReceivePopUpData}
        hidePopUpfunc={() => setGiveAndReceivePopUpData(undefined)}
        data={giveAndReceivePopUpData}
        onDelete={() => refetchPostItems()}
      />
      {section && section !== CommunitySections.CommunityBoard && (
        <Header
          Icon={
            getLogoByZone(zone)
              ? getLogoByZone(zone)
              : () => TogetherIcon({ width: "240", height: "60" })
          }
          title={getLogoByZone(zone) ? undefined : data?.title}
          showLogoutIcon={false}
        />
      )}
      {(section === CommunitySections.DailyTip ||
        section === CommunitySections.FiveMin ||
        section === CommunitySections.AnimalAssociations ||
        section === CommunitySections.HelpfulTipsAndAdvice ||
        section === CommunitySections.EmergencyAndRescueTips ||
        customboard) && (
        <BasicBoard
          posts={posts}
          setPosts={setPosts}
          showAddPost={showAddPost}
          setShowAddPost={setShowAddPost}
          refetchPosts={refetchPosts}
          setShowShareList={setShowShareList}
          zone={zone}
          section={section}
          postId={postId}
          customboard={customboard}
        />
      )}
      {!isEmergency &&
        !(section && section !== CommunitySections.CommunityBoard) && (
          <>
            <ImageV2
              src={
                zone === MainOrgZone["6655d5224b6e3b35f15097c0"]
                  ? C0CommunityBoard
                  : WideCommunityBoard
              }
              alt=""
            />
            <BoardFormV2
              showForm={showAddPost}
              setShowForm={setShowAddPost}
              categories={getCategoriesByZone(zone)}
              isLoading={isPending}
              content={
                <CommunityBoardFormContent
                  posts={posts}
                  setPosts={setPosts}
                  onPostSuccess={() => setShowAddPost(false)}
                  zone={zone}
                  section={section}
                  categories={getCategoriesByZone(zone)}
                />
              }
              headerButton={
                <button
                  style={{
                    ...PostButtonStyles(
                      currentLanguage === LANGUAGES.ENGLISH.value
                    ),
                    background: "#fff",
                    margin: "auto 0",
                  }}
                  onClick={() => setShowAddPost((shown) => !shown)}
                >
                  post
                </button>
              }
            >
              <PostsWrapper gap={"13px"}>
                {highlightedPost && (
                  <PostHighlight>
                    <Post
                      postData={highlightedPost}
                      posts={posts}
                      refetchPosts={refetchPosts}
                      setPosts={setPosts}
                      setShowShareList={setShowShareList}
                      isNetwork={section === CommunitySections.Networks}
                      zone={zone}
                      actionIcon={<ThreeDotsIcon />}
                      isCB
                    />
                  </PostHighlight>
                )}
                {posts &&
                  posts
                    ?.filter((p) => p._id !== postId)
                    ?.map((post) => (
                      <Post
                        postData={post}
                        posts={posts}
                        refetchPosts={refetchPosts}
                        setPosts={setPosts}
                        setShowShareList={setShowShareList}
                        isNetwork={section === CommunitySections.Networks}
                        zone={zone}
                        showImage={false}
                        actionIcon={<ThreeDotsIcon />}
                        isCB
                      />
                    ))}
              </PostsWrapper>
            </BoardFormV2>
          </>
        )}
      {!(section && section !== CommunitySections.GiveReceive) && (
        <>
          <Image src={GiveReceiveImage} alt="" />
          <Form
            showForm={showAddItem}
            setShowForm={setShowAddItem}
            isBtnOnlyDesign
            content={
              <PostItems
                refetchPostItems={refetchPostItems}
                onPostSuccess={() => setShowAddItem(false)}
                zone={zone}
                type={type}
                section={section}
              />
            }
            headerButton={
              <PostItemsButton
                onClick={() => setShowAddItem((shown) => !shown)}
              >
                הוספת מוצרים / בקשות
              </PostItemsButton>
            }
          />
          <PostsWrapper>
            {postItems?.map((activity) =>
              activity?.id === editPostItem ? (
                <PostItemEditWrapper>
                  <PostItems
                    refetchPostItems={refetchPostItems}
                    onPostSuccess={() => setEditPostItem(null)}
                    zone={zone}
                    type={type}
                    section={section}
                    isEditMode={true}
                    postData={activity}
                  />
                  <CloseEdit onClick={() => setEditPostItem(null)}>X</CloseEdit>
                </PostItemEditWrapper>
              ) : (
                <Card
                  key={activity?.id}
                  image={activity?.image}
                  text={activity?.name}
                  action={() => setGiveAndReceivePopUpData(activity)}
                  actionText={activity?.actionText}
                  location={activity?.location}
                  date={activity?.date}
                  userId={activity?.user_id}
                  showOptions={true}
                  isBigDesign
                  optionsList={[
                    {
                      onClick: async () => {
                        if (!isAuthenticated) return;
                        try {
                          await deletePostItem(activity?.id);
                          refetchPostItems();
                        } catch (ex) {
                          console.error(
                            `Failed to delete post item. Error=${ex}`
                          );
                        }
                      },
                      text: "Delete",
                    },
                    {
                      onClick: async () => {
                        setEditPostItem(activity?.id);
                      },
                      text: "Edit",
                    },
                  ]}
                />
              )
            )}
          </PostsWrapper>
        </>
      )}
      {!isEmergency && !(section && section !== CommunitySections.Networks) && (
        <>
          <TitleWrapper>
            <ImageWrapper>
              <Image
                src={
                  CUSTOM_TITLE_ZONES.includes(zone)
                    ? TLVNetworkBlankImage
                    : currentLanguage === LANGUAGES.HEBREW.value
                    ? TLVNetworkHebrewImage
                    : TLVNetworkImage
                }
                alt=""
              />
              {CUSTOM_TITLE_ZONES.includes(zone) && (
                <ImageTitle>שאלות מקצועיות</ImageTitle>
              )}
            </ImageWrapper>
          </TitleWrapper>
          <Form
            title={
              currentLanguage === LANGUAGES.HEBREW.value
                ? "שתפ/י:"
                : "Add post to TLV Networking ..."
            }
            showForm={showAddNetworkPost}
            setShowForm={setShowAddNetworkPost}
            content={
              <AddPost
                posts={networkPosts}
                setPosts={setNetworkPosts}
                onPostSuccess={() => setShowAddNetworkPost(false)}
                zone={zone}
                type={PostType.Network}
                section={section}
              />
            }
            headerButton={
              <button
                style={{
                  ...PostButtonStyles(
                    currentLanguage === LANGUAGES.ENGLISH.value
                  ),
                  background: "#fff",
                  margin: "auto 0",
                }}
                onClick={() => setShowAddNetworkPost((shown) => !shown)}
              >
                post
              </button>
            }
          />
          <PostsWrapper>
            {highlightedPost && (
              <PostHighlight>
                <Post
                  postData={highlightedPost}
                  posts={networkPosts}
                  refetchPosts={refetchNetworkPosts}
                  setPosts={setNetworkPosts}
                  setShowShareList={setShowShareList}
                  isNetwork={section === CommunitySections.Networks}
                  zone={zone}
                />
              </PostHighlight>
            )}
            {networkPosts &&
              networkPosts
                ?.filter((p) => p._id !== postId)
                ?.map((post) => (
                  <Post
                    postData={post}
                    posts={networkPosts}
                    refetchPosts={refetchNetworkPosts}
                    setPosts={setNetworkPosts}
                    setShowShareList={setShowShareList}
                    isNetwork={section === CommunitySections.Networks}
                    zone={zone}
                    section={section}
                  />
                ))}
          </PostsWrapper>
        </>
      )}
    </>
  );

  return (
    <Wrapper>
      <ReturnArrow
        onClickOverride={() => {
          navigate(
            isCustom ? `/public/activities/view/${zone}` : getZoneUrl(zone)
          );
        }}
      />
      {isAuthenticated && !userHasAccess && <LoginAccessText noLogin />}
      {!isAuthenticated && !userHasAccess && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#555",
            height: "100vh",
          }}
        >
          Please&nbsp;
          <span
            style={{ color: "#DD4343", cursor: "pointer" }}
            onClick={() => {
              setLoginRoute(
                `/activities/board/zone/${zone}${
                  section ? `/section/${section}` : ""
                }`
              );
              navigate("/login");
            }}
          >
            login
          </span>
          &nbsp;to view this board.
        </div>
      )}
      {userHasAccess && renderContent()}
    </Wrapper>
  );
}

export default CommunityBoardPage;
