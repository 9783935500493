import React from "react";

const GreenDoubleArrowIcon2 = ({
  color = "#7FB383",
  width = 15,
  height = 15,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill={color}
        d="M.047 14.843l5.276-7.387L.047.07h2.585l5.276 7.386-5.276 7.387H.047zm6.278 0l5.277-7.387L6.325.07h2.586l5.276 7.386-5.276 7.387H6.325z"
      ></path>
    </svg>
  );
};

export default GreenDoubleArrowIcon2;
