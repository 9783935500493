import React, { useEffect, useState, useContext, useMemo } from "react";
import { styled } from "styled-components";
import communityTransport from "../../../../../assets/images/Tasks/detailsPage/transportation.png";
import heart from "../../../../../assets/images/Tasks/detailsPage/heart.svg";
import heartLiked from "../../../../../assets/images/Tasks/detailsPage/heartLiked.svg";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import waitingListIcon from "../../../../../assets/images/Tasks/detailsPage/waitingList.svg";
import { useQuery } from "@tanstack/react-query";
import {
  deleteActivity,
  deleteEvent,
  deleteHub,
  deleteTask,
  endTaskForUser,
  getActivity,
  getEvent,
  getHub,
  getTask,
  getUserTaskStatus,
  likeEntity,
  startTaskForUser,
  unlikeEntity,
} from "../../Activities/Create/api";
import Dropdown from "../../../../../kit/Dropdown/Dropdown";
import { getShareDeleteOptions } from "../../../../../kit/Dropdown/DropdownOptions";
import TaskDeletedPage from "../../Activities/Create/CreateTask/TaskDeletedPage";
import useFetchUser from "../../../../../customHooks/useFetchUser";
import { UserContext } from "../../../../../store/userContext";
import { getPhotoSrc } from "../../../Post/utils";
import WaitingList from "../WaitingList/WaitingList";
import WhatsAppIcon from "../../../../../assets/icons/Common/Layout/WhatsAppIcon.svg";
import SmallGreenAppleIcon from "../../../../../assets/icons/Common/Apples/SmallGreenAppleIcon";
import { getZoneUrl } from "./urlUtils";
import { FaChevronDown } from "react-icons/fa";
import Map, { MarkerIcon } from "../../../../../kit/Map/Map";
import ShareButton from "../../../../../kit/Share/ShareButton";
import RegisterPage from "./RegisterPage";
import LoginPage from "./LoginPage";
import Transportation from "../Transportation/Transportation";
import { CardDetailsContext } from "../../../../../store/cardDetailsContext";
import CoverPhoto from "../../../../Emergency/Contacts/components/CoverPhoto";
import { TogetherIcon } from "../../../../../assets/icons/Logo";
import { DoubleArrowIconsLeft } from "../../../../../assets/icons";
import {
  EMERGENCY_DESCRIPTION,
  EMERGENCY_MESSAGE,
  NEW_DESIGN_ZONES,
  ZONES,
} from "./constants";
import GoogleMapsButton from "../../../../../kit/MapsExternalButtons/GoogleMapsButton";
import WazeButton from "../../../../../kit/MapsExternalButtons/WazeButton";
import { mockHubData, mockVPData } from "./Mock/data";
import LoadingSpinner from "../../../../Common/Loading/Loading";
import { getLinearEmergencyData } from "../../../Mock/publicData";
import EmergencyLinearCard from "../Cards/Linear/Emergency";
import DetailsOrganizations from "./components/DetailsOrganizations";
import { MAIN_ROUTES } from "../../../../Common/Layout/Main/constants";
import { getEntityType, isLiked } from "../../../../Tasks/TaskUtils";
import useFetchEcosystemById from "../../../../../customHooks/useFetchEcosystemById";
import { isUserAuthorizedForAction } from "../../../../Auth/login/utils";
import useMediaQueryConstants from "../../../../../customHooks/useMediaQueryConstants";
import useCustomAppWidth from "../../../../../customHooks/useCustomAppWidth";
import Header from "../../../../Common/Layout/Header";
import ReturnArrow from "../../../../Common/Layout/ReturnArrow/ReturnArrow";
import LoginAccessText from "../../../../../kit/LoginAccessText/LoginAccessText";
import useFetchWaitingList from "../../../../../customHooks/useFetchWaitingList";
import { ChecksIcon } from "./CardDetailsIcons";
import { getCurrentLogo } from "./CardDetailsUtils";
import CardDetailsFeedback from "./CardDetailsFeedback";
import SelectionPopup from "../../../../Admin/AdminArea/CreatePage/SelectionPopup";
import LocationIcon from "../../../../../assets/icons/LocationIcon";
import { ZoneRouteContext } from "../../../../../store/zoneRouteContext";
import CardDetailsConfirmPage from "./CardDetailsConfirmPage";
import PhoneNumberInput from "../../../../../kit/Input/PhoneNumberInput";
import { PreviousRouteContext } from "../../../../../store/perviousRouteContext";

const Wrapper = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "88%" : "100%")};
  display: ${({ isWideScreen }) => (isWideScreen ? "flex" : "block")};
  margin: ${({ isWideScreen }) => (isWideScreen ? "0 2% 0 10% " : "0")};
  gap: ${({ isWideScreen }) => (isWideScreen ? "20px" : "0")};
`;
const Container = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "60%" : "90%")};
  display: flex;
  flex-direction: ${({ isWideScreen }) =>
    isWideScreen ? "column-reverse" : "column"};
  gap: ${({ isWideScreen }) => (isWideScreen ? "20px" : "0")};
  justify-content: space-between;
  margin: ${({ isWideScreen }) => (isWideScreen ? "0" : "auto")};
  position: relative;
`;
const BigContainer = styled.div`
  width: ${({ width, isWideScreen }) => width ?? "auto"};
  margin: ${({ margin }) => margin ?? "auto"};
  padding: ${({ padding }) => padding ?? "0"};
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius ?? "0"};
  background: ${({ background }) => background ?? "none"};
`;
const ButtonsGroup = styled.div`
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin} 0;
  align-items: center;
  width: 100%;
`;
const NewButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
`;
export const ButtonEl = styled.button`
  width: ${(props) => props.width};
  text-align: center;
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius};
  border: 2.87px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  font-size: ${(props) => props.fontSize};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.51px;
  font-family: Assistant;
  background-color: ${(props) =>
    props.backGroundColor ? props.backGroundColor : "white"};
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : "10px auto 25px auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.gap ?? "7.5px"};
  box-shadow: ${(props) => props.boxShadow ?? "none"};
  height: ${(props) => props.height ?? "auto"};
`;
const MapIconsWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;
const CoverPhotoWrapper = styled.div`
  width: 100%;
  height: 354px;
`;
const CoverPhotoEl = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;
const Image = styled.img`
  width: 100%;
  height: ${(props) => props.height ?? "100%"};
  object-fit: contain;
  cursor: pointer;
`;
const CommunityTransportText = styled.p`
  margin: 0;
  font-size: 10px;
  color: var(--preview-txt, #58656d);
  text-align: center;
  font-family: Assistant;
  font-size: 9.965px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.179px;
`;
const RewardIcon = styled.div`
  height: 24px;
  flex-shrink: 0;
  background-color: #7fb383;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 1px 8.5px;
  color: #fff;
  font-weight: 700;
  margin: 0 10px;
  font-size: 13.5px;
  box-sizing: border-box;
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? "column"};

  justify-content: ${(props) => props.justifyContent ?? "none"};
  width: 100%;
  margin: 0 auto;
  text-align: right;
  font-family: Assistant;
  font-size: 18px;
  color: #316788;
  direction: rtl;
  gap: 10px;
`;

const DataRowTitle = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? "start"};
  gap: 5px;
  align-items: center;
`;

const DataRowValue = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Separator = styled.div`
  border: 1px solid #7fb383;
  opacity: 0.3;
  width: 96%;
  margin: 16px auto;
`;

const Options = styled.div`
  transform: Rotate(90deg);
  font-family: Assistant;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  line-height: 5px;
  height: 30px;
  color: #316788;
  position: relative;
  top: 8px;
`;

const UnderLinedBtn = styled.div`
  cursor: pointer;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.16px;
  letter-spacing: 0.02em;
  text-align: right;
  color: #1e9898;
  text-decoration-line: underline;
  margin-bottom: 25px;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 0 10px;
`;

const Title = styled.div`
  font-family: Assistant;
  font-size: 22px;
  font-weight: 600;
  color: #316788;
  max-width: 220px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: auto;
`;
const More = styled(Link)`
  color: #316788;
  text-align: left;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? "start"};
  margin-top: ${({ isWideScreen }) => (isWideScreen ? "20px" : "0")};
  align-items: center;
  gap: 3px;
`;
const PhonePromptTitle = styled.div`
  font-family: Assistant;
  font-size: 22px;
  font-weight: 600;
  line-height: 28.78px;
  letter-spacing: -0.018em;
  text-align: center;
  color: #58656d;
  margin: ${({ margin }) => margin ?? "5px 0 10px 0"};
`;
const DATA = {
  userId: "",
  title: "",
  description: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  mainLocation: "",
  subLocation: "",
  reward: "",
  rewardQuantity: "",
  photo: "",
  contactName: "",
  contactNumber: "",
  latitude: "",
  longitude: "",
  isLiked: false,
};

export const CARD_DETAILS_PAGES = {
  Level_One: "Level_One",
  Register: "Register",
  Feedback: "Feedback",
  Confirm: "Confirm",
  TimeRewardsShareView: "TimeRewardsShareView",
};

export const DataType = {
  Task: "task",
  Event: "event",
  Activity: "activity",
  Hub: "hub",
};

export const WaitingListUserTaskStatus = {
  New: "new",
  Active: "active",
  Done: "done",
};

export const E1BtnProps = {
  width: "100%",
  borderRadius: "30px",
  borderColor: "#BADAE9",
  color: "#316788",
  fontSize: "17px",
  padding: "11px 0",
  margin: "auto",
};

const AllowLocationOptions = [
  {
    title: "כן, חד פעמי",
    value: "1",
  },
  {
    title: "כן, לשעה הקרובה",
    value: "2",
  },
  {
    title: "לא כרגע",
    value: "3",
  },
];

export const renderShareBtn = (location, description, customUrl) => (
  <ShareButton
    description={
      location?.pathname?.includes?.("emergency") &&
      !["BrothersInArms", "KaplanForce"].some((keyword) =>
        location?.pathname?.includes?.(keyword)
      )
        ? EMERGENCY_DESCRIPTION
        : description
    }
    welcomeMessage={
      location?.pathname?.includes?.("emergency")
        ? EMERGENCY_MESSAGE
        : undefined
    }
    customUrl={customUrl}
  />
);

const CardDetailsPage = ({
  navBack = true,
  showWaitingListByDefault,
  showFeedbackByDefault,
}) => {
  useCustomAppWidth();
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { previousRoute } = useContext(PreviousRouteContext);
  const [currentView, setCurrentView] = useState(
    showFeedbackByDefault ||
      previousRoute === "/activities/map/volunteering_pleasure"
      ? CARD_DETAILS_PAGES.Feedback
      : showWaitingListByDefault
      ? CARD_DETAILS_PAGES.Register
      : CARD_DETAILS_PAGES.Level_One
  );
  const { isAuthenticated, currentUser } = useContext(UserContext);
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const { selectedItem: selectedItemId } = useContext(CardDetailsContext);

  const navigate = useNavigate();
  const { id, zone, dataType, feedbackDisplayName } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { isLoading, data: fetchedData } = useQuery({
    queryKey: [dataType, id],
    queryFn: () => {
      if (dataType === DataType.Task) return getTask(id);
      if (dataType === DataType.Event) return getEvent(id);
      if (dataType === DataType.Activity) return getActivity(id);
      if (dataType === DataType.Hub) return getHub(id);
    },
  });

  const showNewDesign = NEW_DESIGN_ZONES.includes(zone);
  const data =
    zone === ZONES.VOLUNTEERING_PLEASURE
      ? mockVPData[id]
      : fetchedData || mockHubData;

  const [cardData, setCardData] = useState(DATA);
  const [waitingListVisible, setWaitingListVisible] = useState(false);
  const [startWaitingListVisible, setStartWaitingListVisible] = useState(false);
  const [TransportationVisible, setTransportationVisible] = useState(false);
  const [allowLocationPrompt, setAllowLocationPrompt] = useState(true);
  const [showNumberPrompt, setShowNumberPrompt] = useState(false);
  const [isVisitorRegistered, setIsVisitorRegistered] = useState(false);
  const { user } = useFetchUser({ userId: data?.data?.user_id });

  const { waitingList, refetchWaitingList } = useFetchWaitingList(dataType, id);
  useEffect(() => {
    if (!waitingList) {
      // refetchWaitingList();
    }
  }, [waitingList]);

  const isUserRegisteredInTask = useMemo(() => {
    if (isVisitorRegistered) return true;
    if (!isAuthenticated || !waitingList || waitingList?.users?.length < 1) {
      return false;
    }
    if (waitingList?.users?.find((user) => user.user_id === currentUser?._id)) {
      return true;
    }
    return false;
  }, [currentUser?._id, isAuthenticated, isVisitorRegistered, waitingList]);

  const [userTaskStatus, setUserTaskStatus] = useState(undefined);

  useEffect(() => {
    if (!waitingList?._id || !currentUser?._id) {
      return;
    }
    const fetchUserTaskStatus = async () => {
      if (!isUserRegisteredInTask) {
        setUserTaskStatus(undefined);
        return;
      }
      try {
        const response = await getUserTaskStatus(
          waitingList?._id,
          currentUser?._id
        );
        setUserTaskStatus(response.data.status);
      } catch (error) {
        console.error("Failed to fetch user task status:", error);
        setUserTaskStatus(undefined);
      }
    };

    fetchUserTaskStatus();
  }, [currentUser?._id, isUserRegisteredInTask, waitingList?._id]);

  const getLocation = (indoor, outdoor) => {
    if (indoor && indoor !== "" && indoor !== "-") {
      return indoor;
    }
    if (outdoor && outdoor !== "" && outdoor !== "-") {
      return outdoor;
    }
    return "-";
  };
  useEffect(() => {
    if ((isLoading && zone !== ZONES.VOLUNTEERING_PLEASURE) || !data) {
      return;
    }
    const fetchedCardData = data?.data;
    setCardData({
      userId: fetchedCardData.user_id,
      title: fetchedCardData.subtitle,
      description: fetchedCardData.description,
      startDate: fetchedCardData.start_date,
      endDate: fetchedCardData.end_date,
      startTime: fetchedCardData.start_time,
      endTime: fetchedCardData.end_time,
      mainLocation: getLocation(
        fetchedCardData.outdoor_location,
        fetchedCardData.indoor_location
      ),
      subLocation: "",
      reward: fetchedCardData.reward,
      rewardQuantity: fetchedCardData.quantity_reward,
      photo: fetchedCardData.image,
      contactName: fetchedCardData.contact_name,
      contactNumber: fetchedCardData.contact_number || cardData.contactNumber,
      longitude: fetchedCardData.longitude,
      latitude: fetchedCardData.lattiude,
      taskType: fetchedCardData.task_type,
      isLiked: isAuthenticated
        ? isLiked(fetchedCardData, currentUser?._id)
        : false,
    });
  }, [currentUser?._id, data, isLoading, isAuthenticated]);

  const { ecosystem } = useFetchEcosystemById(zone);
  const userHasAccess = useMemo(() => {
    if (zone === "vet") {
      return isUserAuthorizedForAction(currentUser);
    }
    return true;
  }, [zone, currentUser]);

  const startDateFormatted = useMemo(
    () =>
      cardData.startDate
        ?.replaceAll?.("-", "/")
        ?.split?.("/")
        ?.reverse?.()
        ?.join?.("/"),
    [cardData.startDate]
  );

  const endDateFormatted = useMemo(
    () =>
      cardData.endDate
        ?.replaceAll?.("-", "/")
        ?.split?.("/")
        ?.reverse?.()
        ?.join?.("/"),
    [cardData.endDate]
  );

  const [visitorName, setVisitorName] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [authRegistration, setAuthRegistration] = useState(false);
  const onDeleteClick = async () => {
    try {
      if (cardData?.taskType === DataType.Task || dataType === DataType.Task)
        await deleteTask(id);
      if (cardData?.taskType === DataType.Event || dataType === DataType.Event)
        await deleteEvent(id);
      if (
        cardData?.taskType === DataType.Activity ||
        dataType === DataType.Activity
      )
        await deleteActivity(id);
      if (cardData?.taskType === DataType.Hub || dataType === DataType.Hub)
        await deleteHub(id);
      setDeleted(true);
    } catch (ex) {
      console.error(`Failed to delete ${dataType} with error: ` + ex, ex);
    }
  };

  const onEditClick = async () => {
    if (dataType === DataType.Task)
      navigate(`/activities/together/${zone}/new-task/${id}`);
    if (dataType === DataType.Event)
      navigate(`/activities/together/${zone}/new-event/${id}`);
    if (dataType === DataType.Activity)
      navigate(`/activities/together/${zone}/new-activity/${id}`);
    if (dataType === DataType.Hub)
      navigate(`/activities/together/${zone}/new-hub/${id}`);
  };

  const renderContactIcons = () => (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <img src={WhatsAppIcon} width={30} height={30} alt="whatsapp" />
      <SmallGreenAppleIcon width={"30"} height={"33"} />
    </div>
  );

  if (deleted) {
    return <TaskDeletedPage dataType={dataType} />;
  }

  if (currentView === CARD_DETAILS_PAGES.Feedback) {
    return (
      <CardDetailsFeedback
        zone={zone}
        cardData={cardData}
        ecosystem={ecosystem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        displayName={
          showFeedbackByDefault
            ? feedbackDisplayName ?? "-"
            : isAuthenticated
            ? currentUser?.name
            : visitorName
        }
        isShareView={showFeedbackByDefault}
      />
    );
  }

  const registerVisitor = (visitorName) => {
    setIsVisitorRegistered(true);
    setUserTaskStatus(WaitingListUserTaskStatus.New);
    if (visitorName) {
      setVisitorName(visitorName);
    }
  };

  if (currentView === CARD_DETAILS_PAGES.Register) {
    if (showNewDesign) {
      if (!isAuthenticated) {
        setLoginRoute(location.pathname);
        navigate("/login");
      } else {
        return (
          <LoginPage
            dataType={dataType}
            entityId={id}
            setCurrentView={setCurrentView}
            logo={
              getCurrentLogo(zone) ||
              TogetherIcon({ width: "240", height: "60" })
            }
            authorId={cardData?.userId || data?.data?.userId}
          />
        );
      }
    } else {
      if (!isAuthenticated && authRegistration) {
        setLoginRoute(`${location.pathname}?confirm=true`);
        navigate("/login");
      }
      return (
        <RegisterPage
          dataType={dataType}
          entityId={id}
          setCurrentView={setCurrentView}
          zone={zone}
          authorId={cardData?.userId || data?.data?.userId}
          registerVisitorFunc={registerVisitor}
        />
      );
    }
  }

  // Show confirm page after login for members
  if (
    (searchParams.get("confirm") &&
      isAuthenticated &&
      CARD_DETAILS_PAGES.Level_One &&
      !isUserRegisteredInTask) ||
    currentView === CARD_DETAILS_PAGES.Confirm
  ) {
    return (
      <CardDetailsConfirmPage
        setCurrentView={setCurrentView}
        postData={{
          waitingList_id: waitingList?._id,
          user_id: currentUser?._id,
          phone_number: currentUser?.phone,
          full_name: currentUser?.displayName,
          city: currentUser?.address,
          authorId: cardData?.userId || data?.data?.userId,
        }}
        cardData={cardData}
        refetchWaitingList={refetchWaitingList}
      />
    );
  }

  const renderButtonsSection = () => {
    const oldBtnProps = {
      width: "172px",
      borderRadius: "30px",
      borderColor: "#316788",
      color: "#316788",
      fontSize: "15px",
      padding: "5px 0",
      height: "44px",
    };
    return (
      <>
        <SelectionPopup
          title={"קוד קהילה  -  Insert Code"}
          visible={showNumberPrompt}
          setVisible={setShowNumberPrompt}
          onDismiss={() => setShowNumberPrompt(false)}
          bodyComponentRenderFunc={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                gap: "20px",
              }}
            >
              <PhonePromptTitle>
                הזינו קוד קהילה לתחילת
                <br />
                המשימה / פעילות
              </PhonePromptTitle>
              <PhoneNumberInput
                inputStyle={{
                  border: "2.11px solid #58656D",
                  backgroundColor: "#FFF",
                  width: "26.278px",
                  height: "28.341px",
                  borderRadius: "7.167px",
                }}
                showSeparator={false}
                setValue={() => {}}
                length={4}
                placeholderValue={"2244"}
              />
              <PhonePromptTitle margin={"0"}>או Or</PhonePromptTitle>
              <PhonePromptTitle margin={"0 0 10px 0"}>
                הזינו מספר טלפון Telephone{" "}
              </PhonePromptTitle>
              <PhoneNumberInput
                inputStyle={{
                  border: "2.11px solid #58656D",
                  backgroundColor: "#FFF",
                  width: "26.278px",
                  height: "28.341px",
                  borderRadius: "7.167px",
                }}
                setValue={() => {}}
              />
              <ButtonEl
                {...E1BtnProps}
                width={"150px"}
                borderColor="#28B5B5"
                padding={"2px 0"}
                backGroundColor="#28B5B5"
                color="#fff"
                boxShadow="0px 4px 10px 0px #42848033"
                onClick={() => {
                  setShowNumberPrompt(false);
                  registerVisitor();
                }}
              >
                Confirm - אישור
              </ButtonEl>
            </div>
          )}
        />
        <Separator />
        <DataRow>
          <DataRowTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: "15px", margin: "7.5px 0 10px 0" }}>
                הרשמה - Register
              </div>
            </div>
          </DataRowTitle>
        </DataRow>
        <ButtonsGroup
          margin="0"
          gap={"15px"}
          display={`${showNewDesign ? "block" : "flex"}`}
        >
          {!selectedItemId &&
            (showNewDesign ? (
              <NewButtonWrapper>
                <ButtonEl
                  {...E1BtnProps}
                  borderColor="#316788"
                  onClick={() => setCurrentView(CARD_DETAILS_PAGES.Register)}
                >
                  Register &nbsp;&nbsp;&nbsp; הרשמה להתנדבות
                </ButtonEl>
                <ButtonEl
                  {...E1BtnProps}
                  borderColor="#7FB3CC"
                  onClick={() => setCurrentView(CARD_DETAILS_PAGES.Register)}
                >
                  Register &nbsp;&nbsp;&nbsp; הרשמה ללוגיסטיקה
                </ButtonEl>
                <ButtonEl
                  {...E1BtnProps}
                  borderColor="#BADAE9"
                  onClick={() => setCurrentView(CARD_DETAILS_PAGES.Register)}
                >
                  Register &nbsp;&nbsp;&nbsp; הרשמה הסעות
                </ButtonEl>
              </NewButtonWrapper>
            ) : isUserRegisteredInTask ? (
              <NewButtonWrapper>
                {!userTaskStatus ||
                userTaskStatus === WaitingListUserTaskStatus.New ? (
                  <>
                    <ButtonEl
                      {...E1BtnProps}
                      borderColor="#28B5B5"
                      color={"#28B5B5"}
                    >
                      <ChecksIcon />
                      הרשמתך נקלטה
                    </ButtonEl>
                    <ButtonEl
                      {...E1BtnProps}
                      borderColor="#28B5B5"
                      backGroundColor="#28B5B5"
                      color="#fff"
                      boxShadow="0px 4px 10px 0px #42848033"
                      onClick={async () => {
                        try {
                          await startTaskForUser({
                            user_id: currentUser?._id,
                            waitingList_id: waitingList?._id,
                            authorId: cardData?.userId || data?.data?.userId,
                          });
                          setUserTaskStatus(WaitingListUserTaskStatus.Active);
                        } catch (error) {
                          console.error("Error starting task:", error);
                        }
                      }}
                    >
                      Start - התחלה
                    </ButtonEl>
                    <UnderLinedBtn>Maybe another time</UnderLinedBtn>
                  </>
                ) : userTaskStatus === WaitingListUserTaskStatus.Active ? (
                  <>
                    <ButtonEl
                      {...E1BtnProps}
                      borderColor="#28B5B5"
                      backGroundColor="#28B5B5"
                      color="#fff"
                      boxShadow="0px 4px 10px 0px #42848033"
                    >
                      <ChecksIcon single color="#fff" />
                      בתהליך - In Process
                    </ButtonEl>
                    <ButtonEl
                      {...E1BtnProps}
                      borderColor="#28B5B5"
                      color={"#28B5B5"}
                      onClick={async () => {
                        try {
                          if (isAuthenticated) {
                            await endTaskForUser({
                              user_id: currentUser?._id,
                              waitingList_id: waitingList._id,
                              authorId: cardData?.userId || data?.data?.userId,
                            });
                          }
                          setUserTaskStatus(WaitingListUserTaskStatus.Done);
                          setCurrentView(CARD_DETAILS_PAGES.Feedback);
                        } catch (error) {
                          console.error("Error ending task:", error);
                        }
                      }}
                    >
                      סיום - Done
                    </ButtonEl>
                    <SelectionPopup
                      title={"שיתוף מיקום  -  Location"}
                      icon={<LocationIcon />}
                      visible={allowLocationPrompt}
                      setVisible={setAllowLocationPrompt}
                      onDismiss={() => setAllowLocationPrompt(false)}
                      options={AllowLocationOptions}
                    />
                  </>
                ) : (
                  <>
                    <ButtonEl
                      {...E1BtnProps}
                      borderColor="#28B5B5"
                      backGroundColor="#28B5B5"
                      color="#fff"
                      boxShadow="0px 4px 10px 0px #42848033"
                    >
                      <ChecksIcon color="#fff" />
                      Done - סיום
                    </ButtonEl>
                  </>
                )}
              </NewButtonWrapper>
            ) : (
              <>
                <ButtonEl
                  {...oldBtnProps}
                  onClick={() => {
                    setAuthRegistration(false);
                    setCurrentView(CARD_DETAILS_PAGES.Register);
                  }}
                  color={"#316788"}
                  borderColor={"#316788"}
                >
                  Visitors אורחים
                </ButtonEl>
                <ButtonEl
                  {...oldBtnProps}
                  onClick={() => {
                    if (isAuthenticated) {
                      setCurrentView(CARD_DETAILS_PAGES.Confirm);
                    } else {
                      setAuthRegistration(true);
                      setCurrentView(CARD_DETAILS_PAGES.Register);
                    }
                  }}
                  gap={0}
                  width={"210px"}
                  color={"#59865D"}
                  borderColor={"#59865D"}
                >
                  <div style={{ marginLeft: "4px", marginRight: "2px" }}>
                    <SmallGreenAppleIcon width={"25"} height={"28"} />
                  </div>
                  Members לחברים
                </ButtonEl>
              </>
            ))}
          {selectedItemId && (
            <ButtonEl
              width="226px"
              borderRadius="30px"
              borderColor="#316788"
              color="#316788"
              fontSize="17px"
              padding="5px 0"
              onClick={() => navigate("/reward")}
            >
              Transfer &nbsp;&nbsp;&nbsp; העבר תגמול
            </ButtonEl>
          )}
        </ButtonsGroup>
        {!Object.values(WaitingListUserTaskStatus).includes(userTaskStatus) && (
          <>
            <Separator />
            <DataRow>
              <DataRowTitle>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginLeft: "15px", margin: "7.5px 0 10px 0" }}>
                    לתחילת פעילות / משימה
                  </div>
                </div>
              </DataRowTitle>
            </DataRow>
            <ButtonEl
              {...oldBtnProps}
              onClick={() => {
                setStartWaitingListVisible(true);
              }}
              color={"#316788"}
              borderColor={"#316788"}
            >
              Start - התחלה
            </ButtonEl>
          </>
        )}
      </>
    );
  };

  const renderDataSection = () => {
    const renderDescriptionSection = () => (
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#316788",
          }}
        >
          <DataRowTitle>{`${startDateFormatted} ${
            endDateFormatted !== startDateFormatted
              ? `- ${endDateFormatted}`
              : ""
          }`}</DataRowTitle>
          <DataRowTitle>{cardData.mainLocation}</DataRowTitle>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "#316788",
            marginTop: "10px",
          }}
        >
          <DataRowTitle>{`${cardData.startTime} - ${cardData.endTime}`}</DataRowTitle>
        </div>
        <More>
          <DoubleArrowIconsLeft />
          <div> פרטים נוספים</div>
        </More>
        <Separator />
        <EmergencyLinearCard
          data={getLinearEmergencyData(cardData?.title)}
          liked={false}
          margin={"auto"}
          linkAddress={"פרטים נוספים"}
          isTasksVisable={true}
          link={`/${MAIN_ROUTES.PRIVATE_ZONE}/${zone}/linear-card-details`}
        />
      </div>
    );

    const MoreProps =
      isWideScreen || isMediumScreen
        ? {
            justifyContent: "end",
            isWideScreen: isWideScreen || isMediumScreen,
          }
        : undefined;

    return (
      <>
        <div style={{ paddingTop: "15px" }} />
        <DataRow>
          <DataRowTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: "15px", marginTop: "7.5px" }}>
                תיאור - Description
              </div>
              <RewardIcon>{`${cardData.rewardQuantity} נקודה`}</RewardIcon>
            </div>
          </DataRowTitle>
          <DataRowValue>{cardData.description}</DataRowValue>
        </DataRow>
        <>
          {showNewDesign ? (
            renderDescriptionSection()
          ) : (
            <>
              <More {...MoreProps}>
                <DoubleArrowIconsLeft
                  width={"12.055px"}
                  height={"9px"}
                  color={"#316788"}
                />
                פרטים נוספים
              </More>
              <Separator />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#316788",
                }}
              >
                <DataRowTitle>{`${startDateFormatted} ${
                  endDateFormatted !== startDateFormatted
                    ? `- ${endDateFormatted}`
                    : ""
                }`}</DataRowTitle>
                <DataRowTitle>{`${cardData.startTime} - ${cardData.endTime}`}</DataRowTitle>
              </div>
              <div
                style={{
                  color: "#316788",
                  marginLeft: "auto",
                  marginTop: "10px",
                }}
              >
                <DataRowTitle
                  justifyContent={
                    isWideScreen || isMediumScreen ? "end" : undefined
                  }
                >
                  {cardData.mainLocation}
                </DataRowTitle>
              </div>
            </>
          )}
        </>
      </>
    );
  };

  const renderWideScreenHeader = () => (
    <>
      <Header
        Icon={() =>
          getCurrentLogo(zone) ?? TogetherIcon({ width: "240", height: "60" })
        }
        title={ecosystem?.title}
        showLogoutIcon={false}
      />
      <ReturnArrow
        forceShowArrow
        onClickOverride={() => {
          navBack ? navigate(-1) : navigate(getZoneUrl(zone));
        }}
      />
    </>
  );

  const renderSmallScreenHeader = () => (
    <CoverPhoto
      image={getPhotoSrc(cardData?.photo)}
      logo={
        getCurrentLogo(zone) || TogetherIcon({ width: "240", height: "60" })
      }
      logoTitle={ecosystem?.title}
      logoRightButton={renderShareBtn(location, cardData.description)}
      onClick={() => {
        navBack ? navigate(-1) : navigate(getZoneUrl(zone));
      }}
    />
  );

  const renderContent = (location) => (
    <>
      {(isWideScreen || isMediumScreen) && renderWideScreenHeader()}
      <Wrapper isWideScreen={isWideScreen || isMediumScreen}>
        <WaitingList
          visible={waitingListVisible}
          hidePopUpfunc={() => setWaitingListVisible(false)}
          showPopFunc={() => setWaitingListVisible(true)}
          waitingList={waitingList}
          refetchWaitingList={refetchWaitingList}
          zone={zone}
          onUserClick={() => {
            setWaitingListVisible(false);
            setShowNumberPrompt(true);
          }}
        />
        <WaitingList
          visible={startWaitingListVisible}
          hidePopUpfunc={() => setStartWaitingListVisible(false)}
          showPopFunc={() => setStartWaitingListVisible(true)}
          waitingList={waitingList}
          refetchWaitingList={refetchWaitingList}
          zone={zone}
          onUserClick={(userName) => {
            setStartWaitingListVisible(false);
            setShowNumberPrompt(true);
            setVisitorName(userName ?? "-");
          }}
          isStartWaitingList
        />
        <Transportation
          visible={TransportationVisible}
          hidePopUpfunc={() => setTransportationVisible(false)}
        />
        {!(isWideScreen || isMediumScreen) && renderSmallScreenHeader()}
        <BigContainer
          isWideScreen={isWideScreen || isMediumScreen}
          width={isWideScreen || isMediumScreen ? "50%" : "100%"}
          padding="0 2%"
        >
          {(isWideScreen || isMediumScreen) && (
            <CoverPhotoWrapper>
              <CoverPhotoEl src={getPhotoSrc(cardData?.photo)} />
            </CoverPhotoWrapper>
          )}
          <HeaderSection>
            <div style={{ marginRight: "15px", marginTop: "-15px" }}>
              <Dropdown
                customIcon={<Options>...</Options>}
                wrapperPadding={0}
                options={getShareDeleteOptions(
                  isUserAuthorizedForAction(currentUser, data?.data?.user_id)
                    ? onEditClick
                    : () => {
                        if (!isAuthenticated) {
                          setLoginRoute(location.pathname);
                          navigate("/login");
                        }
                      },
                  isUserAuthorizedForAction(currentUser, data?.data?.user_id)
                    ? onDeleteClick
                    : () => {
                        if (!isAuthenticated) {
                          setLoginRoute(location.pathname);
                          navigate("/login");
                        }
                      },
                  () => {}
                ).slice(0, 2)}
              />
            </div>
            <div
              style={{
                display: isWideScreen || isMediumScreen ? "flex" : "block",
                width: isWideScreen || isMediumScreen ? "80px" : "30px",
                marginLeft: isWideScreen || isMediumScreen ? "10px" : "0",
                height: "30px",
              }}
              onClick={() => {
                if (!isAuthenticated) return;
                try {
                  if (cardData.isLiked) {
                    unlikeEntity(getEntityType(dataType), {
                      user_id: currentUser?._id,
                      [`${dataType}_id`]: id,
                    });
                    setCardData({ ...cardData, isLiked: false });
                    return;
                  }
                  likeEntity(getEntityType(dataType), {
                    user_id: currentUser?._id,
                    [`${dataType}_id`]: id,
                  });
                  setCardData({ ...cardData, isLiked: true });
                  return;
                } catch (er) {
                  console.error("Failed to like/unlike", er);
                }
              }}
            >
              {(isWideScreen || isMediumScreen) && renderShareBtn()}
              <Image src={!!cardData.isLiked ? heartLiked : heart} />
            </div>
            <Title>{cardData.title}</Title>
          </HeaderSection>
          {(isWideScreen || isMediumScreen) && renderDataSection()}
          {(isWideScreen || isMediumScreen) && renderButtonsSection()}
        </BigContainer>
        <Container isWideScreen={isWideScreen || isMediumScreen}>
          {!(isWideScreen || isMediumScreen) && renderDataSection()}
          <BigContainer
            width={(isWideScreen || isMediumScreen) && "100%"}
            padding={isWideScreen || isMediumScreen ? "4% 12%" : "0"}
            background={isWideScreen || isMediumScreen ? "#eef8ee" : "none"}
            borderRadius={isWideScreen || isMediumScreen ? "30px" : "0"}
          >
            {cardData.longitude !== "" && cardData.latitude !== "" && (
              <div
                style={{
                  borderRadius: "5.5px",
                  width: "100%",
                  objectFit: "cover",
                  margin: "15px auto",
                  overflow: "hidden",
                }}
              >
                <Map
                  disableClick
                  initialSelectedLocation={{
                    lng: Number(cardData.longitude),
                    lat: Number(cardData.latitude),
                    markerIcon: zone?.toLowerCase?.().includes("emergency")
                      ? MarkerIcon.Orange
                      : dataType === DataType.Task
                      ? MarkerIcon.Gray
                      : dataType === DataType.Activity ||
                        dataType === DataType.Event
                      ? MarkerIcon.Green
                      : MarkerIcon.Blue,
                  }}
                  height={isWideScreen || isMediumScreen ? "354px" : "140px"}
                  expandedHeight={"224px"}
                  expandOnFocus
                />
              </div>
            )}

            <MapIconsWrapper>
              <WazeButton
                latitude={cardData.latitude}
                longitude={cardData.longitude}
              />
              <GoogleMapsButton
                latitude={cardData.latitude}
                longitude={cardData.longitude}
              />
              <div
                style={{
                  height: "59px",
                  width: "69.674px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cfe4d0",
                  borderRadius: "5px",
                  fontWeight: "500",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => setTransportationVisible(true)}
              >
                <div style={{ width: "50.909px", height: "30px" }}>
                  <Image src={communityTransport} />
                </div>
                <CommunityTransportText>הסעות</CommunityTransportText>
                <CommunityTransportText>Transportation</CommunityTransportText>
              </div>
              <div
                style={{
                  height: "59px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(127, 179, 131, 0.8)",
                  borderRadius: "5px",
                  fontSize: "12px",
                  width: "93px",
                  fontWeight: "500",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => setWaitingListVisible(true)}
              >
                <div style={{ width: "24px", height: "30px" }}>
                  <Image src={waitingListIcon} />
                </div>
                נרשמו לפעילות
              </div>
            </MapIconsWrapper>
          </BigContainer>
          <BigContainer
            margin={!(isWideScreen || isMediumScreen) && "0"}
            padding={isWideScreen || isMediumScreen ? "4% 12%" : "0"}
            background={isWideScreen || isMediumScreen ? "#eef8ee" : "none"}
            borderRadius={isWideScreen || isMediumScreen ? "30px" : "0"}
          >
            {!(isWideScreen || isMediumScreen) && <Separator />}
            <DataRow>
              <DataRowTitle>
                אנשי קשר- Contacts <FaChevronDown />
              </DataRowTitle>
              <DataRowValue>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {cardData.contactName}
                  &nbsp; &nbsp; &nbsp;
                  {cardData.contactNumber}
                  &nbsp; &nbsp;
                </div>
                {renderContactIcons()}
              </DataRowValue>
            </DataRow>
            <Separator />
            <DataRow flexDirection={"row"} justifyContent="space-between">
              <DataRowTitle>נוסף על ידי - Added by</DataRowTitle>
              <DataRowValue>
                <p style={{ margin: 0 }}>{user?.name}</p>
              </DataRowValue>
            </DataRow>
            {!showNewDesign && (isWideScreen || isMediumScreen) && (
              <Separator />
            )}
            {showNewDesign && (
              <>
                <Separator />
                <DetailsOrganizations />
              </>
            )}
          </BigContainer>
          {!(isWideScreen || isMediumScreen) && renderButtonsSection()}
        </Container>
      </Wrapper>
    </>
  );

  if (isLoading && zone !== ZONES.VOLUNTEERING_PLEASURE) {
    return <LoadingSpinner center={true} text={"Loading"} />;
  }

  return (
    <div>
      {isAuthenticated && !userHasAccess && (
        <>
          <ReturnArrow
            forceShowArrow
            onClickOverride={() => {
              navBack ? navigate(-1) : navigate(getZoneUrl(zone));
            }}
          />
          <LoginAccessText noLogin viewText={"page"} />
        </>
      )}
      {!isAuthenticated && !userHasAccess && (
        <>
          <ReturnArrow
            forceShowArrow
            onClickOverride={() => {
              navBack ? navigate(-1) : navigate(getZoneUrl(zone));
            }}
          />
          <LoginAccessText
            afterLoginRoute={`/activities/${zone}/card-details/${dataType}/${id}`}
            viewText={"page"}
          />
        </>
      )}
      {userHasAccess && renderContent(location)}
    </div>
  );
};

export default CardDetailsPage;
