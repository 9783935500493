import { EmergencyZone, OrgZone, TaskZone } from "./useFetchTasks";
import useFetchEvents from "./useFetchEvents";

export const MainEmergencyZone = {
  Emergency: "Emergency",
  BrothersInArms: "BrothersInArms",
  KaplanForce: "KaplanForce",
};

export const MainOrgZone = {
  Taglit: "taglit",
  UnitedRescue: "UnitedRescue",
  JewishAgency: "JewishAgency",
  KKL: "KKL",
  BlueWhite: "BlueWhite",
  Vet: "vet",
  BestFriends: "BestFriends",
  ByBestFriends: "65adf777944824b0357f4e82",
  EmergencyAndRescue: "EmergencyAndRescue",
  "6655d5224b6e3b35f15097c0": "6655d5224b6e3b35f15097c0",
};

function useFetchEmergencyEvents(emergencyZone, config = {}) {
  const getEmergencyZones = () => {
    switch (emergencyZone) {
      case MainEmergencyZone.KaplanForce:
        return [
          EmergencyZone.KaplanForce1,
          EmergencyZone.KaplanForce2,
          EmergencyZone.KaplanForce3,
          EmergencyZone.KaplanForce4,
          EmergencyZone.KaplanForce5,
          EmergencyZone.KaplanForce5C,
          EmergencyZone.KaplanForce6,
          EmergencyZone.KaplanForce7,
        ];
      case MainEmergencyZone.BrothersInArms:
        return [
          EmergencyZone.BrothersInArms1,
          EmergencyZone.BrothersInArms2,
          EmergencyZone.BrothersInArms3,
          EmergencyZone.BrothersInArms4,
          EmergencyZone.BrothersInArms5,
          EmergencyZone.BrothersInArms5C,
          EmergencyZone.BrothersInArms6,
          EmergencyZone.BrothersInArms7,
          EmergencyZone.BrothersInArms8,
        ];
      case MainOrgZone.UnitedRescue:
        return [
          OrgZone.UnitedRescue1,
          OrgZone.UnitedRescue2,
          OrgZone.UnitedRescue3,
          OrgZone.UnitedRescue4,
          OrgZone.UnitedRescue5,
          OrgZone.UnitedRescue5C,
          OrgZone.UnitedRescue6,
          OrgZone.UnitedRescue7,
          OrgZone.UnitedRescue8,
          OrgZone.UnitedRescue9,
          OrgZone.UnitedRescue10,
        ];
      case MainOrgZone.JewishAgency:
        return [
          OrgZone.JewishAgency1,
          OrgZone.JewishAgency2,
          OrgZone.JewishAgency3,
          OrgZone.JewishAgency4,
          OrgZone.JewishAgency5,
          OrgZone.JewishAgency5C,
          OrgZone.JewishAgency6,
          OrgZone.JewishAgency7,
          OrgZone.JewishAgency8,
          OrgZone.JewishAgency9,
          OrgZone.JewishAgency10,
          OrgZone.JewishAgency11,
          OrgZone.JewishAgency12,
        ];
      case MainOrgZone.BlueWhite:
        return [
          OrgZone.BlueWhite1,
          OrgZone.BlueWhite2,
          OrgZone.BlueWhite3,
          OrgZone.BlueWhite4,
          OrgZone.BlueWhite5,
          OrgZone.BlueWhite6,
          OrgZone.BlueWhite7,
        ];
      case MainOrgZone.Vet:
        return [OrgZone.Vet1, OrgZone.Vet2, OrgZone.Vet3];
      case MainOrgZone.BestFriends:
        return [OrgZone.PetsFiends1];
      case MainOrgZone.MyBestFriends:
        return [OrgZone.MyBestFriends1];
      case MainOrgZone.KKL:
        return [
          OrgZone.KKL1,
          OrgZone.KKL2,
          OrgZone.KKL3,
          OrgZone.KKL4,
          OrgZone.KKL5,
          OrgZone.KKL5C,
          OrgZone.KKL6,
          OrgZone.KKL7,
          OrgZone.KKL8,
          OrgZone.KKL9,
          OrgZone.KKL10,
        ];
      case MainEmergencyZone.Emergency:
        // default:
        return [
          EmergencyZone.Emergency1,
          EmergencyZone.Emergency2,
          EmergencyZone.Emergency3,
          EmergencyZone.Emergency4,
          EmergencyZone.Emergency5,
          EmergencyZone.Emergency5C,
          EmergencyZone.Emergency6,
          EmergencyZone.Emergency7,
        ];
      default:
        return [];
    }
  };

  const zones = getEmergencyZones();

  const { fetchedEvents: emergencyTestEvents } = useFetchEvents(
    TaskZone.EmergencyTest,
    config
  );

  const { fetchedEvents: emergency1Events } = useFetchEvents(
    zones?.[0],
    config
  );
  const { fetchedEvents: emergency2Events } = useFetchEvents(
    zones?.[1],
    config
  );
  const { fetchedEvents: emergency3Events } = useFetchEvents(
    zones?.[2],
    config
  );
  const { fetchedEvents: emergency4Events } = useFetchEvents(
    zones?.[3],
    config
  );
  const { fetchedEvents: emergency5Events } = useFetchEvents(
    zones?.[4],
    config
  );
  const { fetchedEvents: emergency5CEvents } = useFetchEvents(
    zones?.[5],
    config
  );
  const { fetchedEvents: emergency6Events } = useFetchEvents(
    zones?.[6],
    config
  );
  const { fetchedEvents: emergency7Events } = useFetchEvents(
    zones?.[7],
    config
  );
  const { fetchedEvents: emergency8Events } = useFetchEvents(
    zones?.[8],
    config
  );
  const { fetchedEvents: emergency9Events } = useFetchEvents(
    zones?.[9],
    config
  );
  const { fetchedEvents: emergency10Events } = useFetchEvents(
    zones?.[(10, config)]
  );
  const { fetchedEvents: emergency11Events } = useFetchEvents(
    zones?.[(11, config)]
  );
  const { fetchedEvents: emergency12Events } = useFetchEvents(
    zones?.[(12, config)]
  );

  return {
    emergency1Events,
    emergency2Events,
    emergency3Events,
    emergency4Events,
    emergency5Events,
    emergency5CEvents,
    emergency6Events,
    emergency7Events,
    emergencyTestEvents,
    emergency8Events,
    emergency9Events,
    emergency10Events,
    emergency11Events,
    emergency12Events,
  };
}

export default useFetchEmergencyEvents;
