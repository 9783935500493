import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import styled from "styled-components";
import { shouldBlockMapRequest, getCityCoordinates } from "./MapUtils";
import { CircleSVGs, MarkerIcon, placesColors } from "./CircleConstants";

const LocationInput = styled.input`
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 45px;
  padding: 0 12px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  border: 1px solid #ef7300;
  text-overflow: ellipses;
  right: 10px;
  top: 60px;
  margin-bottom: 10px;
  width: 100%;
`;

export { MarkerIcon } from "./CircleConstants";

const TelAvivCenter = {
  lat: 32.0853,
  lng: 34.7818,
};

const libraries = ["places"];

const defaultMapOptions = {
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ],
};

const Map = ({
  onLocationSelect,
  height,
  minWidth,
  disableClick,
  initialSelectedLocation,
  selectedLocationName,
  setSelectedLocationName,
  expandOnFocus,
  expandedHeight,
  showSearch,
  events,
  borderRadius,
  centerCity,
  showMarkerForCenterCity,
  markers,
  placesType,
  onMarkerClick,
  defaultColor = MarkerIcon.Orange,
  zoom = 13,
}) => {
  const initialSelected = useMemo(() => {
    if (
      initialSelectedLocation == null ||
      isNaN(initialSelectedLocation?.lng) ||
      isNaN(initialSelectedLocation?.lat)
    )
      return null;
    // Ignore default value set in DB
    if (
      initialSelectedLocation?.lng === 31.2323 &&
      initialSelectedLocation?.lat === 32.51651
    ) {
      return TelAvivCenter;
    }
    return initialSelectedLocation;
  }, [initialSelectedLocation]);

  const [selectedLocation, setSelectedLocation] = useState(initialSelected);
  const [expanded, setExpanded] = useState(false);
  const [searchBox, setSearchBox] = useState(null);
  const [searchLocation, setSearchLocation] = useState(null);
  const [centerCityCoords, setCenterCityCoords] = useState();
  const [placesMarkers, setPlacesMarkers] = useState([]);

  const handleMapClick = (event) => {
    if (disableClick) {
      return;
    }
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setSelectedLocation({ lat, lng });
    onLocationSelect?.({ lat, lng });
  };

  const handlePlacesChanged = () => {
    const places = searchBox?.getPlaces?.();

    if (places?.length > 0) {
      setSelectedLocationName?.(places?.[0]?.formatted_address);
      const { lat, lng } = places?.[0]?.geometry?.location;
      const loc = { lat: lat(), lng: lng() };
      setSelectedLocation(loc);
      setSearchLocation(loc);
      onLocationSelect?.(loc);
    }
  };

  const onLoad = (searchBoxInstance) => {
    setSearchBox(searchBoxInstance);
  };

  const { isLoaded } = useJsApiLoader?.({
    id: "google-map-script",
    googleMapsApiKey: shouldBlockMapRequest()
      ? undefined
      : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "he",
    libraries,
  });

  const updateMapHeight = (expand) => {
    if (!expandOnFocus) {
      return;
    }
    if (expand) {
      setExpanded(true);
      return;
    }
    setExpanded(false);
  };

  const getIcon = (event) => {
    const icon = CircleSVGs[event?.markerIcon] ?? CircleSVGs[defaultColor];
    return icon;
  };

  useEffect(() => {
    if (!centerCity) return;
    getCityCoordinates(centerCity)
      ?.then((coords) => {
        setCenterCityCoords(coords);
      })
      .catch((er) => console.error("Failed to get city coordinates", er));
  }, [centerCity]);

  useEffect(() => {
    // Reset markers to an empty array if placesType is empty and there are existing markers
    if (placesType?.length === 0 && placesMarkers.length > 0) {
      setPlacesMarkers([]);
      return;
    }

    if (isLoaded && placesType?.length > 0 && selectedLocation) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      let accumulatedMarkers = [];

      // Collect all nearby search results using Promises
      const promises = placesType.map((type) => {
        return new Promise((resolve) => {
          const request = {
            location: selectedLocation,
            radius: "100000", // Search within 5km radius
            type: [type], // Handle each type individually
          };

          service.nearbySearch(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const markers = results.map((place) => ({
                ...place,
                id: place?.place_id,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                name: place.name,
                title: place.name,
                markerIcon: placesColors[type]?.color ?? defaultColor,
              }));
              accumulatedMarkers = [...accumulatedMarkers, ...markers];
            }
            resolve(); // Resolve the Promise once search for this type is done
          });
        });
      });

      // Once all searches are done, set the markers only once
      Promise.all(promises).then(() => {
        if (accumulatedMarkers.length > 0) {
          setPlacesMarkers(accumulatedMarkers);
        }
      });
    }
  }, [isLoaded, placesType, selectedLocation, placesMarkers.length]);

  const map = useMemo(
    () => (
      <GoogleMap
        mapContainerStyle={{
          ...(expandOnFocus && { height: expanded ? expandedHeight : height }),
          ...(!expandOnFocus && { height: height ?? "500px" }),
          width: "100%",
          minWidth: minWidth,
          transition: "0.2s",
        }}
        center={
          centerCityCoords || searchLocation || initialSelected || TelAvivCenter
        }
        zoom={zoom}
        onClick={handleMapClick}
        options={defaultMapOptions}
      >
        {selectedLocation && (
          <MarkerF
            position={selectedLocation}
            icon={getIcon(selectedLocation)}
          />
        )}
        {showMarkerForCenterCity && centerCityCoords && (
          <MarkerF
            position={centerCityCoords}
            icon={getIcon(selectedLocation)}
          />
        )}
        {events?.map((event) => (
          <MarkerF
            key={event._id}
            icon={getIcon(event)}
            onClick={event.onClick}
            position={
              Number(event.longitude) === 31.2323 &&
              Number(event.lattiude) === 32.51651
                ? TelAvivCenter
                : {
                    lat: Number(event.lattiude),
                    lng: Number(event.longitude),
                  }
            }
          />
        ))}
        {markers?.map((marker, index) => (
          <MarkerF
            key={marker._id ?? index}
            position={marker}
            icon={getIcon(marker)}
          />
        ))}

        {/* Render places (e.g., hospitals, civil defense) markers */}
        {placesMarkers?.map((marker, index) => (
          <MarkerF
            key={marker?.id}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={getIcon(marker)}
            title={marker.name}
            onClick={() => onMarkerClick(marker)}
          />
        ))}
      </GoogleMap>
    ),
    [
      events,
      expandOnFocus,
      expanded,
      expandedHeight,
      handleMapClick,
      height,
      initialSelected,
      searchLocation,
      selectedLocation,
      placesMarkers,
    ]
  );

  useEffect(() => {
    if (initialSelected) {
      setSelectedLocation(initialSelected);
    }
  }, [initialSelected]);

  return isLoaded ? (
    <div
      onFocus={() => (!expanded ? updateMapHeight(true) : undefined)}
      onBlur={() => (expanded ? updateMapHeight(false) : undefined)}
    >
      {showSearch && (
        <StandaloneSearchBox
          onLoad={onLoad}
          onPlacesChanged={handlePlacesChanged}
        >
          <LocationInput
            placeholder="הזנת כתובת"
            value={selectedLocationName ?? ""}
          />
        </StandaloneSearchBox>
      )}
      <div style={{ borderRadius: borderRadius, overflow: "hidden" }}>
        {shouldBlockMapRequest() ? (
          <div
            style={{
              textAlign: "center",
              color: "tomato",
              background: "rgba(0,0,0,0.05)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "25px 0",
            }}
          >
            <span>
              Map request blocked on dev env to save quota.
              <br />
              To enable it, modify:
            </span>
            <span>
              <code style={{ color: "darkgreen" }}>shouldBlockMapRequest</code>{" "}
              function in
              <code style={{ color: "darkgreen" }}> MapUtils.js</code>
            </span>
          </div>
        ) : (
          map
        )}
      </div>
    </div>
  ) : (
    <div>Loading</div>
  );
};

export default Map;
