import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../../store/languageContext";
import { LANGUAGES } from "../../../Common/Layout/Header/constant";

const Wrapper = styled.div`
  border-radius: ${({ isBtnOnlyDesign }) => (isBtnOnlyDesign ? "0" : "17.5px")};
  border: ${({ isBtnOnlyDesign }) =>
    isBtnOnlyDesign ? "none" : "1px solid rgba(0, 0, 0, 0.15)"};
  margin: ${({ isBtnOnlyDesign }) => (isBtnOnlyDesign ? "0" : "15px 18px")};
  padding: ${({ isBtnOnlyDesign }) =>
    isBtnOnlyDesign ? "10px 0" : "9px 15px"};
  position: relative;
  background-color: ${({ isBtnOnlyDesign }) =>
    isBtnOnlyDesign ? "transparent" : "white"};
  cursor: ${({ pointer }) => pointer && "pointer"};
`;

const Title = styled.div`
  color: #ef7300;
  font-size: 16px;
  margin: 14px 0;
  font-weight: 700;
  text-align: start;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  background: transparent;
  justify-content: space-between;
  direction: ${({ isHebrewLanguage }) => (isHebrewLanguage ? "rtl" : "ltr")};
  z-index: 0;
  gap: 10px;
`;

function Container({
  title,
  showForm,
  setShowForm,
  content,
  headerButton,
  isBtnOnlyDesign,
}) {
  const { currentLanguage } = useContext(LanguageContext);
  const handleShowForm = () => {
    !showForm && setShowForm(true);
  };

  const handleHideForm = () => {
    showForm && setShowForm(false);
  };

  return (
    <Wrapper
      onClick={handleShowForm}
      pointer={!showForm}
      isBtnOnlyDesign={isBtnOnlyDesign && !showForm}
    >
      <HeaderWrapper
        isHebrewLanguage={currentLanguage === LANGUAGES.HEBREW.value}
      >
        <Title onClick={handleHideForm}>{title}</Title>
        {headerButton && (!showForm || isBtnOnlyDesign) && headerButton}
      </HeaderWrapper>
      <div
        style={{
          height: showForm ? "auto" : 0,
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        {showForm && content && content}
      </div>
    </Wrapper>
  );
}

export default Container;
