import React, { useContext, useState } from "react";
import { UserContext } from "../../../../../store/userContext";
import PopUpModal from "../../../../../kit/PopUpModal/PopUpModal";
import styled from "styled-components";
import communityTransport from "../../../../../assets/images/Tasks/detailsPage/transportationbig.png";
import moovit from "../../../../../assets/images/Tasks/detailsPage/moovit.png";
import CustomRadioButton from "./components/CustomRadioButton/CustomRadioButton";

const RelativeContainer = styled.div`
  top: -15px;
  background-color: #fff;
`;
const SectionHeader = styled.div`
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
  justify-content: center;
  background: ${(props) => props.background ?? ""};
  color: #53575f;
  font-family: Assistant;
`;
const Container = styled.div`
  direction: rtl;
  padding: 10px;
  background: ${(props) => props.background ?? "rgba(127, 179, 131, 0.2)"};
`;
const Row = styled.div`
  color: var(--Title-dark, #53575f);
  text-align: center;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  padding: ${(props) => props.padding ?? "36px 0"};
  position: relative;
`;
const Separator = styled.div`
  border: 2px solid #7fb383;
  width: 96%;
  margin: 16px auto;
  background: #7fb383;
`;
const Text = styled.p`
  margin: 0;
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
`;

const UserInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 10px auto;
`;
const Information = styled.p`
  margin: 0;
  color: #696d72;
  font-family: Ploni 1.1 AAA;
  font-size: 13.72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.412px;
`;
const radioButtons = [
  { name: "option1", checked: false },
  { name: "option2", checked: false },
  { name: "option3", checked: false },
];
const Transportation = ({ visible, hidePopUpfunc }) => {
  const { currentUser } = useContext(UserContext);
  const [radionButtons, setRadioButtons] = useState(radioButtons);
  const handleRadioChange = (e) => {
    const name = e.target.name;
    setRadioButtons(
      [...radionButtons].map((r) => {
        if (r.name === name) return { ...r, checked: true };
        else return { ...r, checked: false };
      })
    );
  };
  return (
    <PopUpModal
      visible={visible}
      paddingPopUpDialog={"0"}
      hidePopUpfunc={() => hidePopUpfunc?.()}
      useNormalDialog
      background={"rgba(127, 179, 131, 0.2)"}
    >
      <RelativeContainer>
        <SectionHeader marginTop={0} background={"rgba(127, 179, 131, 0.2)"}>
          <img src={communityTransport} width="67px" height="53px" />
          Transportation - הסעות
        </SectionHeader>
        <Container background={"#FAFFFA"}>
          <Row>
            <IconWrapper>
              <CustomRadioButton
                name="option1"
                checked={radionButtons[0].checked}
                onChange={handleRadioChange}
                label=""
              />
            </IconWrapper>

            <Text>אגיע עצמאית</Text>
          </Row>
          <Separator />
          <Row>
            <IconWrapper>
              <CustomRadioButton
                name="option2"
                checked={radionButtons[1].checked}
                onChange={handleRadioChange}
                label=""
              />
            </IconWrapper>

            <Text>בקשה להצטרפות לנסיעה משותפת</Text>
          </Row>
          <Separator />
          <Row padding={"15px 0"}>
            <IconWrapper>
              <CustomRadioButton
                name="option3"
                checked={radionButtons[2].checked}
                onChange={handleRadioChange}
                label=""
              />
            </IconWrapper>

            <Text>איש קשר בהקשר להסעות:</Text>
            <UserInformation>
              <Information>{currentUser?.name}</Information>
              <Information>{currentUser?.phone}</Information>
            </UserInformation>
          </Row>
        </Container>
        <SectionHeader marginTop={0} background={"rgba(127, 179, 131, 0.2)"}>
          <img src={moovit} />
          מעבר לאפליקצית
        </SectionHeader>
      </RelativeContainer>
    </PopUpModal>
  );
};

export default Transportation;
