import React from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import { CameraIcon, MicrophoneIconV2 } from "../../../../../assets/icons";
import useCurrentUser from "../../../../../customHooks/useCurrentUser";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px 0 12px;
  box-sizing: border-box;
  height: 60px;
  position: fixed;
  left: 0;
  bottom: ${({ hasNavbar }) => (hasNavbar ? "75px" : 0)};
  width: 100%;
  background-color: #f9f9f9; /* Adjust background color as needed */
  z-index: 1000; /* Ensure it's above other content */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for separation */
`;

const ClickableWrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  border-radius: 4.074px;
  background: #fff;
  box-shadow: 0px 1.63px 1.63px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 28px;
`;

const BottomForm = ({ onClick }) => {
  const { isAuthenticated } = useCurrentUser();

  return (
    <Wrapper hasNavbar={isAuthenticated}>
      <ClickableWrapper onClick={onClick}>
        <TextField
          sx={{
            width: "100%",
            height: 32,
            "& .MuiOutlinedInput-root": {
              background: "#fff",
              border: "1.38px solid #7FB383",
              borderRadius: "20px",
              "&.Mui-focused fieldset": {
                border: 0,
              },
            },
          }}
          InputProps={{
            sx: {
              "&.MuiInputBase-root": {
                height: 32,
                backgroundColor: "#fff",
              },
            },
          }}
          inputProps={{ readOnly: true }} // This makes the text field read-only
        />
      </ClickableWrapper>
      <IconWrapper>
        <CameraIcon />
      </IconWrapper>
      <IconWrapper>
        <MicrophoneIconV2 />
      </IconWrapper>
    </Wrapper>
  );
};

export default BottomForm;
