export const ROUTES = {
  TEL_AVIV: "tel-aviv",
  GDEROT: "gderot",
  NOGA: "noga",
  IMAOT_RAMAT_AVIV: "imaot-ramat-aviv",
  IMAOT_YAD_ELIHAO: "imaot-yad-elihao",
  RAMAT_HASHARON: "ramat-hasharon",
};

export const CITIES = [
  {
    name: "תל אביב",
    route: `/activities/together/${ROUTES.TEL_AVIV}`,
  },
  {
    name: " ישובי גדרות",
    route: `/activities/together/${ROUTES.GDEROT}`,
  },
  {
    name: "הוד השרון ",
    route: null,
  },
  {
    name: " הרצליה",
    route: null,
  },
  {
    name: "רמת השרון ",
    route: null,
  },
  {
    name: " כפר סבא",
    route: null,
  },
];

export const CITIES_IN_Heb = [
  {
    name: "ישובי גדרות",
    route: `/activities/together/${ROUTES.GDEROT}`,
  },
  {
    name: "הוד השרון ",
    route: null,
  },
  {
    name: "הרצליה",
    route: null,
  },
  {
    name: "רמת השרון ",
    route: null,
  },
  {
    name: "כפר סבא",
    route: null,
  },
  {
    name: "תל אביב",
    route: `/activities/together/${ROUTES.TEL_AVIV}`,
  },
];

export const Noga = [
  {
    name: "Volunteer Ecosystem - ISR",
    route: `/activities/together/${ROUTES.NOGA}`,
    value: ROUTES.NOGA,
  },
];
