import React from "react";

function MessagesFill() {
  return (
    <svg
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_21_12)">
        <path
          d="M8.49782 9.69567C8.5069 9.71158 8.51372 9.72899 8.52584 9.74187C8.66744 9.89104 8.90143 10.0251 8.92566 10.1909C8.9514 10.3681 8.65987 10.4696 8.55385 10.646C8.06543 11.1019 7.59292 11.5744 7.13706 12.0628C6.72437 12.4437 6.32757 12.8405 5.94744 13.2539C5.87096 13.2865 5.83082 13.3433 5.83007 13.4266C5.46811 13.7825 5.10312 14.1354 4.74419 14.4935C3.31679 15.9187 1.89092 17.3461 0.464279 18.7727C0.252252 18.9847 0.242408 18.9802 0.138666 18.697C0.097018 18.5826 0.0939891 18.4524 9.15527e-05 18.3592V2.04C0.10459 1.94231 0.113677 1.79844 0.147753 1.67728C0.212118 1.45011 0.312831 1.50084 0.440047 1.60156C0.483967 1.63639 0.519557 1.68107 0.559691 1.7212C2.23546 3.39697 3.92107 5.06365 5.58094 6.75456C6.07996 7.26342 6.5608 7.79046 7.08027 8.27888C7.53613 8.7673 8.00864 9.23982 8.49706 9.69567H8.49782Z"
          fill="#158796"
        />
        <path
          d="M24.4172 3.34092C23.9424 3.79375 23.4994 4.27763 23.0383 4.74333C20.4493 7.35958 17.8414 9.95691 15.2342 12.555C14.9608 12.8268 14.6428 13.0131 14.221 12.8677C14.0847 12.8208 13.9742 12.7579 13.875 12.6587C11.373 10.1553 8.87112 7.65264 6.36769 5.15072C5.89291 4.67669 5.41282 4.20872 4.93576 3.73771C4.49807 3.26595 4.04297 2.81237 3.57197 2.37393C2.86093 1.68711 2.14988 1.0003 1.41309 0.289252C1.83562 0.0658667 2.21424 0.0045304 2.59211 0.0045304C10.484 -0.00152751 18.3752 -0.00304198 26.2672 0.00907383C26.67 0.00907383 27.0971 0.0181607 27.5492 0.301368C26.7995 1.01999 26.0635 1.72573 25.3282 2.43072C25.0086 2.71847 24.705 3.02137 24.418 3.34168L24.4172 3.34092Z"
          fill="#158796"
        />
        <path
          d="M25.441 18.0821C26.0703 18.7182 26.698 19.3565 27.3319 19.9888C27.4666 20.1236 27.5189 20.1706 27.2766 20.2569C27.0093 20.3523 26.7495 20.4 26.4709 20.4C18.4025 20.3985 10.3341 20.3985 2.26652 20.4C2.01209 20.4 1.78643 20.2978 1.5585 20.2137C1.41538 20.1607 1.53503 20.0857 1.58576 20.035C2.10523 19.5133 2.62621 18.9938 3.14567 18.4721C3.29106 18.3259 3.43115 18.1745 3.57351 18.0253C4.02482 17.6058 4.46175 17.1704 4.8805 16.7176C5.2826 16.3268 5.65667 15.9028 6.06179 15.5211C7.17948 14.4693 8.23052 13.3531 9.33155 12.2854C9.57538 12.0492 9.82072 11.8137 10.0487 11.563C10.1698 11.429 10.2387 11.4396 10.3561 11.5661C10.656 11.8902 10.971 12.1999 11.2807 12.5156C11.258 12.6444 11.3382 12.7208 11.417 12.8041C11.7373 13.1419 12.1038 13.4364 12.3779 13.8181C12.4582 13.9294 12.5733 13.9521 12.7013 13.9362C13.1465 14.3232 13.6736 14.5519 14.2491 14.6071C14.7428 14.6548 15.2282 14.5178 15.6916 14.333C15.7128 14.3179 15.7332 14.3027 15.7544 14.2876C15.943 14.1505 16.1308 14.0135 16.3193 13.8756C16.4988 14.0172 16.6124 13.8605 16.6896 13.7575C16.9017 13.4766 17.1864 13.2661 17.3976 12.9919C17.54 12.8064 17.7869 12.6807 17.7929 12.4021C18.0693 12.1196 18.3518 11.8432 18.6191 11.5524C18.7501 11.4101 18.8349 11.4305 18.9606 11.5585C19.5899 12.1976 20.2274 12.8291 20.862 13.4629C21.5496 14.1505 22.2515 14.8252 22.9209 15.5302C23.4321 16.0678 23.9349 16.6123 24.4793 17.1166L25.4425 18.0798L25.441 18.0821Z"
          fill="#158796"
        />
        <path
          d="M22.3893 7.76471C22.799 7.36186 23.2109 6.96128 23.6176 6.5554C25.2411 4.93416 26.8631 3.3114 28.4858 1.68939C28.7357 1.4395 28.7274 1.44556 28.8569 1.7901C28.9902 2.14449 29.003 2.51932 29.0038 2.87977C29.0167 7.76168 29.0167 12.6428 29.0046 17.5248C29.0038 17.9049 28.9796 18.2964 28.8448 18.6667C28.741 18.9529 28.7365 18.9597 28.5298 18.753C26.9736 17.1976 25.419 15.6407 23.8622 14.0861C23.3737 13.5985 22.88 13.1169 22.3886 12.6322C21.9509 12.1605 21.4958 11.7069 21.0248 11.2685C20.7219 10.9587 20.4243 10.6445 20.1138 10.3431C20.0002 10.2333 19.9912 10.1712 20.11 10.0569C20.4417 9.73656 20.7582 9.4011 21.0816 9.07171C21.5329 8.6522 21.9698 8.21678 22.3886 7.76396L22.3893 7.76471Z"
          fill="#158796"
        />
        <path
          d="M17.7906 12.4028C17.7846 12.6815 17.5385 12.8072 17.3954 12.9927C17.1841 13.2668 16.8994 13.4773 16.6873 13.7583C16.6101 13.8612 16.4965 14.018 16.317 13.8764C16.7918 13.369 17.2833 12.8776 17.7906 12.4028Z"
          fill="#158796"
        />
        <path
          d="M12.6997 13.9362C12.5718 13.9521 12.4559 13.9302 12.3764 13.8181C12.1023 13.4364 11.7358 13.1426 11.4155 12.8041C11.3367 12.7209 11.2564 12.6436 11.2791 12.5156C11.7691 12.973 12.2424 13.4463 12.6997 13.9362Z"
          fill="#158796"
        />
        <path
          d="M15.7529 14.2876C15.7317 14.3027 15.7113 14.3178 15.6901 14.333C15.6992 14.3019 15.7128 14.2762 15.7529 14.2876Z"
          fill="#26BEB7"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_12">
          <rect width="29.0143" height="20.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MessagesFill;
