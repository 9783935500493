import React from "react";

const OrangeMapIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="35"
      fill="none"
      viewBox="0 0 24 35"
    >
      <g fill="#EF7300" clipPath="url(#clip0_1602_22251)">
        <path d="M12.865.612c.791.151 1.595.258 2.372.462 2.075.547 3.848 1.635 5.342 3.166 1.603 1.642 2.668 3.59 3.142 5.839.651 3.097.172 6.026-1.447 8.746-3.042 5.109-6.102 10.207-9.157 15.31-.51.852-1.375.857-1.9.01-3.13-5.043-6.259-10.087-9.383-15.134-.99-1.599-1.602-3.33-1.77-5.21-.006-.061-.041-.12-.062-.182v-1.867c.088-.546.152-1.097.267-1.638a11.772 11.772 0 013.147-5.873C4.91 2.71 6.684 1.621 8.76 1.075c.778-.205 1.58-.311 2.373-.462h1.733zm-.72 31.188c.045-.059.06-.075.072-.094 2.797-4.674 5.602-9.343 8.387-14.025 1.136-1.91 1.572-3.992 1.318-6.202-.216-1.882-.91-3.582-2.1-5.062-1.728-2.15-3.965-3.424-6.706-3.732-2.689-.303-5.13.385-7.261 2.061-2.187 1.72-3.456 3.985-3.785 6.752-.282 2.371.29 4.551 1.547 6.571 2.778 4.466 5.545 8.939 8.318 13.408.063.102.13.201.21.323z"></path>
        <path d="M11.989 18.62a5.981 5.981 0 01-5.987-6.02 5.995 5.995 0 016.026-5.985c3.313.008 5.98 2.707 5.97 6.042a5.979 5.979 0 01-6.01 5.963zm-3.99-6.017c-.004 2.21 1.784 4.014 3.982 4.018a4.01 4.01 0 004.017-3.986c.01-2.198-1.796-4.02-3.985-4.023-2.2-.003-4.011 1.798-4.015 3.991z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1602_22251">
          <path
            fill="#fff"
            d="M0 0H24V34.164H0z"
            transform="translate(0 .612)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default OrangeMapIcon;
