import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import ZoneButton, {
  Wrapper as HeaderButtonStyled,
} from "./components/ZoneButton";
import DropDownOptions from "../../../Ecosystem/Zone/component/DropDownOptions";
import { UserContext } from "../../../../store/userContext";
import { Link, useNavigate } from "react-router-dom";
import EarthIcon from "../../../../assets/icons/EarthIcon";
import PersonIcon from "../../../../assets/icons/PersonIcon";
import { LANGUAGES } from "./constant";
import { LanguageContext } from "../../../../store/languageContext";
import NavbarTogglerIcon from "../../../../assets/icons/NavbarTogglerIcon";
import { Cards } from "../../../Home/constant";
import ShareButton from "../../../../kit/Share/ShareButton";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import { CustomNavBar } from "../../../../assets/icons/Common/NavBar";
import NavBar from "./components/NavBar/NavBar";
import LogoutIcon from "../../../../assets/icons/Common/NavBar/LogoutIcon";
import useCustomAppWidth from "../../../../customHooks/useCustomAppWidth";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import ReturnArrow from "../ReturnArrow/ReturnArrow";

const Wrapper = styled.div`
  position: ${(props) => props.position ?? "relative"};
  padding: ${(props) => props.padding ?? "0"};
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`;
const TitlesWrapper = styled(Link)`
  text-decoration: none;
`;
const IconWrapper = styled.div`
  text-align: center;
`;
const Zones = styled.div`
  position: relative;
  align-items: start;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 1px;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.rtl ? "row-reverse" : "row")};
  margin: ${(props) => props.margin ?? "0px auto 10px"};

  @media (min-width: 768px) {
    position: absolute;
    top: 28px;
    left: 90px;
  }
`;
const Categories = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 4px;
`;
const LogoutIconWrapper = styled.div`
  position: absolute;
  left: 18px;
  top: ${({ isWideScreen }) => (isWideScreen ? "30px" : "35px")};
  width: ${({ isWideScreen }) => (isWideScreen ? "27px" : "20px")};
  height: ${({ isWideScreen }) => (isWideScreen ? "34px" : "20px")};
  cursor: pointer;
`;
const LogoutImage = styled.img`
  width: 100%;
  height: 100%;
`;
const EarthIconWrapper = styled.div`
  position: absolute;
  left: ${(props) => (props.titlesLang === "heb" ? "8px" : "8px")};
  right: ${(props) => (props.titlesLang === "heb" ? "auto" : "auto")};
  top: ${(props) => (props.showLogoutIcon ? "25px" : "7px")};
  width: 35px;
  height: 35px;
`;
const PersonIconWrapper = styled.div`
  position: absolute;
  top: ${(props) => (props.showLogoutIcon ? "25px" : "7px")};
  left: ${(props) => (props.titlesLang === "heb" ? "auto" : "auto")};
  right: ${(props) => (props.titlesLang === "heb" ? "0" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isPersonIconClicked ? "57.143px" : "45px")};
  height: ${(props) => (props.isPersonIconClicked ? "35px" : "27.563px")};
  border-radius: ${(props) =>
    props.titlesLang === "heb"
      ? "16.313px 0px 0px 16.313px"
      : "16.313px 0px 0px 16.313px"};
  background: ${(props) => props.personIconColor ?? "#28b5b5"};
`;
const LanguagesWrapper = styled.div`
  position: absolute;
  z-index: 1900;
  top: 60px;
  left: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(40, 181, 181, 0.1);
`;
const Language = styled.p`
  margin: 0;
  padding: 5px;
  border-bottom: ${(props) =>
    props.langCode === "both" ? "none" : "1px solid #bfdde5"};
  color: ${(props) => (props.isActive ? "#EF7300" : "#003f5d")};
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.989px; /* 111.702% */
  letter-spacing: 0.17px;
  text-transform: uppercase;
  cursor: pointer;
`;
const ShareButtonWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: end;
  align-items: end;
`;
const CloseIconWrapper = styled.div``;
const NavBarAndNavIconContainer = styled.div`
  position: absolute;
  top: ${(props) =>
    props.top
      ? props.top
      : props.hasZones
      ? props.isWide
        ? "70px"
        : "60px"
      : props.isWide
      ? "80px"
      : "60px"};
  right: 0;
  z-index: 1800;
`;
const NavBarContainer = styled.div`
  position: absolute;
  right: ${({ isWideScreen }) => (isWideScreen ? "5px" : "0")};
  top: 50px;
  width: 250px;
  z-index: 1900;
`;
const ImageWrapper = styled.div`
  width: 70%;
  margin: 10px auto;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const LogoIconWrapper = styled.div`
  height: 72px;
  display: flex;
  justify-content: center;
`;
const LogoWrapper = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
`;
const IconTitle = styled.div`
  position: absolute;
  top: ${(props) => props.top ?? "40px"};
  right: ${(props) => props.left ?? "-50px"};
  color: #7fb383;
  text-align: left;
  font-family: Assistant;
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  max-width: 180px;
`;
function Page({
  title,
  sideTitle,
  titlePos,
  padding,
  zones,
  // { text: string, onClick: () => void, color?: string}
  headerButtons,
  headerButtonsMargin,
  titlesLang,
  links,
  link,
  sideTitleColor,
  sideTitleBorderColor,
  sideTitleBackGround,
  sideTitleFontSize,
  sideTitlePadding,
  hasSideTitleUnderline,
  btnsColor,
  hasPersonIcon = false,
  hasEarth = false,
  dropDownMargin,
  fontWeight,
  fontColor,
  fontFamily,
  fontSize,
  ZoneBorderRadius,
  Icon,
  personIconColor,
  showLogoutIcon = true,
  hasLogoutIcon = false,
  handlePersonIconClicked,
  hasLeftArrow,
  zoneMarginLeft,
  showNavbarTogglerIcon = false,
  showNavbar = true,
  titleAsImage,
  iconWidth,
  iconHeight,
  currentLanguageProps,
  position,
  navbarTopBtnFill,
  navbarBottomBtnFill,
  navbarTop,
  bottomComponent,
}) {
  useCustomAppWidth();
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { isAuthenticated, setCurrentUser, setIsAuthenticated } =
    useContext(UserContext);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const [isShareButtonVisible, setIsShareButtonVisable] = useState(true);
  const [areLanguagesVisible, setAreLanguagesVisible] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(currentLanguage);
  const [isPersonIconClicked, setIsPersonIconClicked] = useState(false);
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const navbarIconRef = useRef(null);
  const navbarContentRef = useRef(null);
  const navigate = useNavigate();
  const chooseLanguage = (targetLanguage) => {
    setActiveLanguage(targetLanguage);
    setCurrentLanguage(targetLanguage);
  };
  const handleLogout = () => {
    if (hasLogoutIcon) {
      setCurrentUser({});
      setIsAuthenticated(false);
      localStorage.removeItem("access_token");
    }
    navigate("/");
  };
  const handlePersonIcon_Clicked = () => {
    setIsPersonIconClicked(!isPersonIconClicked);
    handlePersonIconClicked && handlePersonIconClicked(Cards.User);
  };
  const handleShareButtonClicked = () => {
    setIsShareButtonVisable(!isShareButtonVisible);
  };
  const handleNavbarClick = (e) => setIsNavBarVisible(!isNavBarVisible);
  const handleClickOutside = (event) => {
    const { target } = event;
    if (
      navbarContentRef.current &&
      !navbarContentRef.current.contains(target) &&
      navbarIconRef.current &&
      !navbarIconRef.current.contains(target)
    ) {
      setIsNavBarVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [navbarContentRef, navbarIconRef]);
  return (
    <Wrapper position={position} padding={padding}>
      <TitlesWrapper to={link ? link : null}>
        {Icon && (
          <LogoIconWrapper>
            <LogoWrapper>
              <Icon width={iconWidth} height={iconHeight} />
              <IconTitle
                top={titlePos?.top}
                left={titlePos?.left}
                fontSize={titlePos?.fontSize}
              >
                {title}
              </IconTitle>
            </LogoWrapper>
          </LogoIconWrapper>
        )}
        {titleAsImage && (
          <ImageWrapper>
            <Image src={titleAsImage} />
          </ImageWrapper>
        )}
      </TitlesWrapper>
      {!hasPersonIcon && !hasEarth && showNavbar && (
        <NavBarAndNavIconContainer
          hasZones={zones}
          isWide={isMediumScreen || isWideScreen}
          top={navbarTop}
        >
          <IconWrapper ref={navbarIconRef} onClick={handleNavbarClick}>
            <CustomNavBar
              topBtnFill={navbarTopBtnFill}
              bottomBtnFill={navbarBottomBtnFill}
            />
          </IconWrapper>
          <NavBarContainer
            ref={navbarContentRef}
            isWideScreen={isWideScreen || isMediumScreen}
          >
            {isNavBarVisible && <NavBar />}
          </NavBarContainer>
        </NavBarAndNavIconContainer>
      )}
      {showLogoutIcon && (
        <LogoutIconWrapper
          onClick={handleLogout}
          isWideScreen={isWideScreen || isMediumScreen}
        >
          {hasLogoutIcon ? (
            <LogoutIcon
              color={"#003F5D"}
              width={isWideScreen || isMediumScreen ? "40" : "30"}
              height={isWideScreen || isMediumScreen ? "53" : "37"}
            />
          ) : (
            <ReturnArrow marginTop={"15px"} />
          )}
        </LogoutIconWrapper>
      )}
      {hasPersonIcon && (
        <PersonIconWrapper
          personIconColor={personIconColor}
          titlesLang={titlesLang}
          // isPersonIconClicked={isPersonIconClicked}
          onClick={handlePersonIcon_Clicked}
          showLogoutIcon={showLogoutIcon}
        >
          <PersonIcon />
        </PersonIconWrapper>
      )}
      {hasEarth && (
        <EarthIconWrapper
          showLogoutIcon={showLogoutIcon}
          titlesLang={titlesLang}
          onClick={() => setAreLanguagesVisible(!areLanguagesVisible)}
        >
          <EarthIcon width="35" height="35" />
        </EarthIconWrapper>
      )}
      {areLanguagesVisible && (
        <LanguagesWrapper>
          {Object.values(LANGUAGES).map((language) => (
            <Language
              langCode={language.key}
              isActive={language.key === activeLanguage}
              onClick={() => chooseLanguage(language.key)}
            >
              {language.value}
            </Language>
          ))}
        </LanguagesWrapper>
      )}
      {bottomComponent && bottomComponent}
      {(zones || sideTitle) && (
        <Zones
          rtl={titlesLang === "heb"}
          margin={headerButtonsMargin}
          dropDownMargin={dropDownMargin}
        >
          {showNavbar ? (
            <div></div>
          ) : sideTitle ? (
            <DropDownOptions
              links={links || []}
              language={titlesLang}
              title={sideTitle}
              backGround={sideTitleBackGround}
              borderColor={sideTitleBorderColor}
              hasUnderline={hasSideTitleUnderline}
              fontSize={sideTitleFontSize}
              color={sideTitleColor}
              dropDownMargin={dropDownMargin}
              borderRadius={"10.077px"}
              hasLeftArrow={hasLeftArrow}
              padding={sideTitlePadding}
            />
          ) : showNavbarTogglerIcon && isShareButtonVisible ? (
            <CloseIconWrapper onClick={handleShareButtonClicked}>
              <NavbarTogglerIcon />
            </CloseIconWrapper>
          ) : !isShareButtonVisible ? (
            <ShareButtonWrapper>
              <CloseIconWrapper onClick={handleShareButtonClicked}>
                <CloseIcon color={"black"} />
              </CloseIconWrapper>
              <ShareButton showShareButton />
            </ShareButtonWrapper>
          ) : (
            <div></div>
          )}
          <Categories>
            {zones?.map((zone) => (
              <ZoneButton
                name={zone.name}
                nameInOtherLang={
                  zone.nameInOtherLang ? zone.nameInOtherLang : ""
                }
                to={zone.to}
                isSelected={zone.isSelected}
                color={btnsColor ? btnsColor : "#7FB383"}
                borderRadius={ZoneBorderRadius}
                fontWeight={fontWeight}
                fontColor={fontColor}
                fontSize={fontSize}
                fontFamily={fontFamily}
                marginLeft={zoneMarginLeft}
                currentLanguageProps={currentLanguageProps}
                {...zone}
              />
            ))}
            {headerButtons?.map((btn) => (
              <HeaderButtonStyled
                isWideScreen={isWideScreen || isMediumScreen}
                isSelected={false}
                color={btn.color ?? "#7FB383"}
                minWidth={"40px"}
                onClick={btn.onClick}
              >
                {btn.title}
              </HeaderButtonStyled>
            ))}
          </Categories>
        </Zones>
      )}
    </Wrapper>
  );
}
export default Page;
