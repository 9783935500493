import { styled } from "@mui/material";

export const ContainerBody = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  padding: props?.padding,
  gap: props?.gap,
}));
export const FlexRow = styled("div")((props) => ({
  display: "flex",
  flexDirection: "row",
  gap: props?.gap,
  alignContent: props?.alignContent,
  alignItems: props?.alignItems,
  justifyContent: props?.justifyContent,
  flex: props?.flex,
  ...(props?.sx ?? {}),
}));
export const FlexColumn = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: props?.gap,
  justifyContent: props?.justifyContent,
  alignItems: props?.alignItems,
  ...(props?.sx ?? {}),
}));
export const Relative = styled("div")((props) => ({
  position: "relative",
  overflow: props.overflow,
}));
export const DoubleBorder = styled("div")((props) => ({
  border: props.border ?? "4px solid #EF7300",
  borderColor: props.borderColor,
  borderRadius: "10px",
  backgroundColor: "white",
  padding: "3px",
  width: "100%",
  boxSizing: "border-box",
  cursor: "pointer",
  ...(props?.sx ?? {}),
}));

export const ButtonDoubleBorder = styled("div")((props) => ({
  //border: "2px solid #FFFFFF",
}));
