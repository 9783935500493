import React, { useContext, useState } from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../../../store/languageContext";

const Wrapper = styled.div`
  border-radius: 17.5px;
  background-color: ${(props) =>
    props?.background ?? `rgba(101, 201, 101, ${props?.focused ? 0.3 : 0.15})`};
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 6.5px 11px;
  width: calc(100% - 22px);
  direction: ${(props) => (props.language === "hebrew" ? "rtl" : "ltr")};
  max-width: ${(props) => props.maxWidth ?? "calc(100% - 22px)"};
  transition: 0.2s;
`;

const Label = styled.label`
  color: ${(props) => props.labelColor ?? "#1e9898"};
  font-size: ${(props) => props.labelFontSize ?? "10px"};
  font-weight: 700;
  text-decoration: ${(props) => props.textDecoration ?? "none"};
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  min-height: ${(props) =>
    props.minHeight || "90px"}; // Use minHeight prop or default to 90px
  color: ${(props) => props.color ?? "#1e9898"};
  font-size: ${(props) => props.fontSize ?? "10px"};
  &::placeholder {
    font-size: ${(props) => props.placeholderStyle?.fontSize};
    color: ${(props) => props.placeholderStyle?.color};
    font-family: "Assistant";
    font-weight: 600;
    text-decoration: ${(props) =>
      props.placeholderStyle?.textDecoration ?? "none"};
  }
`;

const TextareaWrapper = (props) => {
  const {
    label,
    minHeight,
    background,
    labelColor,
    labelFontSize,
    register,
    required,
    language,
    placeholder,
    placeholderStyle,
    fontSize,
    color,
    maxWidth,
    textDecoration,
  } = props;

  const { currentLanguage } = useContext(LanguageContext);
  const [focused, setFocused] = useState(false);

  return (
    <Wrapper
      language={language ?? currentLanguage}
      maxWidth={maxWidth}
      background={background}
      focused={focused}
    >
      {label && (
        <Label
          labelColor={labelColor}
          labelFontSize={labelFontSize}
          textDecoration={textDecoration}
        >
          {label}
        </Label>
      )}

      <Textarea
        type="text"
        placeholder={placeholder}
        placeholderStyle={placeholderStyle}
        fontSize={fontSize}
        color={color}
        minHeight={minHeight}
        {...register}
        required={required}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </Wrapper>
  );
};

export default TextareaWrapper;
