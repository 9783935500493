import React from "react";

function MicrophoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={18}
      fill="none"
      viewBox="0 0 11 18"
    >
      <g fill="#003F5D" clipPath="url(#a)">
        <path d="M2.827 6.25V3.357c.003-1.53.98-2.674 2.487-2.92 1.449-.235 2.97.884 3.175 2.34.022.152.043.305.043.458.003 2.003.011 4.006-.001 6.01-.009 1.387-.97 2.518-2.332 2.783-1.692.33-3.333-.978-3.366-2.705-.02-1.024-.003-2.047-.003-3.07h-.003V6.25Zm4.092-.514v-.015c.146 0 .293-.005.439.002.097.005.127-.031.125-.126a13.874 13.874 0 0 1 0-.702c.003-.103-.025-.14-.133-.136-.197.008-.395.002-.592.002-.41 0-.415 0-.408-.41.004-.21-.094-.489.042-.608.116-.104.39-.033.593-.035.341 0 .506-.174.495-.518-.04-1.167-1.211-1.995-2.326-1.643-.871.275-1.387 1.113-1.276 2.057.013.114.071.104.145.104.285 0 .57.003.856-.002.104-.002.14.03.137.137-.006.256-.01.512 0 .768.006.135-.051.156-.167.152-.278-.006-.556.002-.834-.004-.103-.003-.14.024-.137.133.008.226.01.453 0 .68-.006.136.053.154.167.151.278-.007.557.002.834-.004.106-.002.14.033.138.138-.007.256-.007.512 0 .768.003.113-.031.155-.149.152-.278-.008-.556.004-.834-.006-.127-.004-.16.038-.156.16.01.219.008.439 0 .658-.004.113.03.153.147.15.27-.008.542.006.811-.006.153-.007.186.049.18.188-.01.24-.008.483 0 .723.004.119-.04.153-.152.149-.175-.008-.35-.002-.526-.002-.31 0-.462.155-.456.467a1.797 1.797 0 0 0 2.408 1.657c.744-.268 1.278-.96 1.194-2.027-.009-.121-.08-.097-.149-.097-.277-.001-.556-.004-.833.002-.108.002-.16-.021-.156-.145a11.49 11.49 0 0 0 0-.768c-.004-.121.045-.148.154-.144.226.006.453.002.68.002.3 0 .306 0 .299-.298-.005-.217.095-.506-.04-.63-.116-.108-.402-.033-.613-.034-.478-.002-.482 0-.476-.475.002-.188-.084-.438.04-.545.102-.09.347-.019.528-.019Z" />
        <path d="M5.669 17.893c-.717 0-1.433-.004-2.15.003-.133.002-.176-.03-.17-.167a8.8 8.8 0 0 0 .001-.746c-.004-.117.034-.148.148-.146.497.006.995-.003 1.492.006.138.003.168-.037.166-.17-.007-.68-.013-1.36.003-2.04.004-.202-.05-.262-.248-.294-1.569-.245-2.78-1.048-3.632-2.384-.492-.774-.74-1.627-.78-2.543-.007-.155.036-.202.19-.193.233.013.468.008.702.001.114-.003.162.018.168.151.104 2.036 1.513 3.608 3.52 3.939 2.21.363 4.415-1.286 4.687-3.504.008-.072.023-.144.03-.217.025-.245.164-.367.417-.367.16 0 .323.01.483-.003.139-.01.171.037.166.172-.045 1.13-.406 2.146-1.108 3.031-.848 1.069-1.951 1.719-3.306 1.917-.201.03-.25.096-.246.296.015.68.01 1.36.003 2.04-.002.135.032.17.168.168.49-.009.98.002 1.47-.008.137-.002.175.037.169.172-.01.24-.013.483 0 .723.008.14-.041.167-.171.166-.724-.007-1.448-.004-2.172-.004v.001Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.499.405h10.364v17.492H.499z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicrophoneIcon;
