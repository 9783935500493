import { createContext, useState } from "react";
import { LANGUAGES } from "../domain/Common/Layout/Header/constant";
import { REGION } from "../domain/Common/Layout/Main/constants";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    LANGUAGES.HEBREW.value
  );
  const [region, setRegion] = useState(REGION.lOCAL);

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        setCurrentLanguage,
        region,
        setRegion,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
