import React from "react";

const GrayArrowDwon = ({ color, width = 110, height = 10 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 10"
    >
      <g>
        <path
          stroke={color ?? "#9FA3A8"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.141"
          d="M16.648 3.084L9.324 8.545 2 3.084"
        ></path>
      </g>
    </svg>
  );
};

export default GrayArrowDwon;
