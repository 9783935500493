import React from "react";
import UploadIcon from "../../../../../assets/icons/Common/Upload/UploadIcon";
import styled from "styled-components";
import { getCurrentShareData } from "../../../../Common/Layout/Header/components/NavBar/utils";
import useCurrentRoute from "../../../../../customHooks/useCurrentRoute";

const ShareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  padding: 4px 12px;
  border: 1px solid #003f5d;
  border-radius: 8px;
  cursor: pointer;
  background-color: #ffffff;
`;

const ShareText = styled.p`
  margin: 0;
  color: #003f5d;
  font-size: 15px;
  font-weight: 600;
  font-family: Assistant, sans-serif;
  margin-right: 8px;
`;

const LandingShare = () => {
  const currentRoute = `${useCurrentRoute()}?shared=true`;

  const onShare = () => {
    const shareData = getCurrentShareData(currentRoute);
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Failed to share, with error", error));
    } else {
      console.warn("Sharing not supported on this device.");
    }
  };

  return (
    <ShareButton onClick={onShare} aria-label="Share content">
      <ShareText>שליחת אות מצוקה</ShareText>
      <UploadIcon color={"#E81616"} size={18} />
    </ShareButton>
  );
};

export default LandingShare;
