import React, { useState } from "react";
import styled from "styled-components";
import InteractiveMapPoint from "./InteractiveMapPoint";
import LocationDetails from "../../../Ecosystem/Public/Map/components/LocationDetails";

const Wrapper = styled.div`
  margin-bottom: "20px";
  position: relative;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "100%"};
`;

const InteractiveLandingMap = ({
  mapImage,
  pointPosition,
  locationDetails,
  width,
  height,
}) => {
  const [showLocationDetails, setShowLocationDetails] = useState(false);

  return (
    <Wrapper width={width} height={height}>
      <img
        src={mapImage}
        alt="Map"
        style={{ width, height, marginBottom: "20px" }}
      />
      <InteractiveMapPoint
        onClick={() => setShowLocationDetails(true)}
        style={{
          position: "absolute",
          top: pointPosition.top,
          left: pointPosition.left,
          right: pointPosition.right,
          cursor: "pointer",
        }}
      />
      {showLocationDetails && (
        <LocationDetails
          location={{
            title: locationDetails.title,
            image: locationDetails.image,
          }}
          buttonText={locationDetails.buttonText || "Choose"}
          reset={() => {
            setShowLocationDetails(false);
          }}
          onClick={() => {
            window.open(locationDetails.wazeUrl, "_blank");
          }}
        />
      )}
    </Wrapper>
  );
};

export default InteractiveLandingMap;
