import axios from "axios";
import { CURRENT_DOMAIN } from "../info";

const api = axios.create({
  baseURL: CURRENT_DOMAIN,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    // You can add other default headers here
  },
});

const requestHandler = async (
  method,
  url,
  data,
  params,
  contentType = "application/json",
  withCredentials = false
) => {
  try {
    const accessToken = localStorage.getItem("access_token");

    const headers = {
      "Content-Type": contentType,
    };

    if (accessToken) {
      headers["authorization"] = accessToken;
    }

    const response = await api({
      method,
      url,
      data,
      params,
      headers,
      withCredentials,
    });

    // You can customize the response handling as needed
    return response.data;
  } catch (error) {
    // You can handle errors globally here
    console.error("Request failed:", error);
    throw error;
  }
};

export default requestHandler;
