import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LoginWith from "../../../../assets/images/Auth/LoginWith";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.extraStyles ?? ""}
  padding-bottom: 20px;
`;
const LoginWithWrapper = styled.div`
  text-align: center;
`;
const SignUpWrapper = styled.div`
  color: ${({ color }) => color ?? "#28b5b5"};
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 8px;
`;
const SignUpLink = styled(Link)`
  margin-bottom: 10px;
  color: ${({ color }) => color ?? "#28b5b5"};
  font-size: 16px;
  font-weight: 700;
  text-decoration-line: underline;
`;
const ForgotPassword = styled(Link)`
  color: #003f5d;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
`;

function LoginFooter({
  registerPage,
  extraStyles,
  hideSignUp,
  textColor,
  linkColor,
}) {
  return (
    <Wrapper extraStyles={extraStyles}>
      <LoginWithWrapper>
        <LoginWith />
      </LoginWithWrapper>
      {!hideSignUp && (
        <SignUpWrapper color={textColor}>
          {registerPage ? "Already have an account?" : "Don’t have an account?"}{" "}
          <SignUpLink
            color={linkColor}
            to={registerPage ? "/login/city" : "/createAccount"}
          >
            {registerPage ? "Sign in" : "Sign up"}
          </SignUpLink>
        </SignUpWrapper>
      )}
      {!registerPage && (
        <ForgotPassword to={"/resetPassword"}>
          Forgot your password?
        </ForgotPassword>
      )}
    </Wrapper>
  );
}

export default LoginFooter;
