import React from "react";
import styled from "styled-components";

const TabsContainer = styled.div`
  margin: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop ?? "0"};
  width: ${({ width }) => width};
`;

const Tab = styled.div`
  flex-grow: ${({ flexGrow }) => flexGrow ?? "1"};
  display: flex;
  justify-content: center;
  gap: 5.63px;
  align-items: center;
  height: 31px;
  border-radius: 16.075px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background: rgba(165, 161, 162, 0.1);
  color: #58656d;
  font-size: 15px;
  font-family: Assistant;
  font-weight: 600;
  letter-spacing: -0.27px;
  border: 0;
  cursor: pointer;
  @media (max-width: 380px) {
    font-size: 12px;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
`;

const Circle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const Tabs = {
  All: "all",
};

const selectedBackgroundColor = "#7FB38380";

const VTTabs = ({
  tabsGroup,
  activeTabs,
  setActiveTabs,
  width,
  allowMultiSelect = true,
}) => {
  const handleTabClick = (tab) => {
    setActiveTabs((prevTabs) => {
      // Single-select behavior: Only allow one tab to be active
      if (!allowMultiSelect) {
        return [tab];
      }

      // Multi-select behavior: Handle tab selection with multiple active tabs
      if (tab === Tabs.All) {
        return [Tabs.All];
      }

      // If the tab is already active, remove it from the active tabs.
      if (prevTabs.includes(tab)) {
        return prevTabs.filter((t) => t !== tab);
      }

      // Otherwise, add the clicked tab and remove the "All" tab from the active tabs.
      return [...prevTabs.filter((t) => t !== Tabs.All), tab];
    });
  };

  const isActive = (tab) => activeTabs.includes(tab);

  return tabsGroup.map((tabs) => (
    <TabsContainer width={width}>
      {tabs.map((tab) => (
        <Tab
          onClick={() => handleTabClick(tab?.value)}
          style={{
            backgroundColor: isActive(tab?.value)
              ? tab?.selectedBackgroundColor ?? selectedBackgroundColor
              : tab?.backgroundColor || "rgba(165, 161, 162, 0.1)",
          }}
        >
          {tab?.label}
          {tab?.color && <Circle color={tab.color} />}
        </Tab>
      ))}
    </TabsContainer>
  ));
};

export default VTTabs;
