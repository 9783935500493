import React, { useContext, useRef } from "react";
import Card from "./Card";
import { Link } from "react-router-dom";
import CardSliderContainer from "../../../../../../../../kit/CardSliderContainer/CardSliderContainer";
import { isLiked } from "../../../../../../../Tasks/TaskUtils";
import useCurrentUser from "../../../../../../../../customHooks/useCurrentUser";
import { PreviousRouteContext } from "../../../../../../../../store/perviousRouteContext";

const CardSlider = ({
  data,
  lang,
  HeartColor,
  showLocation,
  showDate,
  isHeartVisable,
  isPublicZone,
  link,
  hasDirectLink,
  showName,
  activeSlide,
  handleClick,
  hasHiddenContent,
  titleFontSize,
  titleFontFamily,
  titleFontWeight,
  titleColor,
  locationFontSize,
  locationFontFamily,
  locationFontWeight,
  locationColor,
  zone,
  handleFocus,
  hebrewDescription,
  hasNavback = false,
  isCardsVisible = true,
  currentRoute,
  cardHeight,
  imageHeight,
}) => {
  const containerRef = useRef(null);
  const { isAuthenticated, currentUser } = useCurrentUser();
  const { updatePreviousRoute } = useContext(PreviousRouteContext);

  const currentRouteSetter = () => {
    if (currentRoute) {
      updatePreviousRoute(currentRoute);
    }
  };

  const renderCard = (data, index) => {
    if (!data) return null;
    return (
      <Card
        key={data.id}
        {...data}
        lang={lang}
        HeartColor={HeartColor}
        liked={isAuthenticated ? isLiked(data, currentUser?._id) : false}
        showLocation={showLocation}
        showDate={showDate}
        isHeartVisable={isAuthenticated && isHeartVisable}
        isPublicZone={isPublicZone}
        showName={showName}
        isActive={activeSlide === index}
        hasHiddenContent={hasHiddenContent}
        index={index}
        handleClick={handleClick}
        titleFontSize={titleFontSize}
        titleFontFamily={titleFontFamily}
        titleFontWeight={titleFontWeight}
        titleColor={titleColor}
        locationFontSize={locationFontSize}
        locationFontFamily={locationFontFamily}
        locationFontWeight={locationFontWeight}
        locationColor={locationColor}
        zone={zone}
        ref={containerRef}
        handleFocus={handleFocus}
        activeSlide={activeSlide}
        hebrewDescription={hebrewDescription}
        cardHeight={cardHeight}
        imageHeight={imageHeight}
      />
    );
  };
  return (
    <CardSliderContainer ref={containerRef} numberOfCards={data?.length}>
      {isCardsVisible &&
        data?.map((data, index) => (
          <>
            {link && !data?.ignoreLink ? (
              <Link
                style={{ textDecoration: "none" }}
                onClick={currentRouteSetter}
                to={
                  hasNavback
                    ? `${link}/${data?.id || data?._id}/navback`
                    : `${link}/${data?.id || data?._id}`
                }
              >
                {renderCard(data, index)}
              </Link>
            ) : hasDirectLink ? (
              <Link
                style={{ textDecoration: "none" }}
                onClick={currentRouteSetter}
                to={`/activities/${data?.zone}/card-details/${data?.task_type}/${data?.id}`}
              >
                {renderCard(data, index)}
              </Link>
            ) : (
              <div onClick={currentRouteSetter}>{renderCard(data, index)}</div>
            )}
          </>
        ))}
    </CardSliderContainer>
  );
};

export default CardSlider;
