import React, { useContext } from "react";
import PopUpModal from "../../../../kit/PopUpModal/PopUpModal";
import EmergencyContact from "./EmergencyContact/EmergencyContact";
import ReturnArrow from "../../../Common/Layout/ReturnArrow/ReturnArrow";
import { EmergencyOverlaysContext } from "../EmergencyOverlaysContext";
import EmergencyContactV2 from "./EmergencyContact/EmergencyContactV2";

const EmergencyContactPopUp = ({
  visible,
  hidePopUpfunc,
  data,
  onDelete,
  onReturn,
  onClose,
  isV2 = false,
  emergencyId,
}) => {
  const { theme } = useContext(EmergencyOverlaysContext);
  return (
    <>
      {onReturn && <ReturnArrow forceShowArrow onClickOverride={onReturn} />}
      <PopUpModal
        visible={visible}
        hidePopUpfunc={() => onReturn ?? hidePopUpfunc?.()}
        useNormalDialog
        zIndex={100}
        border={`3px solid ${theme?.primary}`}
      >
        {isV2 ? (
          <EmergencyContactV2
            emergencyId={emergencyId}
            onClose={() => {
              if (onClose) {
                onClose();
              } else if (onReturn) {
                onReturn();
              } else if (hidePopUpfunc) {
                hidePopUpfunc();
              }
            }}
          />
        ) : (
          <EmergencyContact
            emergencyId={emergencyId}
            onClose={() => {
              if (onClose) {
                onClose();
              } else if (onReturn) {
                onReturn();
              } else if (hidePopUpfunc) {
                hidePopUpfunc();
              }
            }}
          />
        )}
      </PopUpModal>
    </>
  );
};

export default EmergencyContactPopUp;
