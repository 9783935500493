import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { fetchUser } from "../../../Auth/api";
import { timeAgo } from "../../../Common/utils/date";
import { FaCommentAlt, FaRegCommentAlt } from "react-icons/fa"; // Import comment icons
import SivanImage from "../../../../assets/images/Mock/User/SivanImage.png";
import TomImage from "../../../../assets/images/Mock/User/tom-cruise.png";
import { deletePosts } from "../api";
import Comment from "../Comment/Comment";
import TextInput from "../../../../kit/Input/TextInput";
import PostCommentIcon from "../../../../assets/icons/Activities/CommunityBoard/PostCommentIcon";
import {
  createComment,
  getCommentsByPostId,
  getLikesByPostId,
} from "../../Board/api";
import { useForm } from "react-hook-form";
import { getPhotoSrc } from "../utils";
import Dropdown from "../../../../kit/Dropdown/Dropdown";
import {
  getReportOptions,
  getShareDeleteOptions,
} from "../../../../kit/Dropdown/DropdownOptions";
import { CommunitySections } from "../../Board/Board";
import AddPost from "../../Board/Form/AddPost";
import VideoPreview from "../../../../kit/VideoPreview/VideoPreview";
import ExpandableText from "../../../../kit/ExpandableText/ExpandableText";
import PostImages from "../../../../kit/PostImages/PostImages";
import VetPost from "../../Board/Form/VetPost";
import CommunityBoardFormContent from "../../Board/Form/CommunityBoardFormContent";
import ReactionButton from "../../../../kit/Button/ReactionButton";
import useCurrentUser from "../../../../customHooks/useCurrentUser";
import { getCategoriesByZone } from "../../Board/constant";

const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 15px;
  border-radius: 12px;
  width: calc(100% - 74px);
  background-color: #fff;
  margin: 0 17px;
  position: relative;
  box-sizing: content-box;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 5px;
`;

const UserName = styled.span`
  color: ${(props) => props.color ?? "#007897"};
  font-size: 10px;
  font-weight: 700;
`;

const PostDate = styled.span`
  color: #ababab;
  font-size: 8px;
  font-weight: 600;
`;

const PostActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items to the right */
  width: 100%; /* Make the container occupy the full width */
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const CommentIconWrapper = styled.span`
  margin-right: 8px;
`;

const Interaction = styled.span`
  display: flex;
  gap: 20px;
`;

const ActionNumber = styled.span`
  font-size: 14px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: 8px;
  cursor: pointer;
`;

const CommentsWrapper = styled.div`
  width: 100%;
  margin-top: 11px;
`;

const PostCommentButton = styled.div`
  cursor: pointer;
  padding: 0 5px;
`;

const PostImage = styled.img`
  border-radius: 5px;
  margin: auto;
  max-width: 100%;
  max-height: 400px;
  object-fit: cover;
`;

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const LikedByText = styled.div`
  font-family: Assistant;
  font-weight: 500;
  font-size: 8px;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const LikedByTextBold = styled.div`
  font-family: Assistant;
  font-weight: 700;
  font-size: 8px;
`;

const CloseEdit = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
`;
const FormWrapper = styled.div`
  border-radius: 17.5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease;
  padding: 13px 13px 20px 13px;
  margin: 0 16px;
`;
const CBTitle = styled.div`
  color: #1b5c6d;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
`;

const Post = ({
  postData,
  posts,
  refetchPosts,
  setPosts,
  setShowShareList,
  zone,
  isNetwork,
  section,
  showImage = true,
  postId,
  isBasic,
  actionIcon,
  isCB,
}) => {
  const { register, handleSubmit, setValue } = useForm();
  const [postComments, setPostComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { currentUser, isAuthenticated } = useCurrentUser();

  const userId = useMemo(() => postData?.user_id, [postData]);

  const { isLoadingUser, data: fetchedUser } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => fetchUser(userId),
  });

  const { data: fetchedLikes, refetch: refetchLikes } = useQuery({
    queryKey: ["postLiked", postData?._id],
    queryFn: () => getLikesByPostId(postData?._id),
    onSuccess: (data) => {
      const likes = data?.data?.likes;
      // setLiked(!!likes?.find?.((like) => like.user_id === currentUser?._id)); // Remove this line
    },
  });

  const postLikes = useMemo(
    () => fetchedLikes?.data?.likes,
    [fetchedLikes?.data?.likes]
  );

  const { data: postCommentsData, refetch: refetchComments } = useQuery({
    queryKey: ["postComments", postData?._id],
    queryFn: () => getCommentsByPostId(postData?._id),
  });

  useEffect(() => {
    setPostComments(postCommentsData?.data?.comments ?? []);
  }, [postCommentsData]);

  const user = useMemo(
    () => fetchedUser?.data?.users,
    [fetchedUser?.data.users]
  );
  const postDate = useMemo(() => timeAgo(postData?.createdAt), [postData]);

  const handleDelete = async () => {
    await deletePosts(postData?._id);
    setPosts?.((posts) => posts.filter((post) => post?._id !== postData?._id));
  };

  const handleEdit = () => setIsEditMode(true);

  const handleShare = () => {
    const shareData = {
      title: "",
      text: postData?.description,
      url:
        window.location.origin +
        `/activities/board/zone/${zone}/section/${
          isNetwork
            ? CommunitySections.Networks
            : CommunitySections.CommunityBoard
        }/${postData?._id}`,
    };
    navigator?.share?.(shareData);
    // Temporarily removed until messages are implemented
    // setShowShareList(true);
  };

  const handleReport = () => {};

  const toggleShowComments = () => setShowComments(!showComments);

  if (isLoadingUser || !postData) {
    return null;
  }

  const onPostCommentClick = async (commentData) => {
    try {
      await createComment({
        user_id: currentUser?._id,
        post_id: postData?._id,
        body: commentData?.commentBody,
      });
      setValue("commentBody", "");
      refetchComments();
    } catch (ex) {
      console.error("Failed to post comment, with error: ", ex);
    }
  };

  const renderButtonsRow = () => {
    const getLikesText = () => {
      if (!postLikes?.length || postLikes?.length < 1) {
        return <></>;
      }

      if (postLikes?.length === 1) {
        return (
          <LikedByText>
            Liked by&nbsp;
            <LikedByTextBold>
              {postLikes?.[postLikes?.length - 1]?.user_name ?? ""}
            </LikedByTextBold>
          </LikedByText>
        );
      }

      const postLikesLength = postLikes?.length ?? 0;

      return (
        <LikedByText>
          Liked by &nbsp;
          <LikedByTextBold>
            {postLikes?.[postLikesLength - 1]?.user_name ?? ""}
          </LikedByTextBold>
          &nbsp;and&nbsp;
          <LikedByTextBold>{`${postLikesLength - 1} ${
            postLikesLength - 1 === 1 ? "other" : "others"
          }`}</LikedByTextBold>
        </LikedByText>
      );
    };

    const renderCommentButton = () => (
      <CommentIconWrapper onClick={toggleShowComments}>
        {showComments ? <FaCommentAlt /> : <FaRegCommentAlt />}
      </CommentIconWrapper>
    );

    if (postLikes?.length && postLikes?.length > 0) {
      return (
        <>
          {!isCB && renderCommentButton()}
          <ReactionButton
            postId={postData?._id}
            userId={currentUser?._id}
            hasReacted={
              !!postLikes?.find((like) => like.user_id === currentUser?._id)
            }
            currentReaction={
              postLikes?.find((like) => like.user_id === currentUser?._id)
                ?.reaction
            }
            refetchLikes={refetchLikes}
            canReact={isAuthenticated}
          />
          {getLikesText()}
        </>
      );
    }
    return (
      <>
        <ReactionButton
          postId={postData?._id}
          userId={currentUser?._id}
          hasReacted={
            !!postLikes?.find((like) => like.user_id === currentUser?._id)
          }
          currentReaction={
            postLikes?.find((like) => like.user_id === currentUser?._id)
              ?.reaction
          }
          refetchLikes={refetchLikes}
          canReact={isAuthenticated}
        />
        {!isCB && renderCommentButton()}
      </>
    );
  };

  const renderPostCommentButton = () => (
    <PostCommentButton
      type={"submit"}
      onClick={handleSubmit(onPostCommentClick)}
    >
      <PostCommentIcon />
    </PostCommentButton>
  );

  const renderPostEditSection = () =>
    isBasic ? (
      <VetPost
        posts={posts}
        setPosts={setPosts}
        postData={postData}
        onPostSuccess={() => setIsEditMode(false)}
        zone={zone}
        section={section}
        isEditMode={isEditMode}
        type={postId}
      />
    ) : isCB ? (
      <FormWrapper>
        <CBTitle>
          <div>Add Post</div>
          <div>...הוסף פוסט לפורום הקהילתי</div>
        </CBTitle>
        <CommunityBoardFormContent
          posts={posts}
          setPosts={setPosts}
          postData={postData}
          onPostSuccess={() => setIsEditMode(false)}
          zone={zone}
          section={section}
          categories={getCategoriesByZone(zone)}
          isEditMode={isEditMode}
          type={postId}
        />
      </FormWrapper>
    ) : (
      <PostWrapper>
        <AddPost
          posts={posts}
          setPosts={setPosts}
          postData={postData}
          zone={zone}
          section={section}
          isEditMode={isEditMode}
          onPostSuccess={() => setIsEditMode(false)}
        />
        <CloseEdit onClick={() => setIsEditMode(false)}>X</CloseEdit>
      </PostWrapper>
    );

  if (isEditMode) {
    return renderPostEditSection();
  }

  return (
    <>
      <PostWrapper>
        <UserInfoWrapper>
          {showImage && (
            <UserImage
              src={user?.gender === "female" ? SivanImage : TomImage}
              alt="User"
            />
          )}
          <UserInfo>
            <UserName
              color={user?._id === currentUser?._id ? "#7FB383" : undefined}
            >
              {user?.name}{" "}
              {user?._id === currentUser?._id && <span>(you)</span>}
            </UserName>
            <PostDate>{postDate}</PostDate>
          </UserInfo>
          <DropdownContainer>
            <Dropdown
              menuLeftOffset={"-80px"}
              options={
                user?._id === currentUser?._id
                  ? getShareDeleteOptions(handleEdit, handleDelete, handleShare)
                  : getReportOptions(handleReport, handleShare)
              }
              customIcon={actionIcon}
            />
          </DropdownContainer>
        </UserInfoWrapper>
        {postData?.photo && !postData?.link && (
          <PostImage src={getPhotoSrc(postData?.photo)} />
        )}
        {postData?.photos && postData?.photos.length > 0 && (
          <div style={{ margin: "0 auto" }}>
            <PostImages
              photos={postData?.photos?.map?.((imgUrl) => getPhotoSrc(imgUrl))}
            />
          </div>
        )}
        {postData?.link && <VideoPreview videoUrl={postData?.link} />}
        <ExpandableText
          textStyle={
            isCB
              ? {
                  margin: "0",
                  fontSize: "17px",
                  fontWeight: 600,
                  wordBreak: "break-word",
                }
              : { margin: "10px 0", wordBreak: "break-word" }
          }
          text={postData?.description}
          displayAsHtml
        />
        <PostActionsWrapper>
          <IconsWrapper>{renderButtonsRow()}</IconsWrapper>
          <Interaction>
            <ActionNumber
              onClick={toggleShowComments}
              color={showComments ? "#000" : "#ABABAB"}
              fontWeight={showComments ? "bold" : "normal"}
            >
              {`${postComments?.length ?? 0} Comment${
                postComments?.length === 1 ? "" : "s"
              }`}
            </ActionNumber>
          </Interaction>
        </PostActionsWrapper>
        {showComments && (
          <CommentsWrapper>
            <form onSubmit={handleSubmit(onPostCommentClick)}>
              <TextInput
                height="28px"
                width={"calc(100% - 18px)"}
                fontSize={"10px"}
                margin={"auto"}
                border="1px solid #ABABAB"
                borderRadius="17.5px"
                type="text"
                placeholder="Enter comment here"
                required
                register={register}
                hookName={"commentBody"}
                ActionButton={renderPostCommentButton}
              />
            </form>
            {postComments?.length > 0 &&
              postComments?.map((comment) => (
                <Comment comment={comment} refetchComments={refetchComments} />
              ))}
          </CommentsWrapper>
        )}
      </PostWrapper>
    </>
  );
};

export default Post;
